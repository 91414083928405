import React from "react";
import PaginationPanel from "../../../../components/PaginationPanel";
import { usePagination } from "../../../../hooks/usePagination";
const LoadingTaskPaginationContainer = ({ isLoading, data, pagination }) => {
    const { setPagination } = usePagination();
    const handlerChange = (nextPage, nextPageSize) => {
        setPagination({
            page: nextPage,
            pageSize: nextPageSize,
        });
    };
    if (data) {
        return React.createElement(PaginationPanel, { pagination: pagination, disabled: isLoading, onChange: handlerChange });
    }
    return null;
};
export default LoadingTaskPaginationContainer;
