import React from 'react';
import { useNavigate } from 'react-router-dom';
import PaginationPanel from '../../components/PaginationPanel';
import { links } from '../../constants/links';
import { useFetchListQuery } from '../../redux/api/clientsApi';
const ClientsPaginationContainer = (props) => {
    const { page, pageSize } = props;
    const { data, isLoading } = useFetchListQuery({ page, pageSize });
    const navigate = useNavigate();
    const handlerChange = (nextPage, nextPageSize) => {
        navigate(links.handbookClientsLink.url(nextPage, nextPageSize !== null && nextPageSize !== void 0 ? nextPageSize : pageSize));
    };
    if (data) {
        return (React.createElement(PaginationPanel, { pagination: data === null || data === void 0 ? void 0 : data.pagination, disabled: isLoading, onChange: handlerChange }));
    }
    return null;
};
export default ClientsPaginationContainer;
