import React, { useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Button, Checkbox, Divider, Empty, Form, Select, Spin, Typography, } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import UserGroupSelect from '../UserGroupSelect';
import StatusSelect from '../StatusSelect';
const StatusRelationItem = (props) => {
    const { relationTriggerList, id, objectClass } = props;
    return (React.createElement("div", null,
        React.createElement(Form.Item, { name: [id, 'statusToId'], label: "\u041F\u0435\u0440\u0435\u0445\u043E\u0434 \u043D\u0430 \u0441\u0442\u0430\u0442\u0443\u0441" },
            React.createElement(StatusSelect, { labelInValue: true, objectClass: objectClass })),
        React.createElement(Form.Item, { name: [id, 'trigger'], label: "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u043F\u0440\u0438 \u0441\u043C\u0435\u043D\u0435 \u0441\u0442\u0430\u0442\u0443\u0441\u0430" },
            React.createElement(Select, { options: relationTriggerList })),
        React.createElement(Form.Item, { label: "\u0413\u0440\u0443\u043F\u043F\u0430", name: [id, 'userGroups'] },
            React.createElement(UserGroupSelect, { mode: "multiple" })),
        React.createElement(Form.Item, { name: [id, 'sendNotification'], valuePropName: "checked" },
            React.createElement(Checkbox, null, "\u041E\u0442\u043F\u0440\u0430\u0432\u043B\u044F\u0442\u044C \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u0435")),
        React.createElement(Form.Item, { name: [id, 'needReport'], valuePropName: "checked" },
            React.createElement(Checkbox, null, "\u0422\u0440\u0435\u0431\u043E\u0432\u0430\u0442\u044C \u043E\u0442\u0447\u0435\u0442"))));
};
const StatusRelationForm = (props) => {
    const { relationTriggerList, statusId, initialValues, loading, objectClass, onSubmit, } = props;
    const [form] = useForm();
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);
    const handlerSubmit = (fields) => {
        const result = {
            edit: [],
            add: [],
            delete: [],
        };
        fields.relations.forEach((item, index) => {
            var _a, _b;
            const edited = form.isFieldTouched(['relations', index]);
            if (edited) {
                if (!item.id) {
                    result.add.push(Object.assign(Object.assign({}, item), { userGroups: (_a = item.userGroups) !== null && _a !== void 0 ? _a : [] }));
                }
                else {
                    result.edit.push(Object.assign(Object.assign({}, item), { userGroups: (_b = item.userGroups) !== null && _b !== void 0 ? _b : [] }));
                }
            }
        });
        initialValues.relations.forEach((item) => {
            var _a;
            const exist = fields.relations.some((field) => (field === null || field === void 0 ? void 0 : field.id) === item.id);
            if (!exist) {
                result.delete.push(Object.assign(Object.assign({}, item), { userGroups: (_a = item.userGroups) !== null && _a !== void 0 ? _a : [] }));
            }
        });
        onSubmit(result);
    };
    const handlerClear = () => {
        form.resetFields();
    };
    return (React.createElement(Spin, { spinning: loading },
        React.createElement(Form, { form: form, initialValues: initialValues, layout: "vertical", onFinish: handlerSubmit },
            React.createElement(Form.List, { name: "relations" }, (fields, operation) => {
                const { add, remove } = operation;
                return (React.createElement(React.Fragment, null,
                    fields.length ? (fields.map((value, index) => (React.createElement("div", { key: `${statusId}-${value.key}` },
                        React.createElement(Divider, { orientation: "left", plain: true, style: { position: 'relative', margin: `${index ? 35 : 0}px 0 20px` } },
                            React.createElement(Typography.Text, { strong: true },
                                "\u0421\u0432\u044F\u0437\u044C \u2116",
                                index + 1),
                            React.createElement("div", { style: {
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: 0,
                                    right: 20,
                                    backgroundColor: 'white',
                                    padding: '0 1em',
                                } },
                                React.createElement(Button, { size: "small", onClick: () => remove(value.name), danger: true, icon: React.createElement(CloseOutlined, null) }))),
                        React.createElement(StatusRelationItem, { relationTriggerList: relationTriggerList, objectClass: objectClass, id: value.name }))))) : (React.createElement(Empty, { style: { margin: '40px 0' }, description: "\u0421\u0432\u044F\u0437\u0438 \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0442" })),
                    React.createElement(Button.Group, { style: { width: '100%' } },
                        React.createElement(Button, { block: true, type: "dashed", onClick: () => add() }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"),
                        React.createElement(Button, { block: true, htmlType: "submit" }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
                        React.createElement(Button, { onClick: handlerClear, disabled: !form.isFieldsTouched(), block: true, danger: true }, "\u041E\u0442\u043C\u0435\u043D\u0430"))));
            }))));
};
export default StatusRelationForm;
