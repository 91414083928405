import { Button, Card, Col, Divider, Row, Space, Spin, Typography } from "antd";
import React, { useState } from "react";
import ProductSearchModal from "../../../../components/nomenclatureSearch/product/ProductSearchModal";
import SkuSearchModalWrapper from "../../../../components/nomenclatureSearch/sku/SkuSearchModal/SkuSearchModalWrapper";
import MDIcon from "../../../../components/ui/MDIcon";
import { AddCellModal } from "./Modals/AddCellModal";
const InventoryTaskFormNomenclature = (props) => {
    const { editable, isLoading, children, warehouseId, onAddSku, onAddProduct, onAddCell } = props;
    const [skuModalIsOpen, setSkuModalIsOpen] = useState(false);
    const [productModalIsOpen, setProductModalIsOpen] = useState(false);
    const [cellModalIsOpen, setCellModalIsOpen] = useState(false);
    const openSkuModal = () => {
        setSkuModalIsOpen(true);
    };
    const openProductModal = () => {
        setProductModalIsOpen(true);
    };
    const openCellModal = () => {
        setCellModalIsOpen(true);
    };
    const handlerAddSku = (values) => {
        onAddSku(values);
    };
    const handlerAddProduct = (values) => {
        const ids = values.map((value) => value.id);
        onAddProduct(ids);
    };
    const handlerAddCell = (ids) => {
        if (ids) {
            onAddCell(ids);
        }
        setCellModalIsOpen(false);
    };
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(Card, null,
            React.createElement(Space, { direction: "vertical", className: "task-loading-change__nomenclature mt-3" },
                React.createElement("div", null,
                    React.createElement(Row, null,
                        React.createElement(Col, { flex: "auto" },
                            React.createElement(Typography.Title, { level: 3, style: { marginBottom: 0 } }, "\u041D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440\u0430")),
                        React.createElement(Col, { flex: "none" }, editable ? (React.createElement(Button.Group, null,
                            React.createElement(Space, null,
                                React.createElement(Button, { disabled: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "plus-circle", className: "anticon" }), onClick: openProductModal }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u044E"),
                                React.createElement(Button, { disabled: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "plus-circle", className: "anticon" }), onClick: openCellModal }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u044F\u0447\u0435\u0439\u043A\u0443"),
                                React.createElement(Button, { disabled: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "plus-circle", className: "anticon" }), onClick: openSkuModal }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C sku")))) : null)),
                    React.createElement(Divider, null)),
                children)),
        React.createElement(SkuSearchModalWrapper, { warehouseId: [`${warehouseId}`], filterIncludePersonal: true, onAdd: handlerAddSku, visible: skuModalIsOpen, setModelVisible: setSkuModalIsOpen }),
        React.createElement(ProductSearchModal, { onAdd: handlerAddProduct, visible: productModalIsOpen, setModelVisible: setProductModalIsOpen }),
        React.createElement(AddCellModal, { warehouseId: warehouseId, visible: cellModalIsOpen, onAdd: handlerAddCell, setModelVisible: setCellModalIsOpen })));
};
export default InventoryTaskFormNomenclature;
