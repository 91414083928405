import React from "react";
import { Alert, Badge, Button, Descriptions, Drawer, Dropdown, Result, Space, Spin, Tabs, Tag } from "antd";
import SkuStatusTag from "../SkuStatusTag";
import SkuHistory from "../SkuHistory";
import MDIcon from "../ui/MDIcon";
const SkuInfoDrawer = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { sku, isOpen, onClose, printActions, onPrintActionClick, loading, history } = props;
    const title = sku ? `#${sku.id}: ${sku.product.titleCompiled}` : "";
    const handlerPrintAction = (documentId) => {
        if (onPrintActionClick && sku)
            onPrintActionClick(documentId, sku === null || sku === void 0 ? void 0 : sku.id.toString());
        onClose();
    };
    const documentsDropdownMenu = {
        items: printActions === null || printActions === void 0 ? void 0 : printActions.map((item) => {
            return {
                key: item.id,
                label: item.title,
                onClick: () => handlerPrintAction(item.id),
            };
        }),
    };
    const printButton = () => {
        if (printActions === null || printActions === void 0 ? void 0 : printActions.length) {
            return (React.createElement(Dropdown, { menu: documentsDropdownMenu, trigger: ["click"] },
                React.createElement(Button, { icon: React.createElement(MDIcon, { icon: "printer" }) })));
        }
        return React.createElement("div", null);
    };
    const drawerTabs = [
        {
            key: "info",
            label: "Информация",
            children: (React.createElement(Space, { direction: "vertical", size: [24, 24], style: { width: "100%" } },
                (sku === null || sku === void 0 ? void 0 : sku.hasDefect) ? React.createElement(Alert, { description: sku === null || sku === void 0 ? void 0 : sku.defect, message: "\u0418\u043C\u0435\u0435\u0442\u0441\u044F \u0434\u0435\u0444\u0444\u0435\u043A\u0442 \u0438\u043B\u0438 \u043F\u0440\u043E\u0431\u043B\u0435\u043C\u0430", type: "warning", showIcon: true }) : "",
                React.createElement(Descriptions, { bordered: true, size: "small", column: 2 },
                    React.createElement(Descriptions.Item, { label: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E" }, sku === null || sku === void 0 ? void 0 : sku.quantity),
                    React.createElement(Descriptions.Item, { label: "\u0421\u0442\u0430\u0442\u0443\u0441" },
                        React.createElement(SkuStatusTag, { title: (_a = sku === null || sku === void 0 ? void 0 : sku.status) === null || _a === void 0 ? void 0 : _a.title, style: (_b = sku === null || sku === void 0 ? void 0 : sku.status) === null || _b === void 0 ? void 0 : _b.style })),
                    React.createElement(Descriptions.Item, { label: "\u041A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442" }, sku === null || sku === void 0 ? void 0 : sku.client.companyName),
                    React.createElement(Descriptions.Item, { label: "\u0418\u041D\u041D" }, sku === null || sku === void 0 ? void 0 : sku.client.companyInn),
                    React.createElement(Descriptions.Item, { label: "\u0421\u043A\u043B\u0430\u0434" }, (_c = sku === null || sku === void 0 ? void 0 : sku.warehouse) === null || _c === void 0 ? void 0 : _c.storageTitle),
                    React.createElement(Descriptions.Item, { label: "\u041A\u0430\u0440\u043C\u0430\u043D" }, (_d = sku === null || sku === void 0 ? void 0 : sku.storageCell) === null || _d === void 0 ? void 0 : _d.title),
                    React.createElement(Descriptions.Item, { label: "\u0420\u0430\u0437\u043C\u0435\u0440" }, sku === null || sku === void 0 ? void 0 : sku.size)), sku === null || sku === void 0 ? void 0 :
                sku.skuAttributeCategories.map((category) => (React.createElement(Descriptions, { title: category.title, bordered: true, size: "small", column: 1, key: category.title }, category.skuAttributeValues.map((attr) => {
                    return attr.dataType === "COLOR" ? (React.createElement(Descriptions.Item, { label: attr.title, key: attr.title },
                        React.createElement(Tag, { color: attr.value, style: { width: "100%" } }, "\u00A0"))) : (React.createElement(Descriptions.Item, { label: attr.title, key: attr.title }, attr.value));
                })))))),
        },
        {
            key: "history",
            label: (React.createElement(Badge, { count: (_e = history === null || history === void 0 ? void 0 : history.items.length) !== null && _e !== void 0 ? _e : 0, offset: [10, -2] }, "\u0418\u0441\u0442\u043E\u0440\u0438\u044F")),
            children: React.createElement(SkuHistory, { items: (_f = history === null || history === void 0 ? void 0 : history.items) !== null && _f !== void 0 ? _f : [] }),
        },
        {
            key: "marks",
            label: "Метки",
            children: React.createElement(Result, { status: "404", title: "\u041C\u0435\u0442\u043A\u0438", subTitle: "\u041C\u0435\u0442\u043A\u0438 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B" }),
        },
    ];
    return (React.createElement(Drawer, { title: title, width: 700, placement: "right", onClose: onClose, destroyOnClose: true, open: isOpen, bodyStyle: { paddingTop: 0 } },
        React.createElement(Spin, { spinning: loading },
            React.createElement(Tabs, { defaultActiveKey: "info", centered: true, items: drawerTabs, tabBarExtraContent: {
                    right: printButton(),
                } }))));
};
export default SkuInfoDrawer;
