import { Button, Checkbox, Tooltip } from "antd";
import React from "react";
import MDIcon from "../../../../../components/ui/MDIcon";
import { getColumnType } from "../../../../../helpers/function";
import { InfoCircleOutlined } from "@ant-design/icons";
import { links } from "../../../../../constants/links";
export const createColumns = () => {
    return [
        getColumnType("id", "#", true, {
            fixed: "left",
            width: "80px",
        }),
        getColumnType("address", "Ячейка", true, {
            fixed: "left",
        }),
        getColumnType("skuTotal", "Количество SKU", true, {
            fixed: "left",
            width: "80px",
        }),
        {
            title: "Проблемы",
            children: [
                getColumnType("problems", "не найдено", true, {
                    fixed: "left",
                    width: "80px",
                    render: (item) => item.notFound,
                }),
                getColumnType("problems", "не на месте", true, {
                    fixed: "left",
                    width: "80px",
                    render: (item) => item.notAtPlace,
                }),
                getColumnType("problems", "есть комментарий", true, {
                    fixed: "left",
                    width: "80px",
                    render: (item) => item.commented,
                }),
            ],
        },
    ];
};
export const createModalSkuColumns = () => {
    return [
        getColumnType("skuId", "#", true, {
            width: "60px",
        }),
        getColumnType("type", "Тип", true, {
            width: "60px",
        }),
        getColumnType("title", "Наименование", true, {
            width: "200px",
        }),
        getColumnType("quantity", "Количество", true, {
            width: "60px",
        }),
    ];
};
export const createSkuColumns = (onSkuInfoClick, setSelectedItems, selectedItems) => {
    const handleCheckboxChange = (item, checked) => {
        if (!!item.taskMovementId)
            return;
        if (checked) {
            setSelectedItems((prev) => [...prev, item]);
        }
        else {
            setSelectedItems((prev) => prev.filter((selectedItem) => selectedItem.key !== item.key));
        }
    };
    return [
        getColumnType("skuId", "#", true, {
            width: "80px",
        }),
        getColumnType("", "", true, {
            width: "80px",
            render: (item) => {
                var _a, _b, _c, _d;
                if (((_a = item === null || item === void 0 ? void 0 : item.storageCellActual) === null || _a === void 0 ? void 0 : _a.id) && ((_b = item === null || item === void 0 ? void 0 : item.storageCellExpected) === null || _b === void 0 ? void 0 : _b.id) && Number((_c = item === null || item === void 0 ? void 0 : item.storageCellExpected) === null || _c === void 0 ? void 0 : _c.title) !== Number((_d = item === null || item === void 0 ? void 0 : item.storageCellActual) === null || _d === void 0 ? void 0 : _d.title)) {
                    return React.createElement(Checkbox, { disabled: !!(item === null || item === void 0 ? void 0 : item.taskMovementId), onChange: (e) => handleCheckboxChange(item, e.target.checked), checked: selectedItems.some((selectedItem) => selectedItem.key === item.key) });
                }
                return React.createElement(React.Fragment, null);
            },
        }),
        getColumnType("type", "Тип", true, {
            width: "80px",
        }),
        getColumnType("title", "Наименование", true, {
            width: "240px",
        }),
        getColumnType("quantity", "Количество", true, {
            width: "80px",
        }),
        getColumnType("storageCellExpected", "Ожидаемая", true, {
            width: "80px",
            render: (item) => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.title) !== null && _a !== void 0 ? _a : ""; },
        }),
        getColumnType("storageCellActual", "Фактическая", true, {
            width: "80px",
            render: (item) => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.title) !== null && _a !== void 0 ? _a : ""; },
        }),
        getColumnType("comment", "Комментарий", true, {
            width: "80px",
        }),
        getColumnType("", "", true, {
            width: "80px",
            render: (item) => {
                if (!!(item === null || item === void 0 ? void 0 : item.taskMovementId)) {
                    const taskUrl = links.taskMovementLinks.page.url(item.taskMovementId);
                    return (React.createElement(Tooltip, { title: `Создана задача №${item.taskMovementId} на перемещение` },
                        React.createElement("a", { href: taskUrl, target: "_blank", rel: "noopener noreferrer" },
                            React.createElement(InfoCircleOutlined, { style: { color: "#1890ff" } }))));
                }
                return React.createElement(React.Fragment, null);
            },
        }),
        getColumnType("", "", true, {
            width: "80px",
            fixed: "right",
            render: (item) => {
                return (React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                    React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: () => {
                            onSkuInfoClick(item.skuId);
                        } })));
            },
        }),
    ];
};
