import { Select } from "antd";
import React from "react";
import ProductDataType from "../../constants/enum/ProductDataType";
import { getProductDataTypeName } from "../../helpers/function";
const ProductDataTypeSelect = (props) => (React.createElement(Select, Object.assign({}, props),
    React.createElement(Select.Option, { value: ProductDataType.VARCHAR }, getProductDataTypeName(ProductDataType.VARCHAR)),
    React.createElement(Select.Option, { value: ProductDataType.COLOR }, getProductDataTypeName(ProductDataType.COLOR)),
    React.createElement(Select.Option, { value: ProductDataType.INTEGER }, getProductDataTypeName(ProductDataType.INTEGER)),
    React.createElement(Select.Option, { value: ProductDataType.FLOAT }, getProductDataTypeName(ProductDataType.FLOAT)),
    React.createElement(Select.Option, { value: ProductDataType.BOOLEAN }, getProductDataTypeName(ProductDataType.BOOLEAN)),
    React.createElement(Select.Option, { value: ProductDataType.DATE }, getProductDataTypeName(ProductDataType.DATE)),
    React.createElement(Select.Option, { value: ProductDataType.DATE_TIME }, getProductDataTypeName(ProductDataType.DATE_TIME)),
    React.createElement(Select.Option, { value: ProductDataType.HANDBOOK }, getProductDataTypeName(ProductDataType.HANDBOOK)),
    React.createElement(Select.Option, { value: ProductDataType.FORMULA }, getProductDataTypeName(ProductDataType.FORMULA))));
export default ProductDataTypeSelect;
