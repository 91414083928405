import { Button, Dropdown, Space, Spin, } from 'antd';
import React, { useMemo, useState, } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TreeView from '../../components/TreeView/TreeView';
import TreeViewContectMenu from '../../components/TreeView/TreeViewContextMenu';
import Centered from '../../components/ui/Centered';
import MDIcon from '../../components/ui/MDIcon';
import { links } from '../../constants/links';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { productActions, selectProductSlice } from '../../redux/reducers/productReducer';
import productCategoryApi from '../../redux/api/productApi/productCategory';
import { TYPE_ID_PARAM } from '../pages/product/ProductsPage';
import { productTypeGraphqlApi } from '../../redux/api/productApi/productType';
const buildTreeData = (categories, types) => {
    var _a;
    const ROOT_ID = 'root';
    if (categories.length === 0) {
        return [];
    }
    const sortObject = categories.reduce((acc, item) => {
        var _a, _b, _c, _d, _e;
        const parentkey = (_a = item.parentId) !== null && _a !== void 0 ? _a : ROOT_ID;
        return Object.assign(Object.assign({}, acc), { [item.id]: Object.assign(Object.assign({}, item), { childCategoryIdList: (_c = (_b = acc[item.id]) === null || _b === void 0 ? void 0 : _b.childCategoryIdList) !== null && _c !== void 0 ? _c : [], childTypes: [] }), [parentkey]: Object.assign(Object.assign({}, acc[parentkey]), { childCategoryIdList: [...((_e = (_d = acc[parentkey]) === null || _d === void 0 ? void 0 : _d.childCategoryIdList) !== null && _e !== void 0 ? _e : []), item.id] }) });
    }, {});
    types.forEach((type) => {
        var _a, _b;
        (_b = (_a = sortObject[type.productCategoryId]) === null || _a === void 0 ? void 0 : _a.childTypes) === null || _b === void 0 ? void 0 : _b.push(type);
    });
    const adapter = (item, object) => {
        var _a, _b, _c, _d;
        return ({
            key: `category-${item.id}`,
            id: item.id,
            parentId: {
                label: object[(_a = item.parentId) !== null && _a !== void 0 ? _a : ROOT_ID].title,
                value: (_b = item.parentId) !== null && _b !== void 0 ? _b : ROOT_ID,
            },
            title: item.title,
            children: [
                ...((_d = (_c = item.childCategoryIdList) === null || _c === void 0 ? void 0 : _c.map((child) => adapter(object[child], object))) !== null && _d !== void 0 ? _d : []),
                ...item.childTypes.map((type) => ({
                    key: `type-${type.id}`,
                    id: type.id,
                    parentId: {
                        label: item.title,
                        value: item.id,
                    },
                    title: type.title,
                    isLeaf: true,
                    children: [],
                })),
            ],
        });
    };
    return (_a = sortObject[ROOT_ID]) === null || _a === void 0 ? void 0 : _a.childCategoryIdList.map((childId) => adapter(sortObject[childId], sortObject));
};
const { useFetchProductCategoryListQuery, useDeleteProductCategoryMutation, } = productCategoryApi;
const { useDeleteProductTypeMutation } = productTypeGraphqlApi;
const ProductTreeContainer = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [deleteProductCategory, { isLoading: deleteCatIsLoading },] = useDeleteProductCategoryMutation();
    const [deleteProductType, { isLoading: deleteTypeIsLoading },] = useDeleteProductTypeMutation();
    const { isLoading: listIsLoading, } = useFetchProductCategoryListQuery({ page: 1, pageSize: 1000 });
    const [searchParams, setSearchParams] = useSearchParams();
    const isLoading = deleteCatIsLoading || deleteTypeIsLoading || listIsLoading;
    const { categories, types } = useTypedSelector(selectProductSlice);
    const [selectNode, setSelectNode] = useState(null);
    const treeData = useMemo(() => ([{
            id: null,
            key: '',
            title: 'Продукция',
            children: buildTreeData(categories.list, types.list),
        }]), [categories.list, types.list]);
    const handlerLoadItem = (id) => (new Promise((resolve) => {
        if (id === null) {
            resolve();
            return;
        }
        dispatch(productCategoryApi.endpoints.fetchProductCategory.initiate(id))
            .unwrap()
            .then(() => resolve());
    }));
    const handlerSelect = (node) => {
        setSelectNode((node === null || node === void 0 ? void 0 : node.id) ? node : null);
        if (node.isLeaf) {
            searchParams.set(TYPE_ID_PARAM, node.id.toString());
            setSearchParams(searchParams);
        }
    };
    const handlerAdd = (parent, isCategory) => {
        if (isCategory) {
            dispatch(productActions.changeCategoryModalVisible({
                visible: true, initialValues: { parentId: parent },
            }));
        }
        else {
            navigate(links.handbookProductAddTypeLink.url(parent.value.toString()));
        }
    };
    const handlerEdit = (id, isCategory) => {
        var _a;
        if (isCategory) {
            const category = categories.list.find((item) => item.id === id);
            const parent = categories.list.find((item) => item.id === (category === null || category === void 0 ? void 0 : category.parentId));
            const initialValues = {
                id: category === null || category === void 0 ? void 0 : category.id,
                parentId: {
                    label: parent === null || parent === void 0 ? void 0 : parent.title,
                    value: (_a = parent === null || parent === void 0 ? void 0 : parent.id) !== null && _a !== void 0 ? _a : '',
                },
                title: category === null || category === void 0 ? void 0 : category.title,
            };
            dispatch(productActions.changeCategoryModalVisible({
                visible: true, initialValues,
            }));
        }
        else {
            navigate(links.handbookProductEditTypeLink.url(id));
        }
    };
    const handlerDelete = (id, isCategory) => {
        if (isCategory) {
            deleteProductCategory(id);
        }
        else {
            deleteProductType({
                input: {
                    productTypeId: id
                }
            });
            const typeParam = searchParams.get(TYPE_ID_PARAM);
            const typeId = typeParam ? +typeParam : null;
            if (typeId === id) {
                searchParams.delete(TYPE_ID_PARAM);
                setSearchParams(searchParams);
            }
        }
    };
    if (isLoading && categories.list.length === 0) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { spinning: true })));
    }
    const menu = TreeViewContectMenu({
        currentNode: selectNode,
        onAdd: handlerAdd,
        onEdit: handlerEdit,
        onDelete: handlerDelete,
        foldersName: "Категорию продукции",
        itemsName: "Тип продукции"
    });
    const contextMenu = (currentNode) => {
        const node = (currentNode === null || currentNode === void 0 ? void 0 : currentNode.id) ? currentNode : null;
        return TreeViewContectMenu({
            currentNode: node,
            onAdd: handlerAdd,
            onEdit: handlerEdit,
            onDelete: handlerDelete,
            foldersName: "Категорию продукции",
            itemsName: "Тип продукции"
        });
    };
    return (React.createElement("div", { style: {
            padding: 15, height: '100%', display: 'flex', flexDirection: 'column', gap: 10,
        } },
        React.createElement(TreeView, { style: {
                flexGrow: 1, overflow: 'auto', marginRight: -14, paddingRight: 14,
            }, defaultExpandedKeys: [''], onLoadFolder: handlerLoadItem, onSelect: handlerSelect, emptyText: (React.createElement(Space, { direction: "vertical", align: "center" },
                React.createElement("div", null, "\u0421\u043F\u0438\u0441\u043E\u043A \u0442\u0438\u043F\u043E\u0432 \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438 \u043F\u0443\u0441\u0442"),
                React.createElement(Button, { type: "primary" }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"))), data: treeData, contexMenu: contextMenu }),
        React.createElement(Dropdown, { menu: menu, trigger: ['click'] },
            React.createElement(Button, { block: true, icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }) }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C"))));
};
export default ProductTreeContainer;
