import { Col, Row, Spin, Statistic, } from 'antd';
import React from 'react';
const ReportTotal = (props) => {
    const { isLoading, sCount, tlCount, tsCount, tlQuantity, tsQuantity, } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gutter: 16, justify: "space-around" },
            React.createElement(Col, { key: 0 },
                React.createElement(Spin, { spinning: isLoading },
                    React.createElement(Statistic, { groupSeparator: ' ', title: "\u041F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u044F", value: sCount, prefix: React.createElement("i", { className: "mdi mdi-package-variant-closed" }) }))),
            React.createElement(Col, { key: 1 },
                React.createElement(Spin, { spinning: isLoading },
                    React.createElement(Statistic, { groupSeparator: ' ', title: "\u0417\u0430\u0434\u0430\u0447\u0438 \u043D\u0430 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0443", value: tlCount, prefix: React.createElement("i", { className: "mdi mdi-upload" }) }))),
            React.createElement(Col, { key: 2 },
                React.createElement(Spin, { spinning: isLoading },
                    React.createElement(Statistic, { groupSeparator: ' ', title: "\u0417\u0430\u0434\u0430\u0447\u0438 \u043D\u0430 \u043E\u0442\u0433\u0440\u0443\u0437\u043A\u0443", value: tsCount, prefix: React.createElement("i", { className: "mdi mdi-download" }) }))),
            React.createElement(Col, { key: 3 },
                React.createElement(Spin, { spinning: isLoading },
                    React.createElement(Statistic, { groupSeparator: ' ', title: "\u041F\u0440\u0438\u0445\u043E\u0434", valueStyle: { color: 'var(--green)' }, value: tlQuantity, prefix: React.createElement("i", { className: "mdi mdi-package-variant-closed" }) }))),
            React.createElement(Col, { key: 4 },
                React.createElement(Spin, { spinning: isLoading },
                    React.createElement(Statistic, { groupSeparator: ' ', title: "\u0420\u0430\u0441\u0445\u043E\u0434", valueStyle: { color: 'var(--red)' }, value: tsQuantity, prefix: React.createElement("i", { className: "mdi mdi-package-variant-closed" }) }))))));
};
export default ReportTotal;
