import React, { useContext } from "react";
import { WarehouseTopologyContext } from "./WarehouseTopology";
import { Button, Space } from "antd";
import { RedoOutlined, UndoOutlined } from "@ant-design/icons";
export const Controls = ({}) => {
    const context = useContext(WarehouseTopologyContext);
    if (context === undefined) {
        throw new Error("Drawer must be used within a WarehouseTopology");
    }
    return (React.createElement(Space, { style: { position: "absolute", top: 200, right: 33, zIndex: 99, width: "fit-content" } },
        React.createElement(Button, { disabled: !context.canUndo, onClick: context.handleUndo, icon: React.createElement(UndoOutlined, null) }),
        React.createElement(Button, { disabled: !context.canRedo, onClick: context.handleRedo, icon: React.createElement(RedoOutlined, null) })));
};
