import { configureStore } from "@reduxjs/toolkit";
import { persistState } from "@redux-devtools/core";
import { useDispatch } from "react-redux";
import { clientsApi } from "../api/clientsApi";
import { commentApi } from "../api/commentsApi";
import handbookUnitApi from "../api/handbookApi/handbookUnit";
import productApi from "../api/productApi";
import statusConfiguratorStatusApi from "../api/statusConfiguratorApi";
import warehouseApi from "../api/warehouseApi";
import { rtkQueryErrorNotify } from "../middlewares";
import reducer from "../reducers";
import { productTypeGraphqlApi } from "../api/productApi/productType";
function getDebugSessionKey() {
    const matches = window.location.href.match(/[?&]debug_session=([^&#]+)\b/);
    return (matches && matches[1]) || "defaultSession";
}
const store = configureStore({
    reducer: reducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(clientsApi.middleware).concat(commentApi.middleware).concat(handbookUnitApi.middleware).concat(productApi.middleware).concat(productTypeGraphqlApi.middleware).concat(statusConfiguratorStatusApi.middleware).concat(warehouseApi.middleware).concat(rtkQueryErrorNotify),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(persistState(getDebugSessionKey())),
});
export const useAppDispatch = useDispatch;
export default store;
