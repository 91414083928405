import React, { useMemo } from "react";
import { Table } from "antd";
import { createColumns } from "./Columns";
import { uniqueId } from "lodash";
const TableContainer = ({ skuGorup }) => {
    const columns = useMemo(() => createColumns(), []);
    const skuGorupWithId = skuGorup.map(el => (Object.assign(Object.assign({}, el), { id: uniqueId() })));
    return React.createElement(Table, { rowKey: "id", style: { whiteSpace: "nowrap" }, columns: columns, dataSource: skuGorupWithId, bordered: true, pagination: false, size: "small", scroll: { x: true } });
};
export default TableContainer;
