import { mapValues, pick } from "lodash";
import React, { useEffect, useState } from "react";
import { COMMON_ATTRIBUTES, PERSONAL_ATTRIBUTES, PRODUCT_ID } from "../../../components/nomenclatureSearch/ProductFilter/ProductFilter";
import { SkuStatus } from "../../../constants/enum/SkuStatus";
import { useSku } from "../../../hooks/api/sku/useSku";
import NomenclaturePage from "../../pages/NomenclaturePage/NomenclaturePage";
import { useSkuAdapter } from "../../pages/NomenclaturePage/hooks/useSkuAdapter";
const WAREHOUSE = "warehouses";
const STATUS = "statuses";
const CLIENTS = "clients";
const STORAGE_CELLS = "storageCells";
const productFilterValuesToRequest = (values) => {
    const result = {
        productTypeId: values[PRODUCT_ID],
        storageCells: values[STORAGE_CELLS],
        [CLIENTS]: values[CLIENTS] ? values[CLIENTS].map((item) => item.value) : [],
        [WAREHOUSE]: values[WAREHOUSE] ? values[WAREHOUSE].map((item) => item.value) : [],
        [STATUS]: values[STATUS] || [SkuStatus.FREE],
    };
    const convertAttributes = (attributes) => {
        if (attributes) {
            const filledAttributeKeys = Object.keys(attributes).filter((key) => !!attributes[key]);
            if (filledAttributeKeys.length > 0) {
                const filledAttributes = mapValues(pick(attributes, filledAttributeKeys), (item) => item);
                return filledAttributes;
            }
        }
        return undefined;
    };
    result.productAttributes = convertAttributes(values[COMMON_ATTRIBUTES]);
    result.skuAttributes = convertAttributes(values[PERSONAL_ATTRIBUTES]);
    return result;
};
const defaultPagination = { page: 1, itemsTotalCount: 0, itemsOnPage: 20, itemsCurrentCount: 0 };
const NomenclaturePageContainer = () => {
    const { adaptProductFilterValuesToSkuFilterInput } = useSkuAdapter();
    const [filter, setFilter] = useState({});
    const { skuList } = useSku();
    const [pagination, setPagination] = useState(defaultPagination);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        setPagination({
            itemsCurrentCount: (_b = (_a = skuList === null || skuList === void 0 ? void 0 : skuList.pagination) === null || _a === void 0 ? void 0 : _a.itemsCurrentCount) !== null && _b !== void 0 ? _b : 0,
            itemsOnPage: (_d = (_c = skuList === null || skuList === void 0 ? void 0 : skuList.pagination) === null || _c === void 0 ? void 0 : _c.itemsOnPage) !== null && _d !== void 0 ? _d : 0,
            itemsTotalCount: (_f = (_e = skuList === null || skuList === void 0 ? void 0 : skuList.pagination) === null || _e === void 0 ? void 0 : _e.itemsTotalCount) !== null && _f !== void 0 ? _f : 0,
            page: (_h = (_g = skuList === null || skuList === void 0 ? void 0 : skuList.pagination) === null || _g === void 0 ? void 0 : _g.page) !== null && _h !== void 0 ? _h : 0,
        });
    }, [JSON.stringify(skuList === null || skuList === void 0 ? void 0 : skuList.data)]);
    useEffect(() => {
        skuList.paginationFilteredGetTaskList(adaptProductFilterValuesToSkuFilterInput(filter), pagination === null || pagination === void 0 ? void 0 : pagination.itemsOnPage, pagination === null || pagination === void 0 ? void 0 : pagination.page);
    }, []);
    return (React.createElement(NomenclaturePage, { adaptProductFilterValuesToSkuFilterInput: adaptProductFilterValuesToSkuFilterInput, productFilterValuesToRequest: productFilterValuesToRequest, filter: filter, setFilter: setFilter, defaultPagination: defaultPagination, fetchSku: skuList.paginationFilteredGetTaskList, isLoading: skuList.loading, items: skuList.data, pagination: skuList.pagination }));
};
export default NomenclaturePageContainer;
