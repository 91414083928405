var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getUrlWithParams } from '../../../helpers/function';
import { request } from '../api';
import dtoToEntity from '../adapters/dtoToEntity';
export const getTimezoneList = (query, handlerState) => __awaiter(void 0, void 0, void 0, function* () {
    const url = getUrlWithParams('system/timezone', {
        query,
    });
    const { data } = yield request(handlerState)
        .then(instance => instance.get(url));
    return data.map(dtoToEntity.timeZoneAdapter);
});
