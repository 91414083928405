var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
const ButtonLink = (props) => {
    const { to } = props, buttonProps = __rest(props, ["to"]);
    const navigation = useNavigate();
    return (React.createElement(Button, Object.assign({}, buttonProps, { onClick: (event) => {
            event.preventDefault();
            navigation(to);
        }, href: to }), buttonProps.children));
};
export default ButtonLink;
