import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { PRODUCT_TYPE_TAG } from "..";
import { ProductTypeAttributeCreateDocument, ProductTypeAttributeDeleteDocument, ProductTypeAttributeEditDocument, ProductTypeAttributeSortCategoriesDocument, ProductTypeAttributeSortOrderDocument, ProductTypeAttributeSortPositionDocument, ProductTypeCategoryCreateDocument, ProductTypeCategoryDeleteDocument, ProductTypeCategoryEditDocument, ProductTypeCreateDocument, ProductTypeDeleteDocument, ProductTypeEditDocument, } from "../../../../api/graphql/queries/productType/ProductTypeMutation";
import { ProductTypeDocument } from "../../../../api/graphql/queries/productType/ProductTypeQuery";
import dtoToEntity from "../../../../api/rest/adapters/dtoToEntity";
import { AuthService } from "../../../../services/AuthService";
export const productTypeGraphqlApi = createApi({
    tagTypes: [PRODUCT_TYPE_TAG],
    baseQuery: graphqlRequestBaseQuery({
        url: `${window.env.ApiHost}/graphql`,
        prepareHeaders: (headers, { getState }) => {
            const token = AuthService.getAccessTokenFromStorage();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            else {
                // use refresh token or navigate to login
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchProductType: builder.query({
            query: ({ productTypeId }) => ({
                document: ProductTypeDocument,
                variables: { productTypeId },
            }),
            transformResponse: (response) => dtoToEntity.productTypeDtoAdapter(response === null || response === void 0 ? void 0 : response.productType),
            providesTags: (_, __, queryProductTypeArgs) => [PRODUCT_TYPE_TAG, { type: PRODUCT_TYPE_TAG, id: queryProductTypeArgs.productTypeId }],
        }),
        addProductType: builder.mutation({
            query: (values) => ({
                document: ProductTypeCreateDocument,
                variables: values,
            }),
            transformResponse: (response) => dtoToEntity.productTypeViewGraphqlAdapter(response),
            invalidatesTags: [PRODUCT_TYPE_TAG],
        }),
        editProductType: builder.mutation({
            query: (values) => ({
                document: ProductTypeEditDocument,
                variables: values,
            }),
            transformResponse: (response) => dtoToEntity.productTypeViewGraphqlAdapter(response),
            invalidatesTags: (result, error, { productTypeId }) => [PRODUCT_TYPE_TAG, { type: PRODUCT_TYPE_TAG, id: productTypeId }],
        }),
        deleteProductType: builder.mutation({
            query: (values) => ({
                document: ProductTypeDeleteDocument,
                variables: values,
            }),
            transformResponse: (_, __, res) => res.input.productTypeId,
            invalidatesTags: (_, __, res) => [PRODUCT_TYPE_TAG, { type: PRODUCT_TYPE_TAG, id: res.input.productTypeId }],
        }),
        addProductTypeAttribute: builder.mutation({
            query: (values) => ({
                document: ProductTypeAttributeCreateDocument,
                variables: values,
            }),
            transformResponse: (response) => dtoToEntity.adaptToProductTypeAttributeGraphqlEntity(response),
            invalidatesTags: [PRODUCT_TYPE_TAG],
        }),
        editProductTypeAttribute: builder.mutation({
            query: (values) => ({
                document: ProductTypeAttributeEditDocument,
                variables: values,
            }),
            transformResponse: (response) => dtoToEntity.adaptToProductTypeAttributeGraphqlEntity(response),
            invalidatesTags: [PRODUCT_TYPE_TAG],
        }),
        deleteProductTypeAttribute: builder.mutation({
            query: (values) => ({
                document: ProductTypeAttributeDeleteDocument,
                variables: values,
            }),
            transformResponse: (_, __, res) => res.input.productTypeAttributeId,
            invalidatesTags: [PRODUCT_TYPE_TAG],
        }),
        addProductTypeCategory: builder.mutation({
            query: (values) => ({
                document: ProductTypeCategoryCreateDocument,
                variables: values,
            }),
            transformResponse: (response) => dtoToEntity.productTypeCategoryGraphqlMutationResultAdapter(response),
            invalidatesTags: [PRODUCT_TYPE_TAG],
        }),
        editProductTypeCategory: builder.mutation({
            query: (values) => ({
                document: ProductTypeCategoryEditDocument,
                variables: values,
            }),
            transformResponse: (response) => dtoToEntity.productTypeCategoryGraphqlMutationResultAdapter(response),
            invalidatesTags: [PRODUCT_TYPE_TAG],
        }),
        deleteProductTypeCategory: builder.mutation({
            query: (values) => ({
                document: ProductTypeCategoryDeleteDocument,
                variables: values,
            }),
            transformResponse: (_, __, res) => res.input.productTypeCategoryId,
            invalidatesTags: [PRODUCT_TYPE_TAG],
        }),
        editProductTypeOrder: builder.mutation({
            query: (values) => ({
                document: ProductTypeAttributeSortOrderDocument,
                variables: values,
            }),
            transformResponse: (response) => response.productTypeAttributeSortOrder.productTypeDto,
        }),
        sortProductTypeAttributes: builder.mutation({
            query: (values) => ({
                document: ProductTypeAttributeSortPositionDocument,
                variables: values,
            }),
            transformResponse: (response) => response.productTypeAttributeSortPosition.productTypeDto,
            invalidatesTags: (result, error, { productTypeId }) => [PRODUCT_TYPE_TAG, { type: PRODUCT_TYPE_TAG, id: productTypeId }],
        }),
        sortProductTypeAttrCategories: builder.mutation({
            query: (values) => ({
                document: ProductTypeAttributeSortCategoriesDocument,
                variables: values,
            }),
            transformResponse: (response) => response.productTypeAttributeSortCategories.productTypeDto,
            invalidatesTags: (args) => [PRODUCT_TYPE_TAG, { type: PRODUCT_TYPE_TAG, id: args === null || args === void 0 ? void 0 : args.id }],
        }),
    }),
});
