import React from 'react';
import classNames from 'classnames';
const MDIcon = (props) => {
    const { color, icon, style, onClick, className, } = props;
    const iconName = icon.substr(0, 4) === 'mdi-' ? icon.substr(4) : icon;
    const classes = classNames({
        mdi: true,
        'md-icon': true,
        [`mdi-${iconName}`]: !!iconName,
    });
    return (React.createElement("span", { className: [classes, className].join(' '), style: Object.assign(Object.assign({}, style), { color }), onClick: onClick }));
};
export default MDIcon;
