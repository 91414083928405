import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import handbookApi from '../../redux/api/handbookApi';
import { HANDBOOK_PARAM } from '../pages/HandbookPage';
const { useFetchHandbookQuery } = handbookApi;
const HandbookHeaderContainer = () => {
    const [searchParams] = useSearchParams();
    const handbookParam = searchParams.get(HANDBOOK_PARAM);
    const handbookId = handbookParam ? +handbookParam : null;
    const { data, } = useFetchHandbookQuery(handbookId, { skip: !Number.isInteger(handbookId) });
    return (React.createElement(Row, null,
        React.createElement(Col, { flex: 1 },
            React.createElement(Typography.Title, { className: "m-0", level: 5 }, data ? `Справочник: ${data.title}` : 'Справочник')),
        React.createElement(Col, null)));
};
export default HandbookHeaderContainer;
