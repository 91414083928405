import { Card, Col, DatePicker, Divider, Form, Input, Row, Typography } from "antd";
import React from "react";
import { AddChangeWarehouseFieldName } from "../../../../../api/rest/dto/tasks/changeWarehouse/types";
import ClientSelect from "../../../../../components/ClientSelect";
import WarehouseSelect from "../../../../../components/WarehouseSelect";
const { Title } = Typography;
const formGutter = 15;
const WarehouseSection = ({ disableWarehouse = false }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Col, { span: 8 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u0420\u0435\u043A\u0432\u0438\u0437\u0438\u0442\u044B"),
                React.createElement(Divider, null),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0421\u043A\u043B\u0430\u0434", name: AddChangeWarehouseFieldName.WAREHOUSE, rules: [{ required: true }] },
                            React.createElement(WarehouseSelect, { disabled: disableWarehouse, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434" }))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u041F\u043B\u0430\u0442\u0435\u043B\u044C\u0449\u0438\u043A", name: AddChangeWarehouseFieldName.PAYER },
                            React.createElement(ClientSelect, { allowClear: true, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" })))),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0413\u0440\u0443\u0437\u043E\u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u0435\u043B\u044C", name: AddChangeWarehouseFieldName.SHIPPER },
                            React.createElement(ClientSelect, { allowClear: true, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" }))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0413\u0440\u0443\u0437\u043E\u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044C", name: AddChangeWarehouseFieldName.CONSIGNEE },
                            React.createElement(ClientSelect, { allowClear: true, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" })))),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u041F\u0435\u0440\u0435\u0432\u043E\u0437\u0447\u0438\u043A", name: AddChangeWarehouseFieldName.CARRIER },
                            React.createElement(ClientSelect, { allowClear: true, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" })))))),
        React.createElement(Col, { span: 8 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u041E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0435\u043D\u043D\u044B\u0435"),
                React.createElement(Divider, null),
                React.createElement(Form.Item, { label: "\u041E\u0442\u043F\u0443\u0441\u043A \u0440\u0430\u0437\u0440\u0435\u0448\u0438\u043B" },
                    React.createElement(Row, { gutter: formGutter },
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddChangeWarehouseFieldName.DEPARTURE_ALLOWED_NAME },
                                React.createElement(Input, { placeholder: "\u0424\u0418\u041E" }))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddChangeWarehouseFieldName.DEPARTURE_ALLOWED_POST },
                                React.createElement(Input, { placeholder: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C" }))))),
                React.createElement(Form.Item, { label: "\u041E\u0442\u043F\u0443\u0441\u043A \u043F\u0440\u043E\u0438\u0437\u0432\u0435\u043B" },
                    React.createElement(Row, { gutter: formGutter },
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddChangeWarehouseFieldName.DEPARTURE_PRODUCED_NAME },
                                React.createElement(Input, { placeholder: "\u0424\u0418\u041E" }))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddChangeWarehouseFieldName.DEPARTURE_PRODUCED_POST },
                                React.createElement(Input, { placeholder: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C" }))))))),
        React.createElement(Col, { span: 8 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u0412\u043E\u0434\u0438\u0442\u0435\u043B\u044C"),
                React.createElement(Divider, null),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0424\u0418\u041E", name: AddChangeWarehouseFieldName.DEPARTURE_DRIVER_NAME },
                            React.createElement(Input, null))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u041D\u043E\u043C\u0435\u0440 \u0442/\u0441", name: AddChangeWarehouseFieldName.DEPARTURE_VEHICLE_NUMBER },
                            React.createElement(Input, null)))),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0414\u043E\u0432\u0435\u0440\u0435\u043D\u043D\u043E\u0441\u0442\u044C", name: AddChangeWarehouseFieldName.DEPARTURE_POWER_OF_ATTORNEY },
                            React.createElement(Input, null))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0414\u0430\u0442\u0430 \u0434\u043E\u0432\u0435\u0440\u0435\u043D\u043D\u043E\u0441\u0442\u0438", name: AddChangeWarehouseFieldName.DEPARTURE_POWER_OF_ATTORNEY_DATE },
                            React.createElement(DatePicker, { style: { width: "100%" } }))))))));
};
export default WarehouseSection;
