var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Input, Typography } from "antd";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { DataType } from "../../api/graphql/common";
import { useProductTypeLazyQuery } from "../../api/graphql/queries/productType/ProductTypeQuery";
import FormulaMaskInput from "./FormulaMaskInput";
const { Text } = Typography;
const PRODUCT_TYPE_ATTRIBUTE_TITLE = "Характеристики";
const filterAvailableProductTypes = (attr) => {
    return attr.filter((item) => [DataType.Integer, DataType.Float].includes(item.dataType));
};
const filterNonPersonalProductTypes = (attr) => {
    return attr.filter((item) => !item.isPersonal);
};
export const FormulaMaskInputContainer = ({ title, isPersonal, initValue, isPersonalUpdated, onChange }) => {
    var _a;
    const { typeId } = useParams();
    const [getProductType, { data, loading, error }] = useProductTypeLazyQuery();
    useEffect(() => {
        const fetchProductType = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!!typeId) {
                yield getProductType({ variables: { productTypeId: +typeId } });
            }
        });
        fetchProductType();
    }, [typeId]);
    const productTypeAttributes = useMemo(() => {
        var _a, _b;
        const prop = (_b = (_a = data === null || data === void 0 ? void 0 : data.productType) === null || _a === void 0 ? void 0 : _a.productTypeCategories) === null || _b === void 0 ? void 0 : _b.find((item) => (item === null || item === void 0 ? void 0 : item.title) === PRODUCT_TYPE_ATTRIBUTE_TITLE);
        if (prop) {
            if (isPersonal)
                return filterAvailableProductTypes(prop.productTypeAttributes);
            return filterNonPersonalProductTypes(filterAvailableProductTypes(prop.productTypeAttributes));
        }
        return [];
    }, [JSON.stringify((_a = data === null || data === void 0 ? void 0 : data.productType) === null || _a === void 0 ? void 0 : _a.productTypeCategories), isPersonal]);
    if (loading) {
        return React.createElement(FormulaMaskLoadingInput, null);
    }
    if (!!error) {
        return React.createElement(FormulaMaskErrorInput, null);
    }
    return React.createElement(FormulaMaskInput, { isPersonalUpdated: isPersonalUpdated, isPersonal: isPersonal, productTypeAttributes: productTypeAttributes, initValue: initValue, title: title, onChange: onChange });
};
export const FormulaMaskLoadingInput = ({}) => {
    return React.createElement(Input, { disabled: true, placeholder: "\u041F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0435 \u0434\u0430\u043D\u043D\u044B\u0445" });
};
export const FormulaMaskErrorInput = ({}) => {
    return React.createElement(Text, { type: "warning" }, "\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u044F \u0434\u0430\u043D\u043D\u044B\u0445");
};
export const filterNonFormylaTypeInput = (input) => {
    return input.filter((attr) => attr.dataType !== DataType.Formula);
};
export const filterReadOnlyInput = (input) => {
    return input.filter((attr) => !attr.isReadOnly);
};
export const checkDataTypeIsNotFormula = (dataType) => {
    return (dataType === null || dataType === void 0 ? void 0 : dataType.toLocaleUpperCase()) !== DataType.Formula;
};
