import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import ChangeWarehouseEditTaskHeaderContainer from "../../../containers/task/changeWarehouse/ChangeWarehouseEditTaskHeaderContainer";
import ChangeWarehouseTaskFormNomenclatureContainer from "../../../containers/task/changeWarehouse/ChangeWarehouseTaskFormNomenclatureContainer";
import ProductInfoDrawerContainer from "../../../containers/ProductInfoDrawerContainer";
import { useTaskChangeWarehouse } from "../../../../hooks/api/taskChangeWarehouseApi/useTaskChangeWarehouse";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
export const ChangeWarehouseContext = React.createContext(null);
const ChangeWarehouseProvider = ChangeWarehouseContext.Provider;
const ChangeWarehouseEditPage = () => {
    const params = useParams();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const { task } = useTaskChangeWarehouse();
    const isLocked = useMemo(() => taskHelpers.hasExternalId(task === null || task === void 0 ? void 0 : task.data), [task === null || task === void 0 ? void 0 : task.data]);
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: React.createElement(ChangeWarehouseEditTaskHeaderContainer, { id: taskId, task: task === null || task === void 0 ? void 0 : task.data, isLoading: task === null || task === void 0 ? void 0 : task.loading }),
        toolbarRight: null,
    };
    return (React.createElement(ChangeWarehouseProvider, { value: { isLocked } },
        React.createElement(Page, Object.assign({}, pageProps),
            React.createElement(ChangeWarehouseTaskFormNomenclatureContainer, { refetch: task === null || task === void 0 ? void 0 : task.refetch, taskId: taskId, task: task === null || task === void 0 ? void 0 : task.data, isLoading: task === null || task === void 0 ? void 0 : task.loading }),
            React.createElement(SkuInfoDrawerContainer, null),
            React.createElement(ProductInfoDrawerContainer, null))));
};
export default ChangeWarehouseEditPage;
