import { Empty, List, Spin } from 'antd';
import React from 'react';
import Centered from '../ui/Centered';
import Comments from './Comments';
const CommentsList = ({ comments, loading }) => {
    if (comments != null && comments.length > 0) {
        return (React.createElement(List, { dataSource: comments, itemLayout: "vertical", renderItem: (comment) => React.createElement(Comments.Item, { comment: comment }) }));
    }
    if (loading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { size: "large" })));
    }
    return (React.createElement(Centered, null,
        React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: "\u0421\u043F\u0438\u0441\u043E\u043A \u043A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0435\u0432 \u043F\u0443\u0441\u0442" })));
};
export default CommentsList;
