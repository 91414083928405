import { getColumnType } from "../../../../../../helpers/function";
import { Button, Tooltip } from "antd";
import MDIcon from "../../../../../../components/ui/MDIcon";
import React from "react";
const onCell = (record) => ({});
export const createColumns = (handlerOpenSkuInfo) => {
    // export const createColumns = (): ColumnsType<DataType> => {
    return [
        getColumnType("skuId", "#", false, {
            onCell,
            width: "80px",
        }),
        getColumnType("type", "Тип", false, {
            onCell,
        }),
        getColumnType("title", "Номенклатура", false, {
            onCell,
        }),
        getColumnType("reason", "Причина", false, {
            onCell,
        }),
        getColumnType("", "", false, {
            onCell,
            width: "30px",
            render(_, record) {
                return (React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                    React.createElement(Button, { size: "small", type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: () => {
                            record.skuId && handlerOpenSkuInfo(`${record.skuId}`);
                        } })));
            },
        }),
    ];
};
