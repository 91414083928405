var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Spin } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HandbookWarehouse from "../../components/HandbookWarehouse";
import { links } from "../../constants/links";
import { adaptToHandbookWarehouseFormValues, useHandbookWarehousesActions } from "../pages/handbookWarehouses/HandbookWarehousesPage/useHandbookWarehousesPage";
const HandbookWarehouseContainer = (props) => {
    var _a;
    const { id } = props;
    const warehouseId = id ? +id : null;
    const { editWarehouseType, createWarehouseType, deleteWarehouseType, getWarehouseType } = useHandbookWarehousesActions();
    const [initValues, setInitValues] = React.useState(undefined);
    useEffect(() => {
        if (warehouseId && Number.isInteger(warehouseId))
            getWarehouseType.handleAction({ variables: { warehouseId: warehouseId } }).then((res) => {
                var _a;
                if ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.warehouse) {
                    setInitValues(res.data.warehouse);
                }
            });
    }, [warehouseId]);
    const navigate = useNavigate();
    const handlerSubmit = (formValues) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        if (Number.isInteger(warehouseId)) {
            const values = Object.assign(Object.assign({}, formValues), { geoLongitude: parseFloat(formValues.geoLongitude.replace(",", ".")), geoLatitude: parseFloat(formValues.geoLatitude.replace(",", ".")), timeZoneId: formValues.timeZoneId.value });
            yield editWarehouseType.handleAction(Object.assign({ id: warehouseId }, values));
            const updatedData = yield ((_b = getWarehouseType === null || getWarehouseType === void 0 ? void 0 : getWarehouseType.responce) === null || _b === void 0 ? void 0 : _b.refetch());
            setInitValues((_c = updatedData === null || updatedData === void 0 ? void 0 : updatedData.data) === null || _c === void 0 ? void 0 : _c.warehouse);
        }
        else {
            const values = Object.assign(Object.assign({}, formValues), { geoLongitude: parseFloat(formValues.geoLongitude.replace(",", ".")), geoLatitude: parseFloat(formValues.geoLatitude.replace(",", ".")), timeZoneId: formValues.timeZoneId.value });
            createWarehouseType.handleAction(values).then((res) => {
                var _a, _b, _c;
                navigate(links.handbookWarehouseInfoLink.url((_c = (_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.warehouseCreate) === null || _b === void 0 ? void 0 : _b.warehouseDto) === null || _c === void 0 ? void 0 : _c.id));
            });
        }
    });
    const handlerDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        if (Number.isInteger(warehouseId)) {
            yield deleteWarehouseType.handleAction({ warehouseId: warehouseId });
            navigate(links.handbookWarehouseListLink.url());
        }
    });
    const isLoading = (editWarehouseType === null || editWarehouseType === void 0 ? void 0 : editWarehouseType.isLoading) || (createWarehouseType === null || createWarehouseType === void 0 ? void 0 : createWarehouseType.isLoading) || (deleteWarehouseType === null || deleteWarehouseType === void 0 ? void 0 : deleteWarehouseType.isLoading) || ((_a = getWarehouseType === null || getWarehouseType === void 0 ? void 0 : getWarehouseType.responce) === null || _a === void 0 ? void 0 : _a.loading);
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(HandbookWarehouse, { onSubmit: handlerSubmit, onDelete: handlerDelete, deletable: !!id, initialValues: adaptToHandbookWarehouseFormValues(initValues) })));
};
export default HandbookWarehouseContainer;
