var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../../components/Page";
import { links } from "../../../../constants/links";
import { useTaskMovementCRUDActions } from "../../../../hooks/api/taskMovementApi/useTaskMovementCRUDActions";
import MovementTaskForm from "../../../conponents/task/movement/MovementTaskForm";
const MovementAddPage = () => {
    const navigate = useNavigate();
    const { create, isLoading } = useTaskMovementCRUDActions();
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: null,
        toolbarRight: null,
    };
    const handlerSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const response = yield create({ request: values });
        if (!response.errors) {
            message.success(`Задача ${(_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.taskMovementAdd.taskMovementResponse) === null || _b === void 0 ? void 0 : _b.id} успешно сохранена`);
            navigate(links.taskMovementLinks.edit.url((_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.taskMovementAdd.taskMovementResponse) === null || _d === void 0 ? void 0 : _d.id));
        }
    });
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(MovementTaskForm, { isLoading: isLoading, onSubmit: handlerSubmit })));
};
export default MovementAddPage;
