import React, { createContext, useContext, useEffect, useReducer } from "react";
import { availabilityOnDateReducer } from "./reducers";
import { AvailabilityOnDateActionType } from "./state";
import { useAvailabilityOnDateQueryLazyQuery } from "../../../../api/graphql/queries/report/AvailabilityOnDateQuery";
import { defaultValues } from "../components/AvailabilityOnDateFilter";
const initialState = {
    page: 1,
    pageSize: 20,
    loading: false,
    filterFormState: defaultValues,
    items: [],
    total: {
        arrivalCount: 0,
        arrivalInRageCount: 0,
        arrivalQuantity: 0,
        departureCount: 0,
        departureInRageCount: 0,
        departureQuantity: 0,
        quantityBeginning: 0,
        quantityEnd: 0,
        totalCount: 0,
        totalQuantity: 0,
    }
};
export const AvailabilityOnDateContext = createContext(initialState);
export const AvailabilityOnDateDispatchContext = createContext(() => null);
export const AvailabilityOnDateProvider = ({ children }) => {
    var _a, _b;
    const [state, dispatch] = useReducer(availabilityOnDateReducer, initialState);
    const [getAvailabilityOnDate, { loading: reportIsLoading, data: reportData },] = useAvailabilityOnDateQueryLazyQuery({
        fetchPolicy: 'no-cache',
        variables: {
            page: initialState.page,
            pageSize: initialState.pageSize,
            input: {
                warehouseId: (_a = initialState.filterFormState.warehouseId) !== null && _a !== void 0 ? _a : 0,
                date: initialState.filterFormState.date.format("YYYY-MM-DD"),
                storageCells: [],
                clients: (_b = initialState.filterFormState.clients) !== null && _b !== void 0 ? _b : [],
                productTypeId: initialState.filterFormState.productTypeId,
            }
        }
    });
    useEffect(() => {
        dispatch({
            type: AvailabilityOnDateActionType.SET_LOADING,
            payload: reportIsLoading
        });
    }, [reportIsLoading]);
    useEffect(() => {
        var _a;
        if (state.filterFormState.warehouseId === 0 || state.filterFormState.warehouseId === undefined)
            return;
        getAvailabilityOnDate({
            variables: {
                page: state.page,
                pageSize: state.pageSize,
                input: {
                    warehouseId: state.filterFormState.warehouseId,
                    date: state.filterFormState.date.format("YYYY-MM-DD"),
                    storageCells: [],
                    clients: (_a = state.filterFormState.clients) !== null && _a !== void 0 ? _a : [],
                    productTypeId: state.filterFormState.productTypeId,
                }
            }
        });
    }, [state.page, state.pageSize, state.filterFormState, ...Object.values(state.filterFormState)]);
    useEffect(() => {
        if (reportData === undefined)
            return;
        dispatch({
            type: AvailabilityOnDateActionType.SET_DATA,
            payload: reportData.availabilityOnDate
        });
    }, [reportData]);
    return (React.createElement(AvailabilityOnDateContext.Provider, { value: state },
        React.createElement(AvailabilityOnDateDispatchContext.Provider, { value: dispatch }, children)));
};
export function useAvailabilityOnDate() {
    return useContext(AvailabilityOnDateContext);
}
export function useAvailabilityOnDateDispatch() {
    return useContext(AvailabilityOnDateDispatchContext);
}
