var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTaskWriteOffCreateMutation } from "../../../api/graphql/queries/task/taskWriteOff/TaskWriteOffMutation";
export const useTaskWriteOffCRUDActions = () => {
    const [createTask, { loading }] = useTaskWriteOffCreateMutation();
    const create = (warehouseId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield createTask({ variables: { input: { warehouseId } } });
    });
    return { actions: { create, isLoading: loading } };
};
