import React, { useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Button, Divider, Empty, Form, Select, Spin, Typography, } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import UserGroupSelect from '../UserGroupSelect';
const StatusEntryPointItem = (props) => {
    const { entrypointList, id } = props;
    return (React.createElement("div", null,
        React.createElement(Form.Item, { name: [id, 'entryPointIdentifier'], label: "\u0418\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440 \u0442\u043E\u0447\u043A\u0438 \u0432\u0445\u043E\u0434\u0430" },
            React.createElement(Select, { options: entrypointList })),
        React.createElement(Form.Item, { label: "\u0414\u043E\u0441\u0442\u0443\u043F\u043D\u043E \u0434\u043B\u044F \u0433\u0440\u0443\u043F\u043F", name: [id, 'userGroups'] },
            React.createElement(UserGroupSelect, { mode: "multiple" }))));
};
const StatusEntryPointForm = (props) => {
    const { entrypointList, statusId, initialValues, loading, onSubmit, } = props;
    const [form] = useForm();
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);
    const handlerSubmit = (fields) => {
        const result = {
            edit: [],
            add: [],
            delete: [],
        };
        fields.entryPoints.forEach((item, index) => {
            var _a, _b;
            const edited = form.isFieldTouched(['entryPoints', index]);
            if (edited) {
                if (!item.id) {
                    result.add.push(Object.assign(Object.assign({}, item), { userGroups: (_a = item.userGroups) !== null && _a !== void 0 ? _a : [] }));
                }
                else {
                    result.edit.push(Object.assign(Object.assign({}, item), { userGroups: (_b = item.userGroups) !== null && _b !== void 0 ? _b : [] }));
                }
            }
        });
        initialValues.entryPoints.forEach((item) => {
            var _a;
            const exist = fields.entryPoints.some((field) => (field === null || field === void 0 ? void 0 : field.id) === item.id);
            if (!exist) {
                result.delete.push(Object.assign(Object.assign({}, item), { userGroups: (_a = item.userGroups) !== null && _a !== void 0 ? _a : [] }));
            }
        });
        onSubmit(result);
    };
    const handlerClear = () => {
        form.resetFields();
    };
    return (React.createElement(Spin, { spinning: loading },
        React.createElement(Form, { form: form, initialValues: initialValues, layout: "vertical", onFinish: handlerSubmit },
            React.createElement(Form.List, { name: "entryPoints" }, (fields, operation) => {
                const { add, remove } = operation;
                return (React.createElement(React.Fragment, null,
                    fields.length ? (fields.map((value, index) => (React.createElement("div", { key: `${statusId}-${value.key}` },
                        React.createElement(Divider, { orientation: "left", plain: true, style: { position: 'relative', margin: `${index ? 35 : 0}px 0 20px` } },
                            React.createElement(Typography.Text, { strong: true },
                                "\u0422\u043E\u0447\u043A\u0430 \u0434\u043E\u0441\u0442\u0443\u043F\u0430 \u2116",
                                index + 1),
                            React.createElement("div", { style: {
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: 0,
                                    right: 20,
                                    backgroundColor: 'white',
                                    padding: '0 1em',
                                } },
                                React.createElement(Button, { size: "small", onClick: () => remove(value.name), danger: true, icon: React.createElement(CloseOutlined, null) }))),
                        React.createElement(StatusEntryPointItem, { id: value.name, entrypointList: entrypointList }))))) : (React.createElement(Empty, { style: { margin: '40px 0' }, description: "\u0422\u043E\u0447\u043A\u0438 \u0432\u0445\u043E\u0434\u0430 \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0442" })),
                    React.createElement(Button.Group, { style: { width: '100%' } },
                        React.createElement(Button, { block: true, type: "dashed", onClick: () => add() }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"),
                        React.createElement(Button, { block: true, htmlType: "submit" }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
                        React.createElement(Button, { onClick: handlerClear, disabled: !form.isFieldsTouched(), block: true, danger: true }, "\u041E\u0442\u043C\u0435\u043D\u0430"))));
            }))));
};
export default StatusEntryPointForm;
