var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { getColumnType } from '../../../../helpers/function';
import SearchModalTable from '../../SearchModal/SearchModalTable';
const SkuTableSelected = (props) => {
    const { items } = props, otherProps = __rest(props, ["items"]);
    const columns = [
        getColumnType('id', '#'),
        getColumnType('productTitle', 'Тип'),
        getColumnType('productTitleCompiled', 'Номенклатура'),
        getColumnType('client', 'Контрагент', false, {
            render: (client) => {
                const { companyName, companyInn } = client;
                return (React.createElement(React.Fragment, null,
                    companyName,
                    React.createElement("br", null),
                    companyInn));
            },
        }),
        getColumnType('warehouse', 'Склад'),
        getColumnType('quantity', 'Кол-во'),
    ];
    const data = items.map((item) => {
        var _a;
        return ({
            id: item.id.toString(),
            key: item.id.toString(),
            productTitle: item.product.title || '',
            productTitleCompiled: item.product.titleCompiled || '',
            client: item.client,
            warehouse: ((_a = item.warehouse) === null || _a === void 0 ? void 0 : _a.storageTitle) || '',
            quantity: item.quantity,
        });
    });
    return (React.createElement(SearchModalTable, Object.assign({}, otherProps, { columns: columns, data: data })));
};
export default SkuTableSelected;
