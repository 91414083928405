import React from 'react';
import { Card, Col, Divider, Row } from 'antd';
import TaskInfo from './TaskInfo';
import TaskItemFooter from './TaskItemFooter';
import Label from '../../ui/Label';
import { toDate } from '../../../helpers/function';
const TaskListItemWithSlot = (props) => {
    const { task, link, actions, info, slot, quantityTotal, postfixInfo } = props;
    const getStatus = () => {
        var _a, _b;
        const statusProps = {
            text: (_a = task.status) === null || _a === void 0 ? void 0 : _a.title,
            color: (_b = task.status) === null || _b === void 0 ? void 0 : _b.style,
        };
        return React.createElement(Label, Object.assign({}, statusProps));
    };
    const getFooter = () => {
        var _a;
        const footerProps = {
            userName: task.user.userName || '',
            date: (_a = toDate(task.createdAt)) === null || _a === void 0 ? void 0 : _a.toLocaleDateString(),
            link,
        };
        return React.createElement(TaskItemFooter, Object.assign({}, footerProps), actions);
    };
    const getTitle = () => {
        if (task.externalId) {
            return `#${task.id} - Внешняя задача #${task.externalId}`;
        }
        else {
            return `#${task.id}`;
        }
    };
    return (React.createElement(Card, { key: `task-${task.id}`, title: getTitle(), extra: getStatus(), type: "inner", size: "small", bordered: false },
        React.createElement(Row, { gutter: [12, 12] },
            React.createElement(Col, { xs: 24, sm: 8, md: 7, lg: 6, xl: 5 },
                React.createElement(TaskInfo, { items: info })),
            React.createElement(Col, { xs: 24, sm: 16, md: 17, lg: 18, xl: 19 }, slot),
            postfixInfo && (React.createElement(Col, { flex: "250px" },
                React.createElement(TaskInfo, { items: postfixInfo })))),
        React.createElement(Divider, { style: { margin: '12px -12px', width: 'auto' } }),
        getFooter()));
};
export default TaskListItemWithSlot;
