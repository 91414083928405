var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { Empty, Spin } from "antd";
import { mapValues, pick } from "lodash";
import { COMMON_ATTRIBUTES, PRODUCT_ID } from "../../ProductFilter/ProductFilter";
import { getProductList } from "../../../../api/rest/requests/products/product";
import ProductTable from "../ProductTable";
import LoadingState from "../../../../constants/enum/LoadingState";
import ProductTableSelected from "../ProductTableSelected";
import SearchModal from "../../SearchModal/SearchModal";
import Centered from "../../../ui/Centered";
const productFilterValuesToRequest = (values) => {
    const result = { productTypeId: values[PRODUCT_ID] };
    if (values[COMMON_ATTRIBUTES]) {
        const filledAttributeKeys = Object.keys(values[COMMON_ATTRIBUTES]).filter((key) => !!values[COMMON_ATTRIBUTES][key]);
        if (filledAttributeKeys.length > 0) {
            const filledAttributes = mapValues(pick(values[COMMON_ATTRIBUTES], filledAttributeKeys), (item) => item);
            result.productAttributes = filledAttributes;
        }
    }
    return result;
};
class ProductSearchModal extends SearchModal {
    constructor() {
        super(...arguments);
        this.handlerOk = () => {
            const { onAdd } = this.props;
            const { selectedItems } = this.state;
            onAdd(selectedItems);
            this.handlerClose();
        };
        this.handlerSearch = () => __awaiter(this, void 0, void 0, function* () {
            const { items, filterValues } = this.state;
            const { pagination } = items;
            const paginatedItems = yield getProductList(productFilterValuesToRequest(filterValues), pagination.page.toString(), pagination.itemsOnPage.toString(), undefined, (state) => this.setState({
                loadingState: state,
            }));
            this.setState({
                items: paginatedItems,
            });
        });
        this.renderSelectedTable = () => {
            const { selectedItems } = this.state;
            if (selectedItems.length === 0) {
                return (React.createElement(Centered, null,
                    React.createElement(Empty, null)));
            }
            const selectedProductKeys = selectedItems.map(({ id }) => id);
            return (React.createElement(ProductTableSelected, { items: selectedItems, selectedKeys: selectedProductKeys, onSelect: this.handlerSelect, tableProps: {
                    scroll: {
                        y: 580,
                        x: true,
                    },
                } }));
        };
        this.renderFindTable = () => {
            const { isMultiple } = this.props;
            const { items, selectedItems, attributes, loadingState } = this.state;
            if (loadingState === LoadingState.Loading) {
                return (React.createElement(Centered, null,
                    React.createElement(Spin, null)));
            }
            if (items.list.length === 0) {
                return (React.createElement(Centered, null,
                    React.createElement(Empty, null)));
            }
            const productList = items.list;
            const selectedProductKeys = selectedItems.map(({ id }) => id);
            return (React.createElement(ProductTable, { isMultiple: isMultiple, attributes: attributes, items: productList, selectedKeys: selectedProductKeys, onSelect: this.handlerSelect, tableProps: {
                    scroll: {
                        y: 580,
                        x: true,
                    },
                } }));
        };
    }
}
export default ProductSearchModal;
