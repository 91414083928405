import React from 'react';
import ColorPalette from '../../constants/enum/ColorPalette';
import { SkuStatus } from '../../constants/enum/SkuStatus';
import Label from '../ui/Label';
export const getPropsById = (id) => {
    switch (id) {
        case SkuStatus.FREE: {
            return {
                text: 'Свободен',
                color: ColorPalette.Green,
            };
        }
        case SkuStatus.RESERVED: {
            return {
                text: 'Бронь',
                color: ColorPalette.Purple,
            };
        }
        case SkuStatus.BLOCKED: {
            return {
                text: 'Блок',
                color: ColorPalette.Orange,
            };
        }
        case SkuStatus.WRITTEN_OF: {
            return {
                text: 'Списан',
                color: ColorPalette.Yellow,
            };
        }
        case SkuStatus.SOLD: {
            return {
                text: 'Продан',
                color: ColorPalette.Teal,
            };
        }
        case SkuStatus.SHIPPED: {
            return {
                text: 'Отгружен',
                color: ColorPalette.Teal,
            };
        }
        case SkuStatus.DRAFT: {
            return {
                text: 'Черновик',
                color: ColorPalette.Grey,
            };
        }
        case SkuStatus.IN_WORK: {
            return {
                text: 'В работе',
                color: ColorPalette.Blue,
            };
        }
        case SkuStatus.TASK_PARTITION: {
            return {
                text: 'На делении',
                color: ColorPalette.Orange,
            };
        }
        case SkuStatus.TASK_MOVEMENT: {
            return {
                text: 'На перемещении',
                color: ColorPalette.Orange,
            };
        }
        case SkuStatus.TASK_CHANGE_WAREHOUSE: {
            return {
                text: 'На перемещении между складами',
                color: ColorPalette.Orange,
            };
        }
        case SkuStatus.TASK_LOADING: {
            return {
                text: 'На Загрузке',
                color: ColorPalette.Blue,
            };
        }
        case SkuStatus.TASK_SHIPPING: {
            return {
                text: 'На отгрузке',
                color: ColorPalette.Blue,
            };
        }
        case SkuStatus.TASK_INVENTORY: {
            return {
                text: 'На инвентаризации',
                color: ColorPalette.Orange,
            };
        }
        case SkuStatus.TASK_TRANSFER: {
            return {
                text: 'На перемещении между складами',
                color: ColorPalette.Orange,
            };
        }
        case SkuStatus.TASK_CORRECTION: {
            return {
                text: 'На корректировке',
                color: ColorPalette.Orange,
            };
        }
        case SkuStatus.DELETED: {
            return {
                text: 'Удалено',
                color: ColorPalette.Grey,
            };
        }
        default: {
            return {
                text: 'Не определено',
                color: ColorPalette.Red,
            };
        }
    }
};
const SkuStatusTag = (props) => {
    const { id, title, style } = props;
    let statusProps = {};
    if (title && style) {
        statusProps = {
            text: title,
            color: style,
        };
    }
    else if (id) {
        statusProps = getPropsById(id);
    }
    return React.createElement(Label, Object.assign({}, statusProps));
};
export default SkuStatusTag;
