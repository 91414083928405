import { debounce } from "lodash";
import { useEffect } from "react";
export const useResizeGraph = ({ graphRef, graph, enableResaze }) => {
    useEffect(() => {
        const handleResize = debounce(() => {
            if (enableResaze) {
                if (graphRef && graph) {
                    const { height, width } = graphRef.getBoundingClientRect();
                    graph.resize(width - 40, height - 50);
                }
            }
        }, 600);
        if (enableResaze) {
            window.addEventListener("resize", handleResize);
        }
        return () => {
            handleResize.cancel();
            window.removeEventListener("resize", handleResize);
        };
    }, [graph]);
};
