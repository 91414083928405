var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Pagination, Space, Spin } from 'antd';
import React from 'react';
const PaginationPanel = (props) => {
    const { pagination, disabled, onChange } = props, otherProps = __rest(props, ["pagination", "disabled", "onChange"]);
    const onChangeHandler = (page, itemsOnPage) => {
        onChange(page, itemsOnPage);
    };
    if (pagination) {
        return (React.createElement(Space, { align: "center" },
            React.createElement(Pagination, Object.assign({}, otherProps, { responsive: true, disabled: disabled, defaultCurrent: pagination.page, current: pagination.page, pageSize: pagination.itemsOnPage, total: pagination.itemsTotalCount, onChange: onChangeHandler }))));
    }
    return (React.createElement(Spin, null));
};
export default PaginationPanel;
