var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { getColumnType } from "../../../../../../helpers/function";
import { Button, Dropdown, Form, Tooltip } from 'antd';
import MDIcon from "../../../../../../components/ui/MDIcon";
import StorageCellSelect from "../../../../../../components/StorageCellSelect";
const onCell = (record) => ({});
export const createColumns = (attributes, handlerOpenSkuInfo, editedCells, setEditedCells, onFinish, onRemove, loading, destinationWarehouseId, isLocked) => {
    const attributesKyes = Object.keys(attributes);
    const skuDropdownMenu = (record) => ({
        items: [
            {
                key: "sku_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => {
                    setEditedCells((prev) => {
                        var _a;
                        return [...prev, (_a = record.skuId) !== null && _a !== void 0 ? _a : 0];
                    });
                }
            },
            {
                key: "sku_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => {
                    record.taskDetailId && onRemove(record.taskDetailId);
                }
            }
        ]
    });
    return [
        getColumnType("skuId", "#", false, {
            onCell,
            width: "80px",
        }),
        getColumnType("quantity", "Количество", false, {
            width: "1%",
        }),
        ...attributesKyes.map((attribute) => getColumnType("", attribute, false, {
            onCell,
            width: "80px",
            render: (_, record) => {
                var _a;
                const attr = attributes[attribute];
                const data = (_a = record.skuAttributeValues) === null || _a === void 0 ? void 0 : _a.find((el) => el.key === attr.id);
                return data ? `${data.value.valueReadable || ""} ${(attr === null || attr === void 0 ? void 0 : attr.unit) || ""}` : React.createElement(React.Fragment, null);
            },
        })),
        {
            title: "Ячейка",
            fixed: "right",
            children: [
                getColumnType("storageCellFrom", "до", true, {
                    fixed: "right",
                    width: "80px",
                    render: (_, record) => { var _a; return (_a = record.storageCellFrom) === null || _a === void 0 ? void 0 : _a.title; },
                }),
                getColumnType("storageCellTo", "после", true, {
                    fixed: "right",
                    width: "80px",
                    render(value, record, index) {
                        var _a, _b, _c, _d;
                        return editedCells.includes((_a = record.skuId) !== null && _a !== void 0 ? _a : 0) ? (React.createElement(Form, { id: `storageCellTo-${record === null || record === void 0 ? void 0 : record.skuId}`, onFinish: (e) => __awaiter(this, void 0, void 0, function* () {
                                if (e && typeof e === "object" && "cell" in e && typeof e["cell"] === "object" && e.cell && "value" in e.cell && typeof e.cell["value"] === "number") {
                                    (record === null || record === void 0 ? void 0 : record.taskDetailId) && (yield onFinish(record === null || record === void 0 ? void 0 : record.taskDetailId, e.cell.value));
                                    setEditedCells((prev) => {
                                        return [...prev].filter((item) => { var _a; return (_a = item !== record.skuId) !== null && _a !== void 0 ? _a : 0; });
                                    });
                                }
                            }) },
                            React.createElement(Form.Item, { name: "cell", rules: [
                                    {
                                        required: true,
                                        message: "Укажите ячейку",
                                    },
                                ] },
                                React.createElement(StorageCellSelect, { warehouseId: destinationWarehouseId, value: { value: (_b = record.storageCellTo) === null || _b === void 0 ? void 0 : _b.id, label: (_c = record.storageCellTo) === null || _c === void 0 ? void 0 : _c.title } })))) : ((_d = record.storageCellTo) === null || _d === void 0 ? void 0 : _d.title);
                    },
                }),
            ],
        },
        getColumnType("", "", false, {
            fixed: "right",
            width: "30px",
            render: (_, record) => {
                var _a;
                return !editedCells.includes((_a = record.skuId) !== null && _a !== void 0 ? _a : 0) ? (React.createElement(Button.Group, null,
                    React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                        React.createElement(Button, { size: "small", type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: () => {
                                record.skuId && handlerOpenSkuInfo(`${record.skuId}`);
                            } })),
                    React.createElement(Dropdown, { disabled: isLocked, menu: skuDropdownMenu(record), trigger: ["click"] },
                        React.createElement(Button, { size: "small", icon: React.createElement(MDIcon, { icon: "dots-vertical" }) })))) : (React.createElement(Button.Group, null,
                    React.createElement(Tooltip, { title: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                        React.createElement(Button, { loading: loading, htmlType: "submit", form: `storageCellTo-${record === null || record === void 0 ? void 0 : record.skuId}`, type: "primary", icon: React.createElement(MDIcon, { icon: "content-save" }), size: "small" })),
                    React.createElement(Tooltip, { title: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                        React.createElement(Button, { loading: loading, type: "primary", danger: true, icon: React.createElement(MDIcon, { icon: "close-circle" }), size: "small", onClick: () => {
                                setEditedCells((prev) => {
                                    return [...prev].filter((item) => { var _a; return (_a = item !== record.skuId) !== null && _a !== void 0 ? _a : 0; });
                                });
                            } }))));
            },
        }),
    ];
};
