import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ProductTypeAutomationCreateDocument = gql `
    mutation ProductTypeAutomationCreate($input: ProductTypeAutomationCreateInput!) {
  productTypeAutomationCreate(input: $input) {
    productTypeAutomationDto {
      id
    }
  }
}
    `;
/**
 * __useProductTypeAutomationCreateMutation__
 *
 * To run a mutation, you first call `useProductTypeAutomationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAutomationCreateMutation, { data, loading, error }] = useProductTypeAutomationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAutomationCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAutomationCreateDocument, options);
}
export const ProductTypeAutomationEditDocument = gql `
    mutation ProductTypeAutomationEdit($input: ProductTypeAutomationEditInput!) {
  productTypeAutomationEdit(input: $input) {
    productTypeAutomationDto {
      id
    }
  }
}
    `;
/**
 * __useProductTypeAutomationEditMutation__
 *
 * To run a mutation, you first call `useProductTypeAutomationEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAutomationEditMutation, { data, loading, error }] = useProductTypeAutomationEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAutomationEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAutomationEditDocument, options);
}
export const ProductTypeAutomationDeleteDocument = gql `
    mutation ProductTypeAutomationDelete($input: ProductTypeAutomationDeleteInput!) {
  productTypeAutomationDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useProductTypeAutomationDeleteMutation__
 *
 * To run a mutation, you first call `useProductTypeAutomationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAutomationDeleteMutation, { data, loading, error }] = useProductTypeAutomationDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAutomationDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAutomationDeleteDocument, options);
}
export const ProductTypeAutomationExpressionCreateDocument = gql `
    mutation ProductTypeAutomationExpressionCreate($input: ProductTypeAutomationExpressionCreateInput!) {
  productTypeAutomationExpressionCreate(input: $input) {
    productTypeAutomationExpressionDto {
      id
    }
  }
}
    `;
/**
 * __useProductTypeAutomationExpressionCreateMutation__
 *
 * To run a mutation, you first call `useProductTypeAutomationExpressionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationExpressionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAutomationExpressionCreateMutation, { data, loading, error }] = useProductTypeAutomationExpressionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAutomationExpressionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAutomationExpressionCreateDocument, options);
}
export const ProductTypeAutomationConditionCreateDocument = gql `
    mutation ProductTypeAutomationConditionCreate($input: ProductTypeAutomationConditionCreateInput!) {
  productTypeAutomationConditionCreate(input: $input) {
    productTypeAutomationConditionDto {
      id
    }
  }
}
    `;
/**
 * __useProductTypeAutomationConditionCreateMutation__
 *
 * To run a mutation, you first call `useProductTypeAutomationConditionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationConditionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAutomationConditionCreateMutation, { data, loading, error }] = useProductTypeAutomationConditionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAutomationConditionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAutomationConditionCreateDocument, options);
}
export const ProductTypeAutomationExpressionEditDocument = gql `
    mutation ProductTypeAutomationExpressionEdit($input: ProductTypeAutomationExpressionEditInput!) {
  productTypeAutomationExpressionEdit(input: $input) {
    productTypeAutomationExpressionDto {
      id
    }
  }
}
    `;
/**
 * __useProductTypeAutomationExpressionEditMutation__
 *
 * To run a mutation, you first call `useProductTypeAutomationExpressionEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationExpressionEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAutomationExpressionEditMutation, { data, loading, error }] = useProductTypeAutomationExpressionEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAutomationExpressionEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAutomationExpressionEditDocument, options);
}
export const ProductTypeAutomationConditionEditDocument = gql `
    mutation ProductTypeAutomationConditionEdit($input: ProductTypeAutomationConditionEditInput!) {
  productTypeAutomationConditionEdit(input: $input) {
    productTypeAutomationConditionDto {
      id
    }
  }
}
    `;
/**
 * __useProductTypeAutomationConditionEditMutation__
 *
 * To run a mutation, you first call `useProductTypeAutomationConditionEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationConditionEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAutomationConditionEditMutation, { data, loading, error }] = useProductTypeAutomationConditionEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAutomationConditionEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAutomationConditionEditDocument, options);
}
export const ProductTypeAutomationExpressionSortDocument = gql `
    mutation ProductTypeAutomationExpressionSort($input: ProductTypeAutomationExpressionSortInput!) {
  productTypeAutomationExpressionSort(input: $input) {
    productTypeAutomationDto {
      id
    }
  }
}
    `;
/**
 * __useProductTypeAutomationExpressionSortMutation__
 *
 * To run a mutation, you first call `useProductTypeAutomationExpressionSortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationExpressionSortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAutomationExpressionSortMutation, { data, loading, error }] = useProductTypeAutomationExpressionSortMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAutomationExpressionSortMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAutomationExpressionSortDocument, options);
}
export const ProductTypeAutomationExpressionDeleteDocument = gql `
    mutation ProductTypeAutomationExpressionDelete($input: ProductTypeAutomationExpressionDeleteInput!) {
  productTypeAutomationExpressionDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useProductTypeAutomationExpressionDeleteMutation__
 *
 * To run a mutation, you first call `useProductTypeAutomationExpressionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationExpressionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAutomationExpressionDeleteMutation, { data, loading, error }] = useProductTypeAutomationExpressionDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAutomationExpressionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAutomationExpressionDeleteDocument, options);
}
export const ProductTypeAutomationConditionDeleteDocument = gql `
    mutation ProductTypeAutomationConditionDelete($input: ProductTypeAutomationConditionDeleteInput!) {
  productTypeAutomationConditionDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useProductTypeAutomationConditionDeleteMutation__
 *
 * To run a mutation, you first call `useProductTypeAutomationConditionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationConditionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAutomationConditionDeleteMutation, { data, loading, error }] = useProductTypeAutomationConditionDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAutomationConditionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAutomationConditionDeleteDocument, options);
}
