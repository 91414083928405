import { AvailabilityOnDateActionType } from "./state";
export const availabilityOnDateReducer = (state, action) => {
    switch (action.type) {
        case AvailabilityOnDateActionType.SET_LOADING:
            return Object.assign(Object.assign({}, state), { loading: action.payload });
        case AvailabilityOnDateActionType.SET_FILTER_FORM_STATE:
            return Object.assign(Object.assign({}, state), { filterFormState: action.payload });
        case AvailabilityOnDateActionType.SET_PAGE:
            return Object.assign(Object.assign({}, state), { page: action.payload.page, pageSize: action.payload.pageSize });
        case AvailabilityOnDateActionType.SET_DATA:
            return Object.assign(Object.assign({}, state), { items: action.payload.rows, total: action.payload.total });
        default:
            return state;
    }
};
