import React from 'react';
import Page from '../../components/Page';
import HandbookAddFormContainer from '../containers/HandbookAddFormContainer';
import HandbookHeaderContainer from '../containers/HandbookHeaderContainer';
import HandbookPaginationContainer from '../containers/HandbookPaginationContainer';
import HandbookTableContainer from '../containers/HandbookTableContainer';
import HandbookTreeContainer from '../containers/HandbookTreeContainer';
export const HANDBOOK_PARAM = 'handbook';
const HandbookPage = () => (React.createElement(Page, { toolbarTop: React.createElement(HandbookHeaderContainer, null), toolbarLeft: React.createElement(HandbookTreeContainer, null), toolbarRight: React.createElement(HandbookAddFormContainer, null), toolbarBottom: React.createElement(HandbookPaginationContainer, null) },
    React.createElement(HandbookTableContainer, null)));
export default HandbookPage;
