import { Button, Col, Row, Skeleton, Typography } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import MDIcon from "../../components/ui/MDIcon";
import { links } from "../../constants/links";
import { productTypeGraphqlApi } from "../../redux/api/productApi/productType";
const { Title } = Typography;
const { useFetchProductTypeQuery } = productTypeGraphqlApi;
const ProductTypeAttrHeaderContainer = () => {
    const { typeId: typeParam } = useParams();
    const typeId = typeParam ? +typeParam : null;
    const navigate = useNavigate();
    const { data: type, isLoading } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    return (React.createElement(Row, { align: "middle", gutter: [16, 16] },
        React.createElement(Col, { flex: "auto" },
            React.createElement(Skeleton, { active: true, paragraph: false, loading: isLoading },
                React.createElement(Title, { className: "m-0", level: 5 }, type === null || type === void 0 ? void 0 : type.title))),
        React.createElement(Col, null,
            React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "arrow-left", style: { marginRight: 5 } }), onClick: () => navigate(links.handbookProductEditTypeLink.url(typeId !== null && typeId !== void 0 ? typeId : "")) }, "\u041A \u0442\u0438\u043F\u0443"))));
};
export default ProductTypeAttrHeaderContainer;
