var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Card, Form, Input, Modal, Space, Spin, Table, Typography } from "antd";
import React, { useMemo, useState } from "react";
import Centered from "../../../../components/ui/Centered";
import { createColumns } from "./Table/columns";
import { useGetUserGpoupListQuery } from "../../../../api/graphql/queries/userGpoup/UserGroupQuery";
import { useAccountsGroupsContainer } from "./useAccountsGroupsContainer";
import MDIcon from "../../../../components/ui/MDIcon";
const AccountsGroupsContainer = () => {
    const { data, loading, error, refetch } = useGetUserGpoupListQuery();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [editingName, setEditingName] = useState("");
    const [recordId, setRecordId] = useState(null);
    const { edit, add, del, loading: actionLoading } = useAccountsGroupsContainer();
    const handleEdit = (name) => __awaiter(void 0, void 0, void 0, function* () {
        setEditingName(name);
        setIsEditModalVisible(true);
    });
    const handleOk = () => __awaiter(void 0, void 0, void 0, function* () {
        if (recordId) {
            yield edit({ variables: { input: { id: recordId, name: editingName } } });
            yield refetch();
        }
        setIsEditModalVisible(false);
    });
    const handleCancel = () => {
        setIsEditModalVisible(false);
        setRecordId(null);
    };
    const handleCreate = () => {
        setEditingName("");
        setIsCreateModalVisible(true);
    };
    const handleCreateOk = () => __awaiter(void 0, void 0, void 0, function* () {
        if (editingName) {
            yield add({ variables: { input: { name: editingName } } });
            yield refetch();
        }
        setEditingName("");
        setIsCreateModalVisible(false);
    });
    const handleCreateCancel = () => {
        setIsCreateModalVisible(false);
    };
    const handleDelete = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield del({ variables: { input: { userGroupId: id } } });
        yield refetch();
    });
    const columns = useMemo(() => createColumns({ handleEdit, handleDelete, setRecordId }), []);
    if (loading)
        return (React.createElement(Centered, { style: { minHeight: "100vh" } },
            React.createElement(Spin, { size: "large" })));
    if (error)
        return (React.createElement(Card, null,
            React.createElement(Typography.Text, null, "\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u044F \u0433\u0440\u0443\u043F\u043F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439")));
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, null,
            React.createElement(Space, { direction: "vertical", size: "middle", style: { display: "flex" } },
                React.createElement(Table, { loading: actionLoading, columns: columns, dataSource: data === null || data === void 0 ? void 0 : data.userGpoupList, rowKey: "id", pagination: false }),
                React.createElement("div", { style: { display: "flex", justifyContent: "end" } },
                    React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "plus-circle", className: "anticon" }), style: { alignSelf: "end" }, loading: actionLoading, size: "middle", onClick: handleCreate }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0433\u0440\u0443\u043F\u043F\u0443")))),
        React.createElement(Modal, { confirmLoading: actionLoading, key: JSON.stringify(recordId), title: "\u0420\u0435\u0434\u0430\u0442\u0438\u043A\u0440\u043E\u0432\u0430\u0442\u044C \u0433\u0440\u0443\u043F\u043F\u0443", open: isEditModalVisible, onOk: handleOk, onCancel: handleCancel },
            React.createElement(Form, null,
                React.createElement(Form.Item, { label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435", name: "name", initialValue: editingName },
                    React.createElement(Input, { value: editingName, onChange: (e) => setEditingName(e.target.value) })))),
        React.createElement(Modal, { confirmLoading: actionLoading, title: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0433\u0440\u0443\u043F\u043F\u0443", open: isCreateModalVisible, onOk: handleCreateOk, onCancel: handleCreateCancel },
            React.createElement(Form, null,
                React.createElement(Form.Item, { label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435", name: "name" },
                    React.createElement(Input, { onChange: (e) => setEditingName(e.target.value) }))))));
};
export default AccountsGroupsContainer;
