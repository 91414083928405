import { Empty, Typography } from 'antd';
import { uniqueId } from 'lodash';
import React from 'react';
const { Title, Text } = Typography;
const CorrectionDetailNomenclature = (props) => {
    const { details, editable, children, produceWork, warehouseId, onEditOriginal, onEditCreated, onInfoClick, onSkuInfoClick, } = props;
    const handlerEditOriginal = (values) => {
        onEditOriginal === null || onEditOriginal === void 0 ? void 0 : onEditOriginal(values);
    };
    const handlerEditCreated = (values) => {
        onEditCreated === null || onEditCreated === void 0 ? void 0 : onEditCreated(values);
    };
    const handlerOpenInfo = (id) => {
        onInfoClick === null || onInfoClick === void 0 ? void 0 : onInfoClick(id);
    };
    const handlerOpenSkuInfo = (id) => {
        onSkuInfoClick === null || onSkuInfoClick === void 0 ? void 0 : onSkuInfoClick(id);
    };
    if (details.length === 0) {
        return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: (React.createElement(React.Fragment, null,
                React.createElement(Title, { level: 5 }, "\u0417\u0430\u0434\u0430\u0447\u0430 \u043D\u0435 \u043D\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0430."),
                React.createElement(Text, null, "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440\u0443."))) }));
    }
    return (React.createElement(React.Fragment, null, details.map((nomenclature) => children({
        key: uniqueId(),
        nomenclature,
        editable,
        produceWork,
        warehouseId,
        onEditOriginal: handlerEditOriginal,
        onEditCreated: handlerEditCreated,
        onInfoClick: handlerOpenInfo,
        onSkuInfoClick: handlerOpenSkuInfo,
    }))));
};
export default CorrectionDetailNomenclature;
