var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Form, Select } from "antd";
import React, { useRef, useState } from "react";
import ClientSelect from "../../../components/ClientSelect";
import AppFragment from "../../../components/Fragment";
import StorageCellSelect from "../../../components/StorageCellGraphqlSelect";
import WarehouseSelect from "../../../components/WarehouseSelect";
import ProductFilter from "../../../components/nomenclatureSearch/ProductFilter";
import { SkuStatus } from "../../../constants/enum/SkuStatus";
const WAREHOUSE = "warehouses";
const STORAGE_CELL = "storageCells";
const STATUS = "statuses";
const CLIENTS = "clients";
const ToolbarRight = ({ type, handlerFilterChange, setFilterValue }) => {
    var _a;
    const filterForm = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const warehousesIds = (_a = filterForm.current) === null || _a === void 0 ? void 0 : _a.getFieldValue(WAREHOUSE);
    const handlerClose = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        (_b = filterForm.current) === null || _b === void 0 ? void 0 : _b.resetFields();
        setAttributes(attributes);
        setFilterValue({});
    });
    const handlerSetAttributes = (list) => {
        setAttributes(list);
    };
    const onChange = (values) => {
        handlerFilterChange(values);
    };
    return (React.createElement(AppFragment, { title: "\u0424\u0438\u043B\u044C\u0442\u0440", buttons: React.createElement(Button.Group, { style: { width: "100%", gap: "5px" } },
            React.createElement(Button, { block: true, onClick: handlerClose }, "\u0421\u0431\u0440\u043E\u0441")) }, type && (React.createElement(ProductFilter, { productType: { label: "", value: type }, hideProductType: true, includeAdditional: false, includePersonal: false, onSetAttributes: handlerSetAttributes, onChange: onChange, attributes: attributes, formRef: filterForm, productIdIsRequired: false, additionalFields: React.createElement(React.Fragment, null,
            React.createElement(Form.Item, { name: WAREHOUSE, label: "\u0421\u043A\u043B\u0430\u0434" },
                React.createElement(WarehouseSelect, { mode: "multiple", placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434\u044B" })),
            React.createElement(Form.Item, { name: STORAGE_CELL, label: "\u042F\u0447\u0435\u0439\u043A\u0430 \u0445\u0440\u0430\u043D\u0435\u043D\u0438\u044F" },
                React.createElement(StorageCellSelect, { warehouseIds: (warehousesIds === null || warehousesIds === void 0 ? void 0 : warehousesIds.map((i) => i === null || i === void 0 ? void 0 : i.value)) || [] })),
            React.createElement(Form.Item, { name: CLIENTS, label: "\u041A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442" },
                React.createElement(ClientSelect, { mode: "multiple", placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442\u0430" })),
            React.createElement(Form.Item, { name: STATUS, label: "\u0421\u0442\u0430\u0442\u0443\u0441" },
                React.createElement(Select, { mode: "multiple", placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u0442\u0430\u0442\u0443\u0441\u044B" },
                    React.createElement(Select.Option, { value: SkuStatus.FREE }, "\u0421\u0432\u043E\u0431\u043E\u0434\u0435\u043D"),
                    React.createElement(Select.Option, { value: SkuStatus.DRAFT }, "\u0427\u0435\u0440\u043D\u043E\u0432\u0438\u043A"),
                    React.createElement(Select.Option, { value: SkuStatus.WRITTEN_OF }, "\u0421\u043F\u0438\u0441\u0430\u043D"),
                    React.createElement(Select.Option, { value: SkuStatus.SHIPPED }, "\u041E\u0442\u0433\u0440\u0443\u0436\u0435\u043D"),
                    React.createElement(Select.Option, { value: SkuStatus.TASK_LOADING }, "\u041D\u0430 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0435"),
                    React.createElement(Select.Option, { value: SkuStatus.TASK_SHIPPING }, "\u041D\u0430 \u043E\u0442\u0433\u0440\u0443\u0437\u043A\u0435"),
                    React.createElement(Select.Option, { value: SkuStatus.TASK_PARTITION }, "\u041D\u0430 \u0434\u0435\u043B\u0435\u043D\u0438\u0438"),
                    React.createElement(Select.Option, { value: SkuStatus.TASK_MOVEMENT }, "\u041D\u0430 \u043F\u0435\u0440\u0435\u043C\u0435\u0449\u0435\u043D\u0438\u0438"),
                    React.createElement(Select.Option, { value: SkuStatus.TASK_CHANGE_WAREHOUSE }, "\u041D\u0430 \u043F\u0435\u0440\u0435\u043C\u0435\u0449\u0435\u043D\u0438\u0438 \u043C\u0435\u0436\u0434\u0443 \u0441\u043A\u043B\u0430\u0434\u0430\u043C\u0438"),
                    React.createElement(Select.Option, { value: SkuStatus.TASK_INVENTORY }, "\u041D\u0430 \u0438\u043D\u0432\u0435\u043D\u0442\u0430\u0440\u0438\u0437\u0430\u0446\u0438\u0438"),
                    React.createElement(Select.Option, { value: SkuStatus.TASK_CORRECTION }, "\u041D\u0430 \u043A\u043E\u0440\u0440\u0435\u043A\u0442\u0438\u0440\u043E\u0432\u043A\u0435"),
                    React.createElement(Select.Option, { value: SkuStatus.RESERVED }, "\u0411\u0440\u043E\u043D\u044C"),
                    React.createElement(Select.Option, { value: SkuStatus.BLOCKED }, "\u0411\u043B\u043E\u043A")))) }))));
};
export default ToolbarRight;
