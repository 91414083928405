import { DEFAULT_PAGE_SIZE } from ".";
import { getTaskPaths } from "../routing/helpers";
export const rootDirectory = "";
export const links = {
    oidc: {
        profile: {
            url: () => `${window.env.IdentityHost}/Manage`,
        },
    },
    homeLink: {
        url: () => `${rootDirectory}/`,
    },
    nomenclatureLink: {
        url: () => `${rootDirectory}/nomenclature`,
    },
    reportMovementForPeriodLink: {
        url: () => `${rootDirectory}/report/movement_for_period`,
    },
    reportAvailabilityOnDateLink: {
        url: () => `${rootDirectory}/report/availability_on_date`,
    },
    reportAvailabilityOnDateLinkOld: {
        url: () => `${rootDirectory}/report/availability_on_date_old`,
    },
    handbookDynamicLink: {
        url: () => `${rootDirectory}/handbook/dynamic`,
    },
    handbookUnitLink: {
        path: `${rootDirectory}/handbook/unit`,
        url: (page = 1, pageSize = DEFAULT_PAGE_SIZE) => `${rootDirectory}/handbook/unit?page=${page}&pageSize=${pageSize}`,
    },
    handbookUnitItemLink: {
        url: (id) => `${rootDirectory}/handbook/unit/${id}`,
    },
    handbookUnitAddLink: {
        url: () => `${rootDirectory}/handbook/unit/add`,
    },
    handbookPackingLink: {
        url: () => `${rootDirectory}/handbook/packing`,
    },
    handbookPackingSetEditLink: {
        url: (packingSetId) => `${rootDirectory}/handbook/packing/set/${packingSetId}`,
    },
    handbookPackingSetAddLink: {
        url: () => `${rootDirectory}/handbook/packing/set/add`,
    },
    handbookPackingEditLink: {
        url: (packingId, title) => `${rootDirectory}/handbook/packing/${packingId}${title ? `?title=${title}` : ""}`,
    },
    handbookPackingAddLink: {
        url: (packingSetId, title) => `${rootDirectory}/handbook/packing/add/${packingSetId}${title ? `?title=${title}` : ""}`,
    },
    handbookProductLink: {
        url: () => `${rootDirectory}/handbook/product`,
    },
    handbookProductAddTypeLink: {
        url: (parentId) => {
            const url = `${rootDirectory}/handbook/product/type/add`;
            if (parentId) {
                return `${url}?parentId=${parentId}`;
            }
            return url;
        },
    },
    handbookProductEditTypeLink: {
        url: (typeId) => `${rootDirectory}/handbook/product/type/${typeId}`,
    },
    automatizationLink: {
        url: (typeId, automatizationId) => `${rootDirectory}/handbook/product/type/${typeId}/automatization/${automatizationId}`,
    },
    handbookProductAddLink: {
        url: (typeId) => {
            const url = `${rootDirectory}/handbook/product/item/add`;
            if (typeId) {
                return `${url}?typeId=${typeId}`;
            }
            return url;
        },
    },
    handbookProductEditLink: {
        url: (productId) => `${rootDirectory}/handbook/product/${productId}`,
    },
    handbookProductAttrEditLink: {
        url: (typeId, productAttrId) => `${rootDirectory}/handbook/product/type/${typeId}/attr/${productAttrId}`,
    },
    handbookProductAttrAddLink: {
        url: (typeId, categoryId) => {
            const url = `${rootDirectory}/handbook/product/type/${typeId}/attr/add`;
            if (categoryId) {
                return `${url}?categoryId=${categoryId}`;
            }
            return url;
        },
    },
    handbookClientsLink: {
        path: `${rootDirectory}/handbook/clients`,
        url: (page = 1, pageSize = DEFAULT_PAGE_SIZE) => `${rootDirectory}/handbook/clients?page=${page}&pageSize=${pageSize}`,
    },
    handbookWarehouseListLink: {
        path: `${rootDirectory}/handbook/warehouse`,
        url: (page = 1, pageSize = DEFAULT_PAGE_SIZE) => `${rootDirectory}/handbook/warehouse?page=${page}&pageSize=${pageSize}`,
    },
    handbookWarehouseLink: {
        url: (id) => `${rootDirectory}/handbook/warehouse/${id}`,
    },
    handbookWarehouseInfoLink: {
        url: (id) => `${rootDirectory}/handbook/warehouse/${id}/info`,
    },
    handbookWarehouseSectionsTypeLink: {
        path: `${rootDirectory}/handbook/warehouse/:id/section/types`,
        url: (id, page = 1, pageSize = DEFAULT_PAGE_SIZE) => `${rootDirectory}/handbook/warehouse/${id}/section/types?page=${page}&pageSize=${pageSize}`,
    },
    handbookWarehouseCellsTypeLink: {
        path: `${rootDirectory}/handbook/warehouse/:id/cell/types`,
        url: (id, page = 1, pageSize = DEFAULT_PAGE_SIZE) => `${rootDirectory}/handbook/warehouse/${id}/cell/types?page=${page}&pageSize=${pageSize}`,
    },
    handbookWarehouseTopologyEditor: {
        path: `${rootDirectory}/handbook/warehouse/:id/topology/editor`,
        url: (id) => `${rootDirectory}/handbook/warehouse/${id}/topology/editor`,
    },
    handbookWarehouseAddLink: {
        url: () => `${rootDirectory}/handbook/warehouse/add`,
    },
    accountsUsersLink: {
        url: () => `${rootDirectory}/accounts/users`,
    },
    accountsGroupsLink: {
        url: () => `${rootDirectory}/accounts/groups`,
    },
    statusConfigurator: {
        url: (objectClass) => `${rootDirectory}/status/configurator/${objectClass}`,
    },
    taskInventoryLinks: getTaskPaths("inventory", "инвентаризацию"),
    taskLoadingLinks: getTaskPaths("loading", "загрузку"),
    taskMovementLinks: getTaskPaths("movement", "перемещение"),
    taskPartitionLinks: getTaskPaths("partition", "деление"),
    taskCorrectionLinks: getTaskPaths("correction", "корректировка"),
    taskShippingLinks: getTaskPaths("shipping", "отгрузку"),
    taskChangeWarehouseLinks: getTaskPaths("change-warehouse", "перемещение между складами"),
    taskWriteOffLinks: getTaskPaths("write-off", "списание"),
};
