import { ExclamationCircleOutlined, FileAddOutlined, FolderAddOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import MDIcon from "../ui/MDIcon";
const { confirm } = Modal;
const createTreeViewContectMenu = (props) => {
    var _a;
    const { currentNode, foldersName = "Категорию", itemsName = "Элемент", onDelete, onAdd, onEdit } = props;
    const handlerEdit = () => {
        if (currentNode) {
            onEdit(currentNode.id, !currentNode.isLeaf);
        }
    };
    const showDeleteConfirm = () => {
        if (currentNode) {
            const itemTypeName = (currentNode.isLeaf ? itemsName : foldersName).toLowerCase();
            confirm({
                title: `Удалить ${itemTypeName} ${currentNode.title}?`,
                icon: React.createElement(ExclamationCircleOutlined, null),
                content: (React.createElement(React.Fragment, null,
                    `Вы уверены, что хотите удалить ${itemTypeName} `,
                    React.createElement("b", null, currentNode.title),
                    "?")),
                okText: "Да",
                okType: "danger",
                cancelText: "Отмена",
                onOk() {
                    onDelete(currentNode.id, !currentNode.isLeaf);
                },
            });
        }
    };
    const itemLabel = {
        label: currentNode === null || currentNode === void 0 ? void 0 : currentNode.title,
        value: (_a = currentNode === null || currentNode === void 0 ? void 0 : currentNode.id) !== null && _a !== void 0 ? _a : "",
    };
    const node = (currentNode === null || currentNode === void 0 ? void 0 : currentNode.id) ? currentNode : null;
    const add = {
        icon: React.createElement(MDIcon, { icon: "plus-circle", className: "ant-dropdown-menu-item-icon" }),
        label: "Добавить",
        key: "add",
        children: [{ key: "add_folder", icon: React.createElement(FolderAddOutlined, null), label: foldersName, onClick: () => onAdd(itemLabel, true) }, currentNode ? { key: "add_item", icon: React.createElement(FileAddOutlined, null), label: itemsName, onClick: () => onAdd(itemLabel, false) } : null],
    };
    let contextMenuItems = [
        {
            type: "group",
            label: node === null || node === void 0 ? void 0 : node.title,
            key: "group-1",
            children: [
                ...props.preItems || [],
                !(currentNode === null || currentNode === void 0 ? void 0 : currentNode.isLeaf) ? add : null,
                { icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }), label: "Редактировать", key: "edit", onClick: handlerEdit },
                { icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }), label: "Удалить", key: "delete", danger: true, onClick: showDeleteConfirm },
                ...props.postItems || [],
            ],
        },
    ];
    return !currentNode ? [add] : contextMenuItems;
};
export default createTreeViewContectMenu;
