import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HandbookUnit from '../../components/HandbookUnit';
import { links } from '../../constants/links';
import handbookUnitApi from '../../redux/api/handbookApi/handbookUnit';
const { useFetchQuery, useAddMutation, useEditMutation, useDeleteMutation, } = handbookUnitApi;
const HandbookUnitContainer = (props) => {
    const { id: idProp } = props;
    const id = +(idProp !== null && idProp !== void 0 ? idProp : '');
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState(undefined);
    const { data, isLoading: itemIsLoading } = useFetchQuery(id, {
        skip: !id,
    });
    const [addUnit, { isLoading: addIsLoading }] = useAddMutation();
    const [editUnit, { isLoading: editIsLoading }] = useEditMutation();
    const [deleteUnit, { isLoading: deleteIsLoading }] = useDeleteMutation();
    const isLoading = itemIsLoading || addIsLoading || editIsLoading || deleteIsLoading;
    useEffect(() => {
        var _a, _b;
        if (data) {
            setInitialValues({
                title: (_a = data.title) !== null && _a !== void 0 ? _a : '',
                okeiCode: data.okeiCode,
                national: (_b = data.national) !== null && _b !== void 0 ? _b : '',
                alfaNational: data.alfaNational,
                international: data.international,
                alfaInternational: data.alfaInternational,
            });
        }
    }, [data]);
    const handlerSubmit = (values) => {
        if (id) {
            editUnit({
                handbookUnitId: id,
                values,
            });
        }
        else {
            addUnit(values)
                .then(() => navigate(links.handbookUnitLink.url()));
        }
    };
    const handlerDelete = () => {
        if (id) {
            deleteUnit(id)
                .then(() => navigate(links.handbookUnitLink.url()));
        }
    };
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(HandbookUnit, { onSubmit: handlerSubmit, onDelete: handlerDelete, deletable: !!id, initialValues: initialValues })));
};
export default HandbookUnitContainer;
