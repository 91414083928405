var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from "react";
import { useWarehouseMapWarehouseLazyQuery } from "../../../../api/graphql/queries/warehouseMap/WarehouseMapQuery";
import { useParams } from "react-router-dom";
import { enlargeIfSquare, isValidId } from "../helpers";
export const useWarehouseDate = (dispatch, warehouseId) => {
    const [getWarehouseMapWarehouse] = useWarehouseMapWarehouseLazyQuery({ fetchPolicy: "no-cache" });
    const { id } = useParams();
    useEffect(() => {
        if (isValidId(id) || warehouseId) {
            const numericId = warehouseId !== null && warehouseId !== void 0 ? warehouseId : Number(id);
            fetchWarehouseData(dispatch, getWarehouseMapWarehouse, numericId);
        }
    }, []);
    return {};
};
const fetchWarehouseData = (dispatch, fetchWarehouseMapQuery, warehouseId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    try {
        const response = yield fetchWarehouseMapQuery({
            variables: { warehouseId },
        });
        if ((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.warehouseMapWarehouse.vectorObject) === null || _b === void 0 ? void 0 : _b.shape) {
            dispatch({ type: "FETCH_WAREHOUSE_SUCCESS", payload: enlargeIfSquare((_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.warehouseMapWarehouse.vectorObject) === null || _d === void 0 ? void 0 : _d.shape) });
        }
        dispatch({ type: "FETCH_WAREHOUSE_DONE", });
        return response.data;
    }
    catch (error) {
        dispatch({ type: "FETCH_FAILURE" });
    }
});
