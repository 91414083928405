var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Dropdown, Form, Input, InputNumber, Modal, Space } from "antd";
import React from "react";
import ColoredButton from "../../ui/ColoredButton";
import ColoredText from "../../ui/ColoredText";
import MDIcon from "../../ui/MDIcon";
const { TextArea } = Input;
const TaskStatus = (props) => {
    const { loading, status, disabled, onMenuItemClick } = props;
    const [reportForm] = Form.useForm();
    const handlerMenuClick = (id, needReport) => {
        if (needReport) {
            const submitHandler = (values) => {
                onMenuItemClick(values);
                reportForm.resetFields();
            };
            const okClickHandler = (closeModal) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    yield reportForm.validateFields();
                    reportForm.submit();
                    closeModal();
                }
                catch (e) {
                    console.log(e);
                }
            });
            Modal.confirm({
                title: "Требуется отчет",
                cancelText: "Отмена",
                content: (React.createElement("div", { style: { marginLeft: "-38px" } },
                    React.createElement(Space, { direction: "vertical", style: { width: "100%" } },
                        React.createElement(Form, { layout: "vertical", form: reportForm, onFinish: submitHandler },
                            React.createElement(Form.Item, { name: "statusRelationId", hidden: true },
                                React.createElement(InputNumber, null)),
                            React.createElement(Form.Item, { label: "\u0414\u043B\u044F \u0441\u043C\u0435\u043D\u044B \u0441\u0442\u0430\u0442\u0443\u0441\u0430 \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0443\u043A\u0430\u0437\u0430\u0442\u044C \u043F\u0440\u0438\u0447\u0438\u043D\u0443", name: "report", rules: [{ required: true, message: "Укажите причину смены статуса" }] },
                                React.createElement(TextArea, { rows: 4 })))))),
                onCancel: () => reportForm.resetFields(),
                onOk: (close) => {
                    okClickHandler(close).finally();
                    return false;
                },
            });
        }
        else {
            onMenuItemClick({ statusRelationId: id });
        }
        reportForm.setFieldsValue({ statusRelationId: id });
    };
    const relationsDropdownMenu = {
        items: status.relations.map((relation) => {
            return {
                key: relation.id,
                label: React.createElement(ColoredText, { color: relation.style }, relation.title),
                onClick: () => handlerMenuClick(relation.id, relation.needReport),
            };
        }),
    };
    return (React.createElement(Dropdown, { menu: relationsDropdownMenu, trigger: !disabled ? ["click"] : [] },
        React.createElement(ColoredButton, { type: "primary", color: status.style, loading: loading },
            status.title,
            "\u00A0\u00A0",
            !disabled && React.createElement(MDIcon, { icon: "dots-vertical" }))));
};
export default TaskStatus;
