var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Input, Mentions } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ModalComponent from "./HelpModal";
export function formatFormulaInput(input) {
    if (!!input) {
        let formattedInput = input.trim();
        // Удаляем дублирующиеся скобки
        formattedInput = formattedInput.replace(/\[\[/g, "[");
        formattedInput = formattedInput.replace(/\]\]/g, "]");
        // Добавляем пробелы вокруг операторов сравнения
        formattedInput = formattedInput.replace(/(<=|>=|==|!=|=>|=<)(?=[^\s])/g, "$1 ");
        formattedInput = formattedInput.replace(/(?<=[^\s])(<=|>=|==|!=|=>|=<)/g, " $1");
        // Убеждаемся, что вокруг каждого арифметического знака есть по одному пробелу
        formattedInput = formattedInput.replace(/([\+\-\*\/])(?=[^\s])/g, "$1 ");
        formattedInput = formattedInput.replace(/(?<=[^\s])([\+\-\*\/])/g, " $1");
        // Обработка случая с '123+Round(123)'
        formattedInput = formattedInput.replace(/(\d)(?=\+|\-|\*|\/|\(|\[|[a-zA-Z])/g, "$1 ");
        formattedInput = formattedInput.replace(/(\+|\-|\*|\/|\)|\])(?=\d|[a-zA-Z])/g, "$1 ");
        return formattedInput;
    }
    return undefined;
}
const addSpacesAroundOperators = (str) => {
    return str.replace(/([\+\-\*\/])(\[)/g, "$1 $2").replace(/(\])([\+\-\*\/])/g, "$1 $2");
};
const parseAndRenderTags = (inputValue, productTypeAttributes) => {
    // Расширяем регулярное выражение для захвата любых других слов или символов
    const regex = /\[pta:([0-9]+)\]|\[quantity\]|[\+\-\*\/]|(\d+[\.,]?\d*)|[\(\)]|<=|>=|<|>|==|!=|[a-zA-Z]+|\S+/g;
    const elements = inputValue.match(regex) || [];
    return elements.map((el, index) => {
        if (/^\[pta:[0-9]+\]$/.test(el)) {
            const id = el.match(/\[pta:([0-9]+)\]/)[1];
            const attribute = productTypeAttributes.find((attr) => attr.id.toString() === id);
            return (React.createElement("span", { key: index, className: "label label-blue" }, attribute ? attribute.title : id));
        }
        switch (true) {
            case el === "[quantity]":
                return (React.createElement("span", { key: index, className: "label label-yellow" }, "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E"));
            case /^\d+[\.,]?\d*$/.test(el):
                return (React.createElement("span", { key: index, className: "label label-cyan" }, el.replace(",", ".")));
            case /[\+\-\*\/]|<=|>=|<|>|==|!=/.test(el):
                return (React.createElement("span", { key: index, className: "label label-green" }, el));
            case /[\(\)]/.test(el):
                return (React.createElement("span", { key: index, className: "label label-orange" }, el));
            case /[a-zA-Z]+/.test(el): // Любые другие слова
                return (React.createElement("span", { key: index, className: "label label-gray" }, el));
            default:
                // Любые другие символы
                return (React.createElement("span", { key: index, className: "label label-gray" }, el));
        }
    });
};
export const FormulaMaskInput = (_a) => {
    var { productTypeAttributes, title, initValue, isPersonal, isPersonalUpdated } = _a, rest = __rest(_a, ["productTypeAttributes", "title", "initValue", "isPersonal", "isPersonalUpdated"]);
    const [inputValue, setInputValue] = useState(initValue || "");
    const [filteredOptions, setFilteredOptions] = useState([]);
    useLayoutEffect(() => {
        if (isPersonalUpdated) {
            setInputValue("");
            (rest === null || rest === void 0 ? void 0 : rest.onChange) && rest.onChange(undefined);
        }
    }, [isPersonalUpdated]);
    useEffect(() => {
        const options = productTypeAttributes === null || productTypeAttributes === void 0 ? void 0 : productTypeAttributes.map((attr) => ({
            value: `[pta:${attr.id}]`,
            label: `${attr.title} [${attr.id}]`,
            children: null,
        }));
        if (isPersonal) {
            setFilteredOptions([...options, { label: "Количество", value: "[quantity]", children: null }]);
        }
        else {
            setFilteredOptions(options);
        }
    }, [inputValue, productTypeAttributes, isPersonal]);
    const onChange = (value) => {
        setInputValue(value);
        (rest === null || rest === void 0 ? void 0 : rest.onChange) && rest.onChange(value);
    };
    const onSelect = (option) => {
        const lastChar = inputValue.slice(-1);
        let newValue;
        if (["+", "-", "*", "/"].includes(lastChar)) {
            // Добавляем выбранную опцию без дополнительных пробелов
            newValue = `${inputValue}${option.value}`;
        }
        else if (lastChar === "[") {
            // Заменяем последнюю скобку на выбранную опцию без добавления пробелов
            newValue = `${inputValue.slice(0, -1)}${option.value}`;
        }
        else {
            // Просто добавляем выбранную опцию без пробелов
            newValue = `${inputValue}${option.value}`;
        }
        // Добавляем пробелы вокруг математических знаков, если их нет
        newValue = addSpacesAroundOperators(newValue);
        setInputValue(newValue);
    };
    return (React.createElement(React.Fragment, null,
        !!(filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.length) ? (React.createElement("div", { style: { display: "flex", alignItems: "center", width: "100%", gap: "8px" } },
            React.createElement(Mentions, { prefix: ["[", "+", "-", "*", "/"], style: { width: "100%" }, value: inputValue, onSelect: onSelect, onChange: onChange, options: filteredOptions }),
            React.createElement(ModalComponent, null))) : (React.createElement(Input, { disabled: true, placeholder: "\u041D\u0435\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B\u0445 \u0430\u0442\u0440\u0438\u0431\u0443\u0442\u043E\u0432" })),
        React.createElement("div", { className: "tags-container", style: { marginTop: "15px" } },
            title ? `${title}: ` : "",
            " ",
            parseAndRenderTags(inputValue, productTypeAttributes))));
};
export default FormulaMaskInput;
