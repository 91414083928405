import { userGroupAdapter } from './user';
export const statusAccessAdapter = (dto) => {
    const { id, userGroup, scope } = dto;
    return {
        id,
        scope,
        userGroup: userGroupAdapter(userGroup),
    };
};
export const statusRelationAdapter = (dto) => {
    const { id, statusToId, statusFromId, isEntryPoint, needReport, entryPointIdentifier, sendNotification, userGroups, trigger, xPos, yPos, } = dto;
    return {
        id,
        statusToId,
        statusFromId,
        isEntryPoint,
        needReport,
        entryPointIdentifier,
        sendNotification,
        userGroups: userGroups.map(userGroupAdapter),
        trigger,
        xPos: Number(xPos),
        yPos: Number(yPos),
    };
};
export const statusAdapter = (dto) => {
    const { title, style, id, statusAccesses, isCancel, xPos, yPos, isActive, isFinal, isInitial, isDefaultForFilter, statusRelationFrom, statusRelationTo, } = dto;
    return {
        id,
        xPos: +xPos,
        yPos: +yPos,
        title,
        style,
        isFinal,
        isCancel,
        isActive,
        isInitial,
        isDefaultForFilter,
        statusAccesses: statusAccesses.map(statusAccessAdapter),
        statusRelationFrom: statusRelationFrom === null || statusRelationFrom === void 0 ? void 0 : statusRelationFrom.map(statusRelationAdapter),
        statusRelationTo: statusRelationTo === null || statusRelationTo === void 0 ? void 0 : statusRelationTo.map(statusRelationAdapter),
    };
};
