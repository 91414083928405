import { Select } from "antd";
import React from "react";
var Operators;
(function (Operators) {
    Operators["Eq"] = "=";
    Operators["Neq"] = "!=";
    Operators["Gt"] = ">";
    Operators["Gte"] = ">=";
    Operators["Lt"] = "<";
    Operators["Lte"] = "<=";
})(Operators || (Operators = {}));
const options = Object.values(Operators).map((operator) => ({
    label: operator,
    value: operator,
}));
export const OperatorSelect = (props) => {
    return React.createElement(Select, Object.assign({ options: options }, props));
};
