import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskStatusFragmentDoc = gql `
    fragment TaskStatus on TaskStatusDto {
  title
  isFinal
  style
  scope
  relations {
    id
    title
    style
    needReport
  }
}
    `;
export const TaskChangeWarehouseViewFragmentDoc = gql `
    fragment TaskChangeWarehouseView on TaskChangeWarehouseDto {
  id
  createdAt
  updatedAt
  executedAt
  warehouse {
    id
    storageTitle
  }
  user {
    userName
  }
  status {
    ...TaskStatus
  }
}
    ${TaskStatusFragmentDoc}`;
export const TaskChangeWarehouseCreateDocument = gql `
    mutation TaskChangeWarehouseCreate($input: TaskChangeWarehouseAddInput!) {
  taskChangeWarehouseAdd(input: $input) {
    taskChangeWarehouseDto {
      ...TaskChangeWarehouseView
    }
  }
}
    ${TaskChangeWarehouseViewFragmentDoc}`;
/**
 * __useTaskChangeWarehouseCreateMutation__
 *
 * To run a mutation, you first call `useTaskChangeWarehouseCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeWarehouseCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskChangeWarehouseCreateMutation, { data, loading, error }] = useTaskChangeWarehouseCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskChangeWarehouseCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskChangeWarehouseCreateDocument, options);
}
export const TaskChangeWarehouseChangeStatusDocument = gql `
    mutation TaskChangeWarehouseChangeStatus($input: TaskChangeWarehouseChangeStatusInput!) {
  taskChangeWarehouseChangeStatus(input: $input) {
    taskStatusDto {
      isFinal
      scope
      style
      title
      relations {
        id
        needReport
        style
        title
      }
    }
  }
}
    `;
/**
 * __useTaskChangeWarehouseChangeStatusMutation__
 *
 * To run a mutation, you first call `useTaskChangeWarehouseChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeWarehouseChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskChangeWarehouseChangeStatusMutation, { data, loading, error }] = useTaskChangeWarehouseChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskChangeWarehouseChangeStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskChangeWarehouseChangeStatusDocument, options);
}
export const TaskChangeWarehouseAddSkuDocument = gql `
    mutation TaskChangeWarehouseAddSku($input: [TaskChangeWarehouseAddSkuInput!]!, $taskId: Long!) {
  taskChangeWarehouseAddSku(input: $input, taskId: $taskId) {
    taskChangeWarehouseSkuDto {
      quantity
      skuId
    }
  }
}
    `;
/**
 * __useTaskChangeWarehouseAddSkuMutation__
 *
 * To run a mutation, you first call `useTaskChangeWarehouseAddSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeWarehouseAddSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskChangeWarehouseAddSkuMutation, { data, loading, error }] = useTaskChangeWarehouseAddSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskChangeWarehouseAddSkuMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskChangeWarehouseAddSkuDocument, options);
}
export const TaskChangeWarehouseEditSkuDocument = gql `
    mutation TaskChangeWarehouseEditSku($taskId: Long!, $input: [TaskChangeWarehouseEditSkuInput!]!) {
  taskChangeWarehouseEditSku(input: $input, taskId: $taskId) {
    taskChangeWarehouseSkuDto {
      skuId
    }
  }
}
    `;
/**
 * __useTaskChangeWarehouseEditSkuMutation__
 *
 * To run a mutation, you first call `useTaskChangeWarehouseEditSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeWarehouseEditSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskChangeWarehouseEditSkuMutation, { data, loading, error }] = useTaskChangeWarehouseEditSkuMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskChangeWarehouseEditSkuMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskChangeWarehouseEditSkuDocument, options);
}
export const TaskChangeWarehouseDeleteSkuDocument = gql `
    mutation TaskChangeWarehouseDeleteSku($input: TaskChangeWarehouseDeleteSkuInput!) {
  taskChangeWarehouseDeleteSku(input: $input) {
    taskChangeWarehouseSkuDto {
      skuId
    }
  }
}
    `;
/**
 * __useTaskChangeWarehouseDeleteSkuMutation__
 *
 * To run a mutation, you first call `useTaskChangeWarehouseDeleteSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeWarehouseDeleteSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskChangeWarehouseDeleteSkuMutation, { data, loading, error }] = useTaskChangeWarehouseDeleteSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskChangeWarehouseDeleteSkuMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskChangeWarehouseDeleteSkuDocument, options);
}
export const TaskChangeWarehouseEditDocument = gql `
    mutation TaskChangeWarehouseEdit($input: TaskChangeWarehouseEditInput!, $taskId: Long!) {
  taskChangeWarehouseEdit(input: $input, taskId: $taskId) {
    taskChangeWarehouseDto {
      id
    }
  }
}
    `;
/**
 * __useTaskChangeWarehouseEditMutation__
 *
 * To run a mutation, you first call `useTaskChangeWarehouseEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeWarehouseEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskChangeWarehouseEditMutation, { data, loading, error }] = useTaskChangeWarehouseEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskChangeWarehouseEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskChangeWarehouseEditDocument, options);
}
