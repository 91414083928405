import React from 'react';
import classNames from 'classnames';
import ColorPalette from '../../../constants/enum/ColorPalette';
const Label = (props) => {
    const { color, icon, className, pill, text, } = props;
    const classes = {
        label: true,
        'label-pill': pill,
        mdi: icon !== undefined,
    };
    if (icon !== undefined) {
        // @ts-ignore
        classes[icon] = true;
    }
    if (color !== undefined) {
        // @ts-ignore
        classes[`label-${props.color}`] = true;
    }
    return React.createElement("span", { className: `${classNames(classes)} ${className}` }, text);
};
Label.defaultProps = {
    text: undefined,
    color: ColorPalette.Default,
    pill: false,
    icon: undefined,
    className: '',
};
export default Label;
