import { Button, Card, Col, Form, Row, } from 'antd';
import React from 'react';
const TaskForm = (props) => {
    const { onFinish, taskForm, initialValues, } = props;
    return (React.createElement(Form, { layout: "vertical", onFinish: onFinish, initialValues: initialValues },
        React.createElement(Row, { gutter: [1, 1], style: { alignItems: 'stretch' } }, taskForm),
        React.createElement(Card, { style: { margin: '0 0 20px' } },
            React.createElement(Row, null,
                React.createElement(Col, { flex: "auto" }),
                React.createElement(Col, { flex: "none" },
                    React.createElement(Button.Group, null,
                        React.createElement(Button, { htmlType: "submit" }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")))))));
};
export default TaskForm;
