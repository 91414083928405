export const filterConditionsById = (conditions, id) => conditions.filter((condition) => (condition === null || condition === void 0 ? void 0 : condition.expressionId) === id);
export const findConditionByIndex = (index, conditions) => conditions.find((condition) => (condition === null || condition === void 0 ? void 0 : condition.index) === index);
export const hasValidCondition = (conditions, id, index) => {
    const conditionsFound = filterConditionsById(conditions, id);
    const targetCondition = findConditionByIndex(index, conditionsFound);
    return !!(targetCondition === null || targetCondition === void 0 ? void 0 : targetCondition.productTypeAttributeId);
};
export const getConditionByIndex = (conditions, id, index) => {
    const conditionsFound = filterConditionsById(conditions, id);
    return findConditionByIndex(index, conditionsFound);
};
export const getConditionAttributeId = (conditions, id, index) => {
    const targetCondition = getConditionByIndex(conditions, id, index);
    return (targetCondition === null || targetCondition === void 0 ? void 0 : targetCondition.productTypeAttributeId) ? Number(targetCondition.productTypeAttributeId) : null;
};
export const isValidCondition = (condition) => {
    return condition.operator && condition.productTypeAttributeId && condition.where;
};
