import React from 'react';
import { useDispatch } from 'react-redux';
import PrintDialog from '../../components/PrintDialog';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { printDialogActions, selectPrintDialog } from '../../redux/reducers/printDialogReducer';
const PrintDialogContainer = () => {
    const { changeLoadingState, close } = printDialogActions;
    const dispatch = useDispatch();
    const { title, isOpen, loadingState, url, } = useTypedSelector(selectPrintDialog);
    return (React.createElement(PrintDialog, { title: title, url: url !== null && url !== void 0 ? url : undefined, isOpen: isOpen, loadingState: loadingState, onClose: () => dispatch(close()), changeLoadState: (state) => dispatch(changeLoadingState(state)) }));
};
export default PrintDialogContainer;
