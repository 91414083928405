var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Spin } from "antd";
import React from "react";
import Centered from "../../../../components/ui/Centered";
import Scope from "../../../../constants/enum/Scope";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useProductDrawer } from "../../../../hooks/useProductDrawer";
import { useTaskLoadingDetails } from "../../../../hooks/api/taskLoadingApi/useTaskLoadingDetails";
import { skuActions } from "../../../../redux/reducers/skuReducer";
import LoadingDetailNomenclature from "../../../conponents/task/loading/LoadingDetailNomenclature";
const LoadingTaskNomenclatureContainer = (props) => {
    const { task, taskId, isLoading, refetch, children } = props;
    const dispatch = useAppDispatch();
    const { taskDetails } = useTaskLoadingDetails();
    const { openProductDrawer } = useProductDrawer();
    const handlerEditItem = (value) => __awaiter(void 0, void 0, void 0, function* () {
        (taskDetails === null || taskDetails === void 0 ? void 0 : taskDetails.edit) && (yield taskDetails.edit({ variables: { input: { taskId, input: [value] } } }));
        yield refetch();
    });
    const handlerInfoClick = (productId) => {
        openProductDrawer(productId);
    };
    const handlerSkuInfoClick = (skuId) => dispatch(skuActions.open(skuId));
    if (isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, null)));
    }
    if (task) {
        return (React.createElement(LoadingDetailNomenclature, { isLoading: isLoading, details: task.details, warehouseId: task.warehouse.id, produceWork: taskHelpers.hasScope(task, Scope.PRODUCE_WORK), onEditItem: handlerEditItem, onInfoClick: handlerInfoClick, onSkuInfoClick: handlerSkuInfoClick }, children));
    }
    return null;
};
export default LoadingTaskNomenclatureContainer;
