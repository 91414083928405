export const TITLES = {
    packing: "Упаковка",
    packing_set: (packingName) => `Набор упаковок "${packingName}"`,
    packing_add: "Добавить упаковку",
    packing_edit: "Редактировать упаковку",
    packing_set_add: "Добавить набор упаковок",
    packing_set_edit: "Редактировать набор упаковок",
    packing_add_selected: (packingName) => `Упаковка для набора "${packingName}"`,
    packing_edit_selected: (packingName) => `Упаковка для набора "${packingName}"`,
};
export const SUCCESS_MESSAGE = {
    packing_set_with_name_create: (name) => `Набор упаковок ${name} успешно создан`,
    packing_with_name_create: (name) => `Упаковка ${name} успешно создана`,
    packing_create: "Упаковка успешно создана",
    packing_update: "Упаковка успешно изменена",
    packing_with_name_update: (name) => `Упаковка ${name} успешно изменена`,
};
export const FROM_VALIDATION_MESSAGE = "Поле не заполнено";
export const EMPTY_TABLE_MESSAGE = "Список упаковок пуст";
export const DELETE_PACKING_MODAL_MESSAGE = {
    title: "Удаление упаковки",
    message: "Вы уверены, что хотите удалить продукцию"
};
export const ADD_PACKING_BUTTON_TEXT = "Добавить упаковку";
export var FormSetNames;
(function (FormSetNames) {
    FormSetNames["ID"] = "id";
    FormSetNames["HANDBOOK_UNIT_ID"] = "handbookUnitId";
    FormSetNames["TITLE"] = "title";
})(FormSetNames || (FormSetNames = {}));
export var FormNames;
(function (FormNames) {
    FormNames["DEPTH"] = "depth";
    FormNames["HANDBOOK_UNIT_ID"] = "handbookUnitId";
    FormNames["HEIGHT"] = "height";
    FormNames["QUANTITY"] = "quantity";
    FormNames["TITLE"] = "title";
    FormNames["WEIGHT"] = "weight";
    FormNames["WIDTH"] = "width";
})(FormNames || (FormNames = {}));
export var FormNamesLocale;
(function (FormNamesLocale) {
    FormNamesLocale["DEPTH"] = "\u0413\u043B\u0443\u0431\u0438\u043D\u0430";
    FormNamesLocale["HANDBOOK_UNIT_ID"] = "\u0415\u0434\u0438\u043D\u0438\u0446\u0430 \u0438\u0437\u043C\u0435\u0440\u0435\u043D\u0438\u044F";
    FormNamesLocale["HEIGHT"] = "\u0412\u044B\u0441\u043E\u0442\u0430";
    FormNamesLocale["QUANTITY"] = "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E";
    FormNamesLocale["TITLE"] = "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435";
    FormNamesLocale["WEIGHT"] = "\u0412\u0435\u0441";
    FormNamesLocale["WIDTH"] = "\u0412\u044B\u0441\u043E\u0442\u0430";
})(FormNamesLocale || (FormNamesLocale = {}));
export const BASE_VALIDATION_RULES = { required: true, message: FROM_VALIDATION_MESSAGE };
export const TYPE_ID_PARAM = 'type';
export const TITLE_PARAM = 'title';
