import { Empty } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PackingTable from "../PackingTable/PackingTable";
import { usePagination } from "../../../../../../hooks/usePagination";
import { useHandbookPackingDeleteMutation } from "../../../../../../api/graphql/queries/handbookPacking/HandbookPackingMutations";
import { useGetHandbookPackingListLazyQuery } from "../../../../../../api/graphql/queries/handbookPacking/HandbookPackingQuery";
import { links } from "../../../../../../constants/links";
import Centered from "../../../../../../components/ui/Centered";
import { TYPE_ID_PARAM } from "../../../constants";
const PackingTableContainer = () => {
    var _a;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const typeParam = searchParams.get(TYPE_ID_PARAM);
    const typeId = typeParam ? +typeParam : null;
    const { page, pageSize } = usePagination();
    const [deleteProduct] = useHandbookPackingDeleteMutation();
    const [getLazyHandbookPacking, { data, loading }] = useGetHandbookPackingListLazyQuery();
    const handlerEdit = (id, title) => {
        navigate(links.handbookPackingEditLink.url(id, title));
    };
    const handlerDelete = (id) => {
        deleteProduct({ variables: { input: { handbookPackingId: id } } }).then(() => {
            getLazyHandbookPacking({ variables: { page, pageSize, handbookPackingSet: typeId }, fetchPolicy: "cache-and-network" });
        });
    };
    useEffect(() => {
        if (typeId) {
            getLazyHandbookPacking({ variables: { page, pageSize, handbookPackingSet: typeId }, fetchPolicy: "cache-and-network" });
        }
    }, [typeId, page, pageSize]);
    const items = ((_a = data === null || data === void 0 ? void 0 : data.handbookPackingList) === null || _a === void 0 ? void 0 : _a.data) || [];
    if (!(items === null || items === void 0 ? void 0 : items.length) && !loading) {
        return (React.createElement(Centered, null,
            React.createElement(Empty, null)));
    }
    return React.createElement(PackingTable, { items: items !== null && items !== void 0 ? items : [], loading: loading, onDelete: handlerDelete, onEdit: handlerEdit });
};
export default PackingTableContainer;
