import { Button, Dropdown } from 'antd';
import { uniqueId } from "lodash";
import React from "react";
import { links } from "../../../../constants/links";
import { getColumnType } from "../../../../helpers/function";
import TaskListItemWithSlot from "../../../../components/task/TaskList/TaskListItemWithSlot";
import TaskNomenclature from "../../../../components/task/TaskNomenclature";
import { PrinterOutlined } from '@ant-design/icons';
const columns = [getColumnType("type", "Тип"), getColumnType("title", "Номенклатура"), getColumnType("count", "Позиций", false, { align: "right" }), getColumnType("quantity", "Кол-во", false, { align: "right" })];
const ShippingTaskListItem = (props) => {
    var _a;
    const { task, printActions, pagination, onPrintActionClick } = props;
    const info = [
        { title: "Склад", value: task.warehouse.storageTitle },
        { title: "Хранитель", value: task.clientFrom.companyName },
        { title: "Грузополучатель", value: task.clientTo.companyName },
    ];
    const nomenclature = {
        columns,
        data: task.details.map((detail) => (Object.assign({ key: uniqueId() }, detail))),
    };
    const handlerPrintAction = (id) => {
        if (onPrintActionClick)
            onPrintActionClick(id);
    };
    const documentsDropdownMenu = {
        items: printActions === null || printActions === void 0 ? void 0 : printActions.map(item => {
            return {
                key: item.id,
                label: item.title,
                onClick: () => handlerPrintAction(item.id)
            };
        })
    };
    const actions = (React.createElement(React.Fragment, null, (printActions === null || printActions === void 0 ? void 0 : printActions.length) ? (React.createElement(Dropdown, { menu: documentsDropdownMenu, trigger: ["click"] },
        React.createElement(Button, { size: "small", title: 'Печать документов', icon: React.createElement(PrinterOutlined, null) }))) : null));
    return (React.createElement(TaskListItemWithSlot, { quantityTotal: (_a = nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.data) === null || _a === void 0 ? void 0 : _a.reduce((acc, item) => {
            const newCounter = acc + item.quantity;
            return newCounter;
        }, 0), task: task, info: info, pagination: pagination, slot: (nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.data) ? React.createElement(TaskNomenclature, { columns: nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.columns, dataSource: nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.data }) : React.createElement(React.Fragment, null), link: links.taskShippingLinks.page.url(task.id), actions: actions }));
};
export default ShippingTaskListItem;
