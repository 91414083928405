import { Outlet } from 'react-router-dom';
import React from 'react';
import { links } from '../constants/links';
import ShippingListPage from '../core/pages/task/shipping/ShippingListPage';
import ShippingTaskPage from '../core/pages/task/shipping/ShippingTaskPage';
import ShippingAddPage from '../core/pages/task/shipping/ShippingAddPage';
import ShippingEditPage from '../core/pages/task/shipping/ShippingEditPage';
const paths = links.taskShippingLinks;
const taskShippingRoutes = {
    title: paths.list.title,
    breadcrumb: 'Отгрузка',
    path: paths.list.path,
    element: React.createElement(Outlet, null),
    children: [
        {
            index: true,
            element: React.createElement(ShippingListPage, null),
        },
        {
            title: paths.page.title,
            breadcrumb: paths.page.breadcrumb,
            path: paths.page.url(':taskId'),
            element: React.createElement(Outlet, null),
            children: [
                {
                    index: true,
                    element: React.createElement(ShippingTaskPage, null),
                },
                {
                    title: paths.edit.title,
                    breadcrumb: paths.edit.breadcrumb,
                    path: paths.edit.url(':taskId'),
                    element: React.createElement(ShippingEditPage, null),
                },
            ],
        },
        {
            title: paths.add.title,
            breadcrumb: paths.add.breadcrumb,
            path: paths.add.url(),
            element: React.createElement(ShippingAddPage, null),
        },
    ],
};
export default taskShippingRoutes;
