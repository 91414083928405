import { createApi } from "@reduxjs/toolkit/query/react";
import dtoToEntity from '../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../constants/enum/Method';
import fetchAppQuery from '../../fetchAppQuery';
const URI = 'client';
export const clientsApi = createApi({
    reducerPath: 'clientsApi',
    baseQuery: fetchAppQuery,
    endpoints: (builder) => ({
        fetchList: builder.query({
            query: (args) => {
                const { page, pageSize, search } = args;
                return {
                    method: Method.GET,
                    url: `${URI}/list`,
                    params: { page, pageSize, search },
                };
            },
            transformResponse: (response) => ({
                list: response.data.map(dtoToEntity.clientAdapter),
                pagination: dtoToEntity.paginationAdapter(response.pagination),
            }),
        }),
    }),
});
export default clientsApi.reducer;
export const { useFetchListQuery } = clientsApi;
