var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from "react";
import { getDocumentUrl } from "../../api/rest/requests/documents/documents";
import SkuInfoDrawer from "../../components/SkuInfoDrawer";
import ObjectClass from "../../constants/enum/ObjectClass";
import { useSku } from "../../hooks/api/sku/useSku";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useDocumentAvailable } from "../../hooks/useDocumentAvailable";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { printDialogActions } from "../../redux/reducers/printDialogReducer";
import { skuActions } from "../../redux/reducers/skuReducer";
import { useSkuAdapter } from "../pages/NomenclaturePage/hooks/useSkuAdapter";
const SkuInfoDrawerContainer = (props) => {
    const { skuId, onClose, isOpen } = props;
    const { open, history, info, loadingState, id } = useTypedSelector((state) => state.sku.drawer);
    const { setHistory, setInfo } = skuActions;
    const { history: getHistory, sku } = useSku();
    const { adaptSkuHistoryResponseToSkuHistoryEntity, adaptSku } = useSkuAdapter();
    const { close: closeSkuDrawerAction, open: openSkuDrawerAction } = skuActions;
    const documentsAvailable = useDocumentAvailable(ObjectClass.SKU);
    const { open: openPrintDialog } = printDialogActions;
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (open) {
            const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c, _d, _e, _f;
                const historyData = yield getHistory.getHistory({ variables: { skuId: +((_a = skuId !== null && skuId !== void 0 ? skuId : id) !== null && _a !== void 0 ? _a : "") } });
                const skuData = yield sku.getById({ variables: { skuId: +((_b = skuId !== null && skuId !== void 0 ? skuId : id) !== null && _b !== void 0 ? _b : "") } });
                if ((_c = historyData.data) === null || _c === void 0 ? void 0 : _c.skuHistory) {
                    dispatch(setHistory(adaptSkuHistoryResponseToSkuHistoryEntity((_d = historyData.data) === null || _d === void 0 ? void 0 : _d.skuHistory)));
                }
                if ((_e = skuData === null || skuData === void 0 ? void 0 : skuData.data) === null || _e === void 0 ? void 0 : _e.sku) {
                    dispatch(setInfo(adaptSku((_f = skuData.data) === null || _f === void 0 ? void 0 : _f.sku)));
                }
            });
            fetch();
        }
    }, [open]);
    useEffect(() => {
        if (isOpen === true) {
            if (skuId)
                dispatch(openSkuDrawerAction(skuId));
        }
        else if (isOpen === false) {
            dispatch(closeSkuDrawerAction());
        }
    }, [isOpen]);
    const handlerClose = () => {
        dispatch(closeSkuDrawerAction());
        if (onClose)
            onClose();
    };
    const handlerPrintAction = (documentId, objectId) => dispatch(openPrintDialog(getDocumentUrl(documentId, objectId)));
    return React.createElement(SkuInfoDrawer, { sku: info, history: history, isOpen: open, onClose: handlerClose, loading: getHistory.loading || sku.loading, printActions: documentsAvailable, onPrintActionClick: handlerPrintAction });
};
export default SkuInfoDrawerContainer;
