import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProductListHeader from "../../components/ProductListHeader";
import { links } from "../../constants/links";
import { productTypeGraphqlApi } from "../../redux/api/productApi/productType";
import { TYPE_ID_PARAM } from "../pages/product/ProductsPage";
const { useFetchProductTypeQuery } = productTypeGraphqlApi;
const ProductListHeaderContainer = () => {
    var _a;
    const [searchParams] = useSearchParams();
    const productTypeParam = searchParams.get(TYPE_ID_PARAM);
    const productTypeId = productTypeParam ? +productTypeParam : null;
    const { data: type } = useFetchProductTypeQuery({ productTypeId: productTypeId }, { skip: !Number.isInteger(productTypeId) });
    const navigate = useNavigate();
    const handlerAdd = () => {
        if (type) {
            navigate(links.handbookProductAddLink.url(type.id));
        }
    };
    const isShowActions = !!type && !type.isExternal;
    return React.createElement(ProductListHeader, { title: (_a = type === null || type === void 0 ? void 0 : type.title) !== null && _a !== void 0 ? _a : "Продукция", onAddClick: handlerAdd, showActions: isShowActions });
};
export default ProductListHeaderContainer;
