var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { filterNonFormylaTypeInput, filterReadOnlyInput } from "../../../../../../components/FormulaMaskInput/FormulaMaskInputContainer";
import { getColumnType } from "../../../../../../helpers/function";
import CorrectionDetailNomenclatureActions from "../../CorrectionDetailNomenclatureActions";
import { useFormContext } from "../CorrectionDetailNomenclatureFormItemContainer";
import { createInfoCell, labeledValueRender, processAttributes, showEditConfirm } from "../helpers";
import { RowType } from "../types";
const onCell = (record) => {
    var _a;
    return ({
        isHeader: record === null || record === void 0 ? void 0 : record.isHeader,
        title: (_a = record === null || record === void 0 ? void 0 : record.id) === null || _a === void 0 ? void 0 : _a.toString(),
    });
};
export const useTableColumns = ({ data, flags, actions }) => {
    const { attributes, id } = data;
    const { isLoading, isEdit = false } = flags;
    const context = useFormContext();
    if (!context) {
        throw new Error("useFormContext must be used within a FormContext.Provider");
    }
    const { form, afterProductType } = context;
    const [editingKey, setEditingKey] = useState("");
    const { onAddCreated, onDeleteCreated, onEditCreated, onSkuInfoClick } = actions;
    const handleCancel = () => {
        setEditingKey("");
    };
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        const formData = yield (form === null || form === void 0 ? void 0 : form.validateFields());
        const taskCorrectionSkuData = {
            taskCorrectionSkuId: id,
            productIdAfter: afterProductType ? afterProductType.value : undefined,
            attributes: processAttributes(formData, attributes),
        };
        if ("quantity" in formData)
            taskCorrectionSkuData.quantityAfter = +formData.quantity;
        if ("reason" in formData)
            taskCorrectionSkuData.reason = formData.reason;
        const data = [taskCorrectionSkuData];
        onEditCreated(data);
        handleCancel();
    });
    const handleAddCreated = (originalId) => {
        onAddCreated({
            originalId,
            quantity: 1,
            tolerancePercent: 0,
        });
    };
    const handleDeleteCreated = (createdId) => {
        onDeleteCreated(createdId);
    };
    const handleEdit = (record) => {
        var _a, _b;
        if (!!editingKey) {
            showEditConfirm(() => {
                handleCancel();
                handleEdit(record);
            }, editingKey);
        }
        else {
            const attr = Object.keys(record)
                .filter((key) => key.includes("attr_"))
                .reduce((acc, item) => {
                acc[item] = record[item].value;
                return acc;
            }, {});
            attr.quantity = (_a = record === null || record === void 0 ? void 0 : record.quantity) === null || _a === void 0 ? void 0 : _a.value;
            attr.reason = (_b = record === null || record === void 0 ? void 0 : record.reason) === null || _b === void 0 ? void 0 : _b.value;
            form === null || form === void 0 ? void 0 : form.setFieldsValue(attr);
            setEditingKey(record.key);
        }
    };
    const isEditing = (record) => record.key === editingKey;
    const columns = [
        getColumnType("id", "#", true, {
            fixed: "left",
            width: "80px",
            onCell,
        }),
        getColumnType("product", "ГОСТ", true, {
            fixed: "left",
            render: (value) => {
                return React.createElement("div", null, value === null || value === void 0 ? void 0 : value.label);
            },
            onCell: (record) => (Object.assign(Object.assign({}, onCell(record)), { record, value: record, editing: isEditing(record), width: "400px", dataIndex: "product" })),
        }),
        getColumnType("type", "Тип", true, {
            fixed: "left",
            width: "80px",
            onCell,
            render: (value) => React.createElement(React.Fragment, null, value === RowType.Before ? "До" : "После"),
        }),
        getColumnType("storageCell", "Карман", false, {
            width: "100px",
            render: labeledValueRender,
            onCell: (record) => (Object.assign(Object.assign({}, onCell(record)), { record, value: record, dataIndex: "storageCell", editing: false })),
        }),
        ...filterReadOnlyInput(filterNonFormylaTypeInput(attributes)).map((attr) => {
            return getColumnType(`attr_${attr.id}`, attr.title, false, {
                width: "100px",
                render: (d) => createInfoCell(d, attr === null || attr === void 0 ? void 0 : attr.unit),
                onCell: (record) => (Object.assign(Object.assign({}, onCell(record)), { record, dataIndex: `attr_${attr.id}`, editingKey: attr.id, value: attr.valueAfter, attributes: {
                        dataType: attr.dataType,
                        id: attr.id,
                        handbookId: attr.handbookId,
                        isRequired: attr.isRequired,
                        unit: attr.unit,
                    }, editing: isEditing(record) })),
            });
        }),
        getColumnType("quantity", "Кол-во", false, {
            width: "100px",
            render: (d) => {
                return React.createElement(React.Fragment, null, (d === null || d === void 0 ? void 0 : d.value) ? `${d === null || d === void 0 ? void 0 : d.value}${d.unit ? " " + d.unit : ""}` : "");
            },
            onCell: (record) => {
                var _a;
                return (Object.assign(Object.assign({}, onCell(record)), { record, value: record, editing: isEditing(record), attributes: {
                        dataType: "Float",
                        unit: (_a = record === null || record === void 0 ? void 0 : record.quantity) === null || _a === void 0 ? void 0 : _a.unit,
                    }, width: "200px", dataIndex: "quantity" }));
            },
        }),
        getColumnType("reason", "Причина", false, {
            width: "100px",
            fixed: "right",
            render: (value) => React.createElement("span", { style: { maxWidth: "100px", wordBreak: "break-all", whiteSpace: "normal" } }, (value === null || value === void 0 ? void 0 : value.value) ? value.value : ""),
            onCell: (record) => (Object.assign(Object.assign({}, onCell(record)), { record, value: record, editing: isEditing(record), width: "200px", dataIndex: "reason" })),
        }),
        getColumnType("actions", "", false, {
            width: "1%",
            fixed: "right",
            render: (value, record) => {
                const isEditable = isEditing(record);
                return (React.createElement(CorrectionDetailNomenclatureActions, { isLoading: isLoading, id: record.rowId, skuId: record.id, rowType: record.type, isEdit: isEdit, isEditable: isEditable, isOriginal: !!record.isOriginal, onDeleteCreated: handleDeleteCreated, onAddCreated: handleAddCreated, onSkuInfoClick: onSkuInfoClick, onEditCreated: () => {
                        handleEdit(record);
                    }, onCancel: handleCancel, onSaveCreated: handleSave }));
            },
        }),
    ];
    return columns;
};
