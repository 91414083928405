var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Col, Row, Skeleton, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { useStorageCellLoadLazyQuery } from "../../../../../../api/graphql/queries/storageCell/StorageCellQuery";
import { SectionCellContainer } from "./components/SectionCellContainer";
const { Title } = Typography;
export const SectionContainer = ({ warehouseId }) => {
    const [getWarehouseData, { loading, error }] = useStorageCellLoadLazyQuery();
    const [warehouseData, setWarhauseData] = useState(null);
    useEffect(() => {
        if (error) {
            message.error("Ошибка получения списка секций");
        }
    }, [error]);
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            yield getWarehouseData({ variables: { warehouseId } }).then((res) => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                const storageCellLoads = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.storageCellLoad;
                const sectionsMap = new Map();
                storageCellLoads === null || storageCellLoads === void 0 ? void 0 : storageCellLoads.forEach((cell) => {
                    const { storageSectionId, storageSectionAddress } = cell, cellDetails = __rest(cell, ["storageSectionId", "storageSectionAddress"]);
                    if (!sectionsMap.has(storageSectionId)) {
                        sectionsMap.set(storageSectionId, {
                            sectionId: storageSectionId,
                            sectionAddress: storageSectionAddress !== null && storageSectionAddress !== void 0 ? storageSectionAddress : "",
                            cells: [],
                        });
                    }
                    sectionsMap.get(storageSectionId).cells.push({
                        cellId: cell.storageCellId,
                        cellAddress: cell.storageCellAddress,
                        cellTitle: cell.storageCellTitle,
                        loadPercent: cell.storageCellLoadPercent,
                        skuCount: cell.storageCellSkuCount,
                    });
                });
                const sectionsData = Array.from(sectionsMap.values());
                setWarhauseData(sectionsData);
            }));
        });
        fetchData();
    }, []);
    if (loading) {
        return React.createElement(SectionSkeleton, null);
    }
    if (Array.isArray(warehouseData) && !(warehouseData === null || warehouseData === void 0 ? void 0 : warehouseData.length) && !loading) {
        return (React.createElement(Row, { gutter: [24, 24], wrap: true },
            React.createElement(Col, null,
                React.createElement(Typography.Title, { className: "m-0", level: 5 }, "\u0421\u043F\u0438\u0441\u043E\u043A \u0441\u0435\u043A\u0446\u0438\u0439 \u043F\u0443\u0441\u0442"))));
    }
    return (React.createElement(Row, { gutter: [24, 24], wrap: true }, warehouseData === null || warehouseData === void 0 ? void 0 : warehouseData.map((item, index) => (React.createElement(Col, { key: index, style: { display: "flex", flexDirection: "column", gap: "8px" } },
        React.createElement(Title, { level: 4, style: { marginBottom: "8px" } }, item.sectionAddress),
        React.createElement(Row, { gutter: [2, 2], wrap: true, style: { columnGap: "2px" } },
            React.createElement(SectionCellContainer, { cells: item.cells })))))));
};
const SectionSkeleton = () => {
    return (React.createElement(React.Fragment, null, Array.from({ length: 2 }).map((_, index) => (React.createElement(Col, { key: index, style: { display: "flex", flexDirection: "column", gap: "8px", width: "100%", marginBottom: "12px" } },
        React.createElement(Skeleton.Button, { active: true, style: { width: 400 } }),
        React.createElement(Skeleton.Button, { active: true, style: { width: "100%", height: "200px" } }))))));
};
