import React from 'react';
import Comments from '../../components/Comments';
import { useAddMutation, useFetchListQuery } from '../../redux/api/commentsApi';
const CommentsContainer = (props) => {
    const { objectClass, objectId } = props;
    const { data, isLoading, isSuccess, refetch } = useFetchListQuery({ objectClass, objectId });
    const [addComment, { isLoading: addLoading }] = useAddMutation();
    const handlerAdd = (message) => addComment({ objectClass, objectId, message });
    return (React.createElement(Comments, { refetch: refetch, comments: data !== null && data !== void 0 ? data : [], isLoading: isLoading || addLoading, loadSuccess: isSuccess, onAddComment: handlerAdd }));
};
export default CommentsContainer;
