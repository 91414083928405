var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Table, Tooltip, } from 'antd';
import React, { useState } from 'react';
import MDIcon from '../../../../../components/ui/MDIcon';
import { getColumnType } from '../../../../../helpers/function';
import MovementDetailNomenclatureActions from './MovementDetailNomenclatureActions';
import MovementDetailNomenclatureCell from './MovementDetailNomenclatureCell';
const { confirm } = Modal;
const ACTIONS_KEY = 'actions';
const MovementDetailNomenclatureItem = (props) => {
    const { details, warehouseId, editable, onDeleteItem, onEditItem, onSkuInfoClick, } = props;
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;
    const labeledValueRender = (value) => {
        if (value && value.label) {
            return value.label;
        }
        return value;
    };
    const columns = [
        getColumnType('skuId', '#', false, { width: '1%' }),
        getColumnType('type', 'Тип продукции', false, { width: '1%' }),
        getColumnType('title', 'Номенклатура'),
        {
            title: 'Ячейка',
            children: [
                getColumnType('storageCellFrom', 'До', false, { width: '1%' }),
                getColumnType('storageCellTo', 'После', false, {
                    width: '1%',
                    render: labeledValueRender,
                    onCell: (record) => ({
                        record,
                        warehouseId,
                        value: record.storageCellTo,
                        dataIndex: 'storageCellTo',
                        title: 'После',
                        editing: isEditing(record),
                    }),
                }),
            ],
        },
    ];
    const data = details.map((detail) => {
        var _a, _b, _c;
        return ({
            key: detail.skuId.toString(),
            id: detail.id.toString(),
            skuId: detail.skuId.toString(),
            type: detail.type || '',
            title: detail.title || '',
            storageCellFrom: ((_a = detail.storageCellFrom) === null || _a === void 0 ? void 0 : _a.title) || '',
            storageCellTo: {
                label: ((_b = detail.storageCellTo) === null || _b === void 0 ? void 0 : _b.title) || '',
                value: ((_c = detail.storageCellTo) === null || _c === void 0 ? void 0 : _c.id) || '',
            },
        });
    }) || [];
    const handlerCancel = () => {
        setEditingKey('');
    };
    const showEditConfirm = (callback) => {
        confirm({
            title: `Изменения в товарной позиции #${editingKey} не сохранены`,
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: `Вы не сохранили изменения в товарной позиции #${editingKey}. Отменить все изменения?`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk() {
                callback();
            },
        });
    };
    const handlerEdit = (currentEditingKey, record) => {
        if (currentEditingKey) {
            showEditConfirm(() => {
                handlerCancel();
                handlerEdit('', record);
            });
        }
        else {
            form.setFieldsValue(record);
            setEditingKey(record.key);
        }
    };
    const handlerDelete = (detailsId) => {
        if (onDeleteItem)
            onDeleteItem(detailsId);
    };
    const handlerSave = (skuId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const row = (yield form.validateFields());
            const formValues = {
                taskMovementDetailsId: skuId,
                storageCellToId: Number(row.storageCellTo.value),
            };
            if (onEditItem)
                onEditItem(formValues);
            setEditingKey('');
        }
        catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    });
    const handlerOpenSkuInfo = (id) => {
        if (onSkuInfoClick)
            onSkuInfoClick(id);
    };
    const actionsRender = (value, record) => {
        const isEditable = isEditing(record);
        return (React.createElement(MovementDetailNomenclatureActions, { skuId: record.id, isEditable: isEditable, onEditClick: () => handlerEdit(editingKey, record), onDeleteClick: () => handlerDelete(Number(record.id)), onSaveClick: () => handlerSave(Number(record.id)), onCancelClick: handlerCancel, onSkuInfoClick: () => handlerOpenSkuInfo(record.skuId) }));
    };
    columns.push(getColumnType(ACTIONS_KEY, '', false, {
        fixed: 'right',
        width: '1%',
        render: (value, record) => {
            if (editable) {
                return actionsRender(value, record);
            }
            return (React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: () => handlerOpenSkuInfo(record.skuId) })));
        },
    }));
    return (React.createElement("div", { className: "mb-5" },
        React.createElement(Form, { form: form, component: false },
            React.createElement(Table, { style: { whiteSpace: 'nowrap' }, components: {
                    body: {
                        cell: MovementDetailNomenclatureCell,
                    },
                }, columns: columns, dataSource: data, bordered: true, pagination: false, size: "small", scroll: { x: true } }))));
};
export default MovementDetailNomenclatureItem;
