import React from "react";
import SkuSearchModal from "./SkuSearchModal";
import { useSku } from "../../../../hooks/api/sku/useSku";
import { useSkuAdapter } from "../../../../core/pages/NomenclaturePage/hooks/useSkuAdapter";
const SkuSearchModalWrapper = (props) => {
    const { skuList } = useSku();
    const { adaptToPaginatedResult, adaptProductFilterValuesToSkuFilterInput } = useSkuAdapter();
    return React.createElement(SkuSearchModal, Object.assign({ adaptProductFilterValuesToSkuFilterInput: adaptProductFilterValuesToSkuFilterInput, adaptToPaginatedResult: adaptToPaginatedResult, getSkuList: skuList.getSkuList }, props));
};
export default SkuSearchModalWrapper;
