import { Button, Col, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../../components/Page';
import MDIcon from '../../../components/ui/MDIcon';
import { links } from '../../../constants/links';
import HandbookUnitsPaginationContainer from '../../containers/HandbookUnitsPaginationContainer';
import HandbookUnitsTableContainer from '../../containers/HandbookUnitsTableContainer';
const HandbookUnitsPage = () => {
    const navigate = useNavigate();
    const handlerEditItemClick = (id) => {
        navigate(links.handbookUnitItemLink.url(id));
    };
    const handlerAddBtnClick = () => {
        navigate(links.handbookUnitAddLink.url());
    };
    return (React.createElement(Page, { toolbarBottom: (React.createElement(HandbookUnitsPaginationContainer, null)), toolbarTop: (React.createElement(Row, { justify: "end" },
            React.createElement(Col, null,
                React.createElement(Button, { icon: React.createElement(MDIcon, { icon: "plus" }), type: "primary", onClick: handlerAddBtnClick }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0435\u0434\u0438\u043D\u0438\u0446\u0443 \u0438\u0437\u043C\u0435\u0440\u0435\u043D\u0438\u044F")))) },
        React.createElement(HandbookUnitsTableContainer, { onEditItemClick: handlerEditItemClick })));
};
export default HandbookUnitsPage;
