import { Button, Card, Space, Table, Tooltip, Typography } from "antd";
import React from "react";
import MDIcon from "../../../../../components/ui/MDIcon";
import { getColumnType } from "../../../../../helpers/function";
import LoadingDetailNomenclatureCell from "../../loading/LoadingDetailNomenclature/LoadingDetailNomenclatureCell";
import ShippingDetailNomenclatureActions from "./ShippingDetailNomenclatureActions";
import { ShippingDetailNominclatureRequirement } from "./ShippingDetailNominclatureRequirement";
import { ShippingDetailNominclatureProductInfo } from "./ShippingDetailNominclatureProductInfo";
const { Title, Text } = Typography;
const ACTIONS_KEY = "actions";
const SPACE_KEY = "space";
const STORAGE_KEY = "storage";
const QUANTITY_KEY = "quantity";
const ShippingDetailNomenclatureItem = (props) => {
    var _a;
    const { nomenclature, editable, isLoading, warehouseId, onDeleteItem, onInfoClick, onSkuInfoClick, refetch } = props;
    const { type, title, attributes, productId, requirementIsRequired, requirementQuantity, requirementId, requirementComment } = nomenclature;
    let countAttr = 0;
    const columns = [
        getColumnType("id", "#", false, {
            fixed: "left",
            width: "1%",
        }),
        ...((attributes === null || attributes === void 0 ? void 0 : attributes.filter((attr) => attr.isPersonal).map((attr) => {
            const { id, title: attrTitle } = attr;
            countAttr += 1;
            return getColumnType(id.toString(), attrTitle, false, { width: "1%" });
        })) || []),
        getColumnType(SPACE_KEY),
        getColumnType(STORAGE_KEY, "Ячейка склада", false, { width: "1%" }),
        getColumnType(QUANTITY_KEY, "Количество", false, {
            fixed: "right",
            width: "1%",
        }),
    ];
    const data = ((_a = nomenclature.skus) === null || _a === void 0 ? void 0 : _a.map((sku) => {
        const result = {
            id: sku.id.toString(),
            detailsId: sku.detailsId.toString(),
            key: sku.id.toString(),
            storage: sku === null || sku === void 0 ? void 0 : sku.storageCell,
            quantity: (sku === null || sku === void 0 ? void 0 : sku.quantity) ? `${sku === null || sku === void 0 ? void 0 : sku.quantity}` : "0",
        };
        attributes === null || attributes === void 0 ? void 0 : attributes.forEach((prodTypeAttr) => {
            const attr = sku.skuAttributeValues.find((attr) => attr.key === prodTypeAttr.id);
            if (attr) {
                result[prodTypeAttr.id.toString()] = attr.value.valueReadable;
            }
        });
        return result;
    })) || [];
    const handlerDelete = (detailsId) => {
        if (onDeleteItem)
            onDeleteItem(detailsId);
    };
    const handlerOpenInfo = () => {
        if (onInfoClick)
            onInfoClick(productId);
    };
    const handlerOpenSkuInfo = (id) => {
        if (onSkuInfoClick)
            onSkuInfoClick(id);
    };
    const actionsRender = (value, record) => React.createElement(ShippingDetailNomenclatureActions, { isLoading: !!isLoading, skuId: record.id, onDeleteClick: () => handlerDelete(parseInt(record.detailsId, 10)), onSkuInfoClick: () => handlerOpenSkuInfo(record.id) });
    const additionalInfoRender = () => {
        if (requirementId)
            return (React.createElement(React.Fragment, null,
                React.createElement(ShippingDetailNominclatureRequirement, { refetch: refetch, requirementId: requirementId, requirementIsRequired: requirementIsRequired, requirementQuantity: requirementQuantity, requirementComment: requirementComment }),
                React.createElement("br", null),
                React.createElement(ShippingDetailNominclatureProductInfo, { productId: productId, warehouseId: warehouseId }),
                React.createElement("br", null)));
    };
    columns.push({
        title: "",
        dataIndex: ACTIONS_KEY,
        key: ACTIONS_KEY,
        fixed: "right",
        width: "1%",
        render: (value, record) => {
            if (editable) {
                return actionsRender(value, record);
            }
            return (React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: () => handlerOpenSkuInfo(record.id) })));
        },
    });
    countAttr += 1;
    return (React.createElement(Card, { key: productId, type: "inner", title: React.createElement(Space, { style: { width: "auto" } },
            React.createElement(Title, { level: 4 }, type),
            React.createElement("div", { className: "mb-2" },
                React.createElement(Text, { underline: true }, title))), extra: React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u0442\u0438\u043F\u0435 \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
            React.createElement(Button, { type: "primary", size: "small", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: handlerOpenInfo })) },
        additionalInfoRender(),
        React.createElement(Table, { className: "mt-2", style: { whiteSpace: "nowrap" }, components: {
                body: {
                    cell: LoadingDetailNomenclatureCell,
                },
            }, columns: columns, dataSource: data, bordered: true, pagination: false, size: "small", scroll: { x: true }, summary: (pageData) => {
                const totalQuantity = pageData.reduce((total, row) => total + parseFloat(row.quantity), 0);
                const offset = countAttr + 3;
                return (React.createElement(Table.Summary.Row, null,
                    React.createElement(Table.Summary.Cell, { index: 0, colSpan: offset }),
                    React.createElement(Table.Summary.Cell, { index: offset, colSpan: editable ? 2 : 1 }, totalQuantity)));
            } })));
};
export default ShippingDetailNomenclatureItem;
