import { links } from '../constants/links';
const navigation = [
    {
        title: 'Склад',
        icon: 'mdi-package-variant-closed',
        sections: [
            {
                title: '',
                links: [
                    { title: 'Дашборд', icon: 'mdi-view-dashboard', route: links.homeLink },
                ],
            },
            {
                title: 'Наличие',
                links: [
                    { title: 'Номенклатура', icon: 'mdi-format-list-bulleted-type', route: links.nomenclatureLink },
                ],
            },
            {
                title: 'Задачи',
                links: [
                    { title: 'Загрузка', icon: 'mdi-download', route: links.taskLoadingLinks.list },
                    { title: 'Отгрузка', icon: 'mdi-upload', route: links.taskShippingLinks.list },
                    { title: 'Деление', icon: 'mdi-content-cut', route: links.taskPartitionLinks.list },
                    { title: 'Перемещение', icon: 'mdi-shuffle-disabled', route: links.taskMovementLinks.list },
                    { title: 'Перемещение между складами', icon: 'mdi-package-variant-closed', route: links.taskChangeWarehouseLinks.list },
                    { title: 'Списание', icon: 'mdi-minus-box', route: links.taskWriteOffLinks.list },
                    { title: 'Инвентаризация', icon: 'mdi-shuffle', route: links.taskInventoryLinks.list },
                    { title: 'Корректировка', icon: 'mdi-pencil-circle-outline', route: links.taskCorrectionLinks.list },
                ],
            },
        ],
    },
    {
        title: 'Отчеты',
        icon: 'mdi-finance',
        sections: [
            {
                title: 'Наличие',
                links: [
                    { title: 'Движение за период', icon: 'mdi-folder', route: links.reportMovementForPeriodLink },
                    // { title: 'Наличие на дату - старый', icon: 'mdi-folder', route: links.reportAvailabilityOnDateLinkOld },
                    { title: 'Наличие на дату', icon: 'mdi-folder', route: links.reportAvailabilityOnDateLink },
                ],
            },
        ],
    },
    {
        title: 'Администрирование',
        icon: 'mdi-wrench-outline',
        sections: [
            {
                title: 'Справочник',
                links: [
                    { title: 'Динамический справочник', icon: 'mdi-folder', route: links.handbookDynamicLink },
                    { title: 'Единицы измерения', icon: 'mdi-folder', route: links.handbookUnitLink },
                    { title: 'Упаковки', icon: 'mdi-folder', route: links.handbookPackingLink },
                    { title: 'Продукция', icon: 'mdi-folder', route: links.handbookProductLink },
                    { title: 'Контрагенты', icon: 'mdi-folder', route: links.handbookClientsLink },
                    { title: 'Списки складов', icon: 'mdi-folder', route: links.handbookWarehouseListLink },
                ],
            },
            {
                title: 'Пользователи',
                links: [
                    { title: 'Аккаунты', icon: 'mdi-account', route: links.accountsUsersLink },
                    { title: 'Группы', icon: 'mdi-account-multiple', route: links.accountsGroupsLink },
                ],
            },
        ],
    },
];
export default navigation;
