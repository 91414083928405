import React from "react";
import { Col, Empty, Row } from "antd";
import ServiceApplication from "./ServiceApplication";
const ServiceItem = (props) => {
    const { service } = props;
    return (React.createElement(Row, null,
        React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
            React.createElement("h3", null, service.title),
            React.createElement("div", { style: { marginBottom: '15px' } }, service.description),
            React.createElement("div", null, service.url != null ? React.createElement("a", { href: service.url, target: '_blank' }, "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u0432 \u0441\u0435\u0440\u0432\u0438\u0441") : '')),
        React.createElement(Col, { xs: { span: 24 }, md: { span: 12 } },
            service.applications.length == 0 ? React.createElement(Empty, { description: 'Нет доступных приложений' }) : '',
            service.applications.map((application) => React.createElement(ServiceApplication, { application: application })))));
};
export default ServiceItem;
