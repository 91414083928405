import React from "react";
import { getDocumentUrl } from "../../../../api/rest/requests/documents/documents";
import TaskList from "../../../../components/task/TaskList";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useDocumentAvailable } from "../../../../hooks/useDocumentAvailable";
import { printDialogActions } from "../../../../redux/reducers/printDialogReducer";
import MovementTaskListItem from "../../../conponents/task/movement/MovementTaskListItem";
const MovementTaskListContainer = ({ taskList, pagination, isLoading }) => {
    var _a;
    const { open: openPrintDialog } = printDialogActions;
    const dispatch = useAppDispatch();
    const documentsAvailable = useDocumentAvailable(ObjectClass.TaskMovement);
    const handlerPrintAction = (documentId, objectId) => dispatch(openPrintDialog(getDocumentUrl(documentId, objectId)));
    const createTaskComponent = (task) => React.createElement(MovementTaskListItem, { pagination: pagination, key: task.id, task: task, onPrintActionClick: (documentId) => handlerPrintAction(documentId, task.id.toString()), printActions: documentsAvailable });
    return React.createElement(TaskList, { isLoading: isLoading }, (_a = taskList === null || taskList === void 0 ? void 0 : taskList.map(createTaskComponent)) !== null && _a !== void 0 ? _a : []);
};
export default MovementTaskListContainer;
