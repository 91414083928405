import React, { useEffect } from 'react';
import UserPanel from '../../components/UserPanel';
import LoadingState from '../../constants/enum/LoadingState';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { appActions } from '../../redux/reducers/appReducer';
import { fetchUserProfile } from '../../redux/reducers/userReducer/actionCreators';
const UserPanelContainer = () => {
    const { toggleUserPanel, toggleSidebar } = appActions;
    const { userPanelOpen, sidebarOpen, } = useTypedSelector((state) => state.app);
    const { data, loadingState } = useTypedSelector((state) => state.user);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!(data === null || data === void 0 ? void 0 : data.name)) {
            dispatch(fetchUserProfile());
        }
    }, []);
    return (React.createElement(UserPanel, { name: data === null || data === void 0 ? void 0 : data.name, loading: loadingState === LoadingState.Loading, toggle: userPanelOpen, onHeaderClick: () => dispatch(toggleUserPanel()), onLeftArrowClick: () => dispatch(toggleSidebar(!sidebarOpen)) }));
};
export default UserPanelContainer;
