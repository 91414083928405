import { Dnd } from "@antv/x6/es/addon/dnd";
import { Graph } from "@antv/x6/es/graph";
import { Card, Spin } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controls } from "./Controls";
import { Drawer } from "./Drawer";
import { MapWithThree } from "./MapWithThree";
import { Three } from "./components/Three";
import { useCellEditor } from "./hooks/api/useCellEditor";
import { defaultTools } from "./hooks/state";
import { useRenderCells } from "./hooks/useRenderCells";
import { useResizeGraph } from "./hooks/useResizeGraph";
import { useWarehouseState } from "./hooks/useWarehouseState";
import { createNodeFromVectorObject } from "./utils/nodeFactory";
import { createNodeFromShape } from "./utils/warehouseLayoutFactory";
import { Map } from "./Map";
import { useNavigate } from "react-router";
export const WarehouseTopologyContext = React.createContext(undefined);
export const WarehouseTopology = ({ children, warehouseIdInit, addMargin = true, enableResaze = true, isEditable = true, enableCellColoring = false }) => {
    var _a, _b;
    const [minimapContainerRef, seMinimapContainerElement] = useState(null);
    const [graphRef, setGaphElement] = useState(null);
    const [cardRef, setCardRef] = useState(null);
    const isDisposingRef = useRef(false);
    const [warehouseId, setWarehouseId] = useState(warehouseIdInit || null);
    const navigate = useNavigate();
    const [graph, setGraph] = useState(null);
    const [dnd, setDnd] = useState(null);
    const { state, dispatch } = useWarehouseState(warehouseId, enableCellColoring);
    const { handleNodeAdded, handleNodeRemoved, handleNodeRotated, handleNodeMoved } = useCellEditor(dispatch);
    useRenderCells({ warehouseCell: state === null || state === void 0 ? void 0 : state.warehouseCell, graph: graph, cell: state.cell, dispatch });
    useResizeGraph({ graphRef, graph, enableResaze });
    const setGaphRef = useCallback((element) => {
        setGaphElement(element);
    }, []);
    const setMinimapContainerRef = useCallback((element) => {
        seMinimapContainerElement(element);
    }, []);
    useEffect(() => {
        navigate("?", { replace: true });
        if (!graphRef || !minimapContainerRef || !state.warehouseShape || !cardRef)
            return;
        const { height, width } = cardRef.getBoundingClientRect();
        const graph = new Graph({
            container: graphRef,
            width: width - (addMargin ? 40 : 0),
            height: height - (addMargin ? 50 : 0),
            history: true,
            interacting: isEditable,
            resizing: {
                enabled: isEditable,
            },
            rotating: {
                enabled: isEditable,
            },
            scroller: {
                enabled: true,
            },
            background: {
                color: "rgba(0, 0, 0, 0.1)",
            },
            minimap: {
                container: minimapContainerRef,
                width: 200,
                height: 100,
                enabled: true,
                scalable: true,
            },
            mousewheel: {
                enabled: true,
                minScale: 0.2,
                maxScale: 4,
            },
            selecting: {
                enabled: isEditable,
                multiple: isEditable,
                rubberEdge: true,
                rubberNode: true,
                modifiers: "shift",
                rubberband: true,
                showNodeSelectionBox: true,
            },
            grid: {
                size: 1,
                visible: true,
                type: "mesh",
            },
            snapline: {
                tolerance: 1,
                enabled: true,
                filter: () => false,
            },
        });
        graph.on("node:added", ({ node }) => {
            if (isDisposingRef === null || isDisposingRef === void 0 ? void 0 : isDisposingRef.current)
                return;
            const id = node.getData().id;
            const init = node.getData().init;
            const { width, height } = node.getSize();
            const { x, y } = node.getPosition();
            if (!`${id}`.includes("layout")) {
                node.addTools(defaultTools);
            }
            if (!`${id}`.includes("layout") && !init) {
                dispatch({ type: "ADD_ACTIVE_NODE_ID", payload: id });
                handleNodeAdded({
                    id: node.getData().id,
                    width,
                    height,
                    x,
                    y,
                });
            }
        });
        graph.on("node:removed", ({ node }) => {
            if (isDisposingRef === null || isDisposingRef === void 0 ? void 0 : isDisposingRef.current)
                return;
            const id = node.getData().id;
            if (!`${id}`.includes("layout")) {
                dispatch({ type: "REMOVE_ACTIVE_NODE_ID", payload: id });
                handleNodeRemoved({ id });
            }
        });
        graph.on("node:change:*", ({ node, current }) => {
            if (isDisposingRef === null || isDisposingRef === void 0 ? void 0 : isDisposingRef.current)
                return;
            const id = node.getData().id;
            const rotate = node.getAngle();
            const { x, y } = node.getPosition();
            if (typeof current === "number") {
                handleNodeRotated({ id: id, width, height, x, y }, rotate);
            }
            if (typeof current === "object" && "x" in current && "y" in current) {
                handleNodeMoved({ id: id, width, height, x, y }, rotate);
            }
        });
        graph.on("node:click", (e) => {
            const cellId = e.node.getData().id;
            e.e.preventDefault();
            e.e.stopPropagation();
            if ((isDisposingRef === null || isDisposingRef === void 0 ? void 0 : isDisposingRef.current) || `${cellId}`.includes("layout"))
                return;
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set("cellId", cellId);
            navigate(`?${queryParams}`);
            e.node.off();
            return;
        });
        setGraph(graph);
        const dnd = new Dnd({
            target: graph,
            scaled: false,
            animation: true,
            validateNode(droppingNode) {
                const existingNodes = graph.getNodes();
                const droppingNodeId = droppingNode.getData().id;
                const isUnique = !existingNodes.some((node) => {
                    const nodeId = node.getData();
                    return nodeId && nodeId.id === droppingNodeId;
                });
                return isUnique;
            },
        });
        setDnd(dnd);
        return () => {
            isDisposingRef.current = true;
            graph.dispose();
        };
    }, [graphRef, minimapContainerRef, cardRef, state.warehouseShape, isDisposingRef === null || isDisposingRef === void 0 ? void 0 : isDisposingRef.current]);
    useEffect(() => {
        if (!graph || !(state === null || state === void 0 ? void 0 : state.warehouseShape))
            return;
        createNodeFromShape(state.warehouseShape, graph);
        graph.centerContent();
    }, [graph, state === null || state === void 0 ? void 0 : state.warehouseShape]);
    const handleDragStart = (info) => {
        if (!dnd)
            return;
        const node = createNodeFromVectorObject(info.node);
        if (node) {
            node.removeTools();
            dnd.start(node, info.event.nativeEvent);
        }
    };
    const handleUndo = () => {
        if (graph) {
            graph.history.undo();
        }
    };
    const handleRedo = () => {
        if (graph) {
            graph.history.redo();
        }
    };
    const handleDoubleClick = (key) => {
        if (graph) {
            const cells = graph.getCells();
            const cell = cells.find((cell) => cell.getData().id === key.id);
            if (cell) {
                graph.scrollToCell(cell);
                graph.select(cell);
            }
            else {
                console.log("Ячейка с ID", key, "не найдена на холсте.");
            }
        }
    };
    const canRedo = ((_a = graph === null || graph === void 0 ? void 0 : graph.history) === null || _a === void 0 ? void 0 : _a.canRedo()) || false;
    const canUndo = ((_b = graph === null || graph === void 0 ? void 0 : graph.history) === null || _b === void 0 ? void 0 : _b.canUndo()) || false;
    if (state.isWarehouseLoading || state.isCellsLoading) {
        return (React.createElement(Card, { style: { height: "100%", placeContent: "center", display: "flex", position: "relative", borderRadius: 2 } },
            React.createElement("div", { style: { height: "100%", display: "flex", alignItems: "center" } },
                React.createElement(Spin, null))));
    }
    return (React.createElement(WarehouseTopologyContext.Provider, { value: { state, canRedo, canUndo, warehouseId, setWarehouseId, handleDoubleClick, dispatch, handleRedo, handleUndo, setCardRef, setMinimapContainerRef, setGaphRef, handleDragStart } },
        React.createElement(React.Fragment, null, children)));
};
WarehouseTopology.MapWithThree = MapWithThree;
WarehouseTopology.Drawer = Drawer;
WarehouseTopology.Three = Three;
WarehouseTopology.Controls = Controls;
WarehouseTopology.Map = Map;
