var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Form, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHandbookValueListLazyQuery } from "../../../../../../api/graphql/queries/handbook/HandbookQuery";
import ProductTypeAttrInput from "../../../../../../components/ProductTypeAttrInput";
import { productTypeGraphqlApi } from "../../../../../../redux/api/productApi/productType";
import { ProductAutomatizationContext } from "../../ProductExpirationDate";
const { useFetchProductTypeQuery } = productTypeGraphqlApi;
export const ProductTypeAttrContainer = ({ productTypeAttrId, id, name, where, indexNumber }) => {
    const context = useContext(ProductAutomatizationContext);
    const [dataType, setDataType] = useState(null);
    const [attribute, setAttribute] = useState(null);
    const [defaultValue, setDefaultValue] = useState([]);
    const checkIshandbook = (dataType) => dataType === "Handbook";
    const [getHandbookValues, { loading, data: handbookValues }] = useHandbookValueListLazyQuery();
    if (!context)
        throw new Error("useContext must be used within a Provider");
    const { form } = context;
    const { typeId: typeParam } = useParams();
    const typeId = typeParam ? +typeParam : null;
    const { data, isLoading } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    useEffect(() => {
        var _a, _b;
        const productCategory = (_a = data === null || data === void 0 ? void 0 : data.productTypeCategories) === null || _a === void 0 ? void 0 : _a.find((productCategory) => { var _a; return (_a = productCategory.productTypeAttributes) === null || _a === void 0 ? void 0 : _a.find((productTypeAttribute) => productTypeAttribute.id === productTypeAttrId); });
        const attribute = (_b = productCategory === null || productCategory === void 0 ? void 0 : productCategory.productTypeAttributes) === null || _b === void 0 ? void 0 : _b.find((productTypeAttribute) => productTypeAttribute.id === productTypeAttrId);
        if (attribute)
            setAttribute(attribute);
        if (attribute)
            setDataType(attribute.dataType);
    }, [JSON.stringify(data)]);
    useEffect(() => {
        const findHandbookValues = (items, handbookId) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const filterIds = items.split(",").map(Number);
            const result = yield getHandbookValues({ variables: { handbookId, filterIds } });
            return ((_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.handbookValueList) === null || _b === void 0 ? void 0 : _b.data) || [];
        });
        const updateValues = () => __awaiter(void 0, void 0, void 0, function* () {
            var _c;
            if (dataType && checkIshandbook(dataType) && attribute && attribute.handbookId && !((_c = handbookValues === null || handbookValues === void 0 ? void 0 : handbookValues.handbookValueList) === null || _c === void 0 ? void 0 : _c.data)) {
                const formValue = form.getFieldValue(`conditions-${indexNumber}`);
                const itemIndex = formValue.findIndex((i) => (i === null || i === void 0 ? void 0 : i.id) === id);
                if (itemIndex !== -1 && where) {
                    const selectItems = yield findHandbookValues(where, attribute.handbookId);
                    const updatedItems = selectItems.map((item) => ({ label: item.title, value: item.id }));
                    setDefaultValue(updatedItems);
                }
            }
            else {
                setDefaultValue(where);
            }
        });
        updateValues();
    }, [dataType, attribute, form, handbookValues]);
    if (!dataType) {
        return React.createElement(Input, { disabled: true });
    }
    return (React.createElement("div", null, checkIshandbook(dataType) ? (id ? (React.createElement(React.Fragment, null, loading ? (React.createElement(Input, { disabled: true })) : (React.createElement(React.Fragment, null, Array.isArray(defaultValue) && !!(defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.length) ? (React.createElement(Form.Item, { key: JSON.stringify(defaultValue), initialValue: defaultValue, name: [name, "where"], style: { marginBottom: 0 }, rules: [{ required: true, message: "Выберите опцию" }] },
        React.createElement(ProductTypeAttrInput, { withPreload: true, dataAsNumber: true, mode: "multiple", loading: isLoading || !defaultValue.length, attribute: attribute }))) : (React.createElement(Form.Item, { name: [name, "where"], style: { marginBottom: 0 }, rules: [{ required: true, message: "Выберите опцию" }] },
        React.createElement(ProductTypeAttrInput, { withPreload: true, dataAsNumber: true, mode: "multiple", loading: isLoading, attribute: attribute }))))))) : (React.createElement(Form.Item, { name: [name, "where"], style: { marginBottom: 0 }, rules: [{ required: true, message: "Выберите опцию" }] },
        React.createElement(ProductTypeAttrInput, { withPreload: true, dataAsNumber: true, mode: "multiple", loading: isLoading, attribute: attribute })))) : (React.createElement(Form.Item, { key: JSON.stringify(defaultValue), initialValue: defaultValue, name: [name, "where"], style: { marginBottom: 0 }, rules: [{ required: true, message: "Выберите опцию" }] },
        React.createElement(ProductTypeAttrInput, { withPreload: true, dataAsNumber: true, loading: isLoading, attribute: attribute })))));
};
