import { Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
import Page from "../../../../../components/Page";
import PackingSetAddFormContainer from "../../components/PackingSetAddPage/PackingSetAddFormContainer";
import { TITLES } from "../../constants";
const { Title } = Typography;
const PackingSetAddPage = () => (React.createElement(Page, null,
    React.createElement(Row, null,
        React.createElement(Col, { span: 24 },
            React.createElement(Card, null,
                React.createElement(Title, { level: 3 }, TITLES.packing_set_add),
                React.createElement(Divider, null),
                React.createElement(PackingSetAddFormContainer, null))))));
export default PackingSetAddPage;
