var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { SortableItem } from "./SortableItem";
export const DraggableBodyRow = (_a) => {
    var { className, style, indexNumber, isActive, row, index, itemIndex } = _a, restProps = __rest(_a, ["className", "style", "indexNumber", "isActive", "row", "index", "itemIndex"]);
    return React.createElement(SortableItem, Object.assign({ isActive: isActive, row: row, itemIndex: itemIndex, indexNumber: indexNumber, index: index }, restProps));
};
