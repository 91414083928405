import { Form, Modal } from "antd";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import WarehouseSectionForm from "../../components/WarehouseSectionForm";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { selectTopologySection, selectTopologySections } from "../../redux/reducers/topologyReducer";
import warehouseSectionApi from "../../redux/api/warehouseApi/warehouseSectionReducer";
export const WAREHOUSE_SECTION_MODAL_PARAM = "sectionModal";
const { useAddSectionMutation, useEditSectionMutation } = warehouseSectionApi;
const WarehouseSectionModal = () => {
    var _a, _b, _c;
    const { id } = useParams();
    const warehouseId = id ? +id : null;
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const sectionIdParam = searchParams.get(WAREHOUSE_SECTION_MODAL_PARAM);
    const sectionId = sectionIdParam ? +sectionIdParam : null;
    const parentIdParam = searchParams.get("parent");
    const parentId = parentIdParam ? +parentIdParam : null;
    const section = useTypedSelector(selectTopologySection(sectionId));
    const sections = useTypedSelector(selectTopologySections);
    const [addSection, { isLoading: addIsLoading }] = useAddSectionMutation();
    const [editSection, { isLoading: editIsLoading }] = useEditSectionMutation();
    const isLoading = addIsLoading || editIsLoading;
    let initialValues;
    if (section) {
        const parent = sections.find((item) => item.id === (section === null || section === void 0 ? void 0 : section.parentId));
        initialValues = {
            id: section.id,
            parentId: {
                label: parent === null || parent === void 0 ? void 0 : parent.title,
                value: (_a = parent === null || parent === void 0 ? void 0 : parent.id) !== null && _a !== void 0 ? _a : "",
            },
            title: section.title,
            description: section.description,
            storageSectionTypeId: {
                label: section.sectionType.title,
                value: section.sectionType.id,
            },
        };
    }
    else if (parentId) {
        const parent = sections.find((item) => item.id === parentId);
        initialValues = {
            parentId: {
                label: parent === null || parent === void 0 ? void 0 : parent.title,
                value: (_b = parent === null || parent === void 0 ? void 0 : parent.id) !== null && _b !== void 0 ? _b : "",
            },
        };
    }
    useEffect(() => {
        setTimeout(() => {
            form.resetFields();
        }, 0);
    }, [initialValues]);
    const handleOk = () => form.submit();
    const handleCancel = () => {
        form.resetFields();
        searchParams.delete(WAREHOUSE_SECTION_MODAL_PARAM);
        searchParams.delete("parent");
        setSearchParams(searchParams);
    };
    const handlerSubmit = (formValues) => {
        var _a, _b, _c, _d, _e;
        if (formValues.id === undefined) {
            const values = {
                parentId: ((_a = formValues.parentId) === null || _a === void 0 ? void 0 : _a.value) ? (_b = formValues.parentId) === null || _b === void 0 ? void 0 : _b.value : null,
                title: formValues.title,
                description: formValues.description,
                storageSectionTypeId: +((_d = (_c = formValues === null || formValues === void 0 ? void 0 : formValues.storageSectionTypeId) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : 1),
            };
            if (warehouseId)
                addSection({
                    input: {
                        storageSectionTypeId: values === null || values === void 0 ? void 0 : values.storageSectionTypeId,
                        title: values === null || values === void 0 ? void 0 : values.title,
                        warehouseId: warehouseId,
                        description: values === null || values === void 0 ? void 0 : values.description,
                        parentId: values === null || values === void 0 ? void 0 : values.parentId,
                    },
                })
                    .unwrap()
                    .then(() => handleCancel());
        }
        else {
            const values = {
                storageSectionTypeId: formValues.storageSectionTypeId.value,
                title: formValues.title,
                description: formValues.description,
                parentId: (_e = formValues.parentId) === null || _e === void 0 ? void 0 : _e.value,
            };
            if (sectionId && warehouseId)
                editSection({
                    input: {
                        storageSectionId: sectionId,
                        storageSectionTypeId: values === null || values === void 0 ? void 0 : values.storageSectionTypeId,
                        title: values === null || values === void 0 ? void 0 : values.title,
                        warehouseId: warehouseId,
                        description: values === null || values === void 0 ? void 0 : values.description,
                        parentId: (values === null || values === void 0 ? void 0 : values.parentId) ? +(values === null || values === void 0 ? void 0 : values.parentId) : undefined,
                    },
                })
                    .unwrap()
                    .then(() => handleCancel());
        }
    };
    return (React.createElement(Modal, { open: !!sectionIdParam, confirmLoading: isLoading, onOk: handleOk, onCancel: handleCancel, title: `${(initialValues === null || initialValues === void 0 ? void 0 : initialValues.id) === undefined ? "Добавить" : "Редактировать"} секцию`, okText: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", cancelText: "\u041E\u0442\u043C\u0435\u043D\u0430" },
        React.createElement(WarehouseSectionForm, { form: form, initialValues: initialValues, warehouseId: (_c = warehouseId === null || warehouseId === void 0 ? void 0 : warehouseId.toString()) !== null && _c !== void 0 ? _c : "", onSubmit: handlerSubmit })));
};
export default WarehouseSectionModal;
