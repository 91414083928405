var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from "@apollo/client";
import { Button, Dropdown, Menu, Modal, notification } from "antd";
import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import MomentDatePicker from "../DatePicker";
export var TaskTypes;
(function (TaskTypes) {
    TaskTypes[TaskTypes["ChangeWarehouse"] = 0] = "ChangeWarehouse";
    TaskTypes[TaskTypes["Correction"] = 1] = "Correction";
    TaskTypes[TaskTypes["Inventory"] = 2] = "Inventory";
    TaskTypes[TaskTypes["Loading"] = 3] = "Loading";
    TaskTypes[TaskTypes["Movement"] = 4] = "Movement";
    TaskTypes[TaskTypes["Partition"] = 5] = "Partition";
    TaskTypes[TaskTypes["Shipping"] = 6] = "Shipping";
    TaskTypes[TaskTypes["WriteOff"] = 7] = "WriteOff";
})(TaskTypes || (TaskTypes = {}));
const mutationBodyType = {
    [TaskTypes.Correction]: "ViewResponse",
    [TaskTypes.Loading]: "ViewDto",
    [TaskTypes.Movement]: "Response",
    [TaskTypes.Partition]: "ViewDto",
    [TaskTypes.Shipping]: "ViewResponse",
    [TaskTypes.ChangeWarehouse]: "Dto",
    [TaskTypes.Inventory]: "Dto",
    [TaskTypes.WriteOff]: "Dto",
};
const EditExecutedAtButton = ({ taskType, showShippingDate, createdAt, shippedAt, acceptedAt, showAcceptedDate, taskId, refetch }) => {
    const user = useTypedSelector((state) => state.user);
    const userRoles = useMemo(() => {
        var _a;
        return ((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.role) || [];
    }, [JSON.stringify(user)]);
    const isAllowedRole = useMemo(() => {
        return userRoles.includes("VITMWMSManager") || userRoles.includes("VITMWMSAdministrator");
    }, [JSON.stringify(userRoles)]);
    const [visible, setVisible] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [date, setDate] = useState(null);
    useEffect(() => {
        if (selectedAction === "executed" && createdAt) {
            setDate(moment(createdAt));
        }
        else if (selectedAction === "accepted" && acceptedAt) {
            setDate(moment(acceptedAt));
        }
        else if (selectedAction === "acceptMovement" && shippedAt) {
            setDate(moment(shippedAt));
        }
        else {
            setDate(null);
        }
    }, [selectedAction, createdAt, acceptedAt, shippedAt]);
    const taskTypeName = TaskTypes[taskType];
    const [editExecutedAt, { loading }] = useMutation(gql `
    mutation Task${taskTypeName}EditExecutedAt($input: Task${taskTypeName}EditExecutedAtInput!) {
      task${taskTypeName}EditExecutedAt (input: $input) {
        task${taskTypeName}${mutationBodyType[taskType]} {
          id
        }
      }
    }`, {
        onError: () => {
            notification.error({
                message: "Не удалось обновить дату закрытия задачи",
            });
        },
        onCompleted: () => __awaiter(void 0, void 0, void 0, function* () {
            setVisible(false);
            yield refetch();
        }),
    });
    const [editAcceptedAt, { loading: acceptedAtLoading }] = useMutation(gql `
    mutation Task${taskTypeName}EditAcceptedAt($input: Task${taskTypeName}EditAcceptedAtInput!) {
      task${taskTypeName}EditAcceptedAt (input: $input) {
        task${taskTypeName}${mutationBodyType[taskType]} {
          id
        }
      }
    }`, {
        onError: () => {
            notification.error({
                message: "Не удалось обновить дату приемки задачи",
            });
        },
        onCompleted: () => __awaiter(void 0, void 0, void 0, function* () {
            setVisible(false);
            yield refetch();
        }),
    });
    const [editShippedAt, { loading: acceptedAcceptMovement }] = useMutation(gql `
    mutation Task${taskTypeName}EditShippedAt($input: Task${taskTypeName}EditShippedAtInput!) {
      task${taskTypeName}EditShippedAt (input: $input) {
        task${taskTypeName}${mutationBodyType[taskType]} {
          id
        }
      }
    }`, {
        onError: () => {
            notification.error({
                message: "Не удалось обновить дату отгрузки задачи",
            });
        },
        onCompleted: () => __awaiter(void 0, void 0, void 0, function* () {
            setVisible(false);
            yield refetch();
        }),
    });
    const handleOk = () => {
        if (!date) {
            notification.warning({
                message: "Поле не может быть пустым",
            });
            return;
        }
        if (selectedAction === "executed") {
            editExecutedAt({ variables: { input: { taskId: taskId, executedAt: date } } });
        }
        else if (selectedAction === "accepted") {
            editAcceptedAt({ variables: { input: { taskId: taskId, acceptedAt: date } } });
        }
        else if (selectedAction === "acceptMovement") {
            editShippedAt({ variables: { input: { taskId: taskId, shippedAt: date } } });
        }
    };
    const handleCancel = () => {
        setVisible(false);
    };
    const menu = (React.createElement(Menu, { onClick: ({ key }) => {
            setSelectedAction(key);
            setVisible(true);
        } },
        React.createElement(Menu.Item, { key: "executed" }, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0434\u0430\u0442\u0443 \u0437\u0430\u043A\u0440\u044B\u0442\u0438\u044F"),
        showAcceptedDate && React.createElement(Menu.Item, { key: "accepted" }, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0434\u0430\u0442\u0443 \u043F\u0440\u0438\u0435\u043C\u043A\u0438"),
        showShippingDate && React.createElement(Menu.Item, { key: "acceptMovement" }, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0434\u0430\u0442\u0443 \u043E\u0442\u0433\u0440\u0443\u0437\u043A\u0438")));
    if (!isAllowedRole) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { overlay: menu },
            React.createElement(Button, null, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0434\u0430\u0442\u0443")),
        React.createElement(Modal, { title: `Изменить дату ${selectedAction === "executed" ? "закрытия" : selectedAction === "accepted" ? "приемки" : "отгрузки"}?`, visible: visible, onOk: handleOk, onCancel: handleCancel, confirmLoading: loading || acceptedAtLoading || acceptedAcceptMovement },
            React.createElement(MomentDatePicker, { key: JSON.stringify(`${createdAt}${acceptedAt}${shippedAt}`), showTime: true, format: (date) => date.utc().format(), value: date ? moment(date) : null, onChange: (value) => setDate(value ? moment(value) : null) }))));
};
export default EditExecutedAtButton;
