import React, { useEffect } from 'react';
import StatusHistory from '../../../../components/StatusHistory';
import { useAdapters } from '../../../../hooks/useAdapters';
import { useTaskLoading } from '../../../../hooks/api/taskLoadingApi/useTaskLoading';
const LoadingStatusHistoryContainer = (props) => {
    var _a;
    const { taskId } = props;
    const { history } = useTaskLoading();
    const { normalizeHistory } = useAdapters();
    useEffect(() => {
        history.getHistory({ variables: { taskId: taskId } });
    }, []);
    return React.createElement(StatusHistory, { history: ((_a = history === null || history === void 0 ? void 0 : history.data) === null || _a === void 0 ? void 0 : _a.length) ? normalizeHistory(history.data) : [], isLoading: history.loading });
};
export default LoadingStatusHistoryContainer;
