export const warehouseInfoAdapter = (dto) => {
    const { id, timeZoneId, storageTitle, storageType, address, addressBuilding, addressCountry, addressIndex, addressRegion, addressStreet, addressCity, geoLongitude, geoLatitude, timeZoneReadable } = dto;
    return {
        id,
        timeZoneId,
        storageTitle,
        storageType,
        address,
        addressBuilding,
        addressCountry,
        addressIndex,
        addressRegion,
        addressStreet,
        addressCity,
        geoLongitude,
        geoLatitude,
        timeZoneReadable,
    };
};
export const warehouseDtoInfoAdapter = (dto) => {
    const { id, timeZone, storageTitle, storageType, geoLongitude, geoLatitude } = dto;
    return {
        id,
        timeZoneId: timeZone === null || timeZone === void 0 ? void 0 : timeZone.id,
        storageTitle,
        storageType,
        geoLongitude,
        geoLatitude,
    };
};
export const warehouseSectionTypeAdapter = (dto) => {
    const { icon, separator, id, description, title } = dto;
    return {
        icon,
        separator,
        id,
        description,
        title,
    };
};
export const warehouseSectionDtoTypeAdapter = (dto) => {
    var _a, _b;
    return {
        icon: dto === null || dto === void 0 ? void 0 : dto.icon,
        separator: dto === null || dto === void 0 ? void 0 : dto.separator,
        id: (_a = dto === null || dto === void 0 ? void 0 : dto.id) !== null && _a !== void 0 ? _a : 0,
        description: (_b = dto === null || dto === void 0 ? void 0 : dto.description) !== null && _b !== void 0 ? _b : undefined,
        title: dto === null || dto === void 0 ? void 0 : dto.title,
    };
};
export const storageSectionTypeListAdapter = (dto) => {
    const { description, icon, id, separator, title } = dto;
    return {
        id: id,
        title: title !== null && title !== void 0 ? title : undefined,
        description: description !== null && description !== void 0 ? description : undefined,
        icon: icon !== null && icon !== void 0 ? icon : undefined,
        separator: separator !== null && separator !== void 0 ? separator : undefined,
    };
};
export const storageSectionListResponseAdapter = (dto) => {
    const { address, description, id, parentId, sectionType, title } = dto;
    return {
        id: id,
        title: title !== null && title !== void 0 ? title : undefined,
        description: description !== null && description !== void 0 ? description : undefined,
        address: address !== null && address !== void 0 ? address : undefined,
        parentId: parentId !== null && parentId !== void 0 ? parentId : undefined,
        sectionType: storageSectionTypeListAdapter(sectionType),
    };
};
export const warehouseSectionAdapter = (dto, sectionParentId) => {
    const { id, title, address, description, storageSectionType, parentId } = dto;
    return {
        id,
        title,
        address,
        description,
        sectionType: warehouseSectionTypeAdapter(storageSectionType),
        parentId: parentId !== null && parentId !== void 0 ? parentId : sectionParentId,
    };
};
export const warehouseDtoSectionAdapter = (dto, sectionParentId) => {
    var _a;
    return {
        id: (_a = dto === null || dto === void 0 ? void 0 : dto.id) !== null && _a !== void 0 ? _a : 0,
        title: dto === null || dto === void 0 ? void 0 : dto.title,
        address: dto === null || dto === void 0 ? void 0 : dto.address,
        description: (dto === null || dto === void 0 ? void 0 : dto.description) || "",
        sectionType: warehouseSectionDtoTypeAdapter(dto === null || dto === void 0 ? void 0 : dto.storageSectionType),
        parentId: sectionParentId,
    };
};
export const storageCellAdapter = (dto, parentSectionId) => {
    const { id, title, description, address } = dto;
    return {
        id,
        title,
        address,
        description,
        parentSectionId,
    };
};
export const storageCellTypeAdapter = (dto) => {
    const { cellDepth, cellHeight, cellWeight, cellWidth, description, id, title } = dto;
    return {
        id: id,
        title: title !== null && title !== void 0 ? title : undefined,
        description: description !== null && description !== void 0 ? description : undefined,
        cellHeight: cellHeight,
        cellWeight: cellWeight,
        cellWidth: cellWidth,
        cellDepth: cellDepth,
    };
};
export const storageCellDtoAdapter = (dto) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        id: (_a = dto === null || dto === void 0 ? void 0 : dto.id) !== null && _a !== void 0 ? _a : 0,
        title: (_b = dto === null || dto === void 0 ? void 0 : dto.title) !== null && _b !== void 0 ? _b : undefined,
        description: (_c = dto === null || dto === void 0 ? void 0 : dto.description) !== null && _c !== void 0 ? _c : undefined,
        cellHeight: (_d = dto === null || dto === void 0 ? void 0 : dto.cellHeight) !== null && _d !== void 0 ? _d : 0,
        cellWeight: (_e = dto === null || dto === void 0 ? void 0 : dto.cellWeight) !== null && _e !== void 0 ? _e : 0,
        cellWidth: (_f = dto === null || dto === void 0 ? void 0 : dto.cellWidth) !== null && _f !== void 0 ? _f : 0,
        cellDepth: (_g = dto === null || dto === void 0 ? void 0 : dto.cellDepth) !== null && _g !== void 0 ? _g : 0,
    };
};
export const storageCellInfoAdapter = (dto) => {
    const { id, title, description, address, storageCellType, storageSection } = dto;
    return {
        id,
        title,
        address,
        description: description !== null && description !== void 0 ? description : undefined,
        parentSectionId: (storageSection === null || storageSection === void 0 ? void 0 : storageSection.id) || 0,
        storageCellType: storageCellDtoAdapter(storageCellType),
        storageSection: warehouseDtoSectionAdapter(storageSection),
    };
};
function convertStorageSectionTypeToWarehouseSectionType(newType) {
    return {
        id: newType.id,
        title: newType.title,
        description: newType.description || "",
        icon: newType.icon,
        separator: newType.separator,
    };
}
function convertStorageCellListDtoToStorageCellDto(newCell) {
    return {
        id: newCell.id,
        address: newCell.address,
        title: newCell.title,
        description: newCell.description || "",
    };
}
export function convertStorageSectionDtoToWarehouseSectionDto(newSection) {
    var _a, _b;
    return {
        id: newSection.id,
        address: newSection.address,
        title: newSection.title,
        description: newSection.description || "",
        storageSectionType: (newSection === null || newSection === void 0 ? void 0 : newSection.storageSectionType) ? convertStorageSectionTypeToWarehouseSectionType(newSection.storageSectionType) : { id: 0, title: "", description: "" },
        storageSections: (_a = newSection === null || newSection === void 0 ? void 0 : newSection.storageSections) === null || _a === void 0 ? void 0 : _a.map(convertStorageSectionDtoToWarehouseSectionDto),
        storageCells: (_b = newSection === null || newSection === void 0 ? void 0 : newSection.storageCells) === null || _b === void 0 ? void 0 : _b.map(convertStorageCellListDtoToStorageCellDto),
    };
}
