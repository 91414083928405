import { getColumnType } from "../../../../../../helpers/function";
const onCell = () => ({});
export const createColumns = () => {
    return [
        getColumnType("type", "Тип", false, {
            onCell,
        }),
        getColumnType("title", "Номенклатура", false, {
            onCell,
        }),
        getColumnType("count", "Кол-во SKU шт.", false, {
            onCell,
        }),
        getColumnType("quantity", "Кол-во", false, {
            onCell,
        }),
        getColumnType("dimension", "Размерность", false, {
            onCell,
        }),
    ];
};
