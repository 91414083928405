import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskCorrectionAddDocument = gql `
    mutation TaskCorrectionAdd($input: TaskCorrectionAddInput!) {
  taskCorrectionAdd(input: $input) {
    taskCorrectionViewResponse {
      id
    }
  }
}
    `;
/**
 * __useTaskCorrectionAddMutation__
 *
 * To run a mutation, you first call `useTaskCorrectionAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskCorrectionAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCorrectionAddMutation, { data, loading, error }] = useTaskCorrectionAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskCorrectionAddMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskCorrectionAddDocument, options);
}
export const TaskCorrectionChangeStatusDocument = gql `
    mutation TaskCorrectionChangeStatus($input: TaskCorrectionChangeStatusInput!) {
  taskCorrectionChangeStatus(input: $input) {
    taskStatusResponse {
      title
    }
  }
}
    `;
/**
 * __useTaskCorrectionChangeStatusMutation__
 *
 * To run a mutation, you first call `useTaskCorrectionChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskCorrectionChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCorrectionChangeStatusMutation, { data, loading, error }] = useTaskCorrectionChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskCorrectionChangeStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskCorrectionChangeStatusDocument, options);
}
export const TaskCorrectionAddSkuDocument = gql `
    mutation TaskCorrectionAddSku($input: TaskCorrectionAddSkuInput!) {
  taskCorrectionAddSku(input: $input) {
    taskCorrectionViewSkuResponse {
      id
    }
  }
}
    `;
/**
 * __useTaskCorrectionAddSkuMutation__
 *
 * To run a mutation, you first call `useTaskCorrectionAddSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskCorrectionAddSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCorrectionAddSkuMutation, { data, loading, error }] = useTaskCorrectionAddSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskCorrectionAddSkuMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskCorrectionAddSkuDocument, options);
}
export const TaskCorrectionEditSkuDocument = gql `
    mutation TaskCorrectionEditSku($input: TaskCorrectionEditSkuInput!) {
  taskCorrectionEditSku(input: $input) {
    taskCorrectionViewSkuResponse {
      id
    }
  }
}
    `;
/**
 * __useTaskCorrectionEditSkuMutation__
 *
 * To run a mutation, you first call `useTaskCorrectionEditSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskCorrectionEditSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCorrectionEditSkuMutation, { data, loading, error }] = useTaskCorrectionEditSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskCorrectionEditSkuMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskCorrectionEditSkuDocument, options);
}
export const TaskCorrectionDeleteSkuDocument = gql `
    mutation TaskCorrectionDeleteSku($input: TaskCorrectionDeleteSkuInput!) {
  taskCorrectionDeleteSku(input: $input) {
    taskCorrectionViewSkuResponse {
      id
    }
  }
}
    `;
/**
 * __useTaskCorrectionDeleteSkuMutation__
 *
 * To run a mutation, you first call `useTaskCorrectionDeleteSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskCorrectionDeleteSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCorrectionDeleteSkuMutation, { data, loading, error }] = useTaskCorrectionDeleteSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskCorrectionDeleteSkuMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskCorrectionDeleteSkuDocument, options);
}
export const TaskCorrectionEditExecutedAtDocument = gql `
    mutation TaskCorrectionEditExecutedAt($input: TaskCorrectionEditExecutedAtInput!) {
  taskCorrectionEditExecutedAt(input: $input) {
    taskCorrectionViewResponse {
      id
    }
  }
}
    `;
/**
 * __useTaskCorrectionEditExecutedAtMutation__
 *
 * To run a mutation, you first call `useTaskCorrectionEditExecutedAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskCorrectionEditExecutedAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCorrectionEditExecutedAtMutation, { data, loading, error }] = useTaskCorrectionEditExecutedAtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskCorrectionEditExecutedAtMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskCorrectionEditExecutedAtDocument, options);
}
