import { Breadcrumb, Tooltip } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../routing';
import { useBreadcrumbs } from '../../routing/hooks';
const Breadcrumbs = () => {
    const { pathname } = useLocation();
    const breadcrumbsRoutes = useBreadcrumbs(routes, (node, link, title) => {
        const component = (pathname !== link) ? (React.createElement(Link, { to: link }, node)) : node;
        return (React.createElement(Breadcrumb.Item, { key: title !== null && title !== void 0 ? title : link }, title ? (React.createElement(Tooltip, { title: title, mouseEnterDelay: 1 }, component)) : component));
    });
    return (React.createElement(Breadcrumb, null, breadcrumbsRoutes === null || breadcrumbsRoutes === void 0 ? void 0 : breadcrumbsRoutes.map(({ element }) => element)));
};
export default Breadcrumbs;
