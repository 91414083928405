var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const OidcLoading = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
            navigate(0);
        }), 15000);
        return () => clearTimeout(timer);
    }, [navigate]);
    return (React.createElement("div", { className: "app-preloader" },
        React.createElement("h1", null, "Warehouse Management System"),
        React.createElement("h2", null, "Varrant IT Management"),
        React.createElement("div", { className: "state" }, "Authorization..."),
        React.createElement("div", { className: "loader" },
            React.createElement("div", { className: "spin" }),
            React.createElement("div", { className: "bounce" }))));
};
export default OidcLoading;
