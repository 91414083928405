var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Table } from "antd";
import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { InventoryByType } from "../../../../../../api/graphql/common";
import { createColumns } from "./Columns/Product";
import { columns as SKUColumn } from "./Columns/SKU";
import { checkIdIsEqual } from "./helper";
import { useTaskInventoryDetails } from "../../../../../../hooks/api/taskInventoryApi/useTaskInventoryDetails";
import { InventoryContext } from "../../../../../pages/task/inventory/InventoryEditPage";
export const Product = ({ taskInventoryProducts, taskInventorySkus, refetch }) => {
    const params = useParams();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const data = useMemo(() => dtoToEntityAdapt(taskInventoryProducts, taskInventorySkus), [JSON.stringify(taskInventoryProducts), JSON.stringify(taskInventorySkus)]);
    const { taskDetails } = useTaskInventoryDetails();
    const handlerDeleteDetails = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.delete({ variables: { input: { taskId, request: { ids: [id], inventoryByType: InventoryByType.Product } } } });
        yield refetch;
    });
    const context = useContext(InventoryContext);
    const columns = useMemo(() => createColumns(handlerDeleteDetails, taskDetails.loading, !!(context === null || context === void 0 ? void 0 : context.isLocked)), []);
    return (React.createElement(Table, { expandable: {
            expandedRowRender: (record) => React.createElement(Table, { columns: SKUColumn, dataSource: record.sku, pagination: false }),
            rowExpandable: (record) => { var _a; return !!((_a = record === null || record === void 0 ? void 0 : record.sku) === null || _a === void 0 ? void 0 : _a.length); },
        }, style: { whiteSpace: "nowrap" }, columns: columns, dataSource: data, bordered: true, pagination: false, size: "small", scroll: { x: true } }));
};
function dtoToEntityAdapt(taskInventoryProducts, taskInventorySkus) {
    return [
        ...taskInventoryProducts.map((taskInventoryProduct) => {
            return {
                key: taskInventoryProduct.id,
                number: taskInventoryProduct.id,
                type: taskInventoryProduct.type,
                title: taskInventoryProduct.title,
                skuCount: taskInventorySkus.filter((taskInventorySku) => checkIdIsEqual(taskInventorySku.taskInventoryProductId, taskInventoryProduct.id)).length,
                sku: taskInventorySkus
                    .filter((taskInventorySku) => checkIdIsEqual(taskInventorySku.taskInventoryProductId, taskInventoryProduct.id))
                    .map((sku) => {
                    var _a;
                    return {
                        number: sku.id,
                        type: sku.type,
                        title: sku.title,
                        quantity: sku.quantity,
                        storageCellExpectedTitle: (_a = sku === null || sku === void 0 ? void 0 : sku.storageCellExpected) === null || _a === void 0 ? void 0 : _a.title,
                    };
                }),
            };
        }),
    ];
}
