import handbookApi, { HANDBOOK_UNIT_TAG } from '..';
import dtoToEntity from '../../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../../constants/enum/Method';
const URI = 'handbookUnit';
const handbookUnitApi = handbookApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchList: builder.query({
            query: (args) => {
                const { page, pageSize, search } = args;
                return {
                    method: Method.GET,
                    url: `${URI}/list`,
                    params: {
                        page, pageSize, search,
                    },
                };
            },
            transformResponse: (response) => ({
                list: response.data.map(dtoToEntity.handbookUnitAdapter),
                pagination: dtoToEntity.paginationAdapter(response.pagination),
            }),
            providesTags: () => [HANDBOOK_UNIT_TAG],
        }),
        fetch: builder.query({
            query: (handbookUnitId) => ({
                method: Method.GET,
                url: `${URI}/${handbookUnitId}`,
            }),
            transformResponse: (response) => (dtoToEntity.handbookUnitAdapter(response)),
        }),
        add: builder.mutation({
            query: (values) => ({
                method: Method.POST,
                url: `${URI}/add`,
                body: values,
            }),
            transformResponse: (response) => (dtoToEntity.handbookUnitAdapter(response)),
            invalidatesTags: [HANDBOOK_UNIT_TAG],
        }),
        edit: builder.mutation({
            query: (args) => {
                const { handbookUnitId, values } = args;
                return {
                    method: Method.PUT,
                    url: `${URI}/${handbookUnitId}`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.handbookUnitAdapter(response)),
            invalidatesTags: [HANDBOOK_UNIT_TAG],
        }),
        delete: builder.mutation({
            query: (handbookUnitId) => ({
                method: Method.DELETE,
                url: `${URI}/${handbookUnitId}`,
            }),
            transformResponse: (_, __, handbookUnitId) => handbookUnitId,
            invalidatesTags: [HANDBOOK_UNIT_TAG],
        }),
    }),
});
export default handbookUnitApi;
