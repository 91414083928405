import { Form, Input } from 'antd';
import React from 'react';
var FormNames;
(function (FormNames) {
    FormNames["ID"] = "id";
    FormNames["TITLE"] = "title";
    FormNames["TYPE"] = "productTypeId";
})(FormNames || (FormNames = {}));
const ProductTypeAttrCatForm = (props) => {
    const { form, onSubmit, initialValues } = props;
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues },
        React.createElement(Form.Item, { name: FormNames.ID, hidden: true },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { name: FormNames.TYPE, hidden: true },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { name: FormNames.TITLE, label: "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A", rules: [{ required: true, message: 'Поле не заполнено' }] },
            React.createElement(Input, null))));
};
export default ProductTypeAttrCatForm;
