import { Col, Divider, Form, Input, Row, Select, Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useState, useEffect } from "react";
import ProductDataType from "../../constants/enum/ProductDataType";
import HandbookSelect from "../HandbookSelect";
import ProductDataTypeSelect from "../ProductDataTypeSelect";
import FormulaMaskInputContainer from "../FormulaMaskInput";
import { DataType } from "../../api/graphql/common";
var FormNames;
(function (FormNames) {
    FormNames["READ_ONLY"] = "isReadOnly";
    FormNames["REQUIRED"] = "isRequired";
    FormNames["MAIN"] = "isMain";
    FormNames["PERSONAL"] = "isPersonal";
    FormNames["FILTER"] = "inFilter";
    FormNames["PRINT"] = "inPrint";
    FormNames["SUMMARIZED"] = "canBeSummarized";
    FormNames["DATA_TYPE"] = "dataType";
    FormNames["TITLE"] = "title";
    FormNames["FORMULA"] = "formula";
    FormNames["DESCRIPTION"] = "description";
    FormNames["DIMENSION"] = "dimension";
    FormNames["CATEGORY_ID"] = "productTypeCategoryId";
    FormNames["HANDBOOK_ID"] = "handbookId";
})(FormNames || (FormNames = {}));
const gutter = [16, 16];
const dividerStyles = {
    margin: "0 0 24px",
};
const ProductTypeAttrForm = (props) => {
    const { form, onSubmit, initialValues, categories } = props;
    const [disableHandbook, setDisableHandbook] = useState(true);
    const [isPersonal, setIsPersonal] = useState(true);
    const [title, setTitle] = useState("");
    const [isPersonalUpdated, setIsPersonalUpdated] = useState(false);
    const [showAdditionalInput, setShowAdditionalInput] = useState(false);
    const handlerChange = (changed, values) => {
        var _a, _b;
        setIsPersonal(values[FormNames.PERSONAL]);
        setTitle(values[FormNames.TITLE]);
        setIsPersonalUpdated(false);
        const isPersonalWasChanged = changed === null || changed === void 0 ? void 0 : changed.isPersonal;
        if (typeof isPersonalWasChanged === "boolean") {
            setIsPersonalUpdated(true);
        }
        const isHandbook = typeof values.dataType === "string" ? [ProductDataType.HANDBOOK, DataType.Handbook].includes(values.dataType) : [ProductDataType.HANDBOOK, DataType.Handbook].includes(((_a = values.dataType) === null || _a === void 0 ? void 0 : _a.value) || DataType);
        const isFormula = typeof values.dataType === "string" ? [ProductDataType.HANDBOOK, DataType.Handbook].includes(values.dataType) : [ProductDataType.FORMULA, DataType.Formula].includes(((_b = values.dataType) === null || _b === void 0 ? void 0 : _b.value) || DataType);
        console.log("values", values);
        setDisableHandbook(!isHandbook);
        setShowAdditionalInput(isFormula);
        if (!isHandbook) {
            form.setFieldValue(FormNames.HANDBOOK_ID, undefined);
        }
    };
    useEffect(() => {
        form.resetFields();
        if (initialValues)
            handlerChange(null, initialValues);
    }, [initialValues]);
    const onChange = (value) => {
        form.setFieldValue(ProductDataType.FORMULA, value);
    };
    const defaultFormula = initialValues === null || initialValues === void 0 ? void 0 : initialValues[FormNames.FORMULA];
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues, onValuesChange: handlerChange },
        React.createElement(Row, { gutter: gutter },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.TITLE, label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0441\u0432\u043E\u0439\u0441\u0442\u0432\u0430", rules: [{ required: true, message: "Поле не заполнено" }] },
                    React.createElement(Input, null))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.CATEGORY_ID, rules: [{ required: true, message: "Поле не заполнено" }], label: "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F" },
                    React.createElement(Select, null, categories.map((item) => (React.createElement(Select.Option, { key: item.value, value: item.value }, item.label))))))),
        React.createElement(Divider, { style: dividerStyles }),
        React.createElement(Row, { gutter: gutter },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.DATA_TYPE, rules: [{ required: true, message: "Поле не заполнено" }], label: "\u0422\u0438\u043F \u0434\u0430\u043D\u043D\u044B\u0445" },
                    React.createElement(ProductDataTypeSelect, null)),
                showAdditionalInput && (React.createElement(Form.Item, { name: FormNames.FORMULA, rules: [{ required: true, message: "Поле не заполнено" }], label: "\u0424\u043E\u0440\u043C\u0443\u043B\u0430", tooltip: 'Вветите символ "[" чтобы появилась подсказка с вариантами' },
                    React.createElement(FormulaMaskInputContainer, { initValue: defaultFormula, isPersonalUpdated: isPersonalUpdated, onChange: onChange, title: title, isPersonal: isPersonal }))),
                React.createElement(Form.Item, { rules: disableHandbook ? [] : [{ required: true, message: "Поле не заполнено" }], name: FormNames.HANDBOOK_ID, label: "\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A" },
                    React.createElement(HandbookSelect, { disabled: disableHandbook }))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.DIMENSION, label: "\u0420\u0430\u0437\u043C\u0435\u0440\u043D\u043E\u0441\u0442\u044C" },
                    React.createElement(Input, null)))),
        React.createElement(Divider, { style: dividerStyles }),
        React.createElement(Space, { wrap: true },
            React.createElement(Form.Item, { name: FormNames.REQUIRED, valuePropName: "checked" },
                React.createElement(Checkbox, null, "\u041E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439")),
            React.createElement(Form.Item, { name: FormNames.PERSONAL, valuePropName: "checked" },
                React.createElement(Checkbox, null, "\u041F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0439 \u0434\u043B\u044F \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438")),
            React.createElement(Form.Item, { name: FormNames.MAIN, valuePropName: "checked" },
                React.createElement(Checkbox, null, "\u041E\u0441\u043D\u043E\u0432\u043D\u043E\u0439 \u0430\u0442\u0440\u0438\u0431\u0443\u0442")),
            React.createElement(Form.Item, { name: FormNames.FILTER, valuePropName: "checked" },
                React.createElement(Checkbox, null, "\u041F\u043E\u043A\u0430\u0437\u044B\u0432\u0430\u0442\u044C \u0432 \u0444\u0438\u043B\u044C\u0442\u0440\u0435")),
            React.createElement(Form.Item, { name: FormNames.SUMMARIZED, valuePropName: "checked" },
                React.createElement(Checkbox, null, "\u041C\u043E\u0436\u043D\u043E \u0441\u0443\u043C\u043C\u0438\u0440\u043E\u0432\u0430\u0442\u044C")),
            React.createElement(Form.Item, { name: FormNames.PRINT, valuePropName: "checked" },
                React.createElement(Checkbox, null, "\u0412\u044B\u0432\u043E\u0434\u0438\u0442\u044C \u0432 \u043F\u0435\u0447\u0430\u0442\u044C")),
            React.createElement(Form.Item, { name: FormNames.READ_ONLY, valuePropName: "checked" },
                React.createElement(Checkbox, null, "\u0422\u043E\u043B\u044C\u043A\u043E \u0434\u043B\u044F \u0447\u0442\u0435\u043D\u0438\u044F"))),
        React.createElement(Divider, { style: dividerStyles }),
        React.createElement(Form.Item, { name: FormNames.DESCRIPTION, label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" },
            React.createElement(Input.TextArea, null))));
};
export default ProductTypeAttrForm;
