import React from 'react';
import classNames from 'classnames';
import MDIcon from '../ui/MDIcon';
const NavigationTab = (props) => {
    const { isActive, onClick, icon } = props;
    const classes = {
        'nav-item': true,
        active: isActive,
    };
    return (React.createElement("div", { className: classNames(classes), onClick: onClick },
        React.createElement(MDIcon, { icon: icon })));
};
export default NavigationTab;
