import { createTopology } from "../helpers";
export function warehouseDataReducer(state, action) {
    switch (action.type) {
        case "ADD_ACTIVE_NODE_ID":
            return Object.assign(Object.assign({}, state), { activeNodeIds: [...state.activeNodeIds, action.payload] });
        case "REMOVE_ACTIVE_NODE_ID":
            return Object.assign(Object.assign({}, state), { activeNodeIds: state.activeNodeIds.filter((id) => id !== action.payload) });
        case "FETCH_CELLS_SUCCESS":
            return Object.assign(Object.assign({}, state), { warehouseCell: action.payload });
        case "FETCH_WAREHOUSE_SUCCESS":
            return Object.assign(Object.assign({}, state), { warehouseShape: action.payload });
        case "FETCH_SECTIONS_SUCCESS":
            return Object.assign(Object.assign({}, state), { warehouseSection: [...state.warehouseSection, ...action.payload] });
        case "FETCH_CELL_DTO_SUCCESS":
            const updatedCells = new Map(state.cell);
            action.payload.forEach((cell) => {
                updatedCells.set(cell.id, cell);
            });
            return Object.assign(Object.assign({}, state), { cell: updatedCells });
        case "FETCH_FAILURE":
            return Object.assign(Object.assign({}, state), { isLoading: false, hasError: true });
        case "FETCH_CELLS_DONE":
            return Object.assign(Object.assign({}, state), { isCellsLoading: false });
        case "FETCH_CELL_DTO_DONE":
            return Object.assign(Object.assign({}, state), { isCellDtoLoading: false });
        case "FETCH_SECTIONS_DONE":
            return Object.assign(Object.assign({}, state), { isSectionsLoading: false });
        case "FETCH_WAREHOUSE_DONE":
            return Object.assign(Object.assign({}, state), { isWarehouseLoading: false });
        case "FETCH_DONE":
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case "CREATE_TOPOLOGY":
            const { warehouseCell, warehouseSection } = state;
            return Object.assign(Object.assign({}, state), { topology: createTopology(warehouseSection, warehouseCell) });
        default:
            return state;
    }
}
