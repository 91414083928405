import { DEFAULT_PAGE_SIZE } from '../../constants';
import { rootDirectory } from '../../constants/links';
export const getTaskPaths = (taskDirectory, typeString) => {
    const taskPath = `${rootDirectory}/${taskDirectory}`;
    return {
        list: {
            path: taskPath,
            url: (page = 1, pageSize = DEFAULT_PAGE_SIZE) => `${taskPath}?page=${page}&pageSize=${pageSize}`,
            title: `Задачи на ${typeString}`,
        },
        page: {
            url: (taskId) => `${taskPath}/${taskId}`,
            title: `#:taskId - Задача на ${typeString}`,
            breadcrumb: '#:taskId',
        },
        add: {
            url: () => `${taskPath}/add`,
            title: `Новая задача на  ${typeString}`,
            breadcrumb: 'Новая задача',
        },
        edit: {
            url: (taskId) => `${taskPath}/${taskId}/edit`,
            title: `Редактировать задачу на ${typeString} #:taskId`,
            breadcrumb: 'Редактировать',
        },
    };
};
