var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useProductTypeLazyQuery, useProductTypeListLazyQuery } from "../../api/graphql/queries/productType/ProductTypeQuery";
import { Select, Spin } from "antd";
import { isNumber, uniqBy } from "lodash";
const defaultValues = {
    page: 1,
    pageSize: 15,
    search: undefined
};
export const ProductTypeSelect = (props) => {
    var _a;
    const [page, setPage] = useState(defaultValues.page);
    const [search, setSearch] = useState(undefined);
    const [getDataList, { loading: loadingList, data: dataList, fetchMore },] = useProductTypeListLazyQuery({
        fetchPolicy: "cache-and-network",
        variables: {
            page: defaultValues.page,
            pageSize: defaultValues.pageSize,
        }
    });
    const [getDataItem, { loading: loadingItem, data: dataItem },] = useProductTypeLazyQuery({
        fetchPolicy: "cache-and-network"
    });
    const searchCallback = useCallback((value) => __awaiter(void 0, void 0, void 0, function* () {
        getDataList({
            variables: {
                page: defaultValues.page,
                pageSize: defaultValues.pageSize,
                search: value
            }
        }).then(response => {
            var _a, _b;
            setPage((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.productTypeList.pagination.page) !== null && _b !== void 0 ? _b : defaultValues.page);
        });
    }), [getDataList]);
    const handleFocus = () => __awaiter(void 0, void 0, void 0, function* () { return yield getDataList(); });
    const handleScroll = (event) => {
        var _a, _b, _c;
        const target = event.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            const itemsOnPage = (_a = dataList === null || dataList === void 0 ? void 0 : dataList.productTypeList.pagination.itemsOnPage) !== null && _a !== void 0 ? _a : 0;
            const itemsCurrentCount = (_b = dataList === null || dataList === void 0 ? void 0 : dataList.productTypeList.pagination.itemsCurrentCount) !== null && _b !== void 0 ? _b : 0;
            const itemsTotalCount = (_c = dataList === null || dataList === void 0 ? void 0 : dataList.productTypeList.pagination.itemsTotalCount) !== null && _c !== void 0 ? _c : 0;
            const loadedItems = (page - 1) * itemsOnPage + itemsCurrentCount;
            if (loadedItems < itemsTotalCount) {
                fetchMore({
                    variables: {
                        page: page + 1
                    },
                    updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                        var _a, _b;
                        if (!fetchMoreResult)
                            return previousQueryResult;
                        return {
                            productTypeList: {
                                data: [
                                    ...(_b = (_a = previousQueryResult.productTypeList) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [],
                                    ...fetchMoreResult.productTypeList.data
                                ],
                                pagination: fetchMoreResult.productTypeList.pagination
                            }
                        };
                    }
                }).then(response => {
                    var _a, _b;
                    setPage((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.productTypeList.pagination.page) !== null && _b !== void 0 ? _b : defaultValues.page);
                });
            }
        }
    };
    useLayoutEffect(() => {
        if (isNumber(props.value)) {
            preloadValue(props.value);
        }
    }, []);
    const options = useMemo(() => {
        var _a, _b;
        const remoteList = (_b = (_a = dataList === null || dataList === void 0 ? void 0 : dataList.productTypeList) === null || _a === void 0 ? void 0 : _a.data.map((x) => {
            return {
                key: x.id,
                label: x.title,
                value: x.id
            };
        })) !== null && _b !== void 0 ? _b : [];
        const remoteItem = (dataItem === null || dataItem === void 0 ? void 0 : dataItem.productType) ? [{
                key: dataItem.productType.id,
                label: dataItem.productType.title,
                value: dataItem.productType.id
            }] : [];
        return uniqBy([...remoteList, ...remoteItem], (x) => x.value);
    }, [(_a = dataList === null || dataList === void 0 ? void 0 : dataList.productTypeList) === null || _a === void 0 ? void 0 : _a.data, dataItem === null || dataItem === void 0 ? void 0 : dataItem.productType]);
    const preloadValue = useCallback((value) => __awaiter(void 0, void 0, void 0, function* () {
        let option = options.find((x) => x.value == value);
        if ((option === null || option === void 0 ? void 0 : option.label) !== undefined)
            return;
        yield getDataItem({
            variables: {
                productTypeId: value
            }
        });
    }), [getDataItem, options]);
    return (React.createElement(Select, Object.assign({}, props, { showSearch: true, searchValue: search, notFoundContent: loadingList ? React.createElement(Spin, { size: "small" }) : null, onSearch: searchCallback, onFocus: handleFocus, onPopupScroll: handleScroll, loading: loadingList || loadingItem, filterOption: false, options: options })));
};
