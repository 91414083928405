var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from 'antd';
import classNames from 'classnames';
import React from 'react';
const ColoredButton = (props) => {
    const { color } = props, btnProps = __rest(props, ["color"]);
    const classes = classNames({
        'colored-button': true,
        [`colored-button--${color}`]: !!color,
    });
    return React.createElement(Button, Object.assign({}, btnProps, { className: classes }));
};
export default ColoredButton;
