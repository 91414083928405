import ProductDataType from "../../../constants/enum/ProductDataType";
import { DataType } from "../common";
export const adaptProductTypeAttributeDtoToEntity = (dto) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return {
        id: dto.id,
        isRequired: (_a = dto.isRequired) !== null && _a !== void 0 ? _a : false,
        isMain: (_b = dto.isMain) !== null && _b !== void 0 ? _b : false,
        isPersonal: (_c = dto.isPersonal) !== null && _c !== void 0 ? _c : false,
        inFilter: (_d = dto.inFilter) !== null && _d !== void 0 ? _d : false,
        sortOrder: (_e = dto.sortOrder) !== null && _e !== void 0 ? _e : null,
        inPrint: (_f = dto.inPrint) !== null && _f !== void 0 ? _f : false,
        canBeSummarized: (_g = dto.canBeSummarized) !== null && _g !== void 0 ? _g : false,
        dataType: mapDataType(dto.dataType),
        title: dto.title,
        description: (_h = dto.description) !== null && _h !== void 0 ? _h : "",
        dimension: (_j = dto.dimension) !== null && _j !== void 0 ? _j : "",
        formula: null,
        position: (_k = dto.position) !== null && _k !== void 0 ? _k : 0,
        productTypeId: undefined,
        productTypeCategoryId: undefined,
        handbookId: (_l = dto.handbookId) !== null && _l !== void 0 ? _l : undefined,
    };
};
export function convertProductDataTypeToDataType(productDataType) {
    switch (productDataType) {
        case ProductDataType.HANDBOOK:
            return DataType.Handbook;
        case ProductDataType.INTEGER:
            return DataType.Integer;
        case ProductDataType.FLOAT:
            return DataType.Float;
        case ProductDataType.VARCHAR:
            return DataType.Varchar;
        case ProductDataType.BOOLEAN:
            return DataType.Boolean;
        case ProductDataType.DATE:
            return DataType.Date;
        case ProductDataType.DATE_TIME:
            return DataType.DateTime;
        case ProductDataType.FORMULA:
            return DataType.Formula;
        case ProductDataType.COLOR:
            return DataType.Color;
        default:
            return productDataType;
    }
}
export const mapDataType = (dataType) => {
    switch (dataType) {
        case DataType.Boolean:
            return ProductDataType.BOOLEAN;
        case DataType.Color:
            return ProductDataType.COLOR;
        case DataType.Date:
            return ProductDataType.DATE;
        case DataType.DateTime:
            return ProductDataType.DATE_TIME;
        case DataType.Float:
            return ProductDataType.FLOAT;
        case DataType.Formula:
            return ProductDataType.FORMULA;
        case DataType.Handbook:
            return ProductDataType.HANDBOOK;
        case DataType.Integer:
            return ProductDataType.INTEGER;
        case DataType.Varchar:
            return ProductDataType.VARCHAR;
        default:
            return dataType;
    }
};
