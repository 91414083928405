import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ProductTypeAutomationListDocument = gql `
    query ProductTypeAutomationList($productTypeId: Long!, $pageSize: Int!, $search: String, $page: Int!) {
  productTypeAutomationList(
    productTypeId: $productTypeId
    pageSize: $pageSize
    search: $search
    page: $page
  ) {
    data {
      id
      enable
      title
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useProductTypeAutomationListQuery__
 *
 * To run a query within a React component, call `useProductTypeAutomationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypeAutomationListQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useProductTypeAutomationListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProductTypeAutomationListDocument, options);
}
export function useProductTypeAutomationListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProductTypeAutomationListDocument, options);
}
export function useProductTypeAutomationListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProductTypeAutomationListDocument, options);
}
export const ProductTypeAutomationDocument = gql `
    query ProductTypeAutomation($productTypeAutomationId: Long!) {
  productTypeAutomation(productTypeAutomationId: $productTypeAutomationId) {
    id
    enable
    title
    schedule
    productTypeAttribute {
      id
      dataType
      title
      handbookId
    }
    productTypeAutomationExpressions {
      enable
      id
      result
      sort
      productTypeAutomationConditions {
        id
        operator
        where
        productTypeAttribute {
          id
          dataType
          title
        }
      }
      productTypeAttribute {
        id
        dataType
        title
      }
    }
  }
}
    `;
/**
 * __useProductTypeAutomationQuery__
 *
 * To run a query within a React component, call `useProductTypeAutomationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAutomationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypeAutomationQuery({
 *   variables: {
 *      productTypeAutomationId: // value for 'productTypeAutomationId'
 *   },
 * });
 */
export function useProductTypeAutomationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProductTypeAutomationDocument, options);
}
export function useProductTypeAutomationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProductTypeAutomationDocument, options);
}
export function useProductTypeAutomationSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProductTypeAutomationDocument, options);
}
