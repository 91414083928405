import { Form, Modal } from "antd";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import WarehouseCellForm from "../../components/WarehouseCellForm/WarehouseCellForm";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { selectTopologySection } from "../../redux/reducers/topologyReducer";
import warehouseCellApi from "../../redux/api/warehouseApi/warehouseCellReducer";
const { useFetchCellQuery, useAddCellMutation, useEditCellMutation } = warehouseCellApi;
export const WAREHOUSE_CELL_MODAL_PARAM = "cellModal";
const WarehouseCellModal = () => {
    var _a, _b, _c, _d;
    const { id } = useParams();
    const warehouseId = id ? +id : null;
    const [searchParams, setSearchParams] = useSearchParams();
    const cellIdParam = searchParams.get(WAREHOUSE_CELL_MODAL_PARAM);
    const cellId = cellIdParam ? +cellIdParam : null;
    const parentIdParam = searchParams.get("parent");
    const parentId = parentIdParam ? +parentIdParam : null;
    const { data: cell, isLoading } = useFetchCellQuery({
        warehouseId: warehouseId,
        storageCellId: cellId,
    }, {
        skip: !Number.isInteger(cellId) || !Number.isInteger(warehouseId),
    });
    const [addCell] = useAddCellMutation();
    const [editCell] = useEditCellMutation();
    const parentSectionId = (_a = cell === null || cell === void 0 ? void 0 : cell.parentSectionId) !== null && _a !== void 0 ? _a : parentId;
    const parent = useTypedSelector(selectTopologySection(parentSectionId));
    const [form] = Form.useForm();
    let initialValues;
    if (cell) {
        initialValues = {
            id: cell.id,
            title: cell.title,
            description: cell.description,
            storageCellTypeId: {
                label: cell.storageCellType.title,
                value: cell.storageCellType.id,
            },
            storageSectionId: {
                label: (_b = parent === null || parent === void 0 ? void 0 : parent.title) !== null && _b !== void 0 ? _b : "",
                value: (_c = parent === null || parent === void 0 ? void 0 : parent.id) !== null && _c !== void 0 ? _c : "",
            },
        };
    }
    else if (parent) {
        initialValues = {
            storageSectionId: {
                label: parent.title,
                value: parent.id,
            },
        };
    }
    useEffect(() => {
        setTimeout(() => {
            form.resetFields();
        }, 0);
    }, [initialValues]);
    const handleOk = () => form.submit();
    const handleCancel = () => {
        form.resetFields();
        searchParams.delete(WAREHOUSE_CELL_MODAL_PARAM);
        searchParams.delete("parent");
        setSearchParams(searchParams);
    };
    const handlerSubmit = (formValues) => {
        var _a, _b, _c, _d, _e, _f;
        if (warehouseId) {
            if (formValues.id === undefined) {
                const values = {
                    storageCellTypeId: +((_a = formValues === null || formValues === void 0 ? void 0 : formValues.storageCellTypeId) === null || _a === void 0 ? void 0 : _a.value),
                    title: formValues.title,
                    description: formValues.description,
                    storageSectionId: ((_b = formValues.storageSectionId) === null || _b === void 0 ? void 0 : _b.value) ? +((_c = formValues.storageSectionId) === null || _c === void 0 ? void 0 : _c.value) : null,
                };
                if (warehouseId) {
                    addCell({
                        input: { storageCellTypeId: (values === null || values === void 0 ? void 0 : values.storageCellTypeId) || 1, title: values === null || values === void 0 ? void 0 : values.title, warehouseId: warehouseId, description: values === null || values === void 0 ? void 0 : values.description, storageSectionId: values === null || values === void 0 ? void 0 : values.storageSectionId },
                    })
                        .unwrap()
                        .then(() => handleCancel());
                }
            }
            else {
                const values = {
                    storageCellTypeId: +((_d = formValues === null || formValues === void 0 ? void 0 : formValues.storageCellTypeId) === null || _d === void 0 ? void 0 : _d.value),
                    title: formValues.title,
                    description: formValues.description,
                    storageSectionId: ((_e = formValues.storageSectionId) === null || _e === void 0 ? void 0 : _e.value) ? +((_f = formValues.storageSectionId) === null || _f === void 0 ? void 0 : _f.value) : null,
                };
                if (cellId) {
                    editCell({
                        input: {
                            storageCellId: cellId,
                            storageCellTypeId: (values === null || values === void 0 ? void 0 : values.storageCellTypeId) || 1,
                            title: values === null || values === void 0 ? void 0 : values.title,
                            warehouseId: warehouseId,
                            description: values === null || values === void 0 ? void 0 : values.description,
                            storageSectionId: values === null || values === void 0 ? void 0 : values.storageSectionId,
                        },
                    })
                        .unwrap()
                        .then(() => handleCancel());
                }
            }
        }
    };
    return (React.createElement(Modal, { open: !!cellIdParam, confirmLoading: isLoading, onOk: handleOk, onCancel: handleCancel, title: `${(initialValues === null || initialValues === void 0 ? void 0 : initialValues.id) === undefined ? "Добавить" : "Редактировать"} ячейку`, okText: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", cancelText: "\u041E\u0442\u043C\u0435\u043D\u0430" },
        React.createElement(WarehouseCellForm, { form: form, initialValues: initialValues, warehouseId: (_d = warehouseId === null || warehouseId === void 0 ? void 0 : warehouseId.toString()) !== null && _d !== void 0 ? _d : "", onSubmit: handlerSubmit })));
};
export default WarehouseCellModal;
