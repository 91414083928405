import { useEffect } from 'react';
const useResize = (callback, deps = []) => {
    useEffect(() => {
        callback();
        window.addEventListener('load', callback);
        window.addEventListener('resize', callback);
        window.addEventListener('orientationchange', callback);
        return () => {
            window.removeEventListener('load', callback);
            window.removeEventListener('resize', callback);
            window.removeEventListener('orientationchange', callback);
        };
    }, deps);
};
export default useResize;
