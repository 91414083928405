import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const StorageSectionTypeDeleteDocument = gql `
    mutation StorageSectionTypeDelete($input: StorageSectionTypeDeleteInput!) {
  storageSectionTypeDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useStorageSectionTypeDeleteMutation__
 *
 * To run a mutation, you first call `useStorageSectionTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageSectionTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageSectionTypeDeleteMutation, { data, loading, error }] = useStorageSectionTypeDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageSectionTypeDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageSectionTypeDeleteDocument, options);
}
export const StorageSectionDeleteDocument = gql `
    mutation StorageSectionDelete($input: StorageSectionDeleteInput!) {
  storageSectionDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useStorageSectionDeleteMutation__
 *
 * To run a mutation, you first call `useStorageSectionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageSectionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageSectionDeleteMutation, { data, loading, error }] = useStorageSectionDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageSectionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageSectionDeleteDocument, options);
}
export const StorageSectionTypeEditDocument = gql `
    mutation StorageSectionTypeEdit($input: StorageSectionTypeEditInput!) {
  storageSectionTypeEdit(input: $input) {
    storageSectionTypeDto {
      id
    }
  }
}
    `;
/**
 * __useStorageSectionTypeEditMutation__
 *
 * To run a mutation, you first call `useStorageSectionTypeEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageSectionTypeEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageSectionTypeEditMutation, { data, loading, error }] = useStorageSectionTypeEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageSectionTypeEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageSectionTypeEditDocument, options);
}
export const StorageSectionEditDocument = gql `
    mutation StorageSectionEdit($input: StorageSectionEditInput!) {
  storageSectionEdit(input: $input) {
    storageSectionDto {
      id
      address
      description
      title
      storageCells {
        address
        description
        title
        warehouseId
        id
      }
      storageSections {
        id
        address
        description
        title
      }
      storageSectionType {
        description
        icon
        id
        separator
        title
      }
    }
  }
}
    `;
/**
 * __useStorageSectionEditMutation__
 *
 * To run a mutation, you first call `useStorageSectionEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageSectionEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageSectionEditMutation, { data, loading, error }] = useStorageSectionEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageSectionEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageSectionEditDocument, options);
}
export const StorageSectionCreateDocument = gql `
    mutation StorageSectionCreate($input: StorageSectionCreateInput!) {
  storageSectionCreate(input: $input) {
    storageSectionDto {
      id
      address
      description
      title
      storageCells {
        address
        description
        title
        warehouseId
        id
      }
      storageSections {
        id
        address
        description
        title
      }
      storageSectionType {
        description
        icon
        id
        separator
        title
      }
    }
  }
}
    `;
/**
 * __useStorageSectionCreateMutation__
 *
 * To run a mutation, you first call `useStorageSectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageSectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageSectionCreateMutation, { data, loading, error }] = useStorageSectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageSectionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageSectionCreateDocument, options);
}
export const StorageSectionTypeCreateDocument = gql `
    mutation StorageSectionTypeCreate($input: StorageSectionTypeCreateInput!) {
  storageSectionTypeCreate(input: $input) {
    storageSectionTypeDto {
      id
    }
  }
}
    `;
/**
 * __useStorageSectionTypeCreateMutation__
 *
 * To run a mutation, you first call `useStorageSectionTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageSectionTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageSectionTypeCreateMutation, { data, loading, error }] = useStorageSectionTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageSectionTypeCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageSectionTypeCreateDocument, options);
}
