var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useStorageSectionTypeCreateMutation, useStorageSectionTypeDeleteMutation, useStorageSectionTypeEditMutation } from "../../../../api/graphql/queries/storageSection/StorageSectionMutation";
import { useStorageSectionTypeLazyQuery, useStorageSectionTypeListQuery } from "../../../../api/graphql/queries/storageSection/StorageSectionQuery";
export const useWarehouseSectionType = ({ warehouseId, page, pageSize }) => {
    const { data, loading, error, refetch } = useStorageSectionTypeListQuery({ variables: { warehouseId, page: page || 1, pageSize: pageSize || 50 }, fetchPolicy: "cache-and-network" });
    const [deleteStorageSectionType, { loading: deleteStorageCellTypeLoading }] = useStorageSectionTypeDeleteMutation();
    const [createStorageSectionType, { loading: createStorageCellTypeLoading }] = useStorageSectionTypeCreateMutation();
    const [editStorageSectionType, { loading: editStorageCellTypeLoading }] = useStorageSectionTypeEditMutation();
    const [getStorageSectionType, getStorageSectionTypeResponce] = useStorageSectionTypeLazyQuery();
    const handleDelete = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteStorageSectionType({ variables: { input } });
        yield refetch();
    });
    const handleCreate = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield createStorageSectionType({ variables: { input } });
        yield refetch();
    });
    const handleEdit = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield editStorageSectionType({ variables: { input } });
        yield refetch();
    });
    return {
        getStorageSectionTypeList: {
            data,
            error,
            isLoading: loading,
            refetch,
        },
        deleteStorageSectionType: {
            handleAction: handleDelete,
            isLoading: deleteStorageCellTypeLoading,
        },
        editStorageSectionType: {
            handleAction: handleEdit,
            isLoading: editStorageCellTypeLoading,
        },
        createStorageSectionType: {
            handleAction: handleCreate,
            isLoading: createStorageCellTypeLoading,
        },
        getStorageSectionType: {
            handleAction: getStorageSectionType,
            responce: getStorageSectionTypeResponce,
        },
    };
};
export const adaptStorageSectionTypeInput = (input, warehouseId) => {
    return {
        description: input.description,
        title: input.title,
        warehouseId: warehouseId,
        separator: input.separator || "",
        icon: input.icon || "",
    };
};
export const adaptStorageSectionTypeListDto = (input) => {
    const adaptedData = [];
    for (let i = 0; i < input.length; i++) {
        const cell = input[i];
        adaptedData.push({
            id: Number(cell.id),
            title: cell.title || "",
            description: cell.description || "",
            icon: cell.icon,
            separator: cell.separator,
        });
    }
    return adaptedData;
};
