import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Modal, Space, Table, Typography, } from 'antd';
import React from 'react';
import { getColumnType } from '../../helpers/function';
import FullHeight from '../FullHeight';
import MDIcon from '../ui/MDIcon';
const { confirm } = Modal;
const { Text } = Typography;
const WarehouseSectionTypesTable = (props) => {
    const { items, loading, onDelete, onEdit, } = props;
    const handlerEdit = (id) => {
        onEdit(id);
    };
    const handlerDelete = (record) => {
        confirm({
            title: 'Удаление продукции',
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: (React.createElement(React.Fragment, null,
                'Вы уверены, что хотите удалить продукцию ',
                React.createElement("b", null, record.title),
                "?")),
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отмена',
            onOk() {
                onDelete(record.id);
            },
        });
    };
    const menu = (record) => ({
        items: [
            {
                key: "product_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => handlerEdit(record.id)
            },
            {
                key: "product_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => handlerDelete(record)
            }
        ]
    });
    const columns = [
        getColumnType('id', '#', false, { width: '1%' }),
        getColumnType('title', 'Тип секции', false, {
            render: (value, record) => (React.createElement(Space, { direction: "vertical" },
                React.createElement(Text, null, record.title),
                React.createElement(Text, { type: "secondary" }, record.description))),
        }),
        getColumnType('actions', '', false, {
            width: '1%',
            render: (value, record) => (React.createElement(Dropdown, { menu: menu(record), trigger: ['click'] },
                React.createElement(Button, { size: "small", icon: React.createElement(MDIcon, { icon: "pencil" }) }))),
        }),
    ];
    const data = items.map((item) => (Object.assign({ key: item.id }, item)));
    return (React.createElement(FullHeight, null, (tableHeight) => (React.createElement(Table, { columns: columns, dataSource: data, loading: loading, bordered: true, size: "small", pagination: false, scroll: { x: true, y: tableHeight } }))));
};
export default WarehouseSectionTypesTable;
