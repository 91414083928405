import * as warehouseAdapters from './warehouse';
import * as paginationAdapters from './pagination';
import * as userAdapters from './user';
import * as taskStatusAdapters from './status';
import * as comment from './comment';
import * as reportMovement from './reports/movement';
import * as reportAvailabilityOnDate from './reports/availabilityOnDate';
import * as client from './client';
import * as handbook from './handbook';
import * as productCategory from './productCategory';
import * as productType from './productType';
import * as product from './product';
import * as document from './document/document';
import * as system from './system';
const dtoToEntity = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, warehouseAdapters), paginationAdapters), userAdapters), taskStatusAdapters), comment), reportMovement), reportAvailabilityOnDate), client), handbook), productCategory), productType), product), document), system);
export default dtoToEntity;
