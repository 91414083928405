var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useWarehouseCreateMutation, useWarehouseDeleteMutation, useWarehouseEditMutation } from "../../../../api/graphql/queries/warehouse/WarehouseMutation";
import { useWarehouseLazyQuery, useWarehouseListQuery } from "../../../../api/graphql/queries/warehouse/WarehouseQuery";
export const useHandbookWarehousesPage = ({ page, pageSize }) => {
    const { data, loading, error, refetch } = useWarehouseListQuery({ variables: { page: page || 1, pageSize: pageSize || 50 }, fetchPolicy: "cache-and-network" });
    return {
        getWarehouseList: {
            data,
            error,
            isLoading: loading,
            refetch,
        },
    };
};
export const useHandbookWarehousesActions = () => {
    const [deleteWarehouse, { loading: deleteWarehouseLoading }] = useWarehouseDeleteMutation();
    const [createWarehouse, { loading: createWarehouseLoading, data: createWarehouseData }] = useWarehouseCreateMutation();
    const [editWarehouse, { loading: editWarehouseLoading }] = useWarehouseEditMutation();
    const [getWarehouseType, getWarehouseResponce] = useWarehouseLazyQuery();
    const handleDelete = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteWarehouse({ variables: { input } });
    });
    const handleCreate = (input) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield createWarehouse({ variables: { input } });
        return res;
    });
    const handleEdit = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield editWarehouse({ variables: { input } });
    });
    return {
        deleteWarehouseType: {
            handleAction: handleDelete,
            isLoading: deleteWarehouseLoading,
        },
        editWarehouseType: {
            handleAction: handleEdit,
            isLoading: editWarehouseLoading,
        },
        createWarehouseType: {
            handleAction: handleCreate,
            isLoading: createWarehouseLoading,
        },
        getWarehouseType: {
            handleAction: getWarehouseType,
            responce: getWarehouseResponce,
        },
    };
};
export const adaptToHandbookWarehouseEntity = (warehouseList) => {
    return warehouseList.map((warehouse) => {
        return {
            id: warehouse.id,
            storageType: 0,
            storageTitle: warehouse.storageTitle,
            address: warehouse.address || "",
            addressCountry: "",
            addressCity: "",
            addressRegion: "",
            addressIndex: "",
            addressStreet: "",
            addressBuilding: "",
            geoLatitude: 0,
            geoLongitude: 0,
            timeZoneId: warehouse.timeZone.id,
            timeZoneReadable: warehouse.timeZone.displayName,
        };
    });
};
export const adaptToHandbookWarehouseFormValues = (warehouseDto) => {
    var _a, _b, _c, _d;
    if (warehouseDto) {
        return {
            storageType: 0,
            storageTitle: warehouseDto.storageTitle || "",
            addressIndex: warehouseDto.addressIndex || "",
            addressCountry: warehouseDto.addressCountry || "",
            addressRegion: warehouseDto.addressRegion || "",
            addressCity: warehouseDto.addressCity || "",
            addressStreet: warehouseDto.addressStreet || "",
            addressBuilding: warehouseDto.addressBuilding || "",
            geoLatitude: ((_a = warehouseDto === null || warehouseDto === void 0 ? void 0 : warehouseDto.geoLatitude) === null || _a === void 0 ? void 0 : _a.toString()) || "",
            geoLongitude: ((_b = warehouseDto === null || warehouseDto === void 0 ? void 0 : warehouseDto.geoLongitude) === null || _b === void 0 ? void 0 : _b.toString()) || "",
            timeZoneId: { label: (_c = warehouseDto === null || warehouseDto === void 0 ? void 0 : warehouseDto.timeZone) === null || _c === void 0 ? void 0 : _c.displayName, value: ((_d = warehouseDto === null || warehouseDto === void 0 ? void 0 : warehouseDto.timeZone) === null || _d === void 0 ? void 0 : _d.id) || "" },
        };
    }
    return undefined;
};
