var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EditOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, Col, Form, InputNumber, Modal, Row, notification } from "antd";
import React, { useState } from "react";
import { useTaskShippingEditRequirementsMutation } from "../../../../../api/graphql/queries/task/taskShipping/TaskShippingMutation";
import { useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
export const ShippingDetailNominclatureRequirement = ({ refetch, requirementId, requirementIsRequired, requirementQuantity, requirementComment }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const { taskId } = useParams();
    const showModal = () => {
        form.setFieldsValue({
            comment: requirementComment,
            quantity: requirementQuantity,
            isRequired: requirementIsRequired,
        });
        setIsModalVisible(true);
    };
    const [editRequirements, { loading }] = useTaskShippingEditRequirementsMutation();
    const handleOk = () => __awaiter(void 0, void 0, void 0, function* () {
        const row = yield form.validateFields();
        if (taskId && requirementId) {
            yield editRequirements({
                variables: {
                    input: {
                        taskId: +taskId,
                        request: [
                            {
                                id: requirementId,
                                isRequired: row.isRequired,
                                quantity: row.quantity,
                                comment: row.comment,
                            },
                        ],
                    },
                },
            })
                .then(() => {
                notification.success({
                    message: "Требования изменены",
                    description: "Требования к SKU успешно обновлены.",
                });
                console.log("refetch", refetch);
                refetch();
            })
                .catch(() => {
                notification.error({
                    message: "Ошибка",
                    description: "Произошла ошибка при обновлении требований.",
                });
            })
                .finally(() => {
                setIsModalVisible(false);
            });
        }
    });
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { flex: "auto" },
                React.createElement(Alert, { showIcon: true, message: React.createElement("div", { style: { display: "flex", justifyContent: "space-between", width: "100%" } },
                        React.createElement("span", null, requirementIsRequired ? "Обязятельное требование к SKU" : "Не обязятельное требование к SKU"),
                        React.createElement(Button, { size: "small", icon: React.createElement(EditOutlined, null), onClick: showModal })), description: React.createElement("ul", null,
                        React.createElement("li", null,
                            "\u0422\u0440\u0435\u0431\u0443\u0435\u043C\u043E\u0435 \u043A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E: ",
                            requirementQuantity),
                        React.createElement("li", { style: { whiteSpace: "pre-wrap" } }, `Комментарий:\r\n${requirementComment}`)), type: requirementIsRequired ? "warning" : "info" }))),
        React.createElement(Modal, { footer: [
                React.createElement(Button, { key: "back", onClick: handleCancel, disabled: loading }, "\u041E\u0442\u043C\u0435\u043D\u0430"),
                React.createElement(Button, { key: "submit", type: "primary", loading: loading, onClick: handleOk }, "\u041E\u043A"),
            ], title: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0442\u0440\u0435\u0431\u043E\u0432\u0430\u043D\u0438\u044F", visible: isModalVisible, onOk: handleOk, onCancel: handleCancel },
            React.createElement(Form, { form: form, layout: "vertical" },
                React.createElement(Form.Item, { style: { resize: "none" }, label: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439", name: "comment" },
                    React.createElement(TextArea, null)),
                React.createElement(Form.Item, { label: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E", name: "quantity", required: true, rules: [{ required: true, message: "Пожалуйста, введите количество!" }] },
                    React.createElement(InputNumber, { min: 0, step: 0.01 })),
                React.createElement(Form.Item, { name: "isRequired", valuePropName: "checked" },
                    React.createElement(Checkbox, null, "\u041E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E\u0435 \u0442\u0440\u0435\u0431\u043E\u0432\u0430\u043D\u0438\u0435"))))));
};
