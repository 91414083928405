import { createApi } from '@reduxjs/toolkit/query/react';
import dtoToEntity from '../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../constants/enum/Method';
import fetchAppQuery from '../../fetchAppQuery';
export const STATUS_CONFIGURATOR_STATUS_TAG = 'statusConfiguratorStatus';
export const STATUS_CONFIGURATOR_RELATION_TAG = 'statusConfiguratorRelation';
const getUri = (objectClass) => `status/${objectClass}`;
const statusConfiguratorStatusApi = createApi({
    reducerPath: 'statusConfigurator/statusApi',
    tagTypes: [STATUS_CONFIGURATOR_STATUS_TAG, STATUS_CONFIGURATOR_RELATION_TAG],
    baseQuery: fetchAppQuery,
    endpoints: (builder) => ({
        fetchStatusList: builder.query({
            query: (objectClass) => ({
                method: Method.GET,
                url: `${getUri(objectClass)}/list`,
            }),
            transformResponse: (response) => (response.map(dtoToEntity.statusAdapter)),
            providesTags: () => [STATUS_CONFIGURATOR_STATUS_TAG],
        }),
        fetchStatus: builder.query({
            query: (args) => {
                const { objectClass, statusId } = args;
                return {
                    method: Method.GET,
                    url: `${getUri(objectClass)}/${statusId}`,
                };
            },
            transformResponse: (response) => (dtoToEntity.statusAdapter(response)),
        }),
        addStatus: builder.mutation({
            query: (args) => {
                const { objectClass, values } = args;
                return {
                    method: Method.POST,
                    url: `${getUri(objectClass)}/add`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.statusAdapter(response)),
            invalidatesTags: [STATUS_CONFIGURATOR_STATUS_TAG, STATUS_CONFIGURATOR_RELATION_TAG],
        }),
        editStatus: builder.mutation({
            query: (args) => {
                const { objectClass, statusId, values } = args;
                return {
                    method: Method.PUT,
                    url: `${getUri(objectClass)}/${statusId}`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.statusAdapter(response)),
            invalidatesTags: [STATUS_CONFIGURATOR_STATUS_TAG, STATUS_CONFIGURATOR_RELATION_TAG],
        }),
        deleteStatus: builder.mutation({
            query: (args) => {
                const { objectClass, statusId } = args;
                return {
                    method: Method.DELETE,
                    url: `${getUri(objectClass)}/${statusId}`,
                };
            },
            transformResponse: (_, __, { statusId }) => statusId,
            invalidatesTags: [STATUS_CONFIGURATOR_STATUS_TAG, STATUS_CONFIGURATOR_RELATION_TAG],
        }),
    }),
});
export default statusConfiguratorStatusApi;
