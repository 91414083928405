var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import SearchSelect from "../../components/SearchSelect";
import { getHandbookValueList } from "../../api/rest/requests/handbook/handbookValue";
const HandbookSearchContainer = (props) => {
    const { handbookId, style, value, loading } = props, otherProps = __rest(props, ["handbookId", "style", "value", "loading"]);
    const handlerSearch = (page, pageSize, query, setLoadingState) => __awaiter(void 0, void 0, void 0, function* () { return getHandbookValueList(handbookId.toString(), page.toString(), pageSize.toString(), query, setLoadingState); });
    return React.createElement(SearchSelect, Object.assign({}, otherProps, { loading: loading, value: value, style: style, dataToOption: ({ id, title }) => ({ value: id, label: title }), placeholder: "\u041D\u0430\u0439\u0442\u0438", request: handlerSearch }));
};
export default HandbookSearchContainer;
