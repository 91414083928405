import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskStatusFragmentDoc = gql `
    fragment TaskStatus on TaskStatusDto {
  title
  isFinal
  style
  scope
  relations {
    id
    title
    style
    needReport
  }
}
    `;
export const TaskWriteOffListDocument = gql `
    query taskWriteOffList($page: Int!, $pageSize: Int!, $filter: TaskFilterRequestInput!) {
  taskWriteOffList(page: $page, pageSize: $pageSize, filter: $filter) {
    data {
      id
      externalId
      createdAt
      updatedAt
      executedAt
      warehouse {
        id
        storageTitle
      }
      skuGroup {
        productId
        type
        title
        quantity
        count
        dimension
      }
      status {
        title
        scope
        style
      }
      user {
        id
        userName
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useTaskWriteOffListQuery__
 *
 * To run a query within a React component, call `useTaskWriteOffListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWriteOffListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWriteOffListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskWriteOffListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskWriteOffListDocument, options);
}
export function useTaskWriteOffListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskWriteOffListDocument, options);
}
export function useTaskWriteOffListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskWriteOffListDocument, options);
}
export const GetTaskWriteOffHistoryDocument = gql `
    query getTaskWriteOffHistory($taskId: Long!) {
  taskWriteOffHistory(taskId: $taskId) {
    createdAt
    isEntryPoint
    needReport
    report
    statusFrom {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    statusTo {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    user {
      uid
      email
      userName
      id
    }
  }
}
    `;
/**
 * __useGetTaskWriteOffHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskWriteOffHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskWriteOffHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskWriteOffHistoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskWriteOffHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTaskWriteOffHistoryDocument, options);
}
export function useGetTaskWriteOffHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTaskWriteOffHistoryDocument, options);
}
export function useGetTaskWriteOffHistorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetTaskWriteOffHistoryDocument, options);
}
export const TaskWriteOffDocument = gql `
    query TaskWriteOff($taskId: Long!) {
  taskWriteOff(taskId: $taskId) {
    id
    externalId
    createdAt
    updatedAt
    executedAt
    taskWriteOffSkus {
      id
      quantity
      reason
      skuId
      title
      type
      storageCell {
        id
        title
        address
      }
    }
    warehouse {
      id
      storageTitle
    }
    user {
      userName
    }
    status {
      ...TaskStatus
    }
  }
}
    ${TaskStatusFragmentDoc}`;
/**
 * __useTaskWriteOffQuery__
 *
 * To run a query within a React component, call `useTaskWriteOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWriteOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWriteOffQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskWriteOffQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskWriteOffDocument, options);
}
export function useTaskWriteOffLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskWriteOffDocument, options);
}
export function useTaskWriteOffSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskWriteOffDocument, options);
}
