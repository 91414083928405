import { Col, Form, Input, Row, } from 'antd';
import React from 'react';
import HandbookCategorySelect from '../HandbookCategorySelect';
const { TextArea } = Input;
var FormNames;
(function (FormNames) {
    FormNames["ID"] = "id";
    FormNames["PARENT_ID"] = "handbookCategoryId";
    FormNames["TITLE"] = "title";
    FormNames["DESCRIPTION"] = "description";
})(FormNames || (FormNames = {}));
const HandbookForm = (props) => {
    const { form, onSubmit, initialValues } = props;
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues },
        React.createElement(Form.Item, { name: FormNames.ID, hidden: true },
            React.createElement(Input, null)),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.PARENT_ID, label: "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F", rules: [{ required: true, message: 'Поле не заполнено' }] },
                    React.createElement(HandbookCategorySelect, null))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.TITLE, label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0441\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A\u0430", rules: [{ required: true, message: 'Поле не заполнено' }] },
                    React.createElement(Input, null)))),
        React.createElement(Form.Item, { name: FormNames.DESCRIPTION, label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u0441\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A\u0430" },
            React.createElement(TextArea, null))));
};
export default HandbookForm;
