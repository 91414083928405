export const getRowClass = (record) => {
    var _a, _b, _c;
    if (!((_a = record.storageCellActual) === null || _a === void 0 ? void 0 : _a.id)) {
        return "row_red";
    }
    if (((_b = record === null || record === void 0 ? void 0 : record.storageCellExpected) === null || _b === void 0 ? void 0 : _b.id) !== ((_c = record === null || record === void 0 ? void 0 : record.storageCellActual) === null || _c === void 0 ? void 0 : _c.id) || !!record.comment) {
        return "row_yellow";
    }
    return "";
};
