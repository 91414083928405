var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Form, message, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getHandbook } from "../../api/rest/requests/handbook/handbook";
import { formatFormulaInput } from "../../components/FormulaMaskInput/FormulaMaskInput";
import ProductTypeAttrForm from "../../components/ProductTypeAttrForm";
import { productTypeGraphqlApi } from "../../redux/api/productApi/productType";
import { convertProductDataTypeToDataType } from "../../api/graphql/adapters";
import { getProductDataTypeName } from "../../helpers/function";
const { useFetchProductTypeQuery, useEditProductTypeAttributeMutation } = productTypeGraphqlApi;
const ProductTypeAttrFormEditContainer = () => {
    const { typeId: typeParam, productAttrId } = useParams();
    const typeId = typeParam ? +typeParam : null;
    const { data: type, isLoading: typeIsLoading } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    const [editProductTypeAttribute, { isLoading: editIsLoading }] = useEditProductTypeAttributeMutation();
    const isLoading = typeIsLoading || editIsLoading;
    const [form] = Form.useForm();
    const [attribute, categoryId] = useMemo(() => {
        var _a, _b;
        let catId;
        for (const cat of (_a = type === null || type === void 0 ? void 0 : type.productTypeCategories) !== null && _a !== void 0 ? _a : []) {
            catId = cat.id;
            for (const attr of (_b = cat.productTypeAttributes) !== null && _b !== void 0 ? _b : []) {
                if (attr.id.toString() === productAttrId) {
                    return [attr, catId];
                }
            }
        }
        return [];
    }, [type]);
    const [handbookValue, setHandbookValue] = useState();
    useEffect(() => {
        const handbookId = attribute === null || attribute === void 0 ? void 0 : attribute.handbookId;
        if (handbookId) {
            const handbookRequest = () => __awaiter(void 0, void 0, void 0, function* () {
                const handbook = yield getHandbook(handbookId);
                setHandbookValue({
                    label: handbook.title,
                    value: handbook.id,
                });
            });
            handbookRequest();
        }
    }, [attribute]);
    const handlerButtonClick = () => {
        form.submit();
    };
    const handlerSubmit = (values) => {
        var _a;
        if (productAttrId && typeId) {
            editProductTypeAttribute({
                input: {
                    canBeSummarized: values.canBeSummarized,
                    dataType: typeof values.dataType === "string" ? convertProductDataTypeToDataType(values.dataType) : convertProductDataTypeToDataType(values.dataType.value),
                    description: values.description,
                    dimension: values.dimension,
                    handbookId: (_a = values.handbookId) === null || _a === void 0 ? void 0 : _a.value,
                    inFilter: values.inFilter,
                    inPrint: values.inPrint,
                    isMain: values.isMain,
                    formula: formatFormulaInput(values.formula),
                    isPersonal: values.isPersonal,
                    isRequired: values.isRequired,
                    isReadOnly: values.isReadOnly,
                    productTypeCategoryId: values.productTypeCategoryId,
                    title: values.title,
                },
                productTypeAttributeId: parseInt(productAttrId, 10),
            })
                .unwrap()
                .then(() => {
                message.success(`Атрибут ${values.title} успешно обновлен`);
            });
        }
    };
    const categories = useMemo(() => {
        var _a;
        return (_a = type === null || type === void 0 ? void 0 : type.productTypeCategories.map((item) => ({
            label: item.title,
            value: item.id,
        }))) !== null && _a !== void 0 ? _a : [];
    }, [type]);
    const initialValues = useMemo(() => {
        var _a, _b, _c;
        if (attribute) {
            const formula = (_c = (_b = (_a = type === null || type === void 0 ? void 0 : type.productTypeCategories) === null || _a === void 0 ? void 0 : _a.find((productTypeCategory) => productTypeCategory.id === categoryId)) === null || _b === void 0 ? void 0 : _b.productTypeAttributes) === null || _c === void 0 ? void 0 : _c.find((productTypeAttribute) => `${productTypeAttribute === null || productTypeAttribute === void 0 ? void 0 : productTypeAttribute.id}` === productAttrId);
            return {
                canBeSummarized: attribute === null || attribute === void 0 ? void 0 : attribute.canBeSummarized,
                dataType: { label: getProductDataTypeName(attribute === null || attribute === void 0 ? void 0 : attribute.dataType), value: attribute === null || attribute === void 0 ? void 0 : attribute.dataType },
                description: attribute === null || attribute === void 0 ? void 0 : attribute.description,
                dimension: attribute === null || attribute === void 0 ? void 0 : attribute.dimension,
                handbookId: handbookValue,
                formula: (formula === null || formula === void 0 ? void 0 : formula.formula) || "",
                inFilter: attribute === null || attribute === void 0 ? void 0 : attribute.inFilter,
                inPrint: attribute === null || attribute === void 0 ? void 0 : attribute.inPrint,
                isReadOnly: attribute === null || attribute === void 0 ? void 0 : attribute.isReadOnly,
                isMain: attribute === null || attribute === void 0 ? void 0 : attribute.isMain,
                isPersonal: attribute === null || attribute === void 0 ? void 0 : attribute.isPersonal,
                isRequired: attribute === null || attribute === void 0 ? void 0 : attribute.isRequired,
                productTypeCategoryId: categoryId,
                title: attribute === null || attribute === void 0 ? void 0 : attribute.title,
            };
        }
        return {};
    }, [attribute, handbookValue]);
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(ProductTypeAttrForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues, categories: categories }),
        React.createElement(Button, { onClick: handlerButtonClick }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
};
export default ProductTypeAttrFormEditContainer;
