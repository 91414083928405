import { Outlet } from 'react-router-dom';
import React from 'react';
import { links } from '../constants/links';
import LoadingListPage from '../core/pages/task/loading/LoadingListPage';
import LoadingTaskPage from '../core/pages/task/loading/LoadingTaskPage';
import LoadingAddPage from '../core/pages/task/loading/LoadingAddPage';
import LoadingEditPage from '../core/pages/task/loading/LoadingEditPage';
const paths = links.taskLoadingLinks;
const taskLoadingRoutes = {
    title: paths.list.title,
    breadcrumb: 'Загрузка',
    path: paths.list.path,
    element: React.createElement(Outlet, null),
    children: [
        {
            index: true,
            element: React.createElement(LoadingListPage, null),
        },
        {
            title: paths.page.title,
            breadcrumb: paths.page.breadcrumb,
            path: paths.page.url(':taskId'),
            element: React.createElement(Outlet, null),
            children: [
                {
                    index: true,
                    element: React.createElement(LoadingTaskPage, null),
                },
                {
                    title: paths.edit.title,
                    breadcrumb: paths.edit.breadcrumb,
                    path: paths.edit.url(':taskId'),
                    element: React.createElement(LoadingEditPage, null),
                },
            ],
        },
        {
            title: paths.add.title,
            breadcrumb: paths.add.breadcrumb,
            path: paths.add.url(),
            element: React.createElement(LoadingAddPage, null),
        },
    ],
};
export default taskLoadingRoutes;
