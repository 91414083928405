import React from 'react';
import { Empty, Space, Spin } from 'antd';
import TaskListItem from './TaskListItem';
import Centered from '../../ui/Centered';
const TaskList = (props) => {
    const { children, isLoading } = props;
    if (children.length > 0) {
        return (React.createElement(Space, { direction: "vertical", size: "middle", style: { display: 'flex' } }, children));
    }
    if (isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { size: "large" })));
    }
    return (React.createElement(Centered, null,
        React.createElement(Empty, { description: "\u041F\u043E \u0434\u0430\u043D\u043D\u043E\u043C\u0443 \u0437\u0430\u043F\u0440\u043E\u0441\u0443 \u0437\u0430\u0434\u0430\u0447 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E" })));
};
TaskList.Item = TaskListItem;
export default TaskList;
