import { debounce } from 'lodash';
import React, { useRef, useState, } from 'react';
import useResize from '../../hooks/useResize';
const DEBOUNCE_TIMEOUT = 100;
const FullHeight = (props) => {
    const { children } = props;
    const wrapper = useRef(null);
    const [tableHeight, setTableHeight] = useState(0);
    const updateDimensions = debounce(() => {
        var _a;
        const antTableHeader = (_a = wrapper.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ant-table-header');
        const wrapperHeight = wrapper.current ? wrapper.current.clientHeight : 0;
        const headerHeight = antTableHeader ? antTableHeader.clientHeight : 0;
        setTableHeight(Math.floor(wrapperHeight - headerHeight) - 1);
    }, DEBOUNCE_TIMEOUT);
    useResize(updateDimensions, []);
    return (React.createElement("div", { className: "wms-table-full", id: "wms-table-full", ref: wrapper }, children(tableHeight)));
};
export default FullHeight;
