var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import { getColumnType } from '../../../../helpers/function';
import SearchModalTable from '../../SearchModal/SearchModalTable';
import SkuItemActions from '../SkuItemActions';
import SkuStatusTag from '../../../SkuStatusTag';
import MDIcon from '../../../ui/MDIcon';
import ColorPalette from '../../../../constants/enum/ColorPalette';
import { Tag } from 'antd';
const SkuTable = (props) => {
    const { items, attributes, itemInfo, tableRef, onSkuInfoClick, setHeightOffset } = props, otherProps = __rest(props, ["items", "attributes", "itemInfo", "tableRef", "onSkuInfoClick", "setHeightOffset"]);
    useEffect(() => {
        var _a;
        const tableHead = (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ant-table-header');
        if (tableHead && setHeightOffset) {
            setHeightOffset(tableHead === null || tableHead === void 0 ? void 0 : tableHead.offsetHeight);
        }
    }, []);
    const actionsRender = (value, record) => {
        const handlerClick = (callback) => {
            if (onSkuInfoClick)
                onSkuInfoClick(record, callback);
        };
        return (React.createElement(SkuItemActions, { onClick: handlerClick }));
    };
    const columns = [];
    columns.push(getColumnType('id', '#', false, { fixed: 'left' }));
    columns.push(getColumnType('client', 'Поклажедатель', true, { fixed: 'left' }));
    if (attributes.length > 0) {
        columns.push({
            title: 'Номенклатура',
            children: [
                ...attributes
                    .filter((attr) => attr.isMain)
                    .map((attr) => {
                    if (attr.dataType === "Color") {
                        return getColumnType(attr.id, attr.title, true, {
                            render: (value) => (React.createElement(Tag, { color: value, style: { width: "100%" } }, "\u00A0")),
                        });
                    }
                    return getColumnType(attr.id, attr.title, true);
                }),
            ],
        });
    }
    else {
        columns.push(getColumnType('type', 'Тип'));
        columns.push(getColumnType('titleCompiled', 'Номенклатура'));
    }
    columns.push(getColumnType('quantity', 'Кол-во', true, { fixed: 'right' }));
    columns.push(getColumnType('storageCell', 'Ячейка', true, { fixed: 'right' }));
    columns.push(getColumnType('status', 'Статус', true, {
        fixed: 'right',
        render: (value) => (React.createElement(SkuStatusTag, { id: value })),
    }));
    columns.push(getColumnType('isPartitioned', 'Участие в делении', true, {
        fixed: 'right',
        render: (value) => typeof value === "boolean" && value && React.createElement(MDIcon, { icon: 'check-bold', color: ColorPalette.Green }),
    }));
    if (itemInfo) {
        columns.push(getColumnType('info', '', false, {
            render: actionsRender,
            fixed: 'right',
            width: '1%',
        }));
    }
    const data = items.map((item) => {
        var _a;
        return (Object.assign(Object.assign({ id: item.id.toString(), key: item.id.toString(), client: item.client.companyName || '', quantity: item.quantity.toString(), storageCell: ((_a = item.storageCell) === null || _a === void 0 ? void 0 : _a.title) || '', titleCompiled: item.product.titleCompiled || '', type: item.product.title || '', status: item.status.id, isPartitioned: !!item.isPartitioned }, item.skuAttributes), item.product.productAttributes));
    });
    return (React.createElement(SearchModalTable, Object.assign({}, otherProps, { data: data, columns: columns })));
};
export default SkuTable;
