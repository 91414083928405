import { createSlice } from '@reduxjs/toolkit';
import LoadingState from '../../../constants/enum/LoadingState';
import { getDefaultUserProfileEntity } from '../../../entities/user/UserProfileEntity';
import { fetchUserProfile } from './actionCreators';
const initialState = {
    loadingState: LoadingState.Ready,
    data: getDefaultUserProfileEntity(),
};
const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProfile.pending, (state) => {
            state.loadingState = LoadingState.Loading;
        })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.loadingState = LoadingState.Ready;
            state.data = action.payload;
        })
            .addCase(fetchUserProfile.rejected, (state) => {
            state.loadingState = LoadingState.Error;
            state.data = initialState.data;
        });
    },
});
export default userSlice.reducer;
