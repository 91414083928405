import { Card, Col, Row, Space, Typography, } from 'antd';
import moment from 'moment';
import React from 'react';
import TaskType from '../../constants/enum/TaskType';
import { links } from '../../constants/links';
import { getTaskTypeName, toDate } from '../../helpers/function';
import Label from '../ui/Label';
const { Link: AntLink, Text } = Typography;
const getLink = (id, taskType) => {
    switch (taskType) {
        case TaskType.INVENTORY:
            return links.taskInventoryLinks.page.url(id);
        case TaskType.LOADING:
            return links.taskLoadingLinks.page.url(id);
        case TaskType.MOVEMENT:
            return links.taskMovementLinks.page.url(id);
        case TaskType.PARTITION:
        case TaskType.PARTITION_ORIGINAL:
        case TaskType.PARTITION_CREATED:
            return links.taskPartitionLinks.page.url(id);
        case TaskType.SHIPPING:
            return links.taskShippingLinks.page.url(id);
        case TaskType.CHANGE_WAREHOUSE:
            return links.taskChangeWarehouseLinks.page.url(id);
        case TaskType.WRITE_OFF:
            return links.taskWriteOffLinks.page.url(id);
        case TaskType.CORRECTION:
            return links.taskCorrectionLinks.page.url(id);
        default:
            return links.homeLink.url();
    }
};
const SkuHistoryItem = (props) => {
    const { info } = props;
    const { createdAt, executedAt, status, taskId, taskType, user, warehouse, } = info;
    return (React.createElement(Card, null,
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { flex: "auto" },
                React.createElement(Space, { direction: "vertical" },
                    React.createElement(AntLink, { href: getLink(taskId, taskType), target: "_blank" },
                        `#${taskId}`,
                        taskType ? ` - ${getTaskTypeName(taskType)}` : ''),
                    React.createElement(Text, { type: "secondary" }, moment(toDate(createdAt)).format('dd D MMM YYYY HH:mm:ss')),
                    React.createElement("div", null,
                        React.createElement(Text, { strong: true }, "\u0421\u043A\u043B\u0430\u0434: "),
                        React.createElement(Text, null, warehouse.storageTitle)),
                    React.createElement("div", null,
                        React.createElement(Text, { strong: true }, "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C: "),
                        React.createElement(Text, null, user.userName)))),
            React.createElement(Col, null,
                React.createElement(Space, { direction: "vertical", align: "end" },
                    React.createElement(Label, { color: status.style, text: status.title }),
                    executedAt ? (React.createElement(Text, { type: "secondary" }, moment(toDate(executedAt)).format('dd D MMM YYYY HH:mm:ss'))) : null)))));
};
export default SkuHistoryItem;
