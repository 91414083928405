import React from "react";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { useTaskChangeWarehouse } from "../../../../hooks/api/taskChangeWarehouseApi/useTaskChangeWarehouse";
import ChangeWarehouseTaskListContainer from "../../../containers/task/changeWarehouse/ChangeWarehouseTaskListContainer";
import ChangeWarehouseTaskListFilterContainer from "../../../containers/task/changeWarehouse/ChangeWarehouseTaskListFilterContainer";
import ChangeWarehouseTaskListHeaderContainer from "../../../containers/task/changeWarehouse/ChangeWarehouseTaskListHeaderContainer";
import ChangeWarehouseTaskPaginationContainer from "../../../containers/task/changeWarehouse/ChangeWarehouseTaskPaginationContainer";
const ChangeWarehouseListPage = () => {
    const { taskList } = useTaskChangeWarehouse();
    const props = {
        toolbarBottom: React.createElement(ChangeWarehouseTaskPaginationContainer, { pagination: taskList.pagination, isLoading: taskList.loading, data: taskList.data }),
        toolbarTop: React.createElement(ChangeWarehouseTaskListHeaderContainer, { data: taskList === null || taskList === void 0 ? void 0 : taskList.data, isLoading: taskList.loading, pagination: taskList === null || taskList === void 0 ? void 0 : taskList.pagination }),
        toolbarRight: (React.createElement(ChangeWarehouseTaskListFilterContainer, { isLoading: taskList.loading, fetchList: taskList.paginationFilteredGetTaskList, objectClass: ObjectClass.TaskChangeWarehouse })),
    };
    return (React.createElement(Page, Object.assign({}, props),
        React.createElement(ChangeWarehouseTaskListContainer, { taskList: taskList === null || taskList === void 0 ? void 0 : taskList.data, pagination: taskList === null || taskList === void 0 ? void 0 : taskList.pagination, isLoading: taskList === null || taskList === void 0 ? void 0 : taskList.loading })));
};
export default ChangeWarehouseListPage;
