var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../../components/Page";
import { links } from "../../../../constants/links";
import { useTaskChangeWarehouseCRUDActions } from "../../../../hooks/api/taskChangeWarehouseApi/useTaskChangeWarehouseCRUDActions";
import ChangeWarehouseTaskForm from "../../../conponents/task/changeWarehouse/ChangeWarehouseTaskForm";
const ChangeWarehouseAddPage = () => {
    const navigate = useNavigate();
    const { actions } = useTaskChangeWarehouseCRUDActions();
    const { create, isLoading } = actions;
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: null,
        toolbarRight: null,
    };
    const handlerSubmit = (input) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        const response = yield create(input);
        const id = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.taskChangeWarehouseAdd) === null || _b === void 0 ? void 0 : _b.taskChangeWarehouseDto) === null || _c === void 0 ? void 0 : _c.id;
        if (id) {
            message.success(`Задача ${id} успешно сохранена`);
            navigate(links.taskChangeWarehouseLinks.edit.url(id));
        }
    });
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(ChangeWarehouseTaskForm, { isLoading: isLoading, onSubmit: handlerSubmit })));
};
export default ChangeWarehouseAddPage;
