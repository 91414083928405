import { notification } from "antd";
const isKnownAction = (action) => {
    return typeof action === "object" && action !== null && "type" in action;
};
export const rtkQueryErrorNotify = () => (next) => (action) => {
    var _a, _b;
    if (isKnownAction(action) && action.type.includes("rejected")) {
        if ((_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.title) {
            notification.error({ message: action.payload.data.title, placement: "top" });
        }
    }
    return next(action);
};
