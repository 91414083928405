import { Drawer as AntDDrawer, Descriptions } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WarehouseTopologyContext } from "./WarehouseTopology";
export const Drawer = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const cellId = query.get("cellId");
    const [visible, setVisible] = useState(false);
    const [cell, setCell] = useState(undefined);
    const context = useContext(WarehouseTopologyContext);
    if (context === undefined) {
        throw new Error("Drawer must be used within a WarehouseTopology");
    }
    const handleClose = () => {
        setVisible(false);
        navigate("?", { replace: true });
    };
    const { state } = context;
    useEffect(() => {
        var _a;
        if ((state === null || state === void 0 ? void 0 : state.cell) && cellId) {
            setVisible(true);
            const cell = (_a = state === null || state === void 0 ? void 0 : state.cell) === null || _a === void 0 ? void 0 : _a.get(Number(cellId));
            setCell(cell);
        }
    }, [state, cellId]);
    return (React.createElement(AntDDrawer, { destroyOnClose: true, onClose: handleClose, title: `Ячейка ${cell === null || cell === void 0 ? void 0 : cell.cellTitle}`, placement: "right", closable: true, open: visible, width: 500 }, cell ? (React.createElement(Descriptions, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u044F\u0447\u0435\u0439\u043A\u0435", bordered: true, column: 1 },
        React.createElement(Descriptions.Item, { label: "\u0410\u0434\u0440\u0435\u0441" }, cell === null || cell === void 0 ? void 0 : cell.cellAddress),
        React.createElement(Descriptions.Item, { label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" }, cell === null || cell === void 0 ? void 0 : cell.cellDescription),
        React.createElement(Descriptions.Item, { label: "\u0413\u043B\u0443\u0431\u0438\u043D\u0430" }, cell === null || cell === void 0 ? void 0 : cell.cellDepth),
        React.createElement(Descriptions.Item, { label: "\u0412\u044B\u0441\u043E\u0442\u0430" }, cell === null || cell === void 0 ? void 0 : cell.cellHeight),
        React.createElement(Descriptions.Item, { label: "\u0417\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u0435" }, cell === null || cell === void 0 ? void 0 : cell.loadPercent),
        React.createElement(Descriptions.Item, { label: "\u0421\u0435\u043A\u0446\u0438\u044F" }, cell === null || cell === void 0 ? void 0 : cell.sectionTitle),
        React.createElement(Descriptions.Item, { label: "\u0410\u0434\u0440\u0435\u0441 \u0441\u0435\u043A\u0446\u0438\u0438" }, cell === null || cell === void 0 ? void 0 : cell.sectionAddress))) : (React.createElement(React.Fragment, null, "\u042F\u0447\u0435\u0439\u043A\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430"))));
};
