import { Button, Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { links } from '../../constants/links';
const HandbookWarehouseTopologyHeader = (props) => {
    const { warehouseId } = props;
    if (!warehouseId) {
        return null;
    }
    return (React.createElement(Row, { justify: "end" },
        React.createElement(Col, null,
            React.createElement(Button.Group, { style: { gap: "8px" } },
                React.createElement(Link, { to: links.handbookWarehouseSectionsTypeLink.url(warehouseId) },
                    React.createElement(Button, { size: "small", type: "primary" }, "\u0422\u0438\u043F\u044B \u0441\u0435\u043A\u0446\u0438\u0439 \u0441\u043A\u043B\u0430\u0434\u0430")),
                React.createElement(Link, { to: links.handbookWarehouseCellsTypeLink.url(warehouseId) },
                    React.createElement(Button, { size: "small", type: "primary" }, "\u0422\u0438\u043F\u044B \u044F\u0447\u0435\u0435\u043A \u0441\u043A\u043B\u0430\u0434\u0430")),
                React.createElement(Link, { to: links.handbookWarehouseTopologyEditor.url(warehouseId) },
                    React.createElement(Button, { size: "small", type: "primary" }, "\u0422\u043E\u043F\u043E\u043B\u043E\u0433\u0438\u044F \u0441\u043A\u043B\u0430\u0434\u0430"))))));
};
export default HandbookWarehouseTopologyHeader;
