import React from 'react';
import HandbookUnitsTable from '../../components/HandbookUnitsTable';
import { usePagination } from '../../hooks/usePagination';
import handbookUnitApi from '../../redux/api/handbookApi/handbookUnit';
const { useFetchListQuery } = handbookUnitApi;
const HandbookUnitsTableContainer = (props) => {
    var _a;
    const { onEditItemClick } = props;
    const { page, pageSize } = usePagination();
    const { data, isLoading } = useFetchListQuery({ page, pageSize });
    return (React.createElement(HandbookUnitsTable, { onEditItemClick: onEditItemClick, loading: isLoading, items: (_a = data === null || data === void 0 ? void 0 : data.list) !== null && _a !== void 0 ? _a : [] }));
};
export default HandbookUnitsTableContainer;
