var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import { getReportExel } from "../../../api/rest/requests/reports/nomenclature";
import Page from "../../../components/Page";
import SkuInfoDrawerContainer from "../../containers/SkuInfoDrawerContainer";
import FindTable from "./FindTable";
import ToolbarFooter from "./ToolbarFooter";
import ToolbarRight from "./ToolbarRight";
import { useSkuAdapter } from "./hooks/useSkuAdapter";
const TABLE_DEFAULT_BOTTOM_OFFSET = 78;
export const PRODUCT_ID = "productTypeId";
export const COMMON_ATTRIBUTES = "productAttributes";
export const PERSONAL_ATTRIBUTES = "skuAttributes";
const NomenclaturePage = ({ pagination, isLoading, defaultPagination, items, filter, fetchSku, productFilterValuesToRequest, adaptProductFilterValuesToSkuFilterInput, setFilter }) => {
    var _a, _b;
    const [skuDrawer, setSkuDrawer] = useState({ isOpen: false });
    const [attributes, setAttributes] = useState([]);
    const [reportLoadingInProcess, setReportLoadingInProcess] = useState(false);
    const [tableScroll, setTableScrollValue] = useState(0);
    const [tableBottomOffset, setTableBottomOffset] = useState(TABLE_DEFAULT_BOTTOM_OFFSET);
    const { adaptSkuListResponse } = useSkuAdapter();
    const filterForm = useRef();
    const tableRef = useRef();
    const setTableScroll = () => {
        var _a;
        const tableHead = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(".ant-table-header");
        if (tableRef.current) {
            setTableScrollValue(tableRef.current.offsetHeight - ((tableHead === null || tableHead === void 0 ? void 0 : tableHead.offsetHeight) || tableBottomOffset));
        }
        else {
            setTableScrollValue(500);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", setTableScroll);
        setTableScroll();
        handlerSearch();
        return () => {
            window.removeEventListener("resize", setTableScroll);
        };
    }, []);
    const setHeightOffset = (offset) => {
        setTableBottomOffset(offset);
        setTableScrollValue(tableRef.current ? tableRef.current.offsetHeight - offset : 500);
    };
    const handlerFilterChange = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setFilter(values);
        yield fetchSku(adaptProductFilterValuesToSkuFilterInput(values), defaultPagination === null || defaultPagination === void 0 ? void 0 : defaultPagination.itemsOnPage, defaultPagination === null || defaultPagination === void 0 ? void 0 : defaultPagination.page);
        setTableScroll();
    });
    const handleDownload = () => __awaiter(void 0, void 0, void 0, function* () {
        setReportLoadingInProcess(true);
        const filterToRequest = productFilterValuesToRequest(filter);
        const filterToRequestWithoutDisabledStatuses = Object.assign(Object.assign({}, filterToRequest), { statuses: filterToRequest === null || filterToRequest === void 0 ? void 0 : filterToRequest.statuses.filter((el) => !["Shipped", "Deleted", "Draft"].includes(el)) });
        yield getReportExel(filterToRequestWithoutDisabledStatuses, () => { });
        setReportLoadingInProcess(false);
    });
    const handlerSearch = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        yield fetchSku(adaptProductFilterValuesToSkuFilterInput(filter), (_c = pagination === null || pagination === void 0 ? void 0 : pagination.itemsOnPage) !== null && _c !== void 0 ? _c : defaultPagination.itemsOnPage, (_d = pagination === null || pagination === void 0 ? void 0 : pagination.page) !== null && _d !== void 0 ? _d : defaultPagination === null || defaultPagination === void 0 ? void 0 : defaultPagination.page);
        setTableScroll();
    });
    const handlerSetAttributes = (list) => {
        setAttributes(list);
    };
    const handlerPaginationChange = (page, itemsOnPage) => __awaiter(void 0, void 0, void 0, function* () {
        yield fetchSku(adaptProductFilterValuesToSkuFilterInput(filter), itemsOnPage !== null && itemsOnPage !== void 0 ? itemsOnPage : defaultPagination.itemsOnPage, page);
    });
    const handlerClose = () => __awaiter(void 0, void 0, void 0, function* () {
        var _e;
        (_e = filterForm.current) === null || _e === void 0 ? void 0 : _e.resetFields();
        setAttributes([]);
        setFilter({});
        setReportLoadingInProcess(false);
        yield fetchSku({}, defaultPagination.itemsOnPage, defaultPagination.page);
    });
    const pageProps = {
        toolbarBottom: React.createElement(ToolbarFooter, { pagination: pagination, disabled: isLoading || !(items === null || items === void 0 ? void 0 : items.length), onChange: handlerPaginationChange }),
        toolbarTop: null,
        toolbarRight: (React.createElement(ToolbarRight, { filterForm: filterForm, reportLoadingInProcess: reportLoadingInProcess, canDownloadReport: false, attributes: attributes, handleDownload: handleDownload, handlerClose: handlerClose, handlerSetAttributes: handlerSetAttributes, handlerFilterChange: handlerFilterChange })),
    };
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement("div", { style: { margin: "-15px", height: "calc(100% + 30px)", overflow: "hidden" }, ref: tableRef },
            React.createElement(FindTable, { isLoading: isLoading, setHeightOffset: setHeightOffset, attributes: attributes, itemList: items === null || items === void 0 ? void 0 : items.map((el) => adaptSkuListResponse(el)), tableScroll: tableScroll, setSkuDrawer: setSkuDrawer }),
            " ",
            React.createElement(SkuInfoDrawerContainer, { skuId: (_b = (_a = skuDrawer.info) === null || _a === void 0 ? void 0 : _a.id.toString()) !== null && _b !== void 0 ? _b : "", isOpen: skuDrawer.isOpen, onClose: () => setSkuDrawer({ isOpen: false, info: skuDrawer.info }) }))));
};
export default NomenclaturePage;
