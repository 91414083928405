var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import { VectorTransform } from "../../../../api/graphql/common";
import { useWarehouseMapStorageCellEditMutation } from "../../../../api/graphql/queries/warehouseMap/WarehouseMapMutation";
import { divideAndRound } from "../helpers";
import { useCallback, useEffect, useRef, useState } from "react";
export const useCellEditor = (dispatch) => {
    //   const [getWarehouseMapStorageCell, { refetch }] = useWarehouseMapStorageCellLazyQuery({ initialFetchPolicy: "cache-and-network" });
    const [update] = useWarehouseMapStorageCellEditMutation({ fetchPolicy: "no-cache" });
    const { id } = useParams();
    const [queue, setQueue] = useState([]);
    const debouncedUpdatesRef = useRef({});
    const enqueueDebouncedUpdate = useCallback((nodeId, input) => {
        if (!debouncedUpdatesRef.current[nodeId]) {
            debouncedUpdatesRef.current[nodeId] = debounce((input) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    yield update({ variables: { inputStorageCellEdit: input } });
                    delete debouncedUpdatesRef.current[nodeId]; // Очистка после выполнения
                }
                catch (error) {
                    message.error("Не удалось обновить данные по ячейке");
                    delete debouncedUpdatesRef.current[nodeId]; // Очистка в случае ошибки
                }
            }), 600);
        }
        debouncedUpdatesRef.current[nodeId](input);
    }, [update]);
    useEffect(() => {
        if (queue.length === 0)
            return;
        const processQueue = () => __awaiter(void 0, void 0, void 0, function* () {
            const current = queue[0];
            try {
                yield update({ variables: { inputStorageCellEdit: current.input } });
                // Успешное выполнение запроса, удаляем его из очереди
                setQueue((prev) => prev.slice(1));
            }
            catch (error) {
                message.error("Не удалось обновить данные по ячейке");
                // Не удаляем из очереди, возможно, стоит попробовать позже или изменить подход
            }
        });
        processQueue();
    }, [queue, update]);
    const handleNodeAdded = (node) => __awaiter(void 0, void 0, void 0, function* () {
        if (id) {
            const numericId = Number(id);
            const input = {
                storageCells: [
                    {
                        id: node.id,
                        vectorObject: {
                            shape: { rect: { width: divideAndRound(node.width, 10), height: divideAndRound(node.height, 10), x: node.x, y: node.y, rx: 0, ry: 0 } },
                        },
                    },
                ],
                warehouseId: numericId,
            };
            try {
                yield update({ variables: { inputStorageCellEdit: input } });
            }
            catch (error) {
                message.error("Не удалось обновить данные по ячейке");
                try {
                    //   await refetch();
                }
                catch (error) {
                    message.error("Не удалось получить данные по ячейкам");
                }
            }
        }
    });
    const handleNodeRemoved = (node) => __awaiter(void 0, void 0, void 0, function* () {
        if (id) {
            const numericId = Number(id);
            const input = {
                storageCells: [
                    {
                        id: node.id,
                        vectorObject: null,
                    },
                ],
                warehouseId: numericId,
            };
            try {
                yield update({ variables: { inputStorageCellEdit: input } });
            }
            catch (error) {
                message.error("Не удалось обновить данные по ячейке");
                try {
                    //   await refetch();
                }
                catch (error) {
                    message.error("Не удалось получить данные по ячейкам");
                }
            }
        }
    });
    const handleNodeRotated = (node, rotation) => __awaiter(void 0, void 0, void 0, function* () {
        if (id) {
            const numericId = Number(id);
            const input = {
                storageCells: [
                    {
                        id: node.id,
                        vectorObject: {
                            shape: {
                                rect: { width: divideAndRound(node.width, 10), height: divideAndRound(node.height, 10), x: node.x, y: node.y, rx: 0, ry: 0 },
                            },
                            transform: [{ key: VectorTransform.Rotate, value: `${rotation}` }],
                        },
                    },
                ],
                warehouseId: numericId,
            };
            enqueueDebouncedUpdate(node.id, input);
        }
    });
    const handleNodeMoved = (node, rotation) => __awaiter(void 0, void 0, void 0, function* () {
        if (id) {
            const numericId = Number(id);
            const input = {
                storageCells: [
                    {
                        id: node.id,
                        vectorObject: {
                            shape: {
                                rect: { width: divideAndRound(node.width, 10), height: divideAndRound(node.height, 10), x: node.x, y: node.y, rx: 0, ry: 0 },
                            },
                            transform: [{ key: VectorTransform.Rotate, value: `${rotation}` }],
                        },
                    },
                ],
                warehouseId: numericId,
            };
            enqueueDebouncedUpdate(node.id, input);
        }
    });
    return { handleNodeAdded, handleNodeRemoved, handleNodeRotated, handleNodeMoved };
};
