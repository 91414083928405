import React from "react";
import { Alert, Card, Col, Collapse, Row, Skeleton, Table } from "antd";
import { CaretRightOutlined, InfoCircleOutlined } from "@ant-design/icons"; // Импортируем иконку
import { useProductAvailabilityQuery } from "../../../../../api/graphql/queries/product/ProductQuery";
const { Panel } = Collapse;
export const ShippingDetailNominclatureProductInfo = ({ productId, warehouseId }) => {
    const { data, error, loading } = useProductAvailabilityQuery({
        variables: { productId, warehouseId },
    });
    if (error)
        return React.createElement(Alert, { message: "\u041E\u0448\u0438\u0431\u043A\u0430 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0438 \u0434\u0430\u043D\u043D\u044B\u0445", type: "error", showIcon: true });
    const columns = [
        {
            title: "Ячейка",
            dataIndex: "storageCellTitle",
            key: "storageCellTitle",
            width: 150,
        },
        {
            title: "Адрес ячейки",
            dataIndex: "storageCellAddress",
            key: "storageCellAddress",
        },
        {
            title: "Кол-во SKU",
            dataIndex: "skuCount",
            key: "skuCount",
            align: "right",
            width: 150,
        },
        {
            title: "Кол-во по учету",
            dataIndex: "skuQantity",
            key: "skuQantity",
            align: "right",
            width: 150,
        },
    ];
    const dataSource = data === null || data === void 0 ? void 0 : data.productAvailability.map((item) => ({
        key: item.storageCellId,
        storageCellTitle: item.storageCellTitle,
        storageCellAddress: item.storageCellAddress,
        skuCount: item.skuCount,
        skuQantity: item.skuQantity,
    }));
    return (React.createElement(Row, null,
        React.createElement(Col, { flex: "auto" },
            React.createElement(Alert, { message: React.createElement(Collapse, { expandIcon: (i) => (React.createElement("div", { style: { display: "flex", gap: "8px" } },
                        React.createElement(InfoCircleOutlined, { style: { fontSize: "22px", color: "#1890ff" } }),
                        " ",
                        React.createElement(CaretRightOutlined, { rotate: i.isActive ? 90 : 0 }))), defaultActiveKey: [], ghost: true, size: "small" },
                    React.createElement(Panel, { header: "\u041F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u044E \u043C\u043E\u0436\u043D\u043E \u043D\u0430\u0439\u0442\u0438 \u0432 \u044F\u0447\u0435\u0439\u043A\u0430\u0445:", key: "1", className: "info-panel" }, loading ? (React.createElement(Skeleton, { active: true })) : (React.createElement(Card, { bordered: true, style: { border: "1px solid #91caff" } },
                        React.createElement(Table, { size: "small", dataSource: dataSource, columns: columns, pagination: false }))))), type: "info" }))));
};
