var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { debounce, throttle } from "lodash";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
export const useGraphqlSelect = ({ dependencyKeys, totalItems, getDataQuery, fetchMoreDataQuery }) => {
    const [searchString, setSearchString] = useState("");
    const [page, setPage] = useState(1);
    const [mergedData, setMergedData] = useState([]);
    const [search, setSearch] = useState("");
    const normalizeData = (mergedData === null || mergedData === void 0 ? void 0 : mergedData.map(({ id, title }) => ({ value: id, label: title }))) || [];
    const onSearchChange = (text) => {
        setSearch(text);
    };
    const debouncedSetSearchString = useCallback(debounce((text) => {
        setSearchString(text);
    }, 800), []);
    useEffect(() => {
        debouncedSetSearchString(search);
    }, [search, debouncedSetSearchString]);
    const hasMore = useMemo(() => (totalItems || 0) > (mergedData === null || mergedData === void 0 ? void 0 : mergedData.length), [totalItems, mergedData === null || mergedData === void 0 ? void 0 : mergedData.length]);
    const getData = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        getDataQuery(setMergedData, searchString);
    }), [JSON.stringify(dependencyKeys), searchString]);
    useEffect(() => {
        if (dependencyKeys && Array.isArray(dependencyKeys) && dependencyKeys.every((i) => typeof i === "number")) {
            getData();
        }
    }, [JSON.stringify(dependencyKeys), searchString]);
    const throttledFetchMore = useCallback(throttle(() => {
        if (hasMore) {
            const nextPage = page + 1;
            setPage((prev) => prev + 1);
            return fetchMoreDataQuery(nextPage, setMergedData, searchString);
        }
    }, 800), [page, searchString, JSON.stringify(dependencyKeys), hasMore]);
    useLayoutEffect(() => {
        setPage(1);
    }, [searchString, JSON.stringify(dependencyKeys)]);
    return {
        hasMore,
        search,
        normalizeData,
        onSearchChange,
        throttledFetchMore,
    };
};
