import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import { useTaskCorrection } from "../../../../hooks/api/taskCorrectionApi/useTaskCorrection";
import ProductInfoDrawerContainer from "../../../containers/ProductInfoDrawerContainer";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import CorrectionEditTaskHeaderContainer from "../../../containers/task/correction/CorrectionEditTaskHeaderContainer";
import CorrectionTaskFormNomenclatureContainer from "../../../containers/task/correction/CorrectionTaskFormNomenclatureContainer";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
export const CorrectionContext = React.createContext(null);
const CorrectionProvider = CorrectionContext.Provider;
const CorrectionEditPage = () => {
    const params = useParams();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const { task } = useTaskCorrection();
    const isLocked = useMemo(() => taskHelpers.hasExternalId(task === null || task === void 0 ? void 0 : task.data), [task === null || task === void 0 ? void 0 : task.data]);
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: React.createElement(CorrectionEditTaskHeaderContainer, { task: task.data, isLoading: task.loading, id: taskId }),
        toolbarRight: null,
    };
    return (React.createElement(CorrectionProvider, { value: { isLocked } },
        React.createElement(Page, Object.assign({}, pageProps),
            React.createElement(CorrectionTaskFormNomenclatureContainer, { refetch: task.refetch, isLoading: task.loading, task: task.data, taskId: taskId }),
            React.createElement(ProductInfoDrawerContainer, null),
            React.createElement(SkuInfoDrawerContainer, null))));
};
export default CorrectionEditPage;
