var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Dropdown, Empty, Tree } from "antd";
import React, { useState, useMemo } from "react";
import Centered from "../ui/Centered";
const { DirectoryTree } = Tree;
const hideSwitchChildernIcon = (data) => {
    return data.map((item) => { var _a, _b; return (Object.assign(Object.assign({}, item), { children: !((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.length) ? undefined : (_b = item === null || item === void 0 ? void 0 : item.children) === null || _b === void 0 ? void 0 : _b.map((i) => { var _a; return (Object.assign(Object.assign({}, i), { switcherIcon: ((_a = i === null || i === void 0 ? void 0 : i.children) === null || _a === void 0 ? void 0 : _a.length) ? undefined : React.createElement(React.Fragment, null) })); }) })); });
};
const TreeView = (props) => {
    const { data, emptyText, draggable = false, showIcon = true, style, contexMenu, defaultExpandedKeys, onLoadFolder, onSelect } = props;
    const [currentNode, setCurrentNode] = useState();
    const [expandedKeys, setExpandedKeys] = useState(defaultExpandedKeys !== null && defaultExpandedKeys !== void 0 ? defaultExpandedKeys : []);
    const [showMenu, setShowMenu] = useState(false);
    const handlerLoadData = (node) => __awaiter(void 0, void 0, void 0, function* () {
        const { id } = node;
        if (id && onLoadFolder) {
            return onLoadFolder(id);
        }
        return undefined;
    });
    const handlerContextClick = (info) => {
        const node = info.node;
        setCurrentNode(node);
        setShowMenu(true);
    };
    const handlerWrapClick = (e) => {
        if (!e.target.closest(".ant-tree")) {
            setCurrentNode(null);
            setShowMenu(true);
        }
    };
    const handlerContextClose = (visible) => {
        if (!visible) {
            setShowMenu(false);
            setCurrentNode(null);
        }
    };
    const handlerSelect = (keys, info) => {
        const node = info.node;
        onSelect(node);
    };
    const handlerExpand = (keys, info) => {
        setExpandedKeys((prevState) => {
            if (info.expanded) {
                return Array.from(new Set([...prevState, ...keys]));
            }
            return prevState.filter((prevKey) => keys.reduce((acc, key) => acc || key === prevKey, false));
        });
    };
    const buildContextMenu = useMemo(() => {
        if (contexMenu && showMenu) {
            return contexMenu(currentNode);
        }
        return {
            items: [],
        };
    }, [contexMenu, showMenu, currentNode]);
    if (data.length) {
        return props.createContextMenuItems ? (React.createElement(Dropdown, { menu: { items: props.createContextMenuItems(currentNode) }, trigger: ["contextMenu"], onOpenChange: handlerContextClose },
            React.createElement("div", { style: style, onContextMenu: handlerWrapClick },
                React.createElement(DirectoryTree, { showIcon: showIcon, draggable: draggable, treeData: hideSwitchChildernIcon(data), defaultExpandAll: true, expandedKeys: expandedKeys, onSelect: handlerSelect, onRightClick: handlerContextClick, onExpand: handlerExpand, loadData: handlerLoadData })))) : (React.createElement(Dropdown, { menu: buildContextMenu, trigger: ["contextMenu"], onOpenChange: handlerContextClose },
            React.createElement("div", { style: style, onContextMenu: handlerWrapClick },
                React.createElement(DirectoryTree, { showIcon: showIcon, draggable: draggable, treeData: data, defaultExpandAll: true, expandedKeys: expandedKeys, onSelect: handlerSelect, onRightClick: handlerContextClick, onExpand: handlerExpand, loadData: handlerLoadData }))));
    }
    return (React.createElement(Centered, null,
        React.createElement(Empty, { description: emptyText !== null && emptyText !== void 0 ? emptyText : "Список пуст" })));
};
export default TreeView;
