var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Select, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
const { Option } = Select;
const SearchSelect = (_a) => {
    var { preselectOption, mode, data, defaultValue, isLoading, hasMore, fetchMoreData, onChange, onSearchChange } = _a, otherProps = __rest(_a, ["preselectOption", "mode", "data", "defaultValue", "isLoading", "hasMore", "fetchMoreData", "onChange", "onSearchChange"]);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const handleScroll = (event) => {
        const target = event.target;
        if (target.scrollTop + target.offsetHeight >= target.scrollHeight && hasMore) {
            fetchMoreData();
        }
    };
    useEffect(() => {
        if (mode === "multiple") {
            setSelectedOptions([...((preselectOption === null || preselectOption === void 0 ? void 0 : preselectOption.map((i) => i === null || i === void 0 ? void 0 : i.value)) || []), ...(selectedOptions || [])]);
        }
        else {
            setSelectedOptions([preselectOption === null || preselectOption === void 0 ? void 0 : preselectOption.value]);
        }
    }, []);
    const handleChange = (value, option) => {
        setSelectedOptions(value);
        onChange(value, option);
    };
    const combinedData = useMemo(() => {
        if (mode === "multiple") {
            return preselectOption && !data.some((item) => preselectOption.some((i) => (i === null || i === void 0 ? void 0 : i.value) === item.value)) ? [...preselectOption, ...data] : data;
        }
        else {
            return preselectOption && !data.some((item) => item.value === preselectOption.value) ? [preselectOption, ...data] : data;
        }
    }, [preselectOption, data]);
    return (React.createElement(Select, Object.assign({}, otherProps, { filterOption: false, mode: mode, onSearch: (text) => {
            onSearchChange(text);
            return text;
        }, onBlur: () => {
            onSearchChange(undefined);
        }, value: selectedOptions || [], onChange: handleChange, onPopupScroll: handleScroll, notFoundContent: isLoading ? React.createElement(Spin, { size: "small" }) : null }), combinedData.map((item) => (React.createElement(Option, { key: item.value, value: item.value }, item.label)))));
};
export default SearchSelect;
