import { Button, Form, Spin, message } from "antd";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHandbookPackingCreateMutation, useHandbookPackingEditMutation } from "../../../../../api/graphql/queries/handbookPacking/HandbookPackingMutations";
import Centered from "../../../../../components/ui/Centered";
import { links } from "../../../../../constants/links";
import PackingForm from "../shared/PackingForm/PackingForm";
import { SUCCESS_MESSAGE } from "../../constants";
const PackingAddFormContainer = ({ packing }) => {
    const [form] = Form.useForm();
    const { packingSetId, packingId } = useParams();
    const [createPacking, { loading }] = useHandbookPackingCreateMutation();
    const [editPacking, { loading: isEditInProgress }] = useHandbookPackingEditMutation();
    const navigate = useNavigate();
    const handlerButtonClick = () => {
        form.submit();
    };
    const handlerSubmit = (values) => {
        if (!!packing) {
            editPacking({
                variables: {
                    input: {
                        id: packing === null || packing === void 0 ? void 0 : packing.id,
                        depth: values.depth,
                        handbookPackingSetId: packing === null || packing === void 0 ? void 0 : packing.handbookPackingSetId,
                        handbookUnitId: values.handbookUnitId.value,
                        height: values.height,
                        quantity: values.quantity,
                        title: values.title,
                        weight: values.weight,
                        width: values.width,
                    },
                },
            }).then(() => {
                message.success(SUCCESS_MESSAGE.packing_update);
                navigate(links.handbookPackingLink.url());
            });
        }
        else {
            createPacking({
                variables: {
                    input: {
                        depth: values.depth,
                        handbookPackingSetId: packingSetId ? +packingSetId : 0,
                        handbookUnitId: values.handbookUnitId.value,
                        height: values.height,
                        quantity: values.quantity,
                        title: values.title,
                        weight: values.weight,
                        width: values.width,
                    },
                },
            }).then(() => {
                message.success(SUCCESS_MESSAGE.packing_create);
                navigate(links.handbookPackingLink.url());
            });
        }
    };
    const initialValues = useMemo(() => {
        var _a, _b;
        return !!packing
            ? {
                depth: packing === null || packing === void 0 ? void 0 : packing.depth,
                weight: packing === null || packing === void 0 ? void 0 : packing.weight,
                title: packing === null || packing === void 0 ? void 0 : packing.title,
                height: packing === null || packing === void 0 ? void 0 : packing.height,
                quantity: packing === null || packing === void 0 ? void 0 : packing.quantity,
                width: packing === null || packing === void 0 ? void 0 : packing.width,
                handbookUnitId: { label: (_a = packing === null || packing === void 0 ? void 0 : packing.handbookUnit) === null || _a === void 0 ? void 0 : _a.title, value: (_b = packing === null || packing === void 0 ? void 0 : packing.handbookUnit) === null || _b === void 0 ? void 0 : _b.id },
            }
            : undefined;
    }, [packing]);
    if (packingSetId || packingId) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PackingForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues }),
            React.createElement(Button, { disabled: loading || isEditInProgress, icon: loading || isEditInProgress ? React.createElement(Spin, { size: "small", spinning: true }) : React.createElement(React.Fragment, null), type: "primary", onClick: handlerButtonClick }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
    }
    return (React.createElement(Centered, null,
        React.createElement(Spin, { spinning: true })));
};
export default PackingAddFormContainer;
