import React, { useMemo } from "react";
import { Empty, Table, Typography } from "antd";
import { uniqueId } from "lodash";
import { createColumns } from "./Columns";
import Title from "antd/lib/typography/Title";
const TableContainer = ({ skuGorup }) => {
    const columns = useMemo(() => createColumns(), []);
    const skuGorupWithId = skuGorup.map((el) => (Object.assign(Object.assign({}, el), { id: uniqueId() })));
    if (skuGorupWithId.length === 0) {
        return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: (React.createElement(React.Fragment, null,
                React.createElement(Title, { level: 5 }, "\u0417\u0430\u0434\u0430\u0447\u0430 \u043D\u0435 \u043D\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0430."),
                React.createElement(Typography.Text, null, "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440\u0443."))) }));
    }
    return React.createElement(Table, { rowKey: "id", style: { whiteSpace: "nowrap" }, columns: columns, dataSource: skuGorupWithId, bordered: true, pagination: false, size: "small", scroll: { x: true } });
};
export default TableContainer;
