import React from 'react';
import classNames from 'classnames';
const ToolbarsSwitcher = (props) => {
    const { children, show } = props;
    if (show) {
        return (React.createElement("div", { className: "app-fragment-buttons" }, children));
    }
    return null;
};
const Item = (props) => {
    const { onClick, icon, active, show, } = props;
    const classList = classNames({
        mdi: true,
        [icon]: true,
        show: active,
    });
    if (show) {
        return (React.createElement("button", { type: "button", className: classList, onClick: onClick, "aria-label": "toggleToolbar" }));
    }
    return null;
};
ToolbarsSwitcher.Item = Item;
export default ToolbarsSwitcher;
