import { Shape as ShapeClass } from "@antv/x6/es";
import { useEffect } from "react";
import { VectorTransform } from "../../../api/graphql/common";
import { getBgColor } from "../../SectionCell/helpers";
import { SCALE, defaultTools } from "./state";
export const useRenderCells = ({ warehouseCell, graph, cell, dispatch }) => {
    useEffect(() => {
        if (!graph || !(warehouseCell === null || warehouseCell === void 0 ? void 0 : warehouseCell.length))
            return;
        // Рендерим новые ячейки на основе warehouseCell
        warehouseCell.forEach((cellDto) => {
            var _a, _b, _c;
            const { vectorObject } = cellDto;
            const cellInfo = cell.get(cellDto.id);
            const bgColor = getBgColor((cellInfo === null || cellInfo === void 0 ? void 0 : cellInfo.loadPercent) || -1, "#FFF");
            const angle = (_c = (_b = (_a = cellDto === null || cellDto === void 0 ? void 0 : cellDto.vectorObject) === null || _a === void 0 ? void 0 : _a.transform) === null || _b === void 0 ? void 0 : _b.find((item) => item.key === VectorTransform.Rotate)) === null || _c === void 0 ? void 0 : _c.value;
            if (vectorObject === null || vectorObject === void 0 ? void 0 : vectorObject.shape) {
                // Создаем и добавляем ячейку на граф, используя информацию из vectorObject
                let shape;
                switch (vectorObject.shape.__typename) {
                    case "Rect":
                        shape = new ShapeClass.Rect({
                            x: vectorObject.shape.x,
                            y: vectorObject.shape.y,
                            width: vectorObject.shape.width * SCALE,
                            height: vectorObject.shape.height * SCALE,
                            data: { id: cellDto.id, init: true },
                            zIndex: 10,
                            angle: angle ? Number(angle) : 0,
                            tools: [...defaultTools],
                            attrs: {
                                body: {
                                    fill: bgColor,
                                    stroke: "none",
                                    rx: vectorObject.shape.rx,
                                    ry: vectorObject.shape.ry,
                                },
                                label: {
                                    text: cellDto.title,
                                    fill: "#000",
                                },
                            },
                        });
                        break;
                    case "Circle":
                        shape = new ShapeClass.Circle({
                            x: vectorObject.shape.cx - vectorObject.shape.r,
                            y: vectorObject.shape.cy - vectorObject.shape.r,
                            width: vectorObject.shape.r * 2,
                            height: vectorObject.shape.r * 2,
                            zIndex: 10,
                            attrs: {
                                body: {
                                    // fill: bgColor,
                                    stroke: "#CCC",
                                    r: vectorObject.shape.r,
                                },
                                label: {
                                    text: cellDto.title,
                                    fill: "#333",
                                },
                            },
                        });
                        break;
                    case "Ellipse":
                        shape = new ShapeClass.Ellipse({
                            x: vectorObject.shape.cx - vectorObject.shape.rx,
                            y: vectorObject.shape.cy - vectorObject.shape.ry,
                            width: vectorObject.shape.rx * 2,
                            height: vectorObject.shape.ry * 2,
                            zIndex: 10,
                            attrs: {
                                body: {
                                    // fill: bgColor,
                                    stroke: "#CCC",
                                    rx: vectorObject.shape.rx,
                                    ry: vectorObject.shape.ry,
                                },
                                label: {
                                    text: cellDto.title,
                                    fill: "#333",
                                },
                            },
                        });
                        break;
                    case "Line":
                        shape = new ShapeClass.Edge({
                            source: { x: vectorObject.shape.x1, y: vectorObject.shape.y1 },
                            target: { x: vectorObject.shape.x2, y: vectorObject.shape.y2 },
                            zIndex: 10,
                            attrs: {
                                line: {
                                    stroke: "#CCC",
                                    strokeWidth: 1,
                                    targetMarker: null, // Убрать стрелку на конце, если не нужна
                                },
                            },
                        });
                        break;
                    case "Polygon":
                    case "Polyline":
                        // Для Polygon и Polyline используем "M L Z" команды в d атрибуте
                        const d = vectorObject.shape.points
                            .split(" ")
                            .map((point, index) => {
                            const [x, y] = point.split(",");
                            return `${index === 0 ? "M" : "L"}${x},${y}`;
                        })
                            .join(" ") + (vectorObject.shape.__typename === "Polygon" ? " Z" : "");
                        shape = new ShapeClass.Path({
                            zIndex: 10,
                            attrs: {
                                body: {
                                    fill: vectorObject.shape.__typename === "Polygon" ? "#EEE" : "none", // Заливаем Polygon, но не Polyline
                                    stroke: "#CCC",
                                    strokeWidth: 1,
                                    d,
                                },
                                label: {
                                    text: cellDto.title,
                                    fill: "#333",
                                },
                            },
                        });
                        break;
                    case "Path":
                        shape = new ShapeClass.Path({
                            zIndex: 10,
                            attrs: {
                                body: {
                                    fill: "none", // Обычно для Path не задаем заливку
                                    stroke: "#CCC",
                                    strokeWidth: 1,
                                    d: vectorObject.shape.d, // Используем атрибут d напрямую
                                },
                                label: {
                                    text: cellDto.title,
                                    fill: "#333",
                                },
                            },
                        });
                        break;
                    default:
                        console.log(`Unsupported shape type: ${vectorObject.__typename}`);
                }
                if (shape) {
                    dispatch({ type: "ADD_ACTIVE_NODE_ID", payload: cellDto.id });
                    graph.addCell(shape);
                }
            }
        });
    }, [warehouseCell, graph, cell]);
};
