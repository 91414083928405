import React, { useEffect, useMemo, useState, } from 'react';
import { Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Typography, } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MapView from '../MapView';
import { addressParse } from '../../helpers/function';
import TimeZoneSelect from '../TimeZoneSelect';
import MDIcon from '../ui/MDIcon';
const { confirm } = Modal;
const gutter = [24, 24];
const HandbookWarehouse = (props) => {
    const { initialValues, deletable, onSubmit, onDelete, } = props;
    const [touched, setTouched] = useState(false);
    const [coords, setCoords] = useState({ lat: 0, lng: 0 });
    const [title, setTitle] = useState('');
    const [form] = Form.useForm();
    const [map, setMap] = useState();
    const setMapInfo = () => {
        const lat = form.getFieldValue('geoLatitude') ? parseFloat(form.getFieldValue('geoLatitude').replace(',', '.')) : null;
        const lng = form.getFieldValue('geoLongitude') ? parseFloat(form.getFieldValue('geoLongitude').replace(',', '.')) : null;
        if (lat === null || lng === null) {
            setCoords(null);
        }
        else {
            setCoords({ lat, lng });
        }
        setTitle(form.getFieldValue('storageTitle'));
    };
    const handlerReset = () => {
        setTouched(false);
        form.resetFields();
        setMapInfo();
    };
    useEffect(() => {
        handlerReset();
    }, [initialValues]);
    const handlerSubmit = (values) => {
        onSubmit(values);
    };
    const handlerChange = () => {
        if (!touched)
            setTouched(true);
        setMapInfo();
    };
    const handlerChangeCoords = (values) => {
        setCoords(values);
        form.setFieldsValue({
            geoLatitude: values.lat.toString(),
            geoLongitude: values.lng.toString(),
        });
        handlerChange();
    };
    const handlerSearch = (address) => {
        const addr = addressParse(address);
        form.setFieldsValue({
            addressIndex: addr.index,
            addressCountry: addr.country,
            addressRegion: addr.region,
            addressCity: addr.city,
            addressStreet: addr.street,
            addressBuilding: addr.building,
        });
    };
    const handlerLocate = () => {
        map === null || map === void 0 ? void 0 : map.locate();
    };
    const handlerDelete = () => {
        confirm({
            title: 'Отправить склад в архив?',
            icon: React.createElement(ExclamationCircleOutlined, null),
            okText: 'Да',
            cancelText: 'Отмена',
            onOk() {
                if (onDelete)
                    onDelete();
            },
        });
    };
    const mapNode = useMemo(() => (React.createElement(MapView, { title: title, map: setMap, coords: coords, onClick: handlerChangeCoords, onSearch: handlerSearch, zoom: 15 })), [coords, title]);
    return (React.createElement(Card, null,
        React.createElement(Form, { form: form, layout: "vertical", onFinish: handlerSubmit, onFieldsChange: handlerChange, initialValues: initialValues },
            React.createElement(Row, { gutter: gutter },
                React.createElement(Col, { xl: 8, lg: 12, span: 24 },
                    React.createElement(Typography.Title, { level: 3 }, "\u041E\u0441\u043D\u043E\u0432\u043D\u043E\u0435"),
                    React.createElement(Divider, null),
                    React.createElement(Form.Item, { name: "storageTitle", label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0441\u043A\u043B\u0430\u0434\u0430", rules: [{ required: true, message: 'Поле не заполнено' }] },
                        React.createElement(Input, null)),
                    React.createElement(Form.Item, { rules: [{ required: true, message: 'Поле не заполнено' }], name: "storageType", label: "\u0422\u0438\u043F \u0441\u043A\u043B\u0430\u0434\u0430" },
                        React.createElement(Select, null,
                            React.createElement(Select.Option, { value: 1 }, "\u0421\u043A\u043B\u0430\u0434 \u0445\u0440\u0430\u043D\u0435\u043D\u0438\u044F"),
                            React.createElement(Select.Option, { value: 2 }, "\u0421\u043A\u043B\u0430\u0434 \u043C\u0430\u0433\u0430\u0437\u0438\u043D"),
                            React.createElement(Select.Option, { value: 3 }, "\u041C\u0435\u0441\u0442\u043E\u0440\u043E\u0436\u0434\u0435\u043D\u0438\u0435"))),
                    React.createElement(Form.Item, { name: "timeZoneId", label: "\u0427\u0430\u0441\u043E\u0432\u043E\u0439 \u043F\u043E\u044F\u0441", rules: [{ required: true, message: 'Поле не заполнено' }] },
                        React.createElement(TimeZoneSelect, null))),
                React.createElement(Col, { xl: 8, lg: 12, span: 24 },
                    React.createElement(Typography.Title, { level: 3 }, "\u0410\u0434\u0440\u0435\u0441"),
                    React.createElement(Divider, null),
                    React.createElement(Row, { gutter: [16, 0] },
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { name: "addressIndex", label: "\u0418\u043D\u0434\u0435\u043A\u0441" },
                                React.createElement(Input, null))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { name: "addressCountry", label: "\u0421\u0442\u0440\u0430\u043D\u0430" },
                                React.createElement(Input, null))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { name: "addressRegion", label: "\u041E\u0431\u043B\u0430\u0441\u0442\u044C" },
                                React.createElement(Input, null))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { name: "addressCity", label: "\u0413\u043E\u0440\u043E\u0434" },
                                React.createElement(Input, null))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { name: "addressStreet", label: "\u0423\u043B\u0438\u0446\u0430" },
                                React.createElement(Input, null))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { name: "addressBuilding", label: "\u0421\u0442\u0440\u043E\u0435\u043D\u0438\u0435" },
                                React.createElement(Input, null))))),
                React.createElement(Col, { xl: 8, lg: 12, span: 24 },
                    React.createElement(Row, { justify: "space-between" },
                        React.createElement(Typography.Title, { level: 3, style: { margin: 0 } }, "\u0413\u0435\u043E\u043F\u043E\u0437\u0438\u0446\u0438\u044F"),
                        React.createElement(Button, { icon: React.createElement(MDIcon, { icon: "map-marker", style: { marginRight: 10 } }), onClick: handlerLocate }, "\u041C\u043E\u044F \u043F\u043E\u0437\u0438\u0446\u0438\u044F")),
                    React.createElement(Divider, null),
                    React.createElement(Row, { gutter: [16, 0] },
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { name: "geoLatitude", label: "\u0428\u0438\u0440\u043E\u0442\u0430" },
                                React.createElement(Input, null))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { name: "geoLongitude", label: "\u0414\u043E\u043B\u0433\u043E\u0442\u0430" },
                                React.createElement(Input, null))),
                        React.createElement(Col, { span: 24, style: {
                                height: 300,
                                overflow: 'hidden',
                            } }, mapNode)))),
            React.createElement(Row, { justify: "end", gutter: gutter, style: { marginTop: 30 } },
                React.createElement(Col, null,
                    React.createElement(Button.Group, null,
                        React.createElement(Button, { disabled: !touched, onClick: handlerReset }, "\u041E\u0442\u043C\u0435\u043D\u0430"),
                        deletable ? (React.createElement(Button, { danger: true, onClick: handlerDelete }, "\u0412 \u0430\u0440\u0445\u0438\u0432")) : null,
                        React.createElement(Button, { type: "primary", htmlType: "submit" }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")))))));
};
export default HandbookWarehouse;
