import LoadingState from "../constants/enum/LoadingState";
export const useAdapters = () => {
    const normalizePagination = (pagination) => {
        return { page: pagination.page, itemsTotalCount: pagination.itemsTotalCount, itemsCurrentCount: pagination.itemsCurrentCount, itemsOnPage: pagination.itemsOnPage };
    };
    const normalizeHistory = (history) => {
        return history.map((el) => ({
            createdAt: el.createdAt,
            isEntryPoint: el.isEntryPoint,
            needReport: el.needReport,
            report: el.report,
            avatar: undefined,
            statusFrom: el.statusFrom,
            statusTo: Object.assign(Object.assign({}, el.statusTo), { loadingState: LoadingState.Ready }),
            user: el.user,
        }));
    };
    const adaptTaskDtoStatusToBaseTaskStatus = (status, isLoading) => (Object.assign(Object.assign({}, status), { loadingState: isLoading ? LoadingState.Loading : LoadingState.Ready, style: status.style, relations: status.relations }));
    return {
        adaptTaskDtoStatusToBaseTaskStatus,
        normalizePagination,
        normalizeHistory
    };
};
