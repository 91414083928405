import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../../components/Page';
import HandbookUnitContainer from '../../containers/HandbookUnitContainer';
const HandbookUnitItemPage = () => {
    const { id } = useParams();
    return (React.createElement(Page, null,
        React.createElement(HandbookUnitContainer, { id: id })));
};
export default HandbookUnitItemPage;
