var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Table } from "antd";
import React, { useMemo, useState } from "react";
import { createColumns } from "./Columns";
import { useWriteOffDetails } from "../../../../../../hooks/api/taskWriteOffApi/useWriteOffDetails";
import { useAppDispatch } from "../../../../../../redux/store";
import { skuActions } from "../../../../../../redux/reducers/skuReducer";
const TableContainer = ({ taskWriteOffSkuDto, taskId, refetch }) => {
    const [editedCells, setEditedCells] = useState([]);
    const { taskDetails } = useWriteOffDetails();
    const dispatch = useAppDispatch();
    const handlerSkuInfoClick = (skuId) => {
        dispatch(skuActions.open(skuId));
    };
    const onFinish = (taskWriteOffSkuId, reason) => __awaiter(void 0, void 0, void 0, function* () {
        (taskDetails === null || taskDetails === void 0 ? void 0 : taskDetails.edit) && (yield taskDetails.edit({ variables: { taskId, input: { reason, taskWriteOffSkuId } } }));
        yield refetch();
    });
    const onRemove = (taskWriteOffSkuId) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.delete({ variables: { input: { input: [taskWriteOffSkuId], taskId } } });
        yield refetch();
    });
    const columns = useMemo(() => createColumns(handlerSkuInfoClick, editedCells, setEditedCells, onFinish, onRemove, taskDetails.loading), [editedCells, taskDetails.loading]);
    return React.createElement(Table, { rowKey: 'id', style: { whiteSpace: "nowrap" }, columns: columns, dataSource: taskWriteOffSkuDto, bordered: true, pagination: false, size: "small", scroll: { x: true } });
};
export default TableContainer;
