import { createDataValue } from "../helpers";
import { RowType } from "../types";
export const useTableData = ({ id, skuId, unit, reason, quantityBefore, quantityAfter, attributes, storageCell, productType, productAfter, productBefore }) => {
    const dataBefore = [
        Object.assign({ key: `${id} ${RowType.Before}`, rowId: id, id: skuId, type: RowType.Before, storageCell: {
                value: storageCell === null || storageCell === void 0 ? void 0 : storageCell.id,
                label: storageCell === null || storageCell === void 0 ? void 0 : storageCell.title,
            }, reason: {
                value: reason,
                label: "Причина",
            }, quantity: {
                label: "Кол-во",
                value: quantityBefore,
                unit: unit,
            }, product: {
                label: productBefore === null || productBefore === void 0 ? void 0 : productBefore.titleCompiled,
                value: productBefore === null || productBefore === void 0 ? void 0 : productBefore.id,
            } }, attributes === null || attributes === void 0 ? void 0 : attributes.map((attr) => ({
            [`attr_${attr.id}`]: {
                label: attr.title,
                value: createDataValue(attr, "before"),
            },
        })).reduce((acc, attrValues) => (Object.assign(Object.assign({}, acc), attrValues)), {})),
    ];
    const dataAfter = [
        Object.assign({ key: `${id} ${RowType.After}`, rowId: id, id: skuId, type: RowType.After, storageCell: {
                value: storageCell === null || storageCell === void 0 ? void 0 : storageCell.id,
                label: storageCell === null || storageCell === void 0 ? void 0 : storageCell.title,
            }, reason: {
                value: reason,
                label: "Причина",
            }, quantity: {
                label: "Кол-во",
                value: quantityAfter,
                unit: unit,
            }, product: {
                label: productAfter === null || productAfter === void 0 ? void 0 : productAfter.titleCompiled,
                value: productAfter === null || productAfter === void 0 ? void 0 : productAfter.id,
            }, productType: {
                label: productType === null || productType === void 0 ? void 0 : productType.label,
                value: productType === null || productType === void 0 ? void 0 : productType.value,
            } }, attributes === null || attributes === void 0 ? void 0 : attributes.map((attr) => ({
            [`attr_${attr.id}`]: {
                label: attr.title,
                value: createDataValue(attr, "after"),
            },
        })).reduce((acc, attrValues) => (Object.assign(Object.assign({}, acc), attrValues)), {})),
    ];
    const getData = () => [...dataBefore, ...dataAfter];
    return getData;
};
