import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskStatusFragmentDoc = gql `
    fragment TaskStatus on TaskStatusDto {
  title
  isFinal
  style
  scope
  relations {
    id
    title
    style
    needReport
  }
}
    `;
export const TaskInventoryViewFragmentDoc = gql `
    fragment TaskInventoryView on TaskInventoryDto {
  id
  createdAt
  updatedAt
  executedAt
  warehouse {
    id
    storageTitle
  }
  user {
    userName
  }
  status {
    ...TaskStatus
  }
}
    ${TaskStatusFragmentDoc}`;
export const TaskInventoryCreateDocument = gql `
    mutation TaskInventoryCreate($input: TaskInventoryCreateInput!) {
  taskInventoryCreate(input: $input) {
    taskInventoryDto {
      ...TaskInventoryView
    }
  }
}
    ${TaskInventoryViewFragmentDoc}`;
/**
 * __useTaskInventoryCreateMutation__
 *
 * To run a mutation, you first call `useTaskInventoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskInventoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskInventoryCreateMutation, { data, loading, error }] = useTaskInventoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskInventoryCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskInventoryCreateDocument, options);
}
export const TaskInventoryEditDocument = gql `
    mutation TaskInventoryEdit($taskId: Long!, $input: TaskInventoryEditInput!) {
  taskInventoryEdit(input: $input, taskId: $taskId) {
    taskInventoryDto {
      ...TaskInventoryView
    }
  }
}
    ${TaskInventoryViewFragmentDoc}`;
/**
 * __useTaskInventoryEditMutation__
 *
 * To run a mutation, you first call `useTaskInventoryEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskInventoryEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskInventoryEditMutation, { data, loading, error }] = useTaskInventoryEditMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskInventoryEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskInventoryEditDocument, options);
}
export const TaskInventoryDetailsCreateDocument = gql `
    mutation TaskInventoryDetailsCreate($input: TaskInventoryAddDetailsInput!) {
  taskInventoryAddDetails(input: $input) {
    taskInventoryDto {
      ...TaskInventoryView
    }
  }
}
    ${TaskInventoryViewFragmentDoc}`;
/**
 * __useTaskInventoryDetailsCreateMutation__
 *
 * To run a mutation, you first call `useTaskInventoryDetailsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskInventoryDetailsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskInventoryDetailsCreateMutation, { data, loading, error }] = useTaskInventoryDetailsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskInventoryDetailsCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskInventoryDetailsCreateDocument, options);
}
export const TaskInventoryDetailsDeleteDocument = gql `
    mutation TaskInventoryDetailsDelete($input: TaskInventoryDeleteDetailsInput!) {
  taskInventoryDeleteDetails(input: $input) {
    taskInventoryDto {
      ...TaskInventoryView
    }
  }
}
    ${TaskInventoryViewFragmentDoc}`;
/**
 * __useTaskInventoryDetailsDeleteMutation__
 *
 * To run a mutation, you first call `useTaskInventoryDetailsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskInventoryDetailsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskInventoryDetailsDeleteMutation, { data, loading, error }] = useTaskInventoryDetailsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskInventoryDetailsDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskInventoryDetailsDeleteDocument, options);
}
export const TaskInventoryChangeStatusDocument = gql `
    mutation TaskInventoryChangeStatus($input: TaskInventoryChangeStatusInput!) {
  taskInventoryChangeStatus(input: $input) {
    taskStatusDto {
      ...TaskStatus
    }
  }
}
    ${TaskStatusFragmentDoc}`;
/**
 * __useTaskInventoryChangeStatusMutation__
 *
 * To run a mutation, you first call `useTaskInventoryChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskInventoryChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskInventoryChangeStatusMutation, { data, loading, error }] = useTaskInventoryChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskInventoryChangeStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskInventoryChangeStatusDocument, options);
}
export const TaskInventotyApplyMovementDocument = gql `
    mutation TaskInventotyApplyMovement($taskId: Long!, $inputApplyMovement: TaskInventoryApplyMovementInput!) {
  taskInventoryApplyMovement(taskId: $taskId, request: $inputApplyMovement) {
    taskInventoryDto {
      ...TaskInventoryView
    }
  }
}
    ${TaskInventoryViewFragmentDoc}`;
/**
 * __useTaskInventotyApplyMovementMutation__
 *
 * To run a mutation, you first call `useTaskInventotyApplyMovementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskInventotyApplyMovementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskInventotyApplyMovementMutation, { data, loading, error }] = useTaskInventotyApplyMovementMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      inputApplyMovement: // value for 'inputApplyMovement'
 *   },
 * });
 */
export function useTaskInventotyApplyMovementMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskInventotyApplyMovementDocument, options);
}
