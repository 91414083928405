import { Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import Page from "../../../components/Page";
import { productTypeGraphqlApi } from "../../../redux/api/productApi/productType";
import ProductAddFormContainer from "../../containers/ProductAddFormContainer";
const { Title } = Typography;
const { useFetchProductTypeQuery } = productTypeGraphqlApi;
const ProductAddPage = () => {
    var _a;
    const [searchParams] = useSearchParams();
    const typeParam = searchParams.get("typeId");
    const typeId = typeParam ? +typeParam : null;
    const { data: type } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    return (React.createElement(Page, null,
        React.createElement(Row, null,
            React.createElement(Col, { span: 12 },
                React.createElement(Card, null,
                    React.createElement(Title, { level: 3 }, (_a = type === null || type === void 0 ? void 0 : type.title) !== null && _a !== void 0 ? _a : "Продукция"),
                    React.createElement(Divider, null),
                    React.createElement(ProductAddFormContainer, null))))));
};
export default ProductAddPage;
