/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    sidebarOpen: false,
    toolbarLeftOpen: false,
    toolbarRightOpen: false,
    userPanelOpen: false,
};
const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        toggleUserPanel(state) {
            state.userPanelOpen = !state.userPanelOpen;
        },
        toggleSidebar(state, action) {
            var _a;
            state.sidebarOpen = (_a = action.payload) !== null && _a !== void 0 ? _a : !state.sidebarOpen;
        },
        toggleToolbarRight(state, action) {
            var _a;
            state.toolbarRightOpen = (_a = action.payload) !== null && _a !== void 0 ? _a : !state.toolbarRightOpen;
        },
        toggleToolbarLeft(state, action) {
            var _a;
            state.toolbarLeftOpen = (_a = action.payload) !== null && _a !== void 0 ? _a : !state.toolbarLeftOpen;
        },
    },
});
export const appActions = appSlice.actions;
export default appSlice.reducer;
