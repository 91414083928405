var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import { useCallback } from "react";
function fetchAndTransformFile(externalId, getFileInfo) {
    var _a, _b, _c, _d, _e, _f;
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield getFileInfo(externalId);
        return {
            uid: externalId || "",
            name: ((_a = res.data) === null || _a === void 0 ? void 0 : _a.originalName) || "",
            fileName: ((_b = res.data) === null || _b === void 0 ? void 0 : _b.originalName) || "",
            size: (_c = res.data) === null || _c === void 0 ? void 0 : _c.size,
            createdAt: (_d = res === null || res === void 0 ? void 0 : res.data) === null || _d === void 0 ? void 0 : _d.createdAt,
            creator: {
                fullName: (_f = (_e = res.data) === null || _e === void 0 ? void 0 : _e.creator) === null || _f === void 0 ? void 0 : _f.fullName,
            },
        };
    });
}
export const useFileComponent = (setUploadedFiles) => {
    const fetchFiles = useCallback((fileListData, getFileInfo) => __awaiter(void 0, void 0, void 0, function* () {
        if (!(fileListData === null || fileListData === void 0 ? void 0 : fileListData.length)) {
            return;
        }
        for (const item of fileListData) {
            try {
                const transformedFile = yield fetchAndTransformFile(item.fileId, getFileInfo);
                setUploadedFiles((prev) => {
                    const alreadyExist = prev.find((file) => file.uid === item.fileId);
                    if (alreadyExist) {
                        return prev;
                    }
                    else {
                        return [...prev, transformedFile];
                    }
                });
            }
            catch (error) {
                message.error("Ошибка получения информации по файлам");
            }
        }
    }), []);
    return { fetchFiles };
};
