import React from "react";
import { getDocumentUrl } from "../../../../api/rest/requests/documents/documents";
import TaskList from "../../../../components/task/TaskList";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useDocumentAvailable } from "../../../../hooks/useDocumentAvailable";
import { printDialogActions } from "../../../../redux/reducers/printDialogReducer";
import PartitionTaskListItem from "../../../conponents/task/partition/PartitionTaskListItem";
const PartitionTaskListContainer = ({ isLoading, pagination, taskList }) => {
    var _a;
    const { open: openPrintDialog } = printDialogActions;
    const dispatch = useAppDispatch();
    const documentsAvailable = useDocumentAvailable(ObjectClass.TaskPartition);
    const handlerPrintAction = (documentId, objectId) => dispatch(openPrintDialog(getDocumentUrl(documentId, objectId)));
    const createTaskComponent = (task) => React.createElement(PartitionTaskListItem, { key: task.id, task: task, pagination: pagination, onPrintActionClick: (documentId) => handlerPrintAction(documentId, task.id.toString()), printActions: documentsAvailable });
    return React.createElement(TaskList, { isLoading: isLoading }, (_a = taskList === null || taskList === void 0 ? void 0 : taskList.map(createTaskComponent)) !== null && _a !== void 0 ? _a : []);
};
export default PartitionTaskListContainer;
