import React from "react";
import MovementTaskListHeader from "../../../conponents/task/movement/MovementTaskListHeader";
const MovementTaskListHeaderContainer = ({ data, pagination, isLoading }) => {
    if (data || isLoading) {
        if (isLoading && !(data === null || data === void 0 ? void 0 : data.length)) {
            return React.createElement(MovementTaskListHeader, { isEmpty: true, currentCount: 0, totalCount: 0, isLoading: true });
        }
        return React.createElement(MovementTaskListHeader, { isEmpty: (data === null || data === void 0 ? void 0 : data.length) === 0 && isLoading, currentCount: pagination.itemsCurrentCount, totalCount: pagination.itemsTotalCount, isLoading: isLoading });
    }
    return null;
};
export default MovementTaskListHeaderContainer;
