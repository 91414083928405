import { Form } from 'antd';
import React from 'react';
import ProductDataType from '../../../constants/enum/ProductDataType';
import ProductTypeAttrInput from '../../ProductTypeAttrInput';
const AttrField = (props) => {
    const { attribute, namePath, required, mode, } = props;
    const { title, description, isRequired } = attribute;
    let name;
    if (namePath) {
        name = [...namePath, attribute.id.toString()];
    }
    else {
        name = attribute.id.toString();
    }
    const formItemProps = {
        name,
        tooltip: description,
        label: title,
        shouldUpdate: (prevValues, curValues) => (prevValues.area !== curValues.area || prevValues.sights !== curValues.sights),
    };
    if (attribute.dataType === ProductDataType.BOOLEAN) {
        formItemProps.valuePropName = 'checked';
    }
    else {
        formItemProps.rules = [{ required: (required !== null && required !== void 0 ? required : isRequired) }];
    }
    return (React.createElement(Form.Item, Object.assign({}, formItemProps),
        React.createElement(ProductTypeAttrInput, { attribute: attribute, style: { width: '100%' }, mode: mode })));
};
export default AttrField;
