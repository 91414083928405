import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import LoadingTaskEditFormContainer from "../../../containers/task/loading/LoadingTaskEditFormContainer";
import LoadingTaskFormNomenclatureContainer from "../../../containers/task/loading/LoadingTaskFormNomenclatureContainer";
import LoadingEditTaskHeaderContainer from "../../../containers/task/loading/LoadingEditTaskHeaderContainer";
import ProductInfoDrawerContainer from "../../../containers/ProductInfoDrawerContainer";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import { useTaskLoading } from "../../../../hooks/api/taskLoadingApi/useTaskLoading";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
export const LoadingContext = React.createContext(null);
const LoadingProvider = LoadingContext.Provider;
const LoadingEditPage = () => {
    const params = useParams();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const { task } = useTaskLoading();
    const isLocked = useMemo(() => taskHelpers.hasExternalId(task === null || task === void 0 ? void 0 : task.data), [task === null || task === void 0 ? void 0 : task.data]);
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: React.createElement(LoadingEditTaskHeaderContainer, { isLoading: task.loading, task: task.data, id: taskId }),
        toolbarRight: null,
    };
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(LoadingTaskEditFormContainer, { refetch: task.refetch, isLoading: task.loading, task: task.data, taskId: taskId, disableWarehouse: true },
            React.createElement(LoadingProvider, { value: { isLocked } },
                React.createElement(LoadingTaskFormNomenclatureContainer, { task: task.data, refetch: task.refetch, isLoading: task.loading, taskId: taskId }))),
        React.createElement(ProductInfoDrawerContainer, null),
        React.createElement(SkuInfoDrawerContainer, null)));
};
export default LoadingEditPage;
