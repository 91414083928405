import React from 'react';
import { useSearchParams } from 'react-router-dom';
import WarehouseSectionTypesTable from '../../components/WarehouseSectionTypesTable';
import { adaptStorageSectionTypeListDto } from '../pages/handbookWarehouses/WarehouseSectionsTypePage/useWarehouseSectionType';
const WarehouseSectionTypesTableContainer = (props) => {
    var _a;
    const { warehouseId, deleteStorageSectionType, isListLoading, data } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const isLoading = isListLoading || deleteStorageSectionType.isLoading;
    const handlerDelete = (storageSectionTypeId) => {
        deleteStorageSectionType.handleAction({ warehouseId, storageSectionTypeId });
    };
    const handlerEdit = (id) => {
        searchParams.set('current', id.toString());
        setSearchParams(searchParams);
    };
    return (React.createElement(WarehouseSectionTypesTable, { items: (_a = adaptStorageSectionTypeListDto(data)) !== null && _a !== void 0 ? _a : [], loading: isLoading, onDelete: handlerDelete, onEdit: handlerEdit }));
};
export default WarehouseSectionTypesTableContainer;
