import { Outlet } from 'react-router-dom';
import React from 'react';
import { links } from '../constants/links';
import MovementListPage from '../core/pages/task/movement/MovementListPage';
import MovementTaskPage from '../core/pages/task/movement/MovementTaskPage';
import MovementAddPage from '../core/pages/task/movement/MovementAddPage';
import MovementEditPage from '../core/pages/task/movement/MovementEditPage';
const paths = links.taskMovementLinks;
const taskMovementRoutes = {
    title: paths.list.title,
    breadcrumb: 'Перемещение',
    path: paths.list.path,
    element: React.createElement(Outlet, null),
    children: [
        {
            index: true,
            element: React.createElement(MovementListPage, null),
        },
        {
            title: paths.page.title,
            breadcrumb: paths.page.breadcrumb,
            path: paths.page.url(':taskId'),
            element: React.createElement(Outlet, null),
            children: [
                {
                    index: true,
                    element: React.createElement(MovementTaskPage, null),
                },
                {
                    title: paths.edit.title,
                    breadcrumb: paths.edit.breadcrumb,
                    path: paths.edit.url(':taskId'),
                    element: React.createElement(MovementEditPage, null),
                },
            ],
        },
        {
            title: paths.add.title,
            breadcrumb: paths.add.breadcrumb,
            path: paths.add.url(),
            element: React.createElement(MovementAddPage, null),
        },
    ],
};
export default taskMovementRoutes;
