var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Card, Form, Space } from "antd";
import { debounce } from "lodash";
import React, { useEffect } from "react";
import SearchSelect from "../../SearchSelect";
import AttrFieldBlock from "./AttrFieldBlock";
import { useProductFilter } from "./useProductFilter";
export const PRODUCT_ID = "productTypeId";
export const COMMON_ATTRIBUTES = "productAttributes";
export const PERSONAL_ATTRIBUTES = "skuAttributes";
const getProductTypeAttributeList = (productType) => {
    var _a;
    return (_a = productType.productTypeCategories) === null || _a === void 0 ? void 0 : _a.reduce((acc, category) => {
        var _a;
        const attrList = ((_a = category.productTypeAttributes) === null || _a === void 0 ? void 0 : _a.map((attr) => attr)) || [];
        return [...acc, ...attrList];
    }, []);
};
const convertAttributes = (attributes) => {
    const result = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in attributes) {
        const attr = attributes[key];
        if (typeof attr === "string" || typeof attr === "number") {
            result[key] = [attr.toString()];
        }
        else if (attr && attr.length > 0) {
            result[key] = attr.map((item) => item.value.toString());
        }
        else {
            result[key] = undefined;
        }
    }
    return result;
};
const ProductFilter = (props) => {
    const { onChange, formRef, onSetAttributes, attributes, hideProductType = false, productIdIsRequired, includeAdditional = true, style, includePersonal, additionalFields, productLabel } = props;
    const commonAttrs = [];
    const personalAttrs = [];
    const { productTypeRequest, getProductType, adaptProductTypeQueryResult } = useProductFilter();
    attributes
        .filter((attr) => attr.inFilter)
        .forEach((attr) => {
        if (attr.isPersonal) {
            personalAttrs.push(attr);
        }
        else {
            commonAttrs.push(attr);
        }
    });
    const onProductTypeChange = (type) => __awaiter(void 0, void 0, void 0, function* () {
        if (type) {
            const view = yield getProductType({
                variables: {
                    productTypeId: Number(type.value),
                },
            });
            const result = adaptProductTypeQueryResult(view);
            result && onSetAttributes(getProductTypeAttributeList(result));
        }
        else {
            onSetAttributes([]);
        }
    });
    useEffect(() => {
        var _a;
        (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.setFieldValue("productTypeId", props.productType);
        if (props.productType) {
            const setProductTypeIdSelect = () => __awaiter(void 0, void 0, void 0, function* () {
                var _b;
                (_b = formRef.current) === null || _b === void 0 ? void 0 : _b.setFieldValue("productTypeId", props.productType);
                handlerChange({
                    productTypeId: props.productType,
                });
            });
            setProductTypeIdSelect();
        }
    }, []);
    const handlerChange = debounce((changed) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        if (changed[PRODUCT_ID]) {
            yield onProductTypeChange(changed[PRODUCT_ID]);
        }
        yield ((_a = formRef.current) === null || _a === void 0 ? void 0 : _a.validateFields());
        const fields = (_b = formRef.current) === null || _b === void 0 ? void 0 : _b.getFieldsValue();
        const result = Object.assign(Object.assign({}, fields), { [PRODUCT_ID]: (_c = fields[PRODUCT_ID]) === null || _c === void 0 ? void 0 : _c.value, [COMMON_ATTRIBUTES]: {}, [PERSONAL_ATTRIBUTES]: {} });
        result[COMMON_ATTRIBUTES] = convertAttributes(fields[COMMON_ATTRIBUTES]);
        result[PERSONAL_ATTRIBUTES] = convertAttributes(fields[PERSONAL_ATTRIBUTES]);
        onChange(result);
    }), 500);
    const requiredProduct = productIdIsRequired !== null && productIdIsRequired !== void 0 ? productIdIsRequired : true;
    return (React.createElement(Form, { layout: "vertical", ref: formRef, onValuesChange: handlerChange, style: style },
        React.createElement(Space, { direction: "vertical", style: { width: "100%" } },
            React.createElement(Card, { title: "\u041E\u0441\u043D\u043E\u0432\u043D\u044B\u0439 \u0444\u0438\u043B\u044C\u0442\u0440", size: "small", style: { display: hideProductType ? "none" : "initial" } },
                React.createElement(Form.Item, { name: PRODUCT_ID, label: productLabel !== null && productLabel !== void 0 ? productLabel : "Тип продукции", rules: [{ required: requiredProduct }] },
                    React.createElement(SearchSelect, { defaultValue: props.productType, allowClear: !requiredProduct, dataToOption: ({ id, title }) => ({ value: id, label: title }), placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0442\u0438\u043F", request: productTypeRequest })),
                includeAdditional ? additionalFields : null),
            commonAttrs.length > 0 || personalAttrs.length > 0 ? (React.createElement(Card, { title: "\u0410\u0442\u0442\u0440\u0438\u0431\u0443\u0442\u044B", size: "small" },
                React.createElement(AttrFieldBlock, { title: includePersonal ? "Общие" : "", namePath: [COMMON_ATTRIBUTES], required: false, attributes: commonAttrs, mode: "multiple" }),
                includePersonal ? React.createElement(AttrFieldBlock, { title: "\u041F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0435", namePath: [PERSONAL_ATTRIBUTES], required: false, attributes: personalAttrs, mode: "multiple" }) : null)) : null)));
};
export default ProductFilter;
