import React, { useState } from 'react';
import { Button } from 'antd';
import MDIcon from '../../../ui/MDIcon';
const SkuItemActions = (props) => {
    const { onClick } = props;
    const [loading, setLoading] = useState(false);
    const handlerClick = () => {
        setLoading(true);
        onClick(() => setLoading(false));
    };
    return (React.createElement(Button, { icon: React.createElement(MDIcon, { icon: "magnify" }), type: "primary", size: "small", loading: loading, onClick: handlerClick }));
};
export default SkuItemActions;
