import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskShippingAddDetailsDocument = gql `
    mutation TaskShippingAddDetails($input: TaskShippingAddDetailsInput!) {
  taskShippingAddDetails(input: $input) {
    taskShippingViewDetailsResponse {
      productId
    }
  }
}
    `;
/**
 * __useTaskShippingAddDetailsMutation__
 *
 * To run a mutation, you first call `useTaskShippingAddDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskShippingAddDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskShippingAddDetailsMutation, { data, loading, error }] = useTaskShippingAddDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskShippingAddDetailsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskShippingAddDetailsDocument, options);
}
export const TaskShippingDeleteDetailsDocument = gql `
    mutation TaskShippingDeleteDetails($input: TaskShippingDeleteDetailsInput!) {
  taskShippingDeleteDetails(input: $input) {
    taskShippingViewDetailsResponse {
      productId
    }
  }
}
    `;
/**
 * __useTaskShippingDeleteDetailsMutation__
 *
 * To run a mutation, you first call `useTaskShippingDeleteDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskShippingDeleteDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskShippingDeleteDetailsMutation, { data, loading, error }] = useTaskShippingDeleteDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskShippingDeleteDetailsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskShippingDeleteDetailsDocument, options);
}
export const TaskShippingAddDocument = gql `
    mutation TaskShippingAdd($input: TaskShippingAddInput!) {
  taskShippingAdd(input: $input) {
    taskShippingViewResponse {
      id
    }
  }
}
    `;
/**
 * __useTaskShippingAddMutation__
 *
 * To run a mutation, you first call `useTaskShippingAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskShippingAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskShippingAddMutation, { data, loading, error }] = useTaskShippingAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskShippingAddMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskShippingAddDocument, options);
}
export const TaskShippingEditDocument = gql `
    mutation TaskShippingEdit($input: TaskShippingEditInput!) {
  taskShippingEdit(input: $input) {
    taskShippingViewResponse {
      id
    }
  }
}
    `;
/**
 * __useTaskShippingEditMutation__
 *
 * To run a mutation, you first call `useTaskShippingEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskShippingEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskShippingEditMutation, { data, loading, error }] = useTaskShippingEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskShippingEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskShippingEditDocument, options);
}
export const TaskShippingChangeStatusDocument = gql `
    mutation TaskShippingChangeStatus($input: TaskShippingChangeStatusInput!) {
  taskShippingChangeStatus(input: $input) {
    taskStatusResponse {
      isFinal
    }
  }
}
    `;
/**
 * __useTaskShippingChangeStatusMutation__
 *
 * To run a mutation, you first call `useTaskShippingChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskShippingChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskShippingChangeStatusMutation, { data, loading, error }] = useTaskShippingChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskShippingChangeStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskShippingChangeStatusDocument, options);
}
export const TaskShippingEditRequirementsDocument = gql `
    mutation TaskShippingEditRequirements($input: TaskShippingEditRequirementsInput!) {
  taskShippingEditRequirements(input: $input) {
    taskShippingViewDetailsResponse {
      productId
    }
  }
}
    `;
/**
 * __useTaskShippingEditRequirementsMutation__
 *
 * To run a mutation, you first call `useTaskShippingEditRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskShippingEditRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskShippingEditRequirementsMutation, { data, loading, error }] = useTaskShippingEditRequirementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskShippingEditRequirementsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskShippingEditRequirementsDocument, options);
}
