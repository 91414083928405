import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetHandbookPackingSetListDocument = gql `
    query GetHandbookPackingSetList($page: Int!, $pageSize: Int!, $search: String) {
  handbookPackingSetList(page: $page, pageSize: $pageSize, search: $search) {
    data {
      id
      title
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useGetHandbookPackingSetListQuery__
 *
 * To run a query within a React component, call `useGetHandbookPackingSetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandbookPackingSetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandbookPackingSetListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetHandbookPackingSetListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetHandbookPackingSetListDocument, options);
}
export function useGetHandbookPackingSetListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetHandbookPackingSetListDocument, options);
}
export function useGetHandbookPackingSetListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetHandbookPackingSetListDocument, options);
}
export const GetHandbookPackingListDocument = gql `
    query GetHandbookPackingList($page: Int!, $pageSize: Int!, $search: String, $handbookPackingSet: Long) {
  handbookPackingList(
    page: $page
    pageSize: $pageSize
    search: $search
    handbookPackingSet: $handbookPackingSet
  ) {
    data {
      depth
      handbookPackingSetId
      height
      id
      parentId
      quantity
      title
      weight
      width
      handbookUnit {
        id
        title
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useGetHandbookPackingListQuery__
 *
 * To run a query within a React component, call `useGetHandbookPackingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandbookPackingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandbookPackingListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *      handbookPackingSet: // value for 'handbookPackingSet'
 *   },
 * });
 */
export function useGetHandbookPackingListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetHandbookPackingListDocument, options);
}
export function useGetHandbookPackingListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetHandbookPackingListDocument, options);
}
export function useGetHandbookPackingListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetHandbookPackingListDocument, options);
}
export const GetHandbookPackingSetDocument = gql `
    query GetHandbookPackingSet($handbookPackingSetId: Long!) {
  handbookPackingSet(handbookPackingSetId: $handbookPackingSetId) {
    id
    title
    handbookUnit {
      id
      title
    }
  }
}
    `;
/**
 * __useGetHandbookPackingSetQuery__
 *
 * To run a query within a React component, call `useGetHandbookPackingSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandbookPackingSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandbookPackingSetQuery({
 *   variables: {
 *      handbookPackingSetId: // value for 'handbookPackingSetId'
 *   },
 * });
 */
export function useGetHandbookPackingSetQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetHandbookPackingSetDocument, options);
}
export function useGetHandbookPackingSetLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetHandbookPackingSetDocument, options);
}
export function useGetHandbookPackingSetSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetHandbookPackingSetDocument, options);
}
export const GetHandbookPackingDocument = gql `
    query GetHandbookPacking($handbookPackingId: Long!) {
  handbookPacking(handbookPackingId: $handbookPackingId) {
    depth
    handbookPackingSetId
    height
    id
    parentId
    quantity
    title
    weight
    width
    handbookUnit {
      id
      title
    }
  }
}
    `;
/**
 * __useGetHandbookPackingQuery__
 *
 * To run a query within a React component, call `useGetHandbookPackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandbookPackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandbookPackingQuery({
 *   variables: {
 *      handbookPackingId: // value for 'handbookPackingId'
 *   },
 * });
 */
export function useGetHandbookPackingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetHandbookPackingDocument, options);
}
export function useGetHandbookPackingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetHandbookPackingDocument, options);
}
export function useGetHandbookPackingSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetHandbookPackingDocument, options);
}
