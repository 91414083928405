import { Card, Col, Skeleton, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { useWarehouseListQuery } from "../../../../../api/graphql/queries/warehouse/WarehouseQuery";
import { WarehouseWrapper } from "./components/WarehouseWrapper";
export const ContentContainer = ({}) => {
    var _a, _b;
    const { data, error, loading } = useWarehouseListQuery({ variables: { page: 1, pageSize: 100, isExternal: false } });
    const [activeTabKey, setActiveTabKey] = useState('0');
    useEffect(() => {
        if (error) {
            message.error("Ошибка получения списка складов");
        }
    }, [error]);
    if (loading) {
        return (React.createElement(Card, { style: { width: "100%", height: "100%", margin: "0px" } },
            React.createElement(Tabs, { items: [{ label: "Загрузка...", key: "", children: React.createElement(SectionSkeleton, null), disabled: true }], defaultActiveKey: "0" })));
    }
    const warehouses = ((_b = (_a = data === null || data === void 0 ? void 0 : data.warehouseList) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.map((warehouse, index) => ({
        label: warehouse.storageTitle,
        key: String(index),
    }))) || [];
    const handleChange = (key) => {
        setActiveTabKey(key);
    };
    return (React.createElement(Card, { style: { width: "100%", minHeight: "100%", margin: "0px" } },
        React.createElement(Tabs, { defaultActiveKey: "0", onChange: handleChange, items: warehouses.map((warehouse) => (Object.assign(Object.assign({}, warehouse), { children: '' }))) }),
        warehouses.map((warehouse, index) => {
            if (activeTabKey === String(index)) {
                return React.createElement(WarehouseWrapper, { key: warehouse.key, warehouseId: data === null || data === void 0 ? void 0 : data.warehouseList.data[index].id });
            }
            return null;
        })));
};
const SectionSkeleton = () => {
    return (React.createElement(React.Fragment, null, Array.from({ length: 2 }).map((_, index) => (React.createElement(Col, { key: index, style: { display: "flex", flexDirection: "column", gap: "8px", width: "100%", marginBottom: "12px" } },
        React.createElement(Skeleton.Button, { active: true, style: { width: 400 } }),
        React.createElement(Skeleton.Button, { active: true, style: { width: "100%", height: "200px" } }))))));
};
