import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskPartitionListDocument = gql `
    query TaskPartitionList($page: Int!, $pageSize: Int!, $filter: TaskFilterRequestInput!) {
  taskPartitionList(page: $page, pageSize: $pageSize, filter: $filter) {
    data {
      id
      externalId
      createdAt
      updatedAt
      executedAt
      details {
        id
        type
        skuId
        title
        partitionCount
      }
      warehouse {
        id
        storageTitle
      }
      user {
        userName
      }
      status {
        title
        style
        scope
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useTaskPartitionListQuery__
 *
 * To run a query within a React component, call `useTaskPartitionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskPartitionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskPartitionListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskPartitionListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskPartitionListDocument, options);
}
export function useTaskPartitionListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskPartitionListDocument, options);
}
export function useTaskPartitionListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskPartitionListDocument, options);
}
export const TaskPartitionDocument = gql `
    query TaskPartition($taskId: Long!) {
  taskPartition(taskId: $taskId) {
    id
    externalId
    createdAt
    updatedAt
    originals {
      id
      skuId
      type
      title
      quantityInitial
      productId
      quantityFinal
      dimension
      attributes {
        id
        title
        dataType
        dimension
        skuAttributeValueInitial
        skuAttributeValueFinal
      }
      created {
        id
        quantityRequired
        quantityFinal
        quantityDeviation
        quantityDeviationIsValid
        toleranceType
        tolerancePercent
        skuId
        attributes {
          taskPartitionOriginalAttributeId
          id
          skuAttributeValueRequired
          skuAttributeValueFinal
          skuAttributeValueDeviation
          skuAttributeValueDeviationIsValid
        }
        storageCell {
          title
          address
          id
        }
      }
      storageCell {
        id
        title
        address
      }
    }
    executedAt
    warehouse {
      id
      storageTitle
    }
    user {
      userName
    }
    status {
      title
      style
      scope
      relations {
        id
        title
        style
        needReport
      }
    }
  }
}
    `;
/**
 * __useTaskPartitionQuery__
 *
 * To run a query within a React component, call `useTaskPartitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskPartitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskPartitionQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskPartitionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskPartitionDocument, options);
}
export function useTaskPartitionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskPartitionDocument, options);
}
export function useTaskPartitionSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskPartitionDocument, options);
}
export const GetTaskPartitionHistoryDocument = gql `
    query getTaskPartitionHistory($taskId: Long!) {
  taskPartitionHistory(taskId: $taskId) {
    createdAt
    isEntryPoint
    needReport
    report
    statusFrom {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    statusTo {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    user {
      uid
      email
      userName
      id
    }
  }
}
    `;
/**
 * __useGetTaskPartitionHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskPartitionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskPartitionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskPartitionHistoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskPartitionHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTaskPartitionHistoryDocument, options);
}
export function useGetTaskPartitionHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTaskPartitionHistoryDocument, options);
}
export function useGetTaskPartitionHistorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetTaskPartitionHistoryDocument, options);
}
