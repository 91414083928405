var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Table } from "antd";
import { uniqueId } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { createColumns } from "./Columns";
import { useAppDispatch } from "../../../../../../redux/store";
import { skuActions } from "../../../../../../redux/reducers/skuReducer";
import { useChangeWarehouseDetails } from "../../../../../../hooks/api/taskChangeWarehouseApi/useChangeWarehouseDetails";
import { ChangeWarehouseContext } from "../../../../../pages/task/changeWarehouse/ChangeWarehouseEditPage";
const TableContainer = ({ taskSkus, attrebutes, taskId, destinationWarehouseId, refetch }) => {
    const [editedCells, setEditedCells] = useState([]);
    const dispatch = useAppDispatch();
    const context = useContext(ChangeWarehouseContext);
    const { taskDetails } = useChangeWarehouseDetails();
    const handlerSkuInfoClick = (skuId) => {
        dispatch(skuActions.open(skuId));
    };
    const onFinish = (taskChangeWarehouseSkuId, storageCellToId) => __awaiter(void 0, void 0, void 0, function* () {
        (taskDetails === null || taskDetails === void 0 ? void 0 : taskDetails.edit) && (yield taskDetails.edit({ variables: { taskId, input: [{ storageCellToId, taskChangeWarehouseSkuId }] } }));
        yield refetch();
    });
    const onRemove = (taskWriteOffSkuId) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.delete({ variables: { input: { input: [taskWriteOffSkuId], taskId } } });
        yield refetch();
    });
    const normalizeAttributes = attrebutes.reduce((acc, item) => {
        acc[item.title] = { id: item === null || item === void 0 ? void 0 : item.id, unit: item === null || item === void 0 ? void 0 : item.unit };
        return acc;
    }, {});
    const columns = useMemo(() => createColumns(normalizeAttributes, handlerSkuInfoClick, editedCells, setEditedCells, onFinish, onRemove, taskDetails.loading, destinationWarehouseId, context === null || context === void 0 ? void 0 : context.isLocked), [editedCells, taskDetails.loading]);
    return React.createElement(Table, { rowKey: () => Number(uniqueId()), style: { whiteSpace: "nowrap" }, columns: columns, dataSource: taskSkus.map((el) => (Object.assign(Object.assign({}, el), normalizeAttributes))), bordered: true, pagination: false, size: "small", scroll: { x: true } });
};
export default TableContainer;
