import { Form, Input } from 'antd';
import React from 'react';
import WarehouseCellTypeSelect from '../WarehouseCellTypeSelect/WarehouseCellTypeSelect';
import WarehouseSectionSelect from '../WarehouseSectionSelect';
var FormNames;
(function (FormNames) {
    FormNames["ID"] = "id";
    FormNames["TITLE"] = "title";
    FormNames["DESCRIPTION"] = "description";
    FormNames["SECTION"] = "storageSectionId";
    FormNames["CELL_TYPE"] = "storageCellTypeId";
})(FormNames || (FormNames = {}));
const WarehouseCellForm = (props) => {
    const { warehouseId, form, onSubmit, initialValues, } = props;
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues },
        React.createElement(Form.Item, { name: FormNames.ID, hidden: true },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435", name: FormNames.TITLE },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { name: FormNames.DESCRIPTION, label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" },
            React.createElement(Input.TextArea, null)),
        React.createElement(Form.Item, { name: FormNames.SECTION, label: "\u0420\u043E\u0434\u0438\u0442\u0435\u043B\u044C\u0441\u043A\u0430\u044F \u0441\u0435\u043A\u0446\u0438\u044F" },
            React.createElement(WarehouseSectionSelect, { warehouseId: warehouseId })),
        React.createElement(Form.Item, { name: FormNames.CELL_TYPE, label: "\u0422\u0438\u043F \u044F\u0447\u0435\u0439\u043A\u0438" },
            React.createElement(WarehouseCellTypeSelect, { warehouseId: warehouseId }))));
};
export default WarehouseCellForm;
