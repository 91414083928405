var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import SearchModalTable from '../../SearchModal/SearchModalTable';
import { getColumnType } from '../../../../helpers/function';
const ProductTable = (props) => {
    const { attributes, items, tableRef, setHeightOffset } = props, otherProps = __rest(props, ["attributes", "items", "tableRef", "setHeightOffset"]);
    useEffect(() => {
        var _a;
        const tableHead = (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ant-table-header');
        if (tableHead && setHeightOffset) {
            setHeightOffset(tableHead === null || tableHead === void 0 ? void 0 : tableHead.offsetHeight);
        }
    }, []);
    const columns = [
        getColumnType('id', '#', false, { fixed: 'left' }),
        ...attributes
            .filter((attr) => !attr.isPersonal)
            .map((attr) => getColumnType(attr.id, attr.title)),
    ];
    const data = items.map((item) => (Object.assign({ id: item.id.toString(), key: item.id.toString() }, item.productAttributes)));
    return (React.createElement(SearchModalTable, Object.assign({}, otherProps, { data: data, columns: columns })));
};
export default ProductTable;
