import { Empty, Spin, Typography } from "antd";
import React from "react";
import Centered from "../../../../components/ui/Centered";
import { PreviewTable } from "../../../conponents/task/inventory/PreviewTable/PreviewTable";
const { Title, Text } = Typography;
const InventoryTaskNomenclatureContainer = (props) => {
    const { loading, taskInventoryDto, refetch } = props;
    if (loading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, null)));
    }
    if (taskInventoryDto) {
        return React.createElement(PreviewTable, { refetch: refetch, taskInventoryDto: taskInventoryDto });
    }
    return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: React.createElement(React.Fragment, null,
            React.createElement(Title, { level: 5 }, "\u0417\u0430\u0434\u0430\u0447\u0430 \u043D\u0435 \u043D\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0430."),
            React.createElement(Text, null, "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440a.")) }));
};
export default InventoryTaskNomenclatureContainer;
