import { createApi } from '@reduxjs/toolkit/query/react';
import dtoToEntity from '../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../constants/enum/Method';
import fetchAppQuery from '../../fetchAppQuery';
const URI = 'product';
export const PRODUCT_TAG = 'product';
export const PRODUCT_TYPE_TAG = 'productType';
export const PRODUCT_CATEGORY_TAG = 'productCategory';
const productApi = createApi({
    reducerPath: 'productApi',
    tagTypes: [
        PRODUCT_TAG,
        PRODUCT_TYPE_TAG,
        PRODUCT_CATEGORY_TAG,
    ],
    baseQuery: fetchAppQuery,
    endpoints: (builder) => ({
        fetchProductList: builder.query({
            query: (args) => {
                const { filter, page, pageSize, search, } = args;
                return {
                    method: Method.POST,
                    url: `${URI}/list`,
                    body: filter,
                    params: {
                        page, pageSize, search,
                    },
                };
            },
            transformResponse: (response) => ({
                pagination: dtoToEntity.paginationAdapter(response.pagination),
                list: response.data.map(dtoToEntity.productAdapter),
            }),
            providesTags: (result) => {
                if (result) {
                    return [
                        ...result.list.map(({ id }) => ({ type: PRODUCT_TAG, id })),
                        PRODUCT_TAG,
                    ];
                }
                return [PRODUCT_TAG];
            },
        }),
        fetchProduct: builder.query({
            query: (productId) => ({
                method: Method.GET,
                url: `${URI}/${productId}/view`,
            }),
            transformResponse: (response) => (dtoToEntity.productViewAdapter(response)),
            providesTags: (result, error, id) => [
                PRODUCT_TAG, { type: PRODUCT_TAG, id },
            ],
        }),
        addProduct: builder.mutation({
            query: (values) => ({
                method: Method.POST,
                url: `${URI}/add`,
                body: values,
            }),
            transformResponse: (response) => (dtoToEntity.productViewAdapter(response)),
            invalidatesTags: [PRODUCT_TAG],
        }),
        editProduct: builder.mutation({
            query: (args) => {
                const { productId, values } = args;
                return {
                    method: Method.PUT,
                    url: `${URI}/${productId}/edit`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.productViewAdapter(response)),
            invalidatesTags: (result, error, { productId }) => [
                PRODUCT_TAG, { type: PRODUCT_TAG, id: productId },
            ],
        }),
        deleteProduct: builder.mutation({
            query: (productId) => ({
                method: Method.DELETE,
                url: `${URI}/${productId}/delete`,
            }),
            transformResponse: (_, __, productId) => productId,
            invalidatesTags: (result, error, productId) => [
                PRODUCT_TAG, { type: PRODUCT_TAG, id: productId },
            ],
        }),
    }),
});
export default productApi;
