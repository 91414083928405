import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskStatusFragmentDoc = gql `
    fragment TaskStatus on TaskStatusDto {
  title
  isFinal
  style
  scope
  relations {
    id
    title
    style
    needReport
  }
}
    `;
export const StorageCellFragmentDoc = gql `
    fragment StorageCell on TaskSkuStorageCell {
  id
  title
  address
}
    `;
export const TaskInventoryListDocument = gql `
    query TaskInventoryList($page: Int!, $pageSize: Int!, $filter: TaskFilterRequestInput!) {
  taskInventoryList(page: $page, pageSize: $pageSize, filter: $filter) {
    data {
      id
      createdAt
      updatedAt
      executedAt
      productCount
      storageCellCount
      skuCount
      skuByProductCount
      skuByStorageCellCount
      warehouse {
        id
        storageTitle
      }
      user {
        id
        userName
      }
      status {
        title
        style
        scope
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useTaskInventoryListQuery__
 *
 * To run a query within a React component, call `useTaskInventoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskInventoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskInventoryListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskInventoryListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskInventoryListDocument, options);
}
export function useTaskInventoryListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskInventoryListDocument, options);
}
export function useTaskInventoryListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskInventoryListDocument, options);
}
export const TaskInventoryDocument = gql `
    query TaskInventory($taskId: Long!) {
  taskInventory(taskId: $taskId) {
    id
    externalId
    createdAt
    updatedAt
    executedAt
    taskInventorySkus {
      id
      taskInventoryProductId
      taskInventoryStorageCellId
      taskMovementId
      skuId
      storageCellExpected {
        ...StorageCell
      }
      storageCellActual {
        ...StorageCell
      }
      comment
      type
      title
      quantity
    }
    taskInventoryCommissions {
      post
      fullName
    }
    taskInventoryStorageCells {
      id
      storageCell {
        ...StorageCell
      }
    }
    taskInventoryProducts {
      id
      productId
      type
      title
    }
    warehouse {
      id
      storageTitle
    }
    user {
      userName
    }
    status {
      ...TaskStatus
    }
  }
}
    ${StorageCellFragmentDoc}
${TaskStatusFragmentDoc}`;
/**
 * __useTaskInventoryQuery__
 *
 * To run a query within a React component, call `useTaskInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskInventoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskInventoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskInventoryDocument, options);
}
export function useTaskInventoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskInventoryDocument, options);
}
export function useTaskInventorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskInventoryDocument, options);
}
export const GetTaskInventoryHistoryDocument = gql `
    query getTaskInventoryHistory($taskId: Long!) {
  taskInventoryHistory(taskId: $taskId) {
    createdAt
    isEntryPoint
    needReport
    report
    statusFrom {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    statusTo {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    user {
      uid
      email
      userName
      id
    }
  }
}
    `;
/**
 * __useGetTaskInventoryHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskInventoryHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskInventoryHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskInventoryHistoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskInventoryHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTaskInventoryHistoryDocument, options);
}
export function useGetTaskInventoryHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTaskInventoryHistoryDocument, options);
}
export function useGetTaskInventoryHistorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetTaskInventoryHistoryDocument, options);
}
