import React, { useContext } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MDIcon from '../../../../../components/ui/MDIcon';
import { ShippingContext } from '../../../../pages/task/shipping/ShippingEditPage';
const { confirm } = Modal;
const ShippingDetailNomenclatureActions = (props) => {
    const { onDeleteClick, onSkuInfoClick, isLoading, skuId } = props;
    const context = useContext(ShippingContext);
    const handlerDelete = () => {
        confirm({
            title: `Удалить товарную позицию #${skuId}`,
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: 'Вы уверены что хотите удалить товарную позицию?',
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk() {
                onDeleteClick();
            },
        });
    };
    return (React.createElement(Button.Group, null,
        React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
            React.createElement(Button, { disabled: context === null || context === void 0 ? void 0 : context.isLocked, loading: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: onSkuInfoClick })),
        React.createElement(Tooltip, { title: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C", mouseEnterDelay: 1 },
            React.createElement(Button, { disabled: context === null || context === void 0 ? void 0 : context.isLocked, loading: isLoading, danger: true, icon: React.createElement(MDIcon, { icon: "delete-forever" }), onClick: handlerDelete }))));
};
export default ShippingDetailNomenclatureActions;
