var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getUrlWithParams } from '../../../../helpers/function';
import dtoToEntity from '../../adapters/dtoToEntity';
import { request } from '../../api';
export const getProductCategoryList = (page, pageSize, search, parentId, handlerState) => __awaiter(void 0, void 0, void 0, function* () {
    const url = getUrlWithParams('productCategory/list', {
        page, pageSize, search, parentId,
    });
    const { data } = yield request(handlerState)
        .then(instance => instance.get(url));
    return {
        list: data.data.map(dtoToEntity.productCategoryAdapter),
        pagination: dtoToEntity.paginationAdapter(data.pagination),
    };
});
