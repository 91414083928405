var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useMemo } from "react";
import { InventoryByType } from "../../../../../../api/graphql/common";
import { Table } from "antd";
import { useParams } from "react-router-dom";
import { columns, createActionColumn } from "./Columns/SKU";
import { skuActions } from "../../../../../../redux/reducers/skuReducer";
import { useAppDispatch } from "../../../../../../redux/store";
import { useTaskInventoryDetails } from "../../../../../../hooks/api/taskInventoryApi/useTaskInventoryDetails";
import { InventoryContext } from "../../../../../pages/task/inventory/InventoryEditPage";
export const SKU = ({ taskInventorySkus, refetch }) => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const data = useMemo(() => dtoToEntityAdapt(taskInventorySkus), [JSON.stringify(taskInventorySkus)]);
    const { taskDetails } = useTaskInventoryDetails();
    const handlerDeleteDetails = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.delete({ variables: { input: { taskId, request: { ids: [id], inventoryByType: InventoryByType.Sku } } } });
        yield refetch;
    });
    const handlerOpenSkuInfo = (id) => {
        dispatch(skuActions.open(id));
    };
    const context = useContext(InventoryContext);
    const groupedColumns = useMemo(() => [...columns, ...createActionColumn(handlerDeleteDetails, handlerOpenSkuInfo, taskDetails.loading, !!(context === null || context === void 0 ? void 0 : context.isLocked))], []);
    return React.createElement(Table, { style: { whiteSpace: "nowrap" }, columns: groupedColumns, dataSource: data, bordered: true, pagination: false, size: "small", scroll: { x: true } });
};
function dtoToEntityAdapt(taskInventorySkus) {
    return [
        ...taskInventorySkus
            .filter((taskInventorySku) => !taskInventorySku.taskInventoryStorageCellId && !taskInventorySku.taskInventoryProductId)
            .map((taskInventorySku) => {
            var _a;
            return ({
                number: taskInventorySku.id,
                type: taskInventorySku.type,
                title: taskInventorySku.title,
                quantity: taskInventorySku.quantity,
                storageCellExpectedTitle: (_a = taskInventorySku.storageCellExpected) === null || _a === void 0 ? void 0 : _a.title,
            });
        }),
    ];
}
