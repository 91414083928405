import warehouseApi, { WAREOUSE_CELL_TAG } from "..";
import { StorageCellCreateDocument, StorageCellDeleteDocument, StorageCellEditDocument } from "../../../../api/graphql/queries/storageCell/StorageCellMutation";
import { GetStorageCellListDocument, StorageCellDocument } from "../../../../api/graphql/queries/storageCell/StorageCellQuery";
import dtoToEntity from "../../../../api/rest/adapters/dtoToEntity";
const warehouseCellApi = warehouseApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchCellList: builder.query({
            query: ({ warehouseId, page, pageSize, search }) => ({
                document: GetStorageCellListDocument,
                variables: { warehouseId, page, pageSize, search },
            }),
            transformResponse: (response) => ({
                list: response.data.map(dtoToEntity.storageCellAdapter),
                pagination: dtoToEntity.paginationAdapter(response.pagination),
            }),
            providesTags: (result) => {
                if (result) {
                    return [...result.list.map(({ id }) => ({ type: WAREOUSE_CELL_TAG, id })), WAREOUSE_CELL_TAG];
                }
                return [WAREOUSE_CELL_TAG];
            },
        }),
        fetchCell: builder.query({
            query: ({ storageCellId, warehouseId }) => ({
                document: StorageCellDocument,
                variables: { storageCellId, warehouseId },
            }),
            transformResponse: (response) => {
                const responseData = response === null || response === void 0 ? void 0 : response.storageCell;
                return dtoToEntity.storageCellInfoAdapter(responseData);
            },
            providesTags: (result, error, { storageCellId }) => [WAREOUSE_CELL_TAG, { type: WAREOUSE_CELL_TAG, storageCellId }],
        }),
        addCell: builder.mutation({
            query: (input) => ({
                document: StorageCellCreateDocument,
                variables: input,
            }),
            transformResponse: (response) => {
                const responseData = response === null || response === void 0 ? void 0 : response.storageCellCreate.storageCellDto;
                return dtoToEntity.storageCellInfoAdapter(responseData);
            },
            invalidatesTags: [WAREOUSE_CELL_TAG],
        }),
        editCell: builder.mutation({
            query: (input) => ({
                document: StorageCellEditDocument,
                variables: input,
            }),
            transformResponse: (response) => {
                const responseData = response === null || response === void 0 ? void 0 : response.storageCellEdit.storageCellDto;
                return dtoToEntity.storageCellInfoAdapter(responseData);
            },
            invalidatesTags: (result, error, { input }) => [WAREOUSE_CELL_TAG, { type: WAREOUSE_CELL_TAG, id: input === null || input === void 0 ? void 0 : input.storageCellId }],
        }),
        deleteCell: builder.mutation({
            query: (input) => ({
                document: StorageCellDeleteDocument,
                variables: input,
            }),
            transformResponse: (_, __, { input }) => input === null || input === void 0 ? void 0 : input.storageCellId,
            invalidatesTags: (result, error, { input }) => [WAREOUSE_CELL_TAG, { type: WAREOUSE_CELL_TAG, id: input === null || input === void 0 ? void 0 : input.storageCellId }],
        }),
    }),
});
export default warehouseCellApi;
