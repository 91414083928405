import { Button, Form, Spin, message } from "antd";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHandbookPackingSetEditMutation } from "../../../../../api/graphql/queries/handbookPacking/HandbookPackingMutations";
import { links } from "../../../../../constants/links";
import PackingForm from "../shared/PackingSetForm/PackingSetForm";
import { useGetHandbookPackingSetQuery } from "../../../../../api/graphql/queries/handbookPacking/HandbookPackingQuery";
import Centered from "../../../../../components/ui/Centered";
import { SUCCESS_MESSAGE } from "../../constants";
const PackingSetEditFormContainer = () => {
    const [form] = Form.useForm();
    const [editPackingSet] = useHandbookPackingSetEditMutation();
    const { typeId: typeParam } = useParams();
    const typeId = typeParam ? +typeParam : null;
    const { data } = useGetHandbookPackingSetQuery({ variables: { handbookPackingSetId: (typeId || 0) } });
    const initialValues = useMemo(() => {
        var _a, _b, _c, _d, _e;
        return ({
            title: (_a = data === null || data === void 0 ? void 0 : data.handbookPackingSet) === null || _a === void 0 ? void 0 : _a.title,
            id: (typeId || 0),
            handbookUnitId: { label: (_c = (_b = data === null || data === void 0 ? void 0 : data.handbookPackingSet) === null || _b === void 0 ? void 0 : _b.handbookUnit) === null || _c === void 0 ? void 0 : _c.title, value: (_e = (_d = data === null || data === void 0 ? void 0 : data.handbookPackingSet) === null || _d === void 0 ? void 0 : _d.handbookUnit) === null || _e === void 0 ? void 0 : _e.id },
        });
    }, [typeId, data]);
    const navigate = useNavigate();
    const handlerButtonClick = () => {
        form.submit();
    };
    const handlerSubmit = (values) => {
        if (typeof values.handbookUnitId.value === "number" && typeId) {
            editPackingSet({
                variables: {
                    input: {
                        id: typeId,
                        handbookUnitId: values.handbookUnitId.value,
                        title: values.title,
                    },
                },
            }).then(() => {
                message.success(SUCCESS_MESSAGE.packing_with_name_create(values.title));
                navigate(links.handbookPackingLink.url());
            });
        }
    };
    if (!(initialValues === null || initialValues === void 0 ? void 0 : initialValues.title)) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { spinning: true })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(PackingForm, { initialValues: initialValues, form: form, onSubmit: handlerSubmit }),
        React.createElement(Button, { type: "primary", onClick: handlerButtonClick }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
};
export default PackingSetEditFormContainer;
