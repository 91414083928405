import React from 'react';
import { Col, Row, Skeleton, Space, } from 'antd';
const TaskHeaderSkeleton = () => (React.createElement("div", null,
    React.createElement(Row, { align: "middle", gutter: [15, 5] },
        React.createElement(Col, { flex: "auto", key: 0 },
            React.createElement(Skeleton.Input, { active: true, style: { width: 200 }, size: "small" })),
        React.createElement(Col, { flex: "none", key: 1 },
            React.createElement(Space, null,
                React.createElement(Skeleton.Button, { active: true, style: { width: 305 } })))),
    React.createElement(Row, { align: "middle", gutter: [15, 10], style: { marginTop: 8 } },
        React.createElement(Col, { flex: "auto", key: 0 },
            React.createElement(Skeleton, { active: true, paragraph: false, title: { width: 120, style: { margin: 0 } } })),
        React.createElement(Col, { flex: "none", key: 2 },
            React.createElement(Skeleton, { active: true, paragraph: false, title: { width: 130, style: { margin: 0 } } })))));
export default TaskHeaderSkeleton;
