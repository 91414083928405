import React from "react";
import { Button, Result } from "antd";
import Centered from "../../../../components/ui/Centered";
import { useAuth } from "react-oidc-context";
const OidcSessionLost = () => {
    const auth = useAuth();
    const btn = (React.createElement(Button, { type: "primary", onClick: () => {
            auth.signinRedirect();
        } }, "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C"));
    return (React.createElement(Centered, null,
        React.createElement(Result, { status: "403", title: "\u0421\u0435\u0441\u0441\u0438\u044F \u043E\u043A\u043E\u043D\u0447\u0435\u043D\u0430", subTitle: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443 \u0447\u0442\u043E\u0431\u044B \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C \u0440\u0430\u0431\u043E\u0442\u0443", extra: btn })));
};
export default OidcSessionLost;
