import React from 'react';
import { Col, Form, Input, Row, } from 'antd';
import HandbookCategorySelect from '../HandbookCategorySelect';
var FormNames;
(function (FormNames) {
    FormNames["ID"] = "id";
    FormNames["PARENT_ID"] = "parentId";
    FormNames["TITLE"] = "title";
})(FormNames || (FormNames = {}));
const HandbookCategoryForm = (props) => {
    const { form, onSubmit, initialValues } = props;
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues },
        React.createElement(Form.Item, { name: FormNames.ID, hidden: true },
            React.createElement(Input, null)),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.PARENT_ID, label: "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F \u0440\u043E\u0434\u0438\u0442\u0435\u043B\u044C" },
                    React.createElement(HandbookCategorySelect, { allowClear: true }))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.TITLE, label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438", rules: [{ required: true, message: 'Поле не заполнено' }] },
                    React.createElement(Input, null))))));
};
export default HandbookCategoryForm;
