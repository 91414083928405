/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import productCategoryApi from '../../api/productApi/productCategory';
import { productTypeGraphqlApi } from '../../api/productApi/productType';
const initialState = {
    categories: {
        modal: {
            visible: false,
        },
        list: [],
    },
    types: {
        list: [],
    },
    attrCategory: {
        modal: {
            visible: false,
        },
    },
};
const setCategoryList = (state, payload) => {
    state.categories.list = [
        ...state.categories.list.map((category) => {
            const newCategory = payload.find((item) => item.id === category.id);
            return newCategory !== null && newCategory !== void 0 ? newCategory : category;
        }),
        ...payload.filter((ctegory) => (!state.categories.list.some((item) => item.id === ctegory.id))),
    ];
};
const setTypeList = (state, payload) => {
    const newTypes = [...payload];
    state.types.list = [
        ...state.types.list.map((type) => {
            const newType = newTypes.find((item, index) => {
                const result = item.id === type.id;
                if (result) {
                    newTypes.splice(index, 1);
                }
                return result;
            });
            return Object.assign(Object.assign(Object.assign({}, type), newType), { productTypeCategories: ((newType === null || newType === void 0 ? void 0 : newType.productTypeCategories.length)
                    ? newType === null || newType === void 0 ? void 0 : newType.productTypeCategories : type.productTypeCategories) });
        }),
        ...newTypes,
    ];
};
const productSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        clearCategoryList(state) {
            state.categories.list = [];
        },
        changeCategoryModalVisible(state, action) {
            state.categories.modal.visible = action.payload.visible;
            state.categories.modal.initialValues = action.payload.visible
                ? action.payload.initialValues : undefined;
        },
        changeAttrCategoryModalVisible(state, action) {
            state.attrCategory.modal.visible = action.payload.visible;
            state.attrCategory.modal.initialValues = action.payload.visible
                ? action.payload.initialValues : undefined;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(productCategoryApi.endpoints.fetchProductCategoryList.matchFulfilled, (state, { payload }) => {
            setCategoryList(state, payload.list);
        })
            .addMatcher(productCategoryApi.endpoints.fetchProductCategory.matchFulfilled, (state, { payload }) => {
            setCategoryList(state, [payload.category, ...payload.childrens.categories]);
            setTypeList(state, payload.childrens.types);
        })
            .addMatcher(productCategoryApi.endpoints.addProductCategory.matchFulfilled, (state, { payload }) => {
            setCategoryList(state, [payload]);
        })
            .addMatcher(productCategoryApi.endpoints.editProductCategory.matchFulfilled, (state, { payload }) => {
            setCategoryList(state, [payload]);
        })
            .addMatcher(productCategoryApi.endpoints.deleteProductCategory.matchFulfilled, (state, { payload }) => {
            state.categories.list = state.categories.list.filter((item) => item.id !== payload);
        })
            .addMatcher(productTypeGraphqlApi.endpoints.fetchProductType.matchFulfilled, (state, { payload }) => {
            setTypeList(state, [payload]);
        })
            .addMatcher(productTypeGraphqlApi.endpoints.addProductType.matchFulfilled, (state, { payload }) => {
            setTypeList(state, [payload]);
        })
            .addMatcher(productTypeGraphqlApi.endpoints.editProductType.matchFulfilled, (state, { payload }) => {
            setTypeList(state, [payload]);
        })
            .addMatcher(productTypeGraphqlApi.endpoints.deleteProductType.matchFulfilled, (state, { payload }) => {
            state.types.list = state.types.list.filter((item) => item.id !== payload);
        });
    },
});
export const productActions = productSlice.actions;
export const selectProductCategories = (state) => state.product.categories;
export const selectProductTypes = (state) => state.product.types;
export const selectProductSlice = (state) => state.product;
export const selectProductTypeAttrCategoryModal = (state) => state.product.attrCategory.modal;
export default productSlice.reducer;
