var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { Empty, Spin } from "antd";
import LoadingState from "../../../../constants/enum/LoadingState";
import SkuTable from "../SkuTable";
import SkuTableSelected from "../SkuTableSelected";
import SearchModal from "../../SearchModal/SearchModal";
import Centered from "../../../ui/Centered";
class SkuSearchModal extends SearchModal {
    constructor() {
        super(...arguments);
        this.handlerOk = () => {
            const { onAdd } = this.props;
            const { selectedItems } = this.state;
            const selectedSkuKeys = selectedItems.map(({ id }) => id);
            onAdd(selectedSkuKeys);
            this.handlerClose();
        };
        this.handlerSearch = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const { warehouseId, getSkuList, adaptToPaginatedResult, adaptProductFilterValuesToSkuFilterInput } = this.props;
            const { items, filterValues } = this.state;
            const { pagination } = items;
            const skuItems = yield getSkuList({
                fetchPolicy: "no-cache",
                variables: {
                    page: pagination.page,
                    pageSize: pagination.itemsOnPage,
                    filter: Object.assign(Object.assign({}, adaptProductFilterValuesToSkuFilterInput(filterValues)), { warehouses: (warehouseId === null || warehouseId === void 0 ? void 0 : warehouseId.map((el) => +el)) || [] }),
                },
            });
            if (!!skuItems.error) {
                this.setState({
                    loadingState: LoadingState.Error,
                });
            }
            else {
                this.setState({
                    items: adaptToPaginatedResult(((_a = skuItems.data) === null || _a === void 0 ? void 0 : _a.skuList) || []),
                });
                this.setState({
                    loadingState: LoadingState.Ready,
                });
            }
        });
        this.renderSelectedTable = () => {
            const { selectedItems } = this.state;
            if (selectedItems.length === 0) {
                return (React.createElement(Centered, null,
                    React.createElement(Empty, null)));
            }
            const selectedSkuKeys = selectedItems.map(({ id }) => id);
            return React.createElement(SkuTableSelected, { items: selectedItems, selectedKeys: selectedSkuKeys, onSelect: this.handlerSelect, tableProps: {
                    scroll: {
                        y: 580,
                        x: true,
                    },
                } });
        };
        this.renderFindTable = () => {
            const { items, selectedItems, attributes, loadingState } = this.state;
            if (loadingState === LoadingState.Loading) {
                return (React.createElement(Centered, null,
                    React.createElement(Spin, null)));
            }
            if (items.list.length === 0) {
                return (React.createElement(Centered, null,
                    React.createElement(Empty, null)));
            }
            const itemList = items.list;
            const selectedItemKeys = selectedItems.map(({ id }) => id);
            return (React.createElement(SkuTable, { attributes: attributes, items: itemList, selectedKeys: selectedItemKeys, onSelect: this.handlerSelect, tableProps: {
                    scroll: {
                        y: 580,
                        x: true,
                    },
                } }));
        };
    }
}
export default SkuSearchModal;
