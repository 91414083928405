import { Button, Form, message } from 'antd';
import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ProductTypeForm from '../../components/ProductTypeForm';
import { links } from '../../constants/links';
import productCategoryApi from '../../redux/api/productApi/productCategory';
import { productTypeGraphqlApi } from '../../redux/api/productApi/productType';
import { formatFormulaInput } from '../../components/FormulaMaskInput/FormulaMaskInput';
const { useFetchProductCategoryQuery } = productCategoryApi;
const { useAddProductTypeMutation } = productTypeGraphqlApi;
const ProductTypeAddFormContainer = () => {
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const parentParam = searchParams.get('parentId');
    const parentId = parentParam ? +parentParam : null;
    const { data: parentCategory } = useFetchProductCategoryQuery(parentId, { skip: !Number.isInteger(parentId) });
    const [addProductType] = useAddProductTypeMutation();
    const navigate = useNavigate();
    const handlerButtonClick = () => {
        form.submit();
    };
    const handlerSubmit = (values) => {
        addProductType({
            input: {
                title: values.title,
                productCategoryId: parseInt(values.productCategoryId.value, 10),
                description: values.description,
                titleMask: values.titleMask,
                sizeFormula: (values === null || values === void 0 ? void 0 : values.sizeFormula) ? formatFormulaInput(values === null || values === void 0 ? void 0 : values.sizeFormula) : undefined,
            }
        })
            .unwrap()
            .then(() => {
            message.success(`Тип ${values.title} успешно создан`);
            navigate(links.handbookProductLink.url());
        });
    };
    const initialValues = useMemo(() => {
        const values = {};
        if (parentCategory) {
            values.productCategoryId = {
                value: parentCategory.category.id,
                label: parentCategory.category.title,
            };
        }
        return values;
    }, [parentCategory]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ProductTypeForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues, maskOptions: [] }),
        React.createElement(Button, { type: "primary", onClick: handlerButtonClick }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
};
export default ProductTypeAddFormContainer;
