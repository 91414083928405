import productApi, { PRODUCT_CATEGORY_TAG } from '..';
import dtoToEntity from '../../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../../constants/enum/Method';
const URI = 'productCategory';
const productCategoryApi = productApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchProductCategoryList: builder.query({
            query: (args) => {
                const { page, pageSize, parentId, search, } = args;
                return {
                    method: Method.GET,
                    url: `${URI}/list`,
                    params: {
                        page, pageSize, parentId, search,
                    },
                };
            },
            transformResponse: (response) => ({
                list: response.data.map(dtoToEntity.productCategoryAdapter),
                pagination: dtoToEntity.paginationAdapter(response.pagination),
            }),
            providesTags: (result) => {
                if (result) {
                    return [
                        ...result.list.map(({ id }) => ({ type: PRODUCT_CATEGORY_TAG, id })),
                        PRODUCT_CATEGORY_TAG,
                    ];
                }
                return [PRODUCT_CATEGORY_TAG];
            },
        }),
        fetchProductCategory: builder.query({
            query: (productCategoryId) => ({
                method: Method.GET,
                url: `${URI}/${productCategoryId}`,
            }),
            transformResponse: (response) => {
                var _a, _b, _c, _d;
                return ({
                    category: dtoToEntity.productCategoryAdapter(response),
                    childrens: {
                        categories: (_b = (_a = response.productCategories) === null || _a === void 0 ? void 0 : _a.map(dtoToEntity.productCategoryAdapter)) !== null && _b !== void 0 ? _b : [],
                        types: (_d = (_c = response.productTypes) === null || _c === void 0 ? void 0 : _c.map(dtoToEntity.productTypeAdapter)) !== null && _d !== void 0 ? _d : [],
                    },
                });
            },
            providesTags: (result, error, id) => [
                PRODUCT_CATEGORY_TAG, { type: PRODUCT_CATEGORY_TAG, id },
            ],
        }),
        addProductCategory: builder.mutation({
            query: (values) => ({
                method: Method.POST,
                url: `${URI}/add`,
                body: values,
            }),
            transformResponse: (response) => (dtoToEntity.productCategoryAdapter(response)),
            invalidatesTags: [PRODUCT_CATEGORY_TAG],
        }),
        editProductCategory: builder.mutation({
            query: (args) => {
                const { productCategoryId, values } = args;
                return {
                    method: Method.PUT,
                    url: `${URI}/${productCategoryId}`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.productCategoryAdapter(response)),
            invalidatesTags: (result, error, { productCategoryId }) => [
                PRODUCT_CATEGORY_TAG, { type: PRODUCT_CATEGORY_TAG, id: productCategoryId },
            ],
        }),
        deleteProductCategory: builder.mutation({
            query: (productCategoryId) => ({
                method: Method.DELETE,
                url: `${URI}/${productCategoryId}`,
            }),
            transformResponse: (_, __, productCategoryId) => productCategoryId,
            invalidatesTags: (result, error, productCategoryId) => [
                PRODUCT_CATEGORY_TAG, { type: PRODUCT_CATEGORY_TAG, id: productCategoryId },
            ],
        }),
    }),
});
export default productCategoryApi;
