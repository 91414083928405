import React from 'react';
import { Outlet } from 'react-router-dom';
import { links } from '../constants/links';
import WriteOffAddPage from '../core/pages/task/writeOff/WriteOffAddPage';
import WriteOffEditPage from '../core/pages/task/writeOff/WriteOffEditPage';
import WriteOffListPage from '../core/pages/task/writeOff/WriteOffListPage';
import WriteOffTaskPage from '../core/pages/task/writeOff/WriteOffTaskPage';
const paths = links.taskWriteOffLinks;
const taskWriteOffRoutes = {
    title: paths.list.title,
    breadcrumb: 'Списание',
    path: paths.list.path,
    element: React.createElement(Outlet, null),
    children: [
        {
            index: true,
            element: React.createElement(WriteOffListPage, null),
        },
        {
            title: paths.page.title,
            breadcrumb: paths.page.breadcrumb,
            path: paths.page.url(':taskId'),
            element: React.createElement(Outlet, null),
            children: [
                {
                    index: true,
                    element: React.createElement(WriteOffTaskPage, null),
                },
                {
                    title: paths.edit.title,
                    breadcrumb: paths.edit.breadcrumb,
                    path: paths.edit.url(':taskId'),
                    element: React.createElement(WriteOffEditPage, null),
                },
            ],
        },
        {
            title: paths.add.title,
            breadcrumb: paths.add.breadcrumb,
            path: paths.add.url(),
            element: React.createElement(WriteOffAddPage, null),
        },
    ],
};
export default taskWriteOffRoutes;
