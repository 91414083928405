import { Form, Input, InputNumber, } from 'antd';
import React from 'react';
var FormNames;
(function (FormNames) {
    FormNames["ID"] = "id";
    FormNames["TITLE"] = "title";
    FormNames["DESCRIPTION"] = "description";
    FormNames["CELL_WIDTH"] = "cellWidth";
    FormNames["CELL_HEIGHT"] = "cellHeight";
    FormNames["CELL_DEPTH"] = "cellDepth";
    FormNames["CELL_WEIGHT"] = "cellWeight";
})(FormNames || (FormNames = {}));
const WarehouseCellTypeForm = (props) => {
    const { form, onSubmit, initialValues } = props;
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues },
        React.createElement(Form.Item, { name: FormNames.ID, hidden: true },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435", name: FormNames.TITLE },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { name: FormNames.DESCRIPTION, label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" },
            React.createElement(Input.TextArea, null)),
        React.createElement(Form.Item, { label: "\u0428\u0438\u0440\u0438\u043D\u0430", name: FormNames.CELL_WIDTH },
            React.createElement(InputNumber, { addonAfter: "\u043C" })),
        React.createElement(Form.Item, { label: "\u0412\u044B\u0441\u043E\u0442\u0430", name: FormNames.CELL_HEIGHT },
            React.createElement(InputNumber, { addonAfter: "\u043C" })),
        React.createElement(Form.Item, { label: "\u0413\u043B\u0443\u0431\u0438\u043D\u0430", name: FormNames.CELL_DEPTH },
            React.createElement(InputNumber, { addonAfter: "\u043C" })),
        React.createElement(Form.Item, { label: "\u0412\u0435\u0441", name: FormNames.CELL_WEIGHT },
            React.createElement(InputNumber, null))));
};
export default WarehouseCellTypeForm;
