import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const WarehouseListDocument = gql `
    query WarehouseList($page: Int!, $pageSize: Int!, $search: String, $isExternal: Boolean) {
  warehouseList(
    page: $page
    pageSize: $pageSize
    search: $search
    isExternal: $isExternal
  ) {
    data {
      id
      address
      isExternal
      storageTitle
      timeZone {
        id
        displayName
        daylightName
        standardName
        supportsDaylightSavingTime
        baseUtcOffset
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useWarehouseListQuery__
 *
 * To run a query within a React component, call `useWarehouseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *      isExternal: // value for 'isExternal'
 *   },
 * });
 */
export function useWarehouseListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(WarehouseListDocument, options);
}
export function useWarehouseListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(WarehouseListDocument, options);
}
export function useWarehouseListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(WarehouseListDocument, options);
}
export const WarehouseDocument = gql `
    query Warehouse($warehouseId: Long!) {
  warehouse(warehouseId: $warehouseId) {
    id
    address
    addressBuilding
    addressCity
    addressCountry
    addressIndex
    addressRegion
    addressStreet
    geoLatitude
    geoLongitude
    isExternal
    storageTitle
    timeZone {
      id
      displayName
      daylightName
      standardName
      supportsDaylightSavingTime
      baseUtcOffset
    }
  }
}
    `;
/**
 * __useWarehouseQuery__
 *
 * To run a query within a React component, call `useWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useWarehouseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(WarehouseDocument, options);
}
export function useWarehouseLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(WarehouseDocument, options);
}
export function useWarehouseSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(WarehouseDocument, options);
}
