import React from "react";
import { getColumnType } from "../../../../../../helpers/function";
import { Button, Tooltip } from "antd";
import MDIcon from "../../../../../../components/ui/MDIcon";
const onCell = (record) => ({});
export const createColumns = (attributes, handlerOpenSkuInfo) => {
    const attributesKyes = Object.keys(attributes);
    return [
        getColumnType("skuId", "#", false, {
            onCell,
            width: "80px",
        }),
        ...attributesKyes.map((attribute) => getColumnType("", attribute, false, {
            onCell,
            width: "80px",
            render: (_, record) => {
                var _a;
                const attr = attributes[attribute];
                const data = (_a = record.skuAttributeValues) === null || _a === void 0 ? void 0 : _a.find((el) => el.key === attr.id);
                return data ? `${data.value.valueReadable || ""} ${(attr === null || attr === void 0 ? void 0 : attr.unit) || ""}` : React.createElement(React.Fragment, null);
            },
        })),
        getColumnType("quantity", "Количество", false, {}),
        {
            title: "Ячейка",
            fixed: "right",
            children: [
                getColumnType("storageCellFrom", "до", true, {
                    fixed: "right",
                    width: "80px",
                    render: (_, record) => { var _a; return (_a = record.storageCellFrom) === null || _a === void 0 ? void 0 : _a.title; },
                }),
                getColumnType("storageCellTo", "после", true, {
                    fixed: "right",
                    width: "80px",
                    render: (_, record) => { var _a; return (_a = record.storageCellTo) === null || _a === void 0 ? void 0 : _a.title; },
                }),
            ],
        },
        getColumnType("", "", true, {
            fixed: "right",
            width: "30px",
            render: (_, record) => (React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                React.createElement(Button, { size: "small", type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: () => {
                        record.skuId && handlerOpenSkuInfo(`${record.skuId}`);
                    } }))),
        }),
    ];
};
