import React from 'react';
import { Outlet } from 'react-router-dom';
import { links } from '../constants/links';
import InventoryAddPage from '../core/pages/task/inventory/InventoryAddPage';
import InventoryEditPage from '../core/pages/task/inventory/InventoryEditPage';
import InventoryListPage from '../core/pages/task/inventory/InventoryListPage';
import InventoryTaskPage from '../core/pages/task/inventory/InventoryTaskPage';
const paths = links.taskInventoryLinks;
const taskInventoryRoutes = {
    title: paths.list.title,
    breadcrumb: 'Инвентаризация',
    path: paths.list.path,
    element: React.createElement(Outlet, null),
    children: [
        {
            index: true,
            element: React.createElement(InventoryListPage, null),
        },
        {
            title: paths.page.title,
            breadcrumb: paths.page.breadcrumb,
            path: paths.page.url(':taskId'),
            element: React.createElement(Outlet, null),
            children: [
                {
                    index: true,
                    element: React.createElement(InventoryTaskPage, null),
                },
                {
                    title: paths.edit.title,
                    breadcrumb: paths.edit.breadcrumb,
                    path: paths.edit.url(':taskId'),
                    element: React.createElement(InventoryEditPage, null),
                },
            ],
        },
        {
            title: paths.add.title,
            breadcrumb: paths.add.breadcrumb,
            path: paths.add.url(),
            element: React.createElement(InventoryAddPage, null),
        },
    ],
};
export default taskInventoryRoutes;
