import React, { useEffect } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import Toolbar from '../Toolbar';
import ToolbarsSwitcher from '../ToolbarsSwitcher';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { appActions } from '../../redux/reducers/appReducer';
import { useAppDispatch } from '../../hooks/useAppDispatch';
const Page = (props) => {
    const { children, toolbarRight, toolbarBottom, toolbarTop, toolbarLeft, } = props;
    const { toolbarLeftOpen, toolbarRightOpen } = useTypedSelector((state) => state.app);
    const { toggleToolbarRight, toggleToolbarLeft } = appActions;
    const dispatch = useAppDispatch();
    const wrapperClass = classNames({
        'app-wrapper': true,
        'has-left-fragment': !!toolbarLeft,
        'has-right-fragment': !!toolbarRight,
    });
    useEffect(() => () => {
        dispatch(toggleToolbarRight(false));
        dispatch(toggleToolbarLeft(false));
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: wrapperClass },
            React.createElement(Toolbar, { mainClass: "app-fragment-left", toggle: toolbarLeftOpen }, toolbarLeft),
            React.createElement("div", { className: "app-contains" },
                React.createElement(Toolbar, { mainClass: "app-toolbar-top" }, toolbarTop),
                React.createElement("div", { className: "app-content" }, children),
                React.createElement(Toolbar, { mainClass: "app-toolbar-bottom" }, toolbarBottom)),
            React.createElement(Toolbar, { mainClass: "app-fragment-right", toggle: toolbarRightOpen }, toolbarRight)),
        React.createElement(ToolbarsSwitcher, { show: !!toolbarLeft || !!toolbarRight },
            React.createElement(ToolbarsSwitcher.Item, { key: uniqueId(), onClick: () => dispatch(toggleToolbarLeft()), show: !!toolbarLeft, active: toolbarLeftOpen, icon: "mdi-page-layout-sidebar-left" }),
            React.createElement(ToolbarsSwitcher.Item, { key: uniqueId(), onClick: () => dispatch(toggleToolbarRight()), show: !!toolbarRight, active: toolbarRightOpen, icon: "mdi mdi-page-layout-sidebar-right" }))));
};
export default Page;
