import { Col, Row, Space, Spin, Tooltip, Typography } from "antd";
import React from "react";
import ColorPalette from "../../../constants/enum/ColorPalette";
import { dateToString, toDate } from "../../../helpers/function";
import Label from "../../ui/Label";
import TaskHeaderSkeleton from "./TaskHeaderSkeleton";
function TaskHeader(props) {
    const { id, userName, createdAt, shippedAt, acceptedAt, executedAt, isLoading, taskTypeTitle, actions, short, externalId } = props;
    if (!id) {
        return React.createElement(TaskHeader.Skeleton, null);
    }
    return (React.createElement("div", null,
        React.createElement(Row, { align: "middle", gutter: [15, 5] },
            React.createElement(Col, { flex: "none", key: 0 },
                React.createElement(Typography.Title, { className: "m-0", level: 5 },
                    React.createElement("span", { className: "text-muted", key: 0 },
                        "#",
                        " "),
                    `${id} - ${taskTypeTitle}`,
                    `${externalId ? ` Внешняя задача #${externalId}` : ""}`)),
            React.createElement(Col, { flex: "auto", key: 1 },
                React.createElement(Spin, { spinning: isLoading })),
            React.createElement(Col, { flex: "none", key: 2 },
                React.createElement(Space, null, actions))),
        !short ? (React.createElement(Row, { align: "middle", gutter: [15, 5], style: { marginTop: 8 } },
            React.createElement(Col, { flex: "auto", key: 0 },
                React.createElement(Label, { icon: "mdi-account", text: userName })),
            React.createElement(Col, { flex: "none", key: 2 },
                React.createElement(Tooltip, { title: "\u0414\u0430\u0442\u0430 \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u044F", placement: "bottom" },
                    React.createElement("span", null,
                        React.createElement(Label, { icon: "mdi-plus", text: dateToString(toDate(createdAt)), color: ColorPalette.Blue })))),
            shippedAt && (React.createElement(Col, { flex: "none", key: 2 },
                React.createElement(Tooltip, { title: "\u0414\u0430\u0442\u0430 \u043E\u0442\u0433\u0440\u0443\u0437\u043A\u0438", placement: "bottom" },
                    React.createElement("span", null,
                        React.createElement(Label, { icon: "mdi-plus", text: dateToString(toDate(shippedAt)), color: ColorPalette.Green }))))),
            acceptedAt && (React.createElement(Col, { flex: "none", key: 2 },
                React.createElement(Tooltip, { title: "\u0414\u0430\u0442\u0430 \u043F\u0440\u0438\u0435\u043C\u043A\u0438", placement: "bottom" },
                    React.createElement("span", null,
                        React.createElement(Label, { icon: "mdi-plus", text: dateToString(toDate(acceptedAt)), color: ColorPalette.Cyan }))))),
            executedAt && (React.createElement(Col, { flex: "none", key: 2 },
                React.createElement(Tooltip, { title: "\u0414\u0430\u0442\u0430 \u0437\u0430\u043A\u0440\u044B\u0442\u0438\u044F", placement: "bottom" },
                    React.createElement("span", null,
                        React.createElement(Label, { icon: "mdi-check", text: dateToString(toDate(executedAt)), color: ColorPalette.Green }))))))) : null));
}
TaskHeader.Skeleton = TaskHeaderSkeleton;
export default TaskHeader;
