import { useSearchParams } from 'react-router-dom';
import { TaskFilterFields } from '../components/task/TaskListFilter/types';
export const useTaskFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const warehouse = searchParams.get('w');
    const status = searchParams.get('s');
    const dateFrom = searchParams.get('df');
    const dateTo = searchParams.get('dt');
    const filterValues = {
        [TaskFilterFields.Warehouse]: JSON.parse(warehouse !== null && warehouse !== void 0 ? warehouse : '[]'),
        [TaskFilterFields.Status]: JSON.parse(status !== null && status !== void 0 ? status : '[]'),
        [TaskFilterFields.DateFrom]: dateFrom !== null && dateFrom !== void 0 ? dateFrom : undefined,
        [TaskFilterFields.DateTo]: dateTo !== null && dateTo !== void 0 ? dateTo : undefined,
    };
    const setFilterValues = (values, oldSearchParams) => new Promise((resolve) => {
        const newWarehouse = values[TaskFilterFields.Warehouse];
        const newStatus = values[TaskFilterFields.Status];
        const newDateFrom = values[TaskFilterFields.DateFrom];
        const newDateTo = values[TaskFilterFields.DateTo];
        const params = oldSearchParams !== null && oldSearchParams !== void 0 ? oldSearchParams : searchParams;
        if (newWarehouse.length) {
            params.set('w', JSON.stringify(newWarehouse));
        }
        else {
            params.delete('w');
        }
        if (newStatus.length) {
            params.set('s', JSON.stringify(newStatus));
        }
        else {
            params.delete('s');
        }
        if (newDateFrom) {
            params.set('df', newDateFrom);
        }
        else {
            params.delete('df');
        }
        if (newDateTo) {
            params.set('dt', newDateTo);
        }
        else {
            params.delete('df');
        }
        setSearchParams(params);
        resolve(params);
    });
    return {
        filterValues,
        setFilterValues,
    };
};
