import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskStatusFragmentDoc = gql `
    fragment TaskStatus on TaskStatusDto {
  title
  isFinal
  style
  scope
  relations {
    id
    title
    style
    needReport
  }
}
    `;
export const TaskWriteOffViewFragmentDoc = gql `
    fragment TaskWriteOffView on TaskWriteOffDto {
  id
  createdAt
  updatedAt
  executedAt
  warehouse {
    id
    storageTitle
  }
  user {
    userName
  }
  status {
    ...TaskStatus
  }
}
    ${TaskStatusFragmentDoc}`;
export const TaskWriteOffCreateDocument = gql `
    mutation TaskWriteOffCreate($input: TaskWriteOffAddInput!) {
  taskWriteOffAdd(input: $input) {
    taskWriteOffDto {
      ...TaskWriteOffView
    }
  }
}
    ${TaskWriteOffViewFragmentDoc}`;
/**
 * __useTaskWriteOffCreateMutation__
 *
 * To run a mutation, you first call `useTaskWriteOffCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskWriteOffCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskWriteOffCreateMutation, { data, loading, error }] = useTaskWriteOffCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskWriteOffCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskWriteOffCreateDocument, options);
}
export const TaskWriteOffChangeStatusDocument = gql `
    mutation TaskWriteOffChangeStatus($input: TaskWriteOffChangeStatusInput!) {
  taskWriteOffChangeStatus(input: $input) {
    taskStatusDto {
      isFinal
      scope
      style
      title
      relations {
        id
        needReport
        style
        title
      }
    }
  }
}
    `;
/**
 * __useTaskWriteOffChangeStatusMutation__
 *
 * To run a mutation, you first call `useTaskWriteOffChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskWriteOffChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskWriteOffChangeStatusMutation, { data, loading, error }] = useTaskWriteOffChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskWriteOffChangeStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskWriteOffChangeStatusDocument, options);
}
export const TaskWriteSkuAddDocument = gql `
    mutation TaskWriteSkuAdd($taskId: Long!, $input: [TaskWriteOffSkuAddInput!]!) {
  taskWriteOffSkuAdd(input: $input, taskId: $taskId) {
    taskWriteOffSkuDto {
      id
      skuId
      storageCell {
        title
        address
      }
    }
  }
}
    `;
/**
 * __useTaskWriteSkuAddMutation__
 *
 * To run a mutation, you first call `useTaskWriteSkuAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskWriteSkuAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskWriteSkuAddMutation, { data, loading, error }] = useTaskWriteSkuAddMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskWriteSkuAddMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskWriteSkuAddDocument, options);
}
export const TaskWriteOffSkuEditDocument = gql `
    mutation TaskWriteOffSkuEdit($taskId: Long!, $input: [TaskWriteOffSkuEditInput!]!) {
  taskWriteOffSkuEdit(input: $input, taskId: $taskId) {
    taskWriteOffSkuDto {
      id
      skuId
      storageCell {
        title
        address
      }
    }
  }
}
    `;
/**
 * __useTaskWriteOffSkuEditMutation__
 *
 * To run a mutation, you first call `useTaskWriteOffSkuEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskWriteOffSkuEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskWriteOffSkuEditMutation, { data, loading, error }] = useTaskWriteOffSkuEditMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskWriteOffSkuEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskWriteOffSkuEditDocument, options);
}
export const TaskWriteOffDeleteDocument = gql `
    mutation TaskWriteOffDelete($input: TaskWriteOffSkuDeleteInput!) {
  taskWriteOffSkuDelete(input: $input) {
    taskWriteOffSkuDto {
      id
      skuId
      storageCell {
        title
        address
      }
    }
  }
}
    `;
/**
 * __useTaskWriteOffDeleteMutation__
 *
 * To run a mutation, you first call `useTaskWriteOffDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskWriteOffDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskWriteOffDeleteMutation, { data, loading, error }] = useTaskWriteOffDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskWriteOffDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskWriteOffDeleteDocument, options);
}
