import React from "react";
import { Button, Result } from "antd";
import Centered from "../../../../components/ui/Centered";
import { useAuth } from "react-oidc-context";
const OidcError = () => {
    const auth = useAuth();
    const btn = (React.createElement(Button, { onClick: () => {
            auth.signinRedirect();
        }, type: "primary" }, "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u044F"));
    return (React.createElement(Centered, null,
        React.createElement(Result, { status: "403", title: "\u041E\u0448\u0438\u0431\u043A\u0430 \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u0438", subTitle: "\u0412 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0435 \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u0438 \u043F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430. \u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0435\u0449\u0435 \u0440\u0430\u0437.", extra: btn })));
};
export default OidcError;
