import { FileObjectClass } from "../../../api/graphql/common";
import ObjectClass from "../../../constants/enum/ObjectClass";
const Adapter = {
    [ObjectClass.TaskLoading]: FileObjectClass.TaskLoading,
    [ObjectClass.TaskShipping]: FileObjectClass.TaskShipping,
    [ObjectClass.TaskMovement]: FileObjectClass.TaskMovement,
    [ObjectClass.TaskPartition]: FileObjectClass.TaskPartition,
    [ObjectClass.TaskCorrection]: FileObjectClass.TaskCorrection,
    [ObjectClass.TaskInventory]: FileObjectClass.TaskInventory,
    [ObjectClass.TaskWriteOff]: FileObjectClass.TaskWriteOff,
    [ObjectClass.TaskAssemblySku]: FileObjectClass.TaskWriteOff, // Не найдено соответствие в новом enum
    [ObjectClass.TaskChangeWarehouse]: FileObjectClass.TaskChangeWarehouse,
    [ObjectClass.SKU]: FileObjectClass.Sku,
    [ObjectClass.StorageSection]: FileObjectClass.Sku, // Не найдено соответствие в новом enum
    [ObjectClass.StorageCell]: FileObjectClass.Sku, // Не найдено соответствие в новом enum
};
export function adapt(oldClass) {
    return Adapter[oldClass];
}
