import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetStorageCellListDocument = gql `
    query GetStorageCellList($page: Int!, $pageSize: Int!, $search: String, $warehouseIds: [Long!]) {
  storageCellList(
    page: $page
    pageSize: $pageSize
    search: $search
    warehouseIds: $warehouseIds
  ) {
    data {
      address
      description
      id
      title
      warehouseId
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useGetStorageCellListQuery__
 *
 * To run a query within a React component, call `useGetStorageCellListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageCellListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageCellListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *      warehouseIds: // value for 'warehouseIds'
 *   },
 * });
 */
export function useGetStorageCellListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetStorageCellListDocument, options);
}
export function useGetStorageCellListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetStorageCellListDocument, options);
}
export function useGetStorageCellListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetStorageCellListDocument, options);
}
export const StorageCellLoadDocument = gql `
    query StorageCellLoad($warehouseId: Long!, $storageSectionIds: [Long!]) {
  storageCellLoad(
    warehouseId: $warehouseId
    storageSectionIds: $storageSectionIds
  ) {
    storageCellAddress
    storageCellId
    storageCellLoadPercent
    storageCellSkuCount
    storageCellTitle
    storageSectionAddress
    storageSectionId
  }
}
    `;
/**
 * __useStorageCellLoadQuery__
 *
 * To run a query within a React component, call `useStorageCellLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageCellLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageCellLoadQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      storageSectionIds: // value for 'storageSectionIds'
 *   },
 * });
 */
export function useStorageCellLoadQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StorageCellLoadDocument, options);
}
export function useStorageCellLoadLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StorageCellLoadDocument, options);
}
export function useStorageCellLoadSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(StorageCellLoadDocument, options);
}
export const StorageCellDocument = gql `
    query StorageCell($warehouseId: Long!, $storageCellId: Long!) {
  storageCell(warehouseId: $warehouseId, storageCellId: $storageCellId) {
    address
    description
    id
    title
    storageCellType {
      cellDepth
      cellHeight
      cellWeight
      cellWidth
      description
      id
      title
    }
    storageSection {
      address
      description
      id
      title
      storageSectionType {
        description
        icon
        id
        separator
        title
      }
    }
  }
}
    `;
/**
 * __useStorageCellQuery__
 *
 * To run a query within a React component, call `useStorageCellQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageCellQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageCellQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      storageCellId: // value for 'storageCellId'
 *   },
 * });
 */
export function useStorageCellQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StorageCellDocument, options);
}
export function useStorageCellLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StorageCellDocument, options);
}
export function useStorageCellSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(StorageCellDocument, options);
}
export const StorageCellTypeListDocument = gql `
    query StorageCellTypeList($warehouseId: Long!, $page: Int!, $pageSize: Int!, $search: String) {
  storageCellTypeList(
    warehouseId: $warehouseId
    page: $page
    pageSize: $pageSize
    search: $search
  ) {
    data {
      id
      cellDepth
      cellHeight
      cellWeight
      cellWidth
      description
      title
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useStorageCellTypeListQuery__
 *
 * To run a query within a React component, call `useStorageCellTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageCellTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageCellTypeListQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useStorageCellTypeListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StorageCellTypeListDocument, options);
}
export function useStorageCellTypeListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StorageCellTypeListDocument, options);
}
export function useStorageCellTypeListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(StorageCellTypeListDocument, options);
}
export const StorageCellTypeDocument = gql `
    query StorageCellType($warehouseId: Long!, $storageCellId: Long!) {
  storageCellType(warehouseId: $warehouseId, storageCellId: $storageCellId) {
    id
    cellDepth
    cellHeight
    cellWeight
    cellWidth
    description
    title
  }
}
    `;
/**
 * __useStorageCellTypeQuery__
 *
 * To run a query within a React component, call `useStorageCellTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageCellTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageCellTypeQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      storageCellId: // value for 'storageCellId'
 *   },
 * });
 */
export function useStorageCellTypeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StorageCellTypeDocument, options);
}
export function useStorageCellTypeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StorageCellTypeDocument, options);
}
export function useStorageCellTypeSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(StorageCellTypeDocument, options);
}
