import React from 'react';
import PaginationPanel from '../../components/PaginationPanel';
const WarehouseSectionTypesPaginationContainer = (props) => {
    const { pagination, isLoading, setPagination } = props;
    const handlerChange = (nextPage, nextPageSize) => {
        setPagination({
            page: nextPage,
            pageSize: nextPageSize,
        });
    };
    if (!!pagination) {
        return React.createElement(PaginationPanel, { pagination: pagination, disabled: isLoading, onChange: handlerChange });
    }
    return null;
};
export default WarehouseSectionTypesPaginationContainer;
