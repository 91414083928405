import moment from "moment";
import ProductDataType from "../../constants/enum/ProductDataType";
import { getProductTypeAttributesObject } from "../../helpers/function";
import { DataType } from "../../api/graphql/common";
export const productFormValuesToRequest = (values, type) => {
    const attrsInfo = getProductTypeAttributesObject(type);
    const attributes = {};
    for (const key in values.attributes) {
        const value = values.attributes[key];
        switch (attrsInfo[key].dataType) {
            case ProductDataType.BOOLEAN:
            case DataType.Boolean:
                attributes[key] = Boolean(value);
                break;
            case ProductDataType.DATE:
            case DataType.Date:
                attributes[key] = value ? moment(value).format("YYYY-MM-DD") : "";
                break;
            case ProductDataType.DATE_TIME:
            case DataType.DateTime:
                attributes[key] = value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
                break;
            case ProductDataType.HANDBOOK:
            case DataType.Handbook:
                if (typeof value === "object") {
                    attributes[key] = value.value.toString();
                }
                else {
                    attributes[key] = value.toString();
                }
                break;
            case ProductDataType.FLOAT:
            case ProductDataType.INTEGER:
            case ProductDataType.VARCHAR:
            case DataType.Float:
            case DataType.Varchar:
            case DataType.Integer:
                attributes[key] = (value !== null && value !== void 0 ? value : "").toString();
                break;
            default:
                break;
        }
    }
    return attributes;
};
