import { Form, Input } from 'antd';
import React from 'react';
import WarehouseSectionSelect from '../WarehouseSectionSelect';
import WarehouseSectionTypeSelect from '../WarehouseSectionTypeSelect';
var FormNames;
(function (FormNames) {
    FormNames["ID"] = "id";
    FormNames["PARENT_ID"] = "parentId";
    FormNames["TITLE"] = "title";
    FormNames["DESCRIPTION"] = "description";
    FormNames["SECTION_TYPE"] = "storageSectionTypeId";
})(FormNames || (FormNames = {}));
const WarehouseSectionForm = (props) => {
    const { warehouseId, form, onSubmit, initialValues, } = props;
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues },
        React.createElement(Form.Item, { name: FormNames.ID, hidden: true },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435", name: FormNames.TITLE },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { name: FormNames.DESCRIPTION, label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" },
            React.createElement(Input.TextArea, null)),
        React.createElement(Form.Item, { name: FormNames.PARENT_ID, label: "\u0420\u043E\u0434\u0438\u0442\u0435\u043B\u044C\u0441\u043A\u0430\u044F \u0441\u0435\u043A\u0446\u0438\u044F" },
            React.createElement(WarehouseSectionSelect, { warehouseId: warehouseId })),
        React.createElement(Form.Item, { name: FormNames.SECTION_TYPE, label: "\u0422\u0438\u043F \u0441\u0435\u043A\u0446\u0438\u0438" },
            React.createElement(WarehouseSectionTypeSelect, { warehouseId: warehouseId }))));
};
export default WarehouseSectionForm;
