import React from "react";
import { Outlet } from "react-router-dom";
import MDIcon from "../components/ui/MDIcon";
import { links } from "../constants/links";
import AppLayoutContainer from "../core/containers/AppLayoutContainer";
import NomenclaturePageContainer from "../core/containers/NomenclaturePageContainer/NomenclaturePageContainer";
import AccountsGroupsPage from "../core/pages/AccountsGroupsPage";
import AccountsUsersPage from "../core/pages/AccountsUsersPage";
import HandbookPage from "../core/pages/HandbookPage";
import NotFoundPage from "../core/pages/NotFoundPage";
import StatusConfiguratorPage from "../core/pages/StatusConfiguratorPage";
import ClientListPage from "../core/pages/client/ClientListPage";
import HandbookUnitAddPage from "../core/pages/handbookUnits/HandbookUnitAddPage";
import HandbookUnitItemPage from "../core/pages/handbookUnits/HandbookUnitItemPage";
import HandbookUnitsPage from "../core/pages/handbookUnits/HandbookUnitsPage";
import HandbookWarehouseAddPage from "../core/pages/handbookWarehouses/HandbookWarehouseAddPage";
import HandbookWarehouseTopologyPage from "../core/pages/handbookWarehouses/HandbookWarehouseTopologyPage/HandbookWarehouseTopologyPage";
import HandbookWarehousesItemPage from "../core/pages/handbookWarehouses/HandbookWarehousesItemPage";
import HandbookWarehousesPage from "../core/pages/handbookWarehouses/HandbookWarehousesPage/HandbookWarehousesPage";
import WarehouseCellsTypePage from "../core/pages/handbookWarehouses/WarehouseCellsTypePage/WarehouseCellsTypePage";
import WarehouseSectionsTypePage from "../core/pages/handbookWarehouses/WarehouseSectionsTypePage/WarehouseSectionsTypePage";
import PackingPage from "../core/pages/packing/PackingPage";
import ProductAddPage from "../core/pages/product/ProductAddPage";
import ProductAttributeAddPage from "../core/pages/product/ProductAttributeAddPage";
import ProductAttributeEditPage from "../core/pages/product/ProductAttributeEditPage";
import ProductEditPage from "../core/pages/product/ProductEditPage";
import ProductEditTypePage from "../core/pages/product/ProductEditTypePage";
import ProductsPage from "../core/pages/product/ProductsPage";
import MovementForPeriodPage from "../core/pages/reports/MovementForPeriodPage";
import taskChangeWarehouseRoutes from "./taskChangeWarehouseRoutes";
import taskCorrectionRoutes from "./taskCorrectionRoutes";
import taskInventoryRoutes from "./taskInventoryRoutes";
import taskLoadingRoutes from "./taskLoadingRoutes";
import taskMovementRoutes from "./taskMovementRoutes";
import taskPartitionRoutes from "./taskPartitionRoutes";
import taskShippingRoutes from "./taskShippingRoutes";
import taskWriteOffRoutes from "./taskWriteOffRoutes";
import PackingSetAddPage from "../core/pages/packing/subpage/PackingSetAddPage/PackingSetAddPage";
import PackingSetEditPage from "../core/pages/packing/subpage/PackingSetEditPage/PackingSetEditPage";
import ProductAddTypePage from "../core/pages/product/ProductAddTypePage";
import PackingAddPage from "../core/pages/packing/subpage/PackingAddPage/PackingAddPage";
import { PackingEditPage } from "../core/pages/packing/subpage/PackingEditPage/PackingEditPage";
import { DashboardPage } from "../core/pages/DashboardPage/DashboardPage";
import { HandbookWarehouseTopologyEditorPage } from "../core/pages/handbookWarehouses/HandbookWarehouseTopologyEditorPage/HandbookWarehouseTopologyEditorPage";
import { ProductAutomatizationPage } from "../core/pages/product/automatization/ProductExpirationDate";
import { AvailabilityOnDatePage } from "../pages/Reports/AvailabilityOnDate/AvailabilityOnDatePage";
const routes = [
    {
        title: "Главная",
        path: links.homeLink.url(),
        element: React.createElement(AppLayoutContainer, null),
        breadcrumb: React.createElement(MDIcon, { icon: "home" }),
        children: [
            {
                index: true,
                element: React.createElement(DashboardPage, null),
            },
            taskLoadingRoutes,
            taskInventoryRoutes,
            taskMovementRoutes,
            taskPartitionRoutes,
            taskWriteOffRoutes,
            taskPartitionRoutes,
            taskShippingRoutes,
            taskChangeWarehouseRoutes,
            taskCorrectionRoutes,
            {
                title: "Номенклатура",
                path: links.nomenclatureLink.url(),
                element: React.createElement(NomenclaturePageContainer, null),
            },
            {
                title: "Движение за период",
                path: links.reportMovementForPeriodLink.url(),
                element: React.createElement(MovementForPeriodPage, null),
            },
            // {
            //   title: "Наличие на дату - старый",
            //   path: links.reportAvailabilityOnDateLinkOld.url(),
            //   element: <AvailabilityOnDatePageOld />,
            // },
            {
                title: "Наличие на дату",
                path: links.reportAvailabilityOnDateLink.url(),
                element: React.createElement(AvailabilityOnDatePage, null),
            },
            {
                title: "Динамический справочник",
                path: links.handbookDynamicLink.url(),
                element: React.createElement(HandbookPage, null),
            },
            {
                title: "Единицы измерения",
                path: links.handbookUnitLink.path,
                element: React.createElement(Outlet, null),
                children: [
                    {
                        index: true,
                        element: React.createElement(HandbookUnitsPage, null),
                    },
                    {
                        title: "Единица измерения",
                        path: links.handbookUnitItemLink.url(":id"),
                        element: React.createElement(HandbookUnitItemPage, null),
                    },
                    {
                        title: "Добавить единицу измерения",
                        breadcrumb: "Добавить",
                        path: links.handbookUnitAddLink.url(),
                        element: React.createElement(HandbookUnitAddPage, null),
                    },
                ],
            },
            {
                title: "Наборы упаковок",
                path: links.handbookPackingLink.url(),
                element: React.createElement(Outlet, null),
                children: [
                    {
                        index: true,
                        element: React.createElement(PackingPage, null),
                    },
                    {
                        title: "Новый набор упаковок",
                        path: links.handbookPackingSetAddLink.url(),
                        element: React.createElement(PackingSetAddPage, null),
                    },
                    {
                        title: "Редактировать набор упаковок #:typeId",
                        path: links.handbookPackingSetEditLink.url(":typeId"),
                        element: React.createElement(PackingSetEditPage, null),
                    },
                    {
                        title: "Новая упаковка",
                        path: links.handbookPackingAddLink.url(":packingSetId"),
                        element: React.createElement(PackingAddPage, null),
                    },
                    {
                        title: "Редактировать упаковку #:packingId",
                        path: links.handbookPackingEditLink.url(":packingId"),
                        element: React.createElement(PackingEditPage, null),
                    },
                ],
            },
            {
                title: "Продукция",
                path: links.handbookProductLink.url(),
                element: React.createElement(Outlet, null),
                children: [
                    {
                        index: true,
                        element: React.createElement(ProductsPage, null),
                    },
                    {
                        title: "Новый тип",
                        path: links.handbookProductAddTypeLink.url(),
                        element: React.createElement(ProductAddTypePage, null),
                    },
                    {
                        title: "Тип #:typeId",
                        path: links.handbookProductEditTypeLink.url(":typeId"),
                        element: React.createElement(Outlet, null),
                        children: [
                            {
                                index: true,
                                element: React.createElement(ProductEditTypePage, null),
                            },
                            {
                                title: "Автоматизация #:autoId",
                                path: links.automatizationLink.url(":typeId", ":autoId"),
                                element: React.createElement(ProductAutomatizationPage, null),
                            },
                            {
                                title: "Редактировать аттрибут #:productAttrId",
                                path: links.handbookProductAttrEditLink.url(":typeId", ":productAttrId"),
                                element: React.createElement(ProductAttributeEditPage, null),
                            },
                            {
                                title: "Новый аттрибут",
                                path: links.handbookProductAttrAddLink.url(":typeId"),
                                element: React.createElement(ProductAttributeAddPage, null),
                            },
                        ],
                    },
                    {
                        title: "Новая продукция",
                        path: links.handbookProductAddLink.url(),
                        element: React.createElement(ProductAddPage, null),
                    },
                    {
                        title: "Продукт #:productId",
                        path: links.handbookProductEditLink.url(":productId"),
                        element: React.createElement(ProductEditPage, null),
                    },
                ],
            },
            {
                title: "Контрагенты",
                path: links.handbookClientsLink.path,
                element: React.createElement(ClientListPage, null),
            },
            {
                title: "Склады",
                path: links.handbookWarehouseListLink.path,
                element: React.createElement(Outlet, null),
                children: [
                    {
                        index: true,
                        element: React.createElement(HandbookWarehousesPage, null),
                    },
                    {
                        title: "Склад #:id - Топология",
                        breadcrumb: "#:id",
                        path: links.handbookWarehouseLink.url(":id"),
                        element: React.createElement(Outlet, null),
                        children: [
                            {
                                index: true,
                                element: React.createElement(HandbookWarehouseTopologyPage, null),
                            },
                            {
                                title: "Склад #:id - Информация",
                                breadcrumb: "Информация",
                                path: links.handbookWarehouseInfoLink.url(":id"),
                                element: React.createElement(HandbookWarehousesItemPage, null),
                            },
                            {
                                title: "Склад #:id - Типы секций",
                                breadcrumb: "Типы секций",
                                path: links.handbookWarehouseSectionsTypeLink.path,
                                element: React.createElement(WarehouseSectionsTypePage, null),
                            },
                            {
                                title: "Склад #:id - Типы ячеек",
                                breadcrumb: "Типы ячеек",
                                path: links.handbookWarehouseCellsTypeLink.path,
                                element: React.createElement(WarehouseCellsTypePage, null),
                            },
                            {
                                title: "Склад #:id - Топология",
                                breadcrumb: "Редактор",
                                path: links.handbookWarehouseTopologyEditor.path,
                                element: React.createElement(HandbookWarehouseTopologyEditorPage, null),
                            },
                        ],
                    },
                    {
                        title: "Добавить склад",
                        breadcrumb: "Добавить",
                        path: links.handbookWarehouseAddLink.url(),
                        element: React.createElement(HandbookWarehouseAddPage, null),
                    },
                ],
            },
            {
                title: "Аккаунты",
                path: links.accountsUsersLink.url(),
                element: React.createElement(AccountsUsersPage, null),
            },
            {
                title: "Группы",
                path: links.accountsGroupsLink.url(),
                element: React.createElement(AccountsGroupsPage, null),
            },
            {
                title: "Конфигуратор статусов",
                path: links.statusConfigurator.url(":objectClass"),
                element: React.createElement(StatusConfiguratorPage, null),
            },
            {
                title: "Страница не найдена",
                path: "*",
                element: React.createElement(NotFoundPage, null),
            },
        ],
    },
];
export default routes;
