export const flatWarehouseSectionDto = (section, includeRoot = true) => {
    var _a;
    const flatChilds = ((_a = section.storageSections) === null || _a === void 0 ? void 0 : _a.map((item) => flatWarehouseSectionDto(item))) || [];
    return flatChilds.reduce((acc, item) => ({
        storageSections: [...acc.storageSections, ...item.storageSections],
        storageCells: [...acc.storageCells, ...item.storageCells],
    }), {
        storageSections: includeRoot ? [section] : [],
        storageCells: section.storageCells || [],
    });
};
