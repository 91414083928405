import { Card, Col, Divider, Row, Tabs } from "antd";
import React from "react";
import Page from "../../../components/Page";
import { ProductTypeAttributeSortTables } from "../../../components/ProductTypeAttrCategories/ProductTypeAttributeSortTables";
import ProductTypeAttrCategoriesContainer from "../../containers/ProductTypeAttrCategoriesContainer";
import ProductTypeAttrCatModal from "../../containers/ProductTypeAttrCategoryModal";
import ProductTypeEditFormContainer from "../../containers/ProductTypeEditFormContainer";
import { ProductAutomatizationListPage } from "./automatization/ProductAutomatizationListPage";
const { TabPane } = Tabs;
const ProductEditTypePage = () => (React.createElement(Page, null,
    React.createElement(Card, null,
        React.createElement(Tabs, { defaultActiveKey: "1", style: { marginBottom: 32 } },
            React.createElement(TabPane, { tab: "\u0422\u0438\u043F \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", key: "1" },
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(Card, null,
                            React.createElement(Divider, null),
                            React.createElement(ProductTypeEditFormContainer, null))))),
            React.createElement(TabPane, { tab: "\u0421\u0432\u043E\u0439\u0441\u0442\u0432\u0430 \u0442\u0438\u043F\u0430", key: "2" },
                React.createElement(ProductTypeAttrCategoriesContainer, null)),
            React.createElement(TabPane, { tab: "\u0421\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u043A\u0430", key: "3" },
                React.createElement(ProductTypeAttributeSortTables, null)),
            React.createElement(TabPane, { tab: "\u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0437\u0430\u0446\u0438\u0438", key: "4" },
                React.createElement(ProductAutomatizationListPage, null))),
        React.createElement(ProductTypeAttrCatModal, null))));
export default ProductEditTypePage;
