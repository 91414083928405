import { useSearchParams } from 'react-router-dom';
const PRODUCT_DRAWER_PARAM = 'prodInfoId';
export const useProductDrawer = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const productParam = searchParams.get(PRODUCT_DRAWER_PARAM);
    const productId = productParam ? +productParam : null;
    const openProductDrawer = (id) => {
        searchParams.set(PRODUCT_DRAWER_PARAM, id.toString());
        setSearchParams(searchParams);
    };
    const closeProductDrawer = () => {
        searchParams.delete(PRODUCT_DRAWER_PARAM);
        setSearchParams(searchParams);
    };
    return {
        openProductDrawer,
        closeProductDrawer,
        productId,
    };
};
