import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../../../components/Page';
import ObjectClass from '../../../../constants/enum/ObjectClass';
import { links } from '../../../../constants/links';
import CommentsContainer from '../../../containers/CommentsContainer';
import FilesContainer from '../../../containers/FilesContainer';
import SkuInfoDrawerContainer from '../../../containers/SkuInfoDrawerContainer';
import MovementDetailNomenclatureContainer from '../../../containers/task/movement/MovementDetailNomenclatureContainer';
import MovementStatusHistoryContainer from '../../../containers/task/movement/MovementStatusHistoryContainer';
import MovementTaskContainer from '../../../containers/task/movement/MovementTaskContainer';
import MovementTaskHeaderContainer from '../../../containers/task/movement/MovementTaskHeaderContainer';
import { useTaskMovement } from '../../../../hooks/api/taskMovementApi/useTaskMovement';
const MovementTaskPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const objectInfo = {
        objectClass: ObjectClass.TaskMovement,
        objectId: taskId,
    };
    const { task } = useTaskMovement();
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const handlerChangeTask = () => navigate(links.taskMovementLinks.edit.url(taskId));
    const pageProps = {
        toolbarTop: React.createElement(MovementTaskHeaderContainer, { isLoading: task.loading, task: task.data, id: taskId, onChangeTask: handlerChangeTask, refetch: task.refetch }),
        toolbarRight: React.createElement(CommentsContainer, Object.assign({}, objectInfo)),
    };
    const containerProps = {
        id: taskId,
        nomenclatureTab: React.createElement(MovementDetailNomenclatureContainer, { loading: task === null || task === void 0 ? void 0 : task.loading, task: task === null || task === void 0 ? void 0 : task.data }),
        filesTab: React.createElement(FilesContainer, Object.assign({}, objectInfo)),
        historyTab: React.createElement(MovementStatusHistoryContainer, { taskId: taskId }),
    };
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(MovementTaskContainer, Object.assign({ task: task.data, isLoading: task.loading }, containerProps)),
        React.createElement(SkuInfoDrawerContainer, null)));
};
export default MovementTaskPage;
