import React from 'react';
import classNames from 'classnames';
const Toolbar = (props) => {
    const { children, mainClass, toggle } = props;
    const classList = classNames({
        [mainClass]: true,
        show: toggle !== null && toggle !== void 0 ? toggle : false,
    });
    if (children) {
        return (React.createElement("div", { className: classList }, children));
    }
    return null;
};
export default Toolbar;
