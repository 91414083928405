import { Select } from "antd";
import React, { useMemo } from "react";
import { productTypeGraphqlApi } from "../../../../../redux/api/productApi/productType";
const { useFetchProductTypeQuery } = productTypeGraphqlApi;
function filterProductTypeAttributes(categories) {
    return categories.flatMap((category) => category.productTypeAttributes || []);
}
export const ProductTypeAttributeSelect = ({ typeId, onChange, value, disabled, placeholder }) => {
    const { data, isLoading: typeIsLoading } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    const attr = useMemo(() => filterProductTypeAttributes((data === null || data === void 0 ? void 0 : data.productTypeCategories) || []).map((i) => ({ label: i.title, value: i.id })), [JSON.stringify(data === null || data === void 0 ? void 0 : data.productTypeCategories)]);
    return React.createElement(Select, { key: JSON.stringify(data === null || data === void 0 ? void 0 : data.id), placeholder: placeholder, disabled: disabled, loading: typeIsLoading, options: attr, onChange: onChange, value: (!value && !(data === null || data === void 0 ? void 0 : data.id)) || typeIsLoading ? undefined : value });
};
