export var ApplyPolicy;
(function (ApplyPolicy) {
    ApplyPolicy["AfterResolver"] = "AFTER_RESOLVER";
    ApplyPolicy["BeforeResolver"] = "BEFORE_RESOLVER";
    ApplyPolicy["Validation"] = "VALIDATION";
})(ApplyPolicy || (ApplyPolicy = {}));
export var DataType;
(function (DataType) {
    DataType["Boolean"] = "BOOLEAN";
    DataType["Color"] = "COLOR";
    DataType["Date"] = "DATE";
    DataType["DateTime"] = "DATE_TIME";
    DataType["Float"] = "FLOAT";
    DataType["Formula"] = "FORMULA";
    DataType["Handbook"] = "HANDBOOK";
    DataType["Integer"] = "INTEGER";
    DataType["Varchar"] = "VARCHAR";
})(DataType || (DataType = {}));
export var EventAction;
(function (EventAction) {
    EventAction["Edit"] = "EDIT";
})(EventAction || (EventAction = {}));
export var FileObjectClass;
(function (FileObjectClass) {
    FileObjectClass["Sku"] = "SKU";
    FileObjectClass["TaskChangeWarehouse"] = "TASK_CHANGE_WAREHOUSE";
    FileObjectClass["TaskCorrection"] = "TASK_CORRECTION";
    FileObjectClass["TaskInventory"] = "TASK_INVENTORY";
    FileObjectClass["TaskLoading"] = "TASK_LOADING";
    FileObjectClass["TaskMovement"] = "TASK_MOVEMENT";
    FileObjectClass["TaskPartition"] = "TASK_PARTITION";
    FileObjectClass["TaskShipping"] = "TASK_SHIPPING";
    FileObjectClass["TaskWriteOff"] = "TASK_WRITE_OFF";
})(FileObjectClass || (FileObjectClass = {}));
export var InventoryByType;
(function (InventoryByType) {
    InventoryByType["Product"] = "PRODUCT";
    InventoryByType["Sku"] = "SKU";
    InventoryByType["StorageCell"] = "STORAGE_CELL";
    InventoryByType["Warehouse"] = "WAREHOUSE";
})(InventoryByType || (InventoryByType = {}));
export var MovementType;
(function (MovementType) {
    MovementType["CreateTask"] = "CREATE_TASK";
    MovementType["CreateTaskAndClose"] = "CREATE_TASK_AND_CLOSE";
})(MovementType || (MovementType = {}));
export var SkuStatus;
(function (SkuStatus) {
    SkuStatus["Blocked"] = "BLOCKED";
    SkuStatus["Deleted"] = "DELETED";
    SkuStatus["Draft"] = "DRAFT";
    SkuStatus["Free"] = "FREE";
    /** @deprecated Legacy.Не используется, к удалению */
    SkuStatus["InWork"] = "IN_WORK";
    SkuStatus["Reserved"] = "RESERVED";
    SkuStatus["Shipped"] = "SHIPPED";
    /** @deprecated Legacy.Не используется, к удалению */
    SkuStatus["Sold"] = "SOLD";
    SkuStatus["TaskChangeWarehouse"] = "TASK_CHANGE_WAREHOUSE";
    SkuStatus["TaskCorrection"] = "TASK_CORRECTION";
    SkuStatus["TaskInventory"] = "TASK_INVENTORY";
    SkuStatus["TaskLoading"] = "TASK_LOADING";
    SkuStatus["TaskMovement"] = "TASK_MOVEMENT";
    SkuStatus["TaskPartition"] = "TASK_PARTITION";
    SkuStatus["TaskShipping"] = "TASK_SHIPPING";
    /** @deprecated Заенено на TaskChangeWarehouse */
    SkuStatus["TaskTransfer"] = "TASK_TRANSFER";
    SkuStatus["WrittenOf"] = "WRITTEN_OF";
})(SkuStatus || (SkuStatus = {}));
export var VectorStyle;
(function (VectorStyle) {
    VectorStyle["Fill"] = "FILL";
    VectorStyle["Stroke"] = "STROKE";
    VectorStyle["StrokeLinecap"] = "STROKE_LINECAP";
    VectorStyle["StrokeWidth"] = "STROKE_WIDTH";
})(VectorStyle || (VectorStyle = {}));
export var VectorTransform;
(function (VectorTransform) {
    VectorTransform["Rotate"] = "ROTATE";
    VectorTransform["Scale"] = "SCALE";
    VectorTransform["Translate"] = "TRANSLATE";
})(VectorTransform || (VectorTransform = {}));
