var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from "react";
import { Button, Col, Modal, Row, Tabs } from "antd";
import LoadingState from "../../../constants/enum/LoadingState";
import ProductFilter from "../ProductFilter/ProductFilter";
import { getDefaultPaginationEntity } from "../../../entities/PaginationEntity";
import PaginationPanel from "../../PaginationPanel";
import SearchModalTab from "./SearchModalTab";
const { TabPane } = Tabs;
const tabStyle = {
    height: "645px",
};
const defaultState = {
    attributes: [],
    items: {
        list: [],
        pagination: getDefaultPaginationEntity(15),
    },
    selectedItems: [],
    loadingState: LoadingState.Ready,
    filterValues: {},
};
class SearchModal extends Component {
    constructor(props) {
        super(props);
        this.filterForm = React.createRef();
        this.handlerClose = () => {
            var _a;
            const { setModelVisible } = this.props;
            setModelVisible(false);
            (_a = this.filterForm.current) === null || _a === void 0 ? void 0 : _a.resetFields();
            this.setState(defaultState);
        };
        this.handlerSetAttributes = (list) => {
            this.setState({
                loadingState: LoadingState.Loading,
                attributes: list,
            });
        };
        this.handlerFilterChange = (values) => __awaiter(this, void 0, void 0, function* () {
            this.setState((state) => ({
                items: Object.assign(Object.assign({}, state.items), { pagination: defaultState.items.pagination }),
                // eslint-disable-next-line react/no-unused-state
                filterValues: values,
            }));
            setTimeout(this.handlerSearch, 0);
        });
        this.handlerPaginationChange = (page, itemsOnPage) => __awaiter(this, void 0, void 0, function* () {
            this.setState((state) => ({
                items: Object.assign(Object.assign({}, state.items), { pagination: Object.assign(Object.assign({}, state.items.pagination), { page, itemsOnPage: itemsOnPage !== null && itemsOnPage !== void 0 ? itemsOnPage : state.items.pagination.itemsOnPage }) }),
            }));
            setTimeout(this.handlerSearch, 0);
        });
        this.handlerSelect = (idList, selected, isMultiple) => {
            const { items, selectedItems } = this.state;
            const itemList = items === null || items === void 0 ? void 0 : items.list;
            if (selected) {
                if (isMultiple) {
                    const addedItems = itemList === null || itemList === void 0 ? void 0 : itemList.filter((item) => idList.reduce((acc, id) => acc || id === item.id, false));
                    this.setState((state) => ({
                        selectedItems: [...state.selectedItems, ...(addedItems || [])],
                    }));
                }
                else {
                    const addedItems = itemList === null || itemList === void 0 ? void 0 : itemList.filter((item) => idList.some((id) => id === item.id));
                    this.setState({
                        selectedItems: addedItems || [],
                    });
                }
            }
            else {
                if (isMultiple) {
                    const newSelectedItems = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.filter((item) => !idList.reduce((acc, id) => acc || id === item.id, false));
                    this.setState({
                        selectedItems: [...newSelectedItems],
                    });
                }
                else {
                    const newSelectedItems = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.filter((item) => !idList.some((id) => id === item.id));
                    this.setState({
                        selectedItems: newSelectedItems,
                    });
                }
            }
        };
        this.renderFooter = () => {
            const { items, loadingState } = this.state;
            const { pagination } = items;
            return React.createElement(PaginationPanel, { style: { marginTop: "20px" }, pagination: pagination, disabled: loadingState === LoadingState.Loading, onChange: this.handlerPaginationChange });
        };
        this.state = defaultState;
    }
    render() {
        const { visible, filterIncludePersonal } = this.props;
        const { attributes, items, selectedItems } = this.state;
        return (React.createElement(Modal, { title: "Поиск номенклатуры", open: visible, onCancel: this.handlerClose, width: 1600, bodyStyle: { margin: "0px -24px -20px -24px" }, footer: null },
            React.createElement(Row, null,
                React.createElement(Col, { flex: "auto", style: { margin: "-1px -1px 0 0", maxWidth: "calc(100% - 260px)" } },
                    React.createElement(Tabs, { defaultActiveKey: "1", type: "card", tabPosition: "bottom", tabBarStyle: { padding: "0 20px 20px" }, tabBarExtraContent: this.renderFooter() },
                        React.createElement(TabPane, { tab: React.createElement(SearchModalTab, { count: (items === null || items === void 0 ? void 0 : items.pagination.itemsTotalCount) || 0, text: "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442 \u043F\u043E\u0438\u0441\u043A\u0430" }), key: "1", style: tabStyle }, this.renderFindTable()),
                        React.createElement(TabPane, { tab: React.createElement(SearchModalTab, { count: selectedItems.length, text: "\u0412\u044B\u0431\u0440\u0430\u043D\u043E" }), key: "2", style: tabStyle }, this.renderSelectedTable()))),
                React.createElement(Col, { flex: "260px", style: { maxWidth: "260px" } },
                    React.createElement("div", { style: {
                            display: "flex",
                            height: "100%",
                            flexDirection: "column",
                        } },
                        React.createElement(ProductFilter, { productType: this.props.productType, includePersonal: filterIncludePersonal, onSetAttributes: this.handlerSetAttributes, onChange: this.handlerFilterChange, attributes: attributes, formRef: this.filterForm, style: {
                                height: tabStyle.height,
                                overflowY: "auto",
                                padding: "4px 14px 14px",
                            } }),
                        React.createElement(Button.Group, { style: {
                                padding: "0 14px",
                                flexGrow: 1,
                                alignItems: "center",
                                marginTop: "15px",
                                borderTop: "1px solid rgba(0, 0, 0, 0.06)",
                            } },
                            React.createElement(Button, { type: "primary", block: true, onClick: this.handlerOk }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"),
                            React.createElement(Button, { type: "primary", block: true, danger: true, onClick: this.handlerClose }, "\u041E\u0442\u043C\u0435\u043D\u0430")))))));
    }
}
export default SearchModal;
