import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const SkuListDocument = gql `
    query SkuList($page: Int!, $pageSize: Int!, $filter: SkuFilterInput!) {
  skuList(page: $page, pageSize: $pageSize, filter: $filter) {
    data {
      id
      isPartitioned
      quantity
      status
      hasDefect
      defect
      client {
        id
        companyName
        companyFullName
        companyInn
      }
      skuAttributes {
        productTypeAttributeId
        value
      }
      storageCell {
        id
        title
        address
      }
      warehouse {
        id
        storageTitle
      }
      product {
        productAttributes {
          productTypeAttributeId
          value
        }
        title
        titleCompiled
        unit
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useSkuListQuery__
 *
 * To run a query within a React component, call `useSkuListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkuListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSkuListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SkuListDocument, options);
}
export function useSkuListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SkuListDocument, options);
}
export function useSkuListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(SkuListDocument, options);
}
export const SkuDocument = gql `
    query Sku($skuId: Long!) {
  sku(skuId: $skuId) {
    id
    isPartitioned
    quantity
    size
    hasDefect
    defect
    client {
      id
      companyName
      companyFullName
      companyInn
    }
    product {
      title
      titleCompiled
      unit
    }
    storageCell {
      id
      title
      address
    }
    warehouse {
      id
      timeZone {
        id
        displayName
        daylightName
        standardName
        supportsDaylightSavingTime
        baseUtcOffset
      }
      storageTitle
    }
    status
    skuAttributeCategories {
      title
      skuAttributeValues {
        value
        title
        dataType
      }
    }
  }
}
    `;
/**
 * __useSkuQuery__
 *
 * To run a query within a React component, call `useSkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuQuery({
 *   variables: {
 *      skuId: // value for 'skuId'
 *   },
 * });
 */
export function useSkuQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SkuDocument, options);
}
export function useSkuLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SkuDocument, options);
}
export function useSkuSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(SkuDocument, options);
}
export const SkuHistoryDocument = gql `
    query SkuHistory($skuId: Long!) {
  skuHistory(skuId: $skuId) {
    items {
      taskId
      taskType
      updatedAt
      createdAt
      executedAt
      user {
        uid
        id
        userName
        email
      }
      warehouse {
        id
        storageTitle
      }
      status {
        title
        style
        scope
        isFinal
        relations {
          id
          needReport
          title
          style
        }
      }
    }
  }
}
    `;
/**
 * __useSkuHistoryQuery__
 *
 * To run a query within a React component, call `useSkuHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkuHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuHistoryQuery({
 *   variables: {
 *      skuId: // value for 'skuId'
 *   },
 * });
 */
export function useSkuHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SkuHistoryDocument, options);
}
export function useSkuHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SkuHistoryDocument, options);
}
export function useSkuHistorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(SkuHistoryDocument, options);
}
