var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useTaskCorrectionDetails } from "../../../../hooks/api/taskCorrectionApi/useTaskCorrectionDetails";
import { useProductDrawer } from "../../../../hooks/useProductDrawer";
import { skuActions } from "../../../../redux/reducers/skuReducer";
import { useAppDispatch } from "../../../../redux/store";
import { CorrectionDetailNomenclatureFormItemContextContainer } from "../../../conponents/task/correction/CorrectionDetailNomenclatureForm/CorrectionDetailNomenclatureFormItemContainer";
const CorrectionDetailNomenclatureFormItemContainer = (props) => {
    const dispatch = useAppDispatch();
    const { nomenclature, taskId } = props;
    const { taskDetails } = useTaskCorrectionDetails();
    const { openProductDrawer } = useProductDrawer();
    const handlerEditCreated = (value) => __awaiter(void 0, void 0, void 0, function* () {
        taskDetails.edit && (yield taskDetails.edit({ variables: { input: { taskId, request: value } } }));
        yield props.refetch();
    });
    const handlerAddCreated = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.create({ variables: { input: { taskId, request: value } } });
        yield props.refetch();
    });
    const handlerDeleteCreated = (createdId) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.delete({ variables: { input: { taskId, request: [createdId] } } });
        yield props.refetch();
    });
    const handlerOpenSkuInfo = (id) => {
        dispatch(skuActions.open(id));
    };
    const handlerOpenInfo = (id) => {
        openProductDrawer(id);
    };
    return (React.createElement(CorrectionDetailNomenclatureFormItemContextContainer, { isEdit: true, isLoading: props.isLoading || taskDetails.loading, nomenclature: nomenclature, onAddCreated: handlerAddCreated, onDeleteCreated: handlerDeleteCreated, onSkuInfoClick: handlerOpenSkuInfo, onEditCreated: handlerEditCreated, onInfoClick: handlerOpenInfo }));
};
export default CorrectionDetailNomenclatureFormItemContainer;
