import { Button, Col, Divider, Row, Spin } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductTypeAttrCategories from "../../components/ProductTypeAttrCategories";
import MDIcon from "../../components/ui/MDIcon";
import { links } from "../../constants/links";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { productActions } from "../../redux/reducers/productReducer";
import { productTypeGraphqlApi } from "../../redux/api/productApi/productType";
const { useSortProductTypeAttrCategoriesMutation, useFetchProductTypeQuery, useDeleteProductTypeAttributeMutation, useDeleteProductTypeCategoryMutation, useSortProductTypeAttributesMutation } = productTypeGraphqlApi;
const ProductTypeAttrCategoriesContainer = () => {
    const { typeId: typeParam } = useParams();
    const typeId = typeParam ? +typeParam : null;
    const { data: type, isLoading: typeIsLoading } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    const [deleteProductTypeAttribute, { isLoading: deleteAttrIsLoading }] = useDeleteProductTypeAttributeMutation();
    const [deleteProductTypeAttrCategory, { isLoading: deleteCatIsLoading }] = useDeleteProductTypeCategoryMutation();
    const [sortProductTypeAttrCategories] = useSortProductTypeAttrCategoriesMutation();
    const [sortProductTypeAttributes] = useSortProductTypeAttributesMutation();
    const isLoading = typeIsLoading || deleteAttrIsLoading || deleteCatIsLoading;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handlerAddCategory = () => {
        dispatch(productActions.changeAttrCategoryModalVisible({
            visible: true,
            initialValues: {
                productTypeId: typeId !== null && typeId !== void 0 ? typeId : -1,
            },
        }));
    };
    const handlerEditCategory = (categoryId) => {
        const category = type === null || type === void 0 ? void 0 : type.productTypeCategories.find((item) => item.id === categoryId);
        dispatch(productActions.changeAttrCategoryModalVisible({
            visible: true,
            initialValues: {
                id: category === null || category === void 0 ? void 0 : category.id,
                productTypeId: typeId !== null && typeId !== void 0 ? typeId : -1,
                title: category === null || category === void 0 ? void 0 : category.title,
            },
        }));
    };
    const handlerSortCategory = (items) => {
        if (typeId) {
            sortProductTypeAttrCategories({
                input: {
                    input: {
                        items: items,
                        parentId: typeId
                    },
                    productTypeId: typeId
                }
            });
        }
    };
    const handlerDeleteCategory = (categoryId) => {
        deleteProductTypeAttrCategory({
            input: {
                productTypeCategoryId: categoryId
            }
        });
    };
    const handlerAddAttr = (categoryId) => {
        if (typeId) {
            navigate(links.handbookProductAttrAddLink.url(typeId, categoryId));
        }
    };
    const handlerEditAttr = (attrId) => {
        if (typeId) {
            navigate(links.handbookProductAttrEditLink.url(typeId, attrId));
        }
    };
    const handlerDeleteAttr = (attrId) => {
        deleteProductTypeAttribute({
            input: {
                productTypeAttributeId: attrId
            }
        });
    };
    const handlerSortAttrs = (parentId, items) => {
        if (typeId) {
            sortProductTypeAttributes({
                input: {
                    items: items,
                    parentId: parentId
                },
                productTypeId: typeId
            });
        }
    };
    if (type) {
        return (React.createElement(Spin, { spinning: isLoading },
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "plus-circle", className: "ant-dropdown-menu-item-icon" }), onClick: handlerAddCategory }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044E"))),
            React.createElement(Divider, null),
            React.createElement(ProductTypeAttrCategories, { categoryList: type.productTypeCategories, onDelete: handlerDeleteCategory, onEdit: handlerEditCategory, onSort: handlerSortCategory, onAttrAdd: handlerAddAttr, onAttrEdit: handlerEditAttr, onAttrDelete: handlerDeleteAttr, onAttrsSort: handlerSortAttrs })));
    }
    return null;
};
export default ProductTypeAttrCategoriesContainer;
