var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Card, Row, Space, Spin, Tabs, Tooltip, Typography, message } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import Title from "antd/lib/typography/Title";
import React from "react";
import { TabinfoType } from "../../../../api/rest/dto/tasks/changeWarehouse/types";
import TaskForm from "../../../../components/task/TaskForm";
import MDIcon from "../../../../components/ui/MDIcon";
import Scope from "../../../../constants/enum/Scope";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import { checkDataStringAndConvertToData, dtoStorageToSelectAdapter, dtoContractorToSelectAdapter, removeUndefinedNullKeys, selectAdapter } from "../../../../helpers/function";
import { useProductDrawer } from "../../../../hooks/useProductDrawer";
import ChangeWarehouseTaskFormNomenclature from "../../../conponents/task/changeWarehouse/ChangeWarehouseTaskFormNomenclature";
import DestinationWarehouseSection from "../../../conponents/task/changeWarehouse/Form/DestinationWarehouseSection";
import WarehouseSection from "../../../conponents/task/changeWarehouse/Form/WarehouseSection";
import TableContainer from "../../../conponents/task/changeWarehouse/Tables/EditTable/Table";
import { useChangeWarehouseDetails } from "../../../../hooks/api/taskChangeWarehouseApi/useChangeWarehouseDetails";
import { useTaskChangeWarehouseCRUDActions } from "../../../../hooks/api/taskChangeWarehouseApi/useTaskChangeWarehouseCRUDActions";
const Text = Typography.Text;
const ChangeWarehouseTaskFormNomenclatureContainer = (props) => {
    const { taskId, task, isLoading, refetch } = props;
    const { taskDetails } = useChangeWarehouseDetails();
    const { actions } = useTaskChangeWarehouseCRUDActions();
    const { edit, isLoading: isEditTaskLoading } = actions;
    const { openProductDrawer } = useProductDrawer();
    const handlerOpenInfo = (productId) => {
        openProductDrawer(productId);
    };
    const handlerAddSku = (skuIds) => __awaiter(void 0, void 0, void 0, function* () {
        const body = skuIds.map((skuId) => ({ skuId }));
        yield taskDetails.create({
            variables: {
                input: body,
                taskId,
            },
        });
        yield refetch();
    });
    const handlerFinish = (input) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { warehouseId, destinationWarehouseId } = input, rest = __rest(input, ["warehouseId", "destinationWarehouseId"]);
            yield edit(removeUndefinedNullKeys(selectAdapter(rest)), taskId);
            yield refetch();
        }
        catch (e) {
            message.error("Ошибка при сохранении задачи");
        }
    });
    console.log("task", task);
    const taskForm = (React.createElement(Spin, { spinning: isEditTaskLoading },
        React.createElement(TaskForm, { key: task === null || task === void 0 ? void 0 : task.id, onFinish: handlerFinish, taskForm: React.createElement(Tabs, { type: "card", defaultActiveKey: TabinfoType.WAREHOUSE, style: { width: "100%", border: "1px solid #f0f0f0" }, tabBarStyle: { marginBottom: -2, zIndex: 1 } },
                React.createElement(TabPane, { key: TabinfoType.WAREHOUSE, tab: "\u0421\u043A\u043B\u0430\u0434 \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u044F" },
                    React.createElement(Row, null,
                        React.createElement(WarehouseSection, { disableWarehouse: true }))),
                React.createElement(TabPane, { key: TabinfoType.DESTINATION_WAREHOUSE, tab: "\u0421\u043A\u043B\u0430\u0434 \u043D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F" },
                    React.createElement(Row, null,
                        React.createElement(DestinationWarehouseSection, { disableWarehouse: true })))), initialValues: dtoContractorToSelectAdapter(dtoStorageToSelectAdapter(checkDataStringAndConvertToData(task))) })));
    return (React.createElement(ChangeWarehouseTaskFormNomenclature, { onAddSku: handlerAddSku, editable: task ? taskHelpers.hasScope(task, Scope.DETAILS_EDIT) : false, isLoading: !task || isLoading || taskDetails.loading, warehouseId: (task === null || task === void 0 ? void 0 : task.warehouse) ? [`${task === null || task === void 0 ? void 0 : task.warehouse.id}`] : [], onAddCell: handlerAddSku, onAddProduct: handlerAddSku },
        React.createElement(React.Fragment, null,
            taskForm, task === null || task === void 0 ? void 0 :
            task.skuGroup.map((sku, index) => {
                var _a, _b;
                const attributes = (_a = sku.attributes) === null || _a === void 0 ? void 0 : _a.filter((attr) => attr.isPersonal).map((el) => ({ title: el.title, unit: el.unit, id: el.id }));
                return (React.createElement(Card, { key: index, type: "inner", title: React.createElement(Space, { style: { width: "auto" } },
                        React.createElement(Title, { level: 4 }, sku.type),
                        React.createElement("div", { className: "mb-2" },
                            React.createElement(Text, { underline: true }, sku.title))), extra: React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u0442\u0438\u043F\u0435 \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                        React.createElement(Button, { type: "primary", size: "small", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: () => {
                                handlerOpenInfo(sku.productId);
                            } })) },
                    React.createElement(TableContainer, { destinationWarehouseId: (_b = task === null || task === void 0 ? void 0 : task.destinationWarehouse) === null || _b === void 0 ? void 0 : _b.id, taskId: taskId, refetch: refetch, attrebutes: attributes, taskSkus: sku.skus })));
            }))));
};
export default ChangeWarehouseTaskFormNomenclatureContainer;
