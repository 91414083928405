import { SkuStatus as SkuStatusDto } from "../../../../api/graphql/common";
import { getPropsById } from "../../../../components/SkuStatusTag/SkuStatusTag";
import { COMMON_ATTRIBUTES, PERSONAL_ATTRIBUTES, PRODUCT_ID } from "../../../../components/nomenclatureSearch/ProductFilter/ProductFilter";
import ColorPalette from "../../../../constants/enum/ColorPalette";
import LoadingState from "../../../../constants/enum/LoadingState";
import { SkuStatus } from "../../../../constants/enum/SkuStatus";
function mapSkuStatusDtoToSkuStatus(status) {
    const statusMap = {
        [SkuStatusDto.Blocked]: SkuStatus.BLOCKED,
        [SkuStatusDto.Deleted]: SkuStatus.DELETED,
        [SkuStatusDto.Draft]: SkuStatus.DRAFT,
        [SkuStatusDto.Free]: SkuStatus.FREE,
        [SkuStatusDto.InWork]: SkuStatus.IN_WORK,
        [SkuStatusDto.Reserved]: SkuStatus.RESERVED,
        [SkuStatusDto.Shipped]: SkuStatus.SHIPPED,
        [SkuStatusDto.Sold]: SkuStatus.SOLD,
        [SkuStatusDto.TaskChangeWarehouse]: SkuStatus.TASK_TRANSFER,
        [SkuStatusDto.TaskCorrection]: SkuStatus.TASK_CORRECTION,
        [SkuStatusDto.TaskInventory]: SkuStatus.TASK_INVENTORY,
        [SkuStatusDto.TaskLoading]: SkuStatus.TASK_LOADING,
        [SkuStatusDto.TaskMovement]: SkuStatus.TASK_MOVEMENT,
        [SkuStatusDto.TaskPartition]: SkuStatus.TASK_PARTITION,
        [SkuStatusDto.TaskShipping]: SkuStatus.TASK_SHIPPING,
        [SkuStatusDto.TaskTransfer]: SkuStatus.TASK_TRANSFER,
        [SkuStatusDto.WrittenOf]: SkuStatus.WRITTEN_OF,
    };
    const mappedStatus = statusMap[status];
    if (!mappedStatus) {
        throw new Error(`Unknown status: ${status}`);
    }
    return mappedStatus;
}
export const useSkuAdapter = () => {
    function adaptSkuListResponse(skuListResponse) {
        const { client, id, isPartitioned, product, quantity, hasDefect, defect, skuAttributes, status, storageCell, warehouse } = skuListResponse;
        const skuEntity = {
            isPartitioned,
            id,
            status: {
                id: mapSkuStatusDtoToSkuStatus(status),
            },
            quantity,
            hasDefect: hasDefect,
            defect: defect === null || defect === void 0 ? void 0 : defect.toString(),
            product: {
                id: -1,
                title: product.title,
                titleCompiled: product.titleCompiled,
                productAttributes: product.productAttributes.reduce((acc, attribute) => {
                    if (attribute.value) {
                        acc[attribute.productTypeAttributeId] = attribute.value;
                    }
                    return acc;
                }, {}),
            },
            warehouse: {
                id: warehouse === null || warehouse === void 0 ? void 0 : warehouse.id,
                storageType: -1,
                storageTitle: warehouse === null || warehouse === void 0 ? void 0 : warehouse.storageTitle,
            },
            storageCell: {
                id: storageCell === null || storageCell === void 0 ? void 0 : storageCell.id,
                title: storageCell === null || storageCell === void 0 ? void 0 : storageCell.title,
                address: storageCell === null || storageCell === void 0 ? void 0 : storageCell.address,
            },
            client: {
                id: client === null || client === void 0 ? void 0 : client.id,
                companyName: client === null || client === void 0 ? void 0 : client.companyName,
                companyInn: client === null || client === void 0 ? void 0 : client.companyInn,
            },
            skuAttributes: skuAttributes.reduce((acc, attribute) => {
                if (attribute.value) {
                    acc[attribute.productTypeAttributeId] = attribute.value;
                }
                return acc;
            }, {}),
            skuAttributeCategories: [],
        };
        return skuEntity;
    }
    const adaptSku = (skuDto) => {
        var _a, _b, _c, _d, _e, _f;
        const convertedEntity = {
            isPartitioned: skuDto.isPartitioned,
            id: Number(skuDto.id),
            status: { id: mapSkuStatusDtoToSkuStatus(skuDto.status), style: (_a = getPropsById(mapSkuStatusDtoToSkuStatus(skuDto.status))) === null || _a === void 0 ? void 0 : _a.color, title: (_b = getPropsById(mapSkuStatusDtoToSkuStatus(skuDto.status))) === null || _b === void 0 ? void 0 : _b.text },
            quantity: Number(skuDto.quantity),
            size: skuDto === null || skuDto === void 0 ? void 0 : skuDto.size,
            product: {
                id: 0,
                title: skuDto.product.title,
                titleCompiled: skuDto.product.titleCompiled,
                productAttributes: undefined,
            },
            hasDefect: skuDto.hasDefect,
            defect: (_c = skuDto.defect) === null || _c === void 0 ? void 0 : _c.toString(),
            warehouse: skuDto.warehouse
                ? {
                    id: Number(skuDto.warehouse.id),
                    storageType: -1,
                    storageTitle: skuDto.warehouse.storageTitle,
                }
                : undefined,
            storageCell: skuDto.storageCell
                ? {
                    id: Number(skuDto.storageCell.id),
                    title: skuDto.storageCell.title,
                    address: skuDto.storageCell.address,
                    description: "",
                    parentSectionId: -1,
                }
                : undefined,
            client: {
                id: Number((_d = skuDto === null || skuDto === void 0 ? void 0 : skuDto.client) === null || _d === void 0 ? void 0 : _d.id),
                companyName: (_e = skuDto.client) === null || _e === void 0 ? void 0 : _e.companyName,
                companyInn: (_f = skuDto.client) === null || _f === void 0 ? void 0 : _f.companyInn,
            },
            skuAttributes: undefined,
            skuAttributeCategories: skuDto.skuAttributeCategories.map((category) => ({
                title: category.title,
                skuAttributeValues: category.skuAttributeValues.map((value) => ({
                    title: value.title,
                    dataType: value.dataType,
                    value: value.value,
                    dimension: value.dimension,
                })),
            })),
        };
        return convertedEntity;
    };
    function adaptProductFilterValuesToSkuFilterInput(oldFilter) {
        const newFilter = {};
        if (oldFilter[PRODUCT_ID] !== undefined) {
            newFilter.productTypeId = oldFilter[PRODUCT_ID];
        }
        if (oldFilter[COMMON_ATTRIBUTES] !== undefined) {
            newFilter.productAttributes = Object.entries(oldFilter[COMMON_ATTRIBUTES]).reduce((acc, [key, value]) => {
                if (value && (Array.isArray(value) ? value.length : true)) {
                    acc.push({
                        productTypeAttributeId: parseInt(key),
                        values: Array.isArray(value) ? value.map(String) : [String(value)],
                    });
                }
                return acc;
            }, []);
        }
        if (oldFilter[PERSONAL_ATTRIBUTES] !== undefined) {
            newFilter.skuAttributes = Object.entries(oldFilter[PERSONAL_ATTRIBUTES]).reduce((acc, [key, value]) => {
                if (value && (Array.isArray(value) ? value.length : true)) {
                    acc.push({
                        productTypeAttributeId: parseInt(key),
                        values: Array.isArray(value) ? value.map(String) : [String(value)],
                    });
                }
                return acc;
            }, []);
        }
        if (Array.isArray(oldFilter["warehouses"])) {
            newFilter.warehouses = oldFilter["warehouses"].map((warehouse) => warehouse.value);
        }
        if (Array.isArray(oldFilter["clients"])) {
            newFilter.clients = oldFilter["clients"].map((client) => client.value);
        }
        if (Array.isArray(oldFilter["statuses"])) {
            newFilter.statuses = oldFilter["statuses"];
        }
        if (Array.isArray(oldFilter["storageCells"])) {
            newFilter.storageCells = oldFilter["storageCells"];
        }
        return newFilter;
    }
    function adaptSkuHistoryResponseToSkuHistoryEntity(response) {
        if (response.items) {
            return {
                items: response.items.map((item) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                    return ({
                        createdAt: item.createdAt || "",
                        executedAt: item.executedAt || undefined,
                        updatedAt: item.updatedAt || "",
                        status: {
                            id: ((_c = (_b = (_a = item.status) === null || _a === void 0 ? void 0 : _a.relations) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) || 0,
                            title: ((_f = (_e = (_d = item.status) === null || _d === void 0 ? void 0 : _d.relations) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.title) || "",
                            style: ((_g = item.status) === null || _g === void 0 ? void 0 : _g.style) || ColorPalette.Default,
                            needReport: ((_k = (_j = (_h = item.status) === null || _h === void 0 ? void 0 : _h.relations) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.needReport) || false,
                            scope: [], // provide a default value
                            relations: [], // provide a default value
                            loadingState: LoadingState.Ready, // provide a default value
                        },
                        taskId: item.taskId || 0,
                        taskType: item.taskType || undefined,
                        user: item.user
                            ? {
                                uid: item.user.uid || "",
                                id: item.user.id || 0,
                                email: item.user.email || undefined,
                                userName: item.user.userName || undefined,
                            }
                            : {
                                uid: "",
                                id: 0,
                            },
                        warehouse: item.warehouse
                            ? {
                                id: item.warehouse.id || 0,
                                storageType: undefined, // У нас нет данных для этого поля в ответе
                                storageTitle: item.warehouse.storageTitle || undefined,
                            }
                            : {
                                id: 0,
                            },
                    });
                }),
            };
        }
        else {
            return {
                items: [],
            };
        }
    }
    const adaptToSkuEntity = (skuDto) => {
        var _a, _b, _c, _d, _e, _f;
        return {
            id: skuDto.id,
            isPartitioned: skuDto.isPartitioned,
            quantity: skuDto.quantity,
            hasDefect: skuDto.hasDefect,
            defect: (_a = skuDto.defect) === null || _a === void 0 ? void 0 : _a.toString(),
            status: { id: mapSkuStatusDtoToSkuStatus(skuDto.status), style: (_b = getPropsById(mapSkuStatusDtoToSkuStatus(skuDto.status))) === null || _b === void 0 ? void 0 : _b.color, title: (_c = getPropsById(mapSkuStatusDtoToSkuStatus(skuDto.status))) === null || _c === void 0 ? void 0 : _c.text },
            product: {
                id: 0, // TODO: Исправьте, если у вас есть идентификатор продукта
                title: skuDto.product.title,
                titleCompiled: skuDto.product.titleCompiled,
                productAttributes: skuDto.product.productAttributes.reduce((acc, attribute) => {
                    if (attribute.value) {
                        acc[attribute.productTypeAttributeId] = attribute.value;
                    }
                    return acc;
                }, {}),
            },
            warehouse: skuDto.warehouse
                ? {
                    id: skuDto.warehouse.id,
                    storageTitle: skuDto.warehouse.storageTitle,
                }
                : undefined,
            storageCell: skuDto.storageCell
                ? {
                    id: skuDto.storageCell.id,
                    title: skuDto.storageCell.title,
                    address: skuDto.storageCell.address,
                }
                : undefined,
            client: {
                id: Number((_d = skuDto === null || skuDto === void 0 ? void 0 : skuDto.client) === null || _d === void 0 ? void 0 : _d.id),
                companyName: (_e = skuDto.client) === null || _e === void 0 ? void 0 : _e.companyName,
                companyInn: (_f = skuDto.client) === null || _f === void 0 ? void 0 : _f.companyInn,
            },
            skuAttributes: skuDto.skuAttributes.reduce((acc, attribute) => {
                if (attribute.value) {
                    acc[attribute.productTypeAttributeId] = attribute.value;
                }
                return acc;
            }, {}),
            skuAttributeCategories: [],
        };
    };
    const adaptToPaginatedResult = (paginatedDto) => {
        return {
            list: paginatedDto.data.map(adaptToSkuEntity),
            pagination: {
                page: paginatedDto.pagination.page,
                itemsTotalCount: paginatedDto.pagination.itemsTotalCount,
                itemsCurrentCount: paginatedDto.pagination.itemsCurrentCount,
                itemsOnPage: paginatedDto.pagination.itemsOnPage,
            },
        };
    };
    return { adaptSkuHistoryResponseToSkuHistoryEntity, adaptSku, adaptSkuListResponse, adaptProductFilterValuesToSkuFilterInput, adaptToPaginatedResult, adaptToSkuEntity };
};
