var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Form, InputNumber, Select } from 'antd';
import { ToleranceType } from '../../../../constants/enum/ToleranceType';
import StorageCellSelect from '../../../../components/StorageCellSelect';
const PartitionDetailNomenclatureCell = (props) => {
    const { editing, dataIndex, value, warehouseId, isAttribute, children, record } = props, restProps = __rest(props, ["editing", "dataIndex", "value", "warehouseId", "isAttribute", "children", "record"]);
    const renderEditableField = () => {
        if (editing) {
            let inputNode;
            if (record.isCreated) {
                switch (dataIndex) {
                    case 'toleranceType': {
                        inputNode = (React.createElement(Select, null,
                            React.createElement(Select.Option, { value: ToleranceType.POSITIVE }, "\u041F\u043E\u043B\u043E\u0436\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0439"),
                            React.createElement(Select.Option, { value: ToleranceType.NEGATIVE }, "\u041E\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439")));
                        break;
                    }
                    case 'storageCell': {
                        inputNode = (React.createElement(StorageCellSelect, { warehouseId: warehouseId, value: value }));
                        break;
                    }
                    default: {
                        inputNode = React.createElement(InputNumber, null);
                    }
                }
            }
            else if (dataIndex === 'storageCell') {
                inputNode = (React.createElement(StorageCellSelect, { warehouseId: warehouseId, value: value }));
            }
            else {
                return children;
            }
            const rules = [];
            rules.push({ required: true, message: 'Заполните поле' });
            const name = isAttribute ? ['attributes', dataIndex.toString()] : dataIndex;
            return (React.createElement(Form.Item, { name: name, style: { margin: 0 }, rules: rules }, inputNode));
        }
        return children;
    };
    return (React.createElement("td", Object.assign({}, restProps),
        React.createElement("span", { style: { whiteSpace: 'nowrap' } }, renderEditableField())));
};
export default PartitionDetailNomenclatureCell;
