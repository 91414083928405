export var AddChangeWarehouseFieldName;
(function (AddChangeWarehouseFieldName) {
    AddChangeWarehouseFieldName["CARRIER"] = "carrierId";
    AddChangeWarehouseFieldName["CONSIGNEE"] = "consigneeId";
    AddChangeWarehouseFieldName["DEPARTURE_ALLOWED_NAME"] = "departureAllowedName";
    AddChangeWarehouseFieldName["DEPARTURE_ALLOWED_POST"] = "departureAllowedPost";
    AddChangeWarehouseFieldName["DEPARTURE_DRIVER_NAME"] = "departureDriverName";
    AddChangeWarehouseFieldName["DEPARTURE_POWER_OF_ATTORNEY"] = "departurePowerOfAttorney";
    AddChangeWarehouseFieldName["DEPARTURE_POWER_OF_ATTORNEY_DATE"] = "departurePowerOfAttorneyDate";
    AddChangeWarehouseFieldName["DEPARTURE_PRODUCED_NAME"] = "departureProducedName";
    AddChangeWarehouseFieldName["DEPARTURE_PRODUCED_POST"] = "departureProducedPost";
    AddChangeWarehouseFieldName["DEPARTURE_VEHICLE_NUMBER"] = "departureVehicleNumber";
    AddChangeWarehouseFieldName["DESTINATION_ALLOWED_NAME"] = "destinationAllowedName";
    AddChangeWarehouseFieldName["DESTINATION_ALLOWED_POST"] = "destinationAllowedPost";
    AddChangeWarehouseFieldName["DESTINATION_DRIVER_NAME"] = "destinationDriverName";
    AddChangeWarehouseFieldName["DESTINATION_POWER_OF_ATTORNEY"] = "destinationPowerOfAttorney";
    AddChangeWarehouseFieldName["DESTINATION_POWER_OF_ATTORNEY_DATE"] = "destinationPowerOfAttorneyDate";
    AddChangeWarehouseFieldName["DESTINATION_PRODUCED_NAME"] = "destinationProducedName";
    AddChangeWarehouseFieldName["DESTINATION_PRODUCED_POST"] = "destinationProducedPost";
    AddChangeWarehouseFieldName["DESTINATION_VEHICLE_NUMBER"] = "destinationVehicleNumber";
    AddChangeWarehouseFieldName["DESTINATION_WAREHOUSE"] = "destinationWarehouseId";
    AddChangeWarehouseFieldName["PAYER"] = "payerId";
    AddChangeWarehouseFieldName["SHIPPER"] = "shipperId";
    AddChangeWarehouseFieldName["WAREHOUSE"] = "warehouseId";
})(AddChangeWarehouseFieldName || (AddChangeWarehouseFieldName = {}));
export var TabinfoType;
(function (TabinfoType) {
    TabinfoType["WAREHOUSE"] = "Warehouse";
    TabinfoType["DESTINATION_WAREHOUSE"] = "destinationWarehouse";
})(TabinfoType || (TabinfoType = {}));
