import { Button, Popconfirm, Tooltip } from "antd";
import React from "react";
import MDIcon from "../../../../../components/ui/MDIcon";
export const createColumns = (handlers) => [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: "1%"
    },
    {
        title: "Название группы",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "",
        key: "actions",
        width: "1%",
        render: (_, record) => (React.createElement(Button.Group, { size: "small", style: { gap: "4px" } },
            React.createElement(Tooltip, { title: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { block: true, onClick: () => {
                        handlers.handleEdit(record.name);
                        handlers.setRecordId(record.id);
                    }, icon: React.createElement(MDIcon, { icon: "pencil" }), style: { minWidth: "24px" } })),
            React.createElement(Popconfirm, { title: "\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043B\u0438\u0442\u044C \u0433\u0440\u0443\u043F\u043F\u0443?", onConfirm: () => {
                    handlers.handleDelete(record.id);
                }, okText: "\u0414\u0430", cancelText: "\u041D\u0435\u0442" },
                React.createElement(Tooltip, { title: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C", mouseEnterDelay: 1 },
                    React.createElement(Button, { danger: true, icon: React.createElement(MDIcon, { icon: "delete-forever" }), style: { minWidth: "24px" } }))))),
    },
];
