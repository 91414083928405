import React, { useContext, useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Modal, Tooltip, } from 'antd';
import MDIcon from '../../../../../components/ui/MDIcon';
import { MovementContext } from '../../../../pages/task/movement/MovementEditPage';
const { confirm } = Modal;
const MovementDetailNomenclatureActions = (props) => {
    const { onDeleteClick, onEditClick, onCancelClick, onSaveClick, onSkuInfoClick, isEditable, skuId, } = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const context = useContext(MovementContext);
    const toggleDropdown = () => setDropdownVisible((value) => !value);
    const closeDropdown = () => {
        if (dropdownVisible) {
            setDropdownVisible(false);
        }
    };
    useEffect(() => {
        const wrapper = document.querySelector('.app-content');
        if (dropdownVisible) {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.addEventListener('scroll', closeDropdown);
        }
        return () => {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.removeEventListener('scroll', closeDropdown);
        };
    }, [dropdownVisible]);
    const handlerEdit = () => {
        closeDropdown();
        onEditClick();
    };
    const handlerDelete = () => {
        closeDropdown();
        confirm({
            title: `Удалить товарную позицию #${skuId}`,
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: 'Вы уверены что хотите удалить товарную позицию?',
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk() {
                onDeleteClick();
            },
        });
    };
    const skuDropdownMenu = {
        items: [
            {
                key: "sku_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => handlerEdit()
            },
            {
                key: "sku_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => handlerDelete()
            }
        ]
    };
    if (isEditable) {
        return (React.createElement(Button.Group, null,
            React.createElement(Tooltip, { title: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "content-save" }), onClick: onSaveClick })),
            React.createElement(Tooltip, { title: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { type: "primary", danger: true, icon: React.createElement(MDIcon, { icon: "close-circle" }), onClick: onCancelClick }))));
    }
    return (React.createElement(Button.Group, null,
        React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
            React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: onSkuInfoClick })),
        React.createElement(Dropdown, { disabled: context === null || context === void 0 ? void 0 : context.isLocked, menu: skuDropdownMenu, open: dropdownVisible, onOpenChange: toggleDropdown, placement: "topLeft", arrow: true, trigger: ['click'] },
            React.createElement(Button, { icon: React.createElement(MDIcon, { icon: "dots-vertical" }), type: "primary" }))));
};
export default MovementDetailNomenclatureActions;
