import { ExclamationCircleOutlined, FileAddOutlined, FolderAddOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import MDIcon from '../ui/MDIcon';
const { confirm } = Modal;
const TreeViewContectMenu = (props) => {
    var _a;
    const { currentNode, foldersName = 'Категорию', itemsName = 'Элемент', disableMenuItem = null, onDelete, onAdd, onEdit, } = props;
    const handlerEdit = () => {
        if (currentNode) {
            onEdit(currentNode.id, !currentNode.isLeaf);
        }
    };
    const showDeleteConfirm = () => {
        if (currentNode) {
            const itemTypeName = (currentNode.isLeaf ? itemsName : foldersName || "").toLowerCase();
            confirm({
                title: `Удалить ${itemTypeName} ${currentNode.title}?`,
                icon: React.createElement(ExclamationCircleOutlined, null),
                content: (React.createElement(React.Fragment, null,
                    `Вы уверены, что хотите удалить ${itemTypeName} `,
                    React.createElement("b", null, currentNode.title),
                    "?")),
                okText: 'Да',
                okType: 'danger',
                cancelText: 'Отмена',
                onOk() {
                    onDelete(currentNode.id, !currentNode.isLeaf);
                },
            });
        }
    };
    const itemLabel = {
        label: currentNode === null || currentNode === void 0 ? void 0 : currentNode.title,
        value: (_a = currentNode === null || currentNode === void 0 ? void 0 : currentNode.id) !== null && _a !== void 0 ? _a : '',
    };
    const addMenuItems = [
        {
            key: "add",
            label: "Добавить",
            icon: React.createElement(MDIcon, { icon: "plus-circle", className: "ant-dropdown-menu-item-icon" }),
            children: [
                !!foldersName ? {
                    key: "add_folder",
                    label: foldersName,
                    icon: React.createElement(FolderAddOutlined, null),
                    onClick: () => onAdd(itemLabel, true)
                } : null,
                {
                    key: "add_item",
                    label: itemsName,
                    icon: React.createElement(FileAddOutlined, null),
                    disabled: disableMenuItem === null ? currentNode == null : disableMenuItem,
                    onClick: () => onAdd(itemLabel, false)
                }
            ]
        }
    ];
    const editMenuItems = [
        {
            key: "edit",
            label: "Редактировать",
            icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
            onClick: () => handlerEdit()
        }
    ];
    const deleteMenuItems = [
        {
            key: "delete",
            label: "Удалить",
            danger: true,
            icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
            onClick: () => showDeleteConfirm()
        }
    ];
    return {
        items: [
            {
                key: "tree_view_menu",
                label: currentNode === null || currentNode === void 0 ? void 0 : currentNode.title,
                type: "group",
                children: [].concat(currentNode == null ? addMenuItems : !currentNode.isLeaf ? addMenuItems : [], currentNode != null ? editMenuItems : [], currentNode != null ? deleteMenuItems : [])
            }
        ]
    };
};
export default TreeViewContectMenu;
