import { Form, Modal } from "antd";
import React, { useEffect } from "react";
import ProductTypeAttrCategoryForm from "../../components/ProductTypeAttrCatForm";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { productActions, selectProductTypeAttrCategoryModal } from "../../redux/reducers/productReducer";
import { productTypeGraphqlApi } from "../../redux/api/productApi/productType";
const { useAddProductTypeCategoryMutation, useEditProductTypeCategoryMutation } = productTypeGraphqlApi;
const ProductTypeAttrCategoryModal = () => {
    const [form] = Form.useForm();
    const { visible, initialValues } = useTypedSelector(selectProductTypeAttrCategoryModal);
    const dispatch = useAppDispatch();
    const [addProductTypeAttrCategory, { isLoading: addIsLoading }] = useAddProductTypeCategoryMutation();
    const [editProductTypeAttrCategory, { isLoading: editIsLoading }] = useEditProductTypeCategoryMutation();
    const isLoading = addIsLoading || editIsLoading;
    useEffect(() => {
        if (visible) {
            form.resetFields();
        }
    }, [visible]);
    const handleOk = () => form.submit();
    const handleCancel = () => {
        form.resetFields();
        dispatch(productActions.changeAttrCategoryModalVisible({ visible: false }));
    };
    const handlerSubmit = (values) => {
        if (values.id === undefined) {
            addProductTypeAttrCategory({
                input: {
                    productTypeId: values.productTypeId,
                    title: values.title,
                },
            })
                .unwrap()
                .then(() => handleCancel());
        }
        else {
            editProductTypeAttrCategory({
                input: {
                    title: values.title,
                },
                productTypeAttributeId: values.id,
            })
                .unwrap()
                .then(() => handleCancel());
        }
    };
    return (React.createElement(Modal, { key: JSON.stringify(initialValues), open: visible, confirmLoading: isLoading, onOk: handleOk, onCancel: handleCancel, title: `${(initialValues === null || initialValues === void 0 ? void 0 : initialValues.id) === undefined ? "Добавить" : "Редактировать"} категорию`, okText: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", cancelText: "\u041E\u0442\u043C\u0435\u043D\u0430" },
        React.createElement(ProductTypeAttrCategoryForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues })));
};
export default ProductTypeAttrCategoryModal;
