export class FileHelper {
}
FileHelper.parseContentDisposition = (header) => {
    if (!header)
        return null;
    const parsedHeader = header.split(";");
    const filenamePartUTF8 = parsedHeader.find((p) => p.match("filename\\*=UTF-8"));
    let filenamePart;
    if (filenamePartUTF8) {
        filenamePart = decodeURIComponent(filenamePartUTF8).replace("UTF-8''", "");
    }
    else {
        filenamePart = parsedHeader.find((p) => p.match("filename"));
    }
    return filenamePart && filenamePart.split("=")[1];
};
FileHelper.saveDownload = (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    const filename = FileHelper.parseContentDisposition(response.headers["content-disposition"]);
    link.setAttribute("download", filename || "download"); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }
    const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = Math.pow(10, dp);
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + " " + units[u];
}
