import { Button } from 'antd';
import React, { useMemo } from 'react';
import TaskListHeader from '../../../../components/task/TaskListHeader/TaskListHeader';
import { links } from '../../../../constants/links';
import ObjectClass from '../../../../constants/enum/ObjectClass';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import ButtonLink from '../../../../components/ButtonLink';
import { PlusOutlined } from '@ant-design/icons';
const PartitionTaskListHeader = (props) => {
    const user = useTypedSelector((state) => state.user);
    const userRoles = useMemo(() => {
        var _a;
        return ((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.role) || [];
    }, [JSON.stringify(user)]);
    return (React.createElement(TaskListHeader, Object.assign({}, props),
        React.createElement(Button.Group, null,
            React.createElement(ButtonLink, { to: links.taskPartitionLinks.add.url(), type: "primary", icon: React.createElement(PlusOutlined, null) }, "\u041D\u043E\u0432\u043E\u0435 \u0434\u0435\u043B\u0435\u043D\u0438\u0435"),
            userRoles.includes("VITMWMSAdministrator") && (React.createElement(ButtonLink, { to: links.statusConfigurator.url(ObjectClass.TaskPartition) }, "\u041A\u043E\u043D\u0444\u0438\u0433\u0443\u0440\u0430\u0442\u043E\u0440 \u0441\u0442\u0430\u0442\u0443\u0441\u043E\u0432")))));
};
export default PartitionTaskListHeader;
