import { Button, Space, Spin } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import TreeView from "../../components/TreeView";
import Centered from "../../components/ui/Centered";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { reset, selectTopology } from "../../redux/reducers/topologyReducer";
import warehouseCellApi from "../../redux/api/warehouseApi/warehouseCellReducer";
import warehouseApi from "../../redux/api/warehouseApi";
import warehouseSectionApi from "../../redux/api/warehouseApi/warehouseSectionReducer";
import { WAREHOUSE_CELL_MODAL_PARAM } from "./WarehouseCellModal";
import { WAREHOUSE_SECTION_MODAL_PARAM } from "./WarehouseSectionModal";
import createTreeViewContectMenu from "../../components/TreeView/TreeViewContextMenuArray";
import MDIcon from "../../components/ui/MDIcon";
import { printDialogActions } from "../../redux/reducers/printDialogReducer";
import { getDocumentUrl } from "../../api/rest/requests/documents/documents";
import { useDocumentAvailable } from "../../hooks/useDocumentAvailable";
import ObjectClass from "../../constants/enum/ObjectClass";
const { useFetchWarehouseQuery } = warehouseApi;
const { useFetchSectionListQuery, useDeleteSectionMutation } = warehouseSectionApi;
const { useDeleteCellMutation } = warehouseCellApi;
const buildTreeData = (sections, cells) => {
    var _a;
    const ROOT_ID = "root";
    if (sections.length === 0) {
        return [];
    }
    const sortObject = sections.reduce((acc, item) => {
        var _a, _b, _c, _d, _e;
        const parentkey = (_a = item.parentId) !== null && _a !== void 0 ? _a : ROOT_ID;
        return Object.assign(Object.assign({}, acc), { [item.id]: Object.assign(Object.assign({}, item), { childCategoryIdList: (_c = (_b = acc[item.id]) === null || _b === void 0 ? void 0 : _b.childCategoryIdList) !== null && _c !== void 0 ? _c : [], childTypes: [] }), [parentkey]: Object.assign(Object.assign({}, acc[parentkey]), { childCategoryIdList: [...((_e = (_d = acc[parentkey]) === null || _d === void 0 ? void 0 : _d.childCategoryIdList) !== null && _e !== void 0 ? _e : []), item.id] }) });
    }, {});
    cells.forEach((cell) => {
        var _a, _b;
        if (cell.parentSectionId) {
            (_b = (_a = sortObject[cell.parentSectionId]) === null || _a === void 0 ? void 0 : _a.childTypes) === null || _b === void 0 ? void 0 : _b.push(cell);
        }
    });
    const adapter = (item, object) => {
        var _a, _b, _c, _d;
        return ({
            key: `section-${item.id}`,
            id: item.id,
            parentId: {
                label: object[(_a = item.parentId) !== null && _a !== void 0 ? _a : ROOT_ID].title,
                value: (_b = item.parentId) !== null && _b !== void 0 ? _b : ROOT_ID,
            },
            title: item.title,
            children: [
                ...((_d = (_c = item.childCategoryIdList) === null || _c === void 0 ? void 0 : _c.map((child) => adapter(object[child], object))) !== null && _d !== void 0 ? _d : []),
                ...item.childTypes.map((type) => ({
                    key: `cell-${type.id}`,
                    id: type.id,
                    parentId: {
                        label: item.title,
                        value: item.id,
                    },
                    title: type.title,
                    isLeaf: true,
                    children: [],
                })),
            ],
        });
    };
    return (_a = sortObject[ROOT_ID]) === null || _a === void 0 ? void 0 : _a.childCategoryIdList.map((childId) => adapter(sortObject[childId], sortObject));
};
const WarehouseTopologyContainer = () => {
    const { id: idParam } = useParams();
    const warehouseId = idParam ? +idParam : null;
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectNode, setSelectNode] = useState(null);
    const dispatch = useAppDispatch();
    const { open: openPrintDialog } = printDialogActions;
    useEffect(() => {
        dispatch(reset());
    }, [dispatch, warehouseId]);
    const documentsCellAvailable = useDocumentAvailable(ObjectClass.StorageCell);
    const documentsSectionAvailable = useDocumentAvailable(ObjectClass.StorageSection);
    const { data: warehouse } = useFetchWarehouseQuery(warehouseId, { skip: !Number.isInteger(warehouseId) });
    const { isLoading: listIsLoading } = useFetchSectionListQuery({
        warehouseId: warehouseId,
        page: 1,
        pageSize: 1000,
    }, {
        refetchOnMountOrArgChange: true,
        skip: !Number.isInteger(warehouseId),
    });
    const [deleteSection, { isLoading: delSectionIsLoading }] = useDeleteSectionMutation();
    const [deleteCell, { isLoading: delCellIsLoading }] = useDeleteCellMutation();
    const isLoading = delSectionIsLoading || delCellIsLoading || listIsLoading;
    const { cells, sections } = useTypedSelector(selectTopology);
    const treeData = useMemo(() => {
        var _a;
        return [
            {
                id: null,
                key: "",
                title: (_a = warehouse === null || warehouse === void 0 ? void 0 : warehouse.storageTitle) !== null && _a !== void 0 ? _a : "Склад",
                children: buildTreeData(sections.list, cells.list),
            },
        ];
    }, [JSON.stringify(sections.list), JSON.stringify(cells.list), idParam]);
    const handlerLoadItem = useCallback((id) => new Promise((resolve) => {
        var _a;
        if (id === null || warehouseId === null) {
            resolve();
            return;
        }
        const parentId = (_a = sections.list.find((item) => item.id === id)) === null || _a === void 0 ? void 0 : _a.parentId;
        dispatch(warehouseSectionApi.endpoints.fetchSection.initiate({
            warehouseId,
            storageSectionId: id,
            parentId,
        }, { forceRefetch: true }))
            .unwrap()
            .then(() => resolve());
        return resolve();
    }), [JSON.stringify(sections === null || sections === void 0 ? void 0 : sections.list)]);
    const handlerSelect = (node) => {
        setSelectNode((node === null || node === void 0 ? void 0 : node.id) ? node : null);
    };
    const handlerAdd = (parent, isCategory) => {
        if (isCategory) {
            searchParams.set(WAREHOUSE_SECTION_MODAL_PARAM, "add");
            searchParams.set("parent", parent.value.toString());
            setSearchParams(searchParams);
        }
        else {
            searchParams.set(WAREHOUSE_CELL_MODAL_PARAM, "add");
            searchParams.set("parent", parent.value.toString());
            setSearchParams(searchParams);
        }
    };
    const handlerPrintAction = (documentId, objectId) => dispatch(openPrintDialog(getDocumentUrl(documentId, objectId)));
    const handlerEdit = (id, isCategory) => {
        if (isCategory) {
            searchParams.set(WAREHOUSE_SECTION_MODAL_PARAM, (id !== null && id !== void 0 ? id : -1).toString());
            setSearchParams(searchParams);
        }
        else {
            searchParams.set(WAREHOUSE_CELL_MODAL_PARAM, (id !== null && id !== void 0 ? id : -1).toString());
            setSearchParams(searchParams);
        }
    };
    const handlerDelete = (id, isCategory) => {
        if (id && warehouseId) {
            if (isCategory) {
                deleteSection({
                    input: {
                        warehouseId,
                        storageSectionId: id,
                    },
                });
            }
            else {
                deleteCell({
                    input: {
                        warehouseId,
                        storageCellId: id,
                    },
                });
            }
        }
    };
    const menuItems = (currentNode) => {
        const node = (currentNode === null || currentNode === void 0 ? void 0 : currentNode.id) ? currentNode : null;
        const additionalActions = node && node.isLeaf
            ? documentsCellAvailable.map((el) => ({
                key: "print-section",
                label: el.title,
                onClick: () => {
                    node && handlerPrintAction(el.id, `${node === null || node === void 0 ? void 0 : node.id}`);
                },
            }))
            : documentsSectionAvailable.map((el) => ({
                key: "print-cell",
                label: el.title,
                onClick: () => {
                    node && handlerPrintAction(el.id, `${node === null || node === void 0 ? void 0 : node.id}`);
                },
            }));
        return createTreeViewContectMenu({
            preItems: [{ key: "print", label: "Печать", icon: React.createElement(MDIcon, { icon: "mdi-printer", className: "ant-dropdown-menu-item-icon" }), children: additionalActions }],
            onAdd: handlerAdd,
            onDelete: handlerDelete,
            onEdit: handlerEdit,
            foldersName: "Секцию",
            itemsName: "Ячейку",
            currentNode: node,
        });
    };
    if (isLoading && sections.list.length === 0) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { spinning: true })));
    }
    return (React.createElement("div", { style: {
            padding: 15,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 10,
        } },
        React.createElement(TreeView, { style: {
                flexGrow: 1,
                overflow: "auto",
                marginRight: -14,
                paddingRight: 14,
            }, defaultExpandedKeys: [""], onLoadFolder: handlerLoadItem, onSelect: handlerSelect, emptyText: React.createElement(Space, { direction: "vertical", align: "center" },
                React.createElement("div", null, "\u0422\u043E\u043F\u043E\u043B\u043E\u0433\u0438\u044F \u0441\u043A\u043B\u0430\u0434\u0430 \u043F\u0443\u0441\u0442\u0430"),
                React.createElement(Button, { type: "primary" }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C")), data: treeData, createContextMenuItems: menuItems })));
};
export default WarehouseTopologyContainer;
