var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import MDIcon from "../../../../components/ui/MDIcon";
import { CorrectionContext } from "../../../pages/task/correction/CorrectionEditPage";
import { RowType } from "./CorrectionDetailNomenclatureForm/types";
const { confirm } = Modal;
const CorrectionDetailNomenclatureActions = (props) => {
    const { id, skuId, isOriginal, isLoading, isEditable, isEdit, rowType, onAddCreated, onDeleteCreated, onEditCreated, onSaveCreated, onCancel, onSkuInfoClick } = props;
    const context = useContext(CorrectionContext);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const toggleDropdown = () => setDropdownVisible((value) => !value);
    const closeDropdown = () => {
        if (dropdownVisible) {
            setDropdownVisible(false);
        }
    };
    const handlerOpenSkuInfo = (skuInfoId) => {
        if (onSkuInfoClick)
            onSkuInfoClick(skuInfoId);
    };
    useEffect(() => {
        const wrapper = document.querySelector(".app-content");
        if (dropdownVisible) {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.addEventListener("scroll", closeDropdown);
        }
        return () => {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.removeEventListener("scroll", closeDropdown);
        };
    }, [dropdownVisible]);
    const handlerEdit = () => __awaiter(void 0, void 0, void 0, function* () {
        yield closeDropdown();
        onEditCreated(id);
    });
    const handlerDelete = () => {
        closeDropdown();
        confirm({
            title: "Удалить корректировку?",
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: "Вы уверены что хотите удалить корректировку?",
            okText: "Да",
            okType: "danger",
            cancelText: "Нет",
            onOk() {
                onDeleteCreated(id);
            },
        });
    };
    const skuDropdownMenu = {
        items: [
            {
                key: "sku_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => handlerEdit(),
            },
            {
                key: "sku_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => handlerDelete(),
            },
        ],
    };
    if (isOriginal) {
        return (React.createElement(Tooltip, { title: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043A\u043E\u0440\u0440\u0435\u043A\u0442\u0438\u0440\u043E\u0432\u043A\u0443", mouseEnterDelay: 1, placement: "left" },
            React.createElement(Button, { loading: isLoading, icon: React.createElement(MDIcon, { icon: "content-cut" }), onClick: () => onAddCreated(id), type: "primary", size: "small" })));
    }
    if (isEditable) {
        return (React.createElement(Button.Group, null,
            React.createElement(Tooltip, { title: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { loading: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "content-save" }), size: "small", onClick: () => {
                        closeDropdown();
                        onSaveCreated();
                    } })),
            React.createElement(Tooltip, { title: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { loading: isLoading, type: "primary", danger: true, icon: React.createElement(MDIcon, { icon: "close-circle" }), size: "small", onClick: () => {
                        closeDropdown();
                        onCancel();
                    } }))));
    }
    return (React.createElement(React.Fragment, null,
        rowType === RowType.Before && (React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
            React.createElement(Button, { loading: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), size: "small", onClick: () => handlerOpenSkuInfo(skuId.toString()) }))),
        isEdit && rowType === RowType.After && (React.createElement(Dropdown, { disabled: context === null || context === void 0 ? void 0 : context.isLocked, menu: skuDropdownMenu, open: dropdownVisible, onOpenChange: toggleDropdown, placement: "topLeft", arrow: true, trigger: ["click"] },
            React.createElement(Button, { loading: isLoading, icon: React.createElement(MDIcon, { icon: "dots-vertical" }), type: "primary", size: "small" })))));
};
export default CorrectionDetailNomenclatureActions;
