function hasScope(task, scope) {
    return task.status.scope.indexOf(scope) >= 0;
}
function hasExternalId(task) {
    return !!(task === null || task === void 0 ? void 0 : task.externalId);
}
export const taskHelpers = {
    hasScope,
    hasExternalId,
};
