import React from "react";
import { Col, Typography, Tooltip } from "antd";
import { getBgColor, getTextColor } from "./helpers";
const { Title, Text } = Typography;
export const SectionCell = ({ title, percent, address, quantity }) => {
    return (React.createElement(Col, { style: { padding: "6px", width: 100, height: 100, borderRadius: 2, backgroundColor: getBgColor(percent, "#f0f0f0"), display: "flex", flexDirection: "column", justifyContent: "space-between" } },
        title && (React.createElement(Tooltip, { title: React.createElement(React.Fragment, null,
                "\u042F\u0447\u0435\u0439\u043A\u0430: ",
                title) },
            React.createElement(Text, { style: { fontSize: "10px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } },
                "\u042F\u0447\u0435\u0439\u043A\u0430: ",
                title))),
        address && (React.createElement(Tooltip, { title: address },
            React.createElement(Text, { style: { fontSize: "10px", overflow: "hidden", textOverflow: "ellipsis" } }, address))),
        quantity && React.createElement(Text, { style: { fontSize: "10px", overflow: "hidden", textOverflow: "ellipsis" } },
            "\u041A\u043E\u043B-\u0432\u043E: ",
            quantity),
        React.createElement(Title, { level: 5, style: { fontSize: "16px", lineHeight: "24px", color: getTextColor(percent), margin: 0 } }, `${percent}%`)));
};
