var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useProductTypeAutomationExpressionCreateMutation, useProductTypeAutomationExpressionDeleteMutation, useProductTypeAutomationExpressionEditMutation, useProductTypeAutomationExpressionSortMutation, } from "../../../../../../api/graphql/queries/automatization/AutomatizationMutation";
export const useProductAutomatizationExpressionActions = () => {
    const [deleteProductAutomatizationExpression, { loading: deleteLoading }] = useProductTypeAutomationExpressionDeleteMutation();
    const [createProductAutomatizationExpression, { loading: createLoading }] = useProductTypeAutomationExpressionCreateMutation();
    const [editProductAutomatizationExpression, { loading: editLoading }] = useProductTypeAutomationExpressionEditMutation();
    const [sortProductAutomatizationExpression, { loading: sortLoading }] = useProductTypeAutomationExpressionSortMutation();
    const handleDelete = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteProductAutomatizationExpression({ variables: { input } });
    });
    const handleCreate = (input) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield createProductAutomatizationExpression({ variables: { input } });
        return res;
    });
    const handleEdit = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield editProductAutomatizationExpression({ variables: { input } });
    });
    const handleSort = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield sortProductAutomatizationExpression({ variables: { input } });
    });
    return {
        deleteProductAutomatizationExpression: {
            handleAction: handleDelete,
            isLoading: deleteLoading,
        },
        editProductAutomatizationExpression: {
            handleAction: handleEdit,
            isLoading: editLoading,
        },
        sortProductAutomatizationExpression: {
            handleAction: handleSort,
            isLoading: sortLoading,
        },
        createProductAutomatizationExpression: {
            handleAction: handleCreate,
            isLoading: createLoading,
        },
    };
};
