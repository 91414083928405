import { Form, Table } from "antd";
import { groupBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { createColumns, createSkuColumns } from "./Columns";
import { useAppDispatch } from "../../../../../redux/store";
import { skuActions } from "../../../../../redux/reducers/skuReducer";
import { getRowClass } from "./helpers";
import { TableControls } from "./TableControls";
export const PreviewTable = ({ taskInventoryDto, refetch }) => {
    const [groupedCells, setGroupedCells] = useState(null);
    const [taskEntity, setTaskEntity] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [form] = Form.useForm();
    useEffect(() => {
        setGroupedCells(groupBy(taskInventoryDto.taskInventorySkus, (item) => {
            return item.storageCellExpected.id;
        }));
    }, [taskInventoryDto]);
    useEffect(() => {
        if (!taskEntity.length) {
            groupedCells &&
                Object.keys(groupedCells).forEach((key) => {
                    const info = groupedCells[key].reduce((info, item) => {
                        var _a, _b, _c, _d;
                        info.skuTotal = groupedCells[key].length;
                        if (!((_a = item === null || item === void 0 ? void 0 : item.storageCellActual) === null || _a === void 0 ? void 0 : _a.id)) {
                            info.notFound = ((info === null || info === void 0 ? void 0 : info.notFound) || 0) + 1;
                        }
                        if (!!((_b = item.storageCellActual) === null || _b === void 0 ? void 0 : _b.id) && ((_c = item.storageCellActual) === null || _c === void 0 ? void 0 : _c.id) !== ((_d = item.storageCellExpected) === null || _d === void 0 ? void 0 : _d.id)) {
                            info.notAtPlace = ((info === null || info === void 0 ? void 0 : info.notAtPlace) || 0) + 1;
                        }
                        if (!!item.comment) {
                            info.commented = ((info === null || info === void 0 ? void 0 : info.commented) || 0) + 1;
                        }
                        return info;
                    }, {});
                    setTaskEntity((prev) => [
                        ...prev,
                        {
                            id: +key,
                            key: +key,
                            address: groupedCells[key][0].storageCellExpected.address,
                            skuTotal: info.skuTotal || 0,
                            problems: {
                                notFound: info.notFound || 0,
                                notAtPlace: info.notAtPlace || 0,
                                commented: info.commented || 0,
                            },
                            skus: groupedCells[key].map((el) => (Object.assign(Object.assign({}, el), { key: el.id }))),
                        },
                    ]);
                });
        }
    }, [groupedCells]);
    const columns = useMemo(() => createColumns(), [JSON.stringify(groupedCells)]);
    const dispatch = useAppDispatch();
    const handlerOpenSkuInfo = (skuId) => {
        dispatch(skuActions.open(`${skuId}`));
    };
    const SKUColumn = useMemo(() => createSkuColumns(handlerOpenSkuInfo, setSelectedItems, selectedItems), [JSON.stringify(selectedItems)]);
    const checkSelectedItems = useMemo(() => selectedItems.length, [JSON.stringify(selectedItems)]);
    return (React.createElement("div", { className: "mb-5", style: { display: "flex", flexWrap: "wrap", gap: 8, width: "100%" } },
        React.createElement(Form, { form: form, component: false, style: { width: "100%" } },
            React.createElement(Table, { style: { whiteSpace: "nowrap", width: "100%" }, expandable: {
                    expandedRowRender: (record) => React.createElement(Table, { rowClassName: getRowClass, columns: SKUColumn, dataSource: record === null || record === void 0 ? void 0 : record.skus, pagination: false, size: "small", scroll: { x: true } }),
                    rowExpandable: (record) => { var _a; return !!((_a = record.skus) === null || _a === void 0 ? void 0 : _a.length); },
                }, columns: columns, dataSource: taskEntity, bordered: true, pagination: false, size: "small", scroll: { x: true } })),
        !!checkSelectedItems && React.createElement(TableControls, { refetch: refetch, selectedItems: selectedItems, setSelectedItems: setSelectedItems })));
};
