var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Card, Form, Modal, Space, Spin, Table, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { useGetUserListQuery } from "../../../../api/graphql/queries/user/UserQuery";
import UserGroupSelect from "../../../../components/UserGroupSelect";
import WarehouseSelect from "../../../../components/WarehouseSelect";
import Centered from "../../../../components/ui/Centered";
import { createColumns } from "./Table/columns";
import { useAccountsUsersContainer } from "./useAccountsUsersContainer";
const AccountsUsersContainer = () => {
    const { data, loading, error } = useGetUserListQuery();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [recordId, setRecordId] = useState(null);
    const { edit, loading: actionLoading } = useAccountsUsersContainer();
    const handleEdit = (id) => __awaiter(void 0, void 0, void 0, function* () {
        setIsEditModalVisible(true);
        setRecordId(id);
    });
    const handleOk = () => __awaiter(void 0, void 0, void 0, function* () {
        form.validateFields().then((values) => __awaiter(void 0, void 0, void 0, function* () {
            console.log(values);
            if (recordId) {
                yield edit({
                    variables: {
                        input: {
                            id: recordId,
                            warehouses: values.warehouses.filter((el) => !!(el === null || el === void 0 ? void 0 : el.key)).map((el) => +el.key),
                            groups: values.userGroups.filter((el) => !!(el === null || el === void 0 ? void 0 : el.key)).map((el) => +el.key),
                        },
                    },
                });
            }
            setIsEditModalVisible(false);
            setRecordId(null);
        }));
    });
    const handleCancel = () => {
        setIsEditModalVisible(false);
        setRecordId(null);
    };
    const columns = useMemo(() => createColumns({ handleEdit }), []);
    if (loading)
        return (React.createElement(Centered, { style: { minHeight: "100vh" } },
            React.createElement(Spin, { size: "large" })));
    if (error)
        return (React.createElement(Card, null,
            React.createElement(Typography.Text, null, "\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u044F \u0433\u0440\u0443\u043F\u043F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439")));
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, null,
            React.createElement(Space, { direction: "vertical", size: "middle", style: { display: "flex" } },
                React.createElement(Table, { loading: actionLoading, columns: columns, dataSource: data === null || data === void 0 ? void 0 : data.userList, rowKey: "id", pagination: false }))),
        React.createElement(Modal, { confirmLoading: actionLoading, title: "\u0420\u0435\u0434\u0430\u0442\u0438\u043A\u0440\u043E\u0432\u0430\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F", open: isEditModalVisible, onOk: handleOk, onCancel: handleCancel },
            React.createElement(Form, { form: form },
                React.createElement(Form.Item, { label: "\u0413\u0440\u0443\u043F\u043F\u0430", name: "userGroups" },
                    React.createElement(UserGroupSelect, { mode: "multiple", placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0433\u0440\u0443\u043F\u043F\u044B" })),
                React.createElement(Form.Item, { label: "\u0421\u043A\u043B\u0430\u0434\u044B", name: "warehouses" },
                    React.createElement(WarehouseSelect, { mode: "multiple", placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434\u044B" }))))));
};
export default AccountsUsersContainer;
