import React from 'react';
import { Badge, Empty, Tabs, Typography, } from 'antd';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import StatusConfiguratorStatusForm from '../../components/StatusConfigurator/StatusConfiguratorStatusForm';
import StatusAccessForm from '../../components/StatusAccessForm';
import ObjectClass from '../../constants/enum/ObjectClass';
import StatusRelationForm from '../../components/StatusRelationForm';
import StatusEntryPointForm from '../../components/StatusEntryPointForm';
import Centered from '../../components/ui/Centered';
import { selectStatusConfigurator, statusConfiguratorActions } from '../../redux/reducers/statusConfiguratorReducer';
import statusConfiguratorStatusApi from '../../redux/api/statusConfiguratorApi';
import statusConfiguratorRelationApi from '../../redux/api/statusConfiguratorApi/relation';
import statusConfiguratorAccessApi from '../../redux/api/statusConfiguratorApi/access';
import { useAppDispatch } from '../../hooks/useAppDispatch';
const { useFetchStatusListQuery, useEditStatusMutation, useAddStatusMutation, useDeleteStatusMutation, } = statusConfiguratorStatusApi;
const { useFetchRelationListQuery, useAddRelationMutation, useEditRelationMutation, useDeleteRelationMutation, } = statusConfiguratorRelationApi;
const { useAddAccessMutation, useEditAccessMutation, useDeleteAccessMutation, } = statusConfiguratorAccessApi;
const { select: selectStatus } = statusConfiguratorActions;
const StatusConfiguratorInfoContainer = (props) => {
    var _a, _b;
    const { objectClass } = props;
    const dispatch = useAppDispatch();
    const { data: statuses, isLoading: statusesIsLoading } = useFetchStatusListQuery(objectClass);
    const { data: relations, isLoading: relationsIsLoading } = useFetchRelationListQuery(objectClass);
    const { selected } = useTypedSelector(selectStatusConfigurator);
    const status = statuses === null || statuses === void 0 ? void 0 : statuses.find((item) => item.id === selected);
    const [editStatusRequest] = useEditStatusMutation();
    const [addStatusRequest] = useAddStatusMutation();
    const [deleteStatusRequest] = useDeleteStatusMutation();
    const [addRelationRequest] = useAddRelationMutation();
    const [editRelationRequest] = useEditRelationMutation();
    const [deleteRelationRequest] = useDeleteRelationMutation();
    const [addAccessRequest] = useAddAccessMutation();
    const [editAccessRequest] = useEditAccessMutation();
    const [deleteAccessRequest] = useDeleteAccessMutation();
    // TODO - нужно переделать на получение конфиг из api
    const getTriggerConfig = () => {
        switch (objectClass) {
            case ObjectClass.TaskChangeWarehouse:
                return [
                    { value: "task.cancel", label: "Отмена" },
                    { value: "task.finish", label: "Закончить" },
                    { value: "task.shipped", label: "Произвести отгрузку" },
                    { value: "task.accepted", label: "Произвести приемку" },
                    { value: "task.draft.validate", label: "Валидация черновика" },
                    { value: "task.validate", label: "Валидация задачи" },
                    { value: "task.departure.validate", label: "Валидация отгрузки со склада отправления" },
                    { value: "task.destination.validate", label: "Валидация приемки на складе назначения" },
                ];
            default:
                return [
                    { value: "task.cancel", label: "Отмена" },
                    { value: "task.finish", label: "Закончить" },
                    { value: "task.draft.validate", label: "Валидация черновика" },
                    { value: "task.validate", label: "Валидация задачи" },
                ];
        }
    };
    // TODO - нужно переделать на получение конфиг из api
    const getEntrypointConfig = () => {
        switch (objectClass) {
            case ObjectClass.TaskLoading:
                return [{ value: "new.empty", label: "Пустая задача" }];
            case ObjectClass.TaskShipping:
                return [
                    { value: "new.empty", label: "Пустая задача" },
                    { value: "from_external_service", label: "Пустая задача" },
                ];
            case ObjectClass.TaskPartition:
                return [
                    { value: "new.empty", label: "Пустая задача" },
                    { value: "from_task.shipping", label: "Из задачи на загрузку" },
                ];
            case ObjectClass.TaskMovement:
                return [
                    { value: "new.empty", label: "Пустая задача" },
                    { value: "from_task.inventory.create", label: "Из инвентаризации - создать задачу" },
                    { value: "from_task.inventory.create_and_close", label: "Из инвентаризации - создать и закрыть задачу" },
                ];
            case ObjectClass.TaskChangeWarehouse:
                return [
                    { value: "new.empty", label: "Пустая задача" },
                    { value: "from_task.acceptance", label: "Приемка на складе назначения" },
                ];
            case ObjectClass.TaskWriteOff:
                return [{ value: "new.empty", label: "Пустая задача" }];
            case ObjectClass.TaskInventory:
                return [{ value: "new.empty", label: "Пустая задача" }];
            case ObjectClass.TaskCorrection:
                return [
                    { value: "new.empty", label: "Пустая задача" },
                    { value: "from_task.inventory", label: "Из задачи на инвентаризацию" },
                    { value: "from_task.change_warehouse", label: "Из задачи на перемещение между складами" },
                ];
            default:
                return [];
        }
    };
    // TODO - нужно переделать на получение конфиг из api
    const getScopeConfig = () => {
        switch (objectClass) {
            case ObjectClass.TaskLoading:
                return [
                    { value: "task.edit", label: "Редактировать задачу" },
                    { value: "task.details.edit", label: "Редактировать детали задачи" },
                    { value: "task.produceWork", label: "Производить работу" }
                ];
            case ObjectClass.TaskShipping:
                return [
                    { value: "task.edit", label: "Редактировать задачу" },
                    { value: "task.details.edit", label: "Редактировать детали задачи" },
                    { value: "task.produceWork", label: "Производить работу" }
                ];
            case ObjectClass.TaskPartition:
                return [
                    { value: "task.details.edit", label: "Редактировать детали задачи" },
                    { value: "task.produceWork", label: "Производить работу" }
                ];
            case ObjectClass.TaskMovement:
                return [
                    { value: "task.details.edit", label: "Редактировать детали задачи" },
                    { value: "task.produceWork", label: "Производить работу" }
                ];
            case ObjectClass.TaskChangeWarehouse:
                return [
                    { value: "task.edit", label: "Редактировать задачу" },
                    { value: "task.details.edit", label: "Редактировать детали задачи" },
                    { value: "task.produceWork", label: "Производить работу" }
                ];
            case ObjectClass.TaskWriteOff:
                return [
                    { value: "task.details.edit", label: "Редактировать детали задачи" },
                    { value: "task.produceWork", label: "Производить работу" }
                ];
            case ObjectClass.TaskCorrection:
                return [
                    { value: "task.details.edit", label: "Редактировать детали задачи" },
                    { value: "task.produceWork", label: "Производить работу" }
                ];
            case ObjectClass.TaskInventory:
                return [
                    { value: "task.edit", label: "Редактировать задачу" },
                    { value: "task.details.edit", label: "Редактировать детали задачи" },
                    { value: "task.perform_actions_on_results", label: "Производить действия над результатами" }
                ];
            default:
                return [
                    { value: "task.edit", label: "Редактировать задачу" },
                    { value: "task.details.edit", label: "Редактировать детали задачи" },
                    { value: "task.produceWork", label: "Производить работу" }
                ];
        }
    };
    const handlerSubmit = (statusId, values) => {
        editStatusRequest({ statusId, objectClass, values });
    };
    const handlerDeleteStatus = (statusId) => {
        deleteStatusRequest({ statusId, objectClass });
        dispatch(selectStatus(null));
    };
    const handlerAdd = (values) => {
        addStatusRequest({ values, objectClass });
        dispatch(selectStatus(null));
    };
    const handlerStatusAccess = (result) => {
        result.add.forEach((item) => {
            const values = {
                statusId: status.id,
                scope: item.scope.map((scope) => scope.value),
                userGroupId: item.userGroup.value,
            };
            addAccessRequest({ objectClass, values });
        });
        result.edit.forEach((item) => {
            const values = {
                scope: item.scope.map((scope) => scope.value),
                userGroupId: item.userGroup.value,
            };
            editAccessRequest({
                statusAccessId: item.id,
                values,
                objectClass,
            });
        });
        result.delete.forEach((item) => {
            deleteAccessRequest({
                statusAccessId: item.id,
                objectClass,
            });
        });
    };
    const handlerStatusRelation = (result) => {
        result.add.forEach((item) => {
            const values = {
                trigger: item.trigger,
                isEntryPoint: false,
                needReport: item.needReport,
                sendNotification: item.sendNotification,
                statusFromId: status.id,
                statusToId: item.statusToId.value,
                userGroups: item.userGroups.map((group) => group.value),
                xPos: 0,
                yPos: 0,
            };
            addRelationRequest({ values, objectClass });
        });
        result.edit.forEach((item) => {
            const values = {
                trigger: item.trigger,
                isEntryPoint: false,
                needReport: Boolean(item.needReport),
                sendNotification: Boolean(item.sendNotification),
                statusFromId: status.id,
                statusToId: item.statusToId.value,
                userGroups: item.userGroups.map((group) => group.value),
                xPos: 0,
                yPos: 0,
            };
            editRelationRequest({
                statusRelationId: item.id,
                values,
                objectClass,
            });
        });
        result.delete.forEach((item) => {
            deleteRelationRequest({
                statusRelationId: item.id,
                objectClass,
            });
        });
    };
    const handlerStatusEntryPoint = (result) => {
        console.log(result);
        result.add.forEach((item) => {
            const values = {
                isEntryPoint: true,
                entryPointIdentifier: item.entryPointIdentifier,
                needReport: false,
                sendNotification: false,
                statusToId: status.id,
                userGroups: item.userGroups.map((group) => group.value),
                xPos: 0,
                yPos: 0,
            };
            addRelationRequest({ values, objectClass });
        });
        result.edit.forEach((item) => {
            const values = {
                isEntryPoint: true,
                entryPointIdentifier: item.entryPointIdentifier,
                needReport: false,
                sendNotification: false,
                statusToId: status.id,
                userGroups: item.userGroups.map((group) => group.value),
                xPos: 0,
                yPos: 0,
            };
            editRelationRequest({
                statusRelationId: item.id,
                values,
                objectClass,
            });
        });
        result.delete.forEach((item) => {
            deleteRelationRequest({
                statusRelationId: item.id,
                objectClass,
            });
        });
    };
    if (selected === 'ADD') {
        return (React.createElement("div", { style: {
                width: 400, height: '100%', overflow: 'auto', background: 'white', padding: 15,
            } },
            React.createElement(Typography.Title, { level: 4 }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0442\u0430\u0442\u0443\u0441"),
            React.createElement(StatusConfiguratorStatusForm, { onSubmit: (values) => handlerAdd(Object.assign(Object.assign({}, values), { xPos: 0, yPos: 0 })) })));
    }
    if (selected && status) {
        const accessesCount = status.statusAccesses.length;
        const relationsCount = relations === null || relations === void 0 ? void 0 : relations.filter((relation) => !relation.isEntryPoint && relation.statusFromId === status.id).length;
        const entryPointsCount = relations === null || relations === void 0 ? void 0 : relations.filter((relation) => relation.isEntryPoint && relation.statusToId === status.id).length;
        return (React.createElement("div", { style: {
                width: 400, height: '100%', overflow: 'auto', background: 'white', padding: 15,
            } },
            React.createElement(Typography.Title, { level: 4 }, status.title),
            React.createElement(Tabs, null,
                React.createElement(Tabs.TabPane, { tab: "\u0421\u0442\u0430\u0442\u0443\u0441", key: "statusTab" },
                    React.createElement(StatusConfiguratorStatusForm, { initialValues: status, blockDelete: relations === null || relations === void 0 ? void 0 : relations.some((relation) => (relation.statusToId === status.id || relation.statusFromId === status.id)), onDelete: () => handlerDeleteStatus(status.id), onSubmit: (values) => handlerSubmit(status.id, Object.assign(Object.assign({}, values), { xPos: status.xPos, yPos: status.yPos })) })),
                React.createElement(Tabs.TabPane, { tab: (React.createElement(Badge, { count: accessesCount, offset: [7, -5] }, "\u041F\u0440\u0430\u0432\u0430 \u0433\u0440\u0443\u043F\u043F")), key: "accessTab" },
                    React.createElement(StatusAccessForm, { scopeList: getScopeConfig(), loading: statusesIsLoading, statusId: status.id, initialValues: {
                            accessList: status.statusAccesses.map((access) => (Object.assign(Object.assign({}, access), { scope: access.scope.map((scope) => {
                                    var _a, _b;
                                    return ({
                                        label: (_b = (_a = getScopeConfig()) === null || _a === void 0 ? void 0 : _a.find((item) => item.value === scope)) === null || _b === void 0 ? void 0 : _b.label,
                                        value: scope,
                                    });
                                }), userGroup: {
                                    label: access.userGroup.name,
                                    value: access.userGroup.id,
                                } }))),
                        }, onSubmit: handlerStatusAccess })),
                React.createElement(Tabs.TabPane, { tab: (React.createElement(Badge, { count: relationsCount, offset: [7, -5] }, "\u0421\u0432\u044F\u0437\u0438")), key: "relationsTab" },
                    React.createElement(StatusRelationForm, { relationTriggerList: getTriggerConfig(), loading: relationsIsLoading, statusId: status.id, initialValues: {
                            relations: (_a = relations === null || relations === void 0 ? void 0 : relations.filter((item) => item.statusFromId === status.id && !item.isEntryPoint).map((relation) => {
                                var _a;
                                return (Object.assign(Object.assign({}, relation), { statusToId: {
                                        label: (_a = statuses === null || statuses === void 0 ? void 0 : statuses.find((item) => item.id === relation.statusToId)) === null || _a === void 0 ? void 0 : _a.title,
                                        value: relation.statusToId,
                                    }, userGroups: relation.userGroups.map((group) => ({
                                        label: group.name,
                                        value: group.id,
                                    })) }));
                            })) !== null && _a !== void 0 ? _a : [],
                        }, objectClass: objectClass, onSubmit: handlerStatusRelation })),
                React.createElement(Tabs.TabPane, { tab: (React.createElement(Badge, { count: entryPointsCount, offset: [7, -5] }, "\u0422\u043E\u0447\u043A\u0438 \u0432\u0445\u043E\u0434\u0430")), key: "entryPointsTab" },
                    React.createElement(StatusEntryPointForm, { entrypointList: getEntrypointConfig(), loading: relationsIsLoading, statusId: status.id, initialValues: {
                            entryPoints: (_b = relations === null || relations === void 0 ? void 0 : relations.filter((item) => item.statusToId === status.id && item.isEntryPoint).map((relation) => {
                                var _a;
                                return (Object.assign(Object.assign({}, relation), { statusToId: {
                                        label: (_a = statuses === null || statuses === void 0 ? void 0 : statuses.find((item) => item.id === relation.statusToId)) === null || _a === void 0 ? void 0 : _a.title,
                                        value: relation.statusToId,
                                    }, userGroups: relation.userGroups.map((group) => ({
                                        label: group.name,
                                        value: group.id,
                                    })) }));
                            })) !== null && _b !== void 0 ? _b : [],
                        }, onSubmit: handlerStatusEntryPoint })))));
    }
    return (React.createElement("div", { style: {
            width: 400, height: '100%', background: 'white', padding: 15,
        } },
        React.createElement(Centered, null,
            React.createElement(Empty, { description: "\u0421\u0442\u0430\u0442\u0443\u0441 \u043D\u0435 \u0432\u044B\u0431\u0440\u0430\u043D" }))));
};
export default StatusConfiguratorInfoContainer;
