import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import PartitionTaskFormNomenclatureContainer from "../../../containers/task/partition/PartitionTaskFormNomenclatureContainer";
import PartitionEditTaskHeaderContainer from "../../../containers/task/partition/PartitionEditTaskHeaderContainer";
import { useTaskPartition } from "../../../../hooks/api/taskPartitionApi/useTaskPartition";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
export const PartitionContext = React.createContext(null);
const PartitionProvider = PartitionContext.Provider;
const PartitionEditPage = () => {
    const params = useParams();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const { task } = useTaskPartition();
    const isLocked = useMemo(() => taskHelpers.hasExternalId(task === null || task === void 0 ? void 0 : task.data), [task === null || task === void 0 ? void 0 : task.data]);
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: React.createElement(PartitionEditTaskHeaderContainer, { taskId: taskId, task: task === null || task === void 0 ? void 0 : task.data, isLoading: task === null || task === void 0 ? void 0 : task.loading }),
        toolbarRight: null,
    };
    return (React.createElement(PartitionProvider, { value: { isLocked } },
        React.createElement(Page, Object.assign({}, pageProps),
            React.createElement(PartitionTaskFormNomenclatureContainer, { isLoading: task.loading, taskId: taskId, refetch: task === null || task === void 0 ? void 0 : task.refetch, task: task === null || task === void 0 ? void 0 : task.data }))));
};
export default PartitionEditPage;
