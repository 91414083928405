var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchBaseQuery, retry, } from "@reduxjs/toolkit/query/react";
import { Mutex } from 'async-mutex';
import { AuthService } from "../services/AuthService";
const mutex = new Mutex();
const baseQuery = retry(fetchBaseQuery({
    baseUrl: window.env.ApiHost,
    prepareHeaders: (headers) => __awaiter(void 0, void 0, void 0, function* () {
        const token = AuthService.getAccessTokenFromStorage();
        headers.set('Authorization', `Bearer ${token}`);
        return headers;
    }),
}), {
    maxRetries: 3,
});
const fetchAppQuery = (args, api, extraOptions) => __awaiter(void 0, void 0, void 0, function* () {
    yield mutex.waitForUnlock();
    let result = yield baseQuery(args, api, extraOptions);
    return result;
});
export default fetchAppQuery;
