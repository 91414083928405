var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Card, Col, DatePicker, Divider, Form, Input, Row, Spin, Typography, message } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { AddShippingFieldName } from "../../../../api/rest/dto/tasks/shipping/types";
import ClientSelect from "../../../../components/ClientSelect";
import WarehouseSelect from "../../../../components/WarehouseSelect";
import TaskForm from "../../../../components/task/TaskForm";
import Centered from "../../../../components/ui/Centered";
const { Title } = Typography;
const formGutter = [15, 15];
const ShippingTaskForm = (props) => {
    var _a, _b, _c, _d;
    const { task, onLoad, isLoading, children, isEditing, onSubmit, disableWarehouse, isNew } = props;
    let initialValues = {};
    if (task) {
        initialValues = {
            warehouseId: {
                value: task.warehouse.id,
                label: task.warehouse.storageTitle,
            },
            vehicleNumber: task.vehicleNumber,
            driverName: task.driverName,
            unloadingAddress: task.unloadingAddress,
            powerOfAttorney: task.powerOfAttorney,
            powerOfAttorneyDate: task.powerOfAttorneyDate ? moment(new Date(task.powerOfAttorneyDate)) : undefined,
            releaseAllowedName: task.releaseAllowedName,
            releaseAllowedPost: task.releaseAllowedPost,
            releaseProducedName: task.releaseProducedName,
            releaseProducedPost: task.releaseProducedPost,
            requestDate: task.requestDate ? moment(new Date(task.requestDate)) : undefined,
            requestNumber: task.requestNumber,
            clientFromId: {
                value: task.clientFrom.id,
                label: task.clientFrom.companyName,
            },
            clientToId: {
                value: task.clientTo.id,
                label: task.clientTo.companyName,
            },
            payerId: {
                value: (_a = task.payer) === null || _a === void 0 ? void 0 : _a.id,
                label: (_b = task.payer) === null || _b === void 0 ? void 0 : _b.companyName,
            },
            carrierId: {
                value: (_c = task.carrier) === null || _c === void 0 ? void 0 : _c.id,
                label: (_d = task.carrier) === null || _d === void 0 ? void 0 : _d.companyName,
            },
        };
    }
    useEffect(() => {
        if (onLoad)
            onLoad().finally();
    }, []);
    const handlerFinish = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _e, _f, _g, _h, _j;
        let requestBody;
        const formValues = {
            carrierId: (_e = values.carrierId) === null || _e === void 0 ? void 0 : _e.value,
            unloadingAddress: values.unloadingAddress,
            clientFromId: (_f = values.clientFromId) === null || _f === void 0 ? void 0 : _f.value,
            clientToId: (_g = values.clientToId) === null || _g === void 0 ? void 0 : _g.value,
            driverName: values.driverName,
            payerId: values.payerId.value,
            powerOfAttorney: values.powerOfAttorney,
            powerOfAttorneyDate: (_h = values.powerOfAttorneyDate) === null || _h === void 0 ? void 0 : _h.format("YYYY-MM-DD"),
            releaseAllowedName: values.releaseAllowedName,
            releaseAllowedPost: values.releaseAllowedPost,
            releaseProducedName: values.releaseProducedName,
            releaseProducedPost: values.releaseProducedPost,
            requestDate: (_j = values.requestDate) === null || _j === void 0 ? void 0 : _j.format("YYYY-MM-DD"),
            requestNumber: values.requestNumber,
            vehicleNumber: values.vehicleNumber,
        };
        if (isEditing) {
            const request = Object.assign(Object.assign(Object.assign({}, values), { warehouseId: undefined, taskShippingRequirements: undefined }), formValues);
            requestBody = {
                taskId: task === null || task === void 0 ? void 0 : task.id,
                request,
            };
        }
        else {
            requestBody = Object.assign(Object.assign(Object.assign({}, values), { warehouseId: values.warehouseId.value, taskShippingRequirements: [] }), formValues);
        }
        try {
            yield onSubmit(requestBody);
        }
        catch (e) {
            console.log("error", e);
            message.error("Ошибка при сохранении задачи");
        }
    });
    const taskForm = (React.createElement(React.Fragment, null,
        React.createElement(Col, { span: 8 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u0420\u0435\u043A\u0432\u0438\u0437\u0438\u0442\u044B"),
                React.createElement(Divider, null),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0421\u043A\u043B\u0430\u0434", name: AddShippingFieldName.WAREHOUSE, rules: [{ required: true }] },
                            React.createElement(WarehouseSelect, { disabled: disableWarehouse, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434" }))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u041F\u043B\u0430\u0442\u0435\u043B\u044C\u0449\u0438\u043A", name: AddShippingFieldName.PAYER, rules: [{ required: true }] },
                            React.createElement(ClientSelect, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" })))),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0413\u0440\u0443\u0437\u043E\u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u0435\u043B\u044C", name: AddShippingFieldName.CLIENT_FROM, rules: [{ required: true }] },
                            React.createElement(ClientSelect, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" }))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0413\u0440\u0443\u0437\u043E\u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044C", name: AddShippingFieldName.CLIENT_TO, rules: [{ required: true }] },
                            React.createElement(ClientSelect, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" })))),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u041F\u0435\u0440\u0435\u0432\u043E\u0437\u0447\u0438\u043A", name: AddShippingFieldName.CARRIER, rules: [{ required: false }] },
                            React.createElement(ClientSelect, { allowClear: true, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" }))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u041F\u0443\u043D\u043A\u0442 \u0440\u0430\u0437\u0433\u0440\u0443\u0437\u043A\u0438", name: AddShippingFieldName.UNLOADING_ADDRESS, rules: [{ required: false }] },
                            React.createElement(Input, null)))))),
        React.createElement(Col, { span: 8 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u041E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0435\u043D\u043D\u044B\u0435"),
                React.createElement(Divider, null),
                React.createElement(Form.Item, { label: "\u041E\u0442\u043F\u0443\u0441\u043A \u0440\u0430\u0437\u0440\u0435\u0448\u0438\u043B" },
                    React.createElement(Row, { gutter: formGutter },
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddShippingFieldName.RELEASE_ALLOWED_NAME, rules: [{ required: true }] },
                                React.createElement(Input, { placeholder: "\u0424\u0418\u041E" }))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddShippingFieldName.RELEASE_ALLOWED_POST, rules: [{ required: true }] },
                                React.createElement(Input, { placeholder: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C" }))))),
                React.createElement(Form.Item, { label: "\u041E\u0442\u043F\u0443\u0441\u043A \u043F\u0440\u043E\u0438\u0437\u0432\u0435\u043B" },
                    React.createElement(Row, { gutter: formGutter },
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddShippingFieldName.RELEASE_PRODUCED_NAME, rules: [{ required: true }] },
                                React.createElement(Input, { placeholder: "\u0424\u0418\u041E" }))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddShippingFieldName.RELEASE_PRODUCED_POST, rules: [{ required: true }] },
                                React.createElement(Input, { placeholder: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C" }))))))),
        React.createElement(Col, { span: 8 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u0412\u043E\u0434\u0438\u0442\u0435\u043B\u044C"),
                React.createElement(Divider, null),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0424\u0418\u041E", name: AddShippingFieldName.DRIVER_NAME, rules: [{ required: true }] },
                            React.createElement(Input, null))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u041D\u043E\u043C\u0435\u0440 \u0442/\u0441", name: AddShippingFieldName.VEHICLE_NUMBER, rules: [{ required: true }] },
                            React.createElement(Input, null)))),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0414\u043E\u0432\u0435\u0440\u0435\u043D\u043D\u043E\u0441\u0442\u044C", name: AddShippingFieldName.POWER_OF_ATTORNEY, rules: [{ required: true }] },
                            React.createElement(Input, null))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0414\u0430\u0442\u0430 \u0434\u043E\u0432\u0435\u0440\u0435\u043D\u043D\u043E\u0441\u0442\u0438", name: AddShippingFieldName.POWER_OF_ATTORNEY_DATE, rules: [{ required: true }] },
                            React.createElement(DatePicker, { style: { width: "100%" } })))),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u2116 \u0417\u0430\u044F\u0432\u043A\u0438", name: AddShippingFieldName.REQUEST_NUMBER, rules: [{ required: false }] },
                            React.createElement(Input, null))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0414\u0430\u0442\u0430 \u0437\u0430\u044F\u0432\u043A\u0438", name: AddShippingFieldName.REQUEST_DATE, rules: [{ required: false }] },
                            React.createElement(DatePicker, { style: { width: "100%" } }))))))));
    if (!task && !isNew) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { size: "large" })));
    }
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(TaskForm, { onFinish: handlerFinish, taskForm: taskForm, initialValues: initialValues }),
        children));
};
export default ShippingTaskForm;
