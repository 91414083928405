import dtoToEntity from './index';
import { productTypeCategoryAdapter } from './productType';
export const productAdapter = (dto) => {
    const { id, title, titleCompiled, productAttributes, isExternal, } = dto;
    return {
        id: id || -1,
        title,
        titleCompiled,
        isExternal,
        productAttributes,
    };
};
export const productAttributeAdapter = (dto) => {
    const { attributeValue, handbookValueId, handbookValueReadable, id, } = dto;
    return {
        id: id,
        attributeValue: attributeValue !== null && attributeValue !== void 0 ? attributeValue : undefined,
        handbookValueId: handbookValueId !== null && handbookValueId !== void 0 ? handbookValueId : undefined,
        handbookValueReadable: handbookValueReadable !== null && handbookValueReadable !== void 0 ? handbookValueReadable : undefined,
    };
};
export const productTypeViewAdapter = (dto) => {
    var _a;
    const { productTypeCategories, title, description, id, productCategoryId, titleMask, isExternal, sizeFormula } = dto;
    return {
        id: id,
        productCategoryId: productCategoryId,
        titleMask: titleMask === null ? undefined : titleMask,
        isExternal,
        title: title === null ? undefined : title,
        sizeFormula: sizeFormula,
        description: description === null ? undefined : description,
        productTypeCategories: (_a = productTypeCategories === null || productTypeCategories === void 0 ? void 0 : productTypeCategories.map(productTypeCategoryAdapter)) !== null && _a !== void 0 ? _a : [],
    };
};
export const productViewGraphglAdapter = (dto) => {
    const { handbookPackingSet, handbookUnit, id, productAttributeValues, productType, titleCompiled, } = dto;
    const productAttributeEntities = {};
    for (const key in productAttributeValues) {
        productAttributeEntities[key] = productAttributeAdapter(productAttributeValues[key]);
    }
    return {
        id: id,
        handbookPackingSet: handbookPackingSet ? dtoToEntity.handbookPackingSetAdapter(handbookPackingSet) : undefined,
        handbookUnit: dtoToEntity.handbookUnitAdapter(handbookUnit),
        productAttributeValues: productAttributeEntities,
        productType: dtoToEntity.productTypeViewAdapter(productType),
        titleCompiled: titleCompiled !== null && titleCompiled !== void 0 ? titleCompiled : undefined,
    };
};
export const productViewAdapter = (dto) => {
    const { handbookPackingSet, handbookUnit, id, productAttributeValues, productType, titleCompiled, } = dto;
    const productAttributeEntities = {};
    for (const key in productAttributeValues) {
        productAttributeEntities[key] = productAttributeAdapter(productAttributeValues[key]);
    }
    return {
        id: id,
        handbookPackingSet: handbookPackingSet ? dtoToEntity.handbookPackingSetAdapter(handbookPackingSet) : undefined,
        handbookUnit: dtoToEntity.handbookUnitAdapter(handbookUnit),
        productAttributeValues: productAttributeEntities,
        productType: dtoToEntity.productTypeViewAdapter(productType),
        titleCompiled: titleCompiled !== null && titleCompiled !== void 0 ? titleCompiled : undefined,
    };
};
