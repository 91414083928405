import { Button, Card, Col, Divider, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import MDIcon from "../../../../components/ui/MDIcon";
import SkuSearchModalWrapper from "../../../../components/nomenclatureSearch/sku/SkuSearchModal/SkuSearchModalWrapper";
function ShippingTaskFormNomenclature(props) {
    const { editable, children, warehouseId, isLoading, onAddSku } = props;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => {
        setModalIsOpen(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, null,
            React.createElement(Space, { direction: "vertical", className: "task-loading-change__nomenclature mt-3" },
                React.createElement("div", null,
                    React.createElement(Row, null,
                        React.createElement(Col, { flex: "auto" },
                            React.createElement(Typography.Title, { level: 3, style: { marginBottom: 0 } }, "\u041D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440\u0430")),
                        React.createElement(Col, { flex: "none" }, editable ? (React.createElement(Button.Group, null,
                            React.createElement(Button, { loading: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "plus-circle", className: "anticon" }), onClick: openModal }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C sku"))) : null)),
                    React.createElement(Divider, null)),
                children)),
        React.createElement(SkuSearchModalWrapper, { warehouseId: [`${warehouseId}`], filterIncludePersonal: true, onAdd: onAddSku, visible: modalIsOpen, setModelVisible: setModalIsOpen })));
}
export default ShippingTaskFormNomenclature;
