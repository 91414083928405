import React, { useEffect } from "react";
import StatusHistory from "../../../../components/StatusHistory";
import { useTaskShipping } from "../../../../hooks/api/taskShippingApi/useTaskShipping";
import { useAdapters } from "../../../../hooks/useAdapters";
const ShippingStatusHistoryContainer = (props) => {
    var _a;
    const { taskId } = props;
    const { history } = useTaskShipping();
    const { normalizeHistory } = useAdapters();
    useEffect(() => {
        history.getHistory({ variables: { taskId: taskId } });
    }, []);
    return React.createElement(StatusHistory, { history: ((_a = history === null || history === void 0 ? void 0 : history.data) === null || _a === void 0 ? void 0 : _a.length) ? normalizeHistory(history.data) : [], isLoading: history.loading });
};
export default ShippingStatusHistoryContainer;
