var TaskType;
(function (TaskType) {
    TaskType["INVENTORY"] = "TaskInventory";
    TaskType["LOADING"] = "TaskLoading";
    TaskType["MOVEMENT"] = "TaskMovement";
    TaskType["PARTITION"] = "TaskPartition";
    TaskType["PARTITION_ORIGINAL"] = "TaskPartitionOriginal";
    TaskType["PARTITION_CREATED"] = "TaskPartitionCreated";
    TaskType["CORRECTION_ORIGINAL"] = "TaskCorrectionOriginal";
    TaskType["CORRECTION_CREATED"] = "TaskCorrectionCreated";
    TaskType["SHIPPING"] = "TaskShipping";
    TaskType["CHANGE_WAREHOUSE"] = "TaskChangeWarhouse";
    TaskType["CORRECTION"] = "TaskCorrection";
    TaskType["WRITE_OFF"] = "TaskWriteOff";
})(TaskType || (TaskType = {}));
export default TaskType;
