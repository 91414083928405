import React, { useEffect, useRef } from 'react';
import { Addon, Graph, Shape } from '@antv/x6';
import { debounce } from 'lodash';
import { ReactShape } from '@antv/x6-react-shape';
import { Button, Typography } from 'antd';
import StatusNode from './StatusNode';
import MapNode from './MapNode';
import StatusConfiguratorInfoContainer from '../../core/containers/StatusConfiguratorInfoContainer';
const DEBOUNCE_TIMEOUT = 100;
const NODE_WIDTH = 150;
const NODE_HEIGHT = 50;
const StatusConfigurator = (props) => {
    const { objectClass, statusList, relationList, onAddClick, onStatusChange, onStatusSelect, } = props;
    const graphWrapRef = useRef();
    const graphRef = useRef();
    const stencilRef = useRef();
    const miniMapRef = useRef();
    let graph;
    let stencil;
    useEffect(() => {
        graph = new Graph({
            container: graphRef.current,
            grid: {
                visible: true,
            },
            scroller: {
                enabled: true,
                pageVisible: true,
                pageBreak: false,
                pannable: true,
            },
            mousewheel: {
                enabled: true,
                zoomAtMousePosition: true,
                modifiers: 'ctrl',
                minScale: 0.5,
                maxScale: 3,
            },
            // panning: {
            //   enabled: true,
            //   eventTypes: ['leftMouseDown', 'mouseWheel'],
            // },
            connecting: {
                router: {
                    name: 'orth',
                    args: {
                        // direction: 'T',
                        merge: true,
                        padding: 10,
                    },
                },
                connector: {
                    name: 'rounded',
                    args: {
                        radius: 8,
                    },
                },
                anchor: 'center',
                allowBlank: false,
                snap: {
                    radius: 20,
                },
                createEdge() {
                    return new Shape.Edge({
                        attrs: {
                            line: {
                                stroke: '#A2B1C3',
                                strokeWidth: 2,
                                targetMarker: {
                                    name: 'block',
                                    width: 12,
                                    height: 8,
                                },
                            },
                        },
                        zIndex: 0,
                    });
                },
                validateConnection({ targetMagnet }) {
                    return !!targetMagnet;
                },
            },
            highlighting: {
                magnetAdsorbed: {
                    name: 'stroke',
                    args: {
                        attrs: {
                            fill: '#5F95FF',
                            stroke: '#5F95FF',
                        },
                    },
                },
            },
            selecting: {
                enabled: true,
                multiple: true,
                rubberEdge: true,
                rubberNode: true,
                modifiers: 'shift',
                rubberband: true,
                showNodeSelectionBox: true,
            },
            minimap: {
                enabled: true,
                container: miniMapRef.current,
                width: 200,
                height: 160,
                padding: 10,
                graphOptions: {
                    async: true,
                    // eslint-disable-next-line consistent-return
                    getCellView(cell) {
                        if (cell.isNode()) {
                            return MapNode;
                        }
                    },
                    // eslint-disable-next-line consistent-return
                    createCellView(cell) {
                        if (cell.isEdge()) {
                            return null;
                        }
                    },
                },
            },
            snapline: true,
            keyboard: true,
            clipboard: true,
        });
        const archiveList = statusList
            .filter((status) => !status.isActive)
            .map((status) => new ReactShape({
            id: status.id.toString(),
            x: status.xPos,
            y: status.yPos,
            width: NODE_WIDTH,
            height: NODE_HEIGHT,
            shape: 'react-shape',
            component() {
                return (React.createElement(StatusNode, { color: status.style }, status.title));
            },
        }));
        stencil = new Addon.Stencil({
            target: graph,
            stencilGraphWidth: 170,
            title: '',
            stencilGraphHeight: archiveList.length * 60 + 10,
            layoutOptions: {
                columns: 1,
                columnWidth: 150,
                rowHeight: 60,
            },
            getDragNode: (node) => node.clone({ keepId: true }),
            getDropNode(node) {
                // @ts-ignore
                const { x, y } = node.store.previous.position;
                const status = statusList.find((item) => item.id.toString() === node.id);
                if (status) {
                    onStatusChange(status.id, {
                        title: status.title,
                        style: status.style,
                        isActive: true,
                        isInitial: status.isInitial,
                        isDefaultForFilter: status.isDefaultForFilter,
                        isFinal: status.isFinal,
                        isCancel: status.isCancel,
                        xPos: parseInt(x, 10),
                        yPos: parseInt(y, 10),
                    });
                    return new ReactShape({
                        id: status.id.toString(),
                        x: 0,
                        y: 0,
                        width: NODE_WIDTH,
                        height: NODE_HEIGHT,
                        shape: 'react-shape',
                        component() {
                            return (React.createElement(StatusNode, { color: status.style }, status.title));
                        },
                    });
                }
                return node;
            },
        });
        stencil.load(archiveList);
        stencilRef.current.appendChild(stencil.container);
        statusList
            .filter((status) => status.isActive)
            .forEach((status) => {
            const entryPoints = relationList.filter((relation) => relation.isEntryPoint && relation.statusToId === status.id).length;
            graph === null || graph === void 0 ? void 0 : graph.addNode({
                id: status.id.toString(),
                x: status.xPos,
                y: status.yPos,
                width: NODE_WIDTH,
                height: NODE_HEIGHT,
                shape: 'react-shape',
                component() {
                    return (React.createElement(StatusNode, { color: status.style, count: entryPoints }, status.title));
                },
            });
        });
        relationList
            .filter((relation) => relation.statusFromId && relation.statusToId)
            .forEach((relation) => {
            var _a, _b;
            graph === null || graph === void 0 ? void 0 : graph.addEdge({
                zIndex: 0,
                source: {
                    cell: (_b = (_a = relation.statusFromId) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '',
                },
                target: {
                    cell: relation.statusToId.toString(),
                },
            });
        });
        graph.on('node:moved', ({ node }) => {
            const { x, y } = node.position();
            const status = statusList.find((item) => item.id.toString() === node.id);
            if (status) {
                onStatusChange(status.id, {
                    title: status.title,
                    style: status.style,
                    isActive: status.isActive,
                    isInitial: status.isInitial,
                    isDefaultForFilter: status.isDefaultForFilter,
                    isFinal: status.isFinal,
                    isCancel: status.isCancel,
                    xPos: x,
                    yPos: y,
                });
            }
        });
        graph.on('node:click', ({ node }) => {
            onStatusSelect(Number(node.id));
        });
        graph.on('blank:click', () => {
            onStatusSelect(null);
        });
        stencil === null || stencil === void 0 ? void 0 : stencil.load(graph === null || graph === void 0 ? void 0 : graph.getNodes(), 'sdf');
        const resize = debounce(() => {
            const wrapperHeight = graphWrapRef.current ? graphWrapRef.current.clientHeight : 0;
            const wrapperWidth = graphWrapRef.current ? graphWrapRef.current.clientWidth : 0;
            graph === null || graph === void 0 ? void 0 : graph.resize(wrapperWidth, wrapperHeight);
        }, DEBOUNCE_TIMEOUT);
        window.addEventListener('load', resize);
        window.addEventListener('resize', resize);
        window.addEventListener('orientationchange', resize);
        return () => {
            window.removeEventListener('load', resize);
            window.removeEventListener('resize', resize);
            window.removeEventListener('orientationchange', resize);
            graph === null || graph === void 0 ? void 0 : graph.dispose();
        };
    }, [statusList, relationList]);
    // const [statusIdList, setStatusIdList] = useState<number[]>([]);
    // useEffect(() => {
    //   console.log('statusIdList', statusIdList);
    //   const updates = updateNodes(
    //     statusList,
    //     statusIdList,
    //     (status: StatusEntity) => {
    //       console.log('ADD_NODE', status);
    //       console.log('NODES', graph?.getNodes());
    //       graph?.addNode({
    //         id: status.id.toString(),
    //         x: status.xPos,
    //         y: status.yPos,
    //         // ports: {
    //         //   groups: {
    //         //     in: {
    //         //       position: 'absolute',
    //         //       attrs: {
    //         //         circle: {
    //         //           r: 6,
    //         //           magnet: true,
    //         //           stroke: '#31d03e',
    //         //           strokeWidth: 2,
    //         //           fill: '#fff',
    //         //         },
    //         //       },
    //         //     },
    //         //     // 输出链接桩群组定义
    //         //     out: {
    //         //       position: 'absolute',
    //         //       attrs: {
    //         //         circle: {
    //         //           r: 6,
    //         //           magnet: true,
    //         //           stroke: '#d03131',
    //         //           strokeWidth: 2,
    //         //           fill: '#fff',
    //         //         },
    //         //       },
    //         //     },
    //         //   },
    //         //   items: [
    //         //     {
    //         //       id: 'port_in',
    //         //       group: 'in',
    //         //       args: {
    //         //         x: NODE_HEIGHT / 2,
    //         //         y: NODE_HEIGHT / 2,
    //         //       },
    //         //     },
    //         //     {
    //         //       id: 'port_out',
    //         //       group: 'out',
    //         //       args: {
    //         //         x: NODE_WIDTH - NODE_HEIGHT / 2,
    //         //         // dx: -250,
    //         //         y: NODE_HEIGHT / 2,
    //         //       },
    //         //     },
    //         //   ],
    //         // },
    //         width: NODE_WIDTH,
    //         height: NODE_HEIGHT,
    //         shape: 'react-shape',
    //         component() {
    //           return (
    //             <StatusNode color={status.style as any}>
    //               {status.title}
    //             </StatusNode>
    //           );
    //         },
    //       });
    //     },
    //     (id) => {
    //       graph?.removeNode(id.toString());
    //     },
    //   );
    //
    //   console.log('updates', updates);
    //   setStatusIdList(updates);
    // }, [statusList]);
    //
    // const [relationIdList, setRelationIdList] = useState<number[]>([]);
    // useEffect(() => {
    //   const updates = updateNodes(
    //     relationList.filter((relation) => relation.statusFromId && relation.statusToId),
    //     relationIdList,
    //     (relation: StatusRelationEntity) => {
    //       console.log('relation', {
    //         id: relation.id.toString(),
    //         zIndex: 0,
    //         source: {
    //           cell: relation.statusFromId?.toString() ?? '',
    //           // port: 'port_out',
    //         },
    //         target: {
    //           cell: relation.statusToId.toString(),
    //           // port: 'port_in',
    //         },
    //       });
    //       graph?.addEdge({
    //         id: relation.id.toString(),
    //         zIndex: 0,
    //         source: {
    //           cell: relation.statusFromId?.toString() ?? '',
    //           // port: 'port_out',
    //         },
    //         target: {
    //           cell: relation.statusToId.toString(),
    //           // port: 'port_in',
    //         },
    //       });
    //     },
    //     (id) => {
    //       graph?.removeEdge(id.toString());
    //     },
    //   );
    //
    //   setRelationIdList(updates);
    // }, [relationList]);
    return (React.createElement("div", { style: {
            display: 'flex',
            height: 'calc(100% + 30px)',
            margin: -15,
        } },
        React.createElement("div", { style: {
                width: 200, height: '100%', background: 'white', padding: 15, display: 'flex', flexDirection: 'column',
            } },
            React.createElement(Typography.Title, { level: 4 }, "\u0410\u0440\u0445\u0438\u0432"),
            React.createElement("div", { id: "stencil", ref: stencilRef, style: { position: 'relative', flexGrow: 1 } }),
            React.createElement(Button, { block: true, type: "primary", onClick: onAddClick }, "\u041D\u043E\u0432\u044B\u0439 \u0441\u0442\u0430\u0442\u0443\u0441")),
        React.createElement("div", { ref: graphWrapRef, style: { flexGrow: 1, position: 'relative' } },
            React.createElement("div", { id: "graph-container", ref: graphRef, style: {
                    position: 'absolute',
                    inset: 0,
                } }),
            React.createElement("div", { ref: miniMapRef, style: {
                    position: 'absolute',
                    bottom: 15,
                    right: 15,
                    opacity: 0.7,
                } })),
        React.createElement("div", { style: { position: 'relative', zIndex: 1 } },
            React.createElement(StatusConfiguratorInfoContainer, { objectClass: objectClass }))));
};
export default StatusConfigurator;
