import React from "react";
import { useSearchParams } from "react-router-dom";
import { usePagination } from "../../../../../../hooks/usePagination";
import { useGetHandbookPackingListQuery } from "../../../../../../api/graphql/queries/handbookPacking/HandbookPackingQuery";
import PaginationPanel from "../../../../../../components/PaginationPanel";
import { TYPE_ID_PARAM } from "../../../constants";
const PackingListPaginationContainer = () => {
    var _a;
    const [searchParams] = useSearchParams();
    const productTypeParam = searchParams.get(TYPE_ID_PARAM);
    const productTypeId = productTypeParam ? +productTypeParam : null;
    const { page, pageSize, setPagination } = usePagination();
    const { data, loading } = useGetHandbookPackingListQuery({
        variables: {
            page,
            pageSize,
            handbookPackingSet: productTypeId
        },
    });
    const handlerChange = (nextPage, nextPageSize) => {
        setPagination({
            page: nextPage,
            pageSize: nextPageSize,
        });
    };
    const pagination = (_a = data === null || data === void 0 ? void 0 : data.handbookPackingList) === null || _a === void 0 ? void 0 : _a.pagination;
    if (data && pagination) {
        return React.createElement(PaginationPanel, { pagination: pagination, disabled: loading || !productTypeId, onChange: handlerChange });
    }
    return null;
};
export default PackingListPaginationContainer;
