import { Button, Dropdown, Space, Spin, } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TreeView from '../../components/TreeView/TreeView';
import TreeViewContectMenu from '../../components/TreeView/TreeViewContextMenu';
import Centered from '../../components/ui/Centered';
import MDIcon from '../../components/ui/MDIcon';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import handbookApi from '../../redux/api/handbookApi';
import handbookCategoryApi from '../../redux/api/handbookApi/handbookCategory';
import { selectHandbookTree } from '../../redux/reducers/handbookTreeReducer';
import { HANDBOOK_PARAM } from '../pages/HandbookPage';
import HandbookCategoryModal from './HandbookCategoryModal';
import HandbookModal from './HandbookModal';
const { useFetchCategoryListQuery, useDeleteCategoryMutation, } = handbookCategoryApi;
const { useDeleteHandbookMutation, } = handbookApi;
const buildTreeData = (categories, handbooks) => {
    var _a;
    const ROOT_ID = 'root';
    if (categories.length === 0) {
        return [];
    }
    const sortObject = categories.reduce((acc, item) => {
        var _a, _b, _c, _d, _e;
        const parentkey = (_a = item.parentId) !== null && _a !== void 0 ? _a : ROOT_ID;
        return Object.assign(Object.assign({}, acc), { [item.id]: Object.assign(Object.assign({}, item), { childCategoryIdList: (_c = (_b = acc[item.id]) === null || _b === void 0 ? void 0 : _b.childCategoryIdList) !== null && _c !== void 0 ? _c : [], childTypes: [] }), [parentkey]: Object.assign(Object.assign({}, acc[parentkey]), { childCategoryIdList: [...((_e = (_d = acc[parentkey]) === null || _d === void 0 ? void 0 : _d.childCategoryIdList) !== null && _e !== void 0 ? _e : []), item.id] }) });
    }, {});
    handbooks.forEach((handbook) => {
        var _a, _b;
        if (handbook.handbookCategoryId) {
            (_b = (_a = sortObject[handbook.handbookCategoryId]) === null || _a === void 0 ? void 0 : _a.childTypes) === null || _b === void 0 ? void 0 : _b.push(handbook);
        }
    });
    const adapter = (item, object) => {
        var _a, _b, _c, _d;
        return ({
            key: `category-${item.id}`,
            id: item.id,
            parentId: {
                label: object[(_a = item.parentId) !== null && _a !== void 0 ? _a : ROOT_ID].title,
                value: (_b = item.parentId) !== null && _b !== void 0 ? _b : ROOT_ID,
            },
            title: item.title,
            children: [
                ...((_d = (_c = item.childCategoryIdList) === null || _c === void 0 ? void 0 : _c.map((child) => adapter(object[child], object))) !== null && _d !== void 0 ? _d : []),
                ...item.childTypes.map((type) => ({
                    key: `type-${type.id}`,
                    id: type.id,
                    parentId: {
                        label: item.title,
                        value: item.id,
                    },
                    title: type.title,
                    isLeaf: true,
                    children: [],
                })),
            ],
        });
    };
    return (_a = sortObject[ROOT_ID]) === null || _a === void 0 ? void 0 : _a.childCategoryIdList.map((childId) => adapter(sortObject[childId], sortObject));
};
const HandbookTreeContainer = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { categories, handbooks } = useTypedSelector(selectHandbookTree);
    const { isLoading } = useFetchCategoryListQuery({ page: 1, pageSize: 1000 });
    const [selectNode, setSelectNode] = useState(null);
    const [deleteCategory] = useDeleteCategoryMutation();
    const [deleteHandbook] = useDeleteHandbookMutation();
    const dispatch = useAppDispatch();
    const treeData = useMemo(() => ([{
            id: null,
            key: '',
            title: 'Справочники',
            children: buildTreeData(categories, handbooks),
        }]), [categories, handbooks]);
    const [handbookModalVisible, setHandbookModalVisible] = useState(false);
    const [handbookModalInitialValues, setHandbookModalInitialValues,] = useState();
    const [categoryModalVisible, setCategoryModalVisible] = useState(false);
    const [categoryModalInitialValues, setCategoryModalInitialValues,] = useState();
    useEffect(() => {
        if (categories.length > 0) {
            categories.forEach((category) => {
                new Promise((resolve) => {
                    dispatch(handbookApi.endpoints.fetchHandbookList.initiate({
                        handbookCategoryId: category.id,
                        page: 1,
                        pageSize: 1000,
                    }))
                        .unwrap()
                        .then(() => {
                        resolve();
                    });
                });
            });
        }
    }, [categories]);
    const handlerSelect = (node) => {
        setSelectNode((node === null || node === void 0 ? void 0 : node.id) ? node : null);
        if (node.isLeaf) {
            searchParams.set(HANDBOOK_PARAM, node.id.toString());
            setSearchParams(searchParams);
        }
    };
    const handlerAdd = (parent, isCategory) => {
        if (isCategory) {
            setCategoryModalVisible(true);
            setCategoryModalInitialValues({
                parentId: parent,
            });
        }
        else {
            setHandbookModalVisible(true);
            setHandbookModalInitialValues({
                handbookCategoryId: parent,
            });
        }
    };
    const handlerEdit = (id, isCategory) => {
        if (isCategory) {
            const category = categories.find((item) => item.id === id);
            const parentCategory = categories.find((item) => item.id === category.parentId);
            setCategoryModalVisible(true);
            setCategoryModalInitialValues({
                id: category.id,
                parentId: parentCategory ? {
                    label: parentCategory.title,
                    value: parentCategory.id,
                } : undefined,
                title: category.title,
            });
        }
        else {
            const handbook = handbooks.find((item) => item.id === id);
            const category = categories.find((item) => item.id === handbook.handbookCategoryId);
            setHandbookModalVisible(true);
            setHandbookModalInitialValues({
                id: handbook.id,
                handbookCategoryId: {
                    label: category.title,
                    value: category.id,
                },
                title: handbook.title,
                description: handbook.description,
            });
        }
    };
    const handlerDelete = (id, isCategory) => {
        if (id) {
            if (isCategory) {
                deleteCategory(id);
            }
            else {
                deleteHandbook(id);
                if (id.toString() === searchParams.get(HANDBOOK_PARAM)) {
                    searchParams.delete(HANDBOOK_PARAM);
                    setSearchParams(searchParams);
                }
            }
        }
    };
    if (isLoading && categories.length === 0) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { spinning: true })));
    }
    const menu = TreeViewContectMenu({
        currentNode: selectNode,
        onAdd: handlerAdd,
        onEdit: handlerEdit,
        onDelete: handlerDelete,
        foldersName: "Категорию",
        itemsName: "Справочник"
    });
    const contextMenu = (currentNode) => {
        const node = (currentNode === null || currentNode === void 0 ? void 0 : currentNode.id) ? currentNode : null;
        return TreeViewContectMenu({
            currentNode: node,
            onAdd: handlerAdd,
            onEdit: handlerEdit,
            onDelete: handlerDelete,
            foldersName: "Категорию",
            itemsName: "Справочник"
        });
    };
    return (React.createElement("div", { style: {
            padding: 15, height: '100%', display: 'flex', flexDirection: 'column', gap: 10, overflowY: "auto"
        } },
        React.createElement(TreeView, { style: {
                flexGrow: 1, overflow: 'auto', marginRight: -14, paddingRight: 14,
            }, defaultExpandedKeys: [''], onSelect: handlerSelect, emptyText: (React.createElement(Space, { direction: "vertical", align: "center" },
                React.createElement("div", null, "\u0421\u043F\u0438\u0441\u043E\u043A \u0441\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A\u043E\u0432 \u043F\u0443\u0441\u0442"),
                React.createElement(Button, { type: "primary" }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"))), data: treeData, contexMenu: contextMenu }),
        React.createElement(Dropdown, { menu: menu, trigger: ['click'] },
            React.createElement(Button, { block: true, icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }) }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C")),
        React.createElement(HandbookModal, { visible: handbookModalVisible, initialValues: handbookModalInitialValues, onClose: () => setHandbookModalVisible(false) }),
        React.createElement(HandbookCategoryModal, { visible: categoryModalVisible, initialValues: categoryModalInitialValues, onClose: () => setCategoryModalVisible(false) })));
};
export default HandbookTreeContainer;
