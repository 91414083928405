import { List, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { toDate } from '../../helpers/function';
const CommentsItem = (props) => {
    const { comment } = props;
    const createDate = toDate(comment.createdAt);
    const data = {
        author: comment.user.userName,
        content: comment.message,
        datetime: (React.createElement(Tooltip, { title: moment(createDate).format('dd D MMM YYYY HH:mm:ss') }, moment(createDate).fromNow())),
    };
    return (React.createElement(List.Item, null,
        React.createElement(List.Item.Meta, { title: data.author, description: data.datetime }),
        data.content));
};
export default CommentsItem;
