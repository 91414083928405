import React from "react";
import { useSearchParams } from "react-router-dom";
import WarehouseCellTypesTable from "../../components/WarehouseCellTypesTable";
import { adaptData } from "../pages/handbookWarehouses/WarehouseCellsTypePage/useWarehouseCellType";
const WarehouseCellTypesTableContainer = (props) => {
    var _a;
    const { warehouseId, isListLoading, data, deleteStorageCellType } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const isLoading = isListLoading || (deleteStorageCellType === null || deleteStorageCellType === void 0 ? void 0 : deleteStorageCellType.isLoading);
    const handlerDelete = (storageCellTypeId) => {
        deleteStorageCellType.handleAction({ warehouseId, storageCellTypeId });
    };
    const handlerEdit = (id) => {
        searchParams.set("current", id.toString());
        setSearchParams(searchParams);
    };
    return React.createElement(WarehouseCellTypesTable, { items: (_a = adaptData(data)) !== null && _a !== void 0 ? _a : [], loading: isLoading, onDelete: handlerDelete, onEdit: handlerEdit });
};
export default WarehouseCellTypesTableContainer;
