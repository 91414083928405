import { Table } from "antd";
import React, { useMemo } from "react";
import { createColumns } from "./Columns";
import { skuActions } from "../../../../../../redux/reducers/skuReducer";
import { uniqueId } from "lodash";
import { useAppDispatch } from "../../../../../../hooks/useAppDispatch";
const TableContainer = ({ taskWriteOffSkuDto }) => {
    const dispatch = useAppDispatch();
    const handlerSkuInfoClick = (skuId) => {
        dispatch(skuActions.open(skuId));
    };
    const columns = useMemo(() => createColumns(handlerSkuInfoClick), []);
    return React.createElement(Table, { rowKey: () => Number(uniqueId()), style: { whiteSpace: "nowrap" }, columns: columns, dataSource: taskWriteOffSkuDto, bordered: true, pagination: false, size: "small", scroll: { x: true } });
};
export default TableContainer;
