import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { links } from '../../constants/links';
import Page from '../../components/Page';
import Centered from '../../components/ui/Centered';
const NotFoundPage = () => {
    const navigate = useNavigate();
    const clickHandler = () => {
        navigate(links.homeLink.url());
    };
    return (React.createElement(Page, null,
        React.createElement(Centered, null,
            React.createElement(Result, { status: "404", title: "404", subTitle: "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430", extra: React.createElement(Button, { onClick: clickHandler, type: "primary" }, "\u041D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E") }))));
};
export default NotFoundPage;
