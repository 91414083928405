import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
const NavigationItem = (props) => {
    const { title, icon, isExternal, link, } = props;
    if (isExternal) {
        return (React.createElement("a", { className: `list-group-item mdi ${icon}`, href: link, target: "_blank", rel: "noopener noreferrer" }, title));
    }
    return (React.createElement(NavLink, { className: ({ isActive }) => classNames({
            'list-group-item': true,
            mdi: true,
            [`${icon}`]: true,
            active: isActive,
        }), to: link }, title));
};
export default NavigationItem;
