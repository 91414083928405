import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetStorageSectionLinearListDocument = gql `
    query GetStorageSectionLinearList($page: Int!, $pageSize: Int!, $search: String, $warehouseId: Long!) {
  storageSectionLinearList(
    page: $page
    pageSize: $pageSize
    search: $search
    warehouseId: $warehouseId
  ) {
    data {
      id
      address
      description
      sectionType {
        id
        title
      }
      title
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useGetStorageSectionLinearListQuery__
 *
 * To run a query within a React component, call `useGetStorageSectionLinearListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageSectionLinearListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageSectionLinearListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetStorageSectionLinearListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetStorageSectionLinearListDocument, options);
}
export function useGetStorageSectionLinearListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetStorageSectionLinearListDocument, options);
}
export function useGetStorageSectionLinearListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetStorageSectionLinearListDocument, options);
}
export const StorageSectionTypeListDocument = gql `
    query StorageSectionTypeList($warehouseId: Long!, $page: Int!, $pageSize: Int!, $search: String) {
  storageSectionTypeList(
    warehouseId: $warehouseId
    page: $page
    pageSize: $pageSize
    search: $search
  ) {
    data {
      id
      icon
      separator
      description
      title
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useStorageSectionTypeListQuery__
 *
 * To run a query within a React component, call `useStorageSectionTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageSectionTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageSectionTypeListQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useStorageSectionTypeListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StorageSectionTypeListDocument, options);
}
export function useStorageSectionTypeListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StorageSectionTypeListDocument, options);
}
export function useStorageSectionTypeListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(StorageSectionTypeListDocument, options);
}
export const StorageSectionTypeDocument = gql `
    query StorageSectionType($warehouseId: Long!, $storageSectionId: Long!) {
  storageSectionType(
    warehouseId: $warehouseId
    storageSectionId: $storageSectionId
  ) {
    id
    icon
    separator
    description
    title
  }
}
    `;
/**
 * __useStorageSectionTypeQuery__
 *
 * To run a query within a React component, call `useStorageSectionTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageSectionTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageSectionTypeQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      storageSectionId: // value for 'storageSectionId'
 *   },
 * });
 */
export function useStorageSectionTypeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StorageSectionTypeDocument, options);
}
export function useStorageSectionTypeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StorageSectionTypeDocument, options);
}
export function useStorageSectionTypeSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(StorageSectionTypeDocument, options);
}
export const StorageSectionListDocument = gql `
    query StorageSectionList($warehouseId: Long!, $page: Int!, $pageSize: Int!, $search: String, $parentId: Long) {
  storageSectionList(
    warehouseId: $warehouseId
    page: $page
    pageSize: $pageSize
    search: $search
    parentId: $parentId
  ) {
    data {
      id
      address
      parentId
      description
      title
      sectionType {
        description
        icon
        id
        separator
        title
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useStorageSectionListQuery__
 *
 * To run a query within a React component, call `useStorageSectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageSectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageSectionListQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useStorageSectionListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StorageSectionListDocument, options);
}
export function useStorageSectionListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StorageSectionListDocument, options);
}
export function useStorageSectionListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(StorageSectionListDocument, options);
}
export const StorageSectionDocument = gql `
    query StorageSection($warehouseId: Long!, $storageSectionId: Long!) {
  storageSection(warehouseId: $warehouseId, storageSectionId: $storageSectionId) {
    id
    address
    description
    title
    storageCells {
      address
      description
      title
      warehouseId
      id
    }
    storageSections {
      id
      address
      description
      title
    }
    storageSectionType {
      description
      icon
      id
      separator
      title
    }
  }
}
    `;
/**
 * __useStorageSectionQuery__
 *
 * To run a query within a React component, call `useStorageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageSectionQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      storageSectionId: // value for 'storageSectionId'
 *   },
 * });
 */
export function useStorageSectionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StorageSectionDocument, options);
}
export function useStorageSectionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StorageSectionDocument, options);
}
export function useStorageSectionSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(StorageSectionDocument, options);
}
