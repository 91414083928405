var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import { Card, Col, Divider, Form, message, Row, Spin, Typography, } from 'antd';
import { AddLoadingFieldName } from '../../../../api/rest/dto/tasks/loading/types';
import WarehouseSelect from '../../../../components/WarehouseSelect';
import TaskForm from '../../../../components/task/TaskForm';
const { Title } = Typography;
const formGutter = [15, 15];
const PartitionTaskForm = (props) => {
    const { isLoading, children, onSubmit, disableWarehouse, onLoad, } = props;
    useEffect(() => {
        if (onLoad)
            onLoad().finally();
    }, []);
    const handlerFinish = (values) => __awaiter(void 0, void 0, void 0, function* () {
        console.log("values", values);
        try {
            yield onSubmit(values.warehouseId.value);
        }
        catch (e) {
            message.error('Ошибка при сохранении задачи');
        }
    });
    const taskForm = (React.createElement(Col, { span: 24 },
        React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
            React.createElement(Title, { level: 3 }, "\u0420\u0435\u043A\u0432\u0438\u0437\u0438\u0442\u044B"),
            React.createElement(Divider, null),
            React.createElement(Row, { gutter: formGutter },
                React.createElement(Col, null,
                    React.createElement(Form.Item, { label: "\u0421\u043A\u043B\u0430\u0434", name: AddLoadingFieldName.WAREHOUSE, rules: [{ required: true }] },
                        React.createElement(WarehouseSelect, { disabled: disableWarehouse, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434" })))))));
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(TaskForm, { onFinish: handlerFinish, taskForm: taskForm }),
        children));
};
export default PartitionTaskForm;
