var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Result, Spin } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useRef, useState, } from 'react';
import LoadingState from '../../constants/enum/LoadingState';
import { request } from '../../api/rest/api';
import Centered from '../ui/Centered';
const PrintDialog = (props) => {
    const { loadingState, url, isOpen, title, onClose, changeLoadState, } = props;
    const iframeRef = useRef();
    const [document, setDocument] = useState();
    const loadDocument = () => __awaiter(void 0, void 0, void 0, function* () {
        if (url) {
            const { data } = yield request(changeLoadState, undefined, {
                responseType: 'arraybuffer',
            }).then((instance) => {
                return instance.get(url);
            });
            const blob = new Blob([data], { type: 'application/pdf' });
            const result = URL.createObjectURL(blob);
            setDocument(result);
        }
    });
    useEffect(() => {
        loadDocument().finally();
    }, [url]);
    const handlerRefresh = () => {
        if (iframeRef.current && url) {
            const frame = iframeRef.current.contentWindow;
            if (frame) {
                loadDocument().finally();
            }
        }
    };
    const handlerClose = () => {
        onClose();
    };
    const classNamesDialog = classNames({
        'print-dialog': true,
        show: isOpen,
    });
    const classNamesResult = classNames({
        'd-block': loadingState === LoadingState.Error,
        'd-none': loadingState !== LoadingState.Error,
    });
    const classNamesFrame = classNames({
        'd-block': loadingState !== LoadingState.Error,
        'd-none': loadingState === LoadingState.Error,
    });
    return (React.createElement("div", { className: classNamesDialog },
        React.createElement("div", { className: "print-dialog-header" },
            React.createElement("div", { className: "dialog-title" }, title),
            React.createElement("div", { className: "dialog-buttons" },
                React.createElement(Button.Group, null,
                    React.createElement(Button, { type: "default", className: "ant-btn-background-ghost", icon: React.createElement("span", { className: "mdi mdi-refresh" }), onClick: handlerRefresh }))),
            React.createElement("div", { className: "dialog-close mdi mdi-close", onClick: handlerClose })),
        React.createElement("div", { className: "print-dialog-body" },
            loadingState === LoadingState.Loading ? (React.createElement(Centered, null,
                React.createElement(Spin, { spinning: true, size: "large" }))) : (React.createElement("iframe", { title: "iframe", className: classNamesFrame, ref: iframeRef, src: document })),
            React.createElement(Result, { className: classNamesResult, status: "500", title: "500", subTitle: "Sorry, something went wrong." }))));
};
export default PrintDialog;
