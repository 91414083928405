export const useTaskDetails = (createMutation, editMutation, deleteMutation) => {
    const [createDetails, { loading: createDetailsLoading }] = createMutation();
    const [editDetails, { loading: editDetailsLoading }] = editMutation ? editMutation() : [null, { loading: false }];
    const [deleteDetails, { loading: deleteDetailsLoading }] = deleteMutation();
    return {
        taskDetails: {
            create: createDetails,
            edit: editDetails,
            delete: deleteDetails,
            loading: createDetailsLoading || deleteDetailsLoading || editDetailsLoading,
        },
    };
};
