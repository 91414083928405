import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskMovementListDocument = gql `
    query TaskMovementList($page: Int!, $pageSize: Int!, $filter: TaskFilterRequestInput!) {
  taskMovementList(page: $page, pageSize: $pageSize, filter: $filter) {
    data {
      id
      externalId
      createdAt
      updatedAt
      executedAt
      details {
        id
        type
        title
        skuId
        storageCellFrom {
          title
        }
        storageCellTo {
          title
        }
      }
      warehouse {
        id
        storageTitle
      }
      user {
        userName
      }
      status {
        title
        style
        scope
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useTaskMovementListQuery__
 *
 * To run a query within a React component, call `useTaskMovementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskMovementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskMovementListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskMovementListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskMovementListDocument, options);
}
export function useTaskMovementListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskMovementListDocument, options);
}
export function useTaskMovementListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskMovementListDocument, options);
}
export const TaskMovementDocument = gql `
    query TaskMovement($taskId: Long!) {
  taskMovement(taskId: $taskId) {
    id
    externalId
    createdAt
    updatedAt
    executedAt
    warehouse {
      id
      storageTitle
    }
    details {
      id
      skuId
      type
      title
      storageCellFrom {
        title
      }
      storageCellTo {
        title
      }
    }
    user {
      userName
    }
    status {
      title
      style
      scope
      relations {
        id
        title
        style
        needReport
      }
    }
  }
}
    `;
/**
 * __useTaskMovementQuery__
 *
 * To run a query within a React component, call `useTaskMovementQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskMovementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskMovementQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskMovementQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskMovementDocument, options);
}
export function useTaskMovementLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskMovementDocument, options);
}
export function useTaskMovementSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskMovementDocument, options);
}
export const GetTaskMovementHistoryDocument = gql `
    query getTaskMovementHistory($taskId: Long!) {
  taskMovementHistory(taskId: $taskId) {
    createdAt
    isEntryPoint
    needReport
    report
    statusFrom {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    statusTo {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    user {
      uid
      email
      userName
      id
    }
  }
}
    `;
/**
 * __useGetTaskMovementHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskMovementHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskMovementHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskMovementHistoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskMovementHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTaskMovementHistoryDocument, options);
}
export function useGetTaskMovementHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTaskMovementHistoryDocument, options);
}
export function useGetTaskMovementHistorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetTaskMovementHistoryDocument, options);
}
