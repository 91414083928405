var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { useGetTaskPartitionHistoryLazyQuery, useTaskPartitionLazyQuery, useTaskPartitionListLazyQuery } from "../../../api/graphql/queries/task/taskPartition/TaskPartitionQuery";
import { usePagination } from "../../usePagination";
import { useTaskFilter } from "../../useTaskFilter";
import { useTaskPartitionAddMutation, useTaskPartitionChangeStatusMutation } from "../../../api/graphql/queries/task/taskPartition/TaskPartitionMutation";
export const useTaskPartition = () => {
    var _a, _b;
    const [isDataReady, setIsDataReady] = useState(false);
    const [getById, { loading: getTaskByIdLoading, data: getTaskByIdData, refetch: refetchGqttaskById }] = useTaskPartitionLazyQuery();
    const [getTaskList, { loading: getTaskListLoading, data: getTaskListData }] = useTaskPartitionListLazyQuery({ onCompleted: () => { setIsDataReady(true); } });
    const [getHistory, { loading: getHistoryLoading, data: getHistoryData, refetch: refetchHistory }] = useGetTaskPartitionHistoryLazyQuery();
    const [addPartition, { loading: addPartitionLoading }] = useTaskPartitionAddMutation();
    const [changeStatus, { loading: changeStatusLoading }] = useTaskPartitionChangeStatusMutation();
    const { page, pageSize } = usePagination();
    const { filterValues } = useTaskFilter();
    const [filter, setFilter] = useState(null);
    const paginationFilteredGetTaskList = (filter = filterValues) => __awaiter(void 0, void 0, void 0, function* () {
        setFilter(filter);
        yield getTaskList({ variables: { page, pageSize, filter }, fetchPolicy: "no-cache" });
    });
    useEffect(() => {
        if (filter) {
            paginationFilteredGetTaskList(filter);
        }
    }, [page, pageSize]);
    const addPartitionAction = (request) => __awaiter(void 0, void 0, void 0, function* () {
        return yield addPartition({ variables: { input: request } });
    });
    return {
        history: {
            getHistory,
            data: getHistoryData === null || getHistoryData === void 0 ? void 0 : getHistoryData.taskPartitionHistory,
            loading: getHistoryLoading,
            refetch: refetchHistory,
        },
        task: {
            getById,
            changeStatus,
            addPartitionAction,
            data: getTaskByIdData === null || getTaskByIdData === void 0 ? void 0 : getTaskByIdData.taskPartition,
            loading: getTaskByIdLoading || addPartitionLoading || changeStatusLoading,
            refetch: refetchGqttaskById,
        },
        taskList: {
            data: (_a = getTaskListData === null || getTaskListData === void 0 ? void 0 : getTaskListData.taskPartitionList) === null || _a === void 0 ? void 0 : _a.data,
            loading: getTaskListLoading || !isDataReady,
            pagination: (_b = getTaskListData === null || getTaskListData === void 0 ? void 0 : getTaskListData.taskPartitionList) === null || _b === void 0 ? void 0 : _b.pagination,
            getTaskList,
            paginationFilteredGetTaskList,
        },
    };
};
