import dtoToEntity from '../../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../../constants/enum/Method';
import statusConfiguratorStatusApi, { STATUS_CONFIGURATOR_STATUS_TAG } from '..';
const getUri = (objectClass) => `status/${objectClass}/access`;
const statusConfiguratorAccessApi = statusConfiguratorStatusApi.injectEndpoints({
    endpoints: (builder) => ({
        addAccess: builder.mutation({
            query: (args) => {
                const { objectClass, values } = args;
                return {
                    method: Method.POST,
                    url: `${getUri(objectClass)}/add`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.statusAccessAdapter(response)),
            invalidatesTags: [STATUS_CONFIGURATOR_STATUS_TAG],
        }),
        editAccess: builder.mutation({
            query: (args) => {
                const { objectClass, statusAccessId, values } = args;
                return {
                    method: Method.PUT,
                    url: `${getUri(objectClass)}/${statusAccessId}`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.statusAccessAdapter(response)),
            invalidatesTags: [STATUS_CONFIGURATOR_STATUS_TAG],
        }),
        deleteAccess: builder.mutation({
            query: (args) => {
                const { objectClass, statusAccessId } = args;
                return {
                    method: Method.DELETE,
                    url: `${getUri(objectClass)}/${statusAccessId}`,
                };
            },
            transformResponse: (_, __, { statusAccessId }) => statusAccessId,
            invalidatesTags: [STATUS_CONFIGURATOR_STATUS_TAG],
        }),
    }),
    overrideExisting: false,
});
export default statusConfiguratorAccessApi;
