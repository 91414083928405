import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { links } from "../../../../constants/links";
import LoadingDetailNomenclatureItem from "../../../conponents/task/loading/LoadingDetailNomenclature/LoadingDetailNomenclatureItem";
import CommentsContainer from "../../../containers/CommentsContainer";
import FilesContainer from "../../../containers/FilesContainer";
import ProductInfoDrawerContainer from "../../../containers/ProductInfoDrawerContainer";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import LoadingStatusHistoryContainer from "../../../containers/task/loading/LoadingStatusHistoryContainer";
import LoadingTaskContainer from "../../../containers/task/loading/LoadingTaskContainer";
import LoadingTaskHeaderContainer from "../../../containers/task/loading/LoadingTaskHeaderContainer";
import LoadingTaskNomenclatureContainer from "../../../containers/task/loading/LoadingTaskNomenclatureContainer";
import { useTaskLoading } from "../../../../hooks/api/taskLoadingApi/useTaskLoading";
const LoadingTaskPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const objectInfo = {
        objectClass: ObjectClass.TaskLoading,
        objectId: taskId,
    };
    const { task } = useTaskLoading();
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const handlerChangeTask = () => navigate(links.taskLoadingLinks.edit.url(taskId));
    const pageProps = {
        toolbarTop: React.createElement(LoadingTaskHeaderContainer, { isLoading: task.loading, task: task.data, id: taskId, onChangeTask: handlerChangeTask, refetch: task.refetch }),
        toolbarRight: React.createElement(CommentsContainer, Object.assign({}, objectInfo)),
    };
    const containerProps = {
        id: taskId,
        nomenclatureTab: (React.createElement(LoadingTaskNomenclatureContainer, { refetch: task.refetch, taskId: taskId, isLoading: task === null || task === void 0 ? void 0 : task.loading, task: task === null || task === void 0 ? void 0 : task.data }, (detailProps) => React.createElement(LoadingDetailNomenclatureItem, Object.assign({}, detailProps)))),
        filesTab: React.createElement(FilesContainer, Object.assign({}, objectInfo)),
        historyTab: React.createElement(LoadingStatusHistoryContainer, { taskId: taskId }),
    };
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(LoadingTaskContainer, Object.assign({ task: task.data, isLoading: task.loading }, containerProps)),
        React.createElement(ProductInfoDrawerContainer, null),
        React.createElement(SkuInfoDrawerContainer, null)));
};
export default LoadingTaskPage;
