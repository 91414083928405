var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Empty, Typography } from "antd";
import React from "react";
import Scope from "../../../../constants/enum/Scope";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import { useTaskPartitionOriginal } from "../../../../hooks/api/taskPartitionApi/useTaskPartitionOriginal";
import PartitionTaskFormNomenclature from "../../../conponents/task/partition/PartitionTaskFormNomenclature";
import PartitionDetailNomenclatureFormItemContainer from "./PartitionDetailNomenclatureFormItemContainer";
const { Title, Text } = Typography;
const PartitionTaskFormNomenclatureContainer = (props) => {
    var _a;
    const { taskId, refetch, task, isLoading } = props;
    const { taskDetails } = useTaskPartitionOriginal();
    const handlerAddOriginal = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.create({ variables: { input: { taskId, input: values } } });
        yield refetch();
    });
    const renderDetails = () => {
        if ((task === null || task === void 0 ? void 0 : task.originals) && task.originals.length > 0) {
            return (React.createElement(React.Fragment, null, task.originals.map((nomenclature) => (React.createElement(PartitionDetailNomenclatureFormItemContainer, { refetch: refetch, taskId: task.id, nomenclature: nomenclature, warehouseId: task.warehouse.id })))));
        }
        return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: React.createElement(React.Fragment, null,
                React.createElement(Title, { level: 5 }, "\u0417\u0430\u0434\u0430\u0447\u0430 \u043D\u0435 \u043D\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0430."),
                React.createElement(Text, null, "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440\u0443.")) }));
    };
    return (React.createElement(PartitionTaskFormNomenclature, { editable: task ? taskHelpers.hasScope(task, Scope.DETAILS_EDIT) : false, isLoading: taskDetails.loading || isLoading, onAddSku: handlerAddOriginal, warehouseId: (task === null || task === void 0 ? void 0 : task.warehouse) ? [`${(_a = task === null || task === void 0 ? void 0 : task.warehouse) === null || _a === void 0 ? void 0 : _a.id}`] : [] }, renderDetails()));
};
export default PartitionTaskFormNomenclatureContainer;
