import { Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import Page from "../../../../../components/Page";
import PackingAddFormContainer from "../../components/PackingAddPage/PackingAddFormContainer";
import { TITLES, TITLE_PARAM } from "../../constants";
const { Title } = Typography;
const PackingAddPage = () => {
    const [searchParams] = useSearchParams();
    const titleParam = searchParams.get(TITLE_PARAM);
    return (React.createElement(Page, null,
        React.createElement(Row, null,
            React.createElement(Col, { span: 24 },
                React.createElement(Card, null,
                    React.createElement(Title, { level: 3 }, !!titleParam ? TITLES.packing_add_selected(titleParam) : TITLES.packing_add),
                    React.createElement(Divider, null),
                    React.createElement(PackingAddFormContainer, null))))));
};
export default PackingAddPage;
