import { Table } from "antd";
import { uniqueId } from "lodash";
import React, { useMemo } from "react";
import { createColumns } from "./Columns";
import { useAppDispatch } from "../../../../../../hooks/useAppDispatch";
import { skuActions } from "../../../../../../redux/reducers/skuReducer";
const TableContainer = ({ taskSkus, attrebutes }) => {
    const dispatch = useAppDispatch();
    const handlerSkuInfoClick = (skuId) => {
        dispatch(skuActions.open(skuId));
    };
    const normalizeAttributes = attrebutes.reduce((acc, item) => {
        acc[item.title] = { id: item === null || item === void 0 ? void 0 : item.id, unit: item === null || item === void 0 ? void 0 : item.unit };
        return acc;
    }, {});
    const columns = useMemo(() => createColumns(normalizeAttributes, handlerSkuInfoClick), []);
    return React.createElement(Table, { rowKey: () => Number(uniqueId()), style: { whiteSpace: "nowrap" }, columns: columns, dataSource: taskSkus.map((el) => (Object.assign(Object.assign({}, el), normalizeAttributes))), bordered: true, pagination: false, size: "small", scroll: { x: true } });
};
export default TableContainer;
