var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useProductDrawer } from "../../../../hooks/useProductDrawer";
import { skuActions } from "../../../../redux/reducers/skuReducer";
import { useTaskPartitionCRUDActions } from "../../../../hooks/api/taskPartitionApi/useTaskPartitionCRUDActions";
import { useTaskPartitionOriginal } from "../../../../hooks/api/taskPartitionApi/useTaskPartitionOriginal";
import PartitionDetailNomenclatureItem from "../../../conponents/task/partition/PartitionDetailNomenclatureItem";
const PartitionDetailNomenclatureItemContainer = (props) => {
    const { nomenclature, warehouseId, produceWork, taskId, refetch } = props;
    const dispatch = useAppDispatch();
    const { openProductDrawer } = useProductDrawer();
    const { actions } = useTaskPartitionCRUDActions();
    const { edit, isLoading } = actions;
    const { taskDetails } = useTaskPartitionOriginal();
    const handlerEditOriginal = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (taskDetails.edit) {
            yield taskDetails.edit({ variables: { input: value, taskId } });
            yield refetch();
        }
    });
    const handlerEditCreated = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield edit(taskId, value);
        yield refetch();
    });
    const handlerOpenInfo = (id) => {
        openProductDrawer(id);
    };
    const handlerOpenSkuInfo = (id) => {
        dispatch(skuActions.open(id));
    };
    return React.createElement(PartitionDetailNomenclatureItem, { isLoading: isLoading || taskDetails.loading, nomenclature: nomenclature, warehouseId: warehouseId, produceWork: produceWork, onEditCreated: handlerEditCreated, onEditOriginal: handlerEditOriginal, onInfoClick: handlerOpenInfo, onSkuInfoClick: handlerOpenSkuInfo });
};
export default PartitionDetailNomenclatureItemContainer;
