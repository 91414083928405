var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import { useCreateFileMutation } from "../../../api/graphql/queries/files/FilesMutation";
import { request } from "../../../api/rest/api";
import { adapt } from "./adapter";
export const useFileUpload = () => {
    const [createFile, { loading }] = useCreateFileMutation();
    const uploadFile = (url, args, setProgress) => __awaiter(void 0, void 0, void 0, function* () {
        return yield request(undefined, window.env.FileHost).then((instance) => {
            const { file, objectClass, objectId } = args;
            const formData = new FormData();
            formData.append("uploadFile", file);
            formData.set("ObjectClass", objectClass);
            formData.set("ObjectId", `${objectId}`);
            return instance
                .post(`/File/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (ev) => (ev === null || ev === void 0 ? void 0 : ev.total) &&
                    setProgress((prev) => {
                        const key = file.uid;
                        return Object.assign(Object.assign({}, prev), { [key]: ((ev === null || ev === void 0 ? void 0 : ev.total) && Math.round((ev.loaded * 100) / (ev === null || ev === void 0 ? void 0 : ev.total))) || 0 });
                    }),
            })
                .then((res) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b;
                if ((res === null || res === void 0 ? void 0 : res.data) && typeof (res === null || res === void 0 ? void 0 : res.data) === "object" && "id" in (res === null || res === void 0 ? void 0 : res.data) && typeof ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.id) === "string") {
                    yield createFile({
                        variables: {
                            input: { input: { fileId: (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.id, objectClass: adapt(objectClass), objectId } },
                        },
                    }).catch(() => {
                        message.error("Ошибка привязки файла");
                    });
                }
                else {
                    message.error("Ошибка отправки файла");
                }
            }))
                .catch(() => {
                message.error("Ошибка загрузки файла");
            });
        });
    });
    return { uploadFile, loading };
};
