export var AddShippingFieldName;
(function (AddShippingFieldName) {
    AddShippingFieldName["WAREHOUSE"] = "warehouseId";
    AddShippingFieldName["VEHICLE_NUMBER"] = "vehicleNumber";
    AddShippingFieldName["DRIVER_NAME"] = "driverName";
    AddShippingFieldName["POWER_OF_ATTORNEY"] = "powerOfAttorney";
    AddShippingFieldName["POWER_OF_ATTORNEY_DATE"] = "powerOfAttorneyDate";
    AddShippingFieldName["RELEASE_ALLOWED_NAME"] = "releaseAllowedName";
    AddShippingFieldName["RELEASE_ALLOWED_POST"] = "releaseAllowedPost";
    AddShippingFieldName["RELEASE_PRODUCED_NAME"] = "releaseProducedName";
    AddShippingFieldName["RELEASE_PRODUCED_POST"] = "releaseProducedPost";
    AddShippingFieldName["REQUEST_DATE"] = "requestDate";
    AddShippingFieldName["REQUEST_NUMBER"] = "requestNumber";
    AddShippingFieldName["CLIENT_FROM"] = "clientFromId";
    AddShippingFieldName["CLIENT_TO"] = "clientToId";
    AddShippingFieldName["UNLOADING_ADDRESS"] = "unloadingAddress";
    AddShippingFieldName["PAYER"] = "payerId";
    AddShippingFieldName["CARRIER"] = "carrierId";
})(AddShippingFieldName || (AddShippingFieldName = {}));
