import { Button, Card, Col, Divider, Form, Input, Row, Typography, } from 'antd';
import React, { useEffect, useState } from 'react';
const gutter = [24, 24];
const HandbookUnit = (props) => {
    const { initialValues, deletable, onSubmit, onDelete, } = props;
    const [touched, setTouched] = useState(false);
    const [form] = Form.useForm();
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);
    const handlerReset = () => {
        setTouched(false);
        form.resetFields();
    };
    const handlerSubmit = (values) => {
        onSubmit(values);
    };
    const handlerDelete = () => {
        if (onDelete)
            onDelete();
    };
    const handlerChange = () => {
        if (!touched)
            setTouched(true);
    };
    return (React.createElement(Card, null,
        React.createElement(Form, { form: form, layout: "vertical", onFinish: handlerSubmit, onFieldsChange: handlerChange, initialValues: initialValues },
            React.createElement(Row, { gutter: gutter },
                React.createElement(Col, { span: 8 },
                    React.createElement(Typography.Title, { level: 3 }, "\u041E\u0441\u043D\u043E\u0432\u043D\u043E\u0435"),
                    React.createElement(Divider, null),
                    React.createElement(Form.Item, { name: "title", label: "\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435", rules: [{ required: true, message: 'Поле не заполнено' }] },
                        React.createElement(Input, null)),
                    React.createElement(Form.Item, { name: "okeiCode", label: "\u041A\u043E\u0434 \u041E\u041A\u0415\u0418" },
                        React.createElement(Input, null))),
                React.createElement(Col, { span: 8 },
                    React.createElement(Typography.Title, { level: 3 }, "\u041D\u0430\u0446\u0438\u043E\u043D\u0430\u043B\u044C\u043D\u043E\u0435"),
                    React.createElement(Divider, null),
                    React.createElement(Form.Item, { name: "national", label: "\u0423\u0441\u043B\u043E\u0432\u043D\u043E\u0435 \u043E\u0431\u043E\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435", rules: [{ required: true, message: 'Поле не заполнено' }] },
                        React.createElement(Input, null)),
                    React.createElement(Form.Item, { name: "alfaNational", label: "\u041A\u043E\u0434\u043E\u0432\u043E\u0435 \u043E\u0431\u043E\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435" },
                        React.createElement(Input, null))),
                React.createElement(Col, { span: 8 },
                    React.createElement(Typography.Title, { level: 3 }, "\u0418\u043D\u0442\u0435\u0440\u043D\u0430\u0446\u0438\u043E\u043D\u0430\u043B\u044C\u043D\u043E\u0435"),
                    React.createElement(Divider, null),
                    React.createElement(Form.Item, { name: "international", label: "\u0423\u0441\u043B\u043E\u0432\u043D\u043E\u0435 \u043E\u0431\u043E\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435" },
                        React.createElement(Input, null)),
                    React.createElement(Form.Item, { name: "alfaInternational", label: "\u041A\u043E\u0434\u043E\u0432\u043E\u0435 \u043E\u0431\u043E\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435" },
                        React.createElement(Input, null)))),
            React.createElement(Row, { justify: "end", gutter: gutter, style: { marginTop: 20 } },
                React.createElement(Col, null,
                    React.createElement(Button.Group, null,
                        React.createElement(Button, { disabled: !touched, onClick: handlerReset }, "\u041E\u0442\u043C\u0435\u043D\u0430"),
                        deletable ? (React.createElement(Button, { danger: true, onClick: handlerDelete }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C")) : null,
                        React.createElement(Button, { type: "primary", htmlType: "submit" }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")))))));
};
export default HandbookUnit;
