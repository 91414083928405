export const initialState = {
    isLoading: true,
    isCellDtoLoading: true,
    isCellsLoading: true,
    isSectionsLoading: true,
    isWarehouseLoading: true,
    hasError: false,
    warehouseSection: [],
    warehouseCell: [],
    topology: [],
    cell: new Map(),
    activeNodeIds: [],
};
export const SCALE = 10;
export const defaultTools = [
    {
        name: "button-remove",
        args: { x: 0, y: 0 },
    },
];
