import React from "react";
import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import Page from "../../../../components/Page";
import HandbookWarehousePaginationContainer from "../../../containers/HandbookWarehousePaginationContainer";
import { links } from "../../../../constants/links";
import HandbookWarehousesTableContainer from "../../../containers/HandbookWarehousesTableContainer";
import MDIcon from "../../../../components/ui/MDIcon";
import { usePagination } from "../../../../hooks/usePagination";
import { useHandbookWarehousesPage } from "./useHandbookWarehousesPage";
const HandbookWarehousesPage = () => {
    var _a, _b, _c, _d;
    const navigate = useNavigate();
    const { page, pageSize, setPagination } = usePagination();
    const { getWarehouseList } = useHandbookWarehousesPage({ page, pageSize });
    const warehouseList = ((_b = (_a = getWarehouseList === null || getWarehouseList === void 0 ? void 0 : getWarehouseList.data) === null || _a === void 0 ? void 0 : _a.warehouseList) === null || _b === void 0 ? void 0 : _b.data) || [];
    const handlerEditItemClick = (id) => {
        navigate(links.handbookWarehouseInfoLink.url(id));
    };
    const handlerAddBtnClick = () => {
        navigate(links.handbookWarehouseAddLink.url());
    };
    return (React.createElement(Page, { toolbarBottom: React.createElement(HandbookWarehousePaginationContainer, { pagination: (_d = (_c = getWarehouseList === null || getWarehouseList === void 0 ? void 0 : getWarehouseList.data) === null || _c === void 0 ? void 0 : _c.warehouseList) === null || _d === void 0 ? void 0 : _d.pagination, setPagination: setPagination, isLoading: getWarehouseList === null || getWarehouseList === void 0 ? void 0 : getWarehouseList.isLoading }), toolbarTop: React.createElement(Row, { justify: "end" },
            React.createElement(Col, null,
                React.createElement(Button, { icon: React.createElement(MDIcon, { icon: "plus" }), type: "primary", onClick: handlerAddBtnClick }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u043A\u043B\u0430\u0434"))) },
        React.createElement(HandbookWarehousesTableContainer, { isListLoading: getWarehouseList === null || getWarehouseList === void 0 ? void 0 : getWarehouseList.isLoading, data: warehouseList, onEditItemClick: handlerEditItemClick })));
};
export default HandbookWarehousesPage;
