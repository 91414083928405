import { Form, Modal } from 'antd';
import React, { useEffect } from 'react';
import HandbookForm from '../../components/HandbookForm/HandbookForm';
import handbookApi from '../../redux/api/handbookApi';
const { useAddHandbookMutation, useEditHandbookMutation, } = handbookApi;
const HandbookModal = (props) => {
    const { initialValues, visible, onClose } = props;
    const [form] = Form.useForm();
    const [addHandbook, { isLoading: addIsLoading }] = useAddHandbookMutation();
    const [editHandbook, { isLoading: editIsLoading }] = useEditHandbookMutation();
    const isLoading = addIsLoading || editIsLoading;
    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                form.resetFields();
            }, 0);
        }
    }, [visible, initialValues]);
    const handleOk = () => form.submit();
    const handleCancel = () => {
        form.resetFields();
        onClose();
    };
    const handlerSubmit = (formValues) => {
        if (formValues.id === undefined) {
            addHandbook({
                title: formValues.title,
                description: formValues.description,
                handbookCategoryId: formValues.handbookCategoryId.value,
            })
                .unwrap()
                .then(() => handleCancel());
        }
        else {
            editHandbook({
                handbookId: formValues.id,
                values: {
                    title: formValues.title,
                    description: formValues.description,
                    handbookCategoryId: formValues.handbookCategoryId.value,
                },
            })
                .unwrap()
                .then(() => handleCancel());
        }
    };
    return (React.createElement(Modal, { open: visible, confirmLoading: isLoading, onOk: handleOk, onCancel: handleCancel, title: `${(initialValues === null || initialValues === void 0 ? void 0 : initialValues.id) === undefined ? 'Добавить' : 'Редактировать'} справочник`, okText: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", cancelText: "\u041E\u0442\u043C\u0435\u043D\u0430" },
        React.createElement(HandbookForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues })));
};
export default HandbookModal;
