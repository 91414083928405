import React, { useEffect } from 'react';
import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import { getColumnType } from '../../helpers/function';
import { links } from '../../constants/links';
import MDIcon from '../ui/MDIcon';
import { useAppDispatch } from '../../redux/store';
import { reset } from '../../redux/reducers/topologyReducer';
const HandbookWarehousesTable = (props) => {
    const { items, loading, onEditItemClick } = props;
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(reset());
    }, []);
    const columns = [
        getColumnType('storageTitle', 'Склад', false, {
            render: (value, record) => (React.createElement(Link, { to: links.handbookWarehouseLink.url(record.id) }, value)),
        }),
        getColumnType('address', 'Адрес'),
        getColumnType('timeZone', 'Часовой пояс'),
        getColumnType('actions', '', false, {
            width: '1%',
            render: (value, record) => (React.createElement(Button, { size: "small", icon: React.createElement(MDIcon, { icon: "pencil" }), onClick: () => onEditItemClick(record.id) })),
        }),
    ];
    const data = items.map((item) => ({
        key: item.id,
        id: item.id,
        storageTitle: item.storageTitle,
        address: item.address || '-',
        timeZone: item.timeZoneReadable,
    }));
    return (React.createElement(Table, { bordered: true, loading: loading, columns: columns, dataSource: data, pagination: false }));
};
export default HandbookWarehousesTable;
