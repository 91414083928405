var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import Scope from "../../../../constants/enum/Scope";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useTaskMovementDetails } from "../../../../hooks/api/taskMovementApi/useTaskMovementDetails";
import { skuActions } from "../../../../redux/reducers/skuReducer";
import MovementTaskFormNomenclature from "../../../conponents/task/movement/MovementTaskFormNomenclature";
const MovementTaskFormNomenclatureContainer = (props) => {
    const { taskId, task, isLoading, refetch } = props;
    const dispatch = useAppDispatch();
    const { taskDetails } = useTaskMovementDetails();
    const handlerAddSku = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.create({ variables: { input: { request: values, taskId } } });
        yield refetch();
    });
    const handlerDeleteSku = (detailId) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.delete({ variables: { input: { taskId, request: [detailId] } } });
        yield refetch();
    });
    const handlerEditSku = (values) => __awaiter(void 0, void 0, void 0, function* () {
        (taskDetails === null || taskDetails === void 0 ? void 0 : taskDetails.edit) && (yield taskDetails.edit({ variables: { input: { request: values, taskId } } }));
        yield refetch();
    });
    const handlerSkuInfoClick = (id) => {
        dispatch(skuActions.open(id));
    };
    return (React.createElement(MovementTaskFormNomenclature, { details: (task === null || task === void 0 ? void 0 : task.details) || [], editable: task ? taskHelpers.hasScope(task, Scope.DETAILS_EDIT) : false, warehouseId: task === null || task === void 0 ? void 0 : task.warehouse.id, isLoading: isLoading, onAddSku: handlerAddSku, onDeleteSku: handlerDeleteSku, onEditSku: handlerEditSku, onSkuInfoClick: handlerSkuInfoClick }));
};
export default MovementTaskFormNomenclatureContainer;
