var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { message } from "antd";
import Select from "antd/es/select";
import React, { useEffect, useState } from "react";
import { useHandbookValueListQuery } from "../../api/graphql/queries/handbook/HandbookQuery";
import HandbookSearchContainer from "../../core/containers/HandbookSearchContainer";
export const WithPreloadedOption = (props) => {
    var _a;
    const { handbookId, style, value, loading } = props, otherProps = __rest(props, ["handbookId", "style", "value", "loading"]);
    const [option, setOption] = useState(null);
    console.log("value", value);
    const { data, error, loading: queryLoading } = useHandbookValueListQuery({ variables: { handbookId, filterIds: typeof value === "string" ? [+value] : [value] } });
    useEffect(() => {
        var _a, _b, _c;
        if ((_b = (_a = data === null || data === void 0 ? void 0 : data.handbookValueList) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.length) {
            const handbookValue = (_c = data === null || data === void 0 ? void 0 : data.handbookValueList) === null || _c === void 0 ? void 0 : _c.data[0];
            setOption({ value: handbookValue.id, label: handbookValue.title });
        }
    }, [JSON.stringify((_a = data === null || data === void 0 ? void 0 : data.handbookValueList) === null || _a === void 0 ? void 0 : _a.data)]);
    useEffect(() => {
        if (error) {
            message.error("Ошибка получения данных справочника");
        }
    }, [error]);
    if (!queryLoading && !option) {
        return React.createElement(Select, null);
    }
    return queryLoading ? React.createElement(Select, { loading: true }) : React.createElement(HandbookSearchContainer, Object.assign({}, otherProps, { value: option, handbookId: handbookId || 0, style: style, loading: loading }));
};
