import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskStatusFragmentDoc = gql `
    fragment TaskStatus on TaskStatusDto {
  title
  isFinal
  style
  scope
  relations {
    id
    title
    style
    needReport
  }
}
    `;
export const TaskClientFragmentDoc = gql `
    fragment TaskClient on TaskClientDto {
  id
  companyName
  companyFullName
  companyInn
}
    `;
export const TaskCellFragmentDoc = gql `
    fragment TaskCell on TaskSkuStorageCell {
  address
  title
  id
}
    `;
export const TaskChangeWarehouseListDocument = gql `
    query taskChangeWarehouseList($page: Int!, $pageSize: Int!, $filter: TaskFilterRequestInput!) {
  taskChangeWarehouseList(page: $page, pageSize: $pageSize, filter: $filter) {
    data {
      id
      externalId
      createdAt
      updatedAt
      executedAt
      destinationWarehouse {
        storageTitle
      }
      skuGroup {
        title
        type
        count
        dimension
        productId
        quantity
      }
      warehouse {
        id
        storageTitle
      }
      user {
        id
        userName
      }
      status {
        title
        style
        scope
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useTaskChangeWarehouseListQuery__
 *
 * To run a query within a React component, call `useTaskChangeWarehouseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeWarehouseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskChangeWarehouseListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskChangeWarehouseListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskChangeWarehouseListDocument, options);
}
export function useTaskChangeWarehouseListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskChangeWarehouseListDocument, options);
}
export function useTaskChangeWarehouseListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskChangeWarehouseListDocument, options);
}
export const TaskChangeWarehouseDocument = gql `
    query taskChangeWarehouse($taskId: Long!) {
  taskChangeWarehouse(taskId: $taskId) {
    id
    externalId
    createdAt
    updatedAt
    acceptedAt
    executedAt
    shippedAt
    departureAllowedName
    departureAllowedPost
    departureDriverName
    departurePowerOfAttorney
    departurePowerOfAttorneyDate
    departureProducedName
    departureProducedPost
    departureVehicleNumber
    destinationAllowedName
    destinationAllowedPost
    destinationDriverName
    destinationPowerOfAttorney
    destinationPowerOfAttorneyDate
    destinationProducedName
    destinationProducedPost
    destinationVehicleNumber
    destinationWarehouse {
      id
      storageTitle
    }
    carrier {
      ...TaskClient
    }
    consignee {
      ...TaskClient
    }
    payer {
      ...TaskClient
    }
    shipper {
      ...TaskClient
    }
    skuGroup {
      attributes {
        canBeSummarized
        dataType
        handbookId
        id
        isRequired
        isPersonal
        title
        unit
      }
      count
      dimension
      productId
      quantity
      skus {
        skuId
        taskDetailId
        quantity
        status {
          title
          style
        }
        skuAttributeValues {
          key
          value {
            id
            value
            valueReadable
          }
        }
        storageCellFrom {
          ...TaskCell
        }
        storageCellTo {
          ...TaskCell
        }
      }
      title
      type
    }
    status {
      ...TaskStatus
    }
    warehouse {
      id
      storageTitle
    }
    user {
      id
      userName
    }
  }
}
    ${TaskClientFragmentDoc}
${TaskCellFragmentDoc}
${TaskStatusFragmentDoc}`;
/**
 * __useTaskChangeWarehouseQuery__
 *
 * To run a query within a React component, call `useTaskChangeWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskChangeWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskChangeWarehouseQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskChangeWarehouseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskChangeWarehouseDocument, options);
}
export function useTaskChangeWarehouseLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskChangeWarehouseDocument, options);
}
export function useTaskChangeWarehouseSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskChangeWarehouseDocument, options);
}
export const GetTaskChangeWarehouseHistoryDocument = gql `
    query getTaskChangeWarehouseHistory($taskId: Long!) {
  taskChangeWarehouseHistory(taskId: $taskId) {
    createdAt
    isEntryPoint
    needReport
    report
    statusFrom {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    statusTo {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    user {
      uid
      email
      userName
      id
    }
  }
}
    `;
/**
 * __useGetTaskChangeWarehouseHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskChangeWarehouseHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskChangeWarehouseHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskChangeWarehouseHistoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskChangeWarehouseHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTaskChangeWarehouseHistoryDocument, options);
}
export function useGetTaskChangeWarehouseHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTaskChangeWarehouseHistoryDocument, options);
}
export function useGetTaskChangeWarehouseHistorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetTaskChangeWarehouseHistoryDocument, options);
}
