var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Popconfirm, Space, Table, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import MDIcon from "../../../../components/ui/MDIcon";
import { ToleranceType } from "../../../../constants/enum/ToleranceType";
import { getColumnType } from "../../../../helpers/function";
import PartitionDetailNomenclatureActions from "./PartitionDetailNomenclatureActions";
import PartitionDetailNomenclatureCell from "./PartitionDetailNomenclatureCell";
const { confirm } = Modal;
const { Title, Text } = Typography;
const PartitionDetailNomenclatureFormItem = (props) => {
    const { nomenclature, isLoading, warehouseId, onDeleteItem, onAddCreated, onDeleteCreated, onEditCreated } = props;
    const { attributes, type, title, storageCell, id, quantityInitial, productId, created, skuId } = nomenclature;
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record) => record.id === editingKey;
    const labeledValueRender = (value) => {
        if (value && value.label) {
            return value.label;
        }
        return value;
    };
    const handlerDelete = () => {
        onDeleteItem(id);
    };
    const handlerAddCreated = (originalId) => {
        onAddCreated({
            originalId,
            quantity: 1,
            tolerancePercent: 0,
            toleranceType: "",
        });
    };
    const handlerDeleteCreated = (createdId) => {
        onDeleteCreated(createdId);
    };
    const handlerCancel = () => {
        setEditingKey("");
    };
    const handlerSave = (createdId) => __awaiter(void 0, void 0, void 0, function* () {
        const row = yield form.validateFields();
        const createdItem = created === null || created === void 0 ? void 0 : created.find((item) => item.id === createdId);
        let resultAttributes = [];
        if (row === null || row === void 0 ? void 0 : row.attributes) {
            const entries = Object.entries(row.attributes);
            resultAttributes = [
                ...entries.map((el) => {
                    var _a, _b;
                    return ({
                        skuAttributeValueFinal: (_a = createdItem === null || createdItem === void 0 ? void 0 : createdItem.attributes.find((attribute) => +attribute.taskPartitionOriginalAttributeId === +el[0])) === null || _a === void 0 ? void 0 : _a.skuAttributeValueFinal,
                        skuAttributeValueRequired: `${el[1]}`,
                        taskPartitionCreatedAttributeId: (_b = createdItem === null || createdItem === void 0 ? void 0 : createdItem.attributes.find((attribute) => +attribute.taskPartitionOriginalAttributeId === +el[0])) === null || _b === void 0 ? void 0 : _b.id,
                    });
                }),
            ];
        }
        const result = {
            createdId,
            storageCellId: row.storageCell.value,
            quantityRequired: Number(row.quantityInitial),
            toleranceType: row.toleranceType,
            tolerancePercent: row.tolerancePercent,
            attributes: resultAttributes,
        };
        onEditCreated(result);
        handlerCancel();
    });
    const showEditConfirm = (callback) => {
        confirm({
            title: `Изменения в товарной позиции #${editingKey} не сохранены`,
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: `Вы не сохранили изменения в товарной позиции #${editingKey}. Отменить все изменения?`,
            okText: "Да",
            okType: "danger",
            cancelText: "Нет",
            onOk() {
                callback();
            },
        });
    };
    const handlerEdit = (currentEditingKey, record) => {
        if (currentEditingKey) {
            showEditConfirm(() => {
                handlerCancel();
                handlerEdit("", record);
            });
        }
        else {
            const { id: formId, 
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            isOriginal: formIsOriginal, 
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            key: formKey, quantityInitial: formQuantityInitial, tolerancePercent: formTolerancePercent, toleranceType: formToleranceType, storageCell: formStorageCell, 
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            productId: formProductId } = record, attrs = __rest(record, ["id", "isOriginal", "key", "quantityInitial", "tolerancePercent", "toleranceType", "storageCell", "productId"]);
            form.setFieldsValue({
                id: formId,
                quantityInitial: formQuantityInitial,
                tolerancePercent: formTolerancePercent,
                toleranceType: formToleranceType,
                storageCell: formStorageCell,
                attributes: attrs,
            });
            setEditingKey(record.id);
        }
    };
    const columns = [
        getColumnType("id", "#", true, {
            fixed: "left",
            width: "1%",
        }),
        ...((attributes === null || attributes === void 0 ? void 0 : attributes.map((attr) => {
            const { id: attrId, title: attrTitle } = attr;
            return getColumnType(attrId, attrTitle, true, {
                width: "1%",
                onCell: (record) => ({
                    record,
                    value: record[attrId],
                    dataIndex: attrId,
                    title: attrTitle || "",
                    editing: isEditing(record),
                    isAttribute: true,
                }),
            });
        })) || []),
        getColumnType(""),
        getColumnType("storageCell", "Карман", false, {
            width: "1%",
            render: labeledValueRender,
            onCell: (record) => ({
                record,
                warehouseId,
                value: record.storageCell,
                dataIndex: "storageCell",
                title: "Карман",
                editing: isEditing(record),
            }),
        }),
        getColumnType("quantityInitial", "Кол-во", false, {
            width: "1%",
            onCell: (record) => ({
                record,
                value: record.quantityInitial,
                dataIndex: "quantityInitial",
                title: "Кол-во",
                editing: isEditing(record),
            }),
        }),
        {
            title: "Толеранс",
            children: [
                getColumnType("toleranceType", "Тип", false, {
                    width: "1%",
                    render: (value) => {
                        switch (value) {
                            case ToleranceType.POSITIVE: {
                                return React.createElement(MDIcon, { icon: "plus-circle-outline", color: "#52c41a", style: { fontSize: 24, lineHeight: "24px" } });
                            }
                            case ToleranceType.NEGATIVE: {
                                return React.createElement(MDIcon, { icon: "minus-circle-outline", color: "#ff4d4f", style: { fontSize: 24, lineHeight: "24px" } });
                            }
                            default: {
                                return "";
                            }
                        }
                    },
                    onCell: (record) => ({
                        record,
                        value: record.toleranceType,
                        dataIndex: "toleranceType",
                        title: "Тип",
                        editing: isEditing(record),
                    }),
                }),
                getColumnType("tolerancePercent", "Отклонение", false, {
                    width: "1%",
                    onCell: (record) => ({
                        record,
                        value: record.tolerancePercent,
                        dataIndex: "tolerancePercent",
                        title: "Отклонение",
                        editing: isEditing(record),
                    }),
                }),
            ],
        },
        getColumnType("actions", "", false, {
            width: "1%",
            render: (value, record) => {
                const isEditable = isEditing(record);
                return (React.createElement(PartitionDetailNomenclatureActions, { isLoading: isLoading, id: record.key, isEditable: isEditable, isOriginal: !!record.isOriginal, onDeleteCreated: handlerDeleteCreated, onAddCreated: handlerAddCreated, onEditCreated: () => handlerEdit(editingKey, record), onCancel: handlerCancel, onSaveCreated: () => handlerSave(record.key) }));
            },
        }),
    ];
    const dataOriginal = [
        Object.assign({ key: id, id: skuId, productId,
            quantityInitial, isOriginal: true, storageCell: {
                value: storageCell === null || storageCell === void 0 ? void 0 : storageCell.id,
                label: storageCell === null || storageCell === void 0 ? void 0 : storageCell.title,
            } }, attributes === null || attributes === void 0 ? void 0 : attributes.map((attr) => {
            const { id: attrId, skuAttributeValueInitial } = attr;
            return {
                [attrId]: skuAttributeValueInitial,
            };
        }).reduce((acc, attrValues) => (Object.assign(Object.assign({}, acc), attrValues)), {})),
    ];
    const dataCreated = (created === null || created === void 0 ? void 0 : created.map((createdItem) => {
        var _a, _b, _c;
        return (Object.assign({ key: createdItem.id, id: createdItem.skuId, isCreated: true, productId, quantityInitial: createdItem.quantityRequired, storageCell: {
                value: (_a = createdItem === null || createdItem === void 0 ? void 0 : createdItem.storageCell) === null || _a === void 0 ? void 0 : _a.id,
                label: (_b = createdItem === null || createdItem === void 0 ? void 0 : createdItem.storageCell) === null || _b === void 0 ? void 0 : _b.title,
            }, toleranceType: createdItem.toleranceType, tolerancePercent: createdItem.tolerancePercent }, (_c = createdItem === null || createdItem === void 0 ? void 0 : createdItem.attributes) === null || _c === void 0 ? void 0 : _c.map((el) => ({ [el.taskPartitionOriginalAttributeId]: el.skuAttributeValueRequired })).reduce((acc, item) => (Object.assign(Object.assign({}, acc), item)), {})));
    })) || [];
    const getData = () => {
        if (created && (created === null || created === void 0 ? void 0 : created.length) > 0) {
            return [...dataOriginal, ...dataCreated];
        }
        return dataOriginal;
    };
    const returnAddButton = () => (React.createElement(Popconfirm, { placement: "topLeft", title: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0442\u043E\u0432\u0430\u0440\u043D\u0443\u044E \u043F\u043E\u0437\u0438\u0446\u0438\u044E?", onConfirm: handlerDelete, okText: "\u0414\u0430", cancelText: "\u041E\u0442\u043C\u0435\u043D\u0430" },
        React.createElement(Tooltip, { title: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0442\u043E\u0432\u0430\u0440\u043D\u0443\u044E \u043F\u043E\u0437\u0438\u0446\u0438\u044E", mouseEnterDelay: 1 },
            React.createElement(Button, { loading: isLoading, icon: React.createElement(MDIcon, { icon: "delete" }), type: "primary", danger: true, size: "small", style: { marginBottom: "10px" } }))));
    return (React.createElement("div", { className: "mb-5" },
        React.createElement(Space, { style: { width: "auto" } },
            React.createElement(Title, { level: 4 }, type),
            React.createElement("div", { className: "mb-2" },
                React.createElement(Text, { underline: true }, title)),
            returnAddButton()),
        React.createElement(Form, { form: form, component: false },
            React.createElement(Table, { style: { whiteSpace: "nowrap" }, components: {
                    body: {
                        cell: PartitionDetailNomenclatureCell,
                    },
                }, columns: columns, dataSource: getData(), bordered: true, pagination: false, size: "small", scroll: { x: true } }))));
};
export default PartitionDetailNomenclatureFormItem;
