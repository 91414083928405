import React, { useContext } from "react";
import { WarehouseTopologyContext } from "./WarehouseTopology";
export const Map = ({}) => {
    const context = useContext(WarehouseTopologyContext);
    if (context === undefined) {
        throw new Error("Map must be used within a WarehouseTopology");
    }
    return (React.createElement("div", { style: { height: "100%", position: "relative" }, ref: context.setCardRef },
        React.createElement("div", { ref: context.setGaphRef }),
        React.createElement("div", { ref: context.setMinimapContainerRef, style: {
                position: "absolute",
                right: 0,
                top: 0,
                width: "200px",
                height: "100px",
                backgroundColor: "#f0f0f0",
            } })));
};
