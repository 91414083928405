var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { getWarehouseSectionList } from '../../api/rest/requests/warehouse/section';
import AsyncTreeSelect from '../AsyncTreeSelect';
const WarehouseSectionSelect = (props) => {
    const { warehouseId } = props, selectProps = __rest(props, ["warehouseId"]);
    const request = (page, pageSize, parentId, query, setLoadingState) => __awaiter(void 0, void 0, void 0, function* () {
        return (getWarehouseSectionList(warehouseId, query, page.toString(), pageSize.toString(), parentId === null || parentId === void 0 ? void 0 : parentId.toString(), setLoadingState));
    });
    return (React.createElement(AsyncTreeSelect, Object.assign({}, selectProps, { request: request, dataToOption: (section) => {
            var _a;
            return ({
                id: section.id.toString(),
                title: (_a = section.title) !== null && _a !== void 0 ? _a : '',
                pId: section.parentId,
                isLeaf: false,
                value: section.id.toString(),
            });
        } })));
};
export default WarehouseSectionSelect;
