import React from 'react';
import classNames from 'classnames';
import Navigation from '../../../components/Navigation/Navigation';
import navigation from '../../../variables/navigation';
import Footer from '../../../components/Footer';
import HeaderContainer from '../../containers/HeaderContainer';
import PrintDialogContainer from '../../containers/PrintDialogContainer';
import UserPanelContainer from '../../containers/UserPanelContainer';
import { usePageTitle } from '../../../routing/hooks';
import { AppLayoutProvider } from "../../../layouts/AppLayout/store/AppLayoutProvider";
const AppLayout = (props) => {
    const { sidebarOpen, children } = props;
    const classList = classNames({
        app: true,
        toggled: sidebarOpen,
    });
    usePageTitle();
    return (React.createElement(AppLayoutProvider, null,
        React.createElement("div", { className: classList, style: { display: 'flex', flexDirection: 'column' } },
            React.createElement("div", { className: "app-body" },
                React.createElement("div", { className: "app-sidebar" },
                    React.createElement(UserPanelContainer, null),
                    React.createElement(Navigation, { navigationData: navigation }),
                    React.createElement(Footer, null)),
                React.createElement("div", { className: "app-main" },
                    React.createElement(HeaderContainer, null),
                    children))),
        React.createElement(PrintDialogContainer, null)));
};
export default AppLayout;
