var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useWarehouseEditor } from "../hooks/api/useWarehouseEditor";
import { enlargeIfSquare } from "../hooks/helpers";
const WarehouseSizeModal = ({ warehouseId, dispatch }) => {
    const [isVisible, setIsVisible] = useState(true); // Состояние видимости модалки
    const [form] = Form.useForm();
    const { updateWarhouseMap } = useWarehouseEditor();
    const handleOk = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const values = yield form.validateFields();
            yield updateWarhouseMap({ width: values.width, height: values.width }, warehouseId);
            const payload = {
                __typename: "Rect",
                height: values.height,
                width: values.width,
                x: 0,
                y: 0,
                rx: 0,
                ry: 0,
            };
            dispatch({ type: "FETCH_WAREHOUSE_SUCCESS", payload: enlargeIfSquare(payload) });
            setIsVisible(false);
        }
        catch (error) {
            console.error("Ошибка валидации:", error);
        }
    });
    return (React.createElement(Modal, { maskClosable: false, title: "\u0417\u0430\u0434\u0430\u0439\u0442\u0435 \u0440\u0430\u0437\u043C\u0435\u0440 \u0441\u043A\u043B\u0430\u0434\u0430", open: isVisible, onOk: handleOk, closable: false, onCancel: () => setIsVisible(false), footer: [
            React.createElement(Button, { key: "submit", type: "primary", onClick: handleOk }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
        ] },
        React.createElement(Form, { form: form, layout: "vertical", name: "warehouseSize" },
            React.createElement(Form.Item, { label: "\u0428\u0438\u0440\u0438\u043D\u0430", name: "width", rules: [{ required: true, message: "Пожалуйста, введите ширину склада!" }] },
                React.createElement(Input, { type: "number" })),
            React.createElement(Form.Item, { label: "\u0412\u044B\u0441\u043E\u0442\u0430", name: "height", rules: [{ required: true, message: "Пожалуйста, введите высоту склада!" }] },
                React.createElement(Input, { type: "number" })))));
};
export default WarehouseSizeModal;
