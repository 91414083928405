var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../../components/Page";
import { links } from "../../../../constants/links";
import WriteOffTaskForm from "../../../conponents/task/writeOff/WriteOffTaskForm";
import { useTaskWriteOffCRUDActions } from "../../../../hooks/api/taskWriteOffApi/useTaskWriteOffCRUDActions";
const WriteOffAddPage = () => {
    const navigate = useNavigate();
    const { actions } = useTaskWriteOffCRUDActions();
    const { create, isLoading } = actions;
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: null,
        toolbarRight: null,
    };
    const handlerSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        if (values && typeof values === "object" && "warehouseId" in values && typeof values.warehouseId === "number") {
            const response = yield create(values.warehouseId);
            const id = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.taskWriteOffAdd) === null || _b === void 0 ? void 0 : _b.taskWriteOffDto) === null || _c === void 0 ? void 0 : _c.id;
            if (id) {
                message.success(`Задача ${id} успешно сохранена`);
                navigate(links.taskWriteOffLinks.edit.url(id));
            }
        }
    });
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(WriteOffTaskForm, { isLoading: isLoading, onSubmit: handlerSubmit })));
};
export default WriteOffAddPage;
