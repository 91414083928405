import React, { useEffect, useState } from "react";
import { useServiceListLazyQuery } from "../../api/graphql/queries/services/ServiceQuery";
import { Modal, Tabs } from "antd";
import ServiceItem from "./ServiceItem";
let initialItems = [];
const ServiceManager = () => {
    const [getServiceList, { data, loading }] = useServiceListLazyQuery();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [items, setItems] = useState(initialItems);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        if (isModalOpen)
            getServiceList();
    }, [isModalOpen]);
    useEffect(() => {
        if (data != undefined) {
            let newItems = [];
            data.serviceList.forEach((service) => {
                newItems.push({
                    key: service.title,
                    label: service.title,
                    children: React.createElement(ServiceItem, { service: service })
                });
            });
            setItems(newItems);
        }
    }, [data, loading]);
    return (React.createElement("div", { className: "app-header-services" },
        React.createElement("div", { className: "services-btn mdi mdi-apps", onClick: showModal }),
        React.createElement(Modal, { title: "\u0414\u043E\u0441\u0442\u0443\u043F\u043D\u044B\u0435 \u0441\u0435\u0440\u0432\u0438\u0441\u044B", open: isModalOpen, onCancel: handleCancel, footer: null, width: 800 },
            React.createElement(Tabs, { items: items }))));
};
export default ServiceManager;
