var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useMemo } from "react";
import { InventoryByType } from "../../../../../../api/graphql/common";
import { checkIdIsEqual } from "./helper";
import { Table } from "antd";
import { createColumns } from "./Columns/Cell";
import { useParams } from "react-router-dom";
import { columns as SKUColumn } from "./Columns/SKU";
import { useTaskInventoryDetails } from "../../../../../../hooks/api/taskInventoryApi/useTaskInventoryDetails";
import { InventoryContext } from "../../../../../pages/task/inventory/InventoryEditPage";
export const Cell = ({ taskInventoryStorageCells, taskInventorySkus, refetch }) => {
    const params = useParams();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const data = useMemo(() => dtoToEntityAdapt(taskInventoryStorageCells, taskInventorySkus), [JSON.stringify(taskInventoryStorageCells), JSON.stringify(taskInventorySkus)]);
    const { taskDetails } = useTaskInventoryDetails();
    const handlerDeleteDetails = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.delete({ variables: { input: { taskId, request: { ids: [id], inventoryByType: InventoryByType.StorageCell } } } });
        yield refetch();
    });
    const context = useContext(InventoryContext);
    const columns = useMemo(() => createColumns(handlerDeleteDetails, taskDetails.loading, !!(context === null || context === void 0 ? void 0 : context.isLocked)), []);
    return (React.createElement(Table, { expandable: {
            expandedRowRender: (record) => React.createElement(Table, { columns: SKUColumn, dataSource: record.sku, pagination: false }),
            rowExpandable: (record) => { var _a; return !!((_a = record === null || record === void 0 ? void 0 : record.sku) === null || _a === void 0 ? void 0 : _a.length); },
        }, loading: taskDetails === null || taskDetails === void 0 ? void 0 : taskDetails.loading, style: { whiteSpace: "nowrap" }, columns: columns, dataSource: data, bordered: true, pagination: false, size: "small", scroll: { x: true } }));
};
function dtoToEntityAdapt(taskInventoryStorageCells, taskInventorySkus) {
    return [
        ...taskInventoryStorageCells.map((taskInventoryStorageCell) => {
            return {
                key: taskInventoryStorageCell.id,
                number: taskInventoryStorageCell.id,
                title: taskInventoryStorageCell.storageCell.title,
                address: taskInventoryStorageCell.storageCell.address,
                skuCount: taskInventorySkus.filter((taskInventorySku) => checkIdIsEqual(taskInventorySku.taskInventoryStorageCellId, taskInventoryStorageCell.id)).length,
                sku: taskInventorySkus
                    .filter((taskInventorySku) => checkIdIsEqual(taskInventorySku.taskInventoryStorageCellId, taskInventoryStorageCell.id))
                    .map((sku) => {
                    var _a;
                    return {
                        number: sku.skuId,
                        type: sku.type,
                        title: sku.title,
                        quantity: sku.quantity,
                        storageCellExpectedTitle: (_a = sku === null || sku === void 0 ? void 0 : sku.storageCellExpected) === null || _a === void 0 ? void 0 : _a.title,
                    };
                }),
            };
        }),
    ];
}
