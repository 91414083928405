import { matchRoutes, useLocation, useParams, } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import routes from './index';
const getLink = (route, location) => {
    const match = matchRoutes([route], location);
    return match ? match[0].pathname : '';
};
const generateStringFromParams = (str, params) => {
    let result = str !== null && str !== void 0 ? str : '';
    // Замена пользовательского шаблона на значение из params с сохранением решетки
    Object.keys(params).forEach(key => {
        const pattern = new RegExp(`#:${key}`, 'g');
        result = result.replace(pattern, `#${params[key]}`);
    });
    return result;
};
export const useBreadcrumbs = (pageRoutes, renderFunction) => {
    var _a;
    const location = useLocation();
    const params = useParams();
    const toRouteObject = useCallback((pageRoute) => {
        var _a;
        const route = {
            path: pageRoute.path,
            index: pageRoute.index ? undefined : false,
            caseSensitive: pageRoute.caseSensitive,
            children: (_a = pageRoute.children) === null || _a === void 0 ? void 0 : _a.map(toRouteObject),
        };
        const title = generateStringFromParams(pageRoute.title, params);
        let breadcrumb;
        if (typeof pageRoute.breadcrumb === 'string') {
            breadcrumb = generateStringFromParams(pageRoute.breadcrumb, params);
        }
        else {
            breadcrumb = pageRoute.breadcrumb;
        }
        if (renderFunction) {
            if (breadcrumb) {
                route.element = renderFunction(breadcrumb, getLink(route, location), title);
            }
            else {
                route.element = renderFunction(title, getLink(route, location));
            }
        }
        else {
            route.element = breadcrumb;
        }
        return route;
    }, [renderFunction, params, location]);
    return ((_a = matchRoutes(pageRoutes, location)) === null || _a === void 0 ? void 0 : _a.filter(({ route }) => !route.index).map((item) => toRouteObject(item.route)));
};
export const usePageTitle = () => {
    var _a;
    const location = useLocation();
    const params = useParams();
    const pageTitle = (_a = matchRoutes(routes, location)) === null || _a === void 0 ? void 0 : _a.map(({ route }) => route).filter(({ index }) => !index).slice(-1)[0];
    useEffect(() => {
        let title;
        if (pageTitle && pageTitle.title) {
            title = generateStringFromParams(pageTitle.title, params);
        }
        document.title = `${title ? `${title} | ` : ''}Varrant ITM - WMS`;
    }, [location, pageTitle, params]);
};
