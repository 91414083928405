var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Checkbox, Form, Input } from 'antd';
import React from 'react';
import ProductTypeAttrInput from '../../../../../components/ProductTypeAttrInput';
import ProductDataType from '../../../../../constants/enum/ProductDataType';
import StorageCellSelect from '../../../../../components/StorageCellSelect';
const LoadingDetailNomenclatureCell = (props) => {
    const { editing, dataIndex, attribute, value, warehouseId, children, isRequired } = props, restProps = __rest(props, ["editing", "dataIndex", "attribute", "value", "warehouseId", "children", "isRequired"]);
    const renderEditableField = () => {
        var _a, _b, _c, _d, _e;
        if (editing) {
            let inputNode;
            if (attribute) {
                inputNode = React.createElement(ProductTypeAttrInput, { attribute: attribute, value: value, style: { minWidth: '100px' } });
            }
            else if (dataIndex === 'storage') {
                inputNode = React.createElement(StorageCellSelect, { warehouseId: warehouseId, value: value });
            }
            else {
                inputNode = React.createElement(Input, null);
            }
            const rules = [];
            if (isRequired) {
                rules.push({ required: true, message: 'Заполните поле' });
            }
            switch ((_a = attribute === null || attribute === void 0 ? void 0 : attribute.dataType) === null || _a === void 0 ? void 0 : _a.toLowerCase()) {
                case (_b = ProductDataType.HANDBOOK) === null || _b === void 0 ? void 0 : _b.toLowerCase(): {
                    if (isRequired) {
                        rules.push({
                            type: 'object',
                            validator: (_, fieldValue) => __awaiter(void 0, void 0, void 0, function* () { return fieldValue.value ? Promise.resolve() : Promise.reject(); }),
                            message: 'Заполните поле',
                        });
                    }
                    break;
                }
                case (_c = ProductDataType.BOOLEAN) === null || _c === void 0 ? void 0 : _c.toLowerCase(): {
                    if (typeof value === "boolean") {
                        inputNode = React.createElement(Checkbox, { defaultChecked: value });
                    }
                    return (React.createElement(Form.Item, { name: dataIndex, style: { margin: 0 }, rules: rules, valuePropName: "checked" }, inputNode));
                }
                case (_d = ProductDataType.INTEGER) === null || _d === void 0 ? void 0 : _d.toLowerCase():
                case (_e = ProductDataType.FLOAT) === null || _e === void 0 ? void 0 : _e.toLowerCase(): {
                    rules.push({
                        type: 'number',
                        min: 0,
                        message: 'Отрицательное значение',
                    });
                    break;
                }
                default: {
                    break;
                }
            }
            return (React.createElement(Form.Item, { name: dataIndex, style: { margin: 0 }, rules: rules }, inputNode));
        }
        return children;
    };
    return (React.createElement("td", Object.assign({}, restProps),
        React.createElement("span", { style: { whiteSpace: 'nowrap' } }, renderEditableField())));
};
export default LoadingDetailNomenclatureCell;
