var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { User, WebStorageStateStore } from "oidc-client-ts";
export const userManagerSettings = {
    response_type: "code",
    client_id: window.env.ClientId,
    redirect_uri: `${window.env.FrontHost}/signin-oidc`,
    silentRequestTimeoutInSeconds: 600,
    automaticSilentRenew: true,
    post_logout_redirect_uri: window.env.IdentityHost,
    scope: window.env.Scope,
    authority: window.env.IdentityHost,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};
export class AuthService {
    static getParseTokenUser(token) {
        return __awaiter(this, void 0, void 0, function* () {
            if (token) {
                const base64Url = token.split(".")[1];
                const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
                const jsonPayload = decodeURIComponent(window
                    .atob(base64)
                    .split("")
                    .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                    .join(""));
                return JSON.parse(jsonPayload);
            }
        });
    }
    static getUserFromLocalStorage() {
        const oidcStorage = localStorage.getItem(`oidc.user:${window.env.IdentityHost}:${window.env.ClientId}`);
        if (!oidcStorage) {
            return null;
        }
        return User.fromStorageString(oidcStorage);
    }
    static getAccessTokenFromStorage() {
        const user = this.getUserFromLocalStorage();
        const token = user === null || user === void 0 ? void 0 : user.access_token;
        return token;
    }
}
