import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../constants';
export const usePagination = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const pageParam = searchParams.get('page');
    const pageSizeParam = searchParams.get('pageSize');
    const page = pageParam ? +pageParam : 1;
    const pageSize = pageSizeParam ? +pageSizeParam : DEFAULT_PAGE_SIZE;
    const setPagination = (args, oldSearchParams) => new Promise((resolve) => {
        var _a, _b;
        const params = oldSearchParams !== null && oldSearchParams !== void 0 ? oldSearchParams : searchParams;
        params.set('page', ((_a = args.page) !== null && _a !== void 0 ? _a : page).toString());
        params.set('pageSize', ((_b = args.pageSize) !== null && _b !== void 0 ? _b : pageSize).toString());
        setSearchParams(params);
        resolve(params);
    });
    return {
        page,
        pageSize,
        setPagination,
    };
};
