import { Card, Col, Divider, Row, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetHandbookPackingLazyQuery } from "../../../../../api/graphql/queries/handbookPacking/HandbookPackingQuery";
import Page from "../../../../../components/Page";
import PackingAddFormContainer from "../../components/PackingAddPage/PackingAddFormContainer";
import Centered from "../../../../../components/ui/Centered";
import { TITLES, TITLE_PARAM } from "../../constants";
const { Title } = Typography;
export const PackingEditPage = () => {
    const [searchParams] = useSearchParams();
    const titleParam = searchParams.get(TITLE_PARAM);
    const { packingId } = useParams();
    const [getHandbookPacking, { data, loading }] = useGetHandbookPackingLazyQuery();
    useEffect(() => {
        if (packingId) {
            getHandbookPacking({ variables: { handbookPackingId: +packingId }, fetchPolicy: "cache-and-network" });
        }
    }, [packingId]);
    const packing = data === null || data === void 0 ? void 0 : data.handbookPacking;
    return (React.createElement(Page, null,
        React.createElement(Row, null,
            React.createElement(Col, { span: 24 },
                React.createElement(Card, { key: JSON.stringify(`${packing === null || packing === void 0 ? void 0 : packing.id}`) },
                    React.createElement(Title, { level: 3 }, !!titleParam ? TITLES.packing_edit_selected(titleParam) : TITLES.packing_edit),
                    React.createElement(Divider, null),
                    loading ? (React.createElement(Centered, null,
                        React.createElement(Spin, { spinning: true }))) : (React.createElement(PackingAddFormContainer, { packing: packing })))))));
};
