export function convertValueToString(value) {
    if (typeof value === "string") {
        return value;
    }
    else if (Array.isArray(value)) {
        return value.map(convertValueToString).join(",");
    }
    else if (typeof value === "number") {
        return `${value}`;
    }
    else {
        return String(value.value);
    }
}
export function filterProductTypeAttributes(categories) {
    return categories.flatMap((category) => category.productTypeAttributes || []);
}
