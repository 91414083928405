import React from "react";
import PartitionTaskListHeader from "../../../conponents/task/partition/PartitionTaskListHeader";
const PartitionTaskListHeaderContainer = ({ data, isLoading, pagination }) => {
    if (data || isLoading) {
        if (isLoading && !(data === null || data === void 0 ? void 0 : data.length)) {
            return React.createElement(PartitionTaskListHeader, { isEmpty: true, currentCount: 0, totalCount: 0, isLoading: true });
        }
        return React.createElement(PartitionTaskListHeader, { isEmpty: (data === null || data === void 0 ? void 0 : data.length) === 0 && isLoading, currentCount: pagination.itemsCurrentCount, totalCount: pagination.itemsTotalCount, isLoading: isLoading });
    }
    return null;
};
export default PartitionTaskListHeaderContainer;
