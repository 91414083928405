var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useTaskShippingCRUDActions } from "../../../../hooks/api/taskShippingApi/useTaskShippingCRUDActions";
import ShippingTaskForm from "../../../conponents/task/shipping/ShippingTaskForm";
const ShippingTaskEditFormContainer = (props) => {
    const { disableWarehouse, isLoading, task, taskId, refetch, children } = props;
    const { actions } = useTaskShippingCRUDActions();
    const { edit, isLoading: actionLoading } = actions;
    const handlerSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        edit(values);
        yield refetch();
    });
    return (React.createElement(ShippingTaskForm, { task: task, isLoading: isLoading || actionLoading, disableWarehouse: disableWarehouse, isEditing: true, onSubmit: handlerSubmit }, children));
};
export default ShippingTaskEditFormContainer;
