import React from "react";
import { useSearchParams } from "react-router-dom";
import AppFragment from "../../components/Fragment";
import HandbookValueForm from "../../components/HandbookValueForm";
import handbookValueApi from "../../redux/api/handbookApi/handbookValue";
import { HANDBOOK_PARAM } from "../pages/HandbookPage";
import { HandbookSearch } from "../../components/HandbookSearch/HandbookSearch";
const { useAddValueMutation } = handbookValueApi;
const HandbookAddFormContainer = () => {
    const [searchParams] = useSearchParams();
    const handbookParam = searchParams.get(HANDBOOK_PARAM);
    const handbookId = handbookParam ? +handbookParam : null;
    const [addValue, { isLoading }] = useAddValueMutation();
    const handlerAdd = (values) => addValue(Object.assign({ handbookId }, values)).unwrap();
    if (handbookId) {
        return (React.createElement(AppFragment, { title: "\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A" },
            React.createElement(HandbookSearch, null),
            React.createElement(HandbookValueForm, { onSubmit: handlerAdd, isLoading: isLoading })));
    }
    return null;
};
export default HandbookAddFormContainer;
