var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEqual } from 'lodash';
import React from 'react';
import TaskListFilter from '../../../../components/task/TaskListFilter';
import { usePagination } from '../../../../hooks/usePagination';
import { useTaskFilter } from '../../../../hooks/useTaskFilter';
const LoadingTaskListFilterContainer = (props) => {
    const { objectClass, isLoading, fetchList } = props;
    const { setPagination } = usePagination();
    const { filterValues, setFilterValues } = useTaskFilter();
    const handlerSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const params = yield setFilterValues(values);
        if (!isEqual(filterValues, values)) {
            yield fetchList(values);
            setPagination({ page: 1 }, params);
        }
        else {
            yield fetchList(filterValues);
        }
    });
    return (React.createElement(TaskListFilter, { buttonsLoading: isLoading, objectClass: objectClass, onSubmit: handlerSubmit, initialFormValues: filterValues }));
};
export default LoadingTaskListFilterContainer;
