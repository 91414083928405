export const productTypeAdapter = (dto) => {
    const { id, title, isExternal, productCategoryId, productCount, description } = dto;
    return {
        id,
        title,
        productCategoryId,
        isExternal,
        productCount,
        description,
        productTypeCategories: [],
    };
};
export const productTypeDtoListAdapter = (dto) => {
    const { id, title, productCategoryId, productCount, description } = dto;
    return {
        id,
        title,
        productCategoryId,
        productCount,
        description,
        productTypeCategories: [],
    };
};
export const productTypeDtoAdapter = (dto) => {
    var _a;
    const { id, title, productTypeCategories, isExternal, productCategoryId, description, sizeFormula, titleMask } = dto;
    return {
        id: id,
        productCategoryId: productCategoryId,
        titleMask: titleMask !== null && titleMask !== void 0 ? titleMask : undefined,
        title: title !== null && title !== void 0 ? title : undefined,
        isExternal,
        sizeFormula: sizeFormula,
        description: description === null ? undefined : description,
        productTypeCategories: (_a = productTypeCategories === null || productTypeCategories === void 0 ? void 0 : productTypeCategories.map(productTypeCategoryGraphqlAdapter)) !== null && _a !== void 0 ? _a : [],
    };
};
export const productTypeAttributeAdapter = (dto) => {
    const { id, description, dataType, isReadOnly, canBeSummarized, dimension, inPrint, inFilter, isMain, isPersonal, isRequired, sortOrder, title, handbookId, formula } = dto;
    return {
        id: id,
        description: description !== null && description !== void 0 ? description : undefined,
        dataType: dataType ? dataType : undefined,
        canBeSummarized,
        dimension: dimension !== null && dimension !== void 0 ? dimension : undefined,
        inPrint,
        inFilter,
        isReadOnly,
        sortOrder: sortOrder !== null && sortOrder !== void 0 ? sortOrder : null,
        formula,
        isMain,
        isPersonal,
        isRequired,
        title: title !== null && title !== void 0 ? title : undefined,
        handbookId: handbookId !== null && handbookId !== void 0 ? handbookId : undefined,
    };
};
export const productTypeAttributeGraphqlAdapter = (dto) => {
    const { id, description, position, sortOrder, formula, dataType, canBeSummarized, dimension, inPrint, inFilter, isMain, isReadOnly, isPersonal, isRequired, title, handbookId } = dto;
    return {
        id: id,
        description: description !== null && description !== void 0 ? description : undefined,
        dataType: dataType,
        canBeSummarized,
        dimension: dimension !== null && dimension !== void 0 ? dimension : undefined,
        inPrint,
        inFilter,
        sortOrder: sortOrder !== null && sortOrder !== void 0 ? sortOrder : null,
        position,
        formula,
        isMain,
        isPersonal,
        isReadOnly,
        isRequired,
        title: title !== null && title !== void 0 ? title : undefined,
        handbookId: handbookId !== null && handbookId !== void 0 ? handbookId : undefined,
    };
};
export function adaptToProductTypeAttributeGraphqlEntity(mutationResult) {
    const attributeDto = "productTypeAttributeCreate" in mutationResult ? mutationResult === null || mutationResult === void 0 ? void 0 : mutationResult.productTypeAttributeCreate.productTypeAttributeDto : mutationResult === null || mutationResult === void 0 ? void 0 : mutationResult.productTypeAttributeEdit.productTypeAttributeDto;
    if (!attributeDto) {
        return null;
    }
    const entity = {
        id: attributeDto.id,
        isRequired: attributeDto.isRequired,
        isMain: attributeDto.isMain,
        isPersonal: attributeDto.isPersonal,
        isReadOnly: false,
        inFilter: attributeDto.inFilter,
        sortOrder: attributeDto.sortOrder || null,
        inPrint: attributeDto.inPrint,
        canBeSummarized: attributeDto.canBeSummarized,
        dataType: attributeDto.dataType,
        description: attributeDto.description || undefined,
        dimension: attributeDto.dimension || undefined,
        formula: attributeDto.formula,
        position: attributeDto.position,
        productTypeId: undefined,
        productTypeCategoryId: undefined,
        handbookId: attributeDto.handbookId ? attributeDto.handbookId : undefined,
    };
    return entity;
}
export const productTypeAttributeViewAdapter = (dto) => {
    const { id, description, dataType, isReadOnly, canBeSummarized, dimension, inPrint, inFilter, isMain, isPersonal, isRequired, title, handbookId, position, productTypeCategoryId, productTypeId } = dto;
    return {
        id: id,
        description: description !== null && description !== void 0 ? description : undefined,
        dataType: dataType ? dataType : undefined,
        canBeSummarized,
        dimension: dimension !== null && dimension !== void 0 ? dimension : undefined,
        inPrint,
        inFilter,
        isMain,
        sortOrder: 0,
        isPersonal,
        isRequired,
        isReadOnly,
        title: title !== null && title !== void 0 ? title : undefined,
        handbookId: handbookId !== null && handbookId !== void 0 ? handbookId : undefined,
        position,
        productTypeCategoryId,
        productTypeId,
    };
};
export const productTypeCategoryGraphqlAdapter = (dto) => {
    const { productTypeAttributes, title, id, position } = dto;
    return {
        id: id,
        title: title !== null && title !== void 0 ? title : undefined,
        position,
        productTypeAttributes: productTypeAttributes === null || productTypeAttributes === void 0 ? void 0 : productTypeAttributes.map(productTypeAttributeGraphqlAdapter),
    };
};
export const productTypeCategoryGraphqlMutationResultAdapter = (mutationResult) => {
    const dto = "productTypeCategoryCreate" in mutationResult ? mutationResult === null || mutationResult === void 0 ? void 0 : mutationResult.productTypeCategoryCreate.productTypeCategoryDto : mutationResult === null || mutationResult === void 0 ? void 0 : mutationResult.productTypeCategoryEdit.productTypeCategoryDto;
    const { productTypeAttributes, title, id, position } = dto;
    return {
        id: id,
        title: title !== null && title !== void 0 ? title : undefined,
        position,
        productTypeAttributes: productTypeAttributes === null || productTypeAttributes === void 0 ? void 0 : productTypeAttributes.map(productTypeAttributeGraphqlAdapter),
    };
};
export const productTypeCategoryAdapter = (dto) => {
    const { productTypeAttributes, title, id } = dto;
    return {
        id: id,
        title: title !== null && title !== void 0 ? title : undefined,
        productTypeAttributes: productTypeAttributes === null || productTypeAttributes === void 0 ? void 0 : productTypeAttributes.map(productTypeAttributeAdapter),
    };
};
export const productTypeCategoryViewAdapter = (dto) => {
    const { id, title } = dto;
    return {
        id: id,
        title: title !== null && title !== void 0 ? title : undefined,
    };
};
export const productTypeViewGraphqlAdapter = (mutationResult) => {
    var _a, _b;
    const dto = "productTypeEdit" in mutationResult ? mutationResult === null || mutationResult === void 0 ? void 0 : mutationResult.productTypeEdit.productTypeDto : (_a = mutationResult === null || mutationResult === void 0 ? void 0 : mutationResult.productTypeCreate) === null || _a === void 0 ? void 0 : _a.productTypeDto;
    const { productTypeCategories, title, description, id, productCategoryId, titleMask, isExternal, sizeFormula } = dto;
    return {
        id: id,
        productCategoryId: productCategoryId,
        titleMask: titleMask === null ? undefined : titleMask,
        isExternal,
        title: title === null ? undefined : title,
        sizeFormula: sizeFormula,
        description: description === null ? undefined : description,
        productTypeCategories: (_b = productTypeCategories === null || productTypeCategories === void 0 ? void 0 : productTypeCategories.map(productTypeCategoryGraphqlAdapter)) !== null && _b !== void 0 ? _b : [],
    };
};
export const productTypeViewAdapter = (dto) => {
    var _a, _b;
    const { productTypeCategories, title, description, id, productCategoryId, titleMask, isExternal, sizeFormula } = (_a = dto === null || dto === void 0 ? void 0 : dto.productTypeCreate) === null || _a === void 0 ? void 0 : _a.productTypeDto;
    return {
        id: id,
        productCategoryId: productCategoryId,
        titleMask: titleMask === null ? undefined : titleMask,
        isExternal,
        title: title === null ? undefined : title,
        sizeFormula: sizeFormula,
        description: description === null ? undefined : description,
        productTypeCategories: (_b = productTypeCategories === null || productTypeCategories === void 0 ? void 0 : productTypeCategories.map(productTypeCategoryGraphqlAdapter)) !== null && _b !== void 0 ? _b : [],
    };
};
