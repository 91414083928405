import { SCALE } from "./state";
export const isValidId = (input) => {
    const id = input === null || input === void 0 ? void 0 : input.trim();
    return !!id && !isNaN(+id);
};
export const createTopology = (sections, cells) => {
    // Предварительно обрабатываем секции, превращая их в узлы топологии
    const sectionNodes = sections.reduce((acc, section) => {
        acc[section.id] = Object.assign(Object.assign({}, section), { type: "section", children: [] });
        return acc;
    }, {});
    // Добавляем ячейки к их родительским секциям
    cells.forEach((cell) => {
        var _a;
        if (cell.storageSectionId && sectionNodes[cell.storageSectionId]) {
            (_a = sectionNodes[cell.storageSectionId].children) === null || _a === void 0 ? void 0 : _a.push(Object.assign(Object.assign({}, cell), { type: "cell" }));
        }
        // Обработка случая, когда ячейка не привязана к секции, если необходимо
    });
    // Строим дерево, исключая секции, которые сами являются дочерними
    const topologyTree = Object.values(sectionNodes).filter((node) => !node.parentId);
    // Рекурсивно строим дерево вложенных секций
    const buildTree = (node) => {
        if (node.type === "section") {
            sections.forEach((section) => {
                var _a;
                if (section.parentId === node.id) {
                    const childNode = sectionNodes[section.id];
                    (_a = node.children) === null || _a === void 0 ? void 0 : _a.push(childNode);
                    buildTree(childNode);
                }
            });
        }
    };
    // Инициируем построение дерева для каждой корневой секции
    topologyTree.forEach(buildTree);
    return topologyTree;
};
export const convertToAntTreeData = (nodes, activeNodeIds) => {
    const activeNodeSet = new Set(activeNodeIds.map((id) => id.toString()));
    return nodes.map((node) => ({
        id: node === null || node === void 0 ? void 0 : node.id,
        vectorObject: node === null || node === void 0 ? void 0 : node.vectorObject,
        title: node.title,
        className: activeNodeSet.has(node === null || node === void 0 ? void 0 : node.id.toString()) && node.type !== "section" ? "active-node" : undefined,
        isLeaf: node.type === "section" ? false : true,
        storageCellType: node.type === "cell" ? node.storageCellType : true,
        key: `${node.id}${node === null || node === void 0 ? void 0 : node.title}`,
        children: node.children ? convertToAntTreeData(node.children, activeNodeIds) : undefined,
    }));
};
function enlargeRect(rect) {
    return Object.assign(Object.assign({}, rect), { width: rect.width * SCALE, height: rect.height * SCALE });
}
export function enlargeIfSquare(shape) {
    if (shape.__typename === "Rect") {
        return enlargeRect(shape);
    }
    return shape;
}
export function divideAndRound(value, divisor) {
    if (divisor === 0) {
        throw new Error("Делитель не может быть равен 0");
    }
    const result = value / divisor;
    return Math.round(result * 100) / 100;
}
export const adaptStorageCellLoadDtoToCellCollection = (storageCellLoads) => {
    const cellCollection = new Map();
    storageCellLoads.forEach((loadDto) => {
        const cell = {
            id: loadDto.storageCellId,
            loadPercent: loadDto.storageCellLoadPercent,
            cellAddress: loadDto.storageCellAddress,
            cellTitle: loadDto.storageCellTitle,
            cellDescription: "",
            cellDepth: 0,
            cellHeight: 0,
            sectionTitle: loadDto.storageSectionAddress || "",
            sectionAddress: loadDto.storageSectionAddress || "Неизвестная секция",
        };
        cellCollection.set(cell.id, cell);
    });
    return cellCollection;
};
