import { Outlet } from 'react-router-dom';
import React from 'react';
import { links } from '../constants/links';
import CorrectionListPage from '../core/pages/task/correction/CorrectionListPage';
import CorrectionTaskPage from '../core/pages/task/correction/CorrectionTaskPage';
import CorrectionAddPage from '../core/pages/task/correction/CorrectionAddPage';
import CorrectionEditPage from '../core/pages/task/correction/CorrectionEditPage';
const paths = links.taskCorrectionLinks;
const taskCorrectionRoutes = {
    title: paths.list.title,
    breadcrumb: 'Корректировка',
    path: paths.list.path,
    element: React.createElement(Outlet, null),
    children: [
        {
            index: true,
            element: React.createElement(CorrectionListPage, null),
        },
        {
            title: paths.page.title,
            breadcrumb: paths.page.breadcrumb,
            path: paths.page.url(':taskId'),
            element: React.createElement(Outlet, null),
            children: [
                {
                    index: true,
                    element: React.createElement(CorrectionTaskPage, null),
                },
                {
                    title: paths.edit.title,
                    breadcrumb: paths.edit.breadcrumb,
                    path: paths.edit.url(':taskId'),
                    element: React.createElement(CorrectionEditPage, null),
                },
            ],
        },
        {
            title: paths.add.title,
            breadcrumb: paths.add.breadcrumb,
            path: paths.add.url(),
            element: React.createElement(CorrectionAddPage, null),
        },
    ],
};
export default taskCorrectionRoutes;
