import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { links } from "../../../../constants/links";
import CommentsContainer from "../../../containers/CommentsContainer";
import FilesContainer from "../../../containers/FilesContainer";
import ProductInfoDrawerContainer from "../../../containers/ProductInfoDrawerContainer";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import InventoryTaskContainer from "../../../containers/task/inventory/InventoryTaskContainer";
import InventoryStatusHistoryContainer from "../../../containers/task/inventory/InventoryStatusHistoryContainer";
import InventoryTaskNomenclatureContainer from "../../../containers/task/inventory/InventoryTaskNomenclatureContainer";
import InventoryTaskHeaderContainer from "../../../containers/task/inventory/InventoryTaskHeaderContainer";
import { useTaskInventory } from "../../../../hooks/api/taskInventoryApi/useTaskInventory";
const InventoryTaskPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const objectInfo = {
        objectClass: ObjectClass.TaskInventory,
        objectId: taskId,
    };
    const { task } = useTaskInventory();
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const handlerChangeTask = () => navigate(links.taskInventoryLinks.edit.url(taskId));
    const pageProps = {
        toolbarTop: React.createElement(InventoryTaskHeaderContainer, { refetch: task.refetch, id: taskId, task: task === null || task === void 0 ? void 0 : task.data, isLoading: task === null || task === void 0 ? void 0 : task.loading, onChangeTask: handlerChangeTask }),
        toolbarRight: React.createElement(CommentsContainer, Object.assign({}, objectInfo)),
    };
    const containerProps = {
        id: taskId,
        nomenclatureTab: React.createElement(InventoryTaskNomenclatureContainer, { refetch: task === null || task === void 0 ? void 0 : task.refetch, loading: task === null || task === void 0 ? void 0 : task.loading, taskInventoryDto: task === null || task === void 0 ? void 0 : task.data }),
        filesTab: React.createElement(FilesContainer, Object.assign({}, objectInfo)),
        historyTab: React.createElement(InventoryStatusHistoryContainer, { taskId: taskId }),
    };
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(InventoryTaskContainer, Object.assign({ isLoading: task.loading, task: task.data }, containerProps)),
        React.createElement(ProductInfoDrawerContainer, null),
        React.createElement(SkuInfoDrawerContainer, null)));
};
export default InventoryTaskPage;
