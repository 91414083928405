import React from "react";
import PaginationPanel from "../../../../components/PaginationPanel";
import { usePagination } from "../../../../hooks/usePagination";
const ChangeWarehouseTaskPaginationContainer = ({ isLoading, data, pagination }) => {
    const { setPagination } = usePagination();
    const handlerChange = (nextPage, nextPageSize) => {
        setPagination({
            page: nextPage,
            pageSize: nextPageSize,
        });
    };
    if (data) {
        return (React.createElement(PaginationPanel, { pagination: {
                page: pagination.page,
                itemsTotalCount: pagination.itemsTotalCount,
                itemsCurrentCount: pagination.itemsCurrentCount,
                itemsOnPage: pagination.itemsOnPage,
            }, disabled: isLoading, onChange: handlerChange }));
    }
    return null;
};
export default ChangeWarehouseTaskPaginationContainer;
