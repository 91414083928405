var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Switch } from "antd";
import { debounce } from "lodash";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { arrayMove } from "react-sortable-hoc";
import Page from "../../../../components/Page";
import { ProductTypeAttributeSelect } from "./components/ProductTypeAttributeSelect";
import { DraggableContainer } from "./components/expression/dnd/DraggableContainer";
import { extractConditions, processChangedValues } from "./helpers/fromHelper";
import { useProductAutomatizationExpressionActions } from "./hooks/api/useProductAutomatizationExpressionPage";
import { useProductAutomatizationActions } from "./hooks/api/useProductAutomatizationPage";
import { useProductExpirationDate } from "./hooks/api/useProductExpirationDate";
import { handleCommonError, handleSuccess } from "../../../../helpers/function";
export const ProductAutomatizationContext = createContext(undefined);
export const ProductAutomatizationPage = ({}) => {
    var _a, _b, _c;
    const { data, isLoading, refetch } = useProductExpirationDate();
    const { editProductAutomatization } = useProductAutomatizationActions();
    const { sortProductAutomatizationExpression, createProductAutomatizationExpression, deleteProductAutomatizationExpression, editProductAutomatizationExpression } = useProductAutomatizationExpressionActions();
    const { typeId, autoId } = useParams();
    const [loading, setLoading] = useState(false);
    const [changeResultLoading, setChangeResultLoading] = useState(false);
    const [form] = Form.useForm();
    const [rows, setRows] = useState((_a = data === null || data === void 0 ? void 0 : data.productTypeAutomationExpressions) !== null && _a !== void 0 ? _a : []);
    const [conditions, setConditions] = useState([]);
    const handleDataFetch = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const values = form.getFieldsValue();
        try {
            yield editProductAutomatization.handleAction({
                enable: !!(values === null || values === void 0 ? void 0 : values.enable),
                id: data === null || data === void 0 ? void 0 : data.id,
                schedule: values.schedule,
                title: data === null || data === void 0 ? void 0 : data.title,
            });
            handleSuccess("Автоматизация успешно обновлена");
        }
        catch (error) {
            handleCommonError("Ошибка обновления автоматизации");
        }
        finally {
            setLoading(false);
        }
    }), [form, editProductAutomatization, data === null || data === void 0 ? void 0 : data.id, data === null || data === void 0 ? void 0 : data.title]);
    const handleDataFetchDebounced = useCallback(debounce(handleDataFetch, 600), [data === null || data === void 0 ? void 0 : data.id]);
    const debouncedUpdateExpressionResult = useCallback(debounce((expression, resultKey, changedValues) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setChangeResultLoading(true);
            const result = typeof changedValues[resultKey] === "object" && "label" in changedValues[resultKey] && "value" in changedValues[resultKey] ? `${changedValues[resultKey].value}` : `${changedValues[resultKey]}`;
            yield editProductAutomatizationExpression.handleAction({
                enable: expression.enable,
                id: expression.id,
                result: result,
            });
            handleSuccess("Данные автоматизации обновлены");
            setChangeResultLoading(false);
        }
        catch (error) {
            handleCommonError("Ошибка при задании результата");
        }
    }), 600), []);
    const handleFormChange = (changedValues, values) => __awaiter(void 0, void 0, void 0, function* () {
        const newConditions = extractConditions(values);
        setConditions(newConditions);
        if (data.productTypeAutomationExpressions) {
            yield processChangedValues(changedValues, data.productTypeAutomationExpressions, debouncedUpdateExpressionResult);
        }
        if (changedValues.productTypeAttribute || changedValues.schedule) {
            handleDataFetchDebounced();
        }
    });
    const handleSwithChange = () => {
        handleDataFetch();
    };
    const handleAddExpression = () => __awaiter(void 0, void 0, void 0, function* () {
        if (typeId) {
            try {
                yield createProductAutomatizationExpression.handleAction({
                    enable: false,
                    productTypeAutomationId: data === null || data === void 0 ? void 0 : data.id,
                });
                try {
                    yield refetch();
                }
                catch (error) {
                    handleSuccess("Ошибка обновления данных по автоматизации");
                }
            }
            catch (error) {
                handleCommonError("Ошибка создания условия");
            }
        }
    });
    const handleDeleteExpression = (id, changeLoadingState) => {
        changeLoadingState(true);
        deleteProductAutomatizationExpression
            .handleAction({
            productTypeAutomationId: id,
        })
            .then(() => {
            refetch().catch(() => {
                handleCommonError("Ошибка обновления данных по автоматизации");
            });
        })
            .catch(() => {
            handleSuccess("Ошибка удаления условия");
        })
            .finally(() => {
            changeLoadingState(false);
        });
    };
    const handleChangeExpressionActivity = (id, isActive, changeLoadingState) => __awaiter(void 0, void 0, void 0, function* () {
        var _d;
        changeLoadingState(true);
        try {
            const expression = (_d = data === null || data === void 0 ? void 0 : data.productTypeAutomationExpressions) === null || _d === void 0 ? void 0 : _d.find((expression) => (expression === null || expression === void 0 ? void 0 : expression.id) === id);
            yield editProductAutomatizationExpression.handleAction({
                enable: isActive,
                id,
                result: expression === null || expression === void 0 ? void 0 : expression.result,
            });
            handleSuccess("Статус условия обновлен");
            changeLoadingState(false);
        }
        catch (error) {
            handleCommonError("Ошибка при смене сатуса условия");
        }
    });
    const handlerSortEnd = (sort) => __awaiter(void 0, void 0, void 0, function* () {
        const { oldIndex, newIndex } = sort;
        let newRows = null;
        setRows((currentRows) => {
            newRows = arrayMove(currentRows, oldIndex, newIndex);
            return newRows;
        });
        if (Array.isArray(newRows)) {
            try {
                const sortedIds = newRows.map((row) => row.id);
                yield sortProductAutomatizationExpression.handleAction({
                    automationId: Number(autoId),
                    items: sortedIds,
                });
            }
            catch (error) {
                handleCommonError("Ошибка при сортировке условий");
            }
        }
    });
    useEffect(() => {
        return () => {
            handleDataFetchDebounced.cancel();
        };
    }, [handleDataFetchDebounced]);
    useEffect(() => {
        setRows(data === null || data === void 0 ? void 0 : data.productTypeAutomationExpressions);
    }, [data === null || data === void 0 ? void 0 : data.productTypeAutomationExpressions]);
    useEffect(() => {
        var _a, _b;
        if ((_a = data === null || data === void 0 ? void 0 : data.productTypeAutomationExpressions) === null || _a === void 0 ? void 0 : _a.length) {
            setConditions((_b = data === null || data === void 0 ? void 0 : data.productTypeAutomationExpressions) === null || _b === void 0 ? void 0 : _b.flatMap((expression) => (expression === null || expression === void 0 ? void 0 : expression.productTypeAutomationConditions.map((condition, index) => { var _a; return (Object.assign(Object.assign({}, condition), { id: condition === null || condition === void 0 ? void 0 : condition.id, index, expressionId: expression === null || expression === void 0 ? void 0 : expression.id, productTypeAttributeId: (_a = condition === null || condition === void 0 ? void 0 : condition.productTypeAttribute) === null || _a === void 0 ? void 0 : _a.id })); })) || []));
        }
    }, [JSON.stringify(data === null || data === void 0 ? void 0 : data.productTypeAutomationExpressions)]);
    return (React.createElement(Page, null, isLoading ? (React.createElement(Card, { title: " ", loading: true, type: "inner" })) : (React.createElement(Form, { form: form, onValuesChange: handleFormChange, layout: "vertical", initialValues: Object.assign({ enable: data === null || data === void 0 ? void 0 : data.enable, schedule: data === null || data === void 0 ? void 0 : data.schedule, productTypeAttribute: (_b = data === null || data === void 0 ? void 0 : data.productTypeAttribute) === null || _b === void 0 ? void 0 : _b.id }, (_c = data === null || data === void 0 ? void 0 : data.productTypeAutomationExpressions) === null || _c === void 0 ? void 0 : _c.reduce((acc, expression) => {
            acc[`result-${expression.id}`] = expression.result === null ? undefined : expression.result;
            return acc;
        }, {})) },
        React.createElement(Card, { loading: isLoading, type: "inner", title: React.createElement(Row, null,
                React.createElement(Col, { span: 20 }, data === null || data === void 0 ? void 0 : data.title),
                React.createElement(Col, { span: 4 },
                    React.createElement("div", { style: { display: "flex", gap: "12px", alignItems: "center", justifyContent: "end" } },
                        React.createElement(Button, { loading: createProductAutomatizationExpression.isLoading, onClick: handleAddExpression, type: "primary", size: "small", icon: React.createElement(PlusOutlined, null) }),
                        React.createElement(Form.Item, { name: "enable", noStyle: true },
                            React.createElement(Switch, { loading: loading, onChange: handleSwithChange }))))) },
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { span: 12 },
                    React.createElement(Form.Item, { label: "\u0420\u0430\u0441\u0447\u0435\u0442\u043D\u044B\u0439 \u0430\u0442\u0440\u0438\u0431\u0443\u0442", name: "productTypeAttribute", wrapperCol: { span: 24 } },
                        React.createElement(ProductTypeAttributeSelect, { disabled: true, typeId: Number(typeId) }))),
                React.createElement(Col, { span: 12 },
                    React.createElement(Row, null,
                        React.createElement(Col, { span: 24 },
                            React.createElement(Form.Item, { name: "schedule", label: "\u0420\u0430\u0441\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F" },
                                React.createElement(Input, { disabled: loading })))))),
            React.createElement(ProductAutomatizationContext.Provider, { value: { refetchLoading: isLoading, refetch, form, conditions, changeResultLoading, productTypeAttribute: data.productTypeAttribute, sortLoading: sortProductAutomatizationExpression === null || sortProductAutomatizationExpression === void 0 ? void 0 : sortProductAutomatizationExpression.isLoading, handleDeleteExpression, handleChangeExpressionActivity } },
                React.createElement(DraggableContainer, { handlerSortEnd: handlerSortEnd, items: rows })))))));
};
