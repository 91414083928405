var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useTaskPartitionCRUDActions } from "../../../../hooks/api/taskPartitionApi/useTaskPartitionCRUDActions";
import { useTaskPartitionOriginal } from "../../../../hooks/api/taskPartitionApi/useTaskPartitionOriginal";
import PartitionDetailNomenclatureFormItem from "../../../conponents/task/partition/PartitionDetailNomenclatureFormItem";
const PartitionDetailNomenclatureFormItemContainer = (props) => {
    const { nomenclature, warehouseId, taskId, refetch } = props;
    const { actions } = useTaskPartitionCRUDActions();
    const { deleteAction, create, edit, isLoading } = actions;
    const { taskDetails } = useTaskPartitionOriginal();
    const handlerDeleteSku = (detailId) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.delete({ variables: { input: { taskId, input: [detailId] } } });
        yield refetch();
    });
    const handlerEditCreated = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield edit(taskId, [value]);
        yield refetch();
    });
    const handlerAddCreated = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield create(taskId, [value]);
        yield refetch();
    });
    const handlerDeleteCreated = (createdId) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteAction(taskId, [createdId]);
        yield refetch();
    });
    return React.createElement(PartitionDetailNomenclatureFormItem, { isLoading: taskDetails.loading || isLoading, nomenclature: nomenclature, onAddCreated: handlerAddCreated, onDeleteCreated: handlerDeleteCreated, onDeleteItem: handlerDeleteSku, onEditCreated: handlerEditCreated, warehouseId: warehouseId });
};
export default PartitionDetailNomenclatureFormItemContainer;
