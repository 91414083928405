import React from "react";
import { uniqueId } from "lodash";
import ReportTable from "../../../components/ReportTable";
import { useAvailabilityOnDate } from "./store/AvailabilityOnDateProvider";
import { getColumnType } from "../../../helpers/function";
import { dateRender, quantityRender } from "../../../components/ReportTable/helpers";
import { links } from "../../../constants/links";
import { Tooltip, Typography } from "antd";
const { Link } = Typography;
const linkRender = (type, id) => {
    const tooltip = {
        mouseEnterDelay: 0.3
    };
    switch (type) {
        case "TaskLoading":
            return React.createElement(Tooltip, Object.assign({}, tooltip, { title: "Задача на загрузку" }),
                React.createElement(Link, { href: links.taskLoadingLinks.page.url(id), target: "_blank" }, `#${id}`));
        case "TaskShipping":
            return React.createElement(Tooltip, Object.assign({}, tooltip, { title: "Задача на отгрузку" }),
                React.createElement(Link, { href: links.taskShippingLinks.page.url(id), target: "_blank" }, `#${id}`));
        case "TaskChangeWarehouse":
            return React.createElement(Tooltip, Object.assign({}, tooltip, { title: "Задача на перемещение между складами" }),
                React.createElement(Link, { href: links.taskChangeWarehouseLinks.page.url(id), target: "_blank" }, `#${id}`));
        default:
            return React.createElement(Tooltip, Object.assign({}, tooltip, { title: "Тип задачи не определён" }), `#${id}`);
    }
};
const taskRender = (task) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: (task === null || task === void 0 ? void 0 : task.inRange) ? "text-green" : "text-grey" }, dateRender(task === null || task === void 0 ? void 0 : task.executedAt)),
        !(task === null || task === void 0 ? void 0 : task.discriminator) || !(task === null || task === void 0 ? void 0 : task.id) ? React.createElement(React.Fragment, null) : linkRender(task.discriminator, task.id)));
};
const columns = [
    getColumnType('id', '#', true, {
        align: 'right',
        fixed: 'left',
        width: 30,
    }),
    getColumnType('productTitle', 'Номенклатура', true, {
        align: 'left',
        render: (_, record) => {
            return (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "d-block" }, record.productTitle),
                React.createElement("small", { className: "text-muted" }, record.productTypeTitle)));
        }
    }),
    getColumnType('companyName', 'Контрагент', true, {
        align: 'left',
        render: (_, record) => {
            return (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "d-block" }, record.companyName),
                React.createElement("small", { className: "text-muted" }, `ИНН: ${record.companyInn}`)));
        }
    }),
    getColumnType('quantity', 'Кол-во по учету', false, {
        align: 'right',
        width: 80,
        render: (value) => quantityRender(value, true)
    }),
    {
        title: 'Приход',
        align: 'center',
        ellipsis: true,
        fixed: 'right',
        children: [
            getColumnType('taskArrival.Date', 'Дата', true, {
                align: 'right',
                fixed: 'right',
                width: 70,
                render: (_, record) => taskRender(record.taskArrival),
            }),
            getColumnType('quantity', 'Кол-во', true, {
                align: 'right',
                fixed: 'right',
                width: 70,
                render: (_, record) => { var _a, _b; return quantityRender(record.quantity, (_b = (_a = record.taskArrival) === null || _a === void 0 ? void 0 : _a.inRange) !== null && _b !== void 0 ? _b : false); },
            }),
        ],
    },
    {
        title: 'Расход',
        align: 'center',
        ellipsis: true,
        fixed: 'right',
        children: [
            getColumnType('taskShippingDate', 'Дата', true, {
                align: 'right',
                fixed: 'right',
                width: 70,
                render: (_, record) => taskRender(record.taskDeparture),
            }),
            getColumnType('quantity', 'Кол-во', true, {
                align: 'right',
                fixed: 'right',
                width: 80,
                render: (_, record) => { var _a, _b; return quantityRender(record.quantity, (_b = (_a = record.taskDeparture) === null || _a === void 0 ? void 0 : _a.inRange) !== null && _b !== void 0 ? _b : false); },
            }),
        ],
    },
    // getColumnType('actions', '', false, {
    //   align: 'right',
    //   width: 30,
    //   render: (_, record) => {
    //     return <Button>*</Button>
    //   }
    // }),
];
export const AvailabilityOnDate = () => {
    const state = useAvailabilityOnDate();
    return (React.createElement(ReportTable, { isLoading: state.loading, data: state.items, rowKeyGenerator: () => Number(uniqueId()), columns: columns }));
};
