import React from "react";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { useTaskWriteOff } from "../../../../hooks/api/taskWriteOffApi/useTaskWriteOff";
import WriteOffTaskListHeaderContainer from "../../../containers/task/writeoff/WriteOffHeaderContainer";
import WriteOffTaskListContainer from "../../../containers/task/writeoff/WriteOffTaskListContainer";
import WriteOffTaskListFilterContainer from "../../../containers/task/writeoff/WriteOffTaskListFilterContainer";
import WriteOffTaskPaginationContainer from "../../../containers/task/writeoff/WriteOffTaskPaginationContainer";
const WriteOffListPage = () => {
    const { writeOffList } = useTaskWriteOff();
    const props = {
        toolbarBottom: React.createElement(WriteOffTaskPaginationContainer, { pagination: writeOffList.pagination, isLoading: writeOffList.loading, data: writeOffList.data }),
        toolbarTop: React.createElement(WriteOffTaskListHeaderContainer, { data: writeOffList === null || writeOffList === void 0 ? void 0 : writeOffList.data, isLoading: writeOffList.loading, pagination: writeOffList === null || writeOffList === void 0 ? void 0 : writeOffList.pagination }),
        toolbarRight: React.createElement(WriteOffTaskListFilterContainer, { isLoading: writeOffList.loading, fetchList: writeOffList.paginationFilteredGetTaskList, objectClass: ObjectClass.TaskWriteOff }),
    };
    return (React.createElement(Page, Object.assign({}, props),
        React.createElement(WriteOffTaskListContainer, { taskList: writeOffList === null || writeOffList === void 0 ? void 0 : writeOffList.data, pagination: writeOffList === null || writeOffList === void 0 ? void 0 : writeOffList.pagination, isLoading: writeOffList === null || writeOffList === void 0 ? void 0 : writeOffList.loading })));
};
export default WriteOffListPage;
