import React from 'react';
import { Outlet } from 'react-router-dom';
import { links } from '../constants/links';
import ChangeWarehouseListPage from '../core/pages/task/changeWarehouse/ChangeWarehouseListPage';
import ChangeWarehouseAddPage from '../core/pages/task/changeWarehouse/ChangeWarehouseAddPage';
import ChangeWarehouseTaskPage from '../core/pages/task/changeWarehouse/ChangeWarehouseTaskPage';
import ChangeWarehouseEditPage from '../core/pages/task/changeWarehouse/ChangeWarehouseEditPage';
const paths = links.taskChangeWarehouseLinks;
const taskChangeWarehouseRoutes = {
    title: paths.list.title,
    breadcrumb: 'Перемещение между складами',
    path: paths.list.path,
    element: React.createElement(Outlet, null),
    children: [
        {
            index: true,
            element: React.createElement(ChangeWarehouseListPage, null),
        },
        {
            title: paths.page.title,
            breadcrumb: paths.page.breadcrumb,
            path: paths.page.url(':taskId'),
            element: React.createElement(Outlet, null),
            children: [
                {
                    index: true,
                    element: React.createElement(ChangeWarehouseTaskPage, null),
                },
                {
                    title: paths.edit.title,
                    breadcrumb: paths.edit.breadcrumb,
                    path: paths.edit.url(':taskId'),
                    element: React.createElement(ChangeWarehouseEditPage, null),
                },
            ],
        },
        {
            title: paths.add.title,
            breadcrumb: paths.add.breadcrumb,
            path: paths.add.url(),
            element: React.createElement(ChangeWarehouseAddPage, null),
        },
    ],
};
export default taskChangeWarehouseRoutes;
