import React, { useState } from "react";
import { Modal, Button, Tabs, Table } from "antd";
import MDIcon from "../ui/MDIcon";
const operatorsColumns = [
    {
        title: "Тип оператора",
        dataIndex: "type",
        key: "type",
    },
    {
        title: "Оператор",
        dataIndex: "operator",
        key: "operator",
    },
    {
        title: "Описание",
        dataIndex: "description",
        key: "description",
    },
    {
        title: "Пример",
        dataIndex: "example",
        key: "example",
    },
];
const functionalColumns = [
    {
        title: "Название функции",
        dataIndex: "functionName",
        key: "functionName",
    },
    {
        title: "Описание",
        dataIndex: "description",
        key: "description",
    },
    {
        title: "Пример",
        dataIndex: "example",
        key: "example",
    },
    {
        title: "Результат",
        dataIndex: "result",
        key: "result",
    },
];
const functionalData = [
    {
        key: "1",
        functionName: "Abs",
        description: "Возвращает абсолютное значение указанного числа",
        example: "Abs(-1)",
        result: "1M",
    },
    {
        key: "2",
        functionName: "Ceiling",
        description: "Возвращает наименьшее целое число, которое больше или равно указанному числу",
        example: "Ceiling(1.5)",
        result: "2d",
    },
    {
        key: "3",
        functionName: "Ceiling",
        description: "Возвращает наименьшее целое число, которое больше или равно указанному числу",
        example: "Ceiling(1.5)",
        result: "2d",
    },
    {
        key: "4",
        functionName: "Floor",
        description: "Возвращает наибольшее целое число, которое меньше или равно указанному числу",
        example: "Floor(1.5)",
        result: "1d",
    },
    {
        key: "5",
        functionName: "Max",
        description: "Возвращает большее из двух указанных чисел",
        example: "Max(1, 2)",
        result: "2",
    },
    {
        key: "6",
        functionName: "Min",
        description: "Возвращает меньшее из двух указанных чисел",
        example: "Min(1, 2)",
        result: "1",
    },
    {
        key: "7",
        functionName: "Pow",
        description: "Возвращает указанное число, возведенное в указанную степень",
        example: "Pow(3, 2)",
        result: "9d",
    },
    {
        key: "8",
        functionName: "Round",
        description: "Округляет значение до ближайшего целого числа или до указанного количества десятичных знаков",
        example: "Round(3.222, 2)",
        result: "3.22d",
    },
    {
        key: "9",
        functionName: "Truncate",
        description: "Вычисляет целую часть числа, отбрасывая его дробную часть",
        example: "Truncate(1.7)",
        result: "1",
    },
];
const additionalData = [
    {
        key: "1",
        functionName: "in",
        description: "Проверяет, находится ли элемент в наборе значений",
        example: "in(1 + 1, 1, 2, 3)",
        result: "true",
    },
    {
        key: "2",
        functionName: "if",
        description: "Возвращает значение на основе условия",
        example: "if(3 % 2 = 1, 'value is true', 'value is false')",
        result: "value is true",
    },
];
const operatorsData = [
    {
        key: "1",
        type: "Логический",
        operator: "or, ||",
        description: "Логическое ИЛИ между выражениями",
        example: "true or false",
    },
    {
        key: "2",
        type: "Логический",
        operator: "and, &&",
        description: "Логическое И между выражениями, имеет больший приоритет, чем or",
        example: "false and true",
    },
    {
        key: "3",
        type: "Относительный",
        operator: "=, ==, !=, <>",
        description: "Сравнение равенства и неравенства",
        example: "1 == 2",
    },
    {
        key: "4",
        type: "Относительный",
        operator: "<, <=, >, >=",
        description: "Сравнение величин",
        example: "1 < 2",
    },
    {
        key: "5",
        type: "Аддитивный",
        operator: "+, -",
        description: "Сложение и вычитание",
        example: "1 + 2 - 3",
    },
    {
        key: "6",
        type: "Мультипликативный",
        operator: "*, /, %",
        description: "Умножение, деление, остаток от деления",
        example: "1 * 2 % 3",
    },
    {
        key: "7",
        type: "Побитовый",
        operator: "&, |, ^, <<, >>",
        description: "Побитовые операции И, ИЛИ, XOR, сдвиг влево и вправо",
        example: "2 >> 3",
    },
    {
        key: "8",
        type: "Унарный",
        operator: "!, not, -, ~",
        description: "Логическое НЕ, арифметическое отрицание, побитовое НЕ",
        example: "not true",
    },
    {
        key: "9",
        type: "Первичный",
        operator: "(, )",
        description: "Группировка выражений и изменение порядка операций",
        example: "2 * (3 + 2)",
    },
];
const items = [
    {
        key: "1",
        label: "Операторы",
        children: React.createElement(Table, { size: "small", columns: operatorsColumns, pagination: false, dataSource: operatorsData }),
    },
    {
        key: "2",
        label: "Функции",
        children: React.createElement(Table, { columns: functionalColumns, dataSource: functionalData, pagination: false, size: "small" }),
    },
    {
        key: "3",
        label: "Условия и циклы",
        children: React.createElement(Table, { columns: functionalColumns, dataSource: additionalData, pagination: false, size: "small" }),
    },
];
const ModalComponent = () => {
    const [visible, setVisible] = useState(false);
    const showModal = () => setVisible(true);
    const closeModal = () => setVisible(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { type: "primary", onClick: showModal, icon: React.createElement(MDIcon, { icon: "help-circle-outline" }), style: { minWidth: "24px" } }),
        React.createElement(Modal, { title: "\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A", visible: visible, onCancel: closeModal, footer: null, width: 800 },
            React.createElement(Tabs, { defaultActiveKey: "1", items: items }))));
};
export default ModalComponent;
