import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskLoadingAddDetailsDocument = gql `
    mutation TaskLoadingAddDetails($input: TaskLoadingAddDetailsInput!) {
  taskLoadingAddDetails(input: $input) {
    taskLoadingViewDetailsDto {
      productId
    }
  }
}
    `;
/**
 * __useTaskLoadingAddDetailsMutation__
 *
 * To run a mutation, you first call `useTaskLoadingAddDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskLoadingAddDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskLoadingAddDetailsMutation, { data, loading, error }] = useTaskLoadingAddDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskLoadingAddDetailsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskLoadingAddDetailsDocument, options);
}
export const TaskLoadingEditDetailsDocument = gql `
    mutation TaskLoadingEditDetails($input: TaskLoadingEditDetailsInput!) {
  taskLoadingEditDetails(input: $input) {
    taskLoadingViewDetailsDto {
      productId
    }
  }
}
    `;
/**
 * __useTaskLoadingEditDetailsMutation__
 *
 * To run a mutation, you first call `useTaskLoadingEditDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskLoadingEditDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskLoadingEditDetailsMutation, { data, loading, error }] = useTaskLoadingEditDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskLoadingEditDetailsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskLoadingEditDetailsDocument, options);
}
export const TaskLoadingDeleteDetailsDocument = gql `
    mutation TaskLoadingDeleteDetails($input: TaskLoadingDeleteDetailsInput!) {
  taskLoadingDeleteDetails(input: $input) {
    taskLoadingViewDetailsDto {
      productId
    }
  }
}
    `;
/**
 * __useTaskLoadingDeleteDetailsMutation__
 *
 * To run a mutation, you first call `useTaskLoadingDeleteDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskLoadingDeleteDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskLoadingDeleteDetailsMutation, { data, loading, error }] = useTaskLoadingDeleteDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskLoadingDeleteDetailsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskLoadingDeleteDetailsDocument, options);
}
export const TaskLoadingChangeStatusDocument = gql `
    mutation TaskLoadingChangeStatus($input: TaskLoadingChangeStatusInput!) {
  taskLoadingChangeStatus(input: $input) {
    taskStatusDto {
      isFinal
    }
  }
}
    `;
/**
 * __useTaskLoadingChangeStatusMutation__
 *
 * To run a mutation, you first call `useTaskLoadingChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskLoadingChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskLoadingChangeStatusMutation, { data, loading, error }] = useTaskLoadingChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskLoadingChangeStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskLoadingChangeStatusDocument, options);
}
export const TaskLoadingStorageCellDocument = gql `
    mutation TaskLoadingStorageCell($input: TaskLoadingStorageCellInput!) {
  taskLoadingStorageCell(input: $input) {
    taskLoadingViewDetailsDto {
      productId
    }
  }
}
    `;
/**
 * __useTaskLoadingStorageCellMutation__
 *
 * To run a mutation, you first call `useTaskLoadingStorageCellMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskLoadingStorageCellMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskLoadingStorageCellMutation, { data, loading, error }] = useTaskLoadingStorageCellMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskLoadingStorageCellMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskLoadingStorageCellDocument, options);
}
export const TaskLoadingAddDocument = gql `
    mutation TaskLoadingAdd($input: TaskLoadingAddInput!) {
  taskLoadingAdd(input: $input) {
    taskLoadingViewDto {
      id
    }
  }
}
    `;
/**
 * __useTaskLoadingAddMutation__
 *
 * To run a mutation, you first call `useTaskLoadingAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskLoadingAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskLoadingAddMutation, { data, loading, error }] = useTaskLoadingAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskLoadingAddMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskLoadingAddDocument, options);
}
export const TaskLoadingEditDocument = gql `
    mutation TaskLoadingEdit($input: TaskLoadingEditInput!, $taskId: Long!) {
  taskLoadingEdit(input: $input, taskId: $taskId) {
    taskLoadingViewDto {
      id
    }
  }
}
    `;
/**
 * __useTaskLoadingEditMutation__
 *
 * To run a mutation, you first call `useTaskLoadingEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskLoadingEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskLoadingEditMutation, { data, loading, error }] = useTaskLoadingEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskLoadingEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskLoadingEditDocument, options);
}
