var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTaskPartitionAddCreatedMutation, useTaskPartitionDeleteCreatedMutation, useTaskPartitionEditCreatedMutation } from "../../../api/graphql/queries/task/taskPartition/TaskPartitionMutation";
export const useTaskPartitionCRUDActions = () => {
    const [createTask, { loading: createLoading }] = useTaskPartitionAddCreatedMutation();
    const [editTask, { loading: editLoading }] = useTaskPartitionEditCreatedMutation();
    const [deleteTask, { loading: deleteLoading }] = useTaskPartitionDeleteCreatedMutation();
    const create = (taskId, request) => __awaiter(void 0, void 0, void 0, function* () {
        return yield createTask({ variables: { input: request, taskId } });
    });
    const edit = (taskId, request) => __awaiter(void 0, void 0, void 0, function* () {
        return yield editTask({ variables: { input: request, taskId } });
    });
    const deleteAction = (taskId, request) => __awaiter(void 0, void 0, void 0, function* () {
        return yield deleteTask({ variables: { input: { taskId, input: request } } });
    });
    return { actions: { create, edit, deleteAction, isLoading: createLoading || editLoading || deleteLoading } };
};
