import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Page from '../../../components/Page';
import ClientListContainer from '../../containers/ClientListContainer';
import ClientsPaginationContainer from '../../containers/ClientsPaginationContainer';
const ClientListPage = () => {
    const [searchParams] = useSearchParams();
    const pageParam = searchParams.get('page');
    const pageSizeParam = searchParams.get('pageSize');
    const page = pageParam ? +pageParam : 1;
    const pageSize = pageSizeParam ? +pageSizeParam : 20;
    return (React.createElement(Page, { toolbarBottom: React.createElement(ClientsPaginationContainer, { page: +page, pageSize: +pageSize }) },
        React.createElement(ClientListContainer, { page: +page, pageSize: +pageSize })));
};
export default ClientListPage;
