import React from "react";
import ShippingTaskListHeader from "../../../conponents/task/shipping/ShippingTaskListHeader";
const ShippingTaskListHeaderContainer = ({ data, isLoading, pagination }) => {
    if (data || isLoading) {
        if (isLoading && !(data === null || data === void 0 ? void 0 : data.length)) {
            return React.createElement(ShippingTaskListHeader, { isEmpty: true, currentCount: 0, totalCount: 0, isLoading: true });
        }
        return React.createElement(ShippingTaskListHeader, { isEmpty: data.length === 0 && isLoading, currentCount: pagination.itemsCurrentCount, totalCount: pagination.itemsTotalCount, isLoading: isLoading });
    }
    return null;
};
export default ShippingTaskListHeaderContainer;
