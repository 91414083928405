import statusConfiguratorStatusApi, { STATUS_CONFIGURATOR_RELATION_TAG, STATUS_CONFIGURATOR_STATUS_TAG } from '..';
import dtoToEntity from '../../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../../constants/enum/Method';
const getUri = (objectClass) => `status/${objectClass}/relation`;
const statusConfiguratorRelationApi = statusConfiguratorStatusApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchRelationList: builder.query({
            query: (objectClass) => ({
                method: Method.GET,
                url: `${getUri(objectClass)}/list`,
            }),
            transformResponse: (response) => (response.map(dtoToEntity.statusRelationAdapter)),
            providesTags: () => [STATUS_CONFIGURATOR_RELATION_TAG],
        }),
        fetchRelation: builder.query({
            query: (args) => {
                const { objectClass, statusRelationId } = args;
                return {
                    method: Method.GET,
                    url: `${getUri(objectClass)}/${statusRelationId}`,
                };
            },
            transformResponse: (response) => (dtoToEntity.statusRelationAdapter(response)),
        }),
        addRelation: builder.mutation({
            query: (args) => {
                const { objectClass, values } = args;
                return {
                    method: Method.POST,
                    url: `${getUri(objectClass)}/add`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.statusRelationAdapter(response)),
            invalidatesTags: [STATUS_CONFIGURATOR_RELATION_TAG, STATUS_CONFIGURATOR_STATUS_TAG],
        }),
        editRelation: builder.mutation({
            query: (args) => {
                const { objectClass, statusRelationId, values } = args;
                return {
                    method: Method.PUT,
                    url: `${getUri(objectClass)}/${statusRelationId}`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.statusRelationAdapter(response)),
            invalidatesTags: [STATUS_CONFIGURATOR_RELATION_TAG, STATUS_CONFIGURATOR_STATUS_TAG],
        }),
        deleteRelation: builder.mutation({
            query: (args) => {
                const { objectClass, statusRelationId } = args;
                return {
                    method: Method.DELETE,
                    url: `${getUri(objectClass)}/${statusRelationId}`,
                };
            },
            transformResponse: (_, __, { statusRelationId }) => statusRelationId,
            invalidatesTags: [STATUS_CONFIGURATOR_RELATION_TAG, STATUS_CONFIGURATOR_STATUS_TAG],
        }),
    }),
    overrideExisting: false,
});
export default statusConfiguratorRelationApi;
