var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Modal, Spin, Transfer } from "antd";
import { throttle } from "lodash";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { getWarehouseCellList } from "../../../../../api/rest/requests/warehouse/cell";
export const AddCellModal = ({ warehouseId, visible, onAdd, setModelVisible }) => {
    const [targetKeys, setTargetKeys] = useState([]);
    return (React.createElement(Modal, { title: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u044F\u0447\u0435\u0439\u043A\u0443", open: visible, onOk: () => {
            onAdd(targetKeys.map(el => +el));
        }, onCancel: () => {
            setModelVisible(false);
        } },
        React.createElement(AddCellTransfer, { warehouseId: warehouseId, setTargetKeys: setTargetKeys, targetKeys: targetKeys })));
};
const getData = (warehouseId, page, pageSize, search, ignoreLoading, setData, setIsLoading, setTotal) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    !ignoreLoading && setIsLoading(true);
    const result = yield getWarehouseCellList(warehouseId.toString(), page, pageSize, search);
    const resultAdapter = (_a = result === null || result === void 0 ? void 0 : result.list) === null || _a === void 0 ? void 0 : _a.map((el) => ({
        key: el.id.toString(),
        title: el.title || "",
        address: el.address || "",
        chosen: false,
    }));
    setTotal(result.pagination.itemsTotalCount);
    setData(resultAdapter);
    !ignoreLoading && setIsLoading(false);
});
const fetchMore = (warehouseId, page, pageSize, search, setData, setIsLoading) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    setIsLoading(true);
    const result = yield getWarehouseCellList(warehouseId.toString(), page, pageSize, search);
    const resultAdapter = (_b = result === null || result === void 0 ? void 0 : result.list) === null || _b === void 0 ? void 0 : _b.map((el) => ({
        key: el.id.toString(),
        title: el.title || "",
        address: el.address || "",
        chosen: false,
    }));
    setData((prev) => {
        return [...prev, ...resultAdapter];
    });
    setIsLoading(false);
});
const DEFAULT_PAGINATION = { page: "1", pageSize: "12" };
const AddCellTransfer = ({ warehouseId, targetKeys, setTargetKeys }) => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    useLayoutEffect(() => {
        getData(warehouseId, pagination.page, pagination.pageSize, search, false, setData, setIsLoading, setTotal);
    }, []);
    useEffect(() => {
        const handler = setTimeout(() => {
            setPagination(DEFAULT_PAGINATION);
            getData(warehouseId, DEFAULT_PAGINATION.page, DEFAULT_PAGINATION.pageSize, search, true, setData, setIsLoading, setTotal);
        }, 200);
        return () => {
            clearTimeout(handler);
        };
    }, [search]);
    const handleChange = (targetKeys, direction, moveKeys) => {
        setTargetKeys(targetKeys.map((x) => x));
    };
    const debouncedTryFetchMore = throttle(() => {
        if (data.length < total) {
            const currentPage = pagination.page;
            const nextPage = +currentPage + 1;
            setPagination((prev) => (Object.assign(Object.assign({}, prev), { page: nextPage.toString() })));
            fetchMore(warehouseId, nextPage.toString(), pagination.pageSize, search, setData, setIsLoading);
        }
    }, 1000);
    return (React.createElement(Transfer, { disabled: isLoading, onScroll: (_, e) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom && data.length && data.length < total && !isLoading) {
                debouncedTryFetchMore();
            }
        }, onSearch: (direction, value) => {
            if (direction === "left") {
                setSearch(value);
            }
        }, dataSource: data, showSearch: true, listStyle: {
            height: 300,
        }, operations: ["Добавить", "Убрать"], targetKeys: targetKeys, onChange: handleChange, render: (item) => `${item.title}`, footer: isLoading
            ? (_, info) => (info === null || info === void 0 ? void 0 : info.direction) === "left" ? (React.createElement("div", { style: { padding: "8px", display: "flex", alignItems: "center", gap: "8px" } },
                React.createElement("div", null, "\u041F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0435 \u0434\u0430\u043D\u043D\u044B\u0445..."),
                React.createElement("div", null,
                    React.createElement(Spin, { size: "small" })))) : (React.createElement(React.Fragment, null))
            : () => React.createElement(React.Fragment, null) }));
};
