import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskCorrectionListDocument = gql `
    query TaskCorrectionList($page: Int!, $pageSize: Int!, $filter: TaskFilterRequestInput!) {
  taskCorrectionList(page: $page, pageSize: $pageSize, filter: $filter) {
    data {
      id
      externalId
      createdAt
      updatedAt
      executedAt
      warehouse {
        id
        storageTitle
      }
      details {
        id
        productTypeTitle
        productTitle
        quantityBefore
        quantityAfter
        reason
        skuId
        storageCell {
          id
          title
          address
        }
        unit
      }
      user {
        email
        id
        userName
      }
      status {
        title
        style
        scope
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useTaskCorrectionListQuery__
 *
 * To run a query within a React component, call `useTaskCorrectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCorrectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCorrectionListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskCorrectionListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskCorrectionListDocument, options);
}
export function useTaskCorrectionListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskCorrectionListDocument, options);
}
export function useTaskCorrectionListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskCorrectionListDocument, options);
}
export const TaskCorrectionDocument = gql `
    query TaskCorrection($taskId: Long!) {
  taskCorrection(taskId: $taskId) {
    id
    externalId
    createdAt
    updatedAt
    executedAt
    details {
      id
      productAfter {
        id
        titleCompiled
      }
      productBefore {
        id
        titleCompiled
      }
      attributes {
        valueAfterReadable
        valueBefore
        valueBeforeReadable
        skuAttributeValueId
        valueAfter
        unit
        title
        id
        isReadOnly
        isRequired
        handbookId
        dataType
      }
      productTypeTitle
      productTypeId
      productTitle
      quantityBefore
      quantityAfter
      reason
      skuId
      storageCell {
        id
        title
        address
      }
      unit
    }
    warehouse {
      id
      storageTitle
    }
    user {
      userName
    }
    status {
      title
      style
      scope
      relations {
        id
        title
        style
        needReport
      }
    }
  }
}
    `;
/**
 * __useTaskCorrectionQuery__
 *
 * To run a query within a React component, call `useTaskCorrectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCorrectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCorrectionQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskCorrectionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskCorrectionDocument, options);
}
export function useTaskCorrectionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskCorrectionDocument, options);
}
export function useTaskCorrectionSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskCorrectionDocument, options);
}
export const GetTaskCorrectionHistoryDocument = gql `
    query getTaskCorrectionHistory($taskId: Long!) {
  taskCorrectionHistory(taskId: $taskId) {
    createdAt
    isEntryPoint
    needReport
    report
    statusFrom {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    statusTo {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    user {
      uid
      email
      userName
      id
    }
  }
}
    `;
/**
 * __useGetTaskCorrectionHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskCorrectionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCorrectionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCorrectionHistoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskCorrectionHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTaskCorrectionHistoryDocument, options);
}
export function useGetTaskCorrectionHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTaskCorrectionHistoryDocument, options);
}
export function useGetTaskCorrectionHistorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetTaskCorrectionHistoryDocument, options);
}
