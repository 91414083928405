var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { useGetTaskInventoryHistoryLazyQuery, useTaskInventoryLazyQuery, useTaskInventoryListLazyQuery } from "../../../api/graphql/queries/task/taskInventory/TaskInventoryQuery";
import { usePagination } from "../../../hooks/usePagination";
import { useTaskFilter } from "../../../hooks/useTaskFilter";
import { useTaskInventoryChangeStatusMutation } from "../../../api/graphql/queries/task/taskInventory/TaskInventoryMutation";
export const useTaskInventory = () => {
    var _a, _b;
    const [isDataReady, setIsDataReady] = useState(false);
    const [getById, { loading: getTaskByIdLoading, data: getTaskByIdData, refetch: refetchGqttaskById }] = useTaskInventoryLazyQuery();
    const [getTaskList, { loading: getTaskListLoading, data: getTaskListData }] = useTaskInventoryListLazyQuery({ onCompleted: () => { setIsDataReady(true); } });
    const [getHistory, { loading: getHistoryLoading, data: getHistoryData, refetch: refetchHistory }] = useGetTaskInventoryHistoryLazyQuery();
    const [changeStatus, { loading: changeStatusLoading }] = useTaskInventoryChangeStatusMutation();
    const { page, pageSize } = usePagination();
    const { filterValues } = useTaskFilter();
    const [filter, setFilter] = useState(null);
    const paginationFilteredGetTaskList = (filter = filterValues) => __awaiter(void 0, void 0, void 0, function* () {
        setFilter(filter);
        yield getTaskList({ variables: { page, pageSize, filter }, fetchPolicy: "no-cache" });
    });
    useEffect(() => {
        if (filter) {
            paginationFilteredGetTaskList(filter);
        }
    }, [page, pageSize]);
    return {
        history: {
            getHistory,
            data: getHistoryData === null || getHistoryData === void 0 ? void 0 : getHistoryData.taskInventoryHistory,
            loading: getHistoryLoading,
            refetch: refetchHistory,
        },
        task: {
            getById,
            changeStatus,
            data: getTaskByIdData === null || getTaskByIdData === void 0 ? void 0 : getTaskByIdData.taskInventory,
            loading: getTaskByIdLoading || changeStatusLoading,
            refetch: refetchGqttaskById,
        },
        taskList: {
            data: (_a = getTaskListData === null || getTaskListData === void 0 ? void 0 : getTaskListData.taskInventoryList) === null || _a === void 0 ? void 0 : _a.data,
            loading: getTaskListLoading || !isDataReady,
            pagination: (_b = getTaskListData === null || getTaskListData === void 0 ? void 0 : getTaskListData.taskInventoryList) === null || _b === void 0 ? void 0 : _b.pagination,
            getTaskList,
            paginationFilteredGetTaskList,
        },
    };
};
