import React from 'react';
import Breadcrumbs from '../Breadcrumbs';
import ServiceManager from "../ServiceManager";
const Header = (props) => {
    const { onMenuBtnClick } = props;
    return (React.createElement("div", { className: "app-header" },
        React.createElement("span", { className: "sidebar-toggle mdi mdi-menu", onClick: onMenuBtnClick }),
        React.createElement("div", { className: "app-header-crumbs" },
            React.createElement(Breadcrumbs, null)),
        React.createElement(ServiceManager, null)));
};
export default Header;
