var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Empty } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import HandbookTable from "../../components/HandbookTable";
import Centered from "../../components/ui/Centered";
import { usePagination } from "../../hooks/usePagination";
import handbookValueApi from "../../redux/api/handbookApi/handbookValue";
import { HANDBOOK_PARAM } from "../pages/HandbookPage";
import { HANDBOOK_SEARCH } from "../../components/HandbookSearch/HandbookSearch";
const { useFetchValueListQuery, useEditValueMutation, useDeleteValueMutation } = handbookValueApi;
const HandbookTableContainer = () => {
    var _a;
    const [searchParams] = useSearchParams();
    const handbookParam = searchParams.get(HANDBOOK_PARAM);
    const handbookId = handbookParam ? +handbookParam : null;
    const handbookSearch = searchParams.get(HANDBOOK_SEARCH) || undefined;
    const { page, pageSize } = usePagination();
    const { data, isLoading } = useFetchValueListQuery({ handbookId: handbookId, page, pageSize, search: handbookSearch }, { skip: !Number.isInteger(handbookId) });
    const [editValue] = useEditValueMutation();
    const [deleteValue] = useDeleteValueMutation();
    const handlerDelete = (handbookValueId) => __awaiter(void 0, void 0, void 0, function* () {
        deleteValue(handbookValueId);
    });
    const handlerEdit = (handbookValueId, values) => __awaiter(void 0, void 0, void 0, function* () {
        editValue({ handbookValueId, values });
    });
    if (!(data === null || data === void 0 ? void 0 : data.list.length) && !isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Empty, { description: "\u0421\u043F\u0440\u0430\u0432\u043E\u0447\u043D\u0438\u043A \u043D\u0435 \u0432\u044B\u0431\u0440\u0430\u043D" })));
    }
    return React.createElement(HandbookTable, { data: (_a = data === null || data === void 0 ? void 0 : data.list.map((item) => (Object.assign({ key: item.id }, item)))) !== null && _a !== void 0 ? _a : [], loading: isLoading, onDelete: handlerDelete, onEdit: handlerEdit });
};
export default HandbookTableContainer;
