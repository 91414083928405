import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ServiceListDocument = gql `
    query ServiceList {
  serviceList {
    title
    description
    url
    applications {
      title
      description
      urls {
        title
        uri
      }
      settings
      settingsAsUrl
    }
  }
}
    `;
/**
 * __useServiceListQuery__
 *
 * To run a query within a React component, call `useServiceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ServiceListDocument, options);
}
export function useServiceListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ServiceListDocument, options);
}
export function useServiceListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ServiceListDocument, options);
}
