import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, Space, Typography } from "antd";
import React, { useCallback } from "react";
import ProductTypeAttrTable from "../ProductTypeAttrTable";
import MDIcon from "../ui/MDIcon";
const { confirm } = Modal;
const { Title } = Typography;
const ProductTypeAttrCategories = (props) => {
    const { categoryList, onEdit, onDelete, onAttrAdd, onAttrDelete, onAttrEdit, onAttrsSort, onSort } = props;
    const handlerDelete = (categoryId) => {
        const category = categoryList.find((item) => item.id === categoryId);
        if (category) {
            confirm({
                title: "Удаление категории",
                icon: React.createElement(ExclamationCircleOutlined, null),
                content: (React.createElement(React.Fragment, null,
                    "Вы уверены, что хотите удалить категорию ",
                    React.createElement("b", null, category.title),
                    "?")),
                okText: "Да",
                okType: "danger",
                cancelText: "Отмена",
                onOk() {
                    onDelete(categoryId);
                },
            });
        }
    };
    const handlerSort = (oldIndex, newIndex) => {
        if (oldIndex !== newIndex) {
            const result = categoryList.map((item) => item.id);
            const draggableItem = result.splice(oldIndex, 1)[0];
            result.splice(newIndex, 0, draggableItem);
            onSort(result);
        }
    };
    const menu = useCallback((categoryId) => ({
        items: [
            {
                key: "product_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => onEdit(categoryId),
            },
            {
                key: "product_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => handlerDelete(categoryId),
            },
        ],
    }), [categoryList]);
    const sortMenu = useCallback((index) => ({
        items: [
            {
                key: "product_category_sort_up",
                icon: React.createElement(MDIcon, { icon: "arrow-up-thick", className: "ant-dropdown-menu-item-icon" }),
                label: "Переместить выше",
                disabled: index == 0,
                onClick: () => handlerSort(index, index - 1),
            },
            {
                key: "product_category_sort_down",
                icon: React.createElement(MDIcon, { icon: "arrow-down-thick", className: "ant-dropdown-menu-item-icon" }),
                label: "Переместить ниже",
                disabled: index == categoryList.length - 1,
                onClick: () => handlerSort(index, index + 1),
            },
        ],
    }), [categoryList]);
    const buttons = (categoryId, index) => (React.createElement(Button.Group, { style: { marginBottom: "10px" } },
        React.createElement(Dropdown, { menu: sortMenu(index), trigger: ["click"] },
            React.createElement(Button, { size: "small", icon: React.createElement(MDIcon, { icon: "sort" }) })),
        React.createElement(Dropdown, { menu: menu(categoryId), trigger: ["click"] },
            React.createElement(Button, { type: "primary", size: "small", icon: React.createElement(MDIcon, { icon: "pencil" }) }))));
    return (React.createElement(React.Fragment, null, categoryList.map((category, index) => (React.createElement("div", { className: "mb-5", key: category.id },
        React.createElement(Space, { style: { width: "auto" } },
            buttons(category.id, index),
            React.createElement(Title, { level: 4 }, category.title)),
        React.createElement(ProductTypeAttrTable, { attributes: category.productTypeAttributes, onDelete: onAttrDelete, onEdit: onAttrEdit, onAdd: () => onAttrAdd(category.id), onSort: (sort) => onAttrsSort(category.id, sort) }))))));
};
export default ProductTypeAttrCategories;
