import React from "react";
import TaskListItemWithSlot from "../../../../components/task/TaskList/TaskListItemWithSlot";
import { links } from "../../../../constants/links";
import TableContainer from "./Tables/ListTable/Table";
const WriteOffTaskListItem = (props) => {
    const { task, pagination } = props;
    const { skuGroup } = task;
    const info = [{ title: "Склад", value: task.warehouse.storageTitle }];
    return React.createElement(TaskListItemWithSlot, { pagination: pagination, task: task, info: info, slot: React.createElement(TableContainer, { skuGorup: skuGroup }), link: links.taskWriteOffLinks.page.url(task.id) });
};
export default WriteOffTaskListItem;
