var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Checkbox, Form, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback } from "react";
import ProductTypeAttrInput from "../../../../components/ProductTypeAttrInput";
import StorageCellSelect from "../../../../components/StorageCellSelect";
import MDIcon from "../../../../components/ui/MDIcon";
import ProductDataType from "../../../../constants/enum/ProductDataType";
import { ToleranceType } from "../../../../constants/enum/ToleranceType";
import { useFormContext } from "./CorrectionDetailNomenclatureForm/CorrectionDetailNomenclatureFormItemContainer";
import { DataIndexType } from "./CorrectionDetailNomenclatureForm/types";
const CorrectionDetailNomenclatureCell = (props) => {
    const { editing, dataIndex, value, warehouseId, isAttribute, children, record, attributes } = props, restProps = __rest(props, ["editing", "dataIndex", "value", "warehouseId", "isAttribute", "children", "record", "attributes"]);
    const context = useFormContext();
    if (!context) {
        throw new Error("useFormContext must be used within a FormContext.Provider");
    }
    const { handelOpenProductModal, activeProductTypeTitle } = context;
    const renderEditableField = useCallback(() => {
        if (!editing) {
            return children;
        }
        let inputNode;
        let rules = [];
        switch (dataIndex) {
            case DataIndexType.StorageCell:
                inputNode = React.createElement(StorageCellSelect, { warehouseId: warehouseId, value: value });
                break;
            case DataIndexType.ToleranceType:
                inputNode = (React.createElement(Select, null,
                    React.createElement(Select.Option, { value: ToleranceType.POSITIVE }, "\u041F\u043E\u043B\u043E\u0436\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0439"),
                    React.createElement(Select.Option, { value: ToleranceType.NEGATIVE }, "\u041E\u0442\u0440\u0438\u0446\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439")));
                break;
            case DataIndexType.Product:
                inputNode = (React.createElement(Row, { style: { gap: "8px", justifyContent: "space-between", flexWrap: "nowrap" } },
                    activeProductTypeTitle,
                    React.createElement(Button, { onClick: () => {
                            if (typeof value === "object" && "productType" in value && "label" in value["productType"] && "value" in value["productType"]) {
                                handelOpenProductModal(value["productType"]["label"], value["productType"]["value"]);
                            }
                        }, type: "text", style: { marginLeft: "auto" }, size: "small", icon: React.createElement(MDIcon, { icon: "pencil" }) })));
                break;
            case DataIndexType.Reason:
                inputNode = React.createElement(TextArea, { style: { minWidth: "200px" } });
                break;
            default:
                if ((attributes === null || attributes === void 0 ? void 0 : attributes.dataType) && dataIndex) {
                    inputNode = (React.createElement(ProductTypeAttrInput, { attribute: { id: attributes.id, handbookId: attributes.handbookId, dataType: attributes.dataType, isRequired: attributes.isRequired, dimension: attributes.unit }, value: value, style: { minWidth: "100px" } }));
                }
                else {
                    inputNode = React.createElement(Input, null);
                }
        }
        if (attributes === null || attributes === void 0 ? void 0 : attributes.isRequired) {
            rules.push({ required: true, message: "Заполните поле" });
        }
        switch (attributes === null || attributes === void 0 ? void 0 : attributes.dataType) {
            case ProductDataType.HANDBOOK:
                if (attributes === null || attributes === void 0 ? void 0 : attributes.isRequired) {
                    rules.push({
                        type: "object",
                        validator: (_, fieldValue) => __awaiter(void 0, void 0, void 0, function* () { return (fieldValue.value ? Promise.resolve() : Promise.reject()); }),
                        message: "Заполните поле",
                    });
                }
                break;
            case ProductDataType.INTEGER:
            case ProductDataType.FLOAT:
                rules.push({
                    type: "number",
                    min: 0,
                    message: "Отрицательное значение",
                    validator: (_, fieldValue) => __awaiter(void 0, void 0, void 0, function* () {
                        const value = +fieldValue;
                        return value >= 0 ? Promise.resolve() : Promise.reject();
                    }),
                });
                break;
            case ProductDataType.BOOLEAN:
                if (typeof value === "boolean") {
                    inputNode = React.createElement(Checkbox, { defaultChecked: value });
                }
                return (React.createElement(Form.Item, { name: dataIndex, style: { margin: 0 }, rules: rules, valuePropName: "checked" }, inputNode));
            default:
                break;
        }
        const name = isAttribute ? ["attributes", dataIndex.toString()] : dataIndex;
        return (React.createElement(Form.Item, { name: name, style: { margin: 0 }, rules: rules }, inputNode));
    }, [editing, dataIndex, value, warehouseId, isAttribute, children, record, attributes]);
    return (React.createElement("td", Object.assign({}, restProps),
        React.createElement("span", { style: { whiteSpace: "nowrap" } }, renderEditableField())));
};
export default CorrectionDetailNomenclatureCell;
