import { Col, Form, Input, Row, Typography } from "antd";
import React from "react";
import AttrField from "../nomenclatureSearch/ProductFilter/AttrField";
import UnitSelect from "../UnitSelect";
import HanbookPackingSetGraphqlSelect from "../HanbookPackingSetGraphqlSelect";
import { checkDataTypeIsNotFormula } from "../FormulaMaskInput/FormulaMaskInputContainer";
const { Title } = Typography;
var FormNames;
(function (FormNames) {
    FormNames["TYPE"] = "productTypeId";
    FormNames["UNIT"] = "handbookUnitId";
    FormNames["PACKING_SET"] = "handbookPackingSetId";
    FormNames["ATTRIBUTES"] = "attributes";
})(FormNames || (FormNames = {}));
const ProductForm = (props) => {
    const { form, onSubmit, initialValues, productType } = props;
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues },
        React.createElement(Form.Item, { name: FormNames.TYPE, hidden: true },
            React.createElement(Input, null)),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.UNIT, label: "\u0415\u0434\u0438\u043D\u0438\u0446\u0430 \u0438\u0437\u043C\u0435\u0440\u0435\u043D\u0438\u044F", rules: [{ required: true, message: "Поле не заполнено" }] },
                    React.createElement(UnitSelect, null))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.PACKING_SET, label: "\u041D\u0430\u0431\u043E\u0440 \u0443\u043F\u0430\u043A\u043E\u0432\u043E\u043A" },
                    React.createElement(HanbookPackingSetGraphqlSelect, { defaultValue: initialValues === null || initialValues === void 0 ? void 0 : initialValues[FormNames.PACKING_SET] })))),
        React.createElement("div", null, productType.productTypeCategories.map((category) => {
            var _a;
            return (React.createElement("div", { key: category.id },
                React.createElement(Title, { level: 5 }, category.title),
                React.createElement("div", null, (_a = category.productTypeAttributes) === null || _a === void 0 ? void 0 : _a.filter(({ isPersonal, dataType }) => !isPersonal && checkDataTypeIsNotFormula(dataType)).map((attr) => (React.createElement(AttrField, { key: attr.id, attribute: attr, namePath: [FormNames.ATTRIBUTES] }))))));
        }))));
};
export default ProductForm;
