import React, { useState } from "react";
import { Select, Space } from "antd";
import { WarehouseTopology } from "../../../../../../components/WarehouseTopology/WarehouseTopology";
import { SectionContainer } from "./SectionContainer";
const { Option } = Select;
export const WarehouseWrapper = ({ warehouseId }) => {
    const [selectedView, setSelectedView] = useState("Список");
    const handleChange = (value) => {
        setSelectedView(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Space, { direction: "vertical", size: "small", style: { display: "flex" } },
            React.createElement(Select, { defaultValue: "\u0421\u043F\u0438\u0441\u043E\u043A", style: { width: 120 }, onChange: handleChange },
                React.createElement(Option, { value: "\u0421\u043F\u0438\u0441\u043E\u043A" }, "\u0421\u043F\u0438\u0441\u043E\u043A"),
                React.createElement(Option, { value: "\u0422\u043E\u043F\u043E\u043B\u043E\u0433\u0438\u044F" }, "\u0422\u043E\u043F\u043E\u043B\u043E\u0433\u0438\u044F")),
            selectedView === "Список" ? (React.createElement(SectionContainer, { warehouseId: warehouseId })) : (React.createElement("div", { style: { width: "100%", height: "70dvh" } },
                React.createElement(WarehouseTopology, { enableCellColoring: true, isEditable: false, enableResaze: false, addMargin: false, warehouseIdInit: warehouseId },
                    React.createElement(WarehouseTopology.Map, null),
                    React.createElement(WarehouseTopology.Drawer, null)))))));
};
