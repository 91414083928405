var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from 'antd';
import axios from 'axios';
import LoadingState from '../../constants/enum/LoadingState';
import { AuthService } from '../../services/AuthService';
const headers = () => __awaiter(void 0, void 0, void 0, function* () {
    const headersObject = {
        'Content-Type': 'application/json',
    };
    const token = AuthService.getAccessTokenFromStorage();
    try {
        headersObject.Authorization = `Bearer ${token}`;
        return headersObject;
    }
    catch (e) {
        return headersObject;
    }
});
const handleRequestConfig = (config, loadingState) => {
    if (loadingState) {
        loadingState(LoadingState.Loading);
    }
    return config;
};
const handleRequestError = (error, loadingState) => {
    const errMessage = error.message;
    if (axios.isCancel(error)) {
        if (loadingState) {
            loadingState(LoadingState.Ready);
        }
    }
    else {
        message.error(errMessage);
        if (loadingState) {
            loadingState(LoadingState.Error);
        }
    }
    return Promise.reject(error);
};
const handleSuccess = (response, loadingState) => {
    if (response.request.responseURL && response.request.responseURL.includes('/login')) {
        window.location.href = response.request.responseURL;
    }
    if (loadingState) {
        loadingState(LoadingState.Ready);
    }
    return response;
};
const handleError = (error, loadingState) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const { config, response, message: errMessage } = error;
    if (axios.isCancel(error)) {
        if (loadingState) {
            loadingState(LoadingState.Ready);
        }
    }
    else {
        if ((response === null || response === void 0 ? void 0 : response.status) === 401 && config) {
            return Promise.reject(error);
        }
        message.error((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : errMessage);
        if (loadingState) {
            loadingState(LoadingState.Error);
        }
    }
    return Promise.reject(error);
});
export const request = (loadingStateCallBack, 
// eslint-disable-next-line default-param-last
baseURL = window.env.ApiHost, settings) => __awaiter(void 0, void 0, void 0, function* () {
    let loadingState = () => { };
    if (loadingStateCallBack)
        loadingState = loadingStateCallBack;
    const instance = axios.create(Object.assign(Object.assign({}, settings), { baseURL }));
    instance.defaults.headers = yield headers();
    instance.interceptors.request.use((config) => handleRequestConfig(config, loadingState), (error) => handleRequestError(error, loadingState));
    instance.interceptors.response.use((response) => handleSuccess(response, loadingState), (error) => handleError(error, loadingState));
    return instance;
});
