import { Button, Card, Empty, Space, Spin, Tooltip, Typography } from "antd";
import React from "react";
import Centered from "../../../../components/ui/Centered";
import MDIcon from "../../../../components/ui/MDIcon";
import { useProductDrawer } from "../../../../hooks/useProductDrawer";
import TableContainer from "../../../conponents/task/changeWarehouse/Tables/PreviewTable/Table";
const { Title, Text } = Typography;
const ChangeWarehouseTaskNomenclatureContainer = (props) => {
    var _a;
    const { openProductDrawer } = useProductDrawer();
    const handlerOpenInfo = (productId) => {
        openProductDrawer(productId);
    };
    const { loading, taskChangeWarehouseDto } = props;
    if (loading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, null)));
    }
    if (!!((_a = taskChangeWarehouseDto === null || taskChangeWarehouseDto === void 0 ? void 0 : taskChangeWarehouseDto.skuGroup) === null || _a === void 0 ? void 0 : _a.length)) {
        return (React.createElement(React.Fragment, null, taskChangeWarehouseDto === null || taskChangeWarehouseDto === void 0 ? void 0 : taskChangeWarehouseDto.skuGroup.map((sku, index) => {
            var _a;
            const attributes = (_a = sku.attributes) === null || _a === void 0 ? void 0 : _a.filter((attr) => attr.isPersonal).map((el) => ({ title: el.title, unit: el.unit, id: el.id }));
            return (React.createElement(Card, { key: index, type: "inner", title: React.createElement(Space, { style: { width: "auto" } },
                    React.createElement(Title, { level: 4 }, sku.type),
                    React.createElement("div", { className: "mb-2" },
                        React.createElement(Text, { underline: true }, sku.title))), extra: React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u0442\u0438\u043F\u0435 \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                    React.createElement(Button, { type: "primary", size: "small", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: () => {
                            handlerOpenInfo(sku.productId);
                        } })) },
                React.createElement(TableContainer, { attrebutes: attributes, taskSkus: sku.skus })));
        })));
    }
    return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: React.createElement(React.Fragment, null,
            React.createElement(Title, { level: 5 }, "\u0417\u0430\u0434\u0430\u0447\u0430 \u043D\u0435 \u043D\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0430."),
            React.createElement(Text, null, "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440a.")) }));
};
export default ChangeWarehouseTaskNomenclatureContainer;
