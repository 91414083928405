import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Modal, Tooltip, } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MDIcon from '../ui/MDIcon';
const { confirm } = Modal;
const HandbookTableItemActions = (props) => {
    const { title, isEditable, onEditClick, onCancelClick, onSaveClick, onDeleteClick, } = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const toggleDropdown = () => setDropdownVisible((value) => !value);
    const closeDropdown = () => {
        if (dropdownVisible) {
            setDropdownVisible(false);
        }
    };
    useEffect(() => {
        const wrapper = document.querySelector('.app-content');
        if (dropdownVisible) {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.addEventListener('scroll', closeDropdown);
        }
        return () => {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.removeEventListener('scroll', closeDropdown);
        };
    }, [dropdownVisible]);
    const handlerDelete = () => {
        closeDropdown();
        confirm({
            title: 'Удалить значение',
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: (React.createElement(React.Fragment, null,
                'Вы уверены, что хотите удалить значение ',
                React.createElement("b", null, title),
                "?")),
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk() {
                onDeleteClick();
            },
        });
    };
    const handlerEdit = () => {
        closeDropdown();
        onEditClick();
    };
    const handbookValueDropdownMenu = {
        items: [
            {
                key: "hv_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => handlerEdit()
            },
            {
                key: "hv_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => handlerDelete()
            }
        ]
    };
    if (isEditable) {
        return (React.createElement(Button.Group, { size: "small" },
            React.createElement(Tooltip, { title: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "content-save" }), onClick: onSaveClick })),
            React.createElement(Tooltip, { title: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { type: "primary", danger: true, icon: React.createElement(MDIcon, { icon: "close-circle" }), onClick: onCancelClick }))));
    }
    return (React.createElement(Dropdown, { menu: handbookValueDropdownMenu, open: dropdownVisible, onOpenChange: toggleDropdown, placement: "topLeft", arrow: true, trigger: ['click'] },
        React.createElement(Button, { size: "small", icon: React.createElement(MDIcon, { icon: "dots-vertical" }), type: "primary" })));
};
export default HandbookTableItemActions;
