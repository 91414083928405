import { Col, Row, Typography } from "antd";
import React from "react";
import { SectionCell } from "../../../../../../../components/SectionCell/SectionCell";
export const SectionCellContainer = ({ cells }) => {
    if (Array.isArray(cells) && !(cells === null || cells === void 0 ? void 0 : cells.length)) {
        return (React.createElement(Row, { gutter: [24, 24], wrap: true },
            React.createElement(Col, null,
                React.createElement(Typography.Title, { className: "m-0", level: 5 }, "\u0421\u043F\u0438\u0441\u043E\u043A \u044F\u0447\u0435\u0435\u043A \u043F\u0443\u0441\u0442"))));
    }
    return (React.createElement(React.Fragment, null, cells === null || cells === void 0 ? void 0 : cells.map((i) => (React.createElement(SectionCell, { key: i.cellId, title: i.cellTitle, address: i === null || i === void 0 ? void 0 : i.cellAddress, quantity: i === null || i === void 0 ? void 0 : i.skuCount, percent: i === null || i === void 0 ? void 0 : i.loadPercent })))));
};
