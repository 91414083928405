import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ShapeFragmentDoc = gql `
    fragment Shape on Shape {
  ... on Circle {
    cx
    cy
    r
  }
  ... on Ellipse {
    cx
    cy
    rx
    ry
  }
  ... on Line {
    x1
    x2
    y1
    y2
  }
  ... on Path {
    d
  }
  ... on Polygon {
    points
  }
  ... on Polyline {
    points
  }
  ... on Rect {
    width
    height
    x
    y
    rx
    ry
  }
  __typename
}
    `;
export const VectorObjectFragmentDoc = gql `
    fragment VectorObject on VectorObjectDto {
  shape {
    ...Shape
  }
  style {
    key
    value
  }
  transform {
    key
    value
  }
}
    ${ShapeFragmentDoc}`;
export const WarehouseMapWarehouseDocument = gql `
    query WarehouseMapWarehouse($warehouseId: Long!) {
  warehouseMapWarehouse(warehouseId: $warehouseId) {
    id
    storageTitle
    vectorObject {
      ...VectorObject
    }
  }
}
    ${VectorObjectFragmentDoc}`;
/**
 * __useWarehouseMapWarehouseQuery__
 *
 * To run a query within a React component, call `useWarehouseMapWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseMapWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseMapWarehouseQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useWarehouseMapWarehouseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(WarehouseMapWarehouseDocument, options);
}
export function useWarehouseMapWarehouseLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(WarehouseMapWarehouseDocument, options);
}
export function useWarehouseMapWarehouseSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(WarehouseMapWarehouseDocument, options);
}
export const WarehouseMapStorageCellDocument = gql `
    query WarehouseMapStorageCell($warehouseId: Long!) {
  warehouseMapStorageCells(warehouseId: $warehouseId) {
    id
    address
    title
    storageSectionId
    storageCellType {
      title
      cellWidth
      cellHeight
    }
    vectorObject {
      ...VectorObject
    }
  }
}
    ${VectorObjectFragmentDoc}`;
/**
 * __useWarehouseMapStorageCellQuery__
 *
 * To run a query within a React component, call `useWarehouseMapStorageCellQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseMapStorageCellQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseMapStorageCellQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useWarehouseMapStorageCellQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(WarehouseMapStorageCellDocument, options);
}
export function useWarehouseMapStorageCellLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(WarehouseMapStorageCellDocument, options);
}
export function useWarehouseMapStorageCellSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(WarehouseMapStorageCellDocument, options);
}
