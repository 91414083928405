import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const AvailabilityOnDateQueryDocument = gql `
    query AvailabilityOnDateQuery($input: AvailabilityOnDateInput!, $page: Int!, $pageSize: Int!) {
  availabilityOnDate(input: $input, page: $page, pageSize: $pageSize) {
    rows {
      companyInn
      companyName
      id
      productTitle
      productTypeTitle
      quantity
      taskArrival {
        discriminator
        executedAt
        id
        inRange
      }
      taskDeparture {
        discriminator
        executedAt
        id
        inRange
      }
    }
    total {
      arrivalCount
      arrivalInRageCount
      arrivalQuantity
      departureCount
      departureInRageCount
      departureQuantity
      quantityBeginning
      quantityEnd
      totalCount
      totalQuantity
    }
  }
}
    `;
/**
 * __useAvailabilityOnDateQueryQuery__
 *
 * To run a query within a React component, call `useAvailabilityOnDateQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityOnDateQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityOnDateQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useAvailabilityOnDateQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AvailabilityOnDateQueryDocument, options);
}
export function useAvailabilityOnDateQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AvailabilityOnDateQueryDocument, options);
}
export function useAvailabilityOnDateQuerySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(AvailabilityOnDateQueryDocument, options);
}
