import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import TaskHeaderBase from "../../../../components/task/TaskHeader/TaskHeaderBase";
import MDIcon from "../../../../components/ui/MDIcon";
import { links } from "../../../../constants/links";
const ShippingEditTaskHeaderContainer = ({ task, id, isLoading }) => {
    var _a;
    const navigate = useNavigate();
    return (React.createElement(TaskHeaderBase, { externalId: task === null || task === void 0 ? void 0 : task.externalId, id: task === null || task === void 0 ? void 0 : task.id, userName: (_a = task === null || task === void 0 ? void 0 : task.user) === null || _a === void 0 ? void 0 : _a.userName, createdAt: task === null || task === void 0 ? void 0 : task.createdAt, executedAt: task === null || task === void 0 ? void 0 : task.executedAt, isLoading: isLoading, taskTypeTitle: "\u041E\u0442\u0433\u0440\u0443\u0437\u043A\u0430", short: true, actions: React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "arrow-left", style: { marginRight: 5 } }), onClick: () => navigate(links.taskShippingLinks.page.url(id)) }, "\u041A \u0437\u0430\u0434\u0430\u0447\u0435") }));
};
export default ShippingEditTaskHeaderContainer;
