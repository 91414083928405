var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useParams } from "react-router-dom";
import { useProductTypeAutomationCreateMutation, useProductTypeAutomationDeleteMutation, useProductTypeAutomationEditMutation } from "../../../../../../api/graphql/queries/automatization/AutomatizationMutation";
import { useProductTypeAutomationListQuery } from "../../../../../../api/graphql/queries/automatization/AutomatizationQuery";
export const useProductAutomatizationPage = ({ page, pageSize }) => {
    const { typeId } = useParams();
    const { data, loading, error, refetch } = useProductTypeAutomationListQuery({ variables: { productTypeId: Number(typeId), page: page || 1, pageSize: pageSize || 50 }, fetchPolicy: "cache-and-network" });
    return {
        getAutomatizationList: {
            data,
            error,
            isLoading: loading,
            refetch,
        },
    };
};
export const useProductAutomatizationActions = () => {
    const [deleteProductAutomatization, { loading: deleteLoading }] = useProductTypeAutomationDeleteMutation();
    const [createProductAutomatization, { loading: createLoading }] = useProductTypeAutomationCreateMutation();
    const [editProductAutomatization, { loading: editLoading }] = useProductTypeAutomationEditMutation();
    const handleDelete = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteProductAutomatization({ variables: { input } });
    });
    const handleCreate = (input) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield createProductAutomatization({ variables: { input } });
        return res;
    });
    const handleEdit = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield editProductAutomatization({ variables: { input } });
    });
    return {
        deleteProductAutomatization: {
            handleAction: handleDelete,
            isLoading: deleteLoading,
        },
        editProductAutomatization: {
            handleAction: handleEdit,
            isLoading: editLoading,
        },
        createProductAutomatization: {
            handleAction: handleCreate,
            isLoading: createLoading,
        },
    };
};
