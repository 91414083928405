var ObjectClass;
(function (ObjectClass) {
    ObjectClass["TaskLoading"] = "TaskLoading";
    ObjectClass["TaskShipping"] = "TaskShipping";
    ObjectClass["TaskMovement"] = "TaskMovement";
    ObjectClass["TaskPartition"] = "TaskPartition";
    ObjectClass["TaskCorrection"] = "TaskCorrection";
    ObjectClass["TaskInventory"] = "TaskInventory";
    ObjectClass["TaskWriteOff"] = "TaskWriteOff";
    ObjectClass["TaskAssemblySku"] = "TaskAssemblySku";
    ObjectClass["TaskChangeWarehouse"] = "TaskChangeWarehouse";
    ObjectClass["SKU"] = "Sku";
    ObjectClass["StorageSection"] = "StorageSection";
    ObjectClass["StorageCell"] = "StorageCell";
})(ObjectClass || (ObjectClass = {}));
export default ObjectClass;
