import { Spin } from 'antd';
import React from 'react';
import Centered from '../../../../components/ui/Centered';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useProductDrawer } from '../../../../hooks/useProductDrawer';
import { skuActions } from '../../../../redux/reducers/skuReducer';
import ShippingDetailNomenclature from '../../../conponents/task/shipping/ShippingDetailNomenclature/ShippingDetailNomenclature';
const ShippingTaskNomenclatureContainer = (props) => {
    var _a;
    const { isLoading, task, children, refetch } = props;
    const dispatch = useAppDispatch();
    const { openProductDrawer } = useProductDrawer();
    const handlerInfoClick = (productId) => {
        openProductDrawer(productId);
    };
    const handlerSkuInfoClick = (skuId) => dispatch(skuActions.open(skuId));
    if (isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, null)));
    }
    if (task) {
        return (React.createElement(ShippingDetailNomenclature, { refetch: refetch, warehouseId: (_a = task === null || task === void 0 ? void 0 : task.warehouse) === null || _a === void 0 ? void 0 : _a.id, isLoading: isLoading, details: task.details, onInfoClick: handlerInfoClick, onSkuInfoClick: handlerSkuInfoClick }, children));
    }
    return null;
};
export default ShippingTaskNomenclatureContainer;
