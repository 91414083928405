var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Col, Row, Space, message } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaginationPanel from "../../../../components/PaginationPanel";
import MDIcon from "../../../../components/ui/MDIcon";
import { links } from "../../../../constants/links";
import { usePagination } from "../../../../hooks/usePagination";
import CreateProductAutomationModal from "./components/automationList/CreateProductAutomationModal";
import ProductAutomatizationTableContainer from "./components/automationList/ProductAutomatizationTableContainer";
import { useProductAutomatizationActions, useProductAutomatizationPage } from "./hooks/api/useProductAutomatizationPage";
export const ProductAutomatizationListPage = ({}) => {
    var _a, _b, _c, _d;
    const navigate = useNavigate();
    const { page, pageSize, setPagination } = usePagination();
    const { typeId } = useParams();
    const { createProductAutomatization, deleteProductAutomatization } = useProductAutomatizationActions();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleOpen = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _e, _f;
        try {
            yield createProductAutomatization
                .handleAction({
                title: (_e = values === null || values === void 0 ? void 0 : values.title) !== null && _e !== void 0 ? _e : "",
                enable: !!(values === null || values === void 0 ? void 0 : values.enable),
                schedule: (_f = values === null || values === void 0 ? void 0 : values.schedule) !== null && _f !== void 0 ? _f : "",
                productTypeId: Number(typeId),
                productTypeAttributeId: values.productTypeAttributeId,
            })
                .then((res) => {
                var _a, _b, _c;
                navigate(links.automatizationLink.url(Number(typeId), (_c = (_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.productTypeAutomationCreate) === null || _b === void 0 ? void 0 : _b.productTypeAutomationDto) === null || _c === void 0 ? void 0 : _c.id));
            });
        }
        catch (error) {
            message.error({ content: "Ошибка создания автоматизации", duration: 5 });
        }
        finally {
            setIsModalVisible(false);
        }
    });
    const { getAutomatizationList } = useProductAutomatizationPage({ page, pageSize });
    const automatizationList = ((_b = (_a = getAutomatizationList === null || getAutomatizationList === void 0 ? void 0 : getAutomatizationList.data) === null || _a === void 0 ? void 0 : _a.productTypeAutomationList) === null || _b === void 0 ? void 0 : _b.data) || [];
    const handlerEditItemClick = (id) => {
        navigate(links.automatizationLink.url(Number(typeId), id));
    };
    const handlerDeleteItemClick = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteProductAutomatization.handleAction({
            productTypeAutomationId: id,
        });
        yield getAutomatizationList.refetch();
    });
    const isLoading = (deleteProductAutomatization === null || deleteProductAutomatization === void 0 ? void 0 : deleteProductAutomatization.isLoading) || (getAutomatizationList === null || getAutomatizationList === void 0 ? void 0 : getAutomatizationList.isLoading) || (createProductAutomatization === null || createProductAutomatization === void 0 ? void 0 : createProductAutomatization.isLoading);
    return (React.createElement(Space, { direction: "vertical", size: "large", style: { width: "100%" } },
        React.createElement(Row, { justify: "end" },
            React.createElement(Col, null,
                React.createElement(Button, { loading: isLoading, icon: React.createElement(MDIcon, { icon: "plus" }), type: "primary", onClick: handleOpen }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0437\u0430\u0446\u0438\u044E"))),
        React.createElement(Row, null,
            React.createElement(Col, { span: 24 },
                React.createElement(ProductAutomatizationTableContainer, { isListLoading: isLoading, onDeleteItemClick: handlerDeleteItemClick, data: automatizationList, onEditItemClick: handlerEditItemClick }))),
        React.createElement(Row, null,
            React.createElement(Col, { span: 24 },
                React.createElement(ProductAutomatizationPaginationContainer, { pagination: (_d = (_c = getAutomatizationList === null || getAutomatizationList === void 0 ? void 0 : getAutomatizationList.data) === null || _c === void 0 ? void 0 : _c.productTypeAutomationList) === null || _d === void 0 ? void 0 : _d.pagination, setPagination: setPagination, isLoading: getAutomatizationList === null || getAutomatizationList === void 0 ? void 0 : getAutomatizationList.isLoading }))),
        React.createElement(CreateProductAutomationModal, { isLoading: isLoading, visible: isModalVisible, onSubmit: handleSubmit, onCancel: handleCancel })));
};
const ProductAutomatizationPaginationContainer = (props) => {
    const { pagination, isLoading, setPagination } = props;
    const handlerChange = (nextPage, nextPageSize) => {
        setPagination({
            page: nextPage,
            pageSize: nextPageSize,
        });
    };
    if (!!pagination) {
        return React.createElement(PaginationPanel, { pagination: pagination, disabled: isLoading, onChange: handlerChange });
    }
    return null;
};
