import { Button, Form, message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useHandbookPackingSetCreateMutation } from "../../../../../api/graphql/queries/handbookPacking/HandbookPackingMutations";
import { links } from "../../../../../constants/links";
import PackingSetForm from "../shared/PackingSetForm/PackingSetForm";
import { SUCCESS_MESSAGE } from "../../constants";
const PackingSetAddFormContainer = () => {
    const [form] = Form.useForm();
    const [addPackingSet] = useHandbookPackingSetCreateMutation();
    const navigate = useNavigate();
    const handlerButtonClick = () => {
        form.submit();
    };
    const handlerSubmit = (values) => {
        if (typeof values.handbookUnitId.value === "number") {
            addPackingSet({
                variables: {
                    input: {
                        handbookUnitId: values.handbookUnitId.value,
                        title: values.title,
                    },
                },
            }).then(() => {
                message.success(SUCCESS_MESSAGE.packing_set_with_name_create(values.title));
                navigate(links.handbookPackingLink.url());
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PackingSetForm, { form: form, onSubmit: handlerSubmit }),
        React.createElement(Button, { type: "primary", onClick: handlerButtonClick }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
};
export default PackingSetAddFormContainer;
