var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '../../api';
import { getUrlWithParams } from '../../../../helpers/function';
import dtoToEntity from '../../adapters/dtoToEntity';
export const getDocumentAvailable = (dataSet, handlerState) => __awaiter(void 0, void 0, void 0, function* () {
    const url = getUrlWithParams('document/available', { dataSet });
    const { data, } = yield request(handlerState, window.env.DocumentsHost)
        .then(instance => instance.get(url));
    return data.map(dtoToEntity.documentAvailableAdapter);
});
export const getDocument = (documentId, objectId, handlerState) => __awaiter(void 0, void 0, void 0, function* () {
    const url = getUrlWithParams(`document/${documentId}`, { objectId });
    const { data } = yield request(handlerState, window.env.DocumentsHost, {
        responseType: 'arraybuffer',
    })
        .then(instance => instance.get(url));
    return data;
});
export const getDocumentUrl = (documentId, objectId) => (window.env.DocumentsHost + getUrlWithParams(`/document/${documentId}`, {
    objectId: objectId === null || objectId === void 0 ? void 0 : objectId.toString(),
}));
