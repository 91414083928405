import { getColumnType } from "../../../../../../../helpers/function";
import { Button, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import MDIcon from "../../../../../../../components/ui/MDIcon";
export const columns = [
    getColumnType("number", "#", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("type", "Тип продукции", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("title", "Наименование", true, {
        fixed: "left",
    }),
    getColumnType("quantity", "Количество", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("storageCellExpectedTitle", "Ячейка хранения", true, {
        fixed: "left",
        width: "80px",
    }),
];
export const createActionColumn = (onDelete, onOpenSkuInfo, isLoading, isLocked) => [
    getColumnType("", "", true, {
        fixed: "left",
        width: "80px",
        render: (record) => (React.createElement("div", { style: { display: "flex", alignItems: "center", gap: "4px" } },
            React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                React.createElement(Button, { disabled: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), size: "small", onClick: () => {
                        onOpenSkuInfo(record.number);
                    } })),
            React.createElement(Button, { danger: true, size: "small", type: "primary", disabled: isLoading || isLocked, icon: React.createElement(CloseOutlined, null), onClick: () => {
                    onDelete(record.number);
                } }))),
    }),
];
export const emptyColumns = [
    getColumnType("number", "#", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("type", "Тип продукции", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("title", "Наименование", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("quantity", "Количество", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("storageCellExpectedTitle", "Ячейка хранения", true, {
        fixed: "left",
        width: "80px",
    }),
];
