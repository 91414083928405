import { Button, Form, message, Spin } from "antd";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { convertProductDataTypeToDataType } from "../../api/graphql/adapters";
import { formatFormulaInput } from "../../components/FormulaMaskInput/FormulaMaskInput";
import ProductTypeAttrForm from "../../components/ProductTypeAttrForm";
import { links } from "../../constants/links";
import { productTypeGraphqlApi } from "../../redux/api/productApi/productType";
const { useFetchProductTypeQuery, useAddProductTypeAttributeMutation } = productTypeGraphqlApi;
const ProductTypeAttrFormAddContainer = () => {
    const { typeId: typeParam } = useParams();
    const typeId = typeParam ? +typeParam : null;
    const [searchParams] = useSearchParams();
    const categoryId = searchParams.get("categoryId");
    const { data: type, isLoading: typeIsLoading } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    const [addProductTypeAttribute, { isLoading: addIsLoading }] = useAddProductTypeAttributeMutation();
    const isLoading = typeIsLoading || addIsLoading;
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const handlerButtonClick = () => {
        form.submit();
    };
    const handlerSubmit = (values) => {
        var _a;
        if (typeId) {
            addProductTypeAttribute({
                input: {
                    canBeSummarized: !!values.canBeSummarized,
                    description: values.description,
                    dimension: values.dimension,
                    handbookId: (_a = values.handbookId) === null || _a === void 0 ? void 0 : _a.value,
                    inFilter: !!values.inFilter,
                    inPrint: !!values.inPrint,
                    formula: formatFormulaInput(values.formula),
                    isMain: !!values.isMain,
                    isPersonal: !!values.isPersonal,
                    isRequired: !!values.isRequired,
                    isReadOnly: !!values.isReadOnly,
                    productTypeCategoryId: values.productTypeCategoryId,
                    title: values.title,
                    dataType: typeof values.dataType === "string" ? convertProductDataTypeToDataType(values.dataType) : convertProductDataTypeToDataType(values.dataType.value),
                },
            })
                .unwrap()
                .then(() => {
                message.success(`Атрибут ${values.title} успешно создан`);
                navigate(links.handbookProductEditTypeLink.url(typeId));
            });
        }
    };
    const categories = useMemo(() => {
        var _a;
        return (_a = type === null || type === void 0 ? void 0 : type.productTypeCategories.map((item) => ({
            label: item.title,
            value: item.id,
        }))) !== null && _a !== void 0 ? _a : [];
    }, [type]);
    const initialValues = useMemo(() => ({
        productTypeCategoryId: categoryId ? +categoryId : undefined,
    }), [categoryId]);
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(ProductTypeAttrForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues, categories: categories }),
        React.createElement(Button, { onClick: handlerButtonClick }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
};
export default ProductTypeAttrFormAddContainer;
