import { Card, Col, Divider, Row, Typography, } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../../components/Page';
import productApi from '../../../redux/api/productApi';
import ProductEditFormContainer from '../../containers/ProductEditFormContainer';
const { Title } = Typography;
const { useFetchProductQuery } = productApi;
const ProductEditPage = () => {
    const { productId: productParam } = useParams();
    const productId = productParam ? +productParam : null;
    const { data } = useFetchProductQuery(productId, { skip: !Number.isInteger(productId) });
    if (data) {
        return (React.createElement(Page, null,
            React.createElement(Row, null,
                React.createElement(Col, { span: 12 },
                    React.createElement(Card, null,
                        React.createElement(Title, { level: 3 }, data === null || data === void 0 ? void 0 : data.titleCompiled),
                        React.createElement(Divider, null),
                        React.createElement(ProductEditFormContainer, { productId: data.id }))))));
    }
    return (React.createElement("div", null, "ERROR"));
};
export default ProductEditPage;
