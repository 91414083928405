import { InboxOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Spin } from "antd";
import React, { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import TreeView from "../../../../../../components/TreeView/TreeView";
import { useGetHandbookPackingSetListQuery } from "../../../../../../api/graphql/queries/handbookPacking/HandbookPackingQuery";
import { useHandbookPackingSetDeleteMutation } from "../../../../../../api/graphql/queries/handbookPacking/HandbookPackingMutations";
import { links } from "../../../../../../constants/links";
import Centered from "../../../../../../components/ui/Centered";
import TreeViewContectMenu from "../../../../../../components/TreeView/TreeViewContextMenu";
import MDIcon from "../../../../../../components/ui/MDIcon";
import { EMPTY_TABLE_MESSAGE, TITLE_PARAM, TYPE_ID_PARAM } from "../../../constants";
const buildTreeData = (packingItems) => {
    function convertToTreeData(packingCategories) {
        return packingCategories.map((category) => ({
            key: category.id.toString(),
            title: category.title || "Без названия",
            icon: React.createElement(InboxOutlined, null),
            isLeaf: true,
            children: [],
            id: category.id,
        }));
    }
    return convertToTreeData(packingItems);
};
const PackingTreeContainer = () => {
    var _a;
    const navigate = useNavigate();
    const { data, loading, refetch } = useGetHandbookPackingSetListQuery({ variables: { page: 1, pageSize: 1000 }, fetchPolicy: "cache-and-network" });
    const [deletePackingSet, { loading: isDeleteInProgress }] = useHandbookPackingSetDeleteMutation();
    const items = ((_a = data === null || data === void 0 ? void 0 : data.handbookPackingSetList) === null || _a === void 0 ? void 0 : _a.data) || [];
    const [searchParams, setSearchParams] = useSearchParams();
    const isLoading = loading || isDeleteInProgress;
    const [selectNode, setSelectNode] = useState(null);
    const treeData = useMemo(() => buildTreeData(items), [JSON.stringify(items)]);
    const handlerLoadItem = (id) => Promise.resolve();
    const handlerSelect = (node) => {
        setSelectNode((node === null || node === void 0 ? void 0 : node.id) ? node : null);
        if (node.isLeaf) {
            searchParams.set(TYPE_ID_PARAM, node.id.toString());
            searchParams.set(TITLE_PARAM, node.title);
            setSearchParams(searchParams);
        }
    };
    const handlerAdd = () => {
        navigate(links.handbookPackingSetAddLink.url());
    };
    const handlerEdit = (id) => {
        navigate(links.handbookPackingSetEditLink.url(id));
    };
    const handlerDelete = (id) => {
        if (id) {
            deletePackingSet({
                variables: {
                    input: {
                        handbookPackingSetId: id,
                    },
                },
            }).then(() => {
                refetch();
            });
        }
    };
    if (isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { spinning: true })));
    }
    const menu = TreeViewContectMenu({
        currentNode: selectNode,
        onAdd: handlerAdd,
        onEdit: handlerEdit,
        onDelete: handlerDelete,
        itemsName: "Упаковку",
        foldersName: null,
        disableMenuItem: false,
    });
    const contextMenu = (currentNode) => {
        const node = (currentNode === null || currentNode === void 0 ? void 0 : currentNode.id) ? currentNode : null;
        return TreeViewContectMenu({
            currentNode: node,
            onAdd: handlerAdd,
            onEdit: handlerEdit,
            onDelete: handlerDelete,
            itemsName: "Упаковку",
            foldersName: null,
            disableMenuItem: false,
        });
    };
    return (React.createElement("div", { className: "hide-switch-icon", style: {
            padding: 15,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 10,
        } },
        React.createElement(TreeView, { style: {
                flexGrow: 1,
                overflow: "auto",
                marginRight: -14,
                paddingRight: 14,
            }, defaultExpandedKeys: [""], onLoadFolder: handlerLoadItem, onSelect: handlerSelect, emptyText: React.createElement(Space, { direction: "vertical", align: "center" },
                React.createElement("div", null, EMPTY_TABLE_MESSAGE),
                React.createElement(Button, { type: "primary" }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C")), data: treeData, contexMenu: contextMenu }),
        React.createElement(Dropdown, { menu: menu, trigger: ["click"] },
            React.createElement(Button, { block: true, icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }) }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C"))));
};
export default PackingTreeContainer;
