import { NodeView } from '@antv/x6';
class MapNode extends NodeView {
    renderMarkup() {
        return this.renderJSONMarkup({
            tagName: 'rect',
            selector: 'body',
        });
    }
    update() {
        super.update({
            body: {
                refWidth: '100%',
                refHeight: '100%',
                fill: '#31d0c6',
            },
        });
    }
}
export default MapNode;
