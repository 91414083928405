var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useStorageCellTypeCreateMutation, useStorageCellTypeDeleteMutation, useStorageCellTypeEditMutation } from "../../../../api/graphql/queries/storageCell/StorageCellMutation";
import { useStorageCellTypeLazyQuery, useStorageCellTypeListQuery } from "../../../../api/graphql/queries/storageCell/StorageCellQuery";
export const useWarehouseCellType = ({ warehouseId, page, pageSize }) => {
    const { data, loading, error, refetch } = useStorageCellTypeListQuery({ variables: { warehouseId, page: page || 1, pageSize: pageSize || 50 }, fetchPolicy: "cache-and-network" });
    const [deleteStorageCellType, { loading: deleteStorageCellTypeLoading }] = useStorageCellTypeDeleteMutation();
    const [createStorageCellType, { loading: createStorageCellTypeLoading }] = useStorageCellTypeCreateMutation();
    const [editStorageCellType, { loading: editStorageCellTypeLoading }] = useStorageCellTypeEditMutation();
    const [getStorageCellType, getStorageCellTypeResponce] = useStorageCellTypeLazyQuery();
    const handleDelete = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteStorageCellType({ variables: { input } });
        yield refetch();
    });
    const handleCreate = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield createStorageCellType({ variables: { input } });
        yield refetch();
    });
    const handleEdit = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield editStorageCellType({ variables: { input } });
        yield refetch();
    });
    return {
        getStorageCellTypeList: {
            data,
            error,
            isLoading: loading,
            refetch,
        },
        deleteStorageCellType: {
            handleAction: handleDelete,
            isLoading: deleteStorageCellTypeLoading,
        },
        editStorageCellType: {
            handleAction: handleEdit,
            isLoading: editStorageCellTypeLoading,
        },
        createStorageCellType: {
            handleAction: handleCreate,
            isLoading: createStorageCellTypeLoading,
        },
        getStorageCellType: {
            handleAction: getStorageCellType,
            responce: getStorageCellTypeResponce,
        },
    };
};
export const adaptData = (storageCellTypeList) => {
    var _a;
    const adaptedData = [];
    for (let i = 0; i < storageCellTypeList.length; i++) {
        const cell = storageCellTypeList[i];
        adaptedData.push({
            id: cell.id,
            title: cell.title,
            cellWidth: cell.cellWidth,
            cellHeight: cell.cellHeight,
            cellDepth: cell.cellDepth,
            cellWeight: cell.cellWeight,
            description: (_a = cell.description) !== null && _a !== void 0 ? _a : undefined,
        });
    }
    return adaptedData;
};
export const adaptStorageCellTypeInput = (input) => {
    return Object.assign(Object.assign({}, input), { cellDepth: +input.cellDepth, cellHeight: +input.cellHeight, cellWeight: +input.cellWeight, cellWidth: +input.cellWidth });
};
