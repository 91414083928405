/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import handbookApi from '../../api/handbookApi';
import handbookCategoryApi from '../../api/handbookApi/handbookCategory';
const initialState = {
    categories: [],
    handbooks: [],
};
const setCategoryList = (state, payload) => {
    state.categories = [
        ...state.categories.map((category) => {
            const newCategory = payload.find((item) => item.id === category.id);
            return newCategory !== null && newCategory !== void 0 ? newCategory : category;
        }),
        ...payload.filter((ctegory) => (!state.categories.some((item) => item.id === ctegory.id))),
    ];
};
const setHandbookList = (state, payload) => {
    state.handbooks = [
        ...state.handbooks.map((handbook) => {
            const newCell = payload.find((item) => item.id === handbook.id);
            return newCell !== null && newCell !== void 0 ? newCell : handbook;
        }),
        ...payload.filter((handbook) => (!state.handbooks.some((item) => item.id === handbook.id))),
    ];
};
const handbookTreeSlice = createSlice({
    name: 'handbookTreeSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(handbookCategoryApi.endpoints.fetchCategoryList.matchFulfilled, (state, { payload }) => {
            setCategoryList(state, payload.list);
        })
            .addMatcher(handbookCategoryApi.endpoints.fetchCategory.matchFulfilled, (state, { payload }) => {
            setCategoryList(state, [payload]);
        })
            .addMatcher(handbookCategoryApi.endpoints.addCategory.matchFulfilled, (state, { payload }) => {
            setCategoryList(state, [payload]);
        })
            .addMatcher(handbookCategoryApi.endpoints.editCategory.matchFulfilled, (state, { payload }) => {
            setCategoryList(state, [payload]);
        })
            .addMatcher(handbookCategoryApi.endpoints.deleteCategory.matchFulfilled, (state, { payload }) => {
            state.categories = state.categories.filter((category) => category.id !== payload);
        })
            .addMatcher(handbookApi.endpoints.fetchHandbookList.matchFulfilled, (state, { payload }) => {
            setHandbookList(state, payload.list);
        })
            .addMatcher(handbookApi.endpoints.fetchHandbook.matchFulfilled, (state, { payload }) => {
            setHandbookList(state, [payload]);
        })
            .addMatcher(handbookApi.endpoints.addHandbook.matchFulfilled, (state, { payload }) => {
            setHandbookList(state, [payload]);
        })
            .addMatcher(handbookApi.endpoints.editHandbook.matchFulfilled, (state, { payload }) => {
            setHandbookList(state, [payload]);
        })
            .addMatcher(handbookApi.endpoints.deleteHandbook.matchFulfilled, (state, { payload }) => {
            state.handbooks = state.handbooks.filter((handbook) => handbook.id !== payload);
        });
    },
});
export const handbookTreeActions = handbookTreeSlice.actions;
export const selectHandbookTree = (state) => state.handbookTree;
const handbookTreeReducer = handbookTreeSlice.reducer;
export default handbookTreeReducer;
