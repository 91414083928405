import { Shape } from "@antv/x6/es";
import { SCALE, defaultTools } from "../hooks/state";
const standardAttrs = {
    body: {
        stroke: "none",
    },
    label: {
        fill: "#000",
    },
};
function createShape(nodeInfo, shape) {
    var _a, _b, _c;
    const baseConfig = {
        data: { id: nodeInfo.id },
        attrs: Object.assign(Object.assign({}, standardAttrs), { label: { text: ((_a = nodeInfo.title) === null || _a === void 0 ? void 0 : _a.toString()) || "" } }),
        tools: [...defaultTools],
        zIndex: 10,
    };
    if (!shape) {
        return new Shape.Rect(Object.assign(Object.assign({}, baseConfig), { width: ((_b = nodeInfo === null || nodeInfo === void 0 ? void 0 : nodeInfo.storageCellType) === null || _b === void 0 ? void 0 : _b.cellWidth) * SCALE || 10, height: ((_c = nodeInfo === null || nodeInfo === void 0 ? void 0 : nodeInfo.storageCellType) === null || _c === void 0 ? void 0 : _c.cellHeight) * SCALE || 10 }));
    }
    switch (shape.__typename) {
        case "Circle":
            return new Shape.Circle(Object.assign(Object.assign({}, baseConfig), { x: shape.cx - shape.r, y: shape.cy - shape.r, width: shape.r * 2, height: shape.r * 2, attrs: Object.assign(Object.assign({}, baseConfig.attrs), { circle: { r: shape.r } }) }));
        case "Rect":
            return new Shape.Rect(Object.assign(Object.assign({}, baseConfig), { x: shape.x, y: shape.y, width: shape.width * SCALE, height: shape.height * SCALE, attrs: Object.assign(Object.assign({}, baseConfig.attrs), { body: Object.assign(Object.assign({}, standardAttrs.body), { rx: shape.rx, ry: shape.ry }) }) }));
        case "Ellipse":
            return new Shape.Ellipse(Object.assign(Object.assign({}, baseConfig), { x: shape.cx - shape.rx, y: shape.cy - shape.ry, width: shape.rx * 2 * SCALE, height: shape.ry * 2 * SCALE, attrs: Object.assign(Object.assign({}, baseConfig.attrs), { ellipse: { rx: shape.rx, ry: shape.ry } }) }));
        case "Line":
            return new Shape.Edge(Object.assign(Object.assign({}, baseConfig), { source: { x: shape.x1, y: shape.y1 }, target: { x: shape.x2, y: shape.y2 }, attrs: Object.assign(Object.assign({}, baseConfig.attrs), { line: { stroke: "#000" } }) }));
        case "Polygon":
        case "Polyline":
            return new Shape.Path(Object.assign(Object.assign({}, baseConfig), { attrs: Object.assign(Object.assign({}, baseConfig.attrs), { body: Object.assign(Object.assign({}, standardAttrs.body), { d: `M ${shape.points} ${shape.__typename === "Polygon" ? "Z" : ""}` }) }) }));
        case "Path":
            return new Shape.Path(Object.assign(Object.assign({}, baseConfig), { attrs: Object.assign(Object.assign({}, baseConfig.attrs), { body: Object.assign(Object.assign({}, standardAttrs.body), { d: shape.d }) }) }));
        default:
            return new Shape.Rect(Object.assign(Object.assign({}, baseConfig), { width: 50, height: 50 }));
    }
}
export function createNodeFromVectorObject(nodeInfo) {
    var _a;
    const shape = (_a = nodeInfo.vectorObject) === null || _a === void 0 ? void 0 : _a.shape;
    return createShape(nodeInfo, shape);
}
