import React from 'react';
import Page from '../../../../components/Page';
import ObjectClass from '../../../../constants/enum/ObjectClass';
import { useTaskCorrection } from '../../../../hooks/api/taskCorrectionApi/useTaskCorrection';
import CorrectionTaskListContainer from '../../../containers/task/correction/CorrectionTaskListContainer';
import CorrectionTaskListFilterContainer from '../../../containers/task/correction/CorrectionTaskListFilterContainer';
import CorrectionTaskListHeaderContainer from '../../../containers/task/correction/CorrectionTaskListHeaderContainer';
import CorrectionTaskPaginationContainer from '../../../containers/task/correction/CorrectionTaskPaginationContainer';
const CorrectionListPage = () => {
    const { taskList } = useTaskCorrection();
    const props = {
        toolbarBottom: React.createElement(CorrectionTaskPaginationContainer, { pagination: taskList.pagination, isLoading: taskList.loading, data: taskList.data }),
        toolbarTop: React.createElement(CorrectionTaskListHeaderContainer, { data: taskList === null || taskList === void 0 ? void 0 : taskList.data, isLoading: taskList.loading, pagination: taskList === null || taskList === void 0 ? void 0 : taskList.pagination }),
        toolbarRight: React.createElement(CorrectionTaskListFilterContainer, { isLoading: taskList.loading, objectClass: ObjectClass.TaskCorrection, fetchList: taskList.paginationFilteredGetTaskList }),
    };
    return (React.createElement(Page, Object.assign({}, props),
        React.createElement(CorrectionTaskListContainer, { pagination: taskList.pagination, taskList: taskList.data, isLoading: taskList.loading })));
};
export default CorrectionListPage;
