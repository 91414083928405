import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { links } from "../../../../constants/links";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import { useTaskCorrection } from "../../../../hooks/api/taskCorrectionApi/useTaskCorrection";
import { CorrectionDetailNomenclatureFormItemContextContainer } from "../../../conponents/task/correction/CorrectionDetailNomenclatureForm/CorrectionDetailNomenclatureFormItemContainer";
import CommentsContainer from "../../../containers/CommentsContainer";
import ProductInfoDrawerContainer from "../../../containers/ProductInfoDrawerContainer";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import CorrectionStatusHistoryContainer from "../../../containers/task/correction/CorrectionStatusHistoryContainer";
import CorrectionTaskContainer from "../../../containers/task/correction/CorrectionTaskContainer";
import CorrectionTaskHeaderContainer from "../../../containers/task/correction/CorrectionTaskHeaderContainer";
import CorrectionTaskNomenclatureContainer from "../../../containers/task/correction/CorrectionTaskNomenclatureContainer";
const CorrectionTaskPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const objectInfo = {
        objectClass: ObjectClass.TaskCorrection,
        objectId: taskId,
    };
    const { task } = useTaskCorrection();
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const isLocked = useMemo(() => taskHelpers.hasExternalId(task === null || task === void 0 ? void 0 : task.data), [task]);
    const handlerChangeTask = () => navigate(links.taskCorrectionLinks.edit.url(taskId));
    const pageProps = {
        toolbarTop: React.createElement(CorrectionTaskHeaderContainer, { changeStatus: task.changeStatus, isLoading: task.loading, task: task.data, id: taskId, onChangeTask: handlerChangeTask, refetch: task.refetch }),
        toolbarRight: React.createElement(CommentsContainer, Object.assign({}, objectInfo)),
    };
    const containerProps = {
        id: taskId,
        nomenclatureTab: (React.createElement(CorrectionTaskNomenclatureContainer, { isLoading: task === null || task === void 0 ? void 0 : task.loading, task: task === null || task === void 0 ? void 0 : task.data }, (detailProps) => React.createElement(CorrectionDetailNomenclatureFormItemContextContainer, Object.assign({ isLocked: isLocked }, detailProps, { isEdit: false })))),
        historyTab: React.createElement(CorrectionStatusHistoryContainer, { taskId: taskId }),
    };
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(CorrectionTaskContainer, Object.assign({ task: task.data, isLoading: task.loading }, containerProps, containerProps)),
        React.createElement(ProductInfoDrawerContainer, null),
        React.createElement(SkuInfoDrawerContainer, null)));
};
export default CorrectionTaskPage;
