import React from 'react';
import { Table } from 'antd';
import { getColumnType } from '../../helpers/function';
const ClientsTable = (props) => {
    const { items, loading } = props;
    const columns = [
        getColumnType('companyName', 'Контрагент'),
        getColumnType('companyInn', 'ИНН'),
        getColumnType('companyOgrn', 'ОГРН'),
    ];
    const data = items.map((item) => ({
        key: item.id,
        id: item.id,
        companyName: item.companyName,
        companyInn: item.companyInn,
        companyOgrn: item.companyOgrn,
    }));
    return (React.createElement(Table, { bordered: true, loading: loading, columns: columns, dataSource: data, pagination: false }));
};
export default ClientsTable;
