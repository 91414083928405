import React, { useEffect, useRef, useState } from 'react';
import CommentsEditor from './CommentsEditor';
import CommentsList from './CommentsList';
import CommentsItem from './CommentsItem';
import AppFragment from '../Fragment';
const UPDATE_TIMEOUT = 5 * 1000;
const Comments = (props) => {
    const { comments, isLoading, loadSuccess, onAddComment, refetch, } = props;
    const [value, setValue] = useState('');
    const anchorRef = useRef(null);
    let updateTimerId;
    const scrollToBottom = () => {
        var _a;
        (_a = anchorRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
    };
    useEffect(() => {
        scrollToBottom();
        updateTimerId = window.setInterval(refetch, UPDATE_TIMEOUT);
        return () => {
            window.clearInterval(updateTimerId);
        };
    }, []);
    useEffect(() => {
        if (loadSuccess) {
            scrollToBottom();
        }
    }, [loadSuccess, comments]);
    const handleChange = (e) => setValue(e.target.value);
    const handleSubmit = () => {
        if (!value)
            return;
        onAddComment(value)
            .then(() => {
            setValue('');
            scrollToBottom();
            refetch();
        });
    };
    return (React.createElement(AppFragment, { title: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0438", buttons: (React.createElement(Comments.Editor, { onChange: handleChange, onSubmit: handleSubmit, submitting: isLoading, value: value })) },
        React.createElement(Comments.List, { comments: comments, loading: isLoading && comments.length === 0 }),
        React.createElement("div", { ref: anchorRef })));
};
Comments.Editor = CommentsEditor;
Comments.List = CommentsList;
Comments.Item = CommentsItem;
export default Comments;
