import React from 'react';
import { Empty, Typography } from 'antd';
import { uniqueId } from 'lodash';
const { Title, Text } = Typography;
const ShippingDetailNomenclature = (props) => {
    const { details, editable, warehouseId, isLoading, children, onDeleteItem, refetch, onInfoClick, onSkuInfoClick, } = props;
    const handlerDelete = (detailsId) => {
        if (onDeleteItem)
            onDeleteItem(detailsId);
    };
    const handlerOpenInfo = (id) => {
        if (onInfoClick)
            onInfoClick(id);
    };
    const handlerOpenSkuInfo = (id) => {
        if (onSkuInfoClick)
            onSkuInfoClick(id);
    };
    if (details.length === 0) {
        return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: (React.createElement(React.Fragment, null,
                React.createElement(Title, { level: 5 }, "\u0417\u0430\u0434\u0430\u0447\u0430 \u043D\u0435 \u043D\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0430."),
                React.createElement(Text, null, "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440\u0443."))) }));
    }
    return (React.createElement(React.Fragment, null, details.map((nomenclature) => children({
        key: uniqueId(),
        nomenclature,
        editable,
        refetch,
        warehouseId,
        onDeleteItem: handlerDelete,
        onInfoClick: handlerOpenInfo,
        onSkuInfoClick: handlerOpenSkuInfo,
        isLoading
    }))));
};
export default ShippingDetailNomenclature;
