import { Button, Card, Col, Divider, Row, Space, Spin, Typography } from "antd";
import React, { useState } from "react";
import SkuSearchModalWrapper from "../../../../components/nomenclatureSearch/sku/SkuSearchModal/SkuSearchModalWrapper";
import MDIcon from "../../../../components/ui/MDIcon";
import MovementDetailNomenclature from "./MovementDetailNomenclature/MovementDetailNomenclature";
import MovementDetailNomenclatureItem from "./MovementDetailNomenclature/MovementDetailNomenclatureItem";
const MovementTaskFormNomenclature = (props) => {
    const { editable, warehouseId, details, isLoading, onAddSku, onDeleteSku, onEditSku, onSkuInfoClick } = props;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => {
        setModalIsOpen(true);
    };
    const handlerAdd = (values) => {
        onAddSku(values.map((value) => ({ skuId: value })));
    };
    const handlerEdit = (value) => {
        onEditSku([value]);
    };
    const handlerOpenSkuInfo = (id) => {
        onSkuInfoClick(id);
    };
    const renderDetails = () => (React.createElement(MovementDetailNomenclature, { details: details },
        React.createElement(MovementDetailNomenclatureItem, { editable: editable, warehouseId: warehouseId || -1, details: details, onDeleteItem: onDeleteSku, onEditItem: handlerEdit, onSkuInfoClick: handlerOpenSkuInfo })));
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(Card, null,
            React.createElement(Space, { direction: "vertical", className: "task-loading-change__nomenclature mt-3" },
                React.createElement("div", null,
                    React.createElement(Row, null,
                        React.createElement(Col, { flex: "auto" },
                            React.createElement(Typography.Title, { level: 3, style: { marginBottom: 0 } }, "\u041D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440\u0430")),
                        React.createElement(Col, { flex: "none" }, editable ? (React.createElement(Button.Group, null,
                            React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "plus-circle", className: "anticon" }), onClick: openModal }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C sku"))) : null)),
                    React.createElement(Divider, null)),
                renderDetails())),
        warehouseId && React.createElement(SkuSearchModalWrapper, { warehouseId: [`${warehouseId}`], filterIncludePersonal: true, onAdd: handlerAdd, visible: modalIsOpen, setModelVisible: setModalIsOpen })));
};
export default MovementTaskFormNomenclature;
