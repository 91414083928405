import React, { useMemo } from 'react';
import { Button, Dropdown } from 'antd';
import MDIcon from '../ui/MDIcon';
const PrintActionsButton = (props) => {
    const { printActions, onItemClick } = props;
    const documentsDropdownMenu = {
        items: printActions === null || printActions === void 0 ? void 0 : printActions.map(item => {
            return {
                key: item.id,
                label: item.title,
                onClick: () => onItemClick(item.id)
            };
        })
    };
    const menu = useMemo(() => documentsDropdownMenu, [printActions]);
    if (printActions === null || printActions === void 0 ? void 0 : printActions.length) {
        return (React.createElement(Dropdown, { menu: menu, trigger: ['click'] },
            React.createElement(Button, null,
                React.createElement(MDIcon, { icon: "printer" }))));
    }
    return null;
};
export default PrintActionsButton;
