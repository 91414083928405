import React, { useState } from "react";
import QRCode from "react-qr-code";
import { Card, List } from "antd";
const ServiceApplication = (props) => {
    var _a;
    const { application } = props;
    const [activeTabKey, setActiveTabKey] = useState('description');
    const onTabChange = (key) => {
        setActiveTabKey(key);
    };
    const tabList = [
        {
            key: 'description',
            label: 'Описание',
        },
        {
            key: 'settings',
            label: 'Настройки',
        }
    ];
    const tabContentList = {
        description: React.createElement(List, { header: application.description, dataSource: application.urls, renderItem: (url) => React.createElement(List.Item, null,
                React.createElement("a", { href: url.uri, target: '_blank' }, url.title)) }),
        settings: React.createElement(List, { header: React.createElement("div", null,
                React.createElement("div", { style: { marginBottom: '15px' } }, "\u0414\u043B\u044F \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u044F, \u043E\u0442\u0441\u043A\u0430\u043D\u0438\u0440\u0443\u0435\u0439\u0442\u0435 QR \u043A\u043E\u0434"),
                React.createElement(QRCode, { size: 320, viewBox: '0 0 320 320', style: { height: "auto", maxWidth: "100%", width: "100%" }, value: (_a = application.settings) !== null && _a !== void 0 ? _a : '' })), dataSource: [application.settingsAsUrl], renderItem: (url) => React.createElement(List.Item, null, url ? React.createElement("a", { href: url, target: '_blank' }, "\u0418\u043B\u0438 \u043D\u0430\u0436\u043C\u0438\u0442\u0435 \u043D\u0430 \u0441\u0441\u044B\u043B\u043A\u0443 \u043D\u0430 \u0443\u0441\u0442\u0440\u043E\u0439\u0441\u0442\u0432\u0435") : '') })
    };
    return (React.createElement(Card, { title: application.title, size: "small", type: 'inner', tabList: tabList, tabProps: {
            size: 'small',
        }, activeTabKey: activeTabKey, onTabChange: onTabChange }, tabContentList[activeTabKey]));
};
export default ServiceApplication;
