var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Space, Table, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import MDIcon from "../../../../components/ui/MDIcon";
import { ToleranceType } from "../../../../constants/enum/ToleranceType";
import { getColumnType } from "../../../../helpers/function";
import PartitionDetailNomenclatureCell from "./PartitionDetailNomenclatureCell";
import PartitionDetailTaskNomenclatureActions from "./PartitionDetailTaskNomenclatureActions";
const { confirm } = Modal;
const { Title, Text } = Typography;
const Cell = (props) => {
    const { children, isHeader, style: propsStyle } = props;
    const style = {};
    if (isHeader) {
        style.backgroundColor = "#fafafa";
        style.fontWeight = 500;
        return (React.createElement("td", Object.assign({}, props, { style: Object.assign(Object.assign({}, propsStyle), style) }), children));
    }
    return React.createElement(PartitionDetailNomenclatureCell, Object.assign({}, props));
};
const labeledValueRender = (value) => {
    if (value && value.label) {
        return value.label;
    }
    return value;
};
const PartitionDetailNomenclatureItem = (props) => {
    const { nomenclature, isLoading, produceWork, warehouseId, onEditOriginal, onEditCreated, onInfoClick, onSkuInfoClick } = props;
    const { attributes, type, title, storageCell, id, quantityFinal, quantityInitial, productId, created, skuId } = nomenclature;
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record) => record.id === editingKey;
    const showEditConfirm = (callback) => {
        confirm({
            title: `Изменения в товарной позиции #${editingKey} не сохранены`,
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: `Вы не сохранили изменения в товарной позиции #${editingKey}. Отменить все изменения?`,
            okText: "Да",
            okType: "danger",
            cancelText: "Нет",
            onOk() {
                callback();
            },
        });
    };
    const handlerCancel = () => {
        setEditingKey("");
    };
    const handlerOpenInfo = () => {
        if (onInfoClick)
            onInfoClick(productId);
    };
    const handlerSaveCreated = (createdId) => __awaiter(void 0, void 0, void 0, function* () {
        const row = yield form.validateFields();
        const { storageCell: formStorageCell, quantityFinal: formQuantityFinal } = row, attributeValues = __rest(row, ["storageCell", "quantityFinal"]);
        const createdItem = created === null || created === void 0 ? void 0 : created.find((item) => item.id === createdId);
        const entries = Object.entries(attributeValues);
        let resultAttributes = [
            ...entries.map((el) => {
                var _a;
                return ({
                    skuAttributeValueFinal: `${el[1]}`,
                    taskPartitionCreatedAttributeId: (_a = createdItem === null || createdItem === void 0 ? void 0 : createdItem.attributes.find((attribute) => attribute.taskPartitionOriginalAttributeId === +el[0])) === null || _a === void 0 ? void 0 : _a.id,
                });
            }),
        ];
        const result = {
            createdId,
            storageCellId: formStorageCell.value,
            quantityFinal: Number(formQuantityFinal),
            attributes: resultAttributes,
        };
        onEditCreated(result);
        handlerCancel();
    });
    const handlerSaveOrigin = (originalId) => __awaiter(void 0, void 0, void 0, function* () {
        const row = yield form.validateFields();
        const { storageCell: formStorageCell } = row;
        onEditOriginal({
            originalId,
            storageCellId: formStorageCell.value,
        });
        handlerCancel();
    });
    const handlerSave = (editingId, isCreated) => __awaiter(void 0, void 0, void 0, function* () {
        if (isCreated) {
            handlerSaveCreated(editingId);
        }
        else {
            handlerSaveOrigin(editingId);
        }
    });
    const handlerEdit = (currentEditingKey, record) => {
        if (currentEditingKey) {
            showEditConfirm(() => {
                handlerCancel();
                handlerEdit("", record);
            });
        }
        else {
            form.setFieldsValue(Object.assign({ storageCell: record.storageCell, tolerancePercent: record.tolerancePercent, quantityFinal: record.quantityFinal }, attributes === null || attributes === void 0 ? void 0 : attributes.reduce((acc, attr) => (Object.assign(Object.assign({}, acc), { [attr.id]: record[attr.id] })), {})));
            setEditingKey(record.id);
        }
    };
    const handlerOpenSkuInfo = (skuInfoId) => {
        if (onSkuInfoClick)
            onSkuInfoClick(skuInfoId);
    };
    const onCell = (record) => ({
        isHeader: record.isHeader,
        title: record.id.toString(),
    });
    const columns = [
        getColumnType("id", "#", true, {
            fixed: "left",
            width: "80px",
            onCell,
        }),
        ...((attributes === null || attributes === void 0 ? void 0 : attributes.map((attr) => {
            const { id: attrId, title: attrTitle } = attr;
            return {
                title: attrTitle,
                children: [
                    getColumnType(`${attrId}-initial`, "до", true, { width: "100px", onCell }),
                    getColumnType(attrId, "после", true, {
                        width: "100px",
                        onCell: (record) => (Object.assign(Object.assign({}, onCell(record)), { record, value: record[attrId], dataIndex: attrId, editing: isEditing(record) })),
                    }),
                ],
            };
        })) || []),
        getColumnType("", "", false, { onCell }),
        getColumnType("storageCell", "Карман", false, {
            width: "100px",
            render: labeledValueRender,
            onCell: (record) => (Object.assign(Object.assign({}, onCell(record)), { record,
                warehouseId, value: record.storageCell, dataIndex: "storageCell", editing: isEditing(record) })),
        }),
        {
            title: "Кол-во",
            children: [
                getColumnType("quantityInitial", "до", false, { width: "100px", onCell }),
                getColumnType("quantityFinal", "после", false, {
                    width: "100px",
                    onCell: (record) => (Object.assign(Object.assign({}, onCell(record)), { record, value: record.quantityFinal, dataIndex: "quantityFinal", editing: isEditing(record) })),
                }),
            ],
        },
        {
            title: "Толеранс",
            children: [
                getColumnType("toleranceType", "Тип", false, {
                    width: "1%",
                    onCell,
                    render: (value) => {
                        switch (value) {
                            case ToleranceType.POSITIVE: {
                                return React.createElement(MDIcon, { icon: "plus-circle-outline", color: "#52c41a", style: { fontSize: 24, lineHeight: "24px" } });
                            }
                            case ToleranceType.NEGATIVE: {
                                return React.createElement(MDIcon, { icon: "minus-circle-outline", color: "#ff4d4f", style: { fontSize: 24, lineHeight: "24px" } });
                            }
                            default: {
                                return "";
                            }
                        }
                    },
                }),
                getColumnType("tolerancePercent", "%", false, { width: "1%", onCell }),
            ],
        },
    ];
    columns.push(getColumnType("actions", "", false, {
        width: "1%",
        onCell,
        render: (value, record) => {
            if (produceWork) {
                if (!record.isHeader) {
                    const isEditable = isEditing(record);
                    return (React.createElement(PartitionDetailTaskNomenclatureActions, { isLoading: isLoading, id: record.key, isCreated: !!record.isCreated, isEditable: isEditable, onEdit: () => handlerEdit(editingKey, record), onCancel: handlerCancel, onSave: (isCreated) => handlerSave(record.key, isCreated), onSkuInfoClick: () => handlerOpenSkuInfo(record.id.toString()) }));
                }
                return "";
            }
            if (!record.isHeader) {
                return (React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                    React.createElement(Button, { type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), size: "small", onClick: () => handlerOpenSkuInfo(record.id.toString()) })));
            }
            return "";
        },
    }));
    const dataOriginal = [
        Object.assign({ key: id, id: skuId, productId,
            quantityInitial,
            quantityFinal, storageCell: {
                value: storageCell === null || storageCell === void 0 ? void 0 : storageCell.id,
                label: storageCell === null || storageCell === void 0 ? void 0 : storageCell.title,
            } }, attributes === null || attributes === void 0 ? void 0 : attributes.map((attr) => {
            const { id: attrId, skuAttributeValueInitial, skuAttributeValueFinal } = attr;
            return {
                [`${attrId}-initial`]: skuAttributeValueInitial,
                [attrId]: skuAttributeValueFinal,
            };
        }).reduce((acc, attrValues) => (Object.assign(Object.assign({}, acc), attrValues)), {})),
    ];
    const dataCreated = (created === null || created === void 0 ? void 0 : created.map((createdItem) => {
        var _a, _b;
        return (Object.assign({ key: createdItem.id, id: createdItem.skuId, productId, quantityInitial: createdItem.quantityRequired, quantityFinal: createdItem.quantityFinal, storageCell: {
                value: (_a = createdItem === null || createdItem === void 0 ? void 0 : createdItem.storageCell) === null || _a === void 0 ? void 0 : _a.id,
                label: (_b = createdItem === null || createdItem === void 0 ? void 0 : createdItem.storageCell) === null || _b === void 0 ? void 0 : _b.title,
            }, toleranceType: createdItem.toleranceType, tolerancePercent: createdItem.tolerancePercent, isCreated: true }, createdItem === null || createdItem === void 0 ? void 0 : createdItem.attributes.map((el) => ({ [`${el.taskPartitionOriginalAttributeId}-initial`]: el.skuAttributeValueRequired, [el.taskPartitionOriginalAttributeId]: el.skuAttributeValueFinal })).reduce((acc, item) => (Object.assign(Object.assign({}, acc), item)), {})));
    })) || [];
    const dataCreatedHeader = [
        Object.assign({ key: "title", id: "#", productId: "", quantityInitial: "треб.", quantityFinal: "факт.", storageCell: "Карман", toleranceType: "Тип", tolerancePercent: "%", isHeader: true }, attributes === null || attributes === void 0 ? void 0 : attributes.map((attr) => {
            const { id: attrId } = attr;
            return {
                [`${attrId}-initial`]: "треб.",
                [attrId]: "факт.",
            };
        }).reduce((acc, attrValues) => (Object.assign(Object.assign({}, acc), attrValues)), {})),
    ];
    const getData = () => {
        if (created && (created === null || created === void 0 ? void 0 : created.length) > 0) {
            return [...dataOriginal, ...dataCreatedHeader, ...dataCreated];
        }
        return dataOriginal;
    };
    return (React.createElement("div", { className: "mb-5" },
        React.createElement(Row, null,
            React.createElement(Col, { flex: "auto" },
                React.createElement(Space, { style: { width: "auto" } },
                    React.createElement(Title, { level: 4 }, type),
                    React.createElement("div", { className: "mb-2" },
                        React.createElement(Text, { underline: true }, title)))),
            React.createElement(Col, null,
                React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u0442\u0438\u043F\u0435 \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                    React.createElement(Button, { type: "primary", size: "small", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: handlerOpenInfo })))),
        React.createElement(Form, { form: form, component: false },
            React.createElement(Table, { style: { whiteSpace: "nowrap" }, components: {
                    body: {
                        cell: Cell,
                    },
                }, columns: columns, dataSource: getData(), bordered: true, pagination: false, size: "small", scroll: { x: true } }))));
};
export default PartitionDetailNomenclatureItem;
