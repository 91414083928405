import { Button, Row, Typography } from "antd";
import React from "react";
import MDIcon from "../../../../../../../components/ui/MDIcon";
import { ADD_PACKING_BUTTON_TEXT } from "../../../../constants";
const PackingListHeader = (props) => {
    const { title, onAddClick, showActions = true } = props;
    return (React.createElement(Row, { justify: "space-between" },
        React.createElement(Typography.Title, { className: "m-0", level: 5 }, title),
        showActions ? (React.createElement(Button, { type: "primary", onClick: onAddClick, icon: React.createElement(MDIcon, { icon: "plus", style: { marginRight: 5 } }) }, ADD_PACKING_BUTTON_TEXT)) : null));
};
export default PackingListHeader;
