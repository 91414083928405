var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Card, DatePicker, Form, Spin, } from 'antd';
import React, { Component } from 'react';
import moment from 'moment';
import { TaskFilterFields } from './types';
import { getStatusList } from '../../../api/rest/requests/status';
import LoadingState from '../../../constants/enum/LoadingState';
import WarehouseSelect from '../../WarehouseSelect';
import StatusSelect from '../../StatusSelect';
import AppFragment from '../../Fragment';
import { getWarehouseList } from '../../../api/rest/requests/warehouse/warehouse';
const { RangePicker } = DatePicker;
const FORM_ID = 'filter';
const dateFormat = 'YYYY-MM-DD';
class TaskListFilter extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this._isMounted = false;
        this.loadDefaultStatusList = () => __awaiter(this, void 0, void 0, function* () {
            const { initialFormValues } = this.props;
            const initialStatusList = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues[TaskFilterFields.Status];
            const statusResponse = yield this.statusRequest(1, 100, '', () => { });
            const statusList = statusResponse.list
                .filter((item) => {
                if (initialStatusList && initialStatusList.length) {
                    return initialStatusList.includes(item.id);
                }
                return item.isDefaultForFilter;
            })
                .map((item) => ({ value: item.id, label: item.title }));
            return statusList;
        });
        // eslint-disable-next-line class-methods-use-this
        this.loadDefaultWarehouseList = () => __awaiter(this, void 0, void 0, function* () {
            const { initialFormValues } = this.props;
            const initialWarehouseList = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues[TaskFilterFields.Warehouse];
            const warehouseResponse = yield getWarehouseList('1', '100');
            const warehouseList = warehouseResponse.list
                .filter((item) => {
                if (initialWarehouseList && initialWarehouseList.length) {
                    return initialWarehouseList.includes(item.id);
                }
                return true;
            })
                .map((item) => ({ value: item.id, label: item.storageTitle }));
            return warehouseList;
        });
        this.handlerSubmit = (values) => {
            var _a, _b;
            const { onSubmit } = this.props;
            const dateField = values[TaskFilterFields.Date];
            const result = {
                [TaskFilterFields.Warehouse]: values[TaskFilterFields.Warehouse].map((item) => Number(item.value)),
                [TaskFilterFields.Status]: values[TaskFilterFields.Status].map((item) => Number(item.value)),
            };
            if (dateField) {
                result[TaskFilterFields.DateFrom] = (_a = dateField[0]) === null || _a === void 0 ? void 0 : _a.format(dateFormat);
                result[TaskFilterFields.DateTo] = (_b = dateField[1]) === null || _b === void 0 ? void 0 : _b.format(dateFormat);
            }
            onSubmit(result);
        };
        this.handlerStatusRequest = (page, pageSize, query, setLoadingState) => {
            const { objectClass } = this.props;
            return getStatusList(objectClass, page.toString(), pageSize.toString(), query, setLoadingState);
        };
        this.statusRequest = (page, pageSize, query, setLoadingState) => __awaiter(this, void 0, void 0, function* () {
            return (this.handlerStatusRequest(page, pageSize, query, setLoadingState));
        });
        this.handlerReset = () => {
            var _a;
            (_a = this.form.current) === null || _a === void 0 ? void 0 : _a.resetFields();
        };
        this.renderForm = () => {
            const { defaultStatusList, defaultWarehouseList } = this.state;
            const { objectClass } = this.props;
            const initialValues = {
                [TaskFilterFields.Warehouse]: defaultWarehouseList,
                [TaskFilterFields.Status]: defaultStatusList,
                [TaskFilterFields.Date]: this.getInitialDate(),
            };
            return (React.createElement(Form, { ref: this.form, id: FORM_ID, layout: "vertical", initialValues: initialValues, onFinish: this.handlerSubmit },
                React.createElement(Form.Item, { label: "\u0421\u043A\u043B\u0430\u0434", name: TaskFilterFields.Warehouse, rules: [{ required: true }] },
                    React.createElement(WarehouseSelect, { mode: "multiple", placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434\u044B" })),
                React.createElement(Form.Item, { label: "\u0421\u0442\u0430\u0442\u0443\u0441", name: TaskFilterFields.Status, rules: [{ required: true }] },
                    React.createElement(StatusSelect, { objectClass: objectClass, mode: "multiple", placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u0442\u0430\u0442\u0443\u0441\u044B" })),
                React.createElement(Form.Item, { label: "\u0414\u0430\u0442\u0430", name: TaskFilterFields.Date },
                    React.createElement(RangePicker, null))));
        };
        this.state = {
            defaultStatusList: [],
            defaultWarehouseList: [],
            loadingState: LoadingState.Loading,
        };
    }
    componentDidMount() {
        this._isMounted = true;
        Promise.all([
            this.loadDefaultStatusList(),
            this.loadDefaultWarehouseList(),
        ]).then(([statusList, warehouseList]) => {
            if (this._isMounted) {
                this.setState({
                    loadingState: LoadingState.Ready,
                    defaultWarehouseList: warehouseList,
                    defaultStatusList: statusList,
                });
                this.handlerSubmit({
                    [TaskFilterFields.Warehouse]: warehouseList,
                    [TaskFilterFields.Status]: statusList,
                    [TaskFilterFields.Date]: this.getInitialDate(),
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps, prevState) {
        var _a;
        const { defaultStatusList, defaultWarehouseList } = this.state;
        if (prevState.defaultStatusList !== defaultStatusList
            || prevState.defaultWarehouseList !== defaultWarehouseList) {
            (_a = this.form.current) === null || _a === void 0 ? void 0 : _a.resetFields();
        }
    }
    getInitialDate() {
        const { initialFormValues } = this.props;
        const initialDateFrom = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues[TaskFilterFields.DateFrom];
        const initialDateTo = initialFormValues === null || initialFormValues === void 0 ? void 0 : initialFormValues[TaskFilterFields.DateTo];
        const initialDate = [undefined, undefined];
        if (initialDateFrom)
            initialDate[0] = moment(initialDateFrom, dateFormat);
        if (initialDateTo)
            initialDate[1] = moment(initialDateTo, dateFormat);
        return initialDate;
    }
    render() {
        const { buttonsLoading } = this.props;
        const { loadingState } = this.state;
        return (React.createElement(AppFragment, { title: "\u0424\u0438\u043B\u044C\u0442\u0440 \u0437\u0430\u0434\u0430\u0447", buttons: (React.createElement(Button.Group, { style: { width: '100%' } },
                React.createElement(Button, { block: true, loading: buttonsLoading, onClick: this.handlerReset }, "\u0421\u0431\u0440\u043E\u0441"),
                React.createElement(Button, { block: true, type: "primary", htmlType: "submit", form: FORM_ID, loading: buttonsLoading }, "\u0424\u0438\u043B\u044C\u0442\u0440"))) },
            React.createElement(Card, { type: "inner", size: "small" },
                React.createElement(Spin, { spinning: loadingState === LoadingState.Loading }, this.renderForm()))));
    }
}
export default TaskListFilter;
