import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { from } from "@apollo/client/link/core";
import { onError } from "@apollo/client/link/error";
import { message as popUp, notification } from "antd";
import { AuthService } from "../services/AuthService";
import React from "react";
const httpLink = createHttpLink({
    uri: `${window.env.ApiHost}/graphql`,
});
const authLink = setContext((_, { headers }) => {
    const token = AuthService.getAccessTokenFromStorage();
    return {
        headers: Object.assign(Object.assign({}, headers), { authorization: token ? `Bearer ${token}` : "" }),
    };
});
const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, extensions }) => {
            if (extensions.hasOwnProperty("details")) {
                notification.error({
                    message: message,
                    description: React.createElement("div", { dangerouslySetInnerHTML: { __html: extensions.details } }),
                    placement: "top"
                });
            }
            else {
                popUp.error(message);
            }
        });
    }
});
export const gqlClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
});
