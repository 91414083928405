var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Card, Col, DatePicker, Divider, Form, Input, Row, Spin, Typography, message } from "antd";
import moment from "moment/moment";
import React from "react";
import { AddLoadingFieldName } from "../../../../api/rest/dto/tasks/loading/types";
import ClientSelect from "../../../../components/ClientSelect";
import WarehouseSelect from "../../../../components/WarehouseSelect";
import TaskForm from "../../../../components/task/TaskForm";
import Centered from "../../../../components/ui/Centered";
const { Title } = Typography;
const formGutter = [15, 15];
const LoadingTaskForm = (props) => {
    var _a, _b;
    const { task, isLoading, children, isEditing, onSubmit, disableWarehouse, isNew } = props;
    let initialValues = {};
    if (task) {
        initialValues = {
            keeperId: {
                value: task.keeper.id,
                label: task.keeper.companyName,
            },
            ownerId: {
                value: task.owner.id,
                label: task.owner.companyName,
            },
            driverName: task.driverName,
            shipperId: {
                value: (_a = task.shipper) === null || _a === void 0 ? void 0 : _a.id,
                label: (_b = task.shipper) === null || _b === void 0 ? void 0 : _b.companyName,
            },
            ttnNumber: task.ttnNumber,
            ttnDate: task.ttnDate ? moment(new Date(task.ttnDate)) : undefined,
            vehicleNumber: task.vehicleNumber,
            warehouseId: {
                value: task.warehouse.id,
                label: task.warehouse.storageTitle,
            },
        };
    }
    const handlerFinish = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield onSubmit(Object.assign(Object.assign({}, values), { warehouseId: isEditing ? undefined : values.warehouseId.value, keeperId: values.keeperId.value, ownerId: values.ownerId.value, shipperId: values.shipperId.value, ttnDate: values.ttnDate.format("YYYY-MM-DD") }));
        }
        catch (e) {
            message.error("Ошибка при сохранении задачи");
        }
    });
    const taskForm = (React.createElement(React.Fragment, null,
        React.createElement(Col, { span: 12 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u0420\u0435\u043A\u0432\u0438\u0437\u0438\u0442\u044B"),
                React.createElement(Divider, null),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0421\u043A\u043B\u0430\u0434", name: AddLoadingFieldName.WAREHOUSE, rules: [{ required: true }] },
                            React.createElement(WarehouseSelect, { disabled: disableWarehouse, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434" }))),
                    React.createElement(Col, { span: 6 },
                        React.createElement(Form.Item, { label: "\u041D\u043E\u043C\u0435\u0440 \u043D\u0430\u043A\u043B\u0430\u0434\u043D\u043E\u0439", name: AddLoadingFieldName.TTN_NUMBER, rules: [{ required: true }] },
                            React.createElement(Input, null))),
                    React.createElement(Col, { span: 6 },
                        React.createElement(Form.Item, { label: "\u0414\u0430\u0442\u0430 \u043D\u0430\u043A\u043B\u0430\u0434\u043D\u043E\u0439", name: AddLoadingFieldName.TTN_DATE, rules: [{ required: true }] },
                            React.createElement(DatePicker, { style: { width: "100%" } })))),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 8 },
                        React.createElement(Form.Item, { label: "\u041F\u043E\u043A\u043B\u0430\u0436\u0435\u0434\u0430\u0442\u0435\u043B\u044C", name: AddLoadingFieldName.OWNER, rules: [{ required: true }] },
                            React.createElement(ClientSelect, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" }))),
                    React.createElement(Col, { span: 8 },
                        React.createElement(Form.Item, { label: "\u0425\u0440\u0430\u043D\u0438\u0442\u0435\u043B\u044C", name: AddLoadingFieldName.KEEPER, rules: [{ required: true }] },
                            React.createElement(ClientSelect, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" }))),
                    React.createElement(Col, { span: 8 },
                        React.createElement(Form.Item, { label: "\u0413\u0440\u0443\u0437\u043E\u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u0435\u043B\u044C", name: AddLoadingFieldName.SHIPPER, rules: [{ required: true }] },
                            React.createElement(ClientSelect, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E" })))))),
        React.createElement(Col, { span: 12 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u0412\u043E\u0434\u0438\u0442\u0435\u043B\u044C"),
                React.createElement(Divider, null),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0418\u043C\u044F \u0432\u043E\u0434\u0438\u0442\u0435\u043B\u044F", name: AddLoadingFieldName.DRIVER_NAME, rules: [{ required: true }] },
                            React.createElement(Input, null))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u041D\u043E\u043C\u0435\u0440 \u0430\u0432\u0442\u043E\u043C\u043E\u0431\u0438\u043B\u044F", name: AddLoadingFieldName.VEHICLE_NUMBER, rules: [{ required: true }] },
                            React.createElement(Input, null))))))));
    if (!task && !isNew) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { size: "large" })));
    }
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(TaskForm, { onFinish: handlerFinish, taskForm: taskForm, initialValues: initialValues }),
        children));
};
export default LoadingTaskForm;
