var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { getProductCategoryList } from '../../api/rest/requests/products/productCategory';
import AsyncTreeSelect from '../AsyncTreeSelect';
const request = (page, pageSize, parentId, query, setLoadingState) => __awaiter(void 0, void 0, void 0, function* () {
    return (getProductCategoryList(page.toString(), pageSize.toString(), query, parentId === null || parentId === void 0 ? void 0 : parentId.toString(), setLoadingState));
});
const ProductCategorySelect = (props) => (React.createElement(AsyncTreeSelect, Object.assign({}, props, { request: request, dataToOption: (category) => {
        var _a, _b;
        return ({
            id: category.id.toString(),
            title: (_a = category.title) !== null && _a !== void 0 ? _a : '',
            pId: (_b = category.parentId) !== null && _b !== void 0 ? _b : undefined,
            isLeaf: false,
            value: category.id.toString(),
        });
    } })));
export default ProductCategorySelect;
