import { Button, Form, Spin } from "antd";
import React, { useEffect, useMemo } from "react";
import { productFormValuesToRequest } from "../../components/ProductForm/helpers";
import ProductForm from "../../components/ProductForm/ProductForm";
import ProductDataType from "../../constants/enum/ProductDataType";
import { getProductTypeAttributesObject } from "../../helpers/function";
import productApi from "../../redux/api/productApi";
const { useFetchProductQuery, useEditProductMutation } = productApi;
const ProductEditFormContainer = (props) => {
    const { productId } = props;
    const { data, isLoading: dataIsLoading } = useFetchProductQuery(productId);
    const [editProduct, { isLoading: editIsLoading }] = useEditProductMutation();
    const [form] = Form.useForm();
    const isLoading = dataIsLoading || editIsLoading;
    const handlerButtonClick = () => {
        form.submit();
    };
    const handlerSubmit = (values) => {
        var _a;
        if (data) {
            const body = {
                handbookUnitId: values.handbookUnitId.value,
                productAttributeValues: productFormValuesToRequest(values, data === null || data === void 0 ? void 0 : data.productType),
            };
            if (!!values.handbookPackingSetId) {
                if (typeof values.handbookPackingSetId === "number") {
                    body.handbookPackingSetId = values.handbookPackingSetId;
                }
                else {
                    body.handbookPackingSetId = (_a = values === null || values === void 0 ? void 0 : values.handbookPackingSetId) === null || _a === void 0 ? void 0 : _a.value;
                }
            }
            editProduct({
                productId: data.id,
                values: body,
            });
        }
    };
    const initialValues = useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        if (data) {
            const attrsInfo = getProductTypeAttributesObject(data === null || data === void 0 ? void 0 : data.productType);
            const result = {
                productTypeId: data === null || data === void 0 ? void 0 : data.productType.id,
                handbookUnitId: {
                    label: data === null || data === void 0 ? void 0 : data.handbookUnit.title,
                    value: data === null || data === void 0 ? void 0 : data.handbookUnit.id,
                },
                attributes: {},
            };
            const productAttributes = {};
            for (const key in data.productAttributeValues) {
                const attr = data.productAttributeValues[key];
                switch (attrsInfo[key].dataType) {
                    case ProductDataType.BOOLEAN:
                        productAttributes[key] = attr.attributeValue === "true";
                        break;
                    case ProductDataType.DATE:
                    case ProductDataType.DATE_TIME:
                        productAttributes[key] = (_a = attr.attributeValue) !== null && _a !== void 0 ? _a : "";
                        break;
                    case ProductDataType.HANDBOOK:
                        productAttributes[key] = {
                            value: (_c = (_b = attr.handbookValueId) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : "",
                            label: (_d = attr.handbookValueReadable) !== null && _d !== void 0 ? _d : "",
                        };
                        break;
                    case ProductDataType.FORMULA:
                    case ProductDataType.FLOAT:
                    case ProductDataType.INTEGER:
                        productAttributes[key] = (_e = attr.attributeValue) !== null && _e !== void 0 ? _e : 0;
                        break;
                    case ProductDataType.VARCHAR:
                        productAttributes[key] = (_f = attr.attributeValue) !== null && _f !== void 0 ? _f : "";
                        break;
                    default:
                        break;
                }
            }
            if (data === null || data === void 0 ? void 0 : data.handbookPackingSet) {
                result.handbookPackingSetId = {
                    label: data === null || data === void 0 ? void 0 : data.handbookPackingSet.title,
                    value: data === null || data === void 0 ? void 0 : data.handbookPackingSet.id,
                };
            }
            else {
                result.handbookPackingSetId = {
                    label: "Нет упаковки",
                    value: "",
                };
            }
            result.attributes = productAttributes;
            return result;
        }
        return {};
    }, [data]);
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);
    if (data) {
        return (React.createElement(Spin, { spinning: isLoading },
            React.createElement(ProductForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues, productType: data.productType }),
            React.createElement(Button, { loading: editIsLoading, onClick: handlerButtonClick }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
    }
    return React.createElement(Spin, { spinning: true });
};
export default ProductEditFormContainer;
