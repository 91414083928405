import React from 'react';
import Page from '../../../components/Page';
import PackingListPaginationContainer from './components/PackingPage/Pagination/PackingListPaginationContainer';
import PackingTreeContainer from './components/PackingPage/PackingTreeContainer/PackingTreeContainer';
import PackingListHeaderContainer from './components/PackingPage/PackingListHeaderContainer/PackingListHeaderContainer';
import PackingTableContainer from './components/PackingPage/PackingTableContainer/PackingTableContainer';
/**
 * Данный компонент имеет следующую структуру:
 *
 * - Корневая страница
 * - Директория subpages
 *   — В данной директории находятся страницы, доступные по переходам с основной страницы
 * - Директория components, следующей структуре
 *   — Повторяет структуру папки subpages и содержит директорию с названием корневой страницы
 *   (с компонентами уникальными для данных страниц), содержит директорию shared, в которой
 *   хранятся переиспользуемые компоненты, с указанием, где они используются
 * - Файл constants, где содержатся переиспользуемые константы/текстовки раздела.
 */
const PackingPage = () => (React.createElement(React.Fragment, null,
    React.createElement(Page, { toolbarLeft: React.createElement(PackingTreeContainer, null), toolbarBottom: React.createElement(PackingListPaginationContainer, null), toolbarTop: React.createElement(PackingListHeaderContainer, null) },
        React.createElement(PackingTableContainer, null))));
export default PackingPage;
