import { Spin } from "antd";
import React from "react";
import Centered from "../../../../components/ui/Centered";
import Scope from "../../../../constants/enum/Scope";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useProductDrawer } from "../../../../hooks/useProductDrawer";
import { skuActions } from "../../../../redux/reducers/skuReducer";
import CorrectionDetailNomenclature from "../../../conponents/task/correction/CorrectionDetailNomenclature";
const CorrectionTaskNomenclatureContainer = (props) => {
    const { isLoading, task, children } = props;
    const dispatch = useAppDispatch();
    const { openProductDrawer } = useProductDrawer();
    const handlerInfoClick = (productId) => {
        openProductDrawer(productId);
    };
    const handlerSkuInfoClick = (skuId) => dispatch(skuActions.open(skuId));
    if (isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, null)));
    }
    if (task) {
        return (React.createElement(CorrectionDetailNomenclature, { details: task.details, warehouseId: task.warehouse.id, produceWork: taskHelpers.hasScope(task, Scope.PRODUCE_WORK), onInfoClick: handlerInfoClick, onSkuInfoClick: handlerSkuInfoClick }, children));
    }
    return null;
};
export default CorrectionTaskNomenclatureContainer;
