var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { List } from 'antd';
const TaskInfo = (props) => {
    const { items } = props, listProps = __rest(props, ["items"]);
    const renderItem = (item) => (React.createElement(List.Item, null,
        React.createElement(List.Item.Meta, { title: item.title, description: item.value })));
    return React.createElement(List, Object.assign({}, listProps, { bordered: true, dataSource: items, renderItem: (item, index) => renderItem(item) }));
};
export default TaskInfo;
