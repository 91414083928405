var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useProductTypeLazyQuery, useProductTypeListLazyQuery } from "../../../api/graphql/queries/productType/ProductTypeQuery";
import LoadingState from "../../../constants/enum/LoadingState";
export const useProductFilter = () => {
    const [getProductTypeList] = useProductTypeListLazyQuery();
    const [getProductType] = useProductTypeLazyQuery();
    const fetchProductTypeList = (page, pageSize, query) => __awaiter(void 0, void 0, void 0, function* () {
        return getProductTypeList({
            variables: {
                page,
                pageSize,
                search: query,
            },
        });
    });
    const productTypeRequest = (page, pageSize, query, setLoadingState) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoadingState(LoadingState.Loading);
            const result = yield fetchProductTypeList(page, pageSize, query);
            return adaptProductTypeToPaginatedResult(result);
        }
        finally {
            setLoadingState(LoadingState.Ready);
        }
    });
    const adaptProductTypeQueryResult = (queryResult) => {
        if (queryResult && queryResult.data && queryResult.data.productType) {
            const productType = queryResult.data.productType;
            const adaptedProductType = productType;
            return adaptedProductType;
        }
        return null;
    };
    return { productTypeRequest, getProductType, adaptProductTypeQueryResult };
};
export function adaptProductTypeToPaginatedResult(response) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return {
        list: (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.productTypeList.data.map((item) => (Object.assign({}, item)))) !== null && _b !== void 0 ? _b : [],
        pagination: {
            page: (_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.productTypeList.pagination.page) !== null && _d !== void 0 ? _d : 0,
            itemsTotalCount: (_f = (_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.productTypeList.pagination.itemsTotalCount) !== null && _f !== void 0 ? _f : 0,
            itemsCurrentCount: (_h = (_g = response === null || response === void 0 ? void 0 : response.data) === null || _g === void 0 ? void 0 : _g.productTypeList.pagination.itemsCurrentCount) !== null && _h !== void 0 ? _h : 0,
            itemsOnPage: (_k = (_j = response === null || response === void 0 ? void 0 : response.data) === null || _j === void 0 ? void 0 : _j.productTypeList.pagination.itemsOnPage) !== null && _k !== void 0 ? _k : 0,
        },
    };
}
