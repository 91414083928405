/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import warehouseCellApi from '../../api/warehouseApi/warehouseCellReducer';
import warehouseSectionApi from '../../api/warehouseApi/warehouseSectionReducer';
const initialState = {
    sections: {
        list: [],
    },
    cells: {
        list: [],
    },
};
const setSectionList = (state, payload) => {
    state.sections.list = [
        ...state.sections.list.map((section) => {
            const newSection = payload.find((item) => item.id === section.id);
            return newSection !== null && newSection !== void 0 ? newSection : section;
        }),
        ...payload.filter((section) => (!state.sections.list.some((item) => item.id === section.id))),
    ];
};
const setCellList = (state, payload) => {
    state.cells.list = [
        ...state.cells.list.map((cell) => {
            const newCell = payload.find((item) => item.id === cell.id);
            return newCell !== null && newCell !== void 0 ? newCell : cell;
        }),
        ...payload.filter((cell) => (!state.cells.list.some((item) => item.id === cell.id))),
    ];
};
const topologySlice = createSlice({
    name: 'topologySlice',
    initialState,
    reducers: {
        reset: (state) => {
            state.sections.list = [];
            state.cells.list = [];
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(warehouseSectionApi.endpoints.fetchSectionList.matchFulfilled, (state, { payload }) => {
            setSectionList(state, payload.list);
        })
            .addMatcher(warehouseSectionApi.endpoints.fetchSection.matchFulfilled, (state, { payload }) => {
            setSectionList(state, [payload.section, ...payload.childrens.sections]);
            setCellList(state, payload.childrens.cells);
        })
            .addMatcher(warehouseSectionApi.endpoints.addSection.matchFulfilled, (state, { payload }) => {
            setSectionList(state, [payload]);
        })
            .addMatcher(warehouseSectionApi.endpoints.editSection.matchFulfilled, (state, { payload }) => {
            setSectionList(state, [payload]);
        })
            .addMatcher(warehouseSectionApi.endpoints.deleteSection.matchFulfilled, (state, { payload }) => {
            state.sections.list = state.sections.list.filter((section) => section.id !== payload);
        })
            .addMatcher(warehouseCellApi.endpoints.addCell.matchFulfilled, (state, { payload }) => {
            setCellList(state, [payload]);
        })
            .addMatcher(warehouseCellApi.endpoints.editCell.matchFulfilled, (state, { payload }) => {
            setCellList(state, [payload]);
        })
            .addMatcher(warehouseCellApi.endpoints.deleteCell.matchFulfilled, (state, { payload }) => {
            state.cells.list = state.cells.list.filter((cell) => cell.id !== payload);
        });
    },
});
export const topologyActions = topologySlice.actions;
export const { reset } = topologySlice.actions;
export const selectTopology = (state) => state.topology;
export const selectTopologySections = (state) => state.topology.sections.list;
export const selectTopologySection = (id) => createSelector(selectTopologySections, (sections) => { var _a; return (_a = sections.find((section) => section.id === id)) !== null && _a !== void 0 ? _a : null; });
const topologyReducer = topologySlice.reducer;
export default topologyReducer;
