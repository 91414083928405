var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CheckOutlined } from "@ant-design/icons";
import { Spin, Typography, Upload } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { humanFileSize } from "../../helpers/FileHelper";
import MDIcon from "../ui/MDIcon";
export const Files = ({ fileList, isLoading, isFileDownloadInProgress, onAddFile, onDownloadFile }) => {
    const [files, setFileList] = useState(fileList);
    const [progress, setProgress] = useState({});
    useEffect(() => {
        !isLoading && fileList.length && setFileList(fileList);
    }, [fileList, isLoading]);
    const addFileRequest = (options) => __awaiter(void 0, void 0, void 0, function* () {
        onAddFile(options.file, setProgress);
    });
    const handleOnChange = (info) => __awaiter(void 0, void 0, void 0, function* () {
        setFileList((prev) => {
            return [...prev, Object.assign(Object.assign({}, info.file), { percent: progress[info.file.uid] })];
        });
    });
    const handleRenderIcon = (file) => (file.status === "uploading" ? React.createElement(Spin, { style: { transform: "translateY(-2px)" } }) : React.createElement(CheckOutlined, { style: { color: "green", fontSize: "20px", transform: "translateY(-2px)" } }));
    return (React.createElement(React.Fragment, null, isLoading && files.length === 0 ? (React.createElement(Upload.Dragger, { disabled: true },
        React.createElement(Typography.Title, null,
            React.createElement(MDIcon, { icon: "cloud-upload" })),
        React.createElement(Typography.Text, null, "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430..."))) : (React.createElement(Upload.Dragger, { multiple: true, listType: "picture", customRequest: addFileRequest, showUploadList: { showRemoveIcon: false, showDownloadIcon: false }, onChange: handleOnChange, itemRender: (node, file) => React.createElement(FileItem, { isFileDownloadInProgress: isFileDownloadInProgress, fileList: fileList, onDownloadFile: onDownloadFile, setFileList: setFileList, node: node, file: file, progress: progress }), iconRender: handleRenderIcon, fileList: files, progress: { strokeWidth: 3, showInfo: true } },
        React.createElement(Typography.Title, null,
            React.createElement(MDIcon, { icon: "cloud-upload" })),
        React.createElement(Typography.Text, null, "\u041D\u0430\u0436\u043C\u0438\u0442\u0435 \u0438\u043B\u0438 \u043F\u0435\u0440\u0435\u0442\u0430\u0449\u0438\u0442\u0435 \u0444\u0430\u0439\u043B \u0432 \u044D\u0442\u0443 \u043E\u0431\u043B\u0430\u0441\u0442\u044C, \u0447\u0442\u043E\u0431\u044B \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C")))));
};
export default Files;
const FileItem = ({ node, file, progress, isFileDownloadInProgress, fileList, setFileList, onDownloadFile }) => {
    var _a;
    useEffect(() => {
        setFileList((filesList) => {
            return filesList.map((f) => (f.uid === file.uid ? Object.assign(Object.assign({}, f), { percent: progress[file.uid] }) : f));
        });
    }, [progress[file === null || file === void 0 ? void 0 : file.uid]]);
    const targetFile = fileList.find((f) => f.uid === file.uid);
    const fileStyles = { display: "flex", alignItems: "center", marginTop: "8px", padding: "8px", border: "1px solid #d9d9d9", borderRadius: "2px", height: "66px", cursor: "pointer" };
    return file.status === "uploading" ? (React.createElement(React.Fragment, null, node)) : (React.createElement("div", { style: fileStyles, onClick: () => {
            onDownloadFile(file.uid, file.name);
        } },
        React.createElement("div", { style: { display: "flex", alignItems: "center", width: "40px", justifyContent: "center" } }, isFileDownloadInProgress ? React.createElement(Spin, null) : React.createElement(CheckOutlined, { style: { color: "green", fontSize: "20px", transform: "translateY(-2px)" } })),
        React.createElement("div", null,
            React.createElement("div", null,
                "\u0418\u043C\u044F: ",
                file.name),
            React.createElement("div", null,
                "\u0418\u0441\u0442\u043E\u0447\u043D\u0438\u043A: ", (_a = targetFile === null || targetFile === void 0 ? void 0 : targetFile.creator) === null || _a === void 0 ? void 0 :
                _a.fullName)),
        React.createElement("div", { style: { marginLeft: "auto", marginRight: "5px" } },
            React.createElement("div", null,
                "\u0414\u0430\u0442\u0430/\u0432\u0440\u0435\u043C\u044F \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0438: ",
                moment(targetFile === null || targetFile === void 0 ? void 0 : targetFile.createdAt).format("DD-MM-YYYY hh:mm")),
            React.createElement("div", null,
                "\u0420\u0430\u0437\u043C\u0435\u0440: ",
                humanFileSize((targetFile === null || targetFile === void 0 ? void 0 : targetFile.size) || 0)))));
};
