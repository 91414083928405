var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Form, Input } from 'antd';
import StorageCellSelect from '../../../../../components/StorageCellSelect';
const MovementDetailNomenclatureCell = (props) => {
    const { editing, value, warehouseId, dataIndex, children } = props, restProps = __rest(props, ["editing", "value", "warehouseId", "dataIndex", "children"]);
    const renderEditableField = () => {
        if (editing) {
            let inputNode;
            const rules = [];
            switch (dataIndex) {
                case 'storageCellTo': {
                    inputNode = React.createElement(StorageCellSelect, { warehouseId: warehouseId, value: value });
                    rules.push({
                        type: 'object',
                        validator: (_, fieldValue) => __awaiter(void 0, void 0, void 0, function* () {
                            return (fieldValue.value ? Promise.resolve() : Promise.reject());
                        }),
                        message: 'Заполните поле',
                    });
                    break;
                }
                default: {
                    inputNode = React.createElement(Input, null);
                    rules.push({ required: true, message: 'Заполните поле' });
                }
            }
            return (React.createElement(Form.Item, { name: dataIndex, style: { margin: 0 }, rules: rules }, inputNode));
        }
        return children;
    };
    return (React.createElement("td", Object.assign({}, restProps),
        React.createElement("span", { style: { whiteSpace: 'nowrap' } }, renderEditableField())));
};
export default MovementDetailNomenclatureCell;
