var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../../components/Page";
import { links } from "../../../../constants/links";
import { useTaskInventoryCRUDActions } from "../../../../hooks/api/taskInventoryApi/useTaskInventoryCRUDActions";
import InventoryTaskForm from "../../../conponents/task/inventory/InventoryTaskForm";
const InventoryAddPage = () => {
    const navigate = useNavigate();
    const { actions } = useTaskInventoryCRUDActions();
    const { create, isLoading } = actions;
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: null,
        toolbarRight: null,
    };
    const handlerSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        if (values && typeof values === "object" && "warehouseId" in values && typeof values.warehouseId === "number" && "taskInventoryCommissions" in values && Array.isArray(values.taskInventoryCommissions)) {
            const response = yield create(values.warehouseId, values.taskInventoryCommissions || []);
            const id = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.taskInventoryCreate) === null || _b === void 0 ? void 0 : _b.taskInventoryDto) === null || _c === void 0 ? void 0 : _c.id;
            if (id) {
                message.success(`Задача ${id} успешно сохранена`);
                navigate(links.taskInventoryLinks.edit.url(id));
            }
        }
    });
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(InventoryTaskForm, { isLoading: isLoading, onSubmit: handlerSubmit })));
};
export default InventoryAddPage;
