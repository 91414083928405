import React, { useCallback } from "react";
import { Button, Dropdown, Table } from "antd";
import { getColumnType } from "../../../../../../helpers/function";
import MDIcon from "../../../../../../components/ui/MDIcon";
import ColorPalette from "../../../../../../constants/enum/ColorPalette";
const ProductAutomatizationTable = (props) => {
    const { items, loading, onEditItemClick, onDeleteItemClick } = props;
    const menu = useCallback((record) => ({
        items: [
            {
                key: "product_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => onEditItemClick(record.id),
            },
            {
                key: "product_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => onDeleteItemClick(record.id),
            },
        ],
    }), []);
    const columns = [
        getColumnType("id", "id", false, {
            align: "center",
            width: "100px",
        }),
        getColumnType("title", "Название"),
        getColumnType("enable", "Активна", true, {
            align: "center",
            width: "100px",
            render: (value) => typeof value === "boolean" && value && React.createElement(MDIcon, { icon: "check-bold", color: ColorPalette.Green }),
        }),
        getColumnType("actions", "", true, {
            align: "center",
            width: "100px",
            render: (_, record) => (React.createElement(Dropdown, { menu: menu(record), trigger: ["click"] },
                React.createElement(Button, { size: "small", icon: React.createElement(MDIcon, { icon: "pencil" }) }))),
        }),
    ];
    const data = items.map((item) => ({
        key: item.id,
        id: item.id,
        title: item.title,
        enable: item.enable,
    }));
    return React.createElement(Table, { bordered: true, loading: loading, columns: columns, dataSource: data, pagination: false });
};
export default ProductAutomatizationTable;
