import React from "react";
import { useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import WarehouseCellTypeFormContainer from "../../../containers/WarehouseCellTypeFormContainer";
import WarehouseCellTypesPaginationContainer from "../../../containers/WarehouseCellTypesPaginationContainer";
import WarehouseCellTypesTableContainer from "../../../containers/WarehouseCellTypesTableContainer";
import { useWarehouseCellType } from "./useWarehouseCellType";
import { usePagination } from "../../../../hooks/usePagination";
import { DataFetchErrorFallback } from "../../../../components/DataFetchErrorFallback/DataFetchErrorFallback";
const WarehouseCellsTypePage = () => {
    var _a, _b, _c, _d;
    const { id } = useParams();
    const warehouseId = +(id || 0);
    const { page, pageSize, setPagination } = usePagination();
    const { getStorageCellTypeList, deleteStorageCellType, createStorageCellType, editStorageCellType, getStorageCellType } = useWarehouseCellType({ warehouseId, page, pageSize });
    if (getStorageCellTypeList === null || getStorageCellTypeList === void 0 ? void 0 : getStorageCellTypeList.error)
        return React.createElement(DataFetchErrorFallback, null);
    const warehouseCellsTypeList = ((_b = (_a = getStorageCellTypeList === null || getStorageCellTypeList === void 0 ? void 0 : getStorageCellTypeList.data) === null || _a === void 0 ? void 0 : _a.storageCellTypeList) === null || _b === void 0 ? void 0 : _b.data) || [];
    if (Number.isInteger(warehouseId)) {
        const pageProps = {
            toolbarBottom: React.createElement(WarehouseCellTypesPaginationContainer, { pagination: (_d = (_c = getStorageCellTypeList === null || getStorageCellTypeList === void 0 ? void 0 : getStorageCellTypeList.data) === null || _c === void 0 ? void 0 : _c.storageCellTypeList) === null || _d === void 0 ? void 0 : _d.pagination, setPagination: setPagination, isLoading: getStorageCellTypeList === null || getStorageCellTypeList === void 0 ? void 0 : getStorageCellTypeList.isLoading }),
            toolbarRight: React.createElement(WarehouseCellTypeFormContainer, { getStorageCellType: getStorageCellType, createStorageCellType: createStorageCellType, editStorageCellType: editStorageCellType, warehouseId: warehouseId }),
        };
        return (React.createElement(Page, Object.assign({}, pageProps),
            React.createElement(WarehouseCellTypesTableContainer, { deleteStorageCellType: deleteStorageCellType, isListLoading: getStorageCellTypeList === null || getStorageCellTypeList === void 0 ? void 0 : getStorageCellTypeList.isLoading, data: warehouseCellsTypeList, warehouseId: warehouseId })));
    }
    return React.createElement(DataFetchErrorFallback, null);
};
export default WarehouseCellsTypePage;
