import { Button, Tooltip } from "antd";
import React from "react";
import MDIcon from "../../../../../components/ui/MDIcon";
export const createColumns = (handlers) => [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: "1%",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Имя",
        dataIndex: "userName",
        key: "userName",
    },
    {
        title: "",
        key: "actions",
        width: "1%",
        render: (_, record) => (React.createElement(Button.Group, { size: "small", style: { gap: "4px" } },
            React.createElement(Tooltip, { title: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { block: true, onClick: () => {
                        handlers.handleEdit(record.id);
                    }, icon: React.createElement(MDIcon, { icon: "pencil" }), style: { minWidth: "24px" } })))),
    },
];
