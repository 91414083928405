import { combineReducers } from "redux";
import appReducer from './appReducer';
import { clientsApi } from '../api/clientsApi';
import { commentApi } from '../api/commentsApi';
import handbookTreeReducer from './handbookTreeReducer';
import handbookUnitApi from '../api/handbookApi/handbookUnit';
import printDialogReducer from './printDialogReducer';
import productReducer from './productReducer';
import productApi from '../api/productApi';
import skuReducer from './skuReducer';
import statusConfiguratorStatusApi from '../api/statusConfiguratorApi';
import statusConfiguratorReducer from './statusConfiguratorReducer';
import topologyReducer from './topologyReducer';
import userReducer from './userReducer';
import warehouseApi from '../api/warehouseApi';
import { productTypeGraphqlApi } from "../api/productApi/productType";
const reducer = combineReducers({
    app: appReducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    handbookTree: handbookTreeReducer,
    [handbookUnitApi.reducerPath]: handbookUnitApi.reducer,
    [productTypeGraphqlApi.reducerPath]: productTypeGraphqlApi.reducer,
    printDialog: printDialogReducer,
    product: productReducer,
    [productApi.reducerPath]: productApi.reducer,
    sku: skuReducer,
    [statusConfiguratorStatusApi.reducerPath]: statusConfiguratorStatusApi.reducer,
    statusConfigurator: statusConfiguratorReducer,
    topology: topologyReducer,
    user: userReducer,
    [warehouseApi.reducerPath]: warehouseApi.reducer,
});
export default reducer;
