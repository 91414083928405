import { useParams } from "react-router-dom";
import { useProductTypeAutomationQuery } from "../../../../../../api/graphql/queries/automatization/AutomatizationQuery";
import { useEffect } from "react";
import { message } from "antd";
export const useProductExpirationDate = () => {
    const { autoId } = useParams();
    const { data, error, loading, refetch } = useProductTypeAutomationQuery({ variables: { productTypeAutomationId: Number(autoId) }, fetchPolicy: "no-cache" });
    useEffect(() => {
        if (error) {
            message.error("Ошибка получения данных по автоматизации");
        }
    }, [error]);
    return {
        data: data === null || data === void 0 ? void 0 : data.productTypeAutomation,
        isLoading: loading,
        refetch
    };
};
