var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useGetStorageCellListLazyQuery } from "../../api/graphql/queries/storageCell/StorageCellQuery";
import { useGraphqlSelect } from "../../hooks/useGraphqlSelect";
import SearchSelect from "../SearchGraphqlSelect/SearchGraphqlSelect";
const StorageCellSelect = (props) => {
    var _a, _b;
    const { warehouseIds, onChange } = props, otherProps = __rest(props, ["warehouseIds", "onChange"]);
    const [getStorageCellList, { data, loading, fetchMore }] = useGetStorageCellListLazyQuery();
    const getDataQuery = (cb, searchString) => getStorageCellList({
        variables: { page: 1, warehouseIds: (warehouseIds === null || warehouseIds === void 0 ? void 0 : warehouseIds.length) ? warehouseIds : undefined, search: searchString, pageSize: 10 },
    }).then((res) => {
        var _a, _b, _c, _d, _e, _f;
        if (((_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.storageCellList) === null || _b === void 0 ? void 0 : _b.data) && Array.isArray((_d = (_c = res.data) === null || _c === void 0 ? void 0 : _c.storageCellList) === null || _d === void 0 ? void 0 : _d.data)) {
            cb((_f = (_e = res.data) === null || _e === void 0 ? void 0 : _e.storageCellList) === null || _f === void 0 ? void 0 : _f.data);
        }
    });
    const fetchMoreDataQuery = (nextPage, cb, searchString) => fetchMore({
        variables: { page: nextPage, warehouseIds: (warehouseIds === null || warehouseIds === void 0 ? void 0 : warehouseIds.length) ? warehouseIds : undefined, search: searchString, pageSize: 10 },
    }).then((res) => {
        var _a, _b, _c, _d;
        if (((_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.storageCellList) === null || _b === void 0 ? void 0 : _b.data) && Array.isArray((_d = (_c = res.data) === null || _c === void 0 ? void 0 : _c.storageCellList) === null || _d === void 0 ? void 0 : _d.data)) {
            cb((prev) => { var _a, _b; return [...prev, ...(_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.storageCellList) === null || _b === void 0 ? void 0 : _b.data]; });
        }
    });
    const { hasMore, normalizeData, search, onSearchChange, throttledFetchMore } = useGraphqlSelect({ totalItems: (_b = (_a = data === null || data === void 0 ? void 0 : data.storageCellList) === null || _a === void 0 ? void 0 : _a.pagination) === null || _b === void 0 ? void 0 : _b.itemsTotalCount, dependencyKeys: warehouseIds, getDataQuery, fetchMoreDataQuery });
    return (React.createElement(SearchSelect, Object.assign({ key: JSON.stringify(warehouseIds === null || warehouseIds === void 0 ? void 0 : warehouseIds.length), onSearchChange: onSearchChange, data: normalizeData, isLoading: loading, hasMore: hasMore, fetchMoreData: throttledFetchMore }, otherProps, { searchValue: search, onChange: (e) => {
            onChange && onChange(e);
            return e;
        }, mode: "multiple", placeholder: "\u041D\u0430\u0439\u0442\u0438 \u044F\u0447\u0435\u0439\u043A\u0443" })));
};
export default StorageCellSelect;
