import { Spin, Table } from "antd";
import React from "react";
import { HistoryColumns } from "./types";
import { generateRecord } from "./helpers";
import { getColumnType } from "../../helpers/function";
import Centered from "../ui/Centered";
export const columns = [
    getColumnType(HistoryColumns.USER, "Пользователь"),
    getColumnType(HistoryColumns.FROM, "Статус до", false, { width: "120px", align: "right" }),
    getColumnType(HistoryColumns.ARROW, "", false, {
        width: "250px",
    }),
    getColumnType(HistoryColumns.TO, "Статус после", false, { width: "120px" }),
    getColumnType(HistoryColumns.DATE, "Дата / время", true, { width: "150px" }),
    getColumnType(HistoryColumns.REPORT, "Отчет", true, { width: "200px" }),
];
const StatusHistory = (props) => {
    const { isLoading, history } = props;
    let data = [];
    if (!history && isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, null)));
    }
    if (history) {
        data = history.map((entity, index) => generateRecord(entity, index, history));
    }
    return (React.createElement(Table, { loading: isLoading, pagination: false, columns: columns, dataSource: data, scroll: {
            x: "auto",
        } }));
};
export default StatusHistory;
