var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import { useParams } from "react-router-dom";
import { useWarehouseMapEditMutation } from "../../../../api/graphql/queries/warehouseMap/WarehouseMapMutation";
import { isValidId } from "../helpers";
export const useWarehouseEditor = () => {
    const [update] = useWarehouseMapEditMutation({ fetchPolicy: "no-cache" });
    const { id } = useParams();
    const updateWarhouseMap = (node, warehouseId) => __awaiter(void 0, void 0, void 0, function* () {
        if (isValidId(id) || warehouseId) {
            const numericId = warehouseId !== null && warehouseId !== void 0 ? warehouseId : Number(id);
            try {
                yield update({
                    variables: {
                        inputEdit: {
                            warehouseId: numericId,
                            vectorObject: {
                                shape: { rect: { width: Number(node.width), height: Number(node.width), x: 0, y: 0, rx: 0, ry: 0 } },
                            },
                        },
                    },
                });
            }
            catch (error) {
                message.error("Не удалось обновить данные по складу");
            }
        }
    });
    return { updateWarhouseMap };
};
