var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../../components/Page";
import { links } from "../../../../constants/links";
import { useTaskShippingCRUDActions } from "../../../../hooks/api/taskShippingApi/useTaskShippingCRUDActions";
import ShippingTaskForm from "../../../conponents/task/shipping/ShippingTaskForm";
const ShippingAddPage = () => {
    const navigate = useNavigate();
    const { actions } = useTaskShippingCRUDActions();
    const { create, isLoading } = actions;
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: null,
        toolbarRight: null,
    };
    const handlerSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g;
        const response = yield create(values);
        if ((_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.taskShippingAdd) === null || _b === void 0 ? void 0 : _b.taskShippingViewResponse) === null || _c === void 0 ? void 0 : _c.id) {
            message.success(`Задача ${(_d = response.data) === null || _d === void 0 ? void 0 : _d.taskShippingAdd.taskShippingViewResponse.id} успешно сохранена`);
            navigate(links.taskShippingLinks.edit.url((_g = (_f = (_e = response.data) === null || _e === void 0 ? void 0 : _e.taskShippingAdd) === null || _f === void 0 ? void 0 : _f.taskShippingViewResponse) === null || _g === void 0 ? void 0 : _g.id));
        }
    });
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(ShippingTaskForm, { isLoading: isLoading, onSubmit: handlerSubmit, isNew: true })));
};
export default ShippingAddPage;
