export var AddLoadingFieldName;
(function (AddLoadingFieldName) {
    AddLoadingFieldName["WAREHOUSE"] = "warehouseId";
    AddLoadingFieldName["KEEPER"] = "keeperId";
    AddLoadingFieldName["OWNER"] = "ownerId";
    AddLoadingFieldName["SHIPPER"] = "shipperId";
    AddLoadingFieldName["TTN_NUMBER"] = "ttnNumber";
    AddLoadingFieldName["TTN_DATE"] = "ttnDate";
    AddLoadingFieldName["DRIVER_NAME"] = "driverName";
    AddLoadingFieldName["VEHICLE_NUMBER"] = "vehicleNumber";
    AddLoadingFieldName["POWER_OF_ATTORNEY"] = "powerOfAttorney";
    AddLoadingFieldName["POWER_OF_ATTORNEY_DATE"] = "powerOfAttorneyDate";
})(AddLoadingFieldName || (AddLoadingFieldName = {}));
