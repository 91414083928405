import { createApi } from "@reduxjs/toolkit/query/react";
import dtoToEntity from '../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../constants/enum/Method';
import fetchAppQuery from '../../fetchAppQuery';
const COMMENTS_TAG = 'comments';
const URI = 'comment';
const MESSAGE_FIELD = 'message';
const OBJECT_CLASS_FIELD = 'objectClass';
const OBJECT_ID_FIELD = 'objectId';
export const commentApi = createApi({
    reducerPath: 'commentApi',
    tagTypes: [COMMENTS_TAG],
    baseQuery: fetchAppQuery,
    endpoints: (builder) => ({
        fetchList: builder.query({
            query: (args) => ({
                method: Method.GET,
                params: args,
                url: `${URI}/list`,
            }),
            transformResponse: (response) => (response.data.map(dtoToEntity.commentAdapter)),
            providesTags: () => [COMMENTS_TAG],
        }),
        add: builder.mutation({
            query: (args) => {
                const { message, objectClass, objectId } = args;
                return {
                    method: Method.POST,
                    url: `${URI}/add`,
                    body: {
                        [OBJECT_CLASS_FIELD]: objectClass,
                        [OBJECT_ID_FIELD]: objectId,
                        [MESSAGE_FIELD]: message,
                    },
                };
            },
            transformResponse: (response) => (dtoToEntity.commentAdapter(response)),
            invalidatesTags: [COMMENTS_TAG],
        }),
    }),
});
export default commentApi.reducer;
export const { useFetchListQuery, useAddMutation } = commentApi;
