import { Table } from "antd";
import React from "react";
import { getColumnType } from "../../../../helpers/function";
const onCell = (record) => ({});
const InventoryTaskListItemInfo = ({ productCount, skuCount, storageCellCount, skuByProductCount, skuByStorageCellCount, }) => {
    const columns = [
        {
            title: 'Инвентаризация по продукции',
            children: [
                getColumnType('productCount', 'Кол-во продукции', false, {
                    width: '140px',
                    onCell
                }),
                getColumnType('skuByProductCount', 'Кол-во SKU', false, {
                    width: '140px',
                    onCell
                })
            ],
        },
        {
            title: 'Инвентаризация по ячейкам хранения',
            children: [
                getColumnType('storageCellCount', 'Кол-во ячеек', false, {
                    width: '140px',
                    onCell
                }),
                getColumnType('skuByStorageCellCount', 'Кол-во SKU', false, {
                    width: '140px',
                    onCell
                }),
            ],
        },
        {
            title: 'Инвентаризация по SKU',
            children: [
                getColumnType('skuCount', 'Кол-во SKU', false, {
                    width: '1%',
                    onCell
                })
            ],
        }
    ];
    const data = [
        {
            productCount,
            skuCount,
            storageCellCount,
            skuByProductCount,
            skuByStorageCellCount,
        },
    ];
    return (React.createElement(Table, { style: { whiteSpace: "nowrap" }, 
        //   components={{
        //     body: {
        //       cell: Cell,
        //     },
        //   }}
        columns: columns, dataSource: data, bordered: true, pagination: false, size: "small", scroll: { x: true } }));
};
export default InventoryTaskListItemInfo;
