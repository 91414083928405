import { Timeline } from 'antd';
import React from 'react';
import { toDate } from '../../helpers/function';
import SkuHistoryItem from './SkuHistoryItem';
const SkuHistory = (props) => {
    const { items } = props;
    return (React.createElement(Timeline, null, items.map((item) => (React.createElement(Timeline.Item, { color: item.status.style, key: item.taskId + toDate(item.createdAt).toDateString() },
        React.createElement(SkuHistoryItem, { info: item }))))));
};
export default SkuHistory;
