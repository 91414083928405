var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useMount } from "@vitm/template-editor/src/hooks/useMount";
import { useGetFileListLazyQuery } from "../../api/graphql/queries/files/FilesQuery";
import Files from "../../components/Files";
import { adapt } from "../../components/Files/FileService/adapter";
import { useFileDownload } from "../../components/Files/FileService/useFileDownload";
import { useFileUpload } from "../../components/Files/FileService/useFileUpload";
import { useGetFileInfo } from "../../components/Files/FileService/useGetFileInfo";
import { message } from "antd";
import { useFileComponent } from "../../components/Files/FileService/useFileComponent";
const FilesContainer = (props) => {
    var _a;
    const { objectClass, objectId } = props;
    const [fetchList, { data, loading, error }] = useGetFileListLazyQuery({ fetchPolicy: "no-cache" });
    const { getFileInfo, isLoading: isGetFileInfoInProgress } = useGetFileInfo();
    const { downloadFile, isLoading: isFileDownloadInProgress } = useFileDownload();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const { uploadFile, loading: isFileUploadInProgress } = useFileUpload();
    const { fetchFiles } = useFileComponent(setUploadedFiles);
    useMount(() => {
        fetchList({ variables: { input: { objectClass: adapt(objectClass), objectId } } });
    });
    useEffect(() => {
        if (error && error.message) {
            message.error("Ошибка получения списка файлов");
        }
    }, [JSON.stringify(error)]);
    useEffect(() => {
        fetchFiles(data === null || data === void 0 ? void 0 : data.fileList.data, getFileInfo);
    }, [JSON.stringify((_a = data === null || data === void 0 ? void 0 : data.fileList) === null || _a === void 0 ? void 0 : _a.data)]);
    const handlerAddFile = (file, setProgress) => {
        uploadFile("/fileCollection/upload", {
            file,
            objectClass,
            objectId,
        }, setProgress).then(() => __awaiter(void 0, void 0, void 0, function* () {
            yield fetchList({ variables: { input: { objectClass: adapt(objectClass), objectId } } });
        }));
    };
    const handlerDownloadFile = (fileId, fileName) => __awaiter(void 0, void 0, void 0, function* () {
        yield downloadFile(fileId, fileName);
    });
    return React.createElement(Files, { onDownloadFile: handlerDownloadFile, onAddFile: handlerAddFile, fileList: uploadedFiles, isFileDownloadInProgress: isFileDownloadInProgress, isLoading: isFileUploadInProgress || loading || isFileDownloadInProgress || isGetFileInfoInProgress });
};
export default FilesContainer;
