var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Spin, message } from "antd";
import React, { useEffect } from "react";
import TaskForm from "../../../../components/task/TaskForm";
import { removeUndefinedNullKeys, selectAdapter } from "../../../../helpers/function";
import DestinationWarehouseSection from "./Form/DestinationWarehouseSection";
import WarehouseSection from "./Form/WarehouseSection";
const ChangeWarehouseTaskForm = (props) => {
    const { isLoading, children, onSubmit, onLoad } = props;
    useEffect(() => {
        if (onLoad)
            onLoad().finally();
    }, []);
    const handlerFinish = (input) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield onSubmit(removeUndefinedNullKeys(selectAdapter(input)));
        }
        catch (e) {
            message.error("Ошибка при сохранении задачи");
        }
    });
    const taskForm = (React.createElement(React.Fragment, null,
        React.createElement(WarehouseSection, null),
        React.createElement(DestinationWarehouseSection, null)));
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(TaskForm, { onFinish: handlerFinish, taskForm: taskForm }),
        children));
};
export default ChangeWarehouseTaskForm;
