import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const EditUserGroupDocument = gql `
    mutation editUserGroup($input: UserGroupEditInput!) {
  userGroupEdit(input: $input) {
    userGroupDto {
      id
    }
  }
}
    `;
/**
 * __useEditUserGroupMutation__
 *
 * To run a mutation, you first call `useEditUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserGroupMutation, { data, loading, error }] = useEditUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserGroupMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditUserGroupDocument, options);
}
export const CreateUserGroupDocument = gql `
    mutation createUserGroup($input: UserGroupCreateInput!) {
  userGroupCreate(input: $input) {
    userGroupDto {
      id
    }
  }
}
    `;
/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserGroupMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateUserGroupDocument, options);
}
export const DeleteUserGroupDocument = gql `
    mutation deleteUserGroup($input: UserGroupDeleteInput!) {
  userGroupDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteUserGroupDocument, options);
}
