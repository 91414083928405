import React from 'react';
import { Button, Dropdown } from 'antd';
import { links } from '../../../../constants/links';
import { getColumnType } from '../../../../helpers/function';
import TaskListItemWithSlot from '../../../../components/task/TaskList/TaskListItemWithSlot';
import TaskNomenclature from '../../../../components/task/TaskNomenclature';
import { PrinterOutlined } from '@ant-design/icons';
const columns = [
    getColumnType('id', '#', false, { width: '1%' }),
    getColumnType('type', 'Тип', false, { width: '1%' }),
    getColumnType('title', 'Номенклатура'),
    getColumnType('partitionCount', 'Делений', false, { width: '1%' }),
];
const PartitionTaskListItem = (props) => {
    const { task, printActions, pagination, onPrintActionClick } = props;
    const info = [
        { title: 'Склад', value: task.warehouse.storageTitle },
    ];
    const nomenclature = {
        columns,
        data: task.details.map((detail) => (Object.assign({ key: detail.id }, detail))),
    };
    const handlerPrintAction = (id) => {
        if (onPrintActionClick)
            onPrintActionClick(id);
    };
    const documentsDropdownMenu = {
        items: printActions === null || printActions === void 0 ? void 0 : printActions.map(item => {
            return {
                key: item.id,
                label: item.title,
                onClick: () => handlerPrintAction(item.id)
            };
        })
    };
    const actions = (React.createElement(React.Fragment, null, (printActions === null || printActions === void 0 ? void 0 : printActions.length) ? (React.createElement(Dropdown, { menu: documentsDropdownMenu, trigger: ['click'] },
        React.createElement(Button, { size: "small", title: 'Печать документов', icon: React.createElement(PrinterOutlined, null) }))) : null));
    return (React.createElement(TaskListItemWithSlot, { task: task, info: info, pagination: pagination, slot: (nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.data) ? React.createElement(TaskNomenclature, { columns: nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.columns, dataSource: nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.data }) : React.createElement(React.Fragment, null), link: links.taskPartitionLinks.page.url(task.id), actions: actions }));
};
export default PartitionTaskListItem;
