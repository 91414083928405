import { Col, Form, Input, Row } from "antd";
import React from "react";
import UnitSelect from "../../../../../../components/UnitSelect";
import { BASE_VALIDATION_RULES, FormNames, FormNamesLocale, FormSetNames } from "../../../constants";
const PackingSetForm = (props) => {
    const { form, initialValues, onSubmit } = props;
    return (React.createElement(Form, { form: form, initialValues: initialValues, onFinish: onSubmit, layout: "vertical" },
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormSetNames.TITLE, label: FormNamesLocale.TITLE, rules: [BASE_VALIDATION_RULES] },
                    React.createElement(Input, null)),
                React.createElement(Form.Item, { name: FormNames.HANDBOOK_UNIT_ID, label: FormNamesLocale.HANDBOOK_UNIT_ID, rules: [BASE_VALIDATION_RULES] },
                    React.createElement(UnitSelect, null))),
            React.createElement(Col, { span: 12 }))));
};
export default PackingSetForm;
