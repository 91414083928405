export const timeZoneAdapter = (dto) => {
    const { id, baseUtcOffset, supportsDaylightSavingTime, daylightName, standardName, displayName, } = dto;
    return {
        id,
        baseUtcOffset,
        supportsDaylightSavingTime,
        daylightName,
        standardName,
        displayName,
    };
};
