import { Button, Form, message, Spin } from "antd";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import ProductTypeForm from "../../components/ProductTypeForm";
import productCategoryApi from "../../redux/api/productApi/productCategory";
import { productTypeGraphqlApi } from "../../redux/api/productApi/productType";
import { formatFormulaInput } from "../../components/FormulaMaskInput/FormulaMaskInput";
const { useFetchProductTypeQuery, useEditProductTypeMutation } = productTypeGraphqlApi;
const { useFetchProductCategoryQuery } = productCategoryApi;
const ProductTypeEditFormContainer = () => {
    const [form] = Form.useForm();
    const { typeId: typeParam } = useParams();
    const typeId = typeParam ? +typeParam : null;
    const { data: type, isLoading: typeIsLoading } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    const { data: parentCategory, isLoading: categoryIsLoading } = useFetchProductCategoryQuery(type === null || type === void 0 ? void 0 : type.productCategoryId, { skip: !Number.isInteger(type === null || type === void 0 ? void 0 : type.productCategoryId) });
    const [editProductType, { isLoading: editIsLoading }] = useEditProductTypeMutation();
    const isLoading = typeIsLoading || categoryIsLoading || editIsLoading;
    const handlerButtonClick = () => {
        form.submit();
    };
    const handlerSubmit = (values) => {
        if (typeId) {
            editProductType({
                input: {
                    productCategoryId: parseInt(values.productCategoryId.value, 10),
                    title: values.title,
                    description: values.description,
                    titleMask: values.titleMask,
                    sizeFormula: (values === null || values === void 0 ? void 0 : values.sizeFormula) ? formatFormulaInput(values === null || values === void 0 ? void 0 : values.sizeFormula) : undefined,
                },
                productTypeId: typeId
            })
                .unwrap()
                .then(() => {
                message.success(`Тип ${values.title} успешно изменен`);
            });
        }
    };
    const initialValues = useMemo(() => {
        const values = {};
        if (parentCategory && type) {
            values.productCategoryId = {
                value: parentCategory.category.id,
                label: parentCategory.category.title,
            };
            values.title = type.title;
            values.description = type.description || "";
            values.titleMask = type.titleMask;
            values.sizeFormula = type.sizeFormula;
        }
        return values;
    }, [parentCategory, type]);
    const maskOptions = useMemo(() => {
        var _a;
        const attrs = (_a = type === null || type === void 0 ? void 0 : type.productTypeCategories.reduce((acc, cat) => { var _a; return [...acc, ...((_a = cat.productTypeAttributes) !== null && _a !== void 0 ? _a : [])]; }, [])) !== null && _a !== void 0 ? _a : [];
        return attrs.filter((attr) => !attr.isPersonal);
    }, [type]);
    return (React.createElement(Spin, { spinning: isLoading },
        React.createElement(ProductTypeForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues, maskOptions: maskOptions }),
        React.createElement(Button, { onClick: handlerButtonClick }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
};
export default ProductTypeEditFormContainer;
