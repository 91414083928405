import React from "react";
import TaskListItemWithSlot from "../../../../components/task/TaskList/TaskListItemWithSlot";
import { links } from "../../../../constants/links";
import InventoryTaskListItemInfo from "./InventoryTaskListItemInfo";
const InventoryTaskListItem = (props) => {
    const { task, pagination } = props;
    const info = [{ title: "Склад", value: task.warehouse.storageTitle }];
    return (React.createElement(TaskListItemWithSlot, { pagination: pagination, task: task, info: info, slot: React.createElement(InventoryTaskListItemInfo, { productCount: task.productCount, skuCount: task.skuCount, storageCellCount: task.storageCellCount, skuByProductCount: task.skuByProductCount, skuByStorageCellCount: task.skuByStorageCellCount }), link: links.taskInventoryLinks.page.url(task.id) }));
};
export default InventoryTaskListItem;
