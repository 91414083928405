var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DoubleLeftOutlined, DoubleRightOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";
import React, { useLayoutEffect, useState } from "react";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import { productTypeGraphqlApi } from "../../redux/api/productApi/productType";
import Title from "antd/es/typography/Title";
import { useParams } from "react-router-dom";
import ProductDataType from "../../constants/enum/ProductDataType";
import { DataType } from "../../api/graphql/common";
const { useFetchProductTypeQuery, useEditProductTypeOrderMutation } = productTypeGraphqlApi;
const DragHandle = SortableHandle(() => React.createElement(MenuOutlined, { style: { cursor: "grab", color: "#999" } }));
const SortableItem = SortableElement((props) => React.createElement("tr", Object.assign({}, props)));
const SortableBody = SortableContainer((props) => React.createElement("tbody", Object.assign({}, props)));
function filterProductTypeAttributes(categories) {
    return categories.flatMap((category) => { var _a; return ((_a = category.productTypeAttributes) === null || _a === void 0 ? void 0 : _a.filter((attr) => [ProductDataType.FLOAT, ProductDataType.INTEGER].includes(attr === null || attr === void 0 ? void 0 : attr.dataType) || [DataType.Integer, DataType.Float].includes(attr === null || attr === void 0 ? void 0 : attr.dataType))) || []; });
}
export const ProductTypeAttributeSortTables = () => {
    const { typeId: typeParam } = useParams();
    const typeId = typeParam ? +typeParam : null;
    const { data: type, isLoading: typeIsLoading } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    const [sorted, setSorted] = useState([]);
    const [unsorted, setUnsorted] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editProductTypeOrder] = useEditProductTypeOrderMutation();
    useLayoutEffect(() => {
        const sortedData = filterProductTypeAttributes((type === null || type === void 0 ? void 0 : type.productTypeCategories) || [])
            .filter((item) => item.sortOrder !== null)
            .sort((a, b) => { var _a, _b; return ((_a = a.sortOrder) !== null && _a !== void 0 ? _a : 0) - ((_b = b.sortOrder) !== null && _b !== void 0 ? _b : 0); });
        const unsortedData = filterProductTypeAttributes((type === null || type === void 0 ? void 0 : type.productTypeCategories) || []).filter((item) => item.sortOrder === null);
        setSorted(sortedData);
        setUnsorted(unsortedData);
    }, [type === null || type === void 0 ? void 0 : type.productTypeCategories]);
    const patchSortedOrder = (sortedIds) => __awaiter(void 0, void 0, void 0, function* () {
        if (type === null || type === void 0 ? void 0 : type.id) {
            setLoading(true);
            yield editProductTypeOrder({
                input: {
                    items: sortedIds,
                },
                productTypeId: type === null || type === void 0 ? void 0 : type.id,
            });
            setLoading(false);
        }
    });
    const handleSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newSorted = arrayMove(sorted, oldIndex, newIndex);
            setSorted(newSorted);
            const sortedIds = newSorted.map((item) => item.id);
            patchSortedOrder(sortedIds);
        }
    };
    const handleMoveToSorted = (record) => {
        setSorted([...sorted, Object.assign(Object.assign({}, record), { sortOrder: sorted.length })]);
        setUnsorted(unsorted.filter((item) => item.id !== record.id));
        const sortedIds = [...sorted, record].map((item) => item.id);
        patchSortedOrder(sortedIds);
    };
    const handleMoveToUnsorted = (record) => {
        setUnsorted([...unsorted, Object.assign(Object.assign({}, record), { sortOrder: null })]);
        setSorted(sorted.filter((item) => item.id !== record.id));
        const sortedIds = sorted.filter((item) => item.id !== record.id).map((item) => item.id);
        patchSortedOrder(sortedIds);
    };
    const commonColumns = [
        {
            title: "Свойство",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Размерность",
            dataIndex: "dimension",
            key: "dimension",
        },
    ];
    const columnsLeft = [
        {
            title: "",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => React.createElement(DragHandle, null),
        },
        ...commonColumns,
        {
            title: "",
            key: "action",
            render: (_, record) => React.createElement(Button, { size: "small", icon: React.createElement(DoubleRightOutlined, null), onClick: () => handleMoveToUnsorted(record) }),
        },
    ];
    const columnsRight = [
        {
            title: "",
            key: "action",
            render: (_, record) => React.createElement(Button, { size: "small", icon: React.createElement(DoubleLeftOutlined, null), onClick: () => handleMoveToSorted(record) }),
        },
        ...commonColumns,
    ];
    const DraggableContainer = (dragProps) => React.createElement(SortableBody, Object.assign({ useDragHandle: true, disableAutoscroll: true, helperClass: "row-dragging", onSortEnd: handleSortEnd }, dragProps));
    const DraggableBodyRow = (_a) => {
        var restProps = __rest(_a, []);
        const index = sorted.findIndex((x) => x.id === restProps["data-row-key"]);
        return index !== -1 ? React.createElement(SortableItem, Object.assign({ index: index }, restProps)) : React.createElement("tr", Object.assign({}, restProps));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gutter: 16 },
            React.createElement(Col, { span: 12 },
                React.createElement(Table, { size: "small", rowKey: "id", columns: columnsLeft, dataSource: sorted, pagination: false, loading: loading || typeIsLoading, title: () => React.createElement(Title, { level: 5 }, "\u041E\u0442\u0441\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u043E"), components: {
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    } })),
            React.createElement(Col, { span: 12 },
                React.createElement(Table, { size: "small", rowKey: "id", columns: columnsRight, dataSource: unsorted, loading: loading, pagination: false, title: () => React.createElement(Title, { level: 5 }, "\u0411\u0435\u0437 \u0441\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u043A\u0438") })))));
};
