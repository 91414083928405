import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../components/Page';
import StatusConfiguratorContainer from '../containers/StatusConfiguratorContainer';
const StatusConfiguratorPage = () => {
    const { objectClass } = useParams();
    const pageProps = {};
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(StatusConfiguratorContainer, { objectClass: objectClass })));
};
export default StatusConfiguratorPage;
