var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Checkbox, DatePicker, Input, InputNumber } from "antd";
import moment from "moment";
import React from "react";
import { DataType } from "../../api/graphql/common";
import ProductDataType from "../../constants/enum/ProductDataType";
import HandbookSearchContainer from "../../core/containers/HandbookSearchContainer";
import ColorSelect from "../ColorPicker/ColorPicker";
import { WithPreloadedOption } from "./WithPreloadedOption";
const ProductTypeAttrInput = (props) => {
    const { attribute, value, style, loading, withPreload = false, dataAsNumber = false } = props, otherProps = __rest(props, ["attribute", "value", "style", "loading", "withPreload", "dataAsNumber"]);
    const { dataType, id, handbookId } = attribute;
    switch (dataType) {
        case DataType.Float:
        case ProductDataType.FLOAT: {
            return React.createElement(InputNumber, Object.assign({}, otherProps, { disabled: loading, id: `${id}`, defaultValue: value, style: style, addonAfter: attribute.dimension }));
        }
        case DataType.Integer:
        case ProductDataType.INTEGER: {
            return React.createElement(InputNumber, Object.assign({}, otherProps, { disabled: loading, id: `${id}`, defaultValue: value, precision: 0, style: style, addonAfter: attribute.dimension }));
        }
        case DataType.Handbook:
        case ProductDataType.HANDBOOK: {
            return withPreload && (typeof value === "string" || typeof value === "number") ? (React.createElement(WithPreloadedOption, Object.assign({}, otherProps, { value: value, handbookId: handbookId || 0, style: style, loading: loading }))) : (React.createElement(HandbookSearchContainer, Object.assign({}, otherProps, { value: value, handbookId: handbookId || 0, style: style, loading: loading })));
        }
        case ProductDataType.COLOR: {
            return React.createElement(ColorSelect, Object.assign({}, otherProps, { loading: loading, value: value }));
        }
        case DataType.Boolean:
        case ProductDataType.BOOLEAN: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { mode } = otherProps, checkboxProps = __rest(otherProps, ["mode"]);
            return React.createElement(Checkbox, Object.assign({}, checkboxProps, { disabled: loading }));
        }
        case DataType.Date:
        case ProductDataType.DATE: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { mode } = otherProps, dateProps = __rest(otherProps, ["mode"]);
            const pickerProps = dateProps;
            if (value) {
                pickerProps.defaultValue = moment(value, "YYYY-MM-DD");
            }
            if (dataAsNumber) {
                return React.createElement(InputNumber, Object.assign({ addonAfter: "в днях" }, otherProps, { disabled: loading, id: `${id}`, defaultValue: value, style: style }));
            }
            return React.createElement(DatePicker, Object.assign({}, pickerProps, { style: style, disabled: loading }));
        }
        case DataType.DateTime:
        case ProductDataType.DATE_TIME: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { mode } = otherProps, dateProps = __rest(otherProps, ["mode"]);
            const pickerProps = dateProps;
            if (dataAsNumber) {
                return React.createElement(InputNumber, Object.assign({ addonAfter: "в днях" }, otherProps, { disabled: loading, id: `${id}`, defaultValue: value, style: style }));
            }
            if (value) {
                pickerProps.defaultValue = moment(value, "YYYY-MM-DD HH:mm");
            }
            return React.createElement(DatePicker, Object.assign({}, pickerProps, { showTime: true, style: style, disabled: loading }));
        }
        case DataType.Varchar:
        case ProductDataType.VARCHAR:
        default: {
            return React.createElement(Input, Object.assign({}, otherProps, { id: `${id}`, defaultValue: value, style: style, addonAfter: attribute.dimension, disabled: loading }));
        }
    }
};
export default ProductTypeAttrInput;
