import React, { useEffect } from 'react';
import { Button, Checkbox, Col, Form, Input, Popconfirm, Row, Select, Tooltip, } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { getColorName } from '../../helpers/function';
import ColorPalette from '../../constants/enum/ColorPalette';
const getColorOption = (color) => (React.createElement(Select.Option, { value: color, key: color }, getColorName(color)));
const blockDeleteMessage = 'Сначала необходимо удалить все смежные связи и точки входа';
const StatusConfiguratorStatusForm = (props) => {
    const { blockDelete, initialValues, onSubmit, onDelete, } = props;
    const [form] = useForm();
    const handlerDelete = () => {
        if (onDelete)
            onDelete();
    };
    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues]);
    return (React.createElement(Form, { form: form, layout: "vertical", onFinish: onSubmit, initialValues: initialValues },
        React.createElement(Row, { gutter: [20, 0] },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0441\u0442\u0430\u0442\u0443\u0441\u0430", name: "title", rules: [{ required: true, message: 'Укажите название' }] },
                    React.createElement(Input, null))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { label: "\u0426\u0432\u0435\u0442 \u0441\u0442\u0430\u0442\u0443\u0441\u0430", name: "style", rules: [{ required: true, message: 'Укажите цвет' }] },
                    React.createElement(Select, null,
                        getColorOption(ColorPalette.Blue),
                        getColorOption(ColorPalette.Brown),
                        getColorOption(ColorPalette.Cyan),
                        getColorOption(ColorPalette.Green),
                        getColorOption(ColorPalette.Grey),
                        getColorOption(ColorPalette.Indigo),
                        getColorOption(ColorPalette.Olive),
                        getColorOption(ColorPalette.Orange),
                        getColorOption(ColorPalette.Pink),
                        getColorOption(ColorPalette.Purple),
                        getColorOption(ColorPalette.Red),
                        getColorOption(ColorPalette.Teal),
                        getColorOption(ColorPalette.Violet),
                        getColorOption(ColorPalette.Yellow))))),
        blockDelete ? (React.createElement(Tooltip, { title: blockDeleteMessage },
            React.createElement(Form.Item, { name: "isActive", valuePropName: "checked", style: { marginBottom: 0 } },
                React.createElement(Checkbox, { disabled: true }, "\u0410\u043A\u0442\u0438\u0432\u043D\u044B\u0439")))) : (React.createElement(Form.Item, { name: "isActive", valuePropName: "checked", style: { marginBottom: 0 } },
            React.createElement(Checkbox, null, "\u0410\u043A\u0442\u0438\u0432\u043D\u044B\u0439"))),
        React.createElement(Form.Item, { name: "isDefaultForFilter", valuePropName: "checked", style: { marginBottom: 0 } },
            React.createElement(Checkbox, null, "\u0421\u0442\u0430\u043D\u0434\u0430\u0440\u0442\u043D\u043E \u043E\u0442\u043C\u0435\u0447\u0435\u043D \u0432 \u0444\u0438\u043B\u044C\u0442\u0440\u0435")),
        React.createElement(Form.Item, { name: "isFinal", valuePropName: "checked", style: { marginBottom: 0 } },
            React.createElement(Checkbox, null, "\u041A\u043E\u043D\u0435\u0447\u043D\u044B\u0439 \u0441\u0442\u0430\u0442\u0443\u0441")),
        React.createElement(Form.Item, { name: "isCancel", valuePropName: "checked" },
            React.createElement(Checkbox, null, "\u041E\u0442\u043C\u0435\u043D\u0430")),
        React.createElement(Form.Item, null, onDelete ? (React.createElement(Button.Group, { style: { width: '100%' } },
            React.createElement(Button, { block: true, type: "primary", htmlType: "submit" }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
            blockDelete ? (React.createElement(Tooltip, { title: blockDeleteMessage },
                React.createElement(Button, { block: true, type: "primary", disabled: true, danger: true, htmlType: "submit" }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"))) : (React.createElement(Popconfirm, { title: "\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043B\u0438\u0442\u044C \u0441\u0442\u0430\u0442\u0443\u0441?", okText: "\u0414\u0430", cancelText: "\u041D\u0435\u0442", onConfirm: handlerDelete },
                React.createElement(Button, { block: true, type: "primary", danger: true, htmlType: "submit" }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"))))) : (React.createElement(Button, { type: "primary", htmlType: "submit" }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")))));
};
export default StatusConfiguratorStatusForm;
