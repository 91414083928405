import React from "react";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { useTaskShipping } from "../../../../hooks/api/taskShippingApi/useTaskShipping";
import ShippingTaskListContainer from "../../../containers/task/shipping/ShippingTaskListContainer";
import ShippingTaskListFilterContainer from "../../../containers/task/shipping/ShippingTaskListFilterContainer";
import ShippingTaskListHeaderContainer from "../../../containers/task/shipping/ShippingTaskListHeaderContainer";
import ShippingTaskPaginationContainer from "../../../containers/task/shipping/ShippingTaskPaginationContainer";
const ShippingListPage = () => {
    const { taskList } = useTaskShipping();
    const props = {
        toolbarBottom: React.createElement(ShippingTaskPaginationContainer, { pagination: taskList.pagination, isLoading: taskList.loading, data: taskList.data }),
        toolbarTop: React.createElement(ShippingTaskListHeaderContainer, { data: taskList === null || taskList === void 0 ? void 0 : taskList.data, isLoading: taskList.loading, pagination: taskList === null || taskList === void 0 ? void 0 : taskList.pagination }),
        toolbarRight: React.createElement(ShippingTaskListFilterContainer, { isLoading: taskList.loading, objectClass: ObjectClass.TaskShipping, fetchList: taskList.paginationFilteredGetTaskList }),
    };
    return (React.createElement(Page, Object.assign({}, props),
        React.createElement(ShippingTaskListContainer, { pagination: taskList.pagination, taskList: taskList.data, isLoading: taskList.loading })));
};
export default ShippingListPage;
