import { Spin, Tree } from "antd";
import React, { useContext } from "react";
import { WarehouseTopologyContext } from "../WarehouseTopology";
import { convertToAntTreeData } from "../hooks/helpers";
export const Three = () => {
    var _a, _b, _c, _d;
    const context = useContext(WarehouseTopologyContext);
    if (context === undefined) {
        throw new Error("Map must be used within a WarehouseTopology");
    }
    const treeData = convertToAntTreeData((_a = context === null || context === void 0 ? void 0 : context.state) === null || _a === void 0 ? void 0 : _a.topology, (_b = context === null || context === void 0 ? void 0 : context.state) === null || _b === void 0 ? void 0 : _b.activeNodeIds);
    return (React.createElement("div", { className: "styled-tree", style: { padding: "16px", position: "relative", overflow: "auto" } }, ((_c = context === null || context === void 0 ? void 0 : context.state) === null || _c === void 0 ? void 0 : _c.isCellsLoading) || ((_d = context === null || context === void 0 ? void 0 : context.state) === null || _d === void 0 ? void 0 : _d.isSectionsLoading) || !treeData ? (React.createElement("div", { style: { width: "100%", display: "flex", justifyContent: "center" } },
        React.createElement(Spin, null))) : (React.createElement(Tree.DirectoryTree, { onDragStart: context === null || context === void 0 ? void 0 : context.handleDragStart, onDoubleClick: (_, node) => {
            context.handleDoubleClick(node);
        }, draggable: {
            icon: false, //
            nodeDraggable: (node) => !node.children, // Разрешаем перетаскивание только для листьев дерева (узлов без детей)
        }, treeData: treeData }))));
};
