import React from 'react';
import { Button, Table } from 'antd';
import { getColumnType } from '../../helpers/function';
import MDIcon from '../ui/MDIcon';
const HandbookUnitsTable = (props) => {
    const { items, loading, onEditItemClick } = props;
    const columns = [
        getColumnType('title', 'Единица измерения'),
        getColumnType('national', 'Условное обозначение', false, { width: '1%' }),
        getColumnType('okeiCode', 'ОКЕИ', false, { width: '1%' }),
        getColumnType('actions', '', false, {
            width: '1%',
            render: (value, record) => (React.createElement(Button, { size: "small", icon: React.createElement(MDIcon, { icon: "pencil" }), onClick: () => onEditItemClick(record.id) })),
        }),
    ];
    const data = items.map((item) => ({
        key: item.id,
        id: item.id,
        title: item.title,
        national: item.national,
        okeiCode: item.okeiCode,
    }));
    return (React.createElement(Table, { columns: columns, dataSource: data, loading: loading, pagination: false }));
};
export default HandbookUnitsTable;
