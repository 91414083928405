/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    selected: null,
};
const statusConfiguratorSlice = createSlice({
    name: 'statusConfiguratorSlice',
    initialState,
    reducers: {
        select(state, action) {
            state.selected = action.payload;
        },
    },
});
export const selectStatusConfigurator = (state) => state.statusConfigurator;
export const statusConfiguratorActions = statusConfiguratorSlice.actions;
export default statusConfiguratorSlice.reducer;
