import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ProductAvailabilityDocument = gql `
    query ProductAvailability($productId: Long!, $warehouseId: Long) {
  productAvailability(productId: $productId, warehouseId: $warehouseId) {
    storageCellId
    storageCellTitle
    storageCellAddress
    warehouseId
    warehouseTitle
    skuCount
    skuQantity
    skuIds
  }
}
    `;
/**
 * __useProductAvailabilityQuery__
 *
 * To run a query within a React component, call `useProductAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductAvailabilityQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useProductAvailabilityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProductAvailabilityDocument, options);
}
export function useProductAvailabilityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProductAvailabilityDocument, options);
}
export function useProductAvailabilitySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProductAvailabilityDocument, options);
}
