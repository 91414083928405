import { Empty, Typography } from "antd";
import React from "react";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { skuActions } from "../../../../redux/reducers/skuReducer";
import MovementDetailNomenclatureItem from "../../../conponents/task/movement/MovementDetailNomenclature/MovementDetailNomenclatureItem";
const { Title, Text } = Typography;
const MovementDetailNomenclatureContainer = (props) => {
    const { task } = props;
    const dispatch = useAppDispatch();
    const handlerSkuInfoClick = (skuId) => {
        dispatch(skuActions.open(skuId));
    };
    if ((task === null || task === void 0 ? void 0 : task.details) && task.details.length > 0) {
        return React.createElement(MovementDetailNomenclatureItem, { details: task.details, warehouseId: task.warehouse.id, onSkuInfoClick: handlerSkuInfoClick });
    }
    return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: React.createElement(React.Fragment, null,
            React.createElement(Title, { level: 5 }, "\u0417\u0430\u0434\u0430\u0447\u0430 \u043D\u0435 \u043D\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0430."),
            React.createElement(Text, null, "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440\u0443.")) }));
};
export default MovementDetailNomenclatureContainer;
