import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskPartitionAddOriginaDocument = gql `
    mutation TaskPartitionAddOrigina($input: TaskPartitionOriginalAddInput!) {
  taskPartitionOriginalAdd(input: $input) {
    taskPartitionViewOriginalDto {
      skuId
    }
  }
}
    `;
/**
 * __useTaskPartitionAddOriginaMutation__
 *
 * To run a mutation, you first call `useTaskPartitionAddOriginaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskPartitionAddOriginaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskPartitionAddOriginaMutation, { data, loading, error }] = useTaskPartitionAddOriginaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskPartitionAddOriginaMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskPartitionAddOriginaDocument, options);
}
export const TaskPartitionEditOriginalDocument = gql `
    mutation TaskPartitionEditOriginal($input: [TaskPartitionOriginalEditInput!]!, $taskId: Long!) {
  taskPartitionOriginalEdit(input: $input, taskId: $taskId) {
    taskPartitionViewOriginalDto {
      skuId
    }
  }
}
    `;
/**
 * __useTaskPartitionEditOriginalMutation__
 *
 * To run a mutation, you first call `useTaskPartitionEditOriginalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskPartitionEditOriginalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskPartitionEditOriginalMutation, { data, loading, error }] = useTaskPartitionEditOriginalMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskPartitionEditOriginalMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskPartitionEditOriginalDocument, options);
}
export const TaskPartitionDeleteOriginalDocument = gql `
    mutation TaskPartitionDeleteOriginal($input: TaskPartitionOriginalDeleteInput!) {
  taskPartitionOriginalDelete(input: $input) {
    taskPartitionViewOriginalDto {
      skuId
    }
  }
}
    `;
/**
 * __useTaskPartitionDeleteOriginalMutation__
 *
 * To run a mutation, you first call `useTaskPartitionDeleteOriginalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskPartitionDeleteOriginalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskPartitionDeleteOriginalMutation, { data, loading, error }] = useTaskPartitionDeleteOriginalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskPartitionDeleteOriginalMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskPartitionDeleteOriginalDocument, options);
}
export const TaskPartitionChangeStatusDocument = gql `
    mutation TaskPartitionChangeStatus($input: TaskPartitionChangeStatusInput!) {
  taskPartitionChangeStatus(input: $input) {
    taskStatusDto {
      isFinal
    }
  }
}
    `;
/**
 * __useTaskPartitionChangeStatusMutation__
 *
 * To run a mutation, you first call `useTaskPartitionChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskPartitionChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskPartitionChangeStatusMutation, { data, loading, error }] = useTaskPartitionChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskPartitionChangeStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskPartitionChangeStatusDocument, options);
}
export const TaskPartitionAddCreatedDocument = gql `
    mutation TaskPartitionAddCreated($input: [TaskPartitionCreatedAddInput!]!, $taskId: Long!) {
  taskPartitionCreatedAdd(input: $input, taskId: $taskId) {
    taskPartitionViewOriginalDto {
      id
    }
  }
}
    `;
/**
 * __useTaskPartitionAddCreatedMutation__
 *
 * To run a mutation, you first call `useTaskPartitionAddCreatedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskPartitionAddCreatedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskPartitionAddCreatedMutation, { data, loading, error }] = useTaskPartitionAddCreatedMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskPartitionAddCreatedMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskPartitionAddCreatedDocument, options);
}
export const TaskPartitionEditCreatedDocument = gql `
    mutation TaskPartitionEditCreated($input: [TaskPartitionCreatedEditInput!]!, $taskId: Long!) {
  taskPartitionCreatedEdit(input: $input, taskId: $taskId) {
    taskPartitionViewOriginalDto {
      id
    }
  }
}
    `;
/**
 * __useTaskPartitionEditCreatedMutation__
 *
 * To run a mutation, you first call `useTaskPartitionEditCreatedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskPartitionEditCreatedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskPartitionEditCreatedMutation, { data, loading, error }] = useTaskPartitionEditCreatedMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskPartitionEditCreatedMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskPartitionEditCreatedDocument, options);
}
export const TaskPartitionDeleteCreatedDocument = gql `
    mutation TaskPartitionDeleteCreated($input: TaskPartitionCreatedDeleteInput!) {
  taskPartitionCreatedDelete(input: $input) {
    taskPartitionViewOriginalDto {
      id
    }
  }
}
    `;
/**
 * __useTaskPartitionDeleteCreatedMutation__
 *
 * To run a mutation, you first call `useTaskPartitionDeleteCreatedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskPartitionDeleteCreatedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskPartitionDeleteCreatedMutation, { data, loading, error }] = useTaskPartitionDeleteCreatedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskPartitionDeleteCreatedMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskPartitionDeleteCreatedDocument, options);
}
export const TaskPartitionAddDocument = gql `
    mutation TaskPartitionAdd($input: TaskPartitionAddInput!) {
  taskPartitionAdd(input: $input) {
    taskPartitionViewDto {
      id
    }
  }
}
    `;
/**
 * __useTaskPartitionAddMutation__
 *
 * To run a mutation, you first call `useTaskPartitionAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskPartitionAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskPartitionAddMutation, { data, loading, error }] = useTaskPartitionAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskPartitionAddMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskPartitionAddDocument, options);
}
