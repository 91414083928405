import { Empty, Spin, Typography } from 'antd';
import React from 'react';
import Centered from '../../../../components/ui/Centered';
import Scope from '../../../../constants/enum/Scope';
import { taskHelpers } from '../../../../entities/tasks/BaseTaskType';
import PartitionDetailNomenclatureItemContainer from './PartitionDetailNomenclatureItemContainer';
const { Title, Text } = Typography;
const PartitionTaskNomenclatureContainer = (props) => {
    const { isLoading, task, refetch } = props;
    if (isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, null)));
    }
    if ((task === null || task === void 0 ? void 0 : task.originals) && task.originals.length > 0) {
        return (React.createElement(React.Fragment, null, task.originals.map((nomenclature) => (React.createElement(PartitionDetailNomenclatureItemContainer, { refetch: refetch, nomenclature: nomenclature, produceWork: taskHelpers.hasScope(task, Scope.PRODUCE_WORK), taskId: task.id, warehouseId: task.warehouse.id })))));
    }
    return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: (React.createElement(React.Fragment, null,
            React.createElement(Title, { level: 5 }, "\u0417\u0430\u0434\u0430\u0447\u0430 \u043D\u0435 \u043D\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0430."),
            React.createElement(Text, null, "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440\u0443."))) }));
};
export default PartitionTaskNomenclatureContainer;
