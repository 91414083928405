export const handbookValueAdapter = (dto) => {
    const { id, title } = dto;
    return {
        id,
        title,
    };
};
export const handbookAdapter = (dto, categoryId) => {
    const { id, title, description, handbookCategoryId, } = dto;
    return {
        id,
        title,
        description,
        handbookCategoryId: handbookCategoryId !== null && handbookCategoryId !== void 0 ? handbookCategoryId : categoryId,
    };
};
export const handbookCategoryAdapter = (dto) => {
    const { id, title, parentId, handbooks, childrens, } = dto;
    return {
        id,
        title,
        parentId,
        handbooks: handbooks ? handbooks.map(handbookAdapter) : [],
        childrens: childrens ? childrens.map(handbookCategoryAdapter) : [],
    };
};
export const handbookUnitAdapter = (dto) => {
    const { id, title, okeiCode, alfaInternational, international, alfaNational, national, } = dto;
    return {
        id, title, okeiCode, alfaInternational, international, alfaNational, national,
    };
};
export const handbookPackingSetAdapter = (dto) => {
    const { id, title } = dto;
    return {
        id: id,
        title: title !== null && title !== void 0 ? title : '',
    };
};
