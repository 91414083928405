var ProductDataType;
(function (ProductDataType) {
    ProductDataType["HANDBOOK"] = "Handbook";
    ProductDataType["INTEGER"] = "Integer";
    ProductDataType["FLOAT"] = "Float";
    ProductDataType["VARCHAR"] = "Varchar";
    ProductDataType["BOOLEAN"] = "Boolean";
    ProductDataType["DATE"] = "Date";
    ProductDataType["DATE_TIME"] = "DateTime";
    ProductDataType["FORMULA"] = "Formula";
    ProductDataType["COLOR"] = "Color";
})(ProductDataType || (ProductDataType = {}));
export default ProductDataType;
