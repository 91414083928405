import { Button, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
const CommentsEditor = (props) => {
    const { value, onSubmit, submitting, onChange, } = props;
    return (React.createElement(Form, null,
        React.createElement(Form.Item, null,
            React.createElement(TextArea, { style: { resize: 'none' }, onChange: onChange, value: value, autoSize: { minRows: 4 }, placeholder: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0442\u0435\u043A\u0441\u0442 \u043A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u044F" })),
        React.createElement(Form.Item, { style: { marginBottom: 0 } },
            React.createElement(Button, { htmlType: "submit", loading: submitting, onClick: onSubmit, style: { width: "100%" }, type: "primary" }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439"))));
};
export default CommentsEditor;
