var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useProductTypeAutomationConditionCreateMutation, useProductTypeAutomationConditionDeleteMutation, useProductTypeAutomationConditionEditMutation } from "../../../../../../api/graphql/queries/automatization/AutomatizationMutation";
export const useProductAutomatizationConditionPage = () => {
    const [deleteProductAutomatizationCondition, { loading: deleteLoading }] = useProductTypeAutomationConditionDeleteMutation();
    const [createProductAutomatizationCondition, { loading: createLoading }] = useProductTypeAutomationConditionCreateMutation();
    const [editProductAutomatizationCondition, { loading: editLoading }] = useProductTypeAutomationConditionEditMutation();
    const handleDelete = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteProductAutomatizationCondition({ variables: { input } });
    });
    const handleCreate = (input) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield createProductAutomatizationCondition({ variables: { input } });
        return res;
    });
    const handleEdit = (input) => __awaiter(void 0, void 0, void 0, function* () {
        yield editProductAutomatizationCondition({ variables: { input } });
    });
    return {
        deleteProductAutomatizationExpression: {
            handleAction: handleDelete,
            isLoading: deleteLoading,
        },
        editProductAutomatizationExpression: {
            handleAction: handleEdit,
            isLoading: editLoading,
        },
        createProductAutomatizationExpression: {
            handleAction: handleCreate,
            isLoading: createLoading,
        },
    };
};
