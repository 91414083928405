var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Form, Input } from 'antd';
const HandbookTableCell = (props) => {
    const { editing, dataIndex, children } = props, restProps = __rest(props, ["editing", "dataIndex", "children"]);
    const renderEditableField = () => {
        if (editing) {
            return (React.createElement(Form.Item, { name: dataIndex, style: { margin: 0 }, rules: [{ required: true, message: 'Заполните поле' }] },
                React.createElement(Input, null)));
        }
        return children;
    };
    return (React.createElement("td", Object.assign({}, restProps),
        React.createElement("span", { style: { whiteSpace: 'nowrap' } }, renderEditableField())));
};
export default HandbookTableCell;
