import { Form, Modal } from 'antd';
import React, { useEffect } from 'react';
import ProductCategoryForm from '../../components/ProductCategoryForm/ProductCategoryForm';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { productActions, selectProductCategories } from '../../redux/reducers/productReducer';
import productCategoryApi from '../../redux/api/productApi/productCategory';
const { useAddProductCategoryMutation, useEditProductCategoryMutation, } = productCategoryApi;
const ProductCategoryAddModal = () => {
    var _a;
    const [form] = Form.useForm();
    const { modal } = useTypedSelector(selectProductCategories);
    const dispatch = useAppDispatch();
    const [addProductCategory, { isLoading: addIsLoading }] = useAddProductCategoryMutation();
    const [editProductCategory, { isLoading: editIsLoading }] = useEditProductCategoryMutation();
    const isLoading = addIsLoading || editIsLoading;
    useEffect(() => {
        if (modal.visible) {
            setTimeout(() => {
                form.resetFields();
            }, 0);
        }
    }, [modal.visible, modal.initialValues]);
    const handleOk = () => form.submit();
    const handleCancel = () => {
        form.resetFields();
        dispatch(productActions.changeCategoryModalVisible({ visible: false }));
    };
    const handlerSubmit = (values) => {
        if (values.id === undefined) {
            addProductCategory({
                parentId: parseInt(values.parentId.value, 10),
                title: values.title,
            })
                .unwrap()
                .then(() => handleCancel());
        }
        else {
            editProductCategory({
                productCategoryId: values.id,
                values: {
                    parentId: parseInt(values.parentId.value, 10),
                    title: values.title,
                },
            })
                .unwrap()
                .then(() => handleCancel());
        }
    };
    return (React.createElement(Modal, { open: modal.visible, confirmLoading: isLoading, onOk: handleOk, onCancel: handleCancel, title: `${((_a = modal.initialValues) === null || _a === void 0 ? void 0 : _a.id) === undefined ? 'Добавить' : 'Редактировать'} категорию`, okText: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", cancelText: "\u041E\u0442\u043C\u0435\u043D\u0430" },
        React.createElement(ProductCategoryForm, { form: form, onSubmit: handlerSubmit, initialValues: modal.initialValues })));
};
export default ProductCategoryAddModal;
