import React, { useMemo } from 'react';
import { Card, Col, Row } from 'antd';
import TaskInfo from './TaskInfo';
import TaskItemFooter from './TaskItemFooter';
import TaskNomenclature from '../TaskNomenclature';
import Label from '../../ui/Label';
import { toDate } from '../../../helpers/function';
const TaskListItem = (props) => {
    const { task, link, actions, info, nomenclature, } = props;
    const { columns, data: nomenclatureData } = nomenclature;
    const quantityTotal = useMemo(() => {
        var _a;
        return ((_a = task === null || task === void 0 ? void 0 : task.details) === null || _a === void 0 ? void 0 : _a.length)
            ? task === null || task === void 0 ? void 0 : task.details.reduce((acc, task) => {
                return (acc += ~~task.quantity);
            }, 0)
            : null;
    }, [task === null || task === void 0 ? void 0 : task.id]);
    const getStatus = () => {
        var _a, _b;
        const statusProps = {
            text: (_a = task.status) === null || _a === void 0 ? void 0 : _a.title,
            color: (_b = task.status) === null || _b === void 0 ? void 0 : _b.style,
        };
        return React.createElement(Label, Object.assign({}, statusProps));
    };
    const getFooter = () => {
        var _a;
        const footerProps = {
            userName: task.user.userName || '',
            date: (_a = toDate(task.createdAt)) === null || _a === void 0 ? void 0 : _a.toLocaleDateString(),
            link,
        };
        return React.createElement(TaskItemFooter, Object.assign({}, footerProps), actions);
    };
    const getTitle = () => {
        return `#${task.id} ${task.externalId}`;
    };
    return (React.createElement(Card, { key: `task-${task.id}`, title: getTitle(), extra: getStatus() },
        React.createElement(Row, null,
            React.createElement(Col, { flex: "250px" },
                React.createElement(TaskInfo, { items: info })),
            React.createElement(Col, { flex: "auto" },
                React.createElement(TaskNomenclature, { columns: columns, dataSource: nomenclatureData })))));
};
export default TaskListItem;
