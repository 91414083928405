var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import SearchSelect from '../SearchSelect';
import { getWarehouseList } from '../../api/rest/requests/warehouse/warehouse';
const request = (page, pageSize, query, setLoadingState) => __awaiter(void 0, void 0, void 0, function* () {
    return (getWarehouseList(page.toString(), pageSize.toString(), query, setLoadingState));
});
const WarehouseSelect = (props) => (React.createElement(SearchSelect, Object.assign({}, props, { request: request, dataToOption: ({ id, storageTitle }) => ({ value: id, label: storageTitle }) })));
export default WarehouseSelect;
