import { Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import ProductCategorySelect from "../ProductCategorySelect";
import ProductTypeMaskInput from "../ProductTypeMaskInput";
import FormulaMaskInputContainer from "../FormulaMaskInput";
var FormNames;
(function (FormNames) {
    FormNames["TITLE"] = "title";
    FormNames["TITLE_MASK"] = "titleMask";
    FormNames["DESCRIPTION"] = "description";
    FormNames["PARENT_ID"] = "productCategoryId";
    FormNames["SIZE_FORMULA"] = "sizeFormula";
})(FormNames || (FormNames = {}));
const ProductTypeForm = (props) => {
    const { form, onSubmit, initialValues, maskOptions } = props;
    console.log("initialValues", initialValues);
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);
    const defaultFormula = initialValues === null || initialValues === void 0 ? void 0 : initialValues[FormNames.SIZE_FORMULA];
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues },
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.TITLE, label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435", rules: [{ required: true, message: "Поле не заполнено" }] },
                    React.createElement(Input, null)),
                React.createElement(Form.Item, { name: FormNames.DESCRIPTION, label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435", rules: [{ required: true, message: "Поле не заполнено" }] },
                    React.createElement(Input.TextArea, null)),
                React.createElement(Form.Item, { name: FormNames.SIZE_FORMULA, label: "\u0424\u043E\u0440\u043C\u0443\u043B\u0430 \u0440\u0430\u0437\u043C\u0435\u0440\u0430", tooltip: 'Вветите символ "[" чтобы появилась подсказка с вариантами' },
                    React.createElement(FormulaMaskInputContainer, { initValue: defaultFormula, isPersonalUpdated: false, isPersonal: true }))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.PARENT_ID, label: "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F" },
                    React.createElement(ProductCategorySelect, { disabled: true })),
                React.createElement(Form.Item, { name: FormNames.TITLE_MASK, label: "\u041C\u0430\u0441\u043A\u0430", rules: [{ required: true, message: "Поле не заполнено" }], tooltip: 'Вветите символ "{" чтобы появилась подсказка с вариантами' },
                    React.createElement(ProductTypeMaskInput, { attributes: maskOptions, onChange: (val) => form.setFieldValue(FormNames.TITLE_MASK, val) }))))));
};
export default ProductTypeForm;
