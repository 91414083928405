export var RowType;
(function (RowType) {
    RowType["After"] = "After";
    RowType["Before"] = "Before";
})(RowType || (RowType = {}));
export var DataIndexType;
(function (DataIndexType) {
    DataIndexType["StorageCell"] = "storageCell";
    DataIndexType["Reason"] = "reason";
    DataIndexType["Quantity"] = "quantity";
    DataIndexType["Product"] = "product";
    DataIndexType["ToleranceType"] = "toleranceType";
})(DataIndexType || (DataIndexType = {}));
