import React from 'react';
import ProductInfoDrawer from '../../components/ProductInfoDrawer';
import { useProductDrawer } from '../../hooks/useProductDrawer';
import productApi from '../../redux/api/productApi';
const { useFetchProductQuery } = productApi;
const ProductInfoDrawerContainer = () => {
    const { productId, closeProductDrawer } = useProductDrawer();
    const { data: product, isLoading, } = useFetchProductQuery(productId, { skip: !Number.isInteger(productId) });
    const handlerClose = () => {
        closeProductDrawer();
    };
    return (React.createElement(ProductInfoDrawer, { product: product, isOpen: !!productId, loading: isLoading, onClose: handlerClose }));
};
export default ProductInfoDrawerContainer;
