import { getColumnType } from "../../../../../../../helpers/function";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";
export const createColumns = (onDelete, isLoading, isLocked) => [
    getColumnType("number", "#", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("title", "Наименование", true, {
        fixed: "left",
    }),
    getColumnType("address", "Адрес", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("skuCount", "Кол-во SKU шт.", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("", "", true, {
        fixed: "left",
        width: "80px",
        render: (record) => (React.createElement(Button, { type: "primary", danger: true, size: "small", disabled: isLoading || isLocked, icon: React.createElement(CloseOutlined, null), onClick: () => {
                onDelete(record.number);
            } })),
    }),
];
export const emptyColumns = [
    getColumnType("number", "#", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("title", "Наименование", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("address", "Адрес", true, {
        fixed: "left",
        width: "80px",
    }),
    getColumnType("skuCount", "Кол-во SKU шт.", true, {
        fixed: "left",
        width: "80px",
    }),
];
