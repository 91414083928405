import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PaginationPanel from '../../components/PaginationPanel';
import { usePagination } from '../../hooks/usePagination';
import handbookValueApi from '../../redux/api/handbookApi/handbookValue';
import { HANDBOOK_PARAM } from '../pages/HandbookPage';
const { useFetchValueListQuery } = handbookValueApi;
const HandbookPaginationContainer = () => {
    const [searchParams] = useSearchParams();
    const handbookParam = searchParams.get(HANDBOOK_PARAM);
    const handbookId = handbookParam ? +handbookParam : null;
    const { page, pageSize, setPagination } = usePagination();
    const { data, isLoading } = useFetchValueListQuery({
        handbookId: handbookId, page, pageSize,
    }, { skip: !Number.isInteger(handbookId) });
    const handlerChange = (nextPage, nextPageSize) => {
        setPagination({
            page: nextPage,
            pageSize: nextPageSize,
        });
    };
    if (data) {
        return (React.createElement(PaginationPanel, { pagination: data.pagination, disabled: isLoading || !handbookId, onChange: handlerChange }));
    }
    return null;
};
export default HandbookPaginationContainer;
