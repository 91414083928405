var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWarehouseMapStorageCellLazyQuery } from "../../../../api/graphql/queries/warehouseMap/WarehouseMapQuery";
import { isValidId } from "../helpers";
export const useCellData = (dispatch, warehouseId) => {
    const [getWarehouseMapStorageCell] = useWarehouseMapStorageCellLazyQuery({ fetchPolicy: "no-cache" });
    const { id } = useParams();
    useEffect(() => {
        if (isValidId(id) || warehouseId) {
            const numericId = warehouseId !== null && warehouseId !== void 0 ? warehouseId : Number(id);
            fetchWarehouseMapStorageCell(dispatch, getWarehouseMapStorageCell, numericId);
        }
    }, []);
};
const fetchWarehouseMapStorageCell = (dispatch, warehouseMapStorageCellQuery, warehouseId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    try {
        const response = yield warehouseMapStorageCellQuery({
            variables: { warehouseId },
        });
        if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.warehouseMapStorageCells) {
            dispatch({ type: "FETCH_CELLS_SUCCESS", payload: (_b = response.data) === null || _b === void 0 ? void 0 : _b.warehouseMapStorageCells });
            dispatch({ type: "FETCH_CELLS_DONE" });
        }
        return (_c = response.data) === null || _c === void 0 ? void 0 : _c.warehouseMapStorageCells;
    }
    catch (error) {
        dispatch({ type: "FETCH_FAILURE" });
    }
});
