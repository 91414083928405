import React, { useEffect } from "react";
import StatusHistory from "../../../../components/StatusHistory";
import { useAdapters } from "../../../../hooks/useAdapters";
import { useTaskChangeWarehouse } from "../../../../hooks/api/taskChangeWarehouseApi/useTaskChangeWarehouse";
const ChangeWarehouseStatusHistoryContainer = (props) => {
    var _a;
    const { taskId } = props;
    const { history } = useTaskChangeWarehouse();
    const { normalizeHistory } = useAdapters();
    useEffect(() => {
        history.getHistory({ variables: { taskId: taskId } });
    }, []);
    return history.data ? (React.createElement(StatusHistory, { history: (_a = normalizeHistory(history.data)) !== null && _a !== void 0 ? _a : [], isLoading: history.loading })) : (React.createElement(React.Fragment, null));
};
export default ChangeWarehouseStatusHistoryContainer;
