import React from 'react';
import classNames from 'classnames';
const NavigationPane = (props) => {
    const { title, index, isActive, children, } = props;
    const classes = {
        'tab-pane': true,
        show: isActive,
    };
    return (React.createElement("div", { className: classNames(classes), key: `tab-pane-${index}` },
        React.createElement("div", { className: "tab-pane-header" }, title),
        React.createElement("div", { className: "tab-pane-content" }, children)));
};
export default NavigationPane;
