import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const HandbookPackingSetCreateDocument = gql `
    mutation HandbookPackingSetCreate($input: HandbookPackingSetCreateInput!) {
  handbookPackingSetCreate(input: $input) {
    handbookPackingSetDto {
      id
      title
    }
  }
}
    `;
/**
 * __useHandbookPackingSetCreateMutation__
 *
 * To run a mutation, you first call `useHandbookPackingSetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandbookPackingSetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handbookPackingSetCreateMutation, { data, loading, error }] = useHandbookPackingSetCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandbookPackingSetCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(HandbookPackingSetCreateDocument, options);
}
export const HandbookPackingSetEditDocument = gql `
    mutation HandbookPackingSetEdit($input: HandbookPackingSetEditInput!) {
  handbookPackingSetEdit(input: $input) {
    handbookPackingSetDto {
      id
      title
    }
  }
}
    `;
/**
 * __useHandbookPackingSetEditMutation__
 *
 * To run a mutation, you first call `useHandbookPackingSetEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandbookPackingSetEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handbookPackingSetEditMutation, { data, loading, error }] = useHandbookPackingSetEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandbookPackingSetEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(HandbookPackingSetEditDocument, options);
}
export const HandbookPackingSetDeleteDocument = gql `
    mutation HandbookPackingSetDelete($input: HandbookPackingSetDeleteInput!) {
  handbookPackingSetDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useHandbookPackingSetDeleteMutation__
 *
 * To run a mutation, you first call `useHandbookPackingSetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandbookPackingSetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handbookPackingSetDeleteMutation, { data, loading, error }] = useHandbookPackingSetDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandbookPackingSetDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(HandbookPackingSetDeleteDocument, options);
}
export const HandbookPackingDeleteDocument = gql `
    mutation HandbookPackingDelete($input: HandbookPackingDeleteInput!) {
  handbookPackingDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useHandbookPackingDeleteMutation__
 *
 * To run a mutation, you first call `useHandbookPackingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandbookPackingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handbookPackingDeleteMutation, { data, loading, error }] = useHandbookPackingDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandbookPackingDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(HandbookPackingDeleteDocument, options);
}
export const HandbookPackingCreateDocument = gql `
    mutation HandbookPackingCreate($input: HandbookPackingCreateInput!) {
  handbookPackingCreate(input: $input) {
    handbookPackingDto {
      id
    }
  }
}
    `;
/**
 * __useHandbookPackingCreateMutation__
 *
 * To run a mutation, you first call `useHandbookPackingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandbookPackingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handbookPackingCreateMutation, { data, loading, error }] = useHandbookPackingCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandbookPackingCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(HandbookPackingCreateDocument, options);
}
export const HandbookPackingEditDocument = gql `
    mutation HandbookPackingEdit($input: HandbookPackingEditInput!) {
  handbookPackingEdit(input: $input) {
    handbookPackingDto {
      id
      title
    }
  }
}
    `;
/**
 * __useHandbookPackingEditMutation__
 *
 * To run a mutation, you first call `useHandbookPackingEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandbookPackingEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handbookPackingEditMutation, { data, loading, error }] = useHandbookPackingEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandbookPackingEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(HandbookPackingEditDocument, options);
}
