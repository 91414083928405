import React, { useMemo } from "react";
import Page from "../../components/Page";
import AccountsGroupsContainer from "../containers/entity/AccountsGroups/AccountsGroupsContainer";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Card, Typography } from "antd";
const AccountsGroupsPage = () => {
    const user = useTypedSelector((state) => state.user);
    const userRoles = useMemo(() => {
        var _a;
        return ((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.role) || [];
    }, [JSON.stringify(user)]);
    return (React.createElement(Page, null, userRoles.includes("VITMWMSAdministrator") ? (React.createElement(AccountsGroupsContainer, null)) : (React.createElement(Card, null,
        React.createElement(Typography.Text, null, "\u0423 \u0432\u0430\u0441 \u043D\u0435 \u0445\u0432\u0430\u0442\u0430\u0435\u0442 \u043F\u0440\u0430\u0432 \u0434\u043B\u044F \u0434\u043E\u0441\u0442\u0443\u043F\u0430 \u043A \u0440\u0430\u0437\u0434\u0435\u043B\u0443")))));
};
export default AccountsGroupsPage;
