import { Card } from "antd";
import React, { useContext } from "react";
import Page from "../Page";
import { WarehouseTopology, WarehouseTopologyContext } from "./WarehouseTopology";
import WarehouseSizeModal from "./components/WarehouseSizeModal";
export const MapWithThree = () => {
    const context = useContext(WarehouseTopologyContext);
    if (context === undefined) {
        throw new Error("Map must be used within a WarehouseTopology");
    }
    return (React.createElement(Page, { toolbarLeft: WarehouseTopology.Three && React.createElement(WarehouseTopology.Three, null) },
        !context.state.isWarehouseLoading && !context.state.warehouseShape && React.createElement(WarehouseSizeModal, { warehouseId: context.warehouseId, dispatch: context.dispatch }),
        React.createElement(Card, { style: { width: "calc(100% + 30px)", height: "calc(100% + 30px)", margin: "-15px", overflow: "auto" }, ref: context.setCardRef },
            React.createElement("div", { style: { position: "relative" } },
                React.createElement("div", { ref: context.setGaphRef }),
                React.createElement("div", { ref: context.setMinimapContainerRef, style: {
                        position: "absolute",
                        right: 0,
                        top: 0,
                        width: "200px",
                        height: "100px",
                        backgroundColor: "#f0f0f0",
                    } })))));
};
