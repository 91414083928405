import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskLoadingListDocument = gql `
    query TaskLoadingList($page: Int!, $pageSize: Int!, $filter: TaskFilterRequestInput!) {
  taskLoadingList(page: $page, pageSize: $pageSize, filter: $filter) {
    data {
      id
      externalId
      createdAt
      updatedAt
      executedAt
      details {
        count
        dimension
        productId
        quantity
        type
        title
      }
      keeper {
        companyName
        companyInn
        id
      }
      owner {
        companyName
        companyInn
        id
      }
      warehouse {
        id
        storageTitle
      }
      user {
        userName
      }
      status {
        title
        style
        scope
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useTaskLoadingListQuery__
 *
 * To run a query within a React component, call `useTaskLoadingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskLoadingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskLoadingListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskLoadingListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskLoadingListDocument, options);
}
export function useTaskLoadingListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskLoadingListDocument, options);
}
export function useTaskLoadingListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskLoadingListDocument, options);
}
export const TaskLoadingDocument = gql `
    query TaskLoading($taskId: Long!) {
  taskLoading(taskId: $taskId) {
    id
    externalId
    createdAt
    updatedAt
    executedAt
    keeper {
      companyName
      companyInn
      id
    }
    shipper {
      companyName
      companyInn
      id
    }
    driverName
    vehicleNumber
    ttnNumber
    ttnDate
    owner {
      companyName
      companyInn
      id
    }
    details {
      skus {
        skuId
        taskDetailId
        quantity
        hasDefect
        defect
        storageCell {
          id
          title
          address
        }
        skuAttributeValues {
          key
          value {
            value
            valueReadable
            id
          }
        }
        status {
          title
          style
        }
      }
      attributes {
        canBeSummarized
        dataType
        handbookId
        id
        isPersonal
        isReadOnly
        isRequired
        title
        unit
      }
      count
      dimension
      productId
      quantity
      type
      title
    }
    warehouse {
      id
      storageTitle
    }
    user {
      userName
    }
    status {
      title
      style
      scope
      relations {
        id
        title
        style
        needReport
      }
    }
  }
}
    `;
/**
 * __useTaskLoadingQuery__
 *
 * To run a query within a React component, call `useTaskLoadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskLoadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskLoadingQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskLoadingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskLoadingDocument, options);
}
export function useTaskLoadingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskLoadingDocument, options);
}
export function useTaskLoadingSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskLoadingDocument, options);
}
export const GetTaskLoadingHistoryDocument = gql `
    query getTaskLoadingHistory($taskId: Long!) {
  taskLoadingHistory(taskId: $taskId) {
    createdAt
    isEntryPoint
    needReport
    report
    statusFrom {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    statusTo {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    user {
      uid
      email
      userName
      id
    }
  }
}
    `;
/**
 * __useGetTaskLoadingHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskLoadingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskLoadingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskLoadingHistoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskLoadingHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTaskLoadingHistoryDocument, options);
}
export function useGetTaskLoadingHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTaskLoadingHistoryDocument, options);
}
export function useGetTaskLoadingHistorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetTaskLoadingHistoryDocument, options);
}
