import { debounce } from 'lodash';
import { Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
const DEBOUNCE_TIMEOUT = 100;
function ReportTable(props) {
    const { isLoading, data, rowKeyGenerator, columns, onLoad, } = props;
    const [tableHeight, setTableHeight] = useState(0);
    const wrapper = useRef(null);
    const updateDimensions = debounce(() => {
        var _a;
        const antTableHeader = (_a = wrapper.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ant-table-header');
        const wrapperHeight = wrapper.current ? wrapper.current.clientHeight : 0;
        const headerHeight = antTableHeader ? antTableHeader.clientHeight : 0;
        setTableHeight(Math.floor(wrapperHeight - headerHeight) - 1);
    }, DEBOUNCE_TIMEOUT);
    useEffect(() => {
        if (onLoad)
            onLoad();
    }, []);
    useEffect(() => {
        updateDimensions();
        window.addEventListener('load', updateDimensions);
        window.addEventListener('resize', updateDimensions);
        window.addEventListener('orientationchange', updateDimensions);
        return () => {
            window.removeEventListener('load', updateDimensions);
            window.removeEventListener('resize', updateDimensions);
            window.removeEventListener('orientationchange', updateDimensions);
        };
    }, []);
    return (React.createElement("div", { className: "wms-table-full", id: "wms-table-full", ref: wrapper },
        React.createElement(Table, { tableLayout: "fixed", bordered: true, size: "small", loading: isLoading, rowKey: rowKeyGenerator, columns: columns, pagination: false, dataSource: data, scroll: { x: true, y: tableHeight } })));
}
export default ReportTable;
