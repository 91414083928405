import { Form } from "antd";
import React, { useState } from "react";
import ProductSearchModal from "../../../../../components/nomenclatureSearch/product/ProductSearchModal";
import CorrectionDetailNomenclatureFormItem from "./CorrectionDetailNomenclatureFormItem";
export const FormContext = React.createContext(null);
export const useFormContext = () => React.useContext(FormContext);
export const CorrectionDetailNomenclatureFormItemContextContainer = (props) => {
    const [form] = Form.useForm();
    const [productModalIsOpen, setProductModalIsOpen] = useState(false);
    const [productType, setProductType] = useState(undefined);
    const [afterProductType, setAfterProductType] = useState(undefined);
    const [activeProductTypeTitle, setActiveProductTypeTitle] = useState("");
    const handlerAddProduct = (values) => {
        if (values === null || values === void 0 ? void 0 : values.length) {
            setAfterProductType({ label: values[0].titleCompiled, value: values[0].id });
            setActiveProductTypeTitle(values[0].titleCompiled || "");
        }
    };
    const handelOpenProductModal = (label, value) => {
        setProductType({ label, value });
        setProductModalIsOpen(true);
    };
    return (React.createElement(FormContext.Provider, { value: { form, afterProductType, activeProductTypeTitle, setActiveProductTypeTitle, handelOpenProductModal } },
        React.createElement(CorrectionDetailNomenclatureFormItem, Object.assign({}, props)),
        React.createElement(ProductSearchModal, { productType: productType, isMultiple: false, onAdd: handlerAddProduct, visible: productModalIsOpen, setModelVisible: setProductModalIsOpen })));
};
