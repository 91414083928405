import { Card, Col, Divider, Row, Typography, } from 'antd';
import React from 'react';
import Page from '../../../components/Page';
import ProductTypeAddFormContainer from '../../containers/ProductTypeAddFormContainer';
const { Title } = Typography;
const ProductAddTypePage = () => (React.createElement(Page, null,
    React.createElement(Row, null,
        React.createElement(Col, { span: 24 },
            React.createElement(Card, null,
                React.createElement(Title, { level: 3 }, "\u0422\u0438\u043F \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438"),
                React.createElement(Divider, null),
                React.createElement(ProductTypeAddFormContainer, null))))));
export default ProductAddTypePage;
