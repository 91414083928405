import React, { useEffect, useMemo, useRef, } from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import L from 'leaflet';
// @ts-ignore
import icon from 'leaflet/dist/images/marker-icon.png';
// @ts-ignore
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, } from 'react-leaflet';
const SearchControl = (props) => {
    const { onSearch } = props;
    const map = useMap();
    useEffect(() => {
        const searchControl = GeoSearchControl({
            provider: new OpenStreetMapProvider(),
            style: 'bar',
            showMarker: false,
            showPopup: false,
            autoClose: true,
            retainZoomLevel: false,
            animateZoom: true,
            keepResult: false,
            searchLabel: 'Введите адрес',
        });
        map.on('geosearch/showlocation', (args) => {
            const { location } = args;
            const { x, y, label } = location;
            const addressArray = label.split(', ').reverse();
            onSearch(y, x, addressArray);
        });
        map.addControl(searchControl);
        return () => {
            map.removeControl(searchControl);
        };
    }, []);
    return null;
};
const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 40],
});
L.Marker.prototype.options.icon = DefaultIcon;
const MapEvents = (props) => {
    const { onClick, onSearch, coords, zoom, } = props;
    const map = useMap();
    useEffect(() => {
        map.setView(coords, zoom);
    }, [coords]);
    const setMarker = (values, zoomVal) => {
        const z = zoomVal !== undefined ? zoomVal : map.getZoom();
        onClick(values);
        map.setView(values, z);
    };
    const handlerSearch = (lat, lng, address) => {
        setMarker({ lng, lat });
        onSearch(address);
    };
    useMapEvents({
        click: (event) => {
            setMarker(event.latlng);
        },
        locationfound: (location) => {
            setMarker(location.latlng, zoom);
        },
    });
    return (React.createElement(SearchControl, { onSearch: handlerSearch }));
};
const MapView = (props) => {
    const { coords, zoom, title, onClick, onSearch, map, } = props;
    const mapRef = useRef(null);
    useEffect(() => {
        if (mapRef.current)
            map(mapRef.current);
    }, [mapRef.current]);
    useEffect(() => {
        var _a;
        if (coords === null) {
            (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.locate();
        }
    }, [coords]);
    const handlerMarkerClick = () => {
        var _a;
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a._popup._closeButton.addEventListener('click', (event) => {
            event.preventDefault();
        });
    };
    const displayMap = useMemo(() => (React.createElement(MapContainer, { center: coords !== null && coords !== void 0 ? coords : { lng: 0, lat: 0 }, zoom: zoom, ref: mapRef },
        React.createElement(TileLayer, { attribution: '\u00A9 <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors', url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" }),
        coords ? (React.createElement(Marker, { position: [coords.lat, coords.lng], eventHandlers: {
                click: () => handlerMarkerClick(),
            } },
            React.createElement(Popup, null, title !== null && title !== void 0 ? title : 'Нет заголовка'))) : null,
        React.createElement(MapEvents, { zoom: zoom, coords: coords !== null && coords !== void 0 ? coords : { lng: 0, lat: 0 }, onClick: onClick, onSearch: onSearch }))), [coords, zoom, title]);
    return (displayMap);
};
export default MapView;
