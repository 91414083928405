import React from 'react';
import pkg from '../../../package.json';
const Footer = () => (React.createElement("div", { className: "app-footer" },
    "VITM - WMS \u00A9 ",
    new Date().getFullYear(),
    " | ",
    [pkg.version, process.env.CI_PIPELINE_IID, process.env.CI_COMMIT_REF_SLUG].join('.'),
    React.createElement("br", null),
    "Powered by ",
    React.createElement("a", { href: "https://varrant-itm.com", target: "_blank", rel: "noopener noreferrer" }, "Varrant IT Management")));
export default Footer;
