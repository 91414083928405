var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import SearchSelect from '../SearchSelect/SearchSelect';
import { getTimezoneList } from '../../api/rest/requests/system';
const request = (query, setLoadingState) => __awaiter(void 0, void 0, void 0, function* () {
    return (getTimezoneList(query, setLoadingState));
});
const TimeZoneSelect = (props) => (React.createElement(SearchSelect, Object.assign({}, props, { paginated: false, request: (query, setLoadingState) => request(query, setLoadingState), dataToOption: ({ id, displayName }) => ({
        value: id !== null && id !== void 0 ? id : '',
        label: displayName,
    }) })));
export default TimeZoneSelect;
