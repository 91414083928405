import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import ShippingTaskEditFormContainer from "../../../containers/task/shipping/ShippingTaskEditFormContainer";
import ShippingTaskFormNomenclatureContainer from "../../../containers/task/shipping/ShippingTaskFormNomenclatureContainer";
import ShippingEditTaskHeaderContainer from "../../../containers/task/shipping/ShippingEditTaskHeaderContainer";
import ProductInfoDrawerContainer from "../../../containers/ProductInfoDrawerContainer";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import { useTaskShipping } from "../../../../hooks/api/taskShippingApi/useTaskShipping";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
export const ShippingContext = React.createContext(null);
const ShippingProvider = ShippingContext.Provider;
const ShippingEditPage = () => {
    const params = useParams();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const { task } = useTaskShipping();
    const isLocked = useMemo(() => taskHelpers.hasExternalId(task === null || task === void 0 ? void 0 : task.data), [task === null || task === void 0 ? void 0 : task.data]);
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: React.createElement(ShippingEditTaskHeaderContainer, { task: task.data, isLoading: task.loading, id: taskId }),
        toolbarRight: null,
    };
    return (React.createElement(ShippingProvider, { value: { isLocked } },
        React.createElement(Page, Object.assign({}, pageProps),
            React.createElement(ShippingTaskEditFormContainer, { task: task.data, refetch: task.refetch, isLoading: task.loading, taskId: taskId, disableWarehouse: true },
                React.createElement(ShippingTaskFormNomenclatureContainer, { refetch: task.refetch, isLoading: task.loading, task: task.data, taskId: taskId })),
            React.createElement(ProductInfoDrawerContainer, null),
            React.createElement(SkuInfoDrawerContainer, null))));
};
export default ShippingEditPage;
