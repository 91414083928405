export const getBgColor = (percent, defaultColor) => {
    if (percent >= 0 && percent < 25) {
        return defaultColor;
    }
    else if (percent >= 25 && percent < 50) {
        return "#D9F7BE";
    }
    else if (percent >= 50 && percent < 75) {
        return "#FFE7BA";
    }
    else if (percent >= 75) {
        return "#FFCCC7";
    }
    else {
        if (defaultColor)
            return defaultColor;
        throw new Error("Invalid size");
    }
};
export const getTextColor = (percent, defaultColor) => {
    if (percent >= 0 && percent < 25) {
        return "#8C8C8C";
    }
    else if (percent >= 25 && percent < 50) {
        return "#73D13D";
    }
    else if (percent >= 50 && percent < 75) {
        return "#FFA940";
    }
    else if (percent >= 75) {
        return "#FF7875";
    }
    else {
        if (defaultColor)
            return defaultColor;
        throw new Error("Invalid size");
    }
};
