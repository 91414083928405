var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Empty, Typography } from "antd";
import React from "react";
import Scope from "../../../../constants/enum/Scope";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useProductDrawer } from "../../../../hooks/useProductDrawer";
import { useTaskLoadingDetails } from "../../../../hooks/api/taskLoadingApi/useTaskLoadingDetails";
import { skuActions } from "../../../../redux/reducers/skuReducer";
import LoadingDetailNomenclatureItem from "../../../conponents/task/loading/LoadingDetailNomenclature/LoadingDetailNomenclatureItem";
import LoadingTaskFormNomenclature from "../../../conponents/task/loading/LoadingTaskFormNomenclature";
const { Title, Text } = Typography;
const LoadingTaskFormNomenclatureContainer = (props) => {
    const { taskId, task, isLoading, refetch } = props;
    const dispatch = useAppDispatch();
    const { taskDetails } = useTaskLoadingDetails();
    const { openProductDrawer } = useProductDrawer();
    const handlerAddSku = (productId) => __awaiter(void 0, void 0, void 0, function* () {
        const value = {
            productId,
            quantity: 0,
            defect: null
        };
        yield taskDetails.create({ variables: { input: { taskId, input: [value] } } });
        yield refetch();
    });
    const handlerAddProducts = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.create({ variables: { input: { taskId, input: values } } });
        yield refetch();
    });
    const handlerDeleteSku = (detailsId) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskDetails.delete({ variables: { input: { taskId, input: [detailsId] } } });
        yield refetch();
    });
    const handlerDuplicate = (productId, skuId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const product = task === null || task === void 0 ? void 0 : task.details.find((productItem) => productItem.productId === productId);
        const sku = (_a = product === null || product === void 0 ? void 0 : product.skus) === null || _a === void 0 ? void 0 : _a.find((skuItem) => skuItem.skuId === skuId);
        const attributeValues = (sku === null || sku === void 0 ? void 0 : sku.skuAttributeValues) || [];
        const value = {
            productId,
            skuAttributeValues: attributeValues.map((el) => ({
                skuAttributeValueId: el.key,
                value: el.value.value,
            })),
            quantity: parseInt(`${sku === null || sku === void 0 ? void 0 : sku.quantity}` || "0", 10),
            defect: null
        };
        yield taskDetails.create({ variables: { input: { taskId, input: [value] } } });
        yield refetch();
    });
    const handlerEdit = (value) => __awaiter(void 0, void 0, void 0, function* () {
        (taskDetails === null || taskDetails === void 0 ? void 0 : taskDetails.edit) && (yield taskDetails.edit({ variables: { input: { taskId, input: [value] } } }));
        yield refetch();
    });
    const handlerOpenInfo = (id) => {
        openProductDrawer(id);
    };
    const handlerOpenSkuInfo = (skuId) => {
        dispatch(skuActions.open(skuId));
    };
    const renderDetails = () => {
        if ((task === null || task === void 0 ? void 0 : task.details) && task.details.length > 0) {
            return (React.createElement(React.Fragment, null, task.details.map((nomenclature) => {
                const { productId, title, type } = nomenclature;
                const key = `${productId} ${type} ${title}`;
                return (React.createElement(LoadingDetailNomenclatureItem, { key: key, quantity: nomenclature.quantity, isLoading: isLoading || taskDetails.loading, nomenclature: nomenclature, warehouseId: task.warehouse.id, editable: taskHelpers.hasScope(task, Scope.DETAILS_EDIT), onAddItem: handlerAddSku, onDeleteItem: handlerDeleteSku, onDuplicateItem: handlerDuplicate, onEditItem: handlerEdit, onInfoClick: handlerOpenInfo, onSkuInfoClick: handlerOpenSkuInfo }));
            })));
        }
        return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: React.createElement(React.Fragment, null,
                React.createElement(Title, { level: 5 }, "\u0417\u0430\u0434\u0430\u0447\u0430 \u043D\u0435 \u043D\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0430."),
                React.createElement(Text, null, "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u043D\u043A\u043B\u0430\u0442\u0443\u0440\u0443.")) }));
    };
    return (React.createElement(LoadingTaskFormNomenclature, { editable: task ? taskHelpers.hasScope(task, Scope.DETAILS_EDIT) : false, onAddSku: handlerAddProducts }, renderDetails()));
};
export default LoadingTaskFormNomenclatureContainer;
