import { ApolloProvider } from "@apollo/client";
import moment from "moment";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";
import store from "./redux/store";
import "@mdi/font/css/materialdesignicons.css";
import "antd/dist/reset.css";
import { AuthProvider } from "react-oidc-context";
import { gqlClient } from "./api/client";
import App from "./core/conponents/App";
import { PrivateRoute } from "./core/containers/AuthContainer";
import "./resources/styles/application.scss";
import { userManagerSettings } from "./services/AuthService";
moment.locale("ru");
const AntConfig = {
    locale: ruRU,
};
const container = document.getElementById("root");
const root = createRoot(container);
root.render(React.createElement(ConfigProvider, Object.assign({}, AntConfig),
    React.createElement(Provider, { store: store },
        React.createElement(BrowserRouter, null,
            React.createElement(AuthProvider, Object.assign({}, userManagerSettings),
                React.createElement(PrivateRoute, null,
                    React.createElement(ApolloProvider, { client: gqlClient },
                        React.createElement(App, null))))))));
