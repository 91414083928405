import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskMoveChangeStatusDocument = gql `
    mutation TaskMoveChangeStatus($input: TaskMovementChangeStatusInput!) {
  taskMovementChangeStatus(input: $input) {
    taskStatusResponse {
      isFinal
      scope
      style
      title
      relations {
        id
        needReport
        style
        title
      }
    }
  }
}
    `;
/**
 * __useTaskMoveChangeStatusMutation__
 *
 * To run a mutation, you first call `useTaskMoveChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskMoveChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskMoveChangeStatusMutation, { data, loading, error }] = useTaskMoveChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskMoveChangeStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskMoveChangeStatusDocument, options);
}
export const TaskMovementDetailsCreateDocument = gql `
    mutation TaskMovementDetailsCreate($input: TaskMovementAddDetailsInput!) {
  taskMovementAddDetails(input: $input) {
    taskMovementDetailsResponse {
      skuId
    }
  }
}
    `;
/**
 * __useTaskMovementDetailsCreateMutation__
 *
 * To run a mutation, you first call `useTaskMovementDetailsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskMovementDetailsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskMovementDetailsCreateMutation, { data, loading, error }] = useTaskMovementDetailsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskMovementDetailsCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskMovementDetailsCreateDocument, options);
}
export const TaskMovementDetailsEditDocument = gql `
    mutation TaskMovementDetailsEdit($input: TaskMovementEditDetailsInput!) {
  taskMovementEditDetails(input: $input) {
    taskMovementDetailsResponse {
      skuId
    }
  }
}
    `;
/**
 * __useTaskMovementDetailsEditMutation__
 *
 * To run a mutation, you first call `useTaskMovementDetailsEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskMovementDetailsEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskMovementDetailsEditMutation, { data, loading, error }] = useTaskMovementDetailsEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskMovementDetailsEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskMovementDetailsEditDocument, options);
}
export const TaskMovementDetailsDeleteDocument = gql `
    mutation TaskMovementDetailsDelete($input: TaskMovementDeleteDetailsInput!) {
  taskMovementDeleteDetails(input: $input) {
    taskMovementDetailsResponse {
      skuId
    }
  }
}
    `;
/**
 * __useTaskMovementDetailsDeleteMutation__
 *
 * To run a mutation, you first call `useTaskMovementDetailsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskMovementDetailsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskMovementDetailsDeleteMutation, { data, loading, error }] = useTaskMovementDetailsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskMovementDetailsDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskMovementDetailsDeleteDocument, options);
}
export const TaskMovementCreateDocument = gql `
    mutation TaskMovementCreate($input: TaskMovementAddInput!) {
  taskMovementAdd(input: $input) {
    taskMovementResponse {
      id
    }
  }
}
    `;
/**
 * __useTaskMovementCreateMutation__
 *
 * To run a mutation, you first call `useTaskMovementCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskMovementCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskMovementCreateMutation, { data, loading, error }] = useTaskMovementCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskMovementCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskMovementCreateDocument, options);
}
export const TaskMovementChangeStatusDocument = gql `
    mutation TaskMovementChangeStatus($input: TaskMovementChangeStatusInput!) {
  taskMovementChangeStatus(input: $input) {
    taskStatusResponse {
      title
    }
  }
}
    `;
/**
 * __useTaskMovementChangeStatusMutation__
 *
 * To run a mutation, you first call `useTaskMovementChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskMovementChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskMovementChangeStatusMutation, { data, loading, error }] = useTaskMovementChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskMovementChangeStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TaskMovementChangeStatusDocument, options);
}
