import { Button, Row, Typography } from 'antd';
import React from 'react';
import MDIcon from '../ui/MDIcon';
const ProductListHeader = (props) => {
    const { title, onAddClick, showActions = true } = props;
    return (React.createElement(Row, { justify: "space-between" },
        React.createElement(Typography.Title, { className: "m-0", level: 5 }, title),
        showActions ? (React.createElement(Button, { type: "primary", onClick: onAddClick, icon: React.createElement(MDIcon, { icon: "plus", style: { marginRight: 5 } }) }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u044E")) : null));
};
export default ProductListHeader;
