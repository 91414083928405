var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Task from '../../../../components/task/Task';
import { Descriptions } from 'antd';
const ShippingTaskContainer = (props) => {
    var _a, _b;
    const { id, task, isLoading } = props, tabs = __rest(props, ["id", "task", "isLoading"]);
    const info = [
        { title: 'Склад', value: task === null || task === void 0 ? void 0 : task.warehouse.storageTitle },
        { title: 'Грузоотправитель', value: task === null || task === void 0 ? void 0 : task.clientFrom.companyName },
        { title: 'Грузополучатель', value: task === null || task === void 0 ? void 0 : task.clientTo.companyName },
        { title: 'Плательщик', value: (_a = task === null || task === void 0 ? void 0 : task.payer) === null || _a === void 0 ? void 0 : _a.companyName },
        { title: 'Перевозчик', value: (_b = task === null || task === void 0 ? void 0 : task.carrier) === null || _b === void 0 ? void 0 : _b.companyName },
        { title: 'Водитель, т/с', value: React.createElement(React.Fragment, null, task === null || task === void 0 ? void 0 :
                task.driverName,
                React.createElement("br", null), task === null || task === void 0 ? void 0 :
                task.vehicleNumber) },
        { title: 'Отпуск разрешил', value: React.createElement(React.Fragment, null, task === null || task === void 0 ? void 0 :
                task.releaseAllowedName,
                React.createElement("br", null), task === null || task === void 0 ? void 0 :
                task.releaseAllowedPost) },
        { title: 'Отпуск произвел', value: React.createElement(React.Fragment, null, task === null || task === void 0 ? void 0 :
                task.releaseProducedName,
                React.createElement("br", null), task === null || task === void 0 ? void 0 :
                task.releaseProducedPost) },
    ];
    const renderTaskInfo = (items, title) => {
        return React.createElement(Descriptions, { title: title, size: 'small', column: 1 }, items.map(item => {
            return React.createElement(Descriptions.Item, { label: item.title }, item.value);
        }));
    };
    return (React.createElement(Task, Object.assign({ isLoading: isLoading, requisitesTab: renderTaskInfo(info, 'Реквизиты') }, tabs)));
};
export default ShippingTaskContainer;
