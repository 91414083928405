var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import moment from "moment";
class ServiceHelper {
}
_a = ServiceHelper;
ServiceHelper.isEmptyValue = (value) => {
    return value === "" || value === undefined || value === null;
};
ServiceHelper.transformToValueOrNull = (value) => {
    return _a.isEmptyValue(value) ? null : value;
};
ServiceHelper.getAttributeValue = (attr) => {
    if ((attr === null || attr === void 0 ? void 0 : attr.value) !== undefined) {
        return attr.value.toString();
    }
    else if (attr && typeof attr === "object" && "_isUTC" in attr) {
        return moment(attr).format(attr["_f"] || "YYYY-MM-DD");
    }
    return attr === null || attr === void 0 ? void 0 : attr.toString();
};
ServiceHelper.transformObject = (input) => {
    return Object.entries(input).map(([key, value]) => ({
        skuAttributeValueId: Number(key),
        value: _a.transformToValueOrNull(value),
    }));
};
export const onHandlerSave = (detailsId, nomenclature, form, setEditingKey, onEditItem) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    try {
        const detailsIdNumber = Number(detailsId);
        const sku = (_b = nomenclature.skus) === null || _b === void 0 ? void 0 : _b.find((item) => item.taskDetailId === detailsIdNumber);
        const formValuesRaw = yield form.getFieldsValue();
        Object.keys(formValuesRaw).forEach((key) => {
            formValuesRaw[key] = ServiceHelper.transformToValueOrNull(formValuesRaw[key]);
        });
        yield form.setFieldsValue(formValuesRaw);
        const row = (yield form.validateFields());
        const formValues = {};
        for (const key in row) {
            const attr = row[key];
            const skuAttribute = sku === null || sku === void 0 ? void 0 : sku.skuAttributeValues.find((el) => el.key === +key);
            const id = skuAttribute ? skuAttribute.value.id : key;
            formValues[id] = ServiceHelper.getAttributeValue(attr);
        }
        const { quantity: quantityValue, storage: storageCellIdValue, defect: defectValue } = formValues, attributes = __rest(formValues, ["quantity", "storage", "defect"]);
        const editDetailsRequestBody = {
            defect: defectValue,
            taskLoadingDetailsId: detailsIdNumber,
            storageCellId: storageCellIdValue ? Number(storageCellIdValue) : undefined,
            quantity: quantityValue ? Number(quantityValue) : Number(sku === null || sku === void 0 ? void 0 : sku.quantity),
            skuAttributeValues: ServiceHelper.transformObject(attributes)
        };
        if (onEditItem)
            onEditItem(editDetailsRequestBody);
        setEditingKey("");
    }
    catch (errInfo) {
        console.log("Validate Failed:", errInfo);
    }
});
