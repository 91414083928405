import { Outlet } from 'react-router-dom';
import React from 'react';
import { links } from '../constants/links';
import PartitionListPage from '../core/pages/task/partition/PartitionListPage';
import PartitionTaskPage from '../core/pages/task/partition/PartitionTaskPage';
import PartitionAddPage from '../core/pages/task/partition/PartitionAddPage';
import PartitionEditPage from '../core/pages/task/partition/PartitionEditPage';
const paths = links.taskPartitionLinks;
const taskPartitionRoutes = {
    title: paths.list.title,
    breadcrumb: 'Деление',
    path: paths.list.path,
    element: React.createElement(Outlet, null),
    children: [
        {
            index: true,
            element: React.createElement(PartitionListPage, null),
        },
        {
            title: paths.page.title,
            breadcrumb: paths.page.breadcrumb,
            path: paths.page.url(':taskId'),
            element: React.createElement(Outlet, null),
            children: [
                {
                    index: true,
                    element: React.createElement(PartitionTaskPage, null),
                },
                {
                    title: paths.edit.title,
                    breadcrumb: paths.edit.breadcrumb,
                    path: paths.edit.url(':taskId'),
                    element: React.createElement(PartitionEditPage, null),
                },
            ],
        },
        {
            title: paths.add.title,
            breadcrumb: paths.add.breadcrumb,
            path: paths.add.url(),
            element: React.createElement(PartitionAddPage, null),
        },
    ],
};
export default taskPartitionRoutes;
