import React from "react";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { useTaskInventory } from "../../../../hooks/api/taskInventoryApi/useTaskInventory";
import InventoryTaskListContainer from "../../../containers/task/inventory/InventoryTaskListContainer";
import InventoryTaskListFilterContainer from "../../../containers/task/inventory/InventoryTaskListFilterContainer";
import InventoryTaskListHeaderContainer from "../../../containers/task/inventory/InventoryTaskListHeaderContainer";
import InventoryTaskPaginationContainer from "../../../containers/task/inventory/InventoryTaskPaginationContainer";
const InventoryListPage = () => {
    const { taskList, } = useTaskInventory();
    const props = {
        toolbarBottom: React.createElement(InventoryTaskPaginationContainer, { pagination: taskList.pagination, isLoading: taskList.loading, data: taskList.data }),
        toolbarTop: React.createElement(InventoryTaskListHeaderContainer, { data: taskList === null || taskList === void 0 ? void 0 : taskList.data, isLoading: taskList.loading, pagination: taskList === null || taskList === void 0 ? void 0 : taskList.pagination }),
        toolbarRight: (React.createElement(InventoryTaskListFilterContainer, { isLoading: taskList.loading, fetchList: taskList.paginationFilteredGetTaskList, objectClass: ObjectClass.TaskInventory })),
    };
    return (React.createElement(Page, Object.assign({}, props),
        React.createElement(InventoryTaskListContainer, { taskList: taskList === null || taskList === void 0 ? void 0 : taskList.data, pagination: taskList === null || taskList === void 0 ? void 0 : taskList.pagination, isLoading: taskList === null || taskList === void 0 ? void 0 : taskList.loading })));
};
export default InventoryListPage;
