var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from "react";
import { useParams } from "react-router";
import { useGetStorageSectionLinearListLazyQuery } from "../../../../api/graphql/queries/storageSection/StorageSectionQuery";
import { isValidId } from "../helpers";
const PAGE_SIZE = 100;
export const useSectionData = (dispatch, warehouseId) => {
    const [getStorageSectionLinearList, { data, fetchMore }] = useGetStorageSectionLinearListLazyQuery({ fetchPolicy: "no-cache" });
    const { id } = useParams();
    useEffect(() => {
        if (isValidId(id) || warehouseId) {
            const numericId = warehouseId !== null && warehouseId !== void 0 ? warehouseId : Number(id);
            fetchStorageSectionLinearList(dispatch, getStorageSectionLinearList, numericId);
        }
    }, []);
    useEffect(() => {
        if (data) {
            const hasMoreItems = data.storageSectionLinearList.pagination.itemsCurrentCount < data.storageSectionLinearList.pagination.itemsTotalCount;
            if (hasMoreItems) {
                const currentPage = Math.ceil(data.storageSectionLinearList.pagination.itemsCurrentCount / PAGE_SIZE);
                const nextPage = currentPage + 1;
                fetchMore({
                    variables: { page: nextPage, pageSize: PAGE_SIZE },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult)
                            return prev;
                        const updatedData = [...prev.storageSectionLinearList.data, ...fetchMoreResult.storageSectionLinearList.data];
                        return Object.assign(Object.assign({}, prev), { storageSectionLinearList: Object.assign(Object.assign({}, prev.storageSectionLinearList), { data: updatedData, pagination: Object.assign(Object.assign({}, fetchMoreResult.storageSectionLinearList.pagination), { itemsCurrentCount: updatedData.length }) }) });
                    },
                }).catch(() => {
                    dispatch({ type: "FETCH_FAILURE" });
                });
            }
            else {
                dispatch({ type: "FETCH_SECTIONS_SUCCESS", payload: data.storageSectionLinearList.data });
                dispatch({ type: "FETCH_SECTIONS_DONE" });
            }
        }
    }, [data]);
    return {};
};
const fetchStorageSectionLinearList = (dispatch, fetchStorageSectionLinearListQuery, warehouseId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    try {
        const response = yield fetchStorageSectionLinearListQuery({
            variables: { warehouseId, page: 1, pageSize: PAGE_SIZE },
        });
        return (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.storageSectionLinearList) === null || _b === void 0 ? void 0 : _b.data;
    }
    catch (error) {
        dispatch({ type: "FETCH_FAILURE" });
    }
});
