import React from 'react';
import classNames from 'classnames';
import ColorPalette from '../../constants/enum/ColorPalette';
import MDIcon from '../ui/MDIcon';
const StatusNode = (props) => {
    const { color = ColorPalette.Default, children, count = 0 } = props;
    const classes = {
        label: true,
        [`label-${color}`]: true,
    };
    return (React.createElement("div", { className: classNames(classes), style: {
            width: '100%',
            height: '100%',
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '16px',
            borderWidth: '2px',
            position: 'relative',
        } },
        React.createElement("div", { style: {
                display: count ? 'block' : 'none',
                position: 'absolute',
                top: '-10px',
                left: '-15px',
                background: 'red',
                color: 'white',
                padding: '3px 6px',
                borderRadius: '12px',
            } },
            React.createElement(MDIcon, { icon: "arrow-expand-right" }),
            count),
        React.createElement("span", { style: {
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                maxHeight: '100%',
                overflow: 'hidden',
                whiteSpace: 'break-spaces',
            } }, children)));
};
export default StatusNode;
