import warehouseApi, { WAREHOUSE_SECTION_TAG } from "..";
import { StorageSectionCreateDocument, StorageSectionDeleteDocument, StorageSectionEditDocument } from "../../../../api/graphql/queries/storageSection/StorageSectionMutation";
import { StorageSectionDocument, StorageSectionListDocument } from "../../../../api/graphql/queries/storageSection/StorageSectionQuery";
import dtoToEntity from "../../../../api/rest/adapters/dtoToEntity";
import { convertStorageSectionDtoToWarehouseSectionDto } from "../../../../api/rest/adapters/dtoToEntity/warehouse";
import { flatWarehouseSectionDto } from "../../../../api/rest/dto/warehouse/WarehouseSectionDto";
const warehouseSectionApi = warehouseApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchSectionList: builder.query({
            query: ({ warehouseId, page, pageSize, search, parentId }) => ({
                document: StorageSectionListDocument,
                variables: { warehouseId, page, pageSize, search, parentId },
            }),
            transformResponse: (response) => {
                const list = response.storageSectionList.data.map((section) => {
                    var _a, _b;
                    return (Object.assign(Object.assign({}, section), { description: (_a = section.description) !== null && _a !== void 0 ? _a : undefined, sectionType: Object.assign(Object.assign({}, section.sectionType), { description: (_b = section.sectionType.description) !== null && _b !== void 0 ? _b : undefined }) }));
                });
                const pagination = {
                    page: response.storageSectionList.pagination.page,
                    itemsTotalCount: response.storageSectionList.pagination.itemsTotalCount,
                    itemsCurrentCount: response.storageSectionList.pagination.itemsCurrentCount,
                    itemsOnPage: response.storageSectionList.pagination.itemsOnPage,
                };
                return { list, pagination };
            },
            providesTags: (result) => {
                if (result) {
                    return [...result.list.map(({ id }) => ({ type: WAREHOUSE_SECTION_TAG, id })), WAREHOUSE_SECTION_TAG];
                }
                return [WAREHOUSE_SECTION_TAG];
            },
        }),
        fetchSection: builder.query({
            query: ({ storageSectionId, warehouseId }) => ({
                document: StorageSectionDocument,
                variables: { storageSectionId, warehouseId },
            }),
            transformResponse: (response, _, { parentId }) => {
                const responseData = response === null || response === void 0 ? void 0 : response.storageSection;
                const childrens = flatWarehouseSectionDto(convertStorageSectionDtoToWarehouseSectionDto(responseData), false);
                return {
                    section: dtoToEntity.warehouseSectionAdapter(convertStorageSectionDtoToWarehouseSectionDto(responseData), parentId),
                    childrens: {
                        sections: childrens.storageSections.map((section) => dtoToEntity.warehouseSectionAdapter(section, responseData.id)),
                        cells: childrens.storageCells.map((cell) => dtoToEntity.storageCellAdapter(cell, responseData.id)),
                    },
                };
            },
            providesTags: (result, error, { storageSectionId }) => [WAREHOUSE_SECTION_TAG, { type: WAREHOUSE_SECTION_TAG, storageSectionId }],
        }),
        addSection: builder.mutation({
            query: (input) => ({
                document: StorageSectionCreateDocument,
                variables: input,
            }),
            transformResponse: (response, _, { input: { parentId } }) => {
                const responseData = response === null || response === void 0 ? void 0 : response.storageSectionCreate.storageSectionDto;
                return dtoToEntity.warehouseSectionAdapter(convertStorageSectionDtoToWarehouseSectionDto(responseData), parentId !== null && parentId !== void 0 ? parentId : undefined);
            },
            invalidatesTags: [WAREHOUSE_SECTION_TAG],
        }),
        editSection: builder.mutation({
            query: (input) => ({
                document: StorageSectionEditDocument,
                variables: input,
            }),
            transformResponse: (response, _, { input: { parentId } }) => {
                const responseData = response === null || response === void 0 ? void 0 : response.storageSectionEdit.storageSectionDto;
                return dtoToEntity.warehouseSectionAdapter(convertStorageSectionDtoToWarehouseSectionDto(responseData), parentId !== null && parentId !== void 0 ? parentId : undefined);
            },
            invalidatesTags: (result, error, { input: { storageSectionId } }) => [WAREHOUSE_SECTION_TAG, { type: WAREHOUSE_SECTION_TAG, id: storageSectionId }],
        }),
        deleteSection: builder.mutation({
            query: (input) => ({
                document: StorageSectionDeleteDocument,
                variables: input,
            }),
            transformResponse: (_, __, { input: { storageSectionId } }) => storageSectionId,
            invalidatesTags: (result, error, { input: { storageSectionId } }) => [WAREHOUSE_SECTION_TAG, { type: WAREHOUSE_SECTION_TAG, id: storageSectionId }],
        }),
    }),
});
export default warehouseSectionApi;
