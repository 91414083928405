var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from "react";
import { useStorageCellLoadLazyQuery } from "../../../../api/graphql/queries/storageCell/StorageCellQuery";
import { adaptStorageCellLoadDtoToCellCollection, isValidId } from "../helpers";
import { useParams } from "react-router-dom";
export const useLoadingData = (dispatch, warehouseId, enableCellColoring) => {
    const [getWarehouseData] = useStorageCellLoadLazyQuery();
    const { id } = useParams();
    useEffect(() => {
        if (enableCellColoring) {
            if (isValidId(id) || warehouseId) {
                const numericId = warehouseId !== null && warehouseId !== void 0 ? warehouseId : Number(id);
                fetchData(numericId);
            }
        }
        else {
            dispatch({ type: "FETCH_CELL_DTO_DONE" });
        }
    }, []);
    const fetchData = (warehouseId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            const response = yield getWarehouseData({
                variables: { warehouseId },
            });
            if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.storageCellLoad) {
                dispatch({ type: "FETCH_CELL_DTO_SUCCESS", payload: adaptStorageCellLoadDtoToCellCollection((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.storageCellLoad) });
                dispatch({ type: "FETCH_CELL_DTO_DONE" });
            }
        }
        catch (error) {
            dispatch({ type: "FETCH_FAILURE" });
        }
    });
    return { fetchData };
};
