import { Col, Form, Input, InputNumber, Row } from "antd";
import React from "react";
import UnitSelect from "../../../../../../components/UnitSelect";
import { BASE_VALIDATION_RULES, FormNames, FormNamesLocale } from "../../../constants";
const PackingForm = ({ form, onSubmit, initialValues }) => {
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues },
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.TITLE, label: FormNamesLocale.TITLE, rules: [BASE_VALIDATION_RULES] },
                    React.createElement(Input, null))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.QUANTITY, label: FormNamesLocale.QUANTITY, rules: [BASE_VALIDATION_RULES] },
                    React.createElement(InputNumber, { style: { width: "100%" } })))),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.HEIGHT, label: FormNamesLocale.HEIGHT, rules: [BASE_VALIDATION_RULES] },
                    React.createElement(InputNumber, { style: { width: "100%" } }))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.WIDTH, label: FormNamesLocale.WIDTH, rules: [BASE_VALIDATION_RULES] },
                    React.createElement(InputNumber, { style: { width: "100%" } })))),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.DEPTH, label: FormNamesLocale.DEPTH, rules: [BASE_VALIDATION_RULES] },
                    React.createElement(InputNumber, { style: { width: "100%" } }))),
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.WEIGHT, label: FormNamesLocale.WEIGHT, rules: [BASE_VALIDATION_RULES] },
                    React.createElement(InputNumber, { style: { width: "100%" } })))),
        React.createElement(Row, { gutter: [16, 16] },
            React.createElement(Col, { span: 12 },
                React.createElement(Form.Item, { name: FormNames.HANDBOOK_UNIT_ID, label: FormNamesLocale.HANDBOOK_UNIT_ID, rules: [BASE_VALIDATION_RULES] },
                    React.createElement(UnitSelect, null))))));
};
export default PackingForm;
