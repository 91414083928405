import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, Table } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import useResize from "../../../../../../hooks/useResize";
import MDIcon from "../../../../../../components/ui/MDIcon";
import { getColumnType } from "../../../../../../helpers/function";
import { DELETE_PACKING_MODAL_MESSAGE, FormNames, FormNamesLocale } from "../../../constants";
const { confirm } = Modal;
const DEBOUNCE_TIMEOUT = 100;
const PackingTable = (props) => {
    const { items, loading, onDelete, onEdit } = props;
    const wrapper = useRef(null);
    const [tableHeight, setTableHeight] = useState(0);
    const updateDimensions = debounce(() => {
        var _a;
        const antTableHeader = (_a = wrapper.current) === null || _a === void 0 ? void 0 : _a.querySelector(".ant-table-header");
        const wrapperHeight = wrapper.current ? wrapper.current.clientHeight : 0;
        const headerHeight = antTableHeader ? antTableHeader.clientHeight : 0;
        setTableHeight(Math.floor(wrapperHeight - headerHeight) - 1);
    }, DEBOUNCE_TIMEOUT);
    useResize(updateDimensions, []);
    const handlerEdit = (id, title) => {
        onEdit(id, title);
    };
    const handlerDelete = (record) => {
        confirm({
            title: DELETE_PACKING_MODAL_MESSAGE.title,
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: (React.createElement(React.Fragment, null,
                DELETE_PACKING_MODAL_MESSAGE.message,
                React.createElement("b", null, record.title),
                "?")),
            okText: "Да",
            okType: "danger",
            cancelText: "Отмена",
            onOk() {
                onDelete(record.id);
            },
        });
    };
    const menu = useCallback((record) => ({
        items: [
            {
                key: "packing_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => handlerEdit(record.id, record.title),
            },
            {
                key: "packing_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => handlerDelete(record),
            },
        ],
    }), []);
    const columns = [
        getColumnType("id", "#", false, {
            width: "1%",
        }),
        getColumnType(FormNames.TITLE, FormNamesLocale.TITLE, false),
        getColumnType(FormNames.DEPTH, FormNamesLocale.DEPTH, false, {
            width: "8%",
        }),
        getColumnType(FormNames.HEIGHT, FormNamesLocale.HEIGHT, false, {
            width: "8%",
        }),
        getColumnType(FormNames.WIDTH, FormNamesLocale.WIDTH, false, {
            width: "8%",
        }),
        getColumnType(FormNames.WEIGHT, FormNamesLocale.WEIGHT, false, {
            width: "8%",
        }),
        getColumnType(FormNames.QUANTITY, FormNamesLocale.QUANTITY, false, {
            width: "10%",
        }),
        getColumnType(FormNames.HANDBOOK_UNIT_ID, FormNamesLocale.HANDBOOK_UNIT_ID, false, {
            width: "20%",
            render: (value) => value.label,
        }),
        getColumnType("actions", "", false, {
            width: "1%",
            render: (_, record) => {
                return (React.createElement(Button.Group, null, !(record === null || record === void 0 ? void 0 : record.isExternal) && (React.createElement(Dropdown, { menu: menu(record), trigger: ["click"] },
                    React.createElement(Button, { size: "small", icon: React.createElement(MDIcon, { icon: "pencil" }) })))));
            },
        }),
    ];
    const data = items.map((item) => {
        var _a, _b;
        return ({
            key: item.id,
            id: item.id,
            [FormNames.TITLE]: item.title,
            [FormNames.WEIGHT]: item.weight,
            [FormNames.WIDTH]: item.width,
            [FormNames.QUANTITY]: item.quantity,
            [FormNames.DEPTH]: item.depth,
            [FormNames.HEIGHT]: item.height,
            [FormNames.HANDBOOK_UNIT_ID]: { label: (_a = item === null || item === void 0 ? void 0 : item.handbookUnit) === null || _a === void 0 ? void 0 : _a.title, value: (_b = item === null || item === void 0 ? void 0 : item.handbookUnit) === null || _b === void 0 ? void 0 : _b.id },
        });
    });
    return (React.createElement("div", { className: "wms-table-full", id: "wms-table-full", ref: wrapper },
        React.createElement(Table, { columns: columns, dataSource: data, loading: loading, bordered: true, size: "small", pagination: false, scroll: { x: true, y: tableHeight } })));
};
export default PackingTable;
