import React, { useState } from 'react';
import { Card, Result, Spin } from 'antd';
import Centered from '../../ui/Centered';
const Task = (props) => {
    const { isLoading, filesTab, historyTab, requisitesTab, nomenclatureTab, } = props;
    if (isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { size: "large" })));
    }
    const taskTabs = () => {
        const tabs = [
            {
                key: 'nomenclature',
                label: 'Номенклатура',
                children: nomenclatureTab
            }
        ];
        if (requisitesTab != null) {
            tabs.push({
                key: 'requisites',
                label: 'Реквизиты',
                children: requisitesTab
            });
        }
        if (filesTab != null) {
            tabs.push({
                key: 'files',
                label: 'Файлы',
                children: filesTab
            });
        }
        tabs.push({
            key: 'history',
            label: 'История',
            children: historyTab
        });
        return tabs;
    };
    const [activeTabKey, setActiveTabKey] = useState('nomenclature');
    const notFound = React.createElement(Result, { status: "404", title: "404", subTitle: "\u0418\u0437\u0432\u0438\u043D\u0438\u0442\u0435, \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430, \u043A\u043E\u0442\u043E\u0440\u0443\u044E \u0432\u044B \u043F\u043E\u0441\u0435\u0442\u0438\u043B\u0438, \u043D\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442." });
    const onTabChange = (key) => {
        setActiveTabKey(key);
    };
    const getActiveTabChildren = () => {
        const tabs = taskTabs();
        if (tabs == undefined)
            return notFound;
        const activeTab = tabs.find(x => x.key == activeTabKey);
        if (activeTab == undefined)
            return notFound;
        return activeTab.children;
    };
    return (React.createElement(Card, { tabList: taskTabs().map(x => ({
            key: x.key,
            label: x.label
        })), activeTabKey: activeTabKey, onTabChange: onTabChange }, getActiveTabChildren()));
};
export default Task;
