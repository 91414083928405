import { Button, Form, message, Spin } from "antd";
import React, { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { productFormValuesToRequest } from "../../components/ProductForm/helpers";
import ProductForm from "../../components/ProductForm/ProductForm";
import Centered from "../../components/ui/Centered";
import { links } from "../../constants/links";
import productApi from "../../redux/api/productApi";
import { productTypeGraphqlApi } from "../../redux/api/productApi/productType";
const { useAddProductMutation } = productApi;
const { useFetchProductTypeQuery } = productTypeGraphqlApi;
const ProductAddFormContainer = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const typeParam = searchParams.get("typeId");
    const typeId = typeParam ? +typeParam : null;
    const { data: type } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    const [addProduct, { isLoading }] = useAddProductMutation();
    const handlerButtonClick = () => {
        form.submit();
    };
    const handlerSubmit = (values) => {
        var _a;
        const body = {
            productTypeId: values.productTypeId,
            handbookUnitId: values.handbookUnitId.value,
            productAttributeValues: productFormValuesToRequest(values, type),
        };
        if (!!values.handbookPackingSetId) {
            if (typeof values.handbookPackingSetId === "number") {
                body.handbookPackingSetId = values.handbookPackingSetId;
            }
            else {
                body.handbookPackingSetId = (_a = values === null || values === void 0 ? void 0 : values.handbookPackingSetId) === null || _a === void 0 ? void 0 : _a.value;
            }
        }
        addProduct(body)
            .unwrap()
            .then(() => {
            message.success("Продукт успешно создан");
            navigate(links.handbookProductLink.url());
        });
    };
    const initialValues = useMemo(() => ({
        productTypeId: typeId ? +typeId : undefined,
    }), [typeId]);
    if (type) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ProductForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues, productType: type }),
            React.createElement(Button, { loading: isLoading, type: "primary", onClick: handlerButtonClick }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
    }
    return (React.createElement(Centered, null,
        React.createElement(Spin, { spinning: true })));
};
export default ProductAddFormContainer;
