import { createApi } from '@reduxjs/toolkit/query/react';
import dtoToEntity from '../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../constants/enum/Method';
import fetchAppQuery from '../../fetchAppQuery';
const URI = 'handbook';
export const HANDBOOK_TAG = 'handbook';
export const HANDBOOK_CATEGORY_TAG = 'handbookCategory';
export const HANDBOOK_PACKING_SET = 'handbookPackingSet';
export const HANDBOOK_UNIT_TAG = 'handbookUnit';
export const HANDBOOK_VALUE_TAG = 'handbookValue';
const handbookApi = createApi({
    reducerPath: 'handbookApi',
    tagTypes: [
        HANDBOOK_TAG,
        HANDBOOK_CATEGORY_TAG,
        HANDBOOK_PACKING_SET,
        HANDBOOK_UNIT_TAG,
        HANDBOOK_VALUE_TAG,
    ],
    baseQuery: fetchAppQuery,
    endpoints: (builder) => ({
        fetchHandbookList: builder.query({
            query: (args) => ({
                method: Method.GET,
                url: `${URI}/list`,
                params: args,
            }),
            transformResponse: (response, meta, { handbookCategoryId }) => ({
                list: response.data.map((item) => dtoToEntity.handbookAdapter(item, handbookCategoryId)),
                pagination: dtoToEntity.paginationAdapter(response.pagination),
            }),
            providesTags: (result) => {
                if (result) {
                    return [
                        ...result.list.map(({ id }) => ({ type: HANDBOOK_TAG, id })),
                        HANDBOOK_TAG,
                    ];
                }
                return [HANDBOOK_TAG];
            },
        }),
        fetchHandbook: builder.query({
            query: (handbookId) => ({
                method: Method.GET,
                url: `${URI}/${handbookId}`,
            }),
            transformResponse: (response) => (dtoToEntity.handbookAdapter(response)),
            providesTags: (result, error, id) => [
                HANDBOOK_TAG, { type: HANDBOOK_TAG, id },
            ],
        }),
        addHandbook: builder.mutation({
            query: (values) => ({
                method: Method.POST,
                url: `${URI}/add`,
                body: values,
            }),
            transformResponse: (response) => (dtoToEntity.handbookAdapter(response)),
            invalidatesTags: [HANDBOOK_TAG],
        }),
        editHandbook: builder.mutation({
            query: (args) => {
                const { handbookId, values } = args;
                return {
                    method: Method.PUT,
                    url: `${URI}/${handbookId}`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.handbookAdapter(response)),
            invalidatesTags: (result, error, { handbookId }) => [
                HANDBOOK_TAG, { type: HANDBOOK_TAG, id: handbookId },
            ],
        }),
        deleteHandbook: builder.mutation({
            query: (handbookId) => ({
                method: Method.DELETE,
                url: `${URI}/${handbookId}`,
            }),
            transformResponse: (_, __, handbookId) => handbookId,
            invalidatesTags: (result, error, id) => [
                HANDBOOK_TAG, { type: HANDBOOK_TAG, id },
            ],
        }),
    }),
});
export default handbookApi;
