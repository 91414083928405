import { uniqueId } from "lodash";
import { Typography } from "antd";
import React from "react";
import { HistoryColumns } from "./types";
import { dateToString, dateToTimeString, formatDuration } from "../../helpers/function";
import Label from "../ui/Label";
import MDIcon from "../ui/MDIcon";
import moment from "moment";
import { ClockCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;
export const renderStatusLabel = (status) => {
    if (!status)
        return React.createElement(React.Fragment, null);
    return React.createElement(Label, { text: status.title, color: status.style });
};
export const renderDate = (dateString) => {
    try {
        const date = new Date(dateString);
        return (React.createElement("div", { style: { textAlign: "right", whiteSpace: "nowrap" } },
            React.createElement("div", null, dateToString(date)),
            React.createElement(Text, { type: "secondary" }, dateToTimeString(date))));
    }
    catch (e) {
        throw new TypeError("[ StatusHistory ] Error while creating date object");
    }
};
export function getTimeDifferenceText(entity, index, history) {
    if (index > 0) {
        const previousEntity = history[index - 1];
        const timeDifference = moment(entity.createdAt).diff(moment(previousEntity.createdAt));
        const duration = moment.duration(timeDifference);
        return formatDuration(duration);
    }
    return "";
}
function generateArrowCell(index, history, entity) {
    const timeDifferenceText = getTimeDifferenceText(entity, index, history);
    return (React.createElement("div", { style: { justifyContent: "center", display: "flex", flexWrap: "wrap" } },
        React.createElement(MDIcon, { icon: "arrow-right" }),
        index > 0 && (React.createElement("div", { style: { display: "flex", justifyContent: "center", alignItems: "center", gap: "4px", width: "100%" } },
            React.createElement(ClockCircleOutlined, null),
            " ",
            React.createElement("div", { style: { display: "flex", fontSize: "0.75rem" } }, timeDifferenceText)))));
}
export const generateRecord = (entity, index, history) => {
    return {
        key: uniqueId(),
        [HistoryColumns.USER]: React.createElement(React.Fragment, null, entity.user.userName),
        [HistoryColumns.FROM]: renderStatusLabel(entity.statusFrom),
        [HistoryColumns.TO]: renderStatusLabel(entity.statusTo),
        [HistoryColumns.ARROW]: generateArrowCell(index, history, entity),
        [HistoryColumns.DATE]: renderDate(entity.createdAt),
        [HistoryColumns.REPORT]: entity.report,
    };
};
