var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { SortableBody } from "./SortableBody";
import { DraggableBodyRow } from "./DraggableBodyRow";
export const DraggableContainer = (dragProps) => {
    var _a;
    const { handlerSortEnd, items } = dragProps, rest = __rest(dragProps, ["handlerSortEnd", "items"]);
    return (React.createElement(SortableBody, Object.assign({ helperClass: "ghost-dragg-container", useDragHandle: true, disableAutoscroll: true, lockAxis: "y", onSortEnd: handlerSortEnd }, rest), (_a = items === null || items === void 0 ? void 0 : items.filter((i) => !!i)) === null || _a === void 0 ? void 0 : _a.map((row, index) => (React.createElement(DraggableBodyRow, { itemIndex: index, index: index, key: row.sort, isActive: !!row.enable, row: row, indexNumber: row.id })))));
};
