import React from "react";
import TaskListItemWithSlot from "../../../../components/task/TaskList/TaskListItemWithSlot";
import { links } from "../../../../constants/links";
import ChangeWarehouseTaskListItemInfo from "./ChangeWarehouseTaskListItemInfo";
const ChangeWarehouseTaskListItem = (props) => {
    var _a;
    const { task, pagination } = props;
    const info = [
        { title: "Склад отправитель", value: task.warehouse.storageTitle },
        { title: "Склад получатель", value: (_a = task === null || task === void 0 ? void 0 : task.destinationWarehouse) === null || _a === void 0 ? void 0 : _a.storageTitle },
    ];
    return (React.createElement(TaskListItemWithSlot, { pagination: pagination, task: task, info: info, slot: React.createElement(ChangeWarehouseTaskListItemInfo, { data: task }), link: links.taskChangeWarehouseLinks.page.url(task.id) }));
};
export default ChangeWarehouseTaskListItem;
