import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AppLayout from '../conponents/AppLayout';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useWindowSize } from '../../hooks/useWindowSize';
import { appActions } from '../../redux/reducers/appReducer';
const AppLayoutContainer = (props) => {
    const { children } = props;
    const { sidebarOpen } = useTypedSelector((state) => state.app);
    const { toggleSidebar } = appActions;
    const [isMobile, setIsMobile] = useState(false);
    const [windowWidth] = useWindowSize();
    if (!isMobile && windowWidth < 992) {
        setIsMobile(true);
        toggleSidebar(false);
    }
    else if (isMobile && windowWidth >= 992) {
        setIsMobile(false);
        toggleSidebar(false);
    }
    return React.createElement(AppLayout, { sidebarOpen: sidebarOpen }, children !== null && children !== void 0 ? children : React.createElement(Outlet, null));
};
export default AppLayoutContainer;
