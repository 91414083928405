import React from 'react';
// export const dateRender = (date: Date): React.ReactNode => (
//   date instanceof Date ? date.toLocaleDateString() : '-'
// );
export const dateRender = (date) => {
    if (date == null)
        return React.createElement(React.Fragment, null, "-");
    if (date instanceof Date)
        return date.toLocaleDateString();
    try {
        const dateParsed = new Date(date);
        return dateParsed.toLocaleDateString();
    }
    catch (e) {
        return React.createElement(React.Fragment, null, "-");
    }
};
export const quantityRender = (number, notNull = true) => (notNull ? Number(number.toFixed(0)).toLocaleString() : 0);
export const productRender = (model) => (React.createElement(React.Fragment, null,
    React.createElement("span", { className: "d-block" }, model.productTitle),
    React.createElement("small", { className: "text-muted" }, `${model.productType} | ${model.warehouseTitle} | ${model.storageCellTitle}`)));
export const clientRender = (model) => (React.createElement(React.Fragment, null,
    React.createElement("span", { className: "d-block" }, model.clientTitle),
    React.createElement("small", { className: "text-muted" }, `ИНН: ${model.clientInn}`)));
