import React, { useEffect, useLayoutEffect } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import OidcError from "../conponents/oidc/OidcError";
import OidcLoading from "../conponents/oidc/OidcLoading";
import OidcSessionLost from "../conponents/oidc/OidcSessionLost";
import { useNavigate } from "react-router";
export const PrivateRoute = ({ children }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [hasTriedSignin, setHasTriedSignin] = React.useState(false);
    useLayoutEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);
    useLayoutEffect(() => {
        if (auth.isAuthenticated && window.location.pathname.includes('signin-oidc')) {
            navigate('/');
        }
    }, [auth.isAuthenticated, navigate]);
    useEffect(() => {
        return auth.events.addAccessTokenExpiring(() => {
            auth.signinSilent();
        });
    }, [auth.events, auth.signinSilent]);
    if (auth.isLoading && auth.activeNavigator !== "signinSilent") {
        return React.createElement(OidcLoading, null);
    }
    if (auth.error) {
        return React.createElement(OidcError, null);
    }
    if (!auth.isAuthenticated) {
        return React.createElement(OidcSessionLost, null);
    }
    return React.createElement(React.Fragment, null, children);
};
