var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Task from "../../../../components/task/Task";
import { Descriptions } from "antd";
const InventoryTaskContainer = (props) => {
    const { id, task, isLoading } = props, tabs = __rest(props, ["id", "task", "isLoading"]);
    const info = [{ title: "Склад", value: task === null || task === void 0 ? void 0 : task.warehouse.storageTitle }];
    const renderTaskInfo = (items, title) => {
        return (React.createElement(Descriptions, { title: title, size: "small", column: 1 }, items.map((item) => {
            return React.createElement(Descriptions.Item, { label: item.title }, item.value);
        })));
    };
    return React.createElement(Task, Object.assign({ isLoading: isLoading, requisitesTab: renderTaskInfo(info, 'Реквизиты') }, tabs));
};
export default InventoryTaskContainer;
