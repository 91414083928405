import React from "react";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { useTaskLoading } from "../../../../hooks/api/taskLoadingApi/useTaskLoading";
import LoadingTaskListContainer from "../../../containers/task/loading/LoadingTaskListContainer";
import LoadingTaskListFilterContainer from "../../../containers/task/loading/LoadingTaskListFilterContainer";
import LoadingTaskListHeaderContainer from "../../../containers/task/loading/LoadingTaskListHeaderContainer";
import LoadingTaskPaginationContainer from "../../../containers/task/loading/LoadingTaskPaginationContainer";
const LoadingListPage = () => {
    const { taskList } = useTaskLoading();
    const props = {
        toolbarBottom: React.createElement(LoadingTaskPaginationContainer, { pagination: taskList.pagination, isLoading: taskList.loading, data: taskList.data }),
        toolbarTop: React.createElement(LoadingTaskListHeaderContainer, { data: taskList === null || taskList === void 0 ? void 0 : taskList.data, isLoading: taskList.loading, pagination: taskList === null || taskList === void 0 ? void 0 : taskList.pagination }),
        toolbarRight: React.createElement(LoadingTaskListFilterContainer, { isLoading: taskList.loading, objectClass: ObjectClass.TaskLoading, fetchList: taskList.paginationFilteredGetTaskList }),
    };
    return (React.createElement(Page, Object.assign({}, props),
        React.createElement(LoadingTaskListContainer, { pagination: taskList.pagination, taskList: taskList.data, isLoading: taskList.loading })));
};
export default LoadingListPage;
