import React from 'react';
import PaginationPanel from '../../components/PaginationPanel';
import { usePagination } from '../../hooks/usePagination';
import handbookUnitApi from '../../redux/api/handbookApi/handbookUnit';
const { useFetchListQuery } = handbookUnitApi;
const HandbookUnitsPaginationContainer = () => {
    const { page, pageSize, setPagination } = usePagination();
    const { data, isLoading } = useFetchListQuery({ page, pageSize });
    const handlerChange = (nextPage, nextPageSize) => {
        setPagination({
            page: nextPage,
            pageSize: nextPageSize,
        });
    };
    if (data) {
        return (React.createElement(PaginationPanel, { pagination: data.pagination, disabled: isLoading, onChange: handlerChange }));
    }
    return null;
};
export default HandbookUnitsPaginationContainer;
