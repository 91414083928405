import { Form, Modal } from 'antd';
import React, { useEffect } from 'react';
import HandbookCategoryForm from '../../components/HandbookCategoryForm/HandbookCategoryForm';
import handbookCategoryApi from '../../redux/api/handbookApi/handbookCategory';
const { useAddCategoryMutation, useEditCategoryMutation, } = handbookCategoryApi;
const HandbookCategoryModal = (props) => {
    const { initialValues, visible, onClose } = props;
    const [form] = Form.useForm();
    const [addCategory, { isLoading: addIsLoading }] = useAddCategoryMutation();
    const [editCategory, { isLoading: editIsLoading }] = useEditCategoryMutation();
    const isLoading = addIsLoading || editIsLoading;
    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                form.resetFields();
            }, 0);
        }
    }, [visible, initialValues]);
    const handleOk = () => form.submit();
    const handleCancel = () => {
        form.resetFields();
        onClose();
    };
    const handlerSubmit = (formValues) => {
        if (formValues.id === undefined) {
            addCategory({
                title: formValues.title,
                parentId: formValues.parentId.value,
            })
                .unwrap()
                .then(() => handleCancel());
        }
        else {
            editCategory({
                handbookCategoryId: formValues.id,
                values: {
                    title: formValues.title,
                    parentId: formValues.parentId.value,
                },
            })
                .unwrap()
                .then(() => handleCancel());
        }
    };
    return (React.createElement(Modal, { open: visible, confirmLoading: isLoading, onOk: handleOk, onCancel: handleCancel, title: `${(initialValues === null || initialValues === void 0 ? void 0 : initialValues.id) === undefined ? 'Добавить' : 'Редактировать'} категорию`, okText: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", cancelText: "\u041E\u0442\u043C\u0435\u043D\u0430" },
        React.createElement(HandbookCategoryForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues })));
};
export default HandbookCategoryModal;
