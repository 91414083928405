import React from "react";
import { useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import WarehouseSectionTypeFormContainer from "../../../containers/WarehouseSectionTypeFormContainer";
import WarehouseSectionTypesPaginationContainer from "../../../containers/WarehouseSectionTypesPaginationContainer";
import WarehouseSectionTypesTableContainer from "../../../containers/WarehouseSectionTypesTableContainer";
import { usePagination } from "../../../../hooks/usePagination";
import { useWarehouseSectionType } from "./useWarehouseSectionType";
import { DataFetchErrorFallback } from "../../../../components/DataFetchErrorFallback/DataFetchErrorFallback";
const WarehouseSectionsTypePage = () => {
    var _a, _b, _c, _d;
    const { id } = useParams();
    const warehouseId = +(id || 0);
    const { page, pageSize, setPagination } = usePagination();
    const { getStorageSectionTypeList, deleteStorageSectionType, createStorageSectionType, editStorageSectionType, getStorageSectionType } = useWarehouseSectionType({ warehouseId, page, pageSize });
    if (getStorageSectionTypeList === null || getStorageSectionTypeList === void 0 ? void 0 : getStorageSectionTypeList.error)
        return React.createElement(DataFetchErrorFallback, null);
    const warehouseSectionTypeList = ((_b = (_a = getStorageSectionTypeList === null || getStorageSectionTypeList === void 0 ? void 0 : getStorageSectionTypeList.data) === null || _a === void 0 ? void 0 : _a.storageSectionTypeList) === null || _b === void 0 ? void 0 : _b.data) || [];
    if (Number.isInteger(warehouseId)) {
        const pageProps = {
            toolbarBottom: React.createElement(WarehouseSectionTypesPaginationContainer, { pagination: (_d = (_c = getStorageSectionTypeList === null || getStorageSectionTypeList === void 0 ? void 0 : getStorageSectionTypeList.data) === null || _c === void 0 ? void 0 : _c.storageSectionTypeList) === null || _d === void 0 ? void 0 : _d.pagination, setPagination: setPagination, isLoading: getStorageSectionTypeList === null || getStorageSectionTypeList === void 0 ? void 0 : getStorageSectionTypeList.isLoading }),
            toolbarRight: React.createElement(WarehouseSectionTypeFormContainer, { getStorageSectionType: getStorageSectionType, createStorageSectionType: createStorageSectionType, editStorageSectionType: editStorageSectionType, warehouseId: warehouseId }),
        };
        return (React.createElement(Page, Object.assign({}, pageProps),
            React.createElement(WarehouseSectionTypesTableContainer, { deleteStorageSectionType: deleteStorageSectionType, isListLoading: getStorageSectionTypeList === null || getStorageSectionTypeList === void 0 ? void 0 : getStorageSectionTypeList.isLoading, data: warehouseSectionTypeList, warehouseId: warehouseId })));
    }
    return React.createElement("div", null, "ERROR");
};
export default WarehouseSectionsTypePage;
