var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button } from "antd";
import React, { useMemo } from "react";
import TaskHeaderBase from "../../../../components/task/TaskHeader/TaskHeaderBase";
import TaskStatus from "../../../../components/task/TaskStatus";
import Scope from "../../../../constants/enum/Scope";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import { useAdapters } from "../../../../hooks/useAdapters";
import EditExecutedAtButton, { TaskTypes } from "../../../../components/Modals/EditExecutedAtButton";
import { useDocumentAvailable } from "../../../../hooks/useDocumentAvailable";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { useAppDispatch } from "../../../../redux/store";
import { printDialogActions } from "../../../../redux/reducers/printDialogReducer";
import { getDocumentUrl } from "../../../../api/rest/requests/documents/documents";
import PrintActionsButton from "../../../../components/PrintActionsButton";
const СorrectionTaskHeaderContainer = ({ id, task, isLoading, onChangeTask, changeStatus, refetch }) => {
    var _a;
    const dispatch = useAppDispatch();
    const { open: openPrintDialog } = printDialogActions;
    const handlerChangeStatus = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield changeStatus({ variables: { input: { taskId: id, input: values } } });
        yield refetch();
    });
    const documentsAvailable = useDocumentAvailable(ObjectClass.TaskCorrection);
    const handlerPrintAction = (documentId) => dispatch(openPrintDialog(getDocumentUrl(documentId, id.toString())));
    const { adaptTaskDtoStatusToBaseTaskStatus } = useAdapters();
    const editingAllowed = useMemo(() => (task ? taskHelpers.hasScope(task, Scope.EDIT) : false), [task]);
    const isLocked = useMemo(() => taskHelpers.hasExternalId(task), [task]);
    return (React.createElement(TaskHeaderBase, { externalId: task === null || task === void 0 ? void 0 : task.externalId, id: task === null || task === void 0 ? void 0 : task.id, userName: (_a = task === null || task === void 0 ? void 0 : task.user) === null || _a === void 0 ? void 0 : _a.userName, createdAt: task === null || task === void 0 ? void 0 : task.createdAt, executedAt: task === null || task === void 0 ? void 0 : task.executedAt, isLoading: isLoading, taskTypeTitle: "\u041A\u043E\u0440\u0440\u0435\u043A\u0442\u0438\u0440\u043E\u0432\u043A\u0430", actions: React.createElement(React.Fragment, null,
            React.createElement(PrintActionsButton, { printActions: documentsAvailable, onItemClick: handlerPrintAction }),
            !isLocked && (React.createElement(React.Fragment, null,
                editingAllowed ? (React.createElement(Button, { loading: isLoading, onClick: () => onChangeTask() }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C")) : null,
                React.createElement(EditExecutedAtButton, { refetch: refetch, taskType: TaskTypes.Correction, taskId: task === null || task === void 0 ? void 0 : task.id }))),
            (task === null || task === void 0 ? void 0 : task.status) ? React.createElement(TaskStatus, { disabled: isLocked, loading: isLoading, status: adaptTaskDtoStatusToBaseTaskStatus(task.status, isLoading), onMenuItemClick: handlerChangeStatus }) : null) }));
};
export default СorrectionTaskHeaderContainer;
