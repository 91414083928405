import { useEffect, useReducer } from "react";
import { useCellData } from "./api/useCellData";
import { useSectionData } from "./api/useSectionData";
import { useWarehouseDate } from "./api/useWarehouseData";
import { initialState } from "./state";
import { warehouseDataReducer } from "./state/reduser";
import { useLoadingData } from "./api/useloadingData";
export const useWarehouseState = (warehouseId, enableCellColoring) => {
    const [state, dispatch] = useReducer(warehouseDataReducer, initialState);
    useWarehouseDate(dispatch, warehouseId);
    useSectionData(dispatch, warehouseId);
    useCellData(dispatch, warehouseId);
    useLoadingData(dispatch, warehouseId, enableCellColoring);
    useEffect(() => {
        var _a, _b, _c;
        if (!state.isCellDtoLoading && !state.isCellsLoading && !state.isSectionsLoading && !((_a = state.topology) === null || _a === void 0 ? void 0 : _a.length) && (((_b = state.warehouseSection) === null || _b === void 0 ? void 0 : _b.length) || ((_c = state.warehouseCell) === null || _c === void 0 ? void 0 : _c.length))) {
            dispatch({ type: "CREATE_TOPOLOGY" });
        }
    }, [state]);
    useEffect(() => {
        console.log("state", state);
    }, [state]);
    return {
        state,
        dispatch,
    };
};
