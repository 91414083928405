/* eslint-disable camelcase */
export const availabilityOnDateAdapter = (dto) => {
    const { quantity, clientTitle, skuId, productTitle, productType, storageCellTitle, taskShippingDate, taskShippingIsFinal, taskLoadingIsFinal, taskLoadingDate, taskLoadingId, taskLoadingInRange, taskShippingId, taskShippingInRange, warehouseTitle, clientInn, } = dto;
    return {
        quantity,
        clientTitle,
        skuId,
        productTitle,
        productType,
        storageCellTitle,
        taskShippingDate,
        taskShippingIsFinal,
        taskLoadingIsFinal,
        taskLoadingDate,
        taskLoadingId,
        taskLoadingInRange,
        taskShippingId,
        taskShippingInRange,
        warehouseTitle,
        clientInn,
    };
};
export const availabilityOnDateTotalAdapter = (dto) => {
    const { totalCount, taskLoadingCount, taskLoadingQuantity, taskShippingQuantity, taskShippingCount, taskShippingSkus, taskLoadingSkus, quantityBeginning, quantityEnd } = dto;
    return {
        totalCount,
        taskLoadingCount,
        taskLoadingQuantity,
        taskShippingQuantity,
        taskShippingCount,
        taskShippingSkus,
        taskLoadingSkus,
        quantityBeginning,
        quantityEnd
    };
};
