import { DataType } from "../../../../../api/graphql/common";
import { Checkbox, Modal } from "antd";
import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
const { confirm } = Modal;
export const createDataValue = (attr, type) => {
    switch (attr.dataType) {
        case DataType.Handbook:
            return type === "after" ? attr.valueAfterReadable : attr.valueBeforeReadable;
        case DataType.Boolean:
            return type === "after" ? attr.valueAfter === "True" : attr.valueBefore === "True";
        default:
            return type === "after" ? attr.valueAfter : attr.valueBefore;
    }
};
export const createInfoCell = (d, unit) => {
    if (d || typeof (d === null || d === void 0 ? void 0 : d.value) === "boolean") {
        if (typeof d.value === "boolean") {
            return React.createElement(Checkbox, { defaultChecked: d.value, key: `${d.value}`, disabled: true });
        }
        return `${d.value}${unit ? " " + unit : ""}`;
    }
    return React.createElement(React.Fragment, null);
};
export const labeledValueRender = (value) => {
    if (value && value.label) {
        return value.label;
    }
    return value;
};
export const showEditConfirm = (callback, editingKey) => {
    confirm({
        title: `Изменения в товарной позиции #${editingKey} не сохранены`,
        icon: React.createElement(ExclamationCircleOutlined, null),
        content: `Вы не сохранили изменения в товарной позиции #${editingKey}. Отменить все изменения?`,
        okText: "Да",
        okType: "danger",
        cancelText: "Нет",
        onOk() {
            callback();
        },
    });
};
export const processAttributes = (formData, attributes) => {
    return Object.keys(formData).reduce((acc, key) => {
        if (!key.startsWith("attr_"))
            return acc;
        const attributeId = parseInt(key.replace("attr_", ""), 10);
        const attributeValue = formData[key];
        const attribute = attributes.find((attr) => attr.id === attributeId);
        let valueAfter = attributeValue;
        if (typeof attributeValue === "boolean") {
            acc.push({ id: attributeId, valueAfter: attributeValue });
        }
        else if (attributeValue && typeof attributeValue === "object") {
            if ("_isUTC" in attributeValue) {
                valueAfter = moment(attributeValue).format(attributeValue["_f"] || "YYYY-MM-DD");
            }
            else if ("value" in attributeValue) {
                valueAfter = attributeValue["key"];
            }
            acc.push({ id: attributeId, valueAfter });
        }
        else {
            valueAfter = attribute && attribute.valueAfterReadable === attributeValue ? attribute.valueAfter : `${attributeValue}`;
            acc.push({ id: attributeId, valueAfter });
        }
        return acc;
    }, []);
};
