import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { useLocation } from 'react-router-dom';
import NavigationTab from './NavigationTab';
import NavigationPane from './NavigationPane';
import NavigationCategory from './NavigationCategory';
import NavigationItem from './NavigationItem';
const Navigation = (props) => {
    const { navigationData } = props;
    const [activeTab, setActiveTab] = useState(0);
    const location = useLocation();
    useEffect(() => {
        for (let i = 0; i < navigationData.length; i++) {
            const tab = navigationData[i];
            tab.sections.forEach((section) => {
                const res = section.links.find((link) => link.route.url() === location.pathname);
                if (res && activeTab !== i) {
                    setActiveTab(i);
                }
            });
        }
    }, []);
    return (React.createElement("div", { className: "app-navigation" },
        React.createElement("div", { className: "nav-tabs" }, navigationData.map((tab, tabIndex) => (React.createElement(NavigationTab, { key: uniqueId(), isActive: activeTab === tabIndex, icon: tab.icon, onClick: () => setActiveTab(tabIndex) })))),
        React.createElement("div", { className: "tab-content" }, navigationData.map((tab, tabIndex) => (React.createElement(NavigationPane, { key: uniqueId(), title: tab.title, isActive: activeTab === tabIndex, index: tabIndex }, tab.sections.map((section) => (React.createElement(NavigationCategory, { key: uniqueId(), title: section.title }, section.links.map((link) => (React.createElement(NavigationItem, { key: uniqueId(), title: link.title, icon: link.icon, isExternal: link.route.isExternal, link: link.route.url() }))))))))))));
};
export default Navigation;
