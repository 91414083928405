import { Spin } from 'antd';
import React from 'react';
import StatusConfigurator from '../../components/StatusConfigurator';
import Centered from '../../components/ui/Centered';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { statusConfiguratorActions } from '../../redux/reducers/statusConfiguratorReducer';
import statusConfiguratorRelationApi from '../../redux/api/statusConfiguratorApi/relation';
import statusConfiguratorStatusApi from '../../redux/api/statusConfiguratorApi';
const { useFetchStatusListQuery, useEditStatusMutation, } = statusConfiguratorStatusApi;
const { useFetchRelationListQuery } = statusConfiguratorRelationApi;
const { select: selectStatus } = statusConfiguratorActions;
const StatusConfiguratorContainer = (props) => {
    const { objectClass } = props;
    const dispatch = useAppDispatch();
    const { data: statuses, isLoading: statusesIsLoading } = useFetchStatusListQuery(objectClass);
    const { data: relations, isLoading: relationsIsLoading } = useFetchRelationListQuery(objectClass);
    const [editStatusRequest] = useEditStatusMutation();
    if (statusesIsLoading || relationsIsLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, { spinning: true })));
    }
    return (React.createElement(StatusConfigurator
    // loading={
    //   statuses.loadingState === LoadingState.Loading
    //   || relations.loadingState === LoadingState.Loading
    // }
    , { 
        // loading={
        //   statuses.loadingState === LoadingState.Loading
        //   || relations.loadingState === LoadingState.Loading
        // }
        onAddClick: () => dispatch(selectStatus('ADD')), objectClass: objectClass, onStatusChange: (statusId, status) => (editStatusRequest({
            objectClass,
            statusId,
            values: status,
        })), onStatusSelect: (value) => dispatch(selectStatus(value)), statusList: statuses !== null && statuses !== void 0 ? statuses : [], relationList: relations !== null && relations !== void 0 ? relations : [] }));
};
export default StatusConfiguratorContainer;
