import React from "react";
import { Table } from "antd";
const Cell = (props) => {
    const { children } = props;
    return (React.createElement("td", Object.assign({}, props),
        React.createElement("div", { style: { minWidth: "50px" } }, children)));
};
function SearchModalTable(props) {
    const { selectedKeys, onSelect, tableProps, data, columns, disableSelection, isMultiple = true } = props;
    const handleSelect = (record, selected) => {
        const id = parseInt(record.id, 10);
        if (isMultiple) {
            onSelect([id], selected, isMultiple);
        }
        else {
            onSelect(selected ? [id] : [], selected, isMultiple);
        }
    };
    const handleSelectAll = (selected, selectedRows, changeRows) => {
        const idList = changeRows.map(({ id }) => parseInt(id, 10));
        const newSelectedKeys = selected ? [...new Set([...selectedKeys, ...idList])] : selectedKeys.filter((key) => !idList.includes(key));
        onSelect(newSelectedKeys, selected);
    };
    const rowSelection = {
        type: isMultiple ? "checkbox" : "radio",
        selectedRowKeys: selectedKeys.map((id) => id.toString()),
        onSelect: handleSelect,
        onSelectAll: isMultiple ? handleSelectAll : undefined,
    };
    return (React.createElement(Table, Object.assign({ components: {
            body: {
                cell: Cell,
            },
        }, style: {
            whiteSpace: "nowrap",
            height: "100%",
            maxHeight: "100%",
            // overflow: 'auto'
        }, bordered: true, pagination: false, size: "small", scroll: {
            x: true,
        } }, tableProps, { columns: columns, dataSource: data, rowSelection: disableSelection ? undefined : rowSelection })));
}
export default SearchModalTable;
