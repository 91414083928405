import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PaginationPanel from '../../components/PaginationPanel';
import { usePagination } from '../../hooks/usePagination';
import productApi from '../../redux/api/productApi';
import { TYPE_ID_PARAM } from '../pages/product/ProductsPage';
const { useFetchProductListQuery } = productApi;
const ProductListPaginationContainer = () => {
    const [searchParams] = useSearchParams();
    const productTypeParam = searchParams.get(TYPE_ID_PARAM);
    const productTypeId = productTypeParam ? +productTypeParam : null;
    const { page, pageSize, setPagination } = usePagination();
    const { data, isLoading } = useFetchProductListQuery({
        page, pageSize, filter: { productTypeId },
    }, {
        skip: !productTypeParam,
    });
    const handlerChange = (nextPage, nextPageSize) => {
        setPagination({
            page: nextPage,
            pageSize: nextPageSize,
        });
    };
    if (data) {
        return (React.createElement(PaginationPanel, { pagination: data.pagination, disabled: isLoading || !productTypeId, onChange: handlerChange }));
    }
    return null;
};
export default ProductListPaginationContainer;
