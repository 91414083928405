import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { links } from "../../../../constants/links";
import ShippingDetailNomenclatureItem from "../../../conponents/task/shipping/ShippingDetailNomenclature/ShippingDetailNomenclatureItem";
import CommentsContainer from "../../../containers/CommentsContainer";
import FilesContainer from "../../../containers/FilesContainer";
import ProductInfoDrawerContainer from "../../../containers/ProductInfoDrawerContainer";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import ShippingStatusHistoryContainer from "../../../containers/task/shipping/ShippingStatusHistoryContainer";
import ShippingTaskContainer from "../../../containers/task/shipping/ShippingTaskContainer";
import ShippingTaskHeaderContainer from "../../../containers/task/shipping/ShippingTaskHeaderContainer";
import ShippingTaskNomenclatureContainer from "../../../containers/task/shipping/ShippingTaskNomenclatureContainer";
import { useTaskShipping } from "../../../../hooks/api/taskShippingApi/useTaskShipping";
const ShippingTaskPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const objectInfo = {
        objectClass: ObjectClass.TaskShipping,
        objectId: taskId,
    };
    const { task } = useTaskShipping();
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const handlerChangeTask = () => navigate(links.taskShippingLinks.edit.url(taskId));
    const pageProps = {
        toolbarTop: React.createElement(ShippingTaskHeaderContainer, { changeStatus: task.changeStatus, isLoading: task.loading, task: task.data, id: taskId, onChangeTask: handlerChangeTask, refetch: task.refetch }),
        toolbarRight: React.createElement(CommentsContainer, Object.assign({}, objectInfo)),
    };
    const containerProps = {
        id: taskId,
        nomenclatureTab: (React.createElement(ShippingTaskNomenclatureContainer, { refetch: task === null || task === void 0 ? void 0 : task.refetch, isLoading: task === null || task === void 0 ? void 0 : task.loading, task: task === null || task === void 0 ? void 0 : task.data }, (detailProps) => React.createElement(ShippingDetailNomenclatureItem, Object.assign({}, detailProps)))),
        filesTab: React.createElement(FilesContainer, Object.assign({}, objectInfo)),
        historyTab: React.createElement(ShippingStatusHistoryContainer, { taskId: taskId }),
    };
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(ShippingTaskContainer, Object.assign({ task: task.data, isLoading: task.loading }, containerProps)),
        React.createElement(ProductInfoDrawerContainer, null),
        React.createElement(SkuInfoDrawerContainer, null)));
};
export default ShippingTaskPage;
