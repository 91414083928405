import { Button, Col, Form, Row, Space, Table, Tooltip, Typography } from "antd";
import React, { useEffect } from "react";
import MDIcon from "../../../../../components/ui/MDIcon";
import CorrectionDetailNomenclatureCell from "../CorrectionDetailNomenclatureCell";
import { useTableColumns } from "./hooks/useTableColumns";
import { useTableData } from "./hooks/useTableData";
import { useFormContext } from "./CorrectionDetailNomenclatureFormItemContainer";
const { Title, Text } = Typography;
const CorrectionDetailNomenclatureFormItem = (props) => {
    const { nomenclature, isEdit = false, isLoading, onAddCreated, onDeleteCreated, onEditCreated, onSkuInfoClick, onInfoClick } = props;
    const { attributes, productTypeTitle, productTypeId, productTitle, productAfter, productBefore, storageCell, id, skuId, quantityAfter, quantityBefore, unit, reason } = nomenclature;
    const context = useFormContext();
    if (!context) {
        throw new Error("useFormContext must be used within a FormContext.Provider");
    }
    useEffect(() => {
        setActiveProductTypeTitle((productAfter === null || productAfter === void 0 ? void 0 : productAfter.titleCompiled) || "");
    }, []);
    const { form, setActiveProductTypeTitle } = context;
    const columns = useTableColumns({ data: nomenclature, flags: { isLoading, isEdit }, actions: { onAddCreated, onDeleteCreated, onEditCreated, onSkuInfoClick } });
    const getData = useTableData({ id, skuId, unit, reason, quantityBefore, quantityAfter, productAfter, productBefore, attributes, storageCell, productType: { value: productTypeId, label: productTypeTitle } });
    const handlerOpenInfo = () => {
        var _a;
        if (onInfoClick && ((_a = nomenclature.productBefore) === null || _a === void 0 ? void 0 : _a.id))
            onInfoClick(nomenclature.productBefore.id);
    };
    return (React.createElement("div", { className: "mb-5" },
        React.createElement(Row, null,
            React.createElement(Col, { flex: "auto" },
                React.createElement(Space, { style: { width: "auto" } },
                    React.createElement(Title, { level: 4 }, productTypeTitle),
                    React.createElement("div", { className: "mb-2" },
                        React.createElement(Text, { underline: true }, productTitle)))),
            React.createElement(Col, null,
                React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u0442\u0438\u043F\u0435 \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                    React.createElement(Button, { loading: isLoading, type: "primary", size: "small", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: handlerOpenInfo })))),
        React.createElement(Form, { form: form, component: false },
            React.createElement(Table, { style: { whiteSpace: "nowrap" }, components: {
                    body: {
                        cell: CorrectionDetailNomenclatureCell,
                    },
                }, columns: columns, dataSource: getData(), bordered: true, pagination: false, size: "small", scroll: { x: true } }))));
};
export default CorrectionDetailNomenclatureFormItem;
