import React from "react";
import PaginationPanel from "../../../../components/PaginationPanel";
import { usePagination } from "../../../../hooks/usePagination";
import { useAdapters } from "../../../../hooks/useAdapters";
const ShippingTaskPaginationContainer = ({ pagination, data, isLoading }) => {
    const { setPagination } = usePagination();
    const { normalizePagination } = useAdapters();
    const handlerChange = (nextPage, nextPageSize) => {
        setPagination({
            page: nextPage,
            pageSize: nextPageSize,
        });
    };
    if (data) {
        return React.createElement(PaginationPanel, { pagination: normalizePagination(pagination), disabled: isLoading, onChange: handlerChange });
    }
    return null;
};
export default ShippingTaskPaginationContainer;
