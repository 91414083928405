import React from "react";
import { useAvailabilityOnDate } from "../store/AvailabilityOnDateProvider";
import { Col, Row, Spin, Statistic, Tooltip } from "antd";
const taskCountFormatter = (value) => (React.createElement(Tooltip, { title: "Количество задач" }, value));
const taskQuantitySumFormatter = (value) => (React.createElement(Tooltip, { title: "Количество по учёту" },
    "/ ",
    value));
export const AvailabilityOnDateTotal = () => {
    const state = useAvailabilityOnDate();
    return (React.createElement(Row, { gutter: 16, justify: "space-around" },
        React.createElement(Col, { key: 0 },
            React.createElement(Spin, { spinning: state.loading },
                React.createElement(Statistic, { groupSeparator: '', title: "\u041F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u044F", value: state.total.totalCount }))),
        React.createElement(Col, { key: 1 },
            React.createElement(Spin, { spinning: state.loading },
                React.createElement(Statistic, { groupSeparator: '', title: "\u041F\u0440\u0438\u0445\u043E\u0434", value: state.total.arrivalInRageCount, suffix: taskQuantitySumFormatter(state.total.arrivalQuantity), formatter: taskCountFormatter }))),
        React.createElement(Col, { key: 2 },
            React.createElement(Spin, { spinning: state.loading },
                React.createElement(Statistic, { groupSeparator: '', title: "\u0420\u0430\u0441\u0445\u043E\u0434", value: state.total.departureInRageCount, suffix: taskQuantitySumFormatter(state.total.departureQuantity), formatter: taskCountFormatter }))),
        React.createElement(Col, { key: 3 },
            React.createElement(Spin, { spinning: state.loading },
                React.createElement(Statistic, { groupSeparator: '', title: "\u041D\u0430 \u043D\u0430\u0447\u0430\u043B\u043E \u0434\u043D\u044F", value: state.total.quantityBeginning }))),
        React.createElement(Col, { key: 4 },
            React.createElement(Spin, { spinning: state.loading },
                React.createElement(Statistic, { groupSeparator: '', title: "\u041D\u0430 \u043A\u043E\u043D\u0435\u0446 \u0434\u043D\u044F", value: state.total.quantityEnd })))));
};
