import React from 'react';
import { Typography } from 'antd';
import AttrField from './AttrField';
const AttrFieldBlock = (props) => {
    const { attributes, title, required, namePath, mode, } = props;
    const returnTitle = () => {
        if (title) {
            return (React.createElement(Typography.Text, { strong: true }, title));
        }
        return null;
    };
    if (attributes.length > 0) {
        return (React.createElement(React.Fragment, null,
            returnTitle(),
            attributes.map((attr) => (React.createElement(AttrField, { attribute: attr, key: attr.title, namePath: namePath, required: required, mode: mode })))));
    }
    return null;
};
export default AttrFieldBlock;
