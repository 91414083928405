var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import AppFragment from "../../../../components/Fragment";
import { useAvailabilityOnDate, useAvailabilityOnDateDispatch } from "../store/AvailabilityOnDateProvider";
import { Button, DatePicker, Form } from "antd";
import moment from "moment";
import { AvailabilityOnDateActionType } from "../store/state";
import { ProductTypeSelect } from "../../../../components/Select/ProductTypeSelect";
import WarehouseSelect from "../../../../components/WarehouseSelect/WarehouseSelect";
import ClientSelect from "../../../../components/ClientSelect/ClientSelect";
import { getNewReportExel } from "../../../../api/rest/requests/reports/availabilityOnDate";
const dateFormat = 'YYYY-MM-DD';
export const defaultValues = {
    productTypeId: undefined,
    warehouseId: undefined,
    date: moment(new Date().toISOString(), dateFormat)
};
export const AvailabilityOnDateFilter = () => {
    const state = useAvailabilityOnDate();
    const dispatch = useAvailabilityOnDateDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const handlerReset = () => {
        form.resetFields();
    };
    const handleDownload = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        setLoading(true);
        const filterToRequest = {
            warehouseId: (_a = state.filterFormState.warehouseId) !== null && _a !== void 0 ? _a : 0,
            productTypeId: (_b = state.filterFormState.productTypeId) !== null && _b !== void 0 ? _b : 0,
            clients: (_c = state.filterFormState.clients) !== null && _c !== void 0 ? _c : [],
            date: state.filterFormState.date.format("YYYY-MM-DD")
        };
        yield getNewReportExel(filterToRequest, () => {
            setLoading(false);
        });
    });
    const onChange = (changedValues, _) => {
        const form = state.filterFormState;
        Object.entries(changedValues).forEach((value) => {
            switch (value[0]) {
                case "date":
                    form.date = value[1];
                    break;
                case "warehouseId":
                    form.warehouseId = value[1].value;
                    break;
                case "productTypeId":
                    form.productTypeId = value[1];
                    break;
                case "clients":
                    form.clients = value[1].map((x) => x.value);
                    break;
            }
        });
        dispatch({ type: AvailabilityOnDateActionType.SET_FILTER_FORM_STATE, payload: form });
    };
    const formItemStyle = {
        marginBottom: '10px'
    };
    return (React.createElement(AppFragment, { title: "Фильтр", buttons: React.createElement(Button.Group, { style: { width: "100%" } },
            React.createElement(Button, { block: true, onClick: handlerReset }, "\u0421\u0431\u0440\u043E\u0441"),
            React.createElement(Button, { onClick: handleDownload, loading: loading || state.loading, disabled: state.items.length === 0, size: "middle", icon: React.createElement("span", { className: "mdi mdi-download" }) })) },
        React.createElement(Form, { form: form, layout: "vertical", initialValues: defaultValues, onValuesChange: onChange },
            React.createElement(Form.Item, { name: "warehouseId", label: "\u0421\u043A\u043B\u0430\u0434", style: formItemStyle, rules: [{ required: true }] },
                React.createElement(WarehouseSelect, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434" })),
            React.createElement(Form.Item, { name: "date", label: "\u0414\u0430\u0442\u0430", style: formItemStyle, rules: [{ required: true }] },
                React.createElement(DatePicker, { style: { width: '100%' } })),
            React.createElement(Form.Item, { name: "clients", label: "\u041A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442", style: formItemStyle },
                React.createElement(ClientSelect, { mode: "multiple", placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442\u0430" })),
            React.createElement(Form.Item, { name: "productTypeId", label: "\u0422\u0438\u043F \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", style: formItemStyle },
                React.createElement(ProductTypeSelect, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0442\u0438\u043F" })))));
};
