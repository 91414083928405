import handbookApi, { HANDBOOK_CATEGORY_TAG } from '..';
import dtoToEntity from '../../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../../constants/enum/Method';
const URI = 'handbookCategory';
const handbookCategoryApi = handbookApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchCategoryList: builder.query({
            query: (args) => ({
                method: Method.GET,
                url: `${URI}/list`,
                params: args,
            }),
            transformResponse: (response) => ({
                list: response.data.map(dtoToEntity.handbookCategoryAdapter),
                pagination: dtoToEntity.paginationAdapter(response.pagination),
            }),
            providesTags: (result) => {
                if (result) {
                    return [
                        ...result.list.map(({ id }) => ({ type: HANDBOOK_CATEGORY_TAG, id })),
                        HANDBOOK_CATEGORY_TAG,
                    ];
                }
                return [HANDBOOK_CATEGORY_TAG];
            },
        }),
        fetchCategory: builder.query({
            query: (handbookCategoryId) => ({
                method: Method.GET,
                url: `${URI}/${handbookCategoryId}`,
            }),
            transformResponse: (response) => (dtoToEntity.handbookCategoryAdapter(response)),
            providesTags: (result, error, id) => [
                HANDBOOK_CATEGORY_TAG, { type: HANDBOOK_CATEGORY_TAG, id },
            ],
        }),
        addCategory: builder.mutation({
            query: (values) => ({
                method: Method.POST,
                url: `${URI}/add`,
                body: values,
            }),
            transformResponse: (response) => (dtoToEntity.handbookCategoryAdapter(response)),
            invalidatesTags: [HANDBOOK_CATEGORY_TAG],
        }),
        editCategory: builder.mutation({
            query: (args) => {
                const { handbookCategoryId, values } = args;
                return {
                    method: Method.PUT,
                    url: `${URI}/${handbookCategoryId}`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.handbookCategoryAdapter(response)),
            invalidatesTags: (result, error, { handbookCategoryId }) => [
                HANDBOOK_CATEGORY_TAG, { type: HANDBOOK_CATEGORY_TAG, handbookCategoryId },
            ],
        }),
        deleteCategory: builder.mutation({
            query: (handbookCategoryId) => ({
                method: Method.DELETE,
                url: `${URI}/${handbookCategoryId}`,
            }),
            transformResponse: (_, __, id) => id,
            invalidatesTags: (result, error, id) => [
                HANDBOOK_CATEGORY_TAG, { type: HANDBOOK_CATEGORY_TAG, id },
            ],
        }),
    }),
});
export default handbookCategoryApi;
