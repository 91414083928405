var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CheckOutlined, ExclamationCircleOutlined, MenuOutlined, MinusOutlined, } from '@ant-design/icons';
import { Button, Dropdown, Empty, Modal, Row, Space, Table, } from 'antd';
import React, { useCallback, useState, useEffect, } from 'react';
import { SortableContainer, SortableElement, SortableHandle, } from 'react-sortable-hoc';
import ProductDataType from '../../constants/enum/ProductDataType';
import { getColumnType, getProductDataTypeName } from '../../helpers/function';
import MDIcon from '../ui/MDIcon';
const { confirm } = Modal;
const booleanIcon = (value) => (value
    ? React.createElement(CheckOutlined, { style: { color: 'var(--green)' } })
    : React.createElement(MinusOutlined, { style: { color: 'var(--red)' } }));
const DragHandle = SortableHandle(() => React.createElement(MenuOutlined, { style: { cursor: 'grab', color: '#999' } }));
const SortableItem = SortableElement((props) => (React.createElement("tr", Object.assign({}, props))));
const SortableBody = SortableContainer((props) => (React.createElement("tbody", Object.assign({}, props))));
const ProductTypeAttrTable = (props) => {
    const { attributes, onAdd, onEdit, onDelete, onSort, } = props;
    const [data, setData] = useState([]);
    useEffect(() => {
        var _a;
        setData((_a = attributes === null || attributes === void 0 ? void 0 : attributes.map((attr) => {
            var _a, _b, _c, _d, _e;
            return ({
                id: (_a = attr.id) !== null && _a !== void 0 ? _a : -1,
                key: (_b = attr.id) !== null && _b !== void 0 ? _b : -1,
                title: (_c = attr.title) !== null && _c !== void 0 ? _c : '',
                dataType: (_d = attr.dataType) !== null && _d !== void 0 ? _d : ProductDataType.VARCHAR,
                dimension: (_e = attr.dimension) !== null && _e !== void 0 ? _e : '',
                isRequired: !!attr.isRequired,
                isReadOnly: !!attr.isReadOnly,
                isPersonal: !!attr.isPersonal,
                isMain: !!attr.isMain,
                inFilter: !!attr.inFilter,
                canBeSummarized: !!attr.canBeSummarized,
                inPrint: !!attr.inPrint,
            });
        })) !== null && _a !== void 0 ? _a : []);
    }, [attributes]);
    const handlerEdit = (id) => {
        onEdit(id);
    };
    const handlerDelete = (record) => {
        confirm({
            title: 'Удаление свойства',
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: (React.createElement(React.Fragment, null,
                'Вы уверены, что хотите удалить свойство ',
                React.createElement("b", null, record.title),
                "?")),
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отмена',
            onOk() {
                onDelete(record.id);
            },
        });
    };
    const menu = useCallback((record) => ({
        items: [
            {
                key: "product_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => handlerEdit(record.id)
            },
            {
                key: "product_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => handlerDelete(record)
            }
        ]
    }), []);
    const columns = [
        getColumnType('sort', '', undefined, {
            width: '32px',
            className: 'drag-visible',
            render: () => React.createElement(DragHandle, null),
        }),
        getColumnType('title', 'Свойство'),
        getColumnType('dataType', 'Тип', undefined, {
            render: (value) => getProductDataTypeName(value),
        }),
        getColumnType('dimension', 'Размерность'),
        getColumnType('isRequired', 'Обязательный', undefined, {
            render: (value) => booleanIcon(value),
        }),
        getColumnType('isPersonal', 'Персональный', undefined, {
            render: (value) => booleanIcon(value),
        }),
        getColumnType('isReadOnly', 'Только для чтения', undefined, {
            render: (value) => booleanIcon(value),
        }),
        getColumnType('isMain', 'Основной', undefined, {
            render: (value) => booleanIcon(value),
        }),
        getColumnType('inFilter', 'Фильтр', undefined, {
            render: (value) => booleanIcon(value),
        }),
        getColumnType('canBeSummarized', 'Сумма', undefined, {
            render: (value) => booleanIcon(value),
        }),
        getColumnType('inPrint', 'Печать', true, {
            render: (value) => booleanIcon(value),
        }),
        getColumnType('actions', '', true, {
            render: (value, record) => (React.createElement(Dropdown, { menu: menu(record), trigger: ['click'] },
                React.createElement(Button, { size: "small", icon: React.createElement(MDIcon, { icon: "pencil" }) }))),
        }),
    ];
    const handlerSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const result = data.map((item) => item.id);
            const draggableItem = result.splice(oldIndex, 1)[0];
            result.splice(newIndex, 0, draggableItem);
            setData(result.map((id) => data.find((item) => item.id === id)));
            onSort(result);
        }
    };
    const DraggableContainer = (dragProps) => (React.createElement(SortableBody, Object.assign({ useDragHandle: true, disableAutoscroll: true, helperClass: "row-dragging", onSortEnd: handlerSortEnd }, dragProps)));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const DraggableBodyRow = (_a) => {
        var { className, style } = _a, restProps = __rest(_a, ["className", "style"]);
        const index = data.findIndex((item) => item.id === restProps['data-row-key']);
        return React.createElement(SortableItem, Object.assign({ index: index }, restProps));
    };
    if (attributes && attributes.length) {
        return (React.createElement(Table, { bordered: true, size: "small", columns: columns, dataSource: data, pagination: false, components: {
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            }, summary: () => (React.createElement(Table.Summary.Row, null,
                React.createElement(Table.Summary.Cell, { index: 0, colSpan: columns.length },
                    React.createElement(Row, { justify: "end" },
                        React.createElement(Button, { type: "primary", onClick: onAdd, size: "small", icon: React.createElement(MDIcon, { icon: "plus-circle", className: "ant-dropdown-menu-item-icon" }) }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0432\u043E\u0439\u0441\u0442\u0432"))))) }));
    }
    return (React.createElement("div", { style: { border: '1px solid rgba(0, 0, 0, 0.06)', padding: 25 } },
        React.createElement(Empty, { description: (React.createElement(Space, { direction: "vertical", align: "center" },
                React.createElement("div", null, "\u0412 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438 \u043D\u0435\u0442 \u0441\u0432\u043E\u0439\u0441\u0442\u0432"),
                React.createElement(Button, { onClick: onAdd }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0432\u043E\u0439\u0441\u0442\u0432\u043E"))) })));
};
export default ProductTypeAttrTable;
