import React from "react";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { useTaskPartition } from "../../../../hooks/api/taskPartitionApi/useTaskPartition";
import PartitionTaskListContainer from "../../../containers/task/partition/PartitionTaskListContainer";
import PartitionTaskListFilterContainer from "../../../containers/task/partition/PartitionTaskListFilterContainer";
import PartitionTaskListHeaderContainer from "../../../containers/task/partition/PartitionTaskListHeaderContainer";
import PartitionTaskPaginationContainer from "../../../containers/task/partition/PartitionTaskPaginationContainer";
const PartitionListPage = () => {
    const { taskList } = useTaskPartition();
    const props = {
        toolbarBottom: React.createElement(PartitionTaskPaginationContainer, { pagination: taskList.pagination, isLoading: taskList.loading, data: taskList.data }),
        toolbarTop: React.createElement(PartitionTaskListHeaderContainer, { data: taskList === null || taskList === void 0 ? void 0 : taskList.data, isLoading: taskList.loading, pagination: taskList === null || taskList === void 0 ? void 0 : taskList.pagination }),
        toolbarRight: React.createElement(PartitionTaskListFilterContainer, { objectClass: ObjectClass.TaskPartition, isLoading: taskList.loading, fetchList: taskList.paginationFilteredGetTaskList }),
    };
    return (React.createElement(Page, Object.assign({}, props),
        React.createElement(PartitionTaskListContainer, { taskList: taskList === null || taskList === void 0 ? void 0 : taskList.data, pagination: taskList === null || taskList === void 0 ? void 0 : taskList.pagination, isLoading: taskList === null || taskList === void 0 ? void 0 : taskList.loading })));
};
export default PartitionListPage;
