import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, Table } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { getColumnType } from "../../helpers/function";
import useResize from "../../hooks/useResize";
import MDIcon from "../ui/MDIcon";
const { confirm } = Modal;
const DEBOUNCE_TIMEOUT = 100;
const ProductTable = (props) => {
    const { items, loading, onDelete, onEdit, onInfoClick } = props;
    const wrapper = useRef(null);
    const [tableHeight, setTableHeight] = useState(0);
    const updateDimensions = debounce(() => {
        var _a;
        const antTableHeader = (_a = wrapper.current) === null || _a === void 0 ? void 0 : _a.querySelector(".ant-table-header");
        const wrapperHeight = wrapper.current ? wrapper.current.clientHeight : 0;
        const headerHeight = antTableHeader ? antTableHeader.clientHeight : 0;
        setTableHeight(Math.floor(wrapperHeight - headerHeight) - 1);
    }, DEBOUNCE_TIMEOUT);
    useResize(updateDimensions, []);
    const handlerEdit = (id) => {
        onEdit(id);
    };
    const handlerDelete = (record) => {
        confirm({
            title: "Удаление продукции",
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: (React.createElement(React.Fragment, null,
                "Вы уверены, что хотите удалить продукцию ",
                React.createElement("b", null, record.title),
                "?")),
            okText: "Да",
            okType: "danger",
            cancelText: "Отмена",
            onOk() {
                onDelete(record.id);
            },
        });
    };
    const menu = useCallback((record) => ({
        items: [
            {
                key: "product_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => handlerEdit(record.id),
            },
            {
                key: "product_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => handlerDelete(record),
            },
        ],
    }), []);
    const columns = [
        getColumnType("id", "#"),
        getColumnType("title", "Номенклатура"),
        getColumnType("actions", "", false, {
            width: "1%",
            render: (value, record) => {
                return (React.createElement(Button.Group, null,
                    React.createElement(Button, { type: "primary", size: "small", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: () => onInfoClick(record.id) }),
                    !(record === null || record === void 0 ? void 0 : record.isExternal) && (React.createElement(Dropdown, { menu: menu(record), trigger: ["click"] },
                        React.createElement(Button, { size: "small", icon: React.createElement(MDIcon, { icon: "pencil" }) })))));
            },
        }),
    ];
    const data = items.map((item) => {
        var _a;
        return ({
            key: item.id,
            isExternal: item === null || item === void 0 ? void 0 : item.isExternal,
            id: item.id,
            title: (_a = item.titleCompiled) !== null && _a !== void 0 ? _a : "",
        });
    });
    return (React.createElement("div", { className: "wms-table-full", id: "wms-table-full", ref: wrapper },
        React.createElement(Table, { columns: columns, dataSource: data, loading: loading, bordered: true, size: "small", pagination: false, scroll: { x: true, y: tableHeight } })));
};
export default ProductTable;
