export var SkuStatus;
(function (SkuStatus) {
    SkuStatus["FREE"] = "FREE";
    SkuStatus["RESERVED"] = "RESERVED";
    SkuStatus["BLOCKED"] = "BLOCKED";
    SkuStatus["WRITTEN_OF"] = "WRITTEN_OF";
    SkuStatus["SOLD"] = "SOLD";
    SkuStatus["SHIPPED"] = "SHIPPED";
    SkuStatus["DRAFT"] = "DRAFT";
    SkuStatus["IN_WORK"] = "IN_WORK";
    SkuStatus["TASK_PARTITION"] = "TASK_PARTITION";
    SkuStatus["TASK_MOVEMENT"] = "TASK_MOVEMENT";
    SkuStatus["TASK_LOADING"] = "TASK_LOADING";
    SkuStatus["TASK_SHIPPING"] = "TASK_SHIPPING";
    SkuStatus["TASK_INVENTORY"] = "TASK_INVENTORY";
    SkuStatus["TASK_TRANSFER"] = "TASK_TRANSFER";
    SkuStatus["TASK_CORRECTION"] = "TASK_CORRECTION";
    SkuStatus["TASK_CHANGE_WAREHOUSE"] = "TASK_CHANGE_WAREHOUSE";
    SkuStatus["DELETED"] = "DELETED";
})(SkuStatus || (SkuStatus = {}));
