import { Tabs } from "antd";
import React from "react";
import { Cell } from "./Tabs/Cell";
import { emptyColumns as emptyColumnsCell } from "./Tabs/Columns/Cell";
import { emptyColumns as emptyColumnsProduct } from "./Tabs/Columns/Product";
import { emptyColumns as emptyColumnsSKU } from "./Tabs/Columns/SKU";
import { Empty } from "./Tabs/Empty";
import { Product } from "./Tabs/Product";
import { SKU } from "./Tabs/SKU";
export const TabsContainer = ({ task, refetch }) => {
    if (task) {
        const { taskInventoryProducts, taskInventorySkus, taskInventoryStorageCells } = task;
        const items = [
            {
                key: "1",
                label: `Инвентаризация по продукции`,
                children: React.createElement(Product, { refetch: refetch, taskInventoryProducts: taskInventoryProducts, taskInventorySkus: taskInventorySkus }),
            },
            {
                key: "2",
                label: `Инвентаризация по ячейке хранения`,
                children: React.createElement(Cell, { refetch: refetch, taskInventorySkus: taskInventorySkus, taskInventoryStorageCells: taskInventoryStorageCells }),
            },
            {
                key: "3",
                label: `Инвентаризация по SKU`,
                children: React.createElement(SKU, { refetch: refetch, taskInventorySkus: taskInventorySkus }),
            },
        ];
        return React.createElement(Tabs, { defaultActiveKey: "1", items: items });
    }
    const itemsEmpty = [
        {
            key: "1",
            label: `Инвентаризация по продукции`,
            children: React.createElement(Empty, { columns: emptyColumnsProduct }),
        },
        {
            key: "2",
            label: `Инвентаризация по ячейке хранения`,
            children: React.createElement(Empty, { columns: emptyColumnsCell }),
        },
        {
            key: "3",
            label: `Инвентаризация по SKU`,
            children: React.createElement(Empty, { columns: emptyColumnsSKU }),
        },
    ];
    return React.createElement(Tabs, { defaultActiveKey: "1", items: itemsEmpty });
};
