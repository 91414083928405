import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { WarehouseDocument } from "../../../api/graphql/queries/warehouse/WarehouseQuery";
import dtoToEntity from "../../../api/rest/adapters/dtoToEntity";
import { AuthService } from "../../../services/AuthService";
export const WAREHOUSE_TAG = "warehouse";
export const WAREHOUSE_SECTION_TAG = "warehouseSection";
export const WAREHOUSE_SECTION_TYPE_TAG = "warehouseSectionType";
export const WAREOUSE_CELL_TAG = "warehouseCell";
export const WAREHOUSE_CELL_TYPE_TAG = "warehouseCellType";
const warehouseApi = createApi({
    reducerPath: "warehouseApi",
    tagTypes: [WAREHOUSE_TAG, WAREHOUSE_SECTION_TAG, WAREHOUSE_SECTION_TYPE_TAG, WAREOUSE_CELL_TAG, WAREHOUSE_CELL_TYPE_TAG],
    baseQuery: graphqlRequestBaseQuery({
        url: `${window.env.ApiHost}/graphql`,
        prepareHeaders: (headers, { getState }) => {
            const token = AuthService.getAccessTokenFromStorage();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            else {
                // use refresh token or navigate to login
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchWarehouse: builder.query({
            query: (warehouseId) => ({
                document: WarehouseDocument,
                variables: { warehouseId },
            }),
            transformResponse: (response) => {
                const responseData = response === null || response === void 0 ? void 0 : response.warehouse;
                return dtoToEntity.warehouseDtoInfoAdapter(responseData);
            },
            providesTags: (_, __, id) => [WAREHOUSE_TAG, { type: WAREHOUSE_TAG, id }],
        }),
    }),
});
export default warehouseApi;
