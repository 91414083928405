var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Col, Mentions, Row, Typography, } from 'antd';
import React, { useEffect, useMemo, useRef, useState, } from 'react';
import MDIcon from '../ui/MDIcon';
const { Option } = Mentions;
const regex = /\{([\w]+):(.*?)\}/g;
const renderTitle = (title, key) => (React.createElement(Option, { disabled: true, key: key, value: key },
    React.createElement(Typography.Text, { strong: true, style: { marginRight: 10 } }, title)));
const renderItem = (title, value, icon) => (React.createElement(Option, { key: value, value: value },
    React.createElement(Row, null,
        React.createElement(Col, { flex: "auto", style: { marginLeft: 15 } }, title),
        React.createElement(Col, null,
            React.createElement(MDIcon, { icon: icon })))));
const ProductTypeMaskInput = (props) => {
    const { attributes, onChange } = props, otherProps = __rest(props, ["attributes", "onChange"]);
    const [sample, setSample] = useState();
    const sampleRef = useRef(null);
    const handlerChange = (value) => {
        if (onChange)
            onChange(value);
        let str = value;
        let m = regex.exec(value);
        while (m !== null) {
            const mask = m[0];
            // const objectType = m[1];
            const objectValue = m[2].split(':');
            const id = parseInt(objectValue[0], 10);
            const isDimension = !!objectValue[1];
            const attr = attributes.find((item) => item.id === id);
            if (attr) {
                if (isDimension) {
                    str = str.replace(mask, `<span class="label label-green">${attr.dimension}</span>`);
                }
                else {
                    str = str.replace(mask, `<span class="label label-blue">${attr.title}</span>`);
                }
            }
            m = regex.exec(value);
        }
        setSample(str);
    };
    useEffect(() => {
        if (otherProps.value) {
            handlerChange(otherProps.value);
        }
    }, []);
    useEffect(() => {
        if (sampleRef.current) {
            sampleRef.current.innerHTML = sample !== null && sample !== void 0 ? sample : '';
        }
    }, [sample]);
    const options = useMemo(() => (attributes.reduce((acc, attr) => {
        const values = [
            renderItem('Название', `pav:${attr.id}}`, 'application-cog'),
        ];
        if (attr.dimension) {
            values.push(renderItem('Размерность', `pav:${attr.id}:dim}`, 'resize'));
        }
        return [
            ...acc,
            renderTitle(attr.title, `pav:${attr.id}`),
            ...values,
        ];
    }, [])), [attributes]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Mentions, Object.assign({}, otherProps, { autoSize: true, style: { width: '100%' }, prefix: "{", split: "", placement: "top", onChange: handlerChange }), options),
        sample ? (React.createElement("div", { ref: sampleRef, style: { marginTop: 15 } }, sample)) : null));
};
export default ProductTypeMaskInput;
