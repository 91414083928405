import React from 'react';
import { getDocumentUrl } from '../../../../api/rest/requests/documents/documents';
import TaskList from '../../../../components/task/TaskList';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { printDialogActions } from '../../../../redux/reducers/printDialogReducer';
import CorrectionTaskListItem from '../../../conponents/task/correction/CorrectionTaskListItem';
const CorrectionTaskListContainer = ({ isLoading, pagination, taskList }) => {
    var _a;
    const { open: openPrintDialog } = printDialogActions;
    const dispatch = useAppDispatch();
    const handlerPrintAction = (documentId, objectId) => (dispatch(openPrintDialog(getDocumentUrl(documentId, objectId))));
    const createTaskComponent = (task) => (React.createElement(CorrectionTaskListItem, { pagination: pagination, key: task.id, task: task, onPrintActionClick: (documentId) => handlerPrintAction(documentId, task.id.toString()) }));
    return (React.createElement(TaskList, { isLoading: isLoading }, (_a = taskList === null || taskList === void 0 ? void 0 : taskList.map(createTaskComponent)) !== null && _a !== void 0 ? _a : []));
};
export default CorrectionTaskListContainer;
