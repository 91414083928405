import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ShapeFragmentDoc = gql `
    fragment Shape on Shape {
  ... on Circle {
    cx
    cy
    r
  }
  ... on Ellipse {
    cx
    cy
    rx
    ry
  }
  ... on Line {
    x1
    x2
    y1
    y2
  }
  ... on Path {
    d
  }
  ... on Polygon {
    points
  }
  ... on Polyline {
    points
  }
  ... on Rect {
    width
    height
    x
    y
    rx
    ry
  }
  __typename
}
    `;
export const WarehouseMapEditDocument = gql `
    mutation WarehouseMapEdit($inputEdit: WarehouseMapEditInput!) {
  warehouseMapEdit(input: $inputEdit) {
    warehouseMapWarehouseDto {
      id
      vectorObject {
        shape {
          ...Shape
        }
        transform {
          key
          value
        }
        style {
          key
          value
        }
      }
    }
  }
}
    ${ShapeFragmentDoc}`;
/**
 * __useWarehouseMapEditMutation__
 *
 * To run a mutation, you first call `useWarehouseMapEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarehouseMapEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warehouseMapEditMutation, { data, loading, error }] = useWarehouseMapEditMutation({
 *   variables: {
 *      inputEdit: // value for 'inputEdit'
 *   },
 * });
 */
export function useWarehouseMapEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(WarehouseMapEditDocument, options);
}
export const WarehouseMapStorageCellEditDocument = gql `
    mutation WarehouseMapStorageCellEdit($inputStorageCellEdit: WarehouseMapStorageCellEditInput!) {
  warehouseMapStorageCellEdit(input: $inputStorageCellEdit) {
    warehouseMapStorageCellDto {
      id
      title
      storageCellType {
        id
        title
      }
      vectorObject {
        shape {
          ...Shape
        }
        transform {
          key
          value
        }
        style {
          key
          value
        }
      }
    }
  }
}
    ${ShapeFragmentDoc}`;
/**
 * __useWarehouseMapStorageCellEditMutation__
 *
 * To run a mutation, you first call `useWarehouseMapStorageCellEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarehouseMapStorageCellEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warehouseMapStorageCellEditMutation, { data, loading, error }] = useWarehouseMapStorageCellEditMutation({
 *   variables: {
 *      inputStorageCellEdit: // value for 'inputStorageCellEdit'
 *   },
 * });
 */
export function useWarehouseMapStorageCellEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(WarehouseMapStorageCellEditDocument, options);
}
