var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState, } from 'react';
import { Empty, Form, Modal, Table, } from 'antd';
import { debounce } from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getColumnType } from '../../helpers/function';
import HandbookTableItemActions from '../HandbookTableItemActions';
import HandbookTableCell from '../HandbookTableCell';
import Centered from '../ui/Centered';
const { confirm } = Modal;
const DEBOUNCE_TIMEOUT = 100;
const HandbookTable = (props) => {
    const { data, loading, onDelete, onEdit, } = props;
    const [tableHeight, setTableHeight] = useState(0);
    const wrapper = useRef(null);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;
    const showEditConfirm = (callback) => {
        confirm({
            title: `Изменения значении #${editingKey} не сохранены`,
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: `Вы не сохранили изменения в значении #${editingKey}. Отменить все изменения?`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk() {
                callback();
            },
        });
    };
    const handlerCancel = () => {
        setEditingKey('');
    };
    const handlerEdit = (currentEditingKey, record) => {
        if (currentEditingKey) {
            showEditConfirm(() => {
                handlerCancel();
                handlerEdit('', record);
            });
        }
        else {
            form.setFieldsValue(record);
            setEditingKey(record.key);
        }
    };
    const handlerDelete = (handbookId) => {
        if (onDelete)
            onDelete(handbookId);
    };
    const handlerSave = (handbookId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const row = (yield form.validateFields());
            if (onEdit) {
                onEdit(handbookId, { title: row.title });
            }
            setEditingKey('');
        }
        catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    });
    const actionsRender = (value, record) => {
        const isEditable = isEditing(record);
        return (React.createElement(HandbookTableItemActions, { title: record.title, isEditable: isEditable, onSaveClick: () => handlerSave(record.id), onCancelClick: handlerCancel, onDeleteClick: () => handlerDelete(record.id), onEditClick: () => handlerEdit(editingKey, record) }));
    };
    const columns = [
        getColumnType('id', '#'),
        getColumnType('title', 'Значение', false, {
            onCell: (record) => ({
                dataIndex: 'title',
                title: 'Значение',
                value: record.title,
                editing: isEditing(record),
            }),
        }),
        getColumnType('actions', '', false, {
            width: '1%',
            render: actionsRender,
        }),
    ];
    const updateDimensions = debounce(() => {
        var _a;
        const antTableHeader = (_a = wrapper.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ant-table-header');
        const wrapperHeight = wrapper.current ? wrapper.current.clientHeight : 0;
        const headerHeight = antTableHeader ? antTableHeader.clientHeight : 0;
        setTableHeight(Math.floor(wrapperHeight - headerHeight) - 1);
    }, DEBOUNCE_TIMEOUT);
    useEffect(() => {
        updateDimensions();
        window.addEventListener('load', updateDimensions);
        window.addEventListener('resize', updateDimensions);
        window.addEventListener('orientationchange', updateDimensions);
        return () => {
            window.removeEventListener('load', updateDimensions);
            window.removeEventListener('resize', updateDimensions);
            window.removeEventListener('orientationchange', updateDimensions);
        };
    }, []);
    if (data.length === 0) {
        return (React.createElement(Centered, null,
            React.createElement(Empty, null)));
    }
    return (React.createElement("div", { className: "wms-table-full", id: "wms-table-full", ref: wrapper },
        React.createElement(Form, { form: form, component: false },
            React.createElement(Table, { columns: columns, dataSource: data, loading: loading, bordered: true, size: "small", pagination: false, scroll: { x: true, y: tableHeight }, components: {
                    body: {
                        cell: HandbookTableCell,
                    },
                } }))));
};
export default HandbookTable;
