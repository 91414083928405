import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import { useTaskWriteOff } from "../../../../hooks/api/taskWriteOffApi/useTaskWriteOff";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import WriteOffEditTaskHeaderContainer from "../../../containers/task/writeoff/WriteOffEditTaskHeaderContainer";
import WriteOffTaskFormNomenclatureContainer from "../../../containers/task/writeoff/WriteOffTaskFormNomenclatureContainer";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
export const WriteOffContext = React.createContext(null);
const WriteOffProvider = WriteOffContext.Provider;
const WriteOffEditPage = () => {
    const params = useParams();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const { task } = useTaskWriteOff();
    const isLocked = useMemo(() => taskHelpers.hasExternalId(task === null || task === void 0 ? void 0 : task.data), [task === null || task === void 0 ? void 0 : task.data]);
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: React.createElement(WriteOffEditTaskHeaderContainer, { id: taskId, task: task === null || task === void 0 ? void 0 : task.data, isLoading: task === null || task === void 0 ? void 0 : task.loading }),
        toolbarRight: null,
    };
    return (React.createElement(WriteOffProvider, { value: { isLocked } },
        React.createElement(Page, Object.assign({}, pageProps),
            React.createElement(WriteOffTaskFormNomenclatureContainer, { refetch: task === null || task === void 0 ? void 0 : task.refetch, taskId: taskId, task: task === null || task === void 0 ? void 0 : task.data, isLoading: task === null || task === void 0 ? void 0 : task.loading }),
            React.createElement(SkuInfoDrawerContainer, null))));
};
export default WriteOffEditPage;
