import { Button, Dropdown } from 'antd';
import React from "react";
import TaskListItemWithSlot from "../../../../components/task/TaskList/TaskListItemWithSlot";
import TaskNomenclature from "../../../../components/task/TaskNomenclature";
import { links } from "../../../../constants/links";
import { getColumnType } from "../../../../helpers/function";
const columns = [
    getColumnType("id", "#", false, { width: "1%" }),
    getColumnType("type", "Тип продукции"),
    getColumnType("title", "Номенклатура"),
    {
        title: "Ячейка",
        children: [getColumnType("storageCellFrom", "До"), getColumnType("storageCellTo", "После")],
    },
];
const MovementTaskListItem = (props) => {
    var _a;
    const { task, onPrintActionClick, pagination, printActions } = props;
    const info = [{ title: "Склад", value: task.warehouse.storageTitle }];
    const data = (_a = task === null || task === void 0 ? void 0 : task.details) === null || _a === void 0 ? void 0 : _a.map((detail) => {
        var _a, _b;
        return ({
            key: detail.id,
            id: detail.id.toString(),
            type: detail.type || "",
            title: detail.title || "",
            storageCellFrom: ((_a = detail.storageCellFrom) === null || _a === void 0 ? void 0 : _a.title) || "",
            storageCellTo: ((_b = detail.storageCellTo) === null || _b === void 0 ? void 0 : _b.title) || "",
        });
    });
    const nomenclature = {
        columns,
        data,
    };
    const handlerPrintAction = (id) => {
        if (onPrintActionClick)
            onPrintActionClick(id);
    };
    const documentsDropdownMenu = {
        items: printActions === null || printActions === void 0 ? void 0 : printActions.map(item => {
            return {
                key: item.id,
                label: item.title,
                onClick: () => handlerPrintAction(item.id)
            };
        })
    };
    const actions = (React.createElement(React.Fragment, null, (printActions === null || printActions === void 0 ? void 0 : printActions.length) ? (React.createElement(Dropdown, { menu: documentsDropdownMenu, trigger: ["click"] },
        React.createElement(Button, { size: "small", icon: React.createElement("span", { className: "mdi mdi-printer" }) }))) : null));
    return (React.createElement(TaskListItemWithSlot, { task: task, info: info, pagination: pagination, slot: (nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.data) ? React.createElement(TaskNomenclature, { columns: nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.columns, dataSource: nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.data }) : React.createElement(React.Fragment, null), link: links.taskMovementLinks.page.url(task.id), actions: actions }));
};
export default MovementTaskListItem;
