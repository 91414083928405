var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Form, Input, message, Row, Spin, Typography } from "antd";
import { AddLoadingFieldName } from "../../../../api/rest/dto/tasks/loading/types";
import WarehouseSelect from "../../../../components/WarehouseSelect";
import TaskForm from "../../../../components/task/TaskForm";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const { Title } = Typography;
const formGutter = [15, 15];
const InventoryTaskForm = (props) => {
    const { isLoading, children, readOnly, onSubmit, disableWarehouse, initialData, onLoad } = props;
    const [initialValues, setInitialValues] = useState({});
    useEffect(() => {
        var _a, _b, _c;
        const transformedCommissions = ((_a = initialData === null || initialData === void 0 ? void 0 : initialData.tastaskInventoryCommissions) === null || _a === void 0 ? void 0 : _a.map(splitFullName)) || [];
        setInitialValues({
            warehouseId: {
                value: (_b = initialData === null || initialData === void 0 ? void 0 : initialData.warehouse) === null || _b === void 0 ? void 0 : _b.id,
                label: (_c = initialData === null || initialData === void 0 ? void 0 : initialData.warehouse) === null || _c === void 0 ? void 0 : _c.storageTitle,
            },
            taskInventoryCommissions: transformedCommissions,
        });
    }, [initialData]);
    useEffect(() => {
        if (onLoad)
            onLoad().finally();
    }, []);
    const handlerFinish = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const taskInventoryCommissions = values.taskInventoryCommissions.map((commission) => ({
            fullName: `${commission.lastName || ""} ${commission.firstName || ""} ${commission.patronymic || ""}`.trim(),
            post: commission.post,
        }));
        if (!initialData) {
            try {
                yield onSubmit({
                    warehouseId: values.warehouseId.value,
                    taskInventoryCommissions: taskInventoryCommissions,
                });
            }
            catch (e) {
                message.error("Ошибка при сохранении задачи");
            }
        }
        else {
            try {
                yield onSubmit({
                    taskId: initialData === null || initialData === void 0 ? void 0 : initialData.taskId,
                    taskInventoryCommissions: taskInventoryCommissions,
                });
            }
            catch (e) {
                message.error("Ошибка при сохранении задачи");
            }
        }
    });
    const taskForm = (React.createElement(Col, { span: 24 },
        React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
            React.createElement(Title, { level: 3 }, "\u0420\u0435\u043A\u0432\u0438\u0437\u0438\u0442\u044B"),
            React.createElement(Divider, null),
            React.createElement(Row, { gutter: formGutter },
                React.createElement(Col, { span: 12 },
                    React.createElement(Form.Item, { label: "\u0421\u043A\u043B\u0430\u0434", name: AddLoadingFieldName.WAREHOUSE, rules: [{ required: true }] },
                        React.createElement(WarehouseSelect, { disabled: disableWarehouse || !!initialData || readOnly, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434" })))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Title, { level: 5 }, "\u041A\u043E\u043C\u0438\u0441\u0441\u0438\u044F"),
                    React.createElement(Form.List, { initialValue: [{ firstName: "", lastName: "", patronymic: "" }], name: "taskInventoryCommissions" }, (fields, { add, remove }) => (React.createElement(React.Fragment, null,
                        fields.map((field) => (React.createElement(Row, { key: field.key, gutter: 16, align: "bottom", style: { display: "flex", alignItems: "center" } },
                            React.createElement(Col, { span: 5 },
                                React.createElement(Form.Item, Object.assign({}, field, { name: [field.name, "lastName"], rules: [{ required: true, message: "Введите фамилию" }], label: "\u0424\u0430\u043C\u0438\u043B\u0438\u044F" }),
                                    React.createElement(Input, { placeholder: "\u0424\u0430\u043C\u0438\u043B\u0438\u044F", disabled: readOnly }))),
                            React.createElement(Col, { span: 5 },
                                React.createElement(Form.Item, Object.assign({}, field, { name: [field.name, "firstName"], rules: [{ required: true, message: "Введите имя!" }], label: "\u0418\u043C\u044F" }),
                                    React.createElement(Input, { placeholder: "\u0418\u043C\u044F", disabled: readOnly }))),
                            React.createElement(Col, { span: 5 },
                                React.createElement(Form.Item, Object.assign({}, field, { name: [field.name, "patronymic"], label: "\u041E\u0442\u0447\u0435\u0441\u0442\u0432\u043E" }),
                                    React.createElement(Input, { placeholder: "\u041E\u0442\u0447\u0435\u0441\u0442\u0432\u043E", disabled: readOnly }))),
                            React.createElement(Col, { span: 7 },
                                React.createElement(Form.Item, Object.assign({}, field, { name: [field.name, "post"], rules: [{ required: true, message: "Введите должность" }], label: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C" }),
                                    React.createElement(Input, { placeholder: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C", disabled: readOnly }))),
                            readOnly ? null : (React.createElement(Col, { span: 2 },
                                React.createElement(MinusCircleOutlined, { onClick: () => remove(field.name) })))))),
                        readOnly ? null : (React.createElement(Form.Item, null,
                            React.createElement(Button, { type: "dashed", onClick: () => add(), block: true, icon: React.createElement(PlusOutlined, null) }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043A\u043E\u043C\u0438\u0441\u0441\u0438\u044E")))))))))));
    return (React.createElement(Spin, { spinning: isLoading, key: JSON.stringify(initialValues) },
        React.createElement(TaskForm, { initialValues: initialValues, onFinish: handlerFinish, taskForm: taskForm }),
        children));
};
export default InventoryTaskForm;
function splitFullName(commission) {
    var _a;
    const parts = commission.fullName.split(" ");
    let lastName = "";
    let firstName = "";
    let patronymic = "";
    if (parts.length > 0) {
        lastName = parts[0];
    }
    if (parts.length > 1) {
        firstName = parts[1];
    }
    if (parts.length > 2) {
        patronymic = (_a = parts === null || parts === void 0 ? void 0 : parts.slice(2)) === null || _a === void 0 ? void 0 : _a.join(" ");
    }
    return Object.assign(Object.assign({}, commission), { firstName,
        lastName,
        patronymic });
}
