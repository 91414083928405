var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Modal, Row, Space, Table, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { DataType as CellDataType } from "../../../../../api/graphql/common";
import MDIcon from "../../../../../components/ui/MDIcon";
import { getColumnType } from "../../../../../helpers/function";
import { onHandlerSave } from "../TaskService";
import LoadingDetailNomenclatureCell from "./LoadingDetailNomenclatureCell";
import LoadingDetailNomenclatureItemActions from "./LoadingDetailNomenclatureItemActions";
import { filterNonFormylaTypeInput } from "../../../../../components/FormulaMaskInput/FormulaMaskInputContainer";
const { confirm } = Modal;
const { Title, Text } = Typography;
const ACTIONS_KEY = "actions";
const SPACE_KEY = "space";
const LoadingDetailNomenclatureItem = (props) => {
    var _a, _b, _c;
    const { editable, isLoading, nomenclature, produceWork, warehouseId, quantity, onAddItem, onDeleteItem, onDuplicateItem, onEditItem, onInfoClick, onSkuInfoClick } = props;
    const { type, title, attributes, productId } = nomenclature;
    let countAttr = 0;
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record) => record.key === editingKey;
    const labeledValueRender = (value, isRequired) => {
        if (value && value.label) {
            return value.label;
        }
        if (isRequired && !value && typeof value !== "boolean") {
            return React.createElement("div", { style: { color: "#ff4d4f" } }, "\u041D\u0435 \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u043E");
        }
        if (typeof value === "boolean") {
            return React.createElement(Checkbox, { key: `${value}`, defaultChecked: value, disabled: true });
        }
        return value;
    };
    const storageCellValueRender = (cellValue) => {
        const { value, label } = cellValue;
        if (value) {
            return label;
        }
        return React.createElement("div", { style: { color: "#ff4d4f" } }, "\u042F\u0447\u0435\u0439\u043A\u0430 \u043D\u0435 \u0443\u043A\u0430\u0437\u0430\u043D\u0430");
    };
    const columns = [
        getColumnType("id", "#", false, {
            fixed: "left",
            width: "1%",
        }),
        ...(((_b = (_a = filterNonFormylaTypeInput(attributes)) === null || _a === void 0 ? void 0 : _a.filter((attr) => attr.isPersonal && !(attr === null || attr === void 0 ? void 0 : attr.isReadOnly))) === null || _b === void 0 ? void 0 : _b.map((attr) => {
            const { id, title: attrTitle, isRequired } = attr;
            countAttr += 1;
            return getColumnType(id.toString(), attrTitle, false, {
                width: "1%",
                render: (value) => {
                    return labeledValueRender(value, isRequired);
                },
                onCell: (record) => ({
                    record,
                    attribute: attr,
                    value: record[id.toString()],
                    dataIndex: id.toString(),
                    title: attrTitle,
                    editing: isEditing(record) && !produceWork,
                    isRequired: isRequired,
                }),
            });
        })) || []),
        getColumnType(SPACE_KEY),
        getColumnType("defect", "Проблема/дефект", false, {
            width: "1%",
            onCell: (record) => ({
                record,
                value: record.defect,
                dataIndex: "defect",
                title: "Проблема/дефект",
                editing: isEditing(record),
            }),
        }),
        getColumnType("storage", "Ячейка склада", false, {
            width: "1%",
            render: produceWork ? storageCellValueRender : (value) => labeledValueRender(value, true),
            onCell: (record) => ({
                record,
                value: record.storage,
                dataIndex: "storage",
                title: "Ячейка склада",
                warehouseId,
                editing: isEditing(record),
            }),
        }),
        getColumnType("quantity", "Количество", false, {
            fixed: "right",
            width: "1%",
            onCell: (record) => ({
                record,
                value: record.quantity,
                dataIndex: "quantity",
                title: "Количество",
                editing: isEditing(record) && !produceWork,
            }),
        }),
    ];
    const data = ((_c = nomenclature.skus) === null || _c === void 0 ? void 0 : _c.map((sku) => {
        var _a, _b;
        const result = {
            id: sku.skuId.toString(),
            detailsId: sku.taskDetailId.toString(),
            key: sku.skuId.toString(),
            storage: {
                label: (_a = sku.storageCell) === null || _a === void 0 ? void 0 : _a.title,
                value: ((_b = sku.storageCell) === null || _b === void 0 ? void 0 : _b.id.toString()) || "",
            },
            quantity: `${sku.quantity}`,
            defect: sku.defect || ""
        };
        attributes === null || attributes === void 0 ? void 0 : attributes.forEach((prodTypeAttr) => {
            var _a, _b, _c;
            const attr = sku.skuAttributeValues.find((el) => {
                return +el.key === +prodTypeAttr.id;
            });
            if (attr) {
                switch (prodTypeAttr.dataType) {
                    case CellDataType.Handbook: {
                        result[prodTypeAttr.id.toString()] = {
                            value: (_b = (_a = attr === null || attr === void 0 ? void 0 : attr.value) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "",
                            label: (_c = attr === null || attr === void 0 ? void 0 : attr.value) === null || _c === void 0 ? void 0 : _c.valueReadable,
                        };
                        break;
                    }
                    case CellDataType.Integer: {
                        const attrValue = parseInt(attr.value.value || "", 10);
                        result[prodTypeAttr.id.toString()] = !Number.isNaN(attrValue) ? attrValue : undefined;
                        break;
                    }
                    case CellDataType.Boolean: {
                        result[prodTypeAttr.id.toString()] = attr.value.value === "true";
                        break;
                    }
                    case CellDataType.Float: {
                        const attrValue = parseFloat(attr.value.value || "");
                        result[prodTypeAttr.id.toString()] = !Number.isNaN(attrValue) ? attrValue : undefined;
                        break;
                    }
                    default: {
                        result[prodTypeAttr.id.toString()] = attr.value.value || "";
                    }
                }
            }
        });
        return result;
    })) || [];
    const handlerCancel = () => {
        setEditingKey("");
    };
    const showEditConfirm = (callback) => {
        confirm({
            title: `Изменения в товарной позиции #${editingKey} не сохранены`,
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: `Вы не сохранили изменения в товарной позиции #${editingKey}. Отменить все изменения?`,
            okText: "Да",
            okType: "danger",
            cancelText: "Нет",
            onOk() {
                callback();
            },
        });
    };
    const handlerEdit = (currentEditingKey, record) => {
        if (currentEditingKey) {
            showEditConfirm(() => {
                handlerCancel();
                handlerEdit("", record);
            });
        }
        else {
            form.setFieldsValue(record);
            setEditingKey(record.key);
        }
    };
    const handlerAddItem = () => {
        if (onAddItem)
            onAddItem(productId);
    };
    const handlerDelete = (detailsId) => {
        if (onDeleteItem)
            onDeleteItem(detailsId);
    };
    const handlerDuplicate = (skuId) => {
        if (onDuplicateItem)
            onDuplicateItem(productId, skuId);
    };
    const handlerOpenInfo = () => {
        if (onInfoClick)
            onInfoClick(productId);
    };
    const handlerOpenSkuInfo = (id) => {
        if (onSkuInfoClick)
            onSkuInfoClick(id);
    };
    const handlerSave = (detailsId) => __awaiter(void 0, void 0, void 0, function* () {
        onHandlerSave(detailsId, nomenclature, form, setEditingKey, onEditItem);
    });
    const actionsRender = (value, record) => {
        const isEditable = isEditing(record);
        return (React.createElement(LoadingDetailNomenclatureItemActions, { skuId: record.id, isEditable: isEditable, isLoading: isLoading, produceWork: produceWork || false, onEditClick: () => handlerEdit(editingKey, record), onDuplicateClick: () => handlerDuplicate(parseInt(record.id, 10)), onDeleteClick: () => handlerDelete(parseInt(record.detailsId, 10)), onSaveClick: () => handlerSave(record.detailsId), onCancelClick: handlerCancel, onSkuInfoClick: () => handlerOpenSkuInfo(record.id) }));
    };
    columns.push(getColumnType(ACTIONS_KEY, "", false, {
        fixed: "right",
        width: "1%",
        render: (value, record) => {
            if (editable || produceWork) {
                return actionsRender(value, record);
            }
            return (React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                React.createElement(Button, { loading: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: () => handlerOpenSkuInfo(record.id) })));
        },
    }));
    countAttr += 1;
    const returnAddButton = () => {
        if (editable) {
            return (React.createElement(Tooltip, { title: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0442\u043E\u0432\u0430\u0440\u043D\u0443\u044E \u043F\u043E\u0437\u0438\u0446\u0438\u044E", mouseEnterDelay: 1 },
                React.createElement(Button, { loading: isLoading, icon: React.createElement(MDIcon, { icon: "plus-circle" }), type: "primary", onClick: () => handlerAddItem(), style: { marginBottom: "10px" } })));
        }
        return null;
    };
    return (React.createElement("div", { className: "mb-5" },
        React.createElement(Row, null,
            React.createElement(Col, { flex: "auto" },
                React.createElement(Space, { style: { width: "auto" } },
                    returnAddButton(),
                    React.createElement(Title, { level: 4 }, type),
                    React.createElement("div", { className: "mb-2" },
                        React.createElement(Text, { underline: true }, title)))),
            React.createElement(Col, null,
                React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u0442\u0438\u043F\u0435 \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
                    React.createElement(Button, { loading: isLoading, type: "primary", size: "small", icon: React.createElement(MDIcon, { icon: "information-outline" }), onClick: handlerOpenInfo })))),
        React.createElement(Form, { form: form, component: false },
            React.createElement(Table, { style: { whiteSpace: "nowrap" }, components: {
                    body: {
                        cell: LoadingDetailNomenclatureCell,
                    },
                }, columns: columns, dataSource: data, bordered: true, pagination: false, size: "small", scroll: { x: true }, summary: (pageData) => {
                    const offset = countAttr + 3;
                    return (React.createElement(Table.Summary.Row, null,
                        React.createElement(Table.Summary.Cell, { index: 0, colSpan: offset }),
                        React.createElement(Table.Summary.Cell, { index: offset, colSpan: editable || produceWork ? 2 : 1 }, quantity)));
                } }))));
};
export default LoadingDetailNomenclatureItem;
