export const clientAdapter = (dto) => {
    const { id, companyAddressLegal, companyAddressMailing, companyInn, companyKpp, companyName, companyOgrn, companyOkpo, companyFullName, } = dto;
    return {
        id,
        companyAddressLegal,
        companyAddressMailing,
        companyInn,
        companyKpp,
        companyName,
        companyOgrn,
        companyOkpo,
        companyFullName,
    };
};
