import { Empty } from "antd";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProductTable from "../../components/ProductTable";
import Centered from "../../components/ui/Centered";
import { links } from "../../constants/links";
import { usePagination } from "../../hooks/usePagination";
import { useProductDrawer } from "../../hooks/useProductDrawer";
import productApi from "../../redux/api/productApi";
import { TYPE_ID_PARAM } from "../pages/product/ProductsPage";
const { useFetchProductListQuery, useDeleteProductMutation } = productApi;
const ProductTableContainer = ({ filterValue }) => {
    var _a;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const productTypeParam = searchParams.get(TYPE_ID_PARAM);
    const productTypeId = productTypeParam ? +productTypeParam : null;
    const { page, pageSize } = usePagination();
    const { data, isLoading } = useFetchProductListQuery({
        page,
        pageSize,
        filter: { productTypeId, productAttributes: Object.assign({}, filterValue) },
    }, {
        skip: !productTypeParam,
    });
    const [deleteProduct] = useDeleteProductMutation();
    const { openProductDrawer } = useProductDrawer();
    const handlerEdit = (id) => {
        navigate(links.handbookProductEditLink.url(id));
    };
    const handlerDelete = (id) => {
        deleteProduct(id);
    };
    const handlerInfoOpen = (id) => {
        openProductDrawer(id);
    };
    if (!(data === null || data === void 0 ? void 0 : data.list.length) && !isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Empty, null)));
    }
    return React.createElement(ProductTable, { items: (_a = data === null || data === void 0 ? void 0 : data.list) !== null && _a !== void 0 ? _a : [], loading: isLoading, onDelete: handlerDelete, onEdit: handlerEdit, onInfoClick: handlerInfoOpen });
};
export default ProductTableContainer;
