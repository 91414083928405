import React from "react";
import PaginationPanel from "../../../../components/PaginationPanel";
import { useAvailabilityOnDate, useAvailabilityOnDateDispatch } from "../store/AvailabilityOnDateProvider";
import { AvailabilityOnDateActionType } from "../store/state";
export const AvailabilityOnDatePagination = () => {
    const state = useAvailabilityOnDate();
    const dispatch = useAvailabilityOnDateDispatch();
    return (React.createElement(PaginationPanel, { pagination: {
            page: state.page,
            itemsTotalCount: state.total.totalCount,
            itemsCurrentCount: state.items.length,
            itemsOnPage: state.pageSize,
        }, disabled: state.loading, onChange: (page, itemsOnPage) => {
            dispatch({
                type: AvailabilityOnDateActionType.SET_PAGE,
                payload: {
                    page: page,
                    pageSize: itemsOnPage !== null && itemsOnPage !== void 0 ? itemsOnPage : 20
                }
            });
        } }));
};
