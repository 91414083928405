import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PackingListHeader from "./PackingListHeader";
import { links } from "../../../../../../constants/links";
import { TITLES, TITLE_PARAM, TYPE_ID_PARAM } from "../../../constants";
const PackingListHeaderContainer = () => {
    const [searchParams] = useSearchParams();
    const packingTypeParam = searchParams.get(TYPE_ID_PARAM);
    const packingTitle = searchParams.get(TITLE_PARAM);
    const packingTypeId = packingTypeParam ? +packingTypeParam : null;
    const navigate = useNavigate();
    const handlerAdd = () => {
        if (packingTypeId && packingTitle) {
            navigate(links.handbookPackingAddLink.url(packingTypeId, packingTitle));
        }
    };
    const isShowActions = !!packingTypeId;
    return React.createElement(PackingListHeader, { title: !!packingTitle ? TITLES.packing_set(packingTitle) : TITLES.packing, onAddClick: handlerAdd, showActions: isShowActions });
};
export default PackingListHeaderContainer;
