import React from "react";
import ChangeWarehouseTaskListHeader from "../../../conponents/task/changeWarehouse/ChangeWarehouseTaskListHeader";
const ChangeWarehouseTaskListHeaderContainer = ({ data, isLoading, pagination }) => {
    var _a, _b;
    if (data || isLoading) {
        if (isLoading && !(data === null || data === void 0 ? void 0 : data.length)) {
            React.createElement(ChangeWarehouseTaskListHeader, { isEmpty: true, currentCount: 1, totalCount: 1, isLoading: true });
        }
        return React.createElement(ChangeWarehouseTaskListHeader, { isEmpty: (data === null || data === void 0 ? void 0 : data.length) === 0 && isLoading, currentCount: (_a = pagination === null || pagination === void 0 ? void 0 : pagination.itemsCurrentCount) !== null && _a !== void 0 ? _a : 0, totalCount: (_b = pagination === null || pagination === void 0 ? void 0 : pagination.itemsTotalCount) !== null && _b !== void 0 ? _b : 0, isLoading: isLoading });
    }
    return null;
};
export default ChangeWarehouseTaskListHeaderContainer;
