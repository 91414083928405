import { Card, Col, DatePicker, Divider, Form, Input, Row, Typography } from "antd";
import React from "react";
import WarehouseSelect from "../../../../../components/WarehouseSelect";
import { AddChangeWarehouseFieldName } from "../../../../../api/rest/dto/tasks/changeWarehouse/types";
const { Title } = Typography;
const formGutter = 15;
const DestinationWarehouseSection = ({ disableWarehouse = false }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Col, { span: 24 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 2 }, "\u0421\u043A\u043B\u0430\u0434 \u043D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F:"))),
        React.createElement(Col, { span: 8 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u0420\u0435\u043A\u0432\u0438\u0437\u0438\u0442\u044B"),
                React.createElement(Divider, null),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0421\u043A\u043B\u0430\u0434", name: AddChangeWarehouseFieldName.DESTINATION_WAREHOUSE },
                            React.createElement(WarehouseSelect, { disabled: disableWarehouse, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434" })))))),
        React.createElement(Col, { span: 8 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u041E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0435\u043D\u043D\u044B\u0435"),
                React.createElement(Divider, null),
                React.createElement(Form.Item, { label: "\u041F\u0440\u0438\u0451\u043C \u0440\u0430\u0437\u0440\u0435\u0448\u0438\u043B" },
                    React.createElement(Row, { gutter: formGutter },
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddChangeWarehouseFieldName.DESTINATION_ALLOWED_NAME },
                                React.createElement(Input, { placeholder: "\u0424\u0418\u041E" }))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddChangeWarehouseFieldName.DESTINATION_ALLOWED_POST },
                                React.createElement(Input, { placeholder: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C" }))))),
                React.createElement(Form.Item, { label: "\u041F\u0440\u0438\u0451\u043C \u043F\u0440\u043E\u0438\u0437\u0432\u0435\u043B" },
                    React.createElement(Row, { gutter: formGutter },
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddChangeWarehouseFieldName.DESTINATION_PRODUCED_NAME },
                                React.createElement(Input, { placeholder: "\u0424\u0418\u041E" }))),
                        React.createElement(Col, { span: 12 },
                            React.createElement(Form.Item, { style: { marginBottom: 0 }, name: AddChangeWarehouseFieldName.DESTINATION_PRODUCED_POST },
                                React.createElement(Input, { placeholder: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C" }))))))),
        React.createElement(Col, { span: 8 },
            React.createElement(Card, { bordered: false, className: "task-loading-change__block" },
                React.createElement(Title, { level: 3 }, "\u0412\u043E\u0434\u0438\u0442\u0435\u043B\u044C"),
                React.createElement(Divider, null),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0424\u0418\u041E", name: AddChangeWarehouseFieldName.DESTINATION_DRIVER_NAME },
                            React.createElement(Input, null))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u041D\u043E\u043C\u0435\u0440 \u0442/\u0441", name: AddChangeWarehouseFieldName.DESTINATION_VEHICLE_NUMBER },
                            React.createElement(Input, null)))),
                React.createElement(Row, { gutter: formGutter },
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0414\u043E\u0432\u0435\u0440\u0435\u043D\u043D\u043E\u0441\u0442\u044C", name: AddChangeWarehouseFieldName.DESTINATION_POWER_OF_ATTORNEY },
                            React.createElement(Input, null))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { label: "\u0414\u0430\u0442\u0430 \u0434\u043E\u0432\u0435\u0440\u0435\u043D\u043D\u043E\u0441\u0442\u0438", name: AddChangeWarehouseFieldName.DESTINATION_POWER_OF_ATTORNEY_DATE },
                            React.createElement(DatePicker, { style: { width: "100%" } }))))))));
};
export default DestinationWarehouseSection;
