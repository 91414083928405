import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const StorageCellTypeDeleteDocument = gql `
    mutation StorageCellTypeDelete($input: StorageCellTypeDeleteInput!) {
  storageCellTypeDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useStorageCellTypeDeleteMutation__
 *
 * To run a mutation, you first call `useStorageCellTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageCellTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageCellTypeDeleteMutation, { data, loading, error }] = useStorageCellTypeDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageCellTypeDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageCellTypeDeleteDocument, options);
}
export const StorageCellDeleteDocument = gql `
    mutation StorageCellDelete($input: StorageCellDeleteInput!) {
  storageCellDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useStorageCellDeleteMutation__
 *
 * To run a mutation, you first call `useStorageCellDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageCellDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageCellDeleteMutation, { data, loading, error }] = useStorageCellDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageCellDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageCellDeleteDocument, options);
}
export const StorageCellTypeEditDocument = gql `
    mutation StorageCellTypeEdit($input: StorageCellTypeEditInput!) {
  storageCellTypeEdit(input: $input) {
    storageCellTypeDto {
      id
    }
  }
}
    `;
/**
 * __useStorageCellTypeEditMutation__
 *
 * To run a mutation, you first call `useStorageCellTypeEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageCellTypeEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageCellTypeEditMutation, { data, loading, error }] = useStorageCellTypeEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageCellTypeEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageCellTypeEditDocument, options);
}
export const StorageCellEditDocument = gql `
    mutation StorageCellEdit($input: StorageCellEditInput!) {
  storageCellEdit(input: $input) {
    storageCellDto {
      id
      address
      description
      id
      title
      storageCellType {
        cellDepth
        cellHeight
        cellWeight
        cellWidth
        description
        id
        title
      }
      storageSection {
        address
        description
        id
        title
        storageSectionType {
          description
          icon
          id
          separator
          title
        }
      }
    }
  }
}
    `;
/**
 * __useStorageCellEditMutation__
 *
 * To run a mutation, you first call `useStorageCellEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageCellEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageCellEditMutation, { data, loading, error }] = useStorageCellEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageCellEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageCellEditDocument, options);
}
export const StorageCellTypeCreateDocument = gql `
    mutation StorageCellTypeCreate($input: StorageCellTypeCreateInput!) {
  storageCellTypeCreate(input: $input) {
    storageCellTypeDto {
      id
    }
  }
}
    `;
/**
 * __useStorageCellTypeCreateMutation__
 *
 * To run a mutation, you first call `useStorageCellTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageCellTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageCellTypeCreateMutation, { data, loading, error }] = useStorageCellTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageCellTypeCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageCellTypeCreateDocument, options);
}
export const StorageCellCreateDocument = gql `
    mutation StorageCellCreate($input: StorageCellCreateInput!) {
  storageCellCreate(input: $input) {
    storageCellDto {
      id
      address
      description
      id
      title
      storageCellType {
        cellDepth
        cellHeight
        cellWeight
        cellWidth
        description
        id
        title
      }
      storageSection {
        address
        description
        id
        title
        storageSectionType {
          description
          icon
          id
          separator
          title
        }
      }
    }
  }
}
    `;
/**
 * __useStorageCellCreateMutation__
 *
 * To run a mutation, you first call `useStorageCellCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStorageCellCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storageCellCreateMutation, { data, loading, error }] = useStorageCellCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorageCellCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StorageCellCreateDocument, options);
}
