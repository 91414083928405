var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function extractConditions(values) {
    const newConditions = [];
    Object.keys(values).forEach((key) => {
        if (key.startsWith("conditions-")) {
            const id = parseInt(key.split("-")[1], 10);
            const items = values[key];
            if (Array.isArray(items)) {
                items.forEach((item, index) => {
                    newConditions.push({
                        id: item === null || item === void 0 ? void 0 : item.id,
                        expressionId: id,
                        index,
                        operator: item === null || item === void 0 ? void 0 : item.operator,
                        where: item === null || item === void 0 ? void 0 : item.where,
                        productTypeAttributeId: item === null || item === void 0 ? void 0 : item.productTypeAttributeId,
                    });
                });
            }
        }
    });
    return newConditions;
}
export function processChangedValues(changedValues, expressions, debouncedUpdateExpressionResult) {
    return __awaiter(this, void 0, void 0, function* () {
        const keys = Object.keys(changedValues);
        const resultKey = keys.find((key) => key.includes("result"));
        if (resultKey) {
            const match = resultKey.match(/\d+$/);
            if (match) {
                const id = parseInt(match[0], 10);
                const expression = expressions.find((expr) => expr.id === id);
                if (expression) {
                    yield debouncedUpdateExpressionResult(expression, resultKey, changedValues);
                }
            }
        }
    });
}
