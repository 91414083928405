/* eslint-disable camelcase */
export const movementAdapter = (dto) => {
    const { quantity, clientTitle, skuId, productTitle, productType, storageCellTitle, taskShippingDate, taskShippingIsFinal, taskLoadingIsFinal, taskLoadingDate, taskLoadingId, taskLoadingInRange, taskShippingId, taskShippingInRange, warehouseTitle, clientInn, } = dto;
    return {
        quantity,
        clientTitle,
        skuId,
        productTitle,
        productType,
        storageCellTitle,
        taskShippingDate,
        taskShippingIsFinal,
        taskLoadingIsFinal,
        taskLoadingDate,
        taskLoadingId,
        taskLoadingInRange,
        taskShippingId,
        taskShippingInRange,
        warehouseTitle,
        clientInn,
        arrivals: dto.arrivals.map(movementTaskAdapter) || [],
        disposals: dto.disposals.map(movementTaskAdapter) || [],
    };
};
export const movementTaskAdapter = (dto) => {
    const { id, discriminator, executedAt, warehouseId, inRange, isFinal } = dto;
    return {
        id,
        discriminator,
        executedAt,
        warehouseId,
        inRange,
        isFinal
    };
};
export const movementTotalAdapter = (dto) => {
    const { totalCount, taskLoadingCount, taskLoadingQuantity, taskShippingQuantity, taskShippingCount, taskShippingSkus, taskLoadingSkus, } = dto;
    return {
        totalCount,
        taskLoadingCount,
        taskLoadingQuantity,
        taskShippingQuantity,
        taskShippingCount,
        taskShippingSkus,
        taskLoadingSkus,
    };
};
