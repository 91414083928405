import React from 'react';
import ClientsTable from '../../components/ClientsTable';
import { useFetchListQuery } from '../../redux/api/clientsApi';
const ClientListContainer = (props) => {
    var _a;
    const { page, pageSize } = props;
    const { data, isLoading } = useFetchListQuery({ page, pageSize });
    return (React.createElement(ClientsTable, { items: (_a = data === null || data === void 0 ? void 0 : data.list) !== null && _a !== void 0 ? _a : [], loading: isLoading }));
};
export default ClientListContainer;
