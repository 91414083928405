var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Page from "../../../components/Page";
import { COMMON_ATTRIBUTES } from "../../../components/nomenclatureSearch/ProductFilter/ProductFilter";
import ProductCategoryAddModal from "../../containers/ProductCategoryAddModal";
import ProductInfoDrawerContainer from "../../containers/ProductInfoDrawerContainer";
import ProductListHeaderContainer from "../../containers/ProductListHeaderContainer";
import ProductListPaginationContainer from "../../containers/ProductListPaginationContainer";
import ProductTableContainer from "../../containers/ProductTableContainer";
import ProductTreeContainer from "../../containers/ProductTreeContainer";
import ToolbarRight from "./ToolbarRight";
export const TYPE_ID_PARAM = "type";
const ProductsPage = () => {
    const [searchParams] = useSearchParams();
    const typeParam = searchParams.get("type");
    const type = typeParam ? +typeParam : null;
    const [filterValue, setFilterValue] = useState({});
    const handlerFilterChange = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setFilterValue(values[COMMON_ATTRIBUTES]);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Page, { toolbarLeft: React.createElement(ProductTreeContainer, null), toolbarBottom: React.createElement(ProductListPaginationContainer, null), toolbarTop: React.createElement(ProductListHeaderContainer, null), toolbarRight: !!type && React.createElement(ToolbarRight, { type: type, setFilterValue: setFilterValue, key: JSON.stringify(type), handlerFilterChange: handlerFilterChange }) },
            React.createElement(ProductTableContainer, { filterValue: filterValue }),
            React.createElement(ProductInfoDrawerContainer, null)),
        React.createElement(ProductCategoryAddModal, null)));
};
export default ProductsPage;
