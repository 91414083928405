import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
import MDIcon from "../../../../components/ui/MDIcon";
const PartitionDetailTaskNomenclatureActions = (props) => {
    const { id, isEditable, isCreated, isLoading, onEdit, onSave, onCancel, onSkuInfoClick } = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const closeDropdown = () => {
        if (dropdownVisible) {
            setDropdownVisible(false);
        }
    };
    useEffect(() => {
        const wrapper = document.querySelector(".app-content");
        if (dropdownVisible) {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.addEventListener("scroll", closeDropdown);
        }
        return () => {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.removeEventListener("scroll", closeDropdown);
        };
    }, [dropdownVisible]);
    const handlerEdit = () => {
        closeDropdown();
        onEdit(id);
    };
    if (isEditable) {
        return (React.createElement(Button.Group, null,
            React.createElement(Tooltip, { title: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { loading: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "content-save" }), size: "small", onClick: () => onSave(isCreated) })),
            React.createElement(Tooltip, { title: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { loading: isLoading, type: "primary", danger: true, icon: React.createElement(MDIcon, { icon: "close-circle" }), size: "small", onClick: onCancel }))));
    }
    return (React.createElement(Button.Group, null,
        React.createElement(Tooltip, { title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", mouseEnterDelay: 1 },
            React.createElement(Button, { loading: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "information-outline" }), size: "small", onClick: onSkuInfoClick })),
        React.createElement(Button, { loading: isLoading, icon: React.createElement(MDIcon, { icon: "pencil" }), onClick: handlerEdit, type: "primary", size: "small" })));
};
export default PartitionDetailTaskNomenclatureActions;
