import classNames from 'classnames';
import React, { useEffect, useRef, useState, } from 'react';
const Collapse = (props) => {
    const { collapsed, className, children } = props;
    const [init, setInit] = useState(false);
    const [animating, setAnimating] = useState(false);
    const wrapperRef = useRef(null);
    const classes = classNames({
        'ui-collapse': true,
        animating,
    });
    const calcCurrentHeight = () => {
        if (!collapsed && wrapperRef.current) {
            return wrapperRef.current.clientHeight;
        }
        return 0;
    };
    const [height, setHeight] = useState(calcCurrentHeight());
    useEffect(() => {
        var _a;
        const handlerTransitionEnd = () => setAnimating(false);
        (_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('onTransitionEnd', handlerTransitionEnd);
        return () => {
            var _a;
            (_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('onTransitionEnd', handlerTransitionEnd);
        };
    }, []);
    useEffect(() => {
        if (init) {
            setAnimating(true);
        }
        else {
            setInit(true);
        }
        setHeight(calcCurrentHeight());
    }, [collapsed]);
    return (React.createElement("div", { className: `${classes} ${className}`, style: { height } },
        React.createElement("div", { className: "ui-collapse-wrapper", ref: wrapperRef }, children)));
};
export default Collapse;
