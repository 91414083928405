import { Descriptions, Drawer, Space, Spin, Typography, } from 'antd';
import React from 'react';
const { Text, Title } = Typography;
const ProductInfoDrawer = (props) => {
    const { isOpen, loading, onClose, product, } = props;
    return (React.createElement(Drawer, { title: product === null || product === void 0 ? void 0 : product.titleCompiled, width: 700, placement: "right", onClose: onClose, destroyOnClose: true, open: isOpen },
        React.createElement(Spin, { spinning: loading },
            React.createElement(Space, { direction: "vertical", size: [24, 24], style: { width: '100%' } },
                (product === null || product === void 0 ? void 0 : product.productType.description) ? (React.createElement("div", null,
                    React.createElement(Title, { level: 5 }, "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435"),
                    React.createElement(Text, null, product === null || product === void 0 ? void 0 : product.productType.description))) : null, product === null || product === void 0 ? void 0 :
                product.productType.productTypeCategories.map((category) => {
                    var _a;
                    return (React.createElement(Descriptions, { title: category.title, bordered: true, size: "small", column: 1, key: category.id }, (_a = category.productTypeAttributes) === null || _a === void 0 ? void 0 : _a.filter((attr) => !attr.isPersonal).map((attr) => {
                        var _a, _b, _c;
                        return (React.createElement(Descriptions.Item, { label: attr.title, key: attr.title }, (_b = (_a = product.productAttributeValues[attr.id]) === null || _a === void 0 ? void 0 : _a.handbookValueReadable) !== null && _b !== void 0 ? _b : (_c = product.productAttributeValues[attr.id]) === null || _c === void 0 ? void 0 : _c.attributeValue));
                    })));
                })))));
};
export default ProductInfoDrawer;
