var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FileHelper } from '../../../../helpers/FileHelper';
import { getUrlWithParams } from '../../../../helpers/function';
import { request } from '../../api';
export const getReportExel = (filterValues, afterLoadCb) => __awaiter(void 0, void 0, void 0, function* () {
    const url = getUrlWithParams("/sku/xlsx", {});
    yield request().then((instance) => {
        return instance.post(url, filterValues, { responseType: "blob" }).then((res) => {
            FileHelper.saveDownload(res);
            afterLoadCb();
        });
    });
});
