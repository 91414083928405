import { useCreateUserGroupMutation, useDeleteUserGroupMutation, useEditUserGroupMutation } from "../../../../api/graphql/queries/userGpoup/UserGroupMutation";
export const useAccountsGroupsContainer = () => {
    const [editUserGroup, { loading: editLoading }] = useEditUserGroupMutation();
    const [createUserGroup, { loading: createLoading }] = useCreateUserGroupMutation();
    const [deleteUserGroup, { loading: deleteLoading }] = useDeleteUserGroupMutation();
    return {
        add: createUserGroup,
        edit: editUserGroup,
        del: deleteUserGroup,
        loading: editLoading || createLoading || deleteLoading,
    };
};
