import React, { createContext, useContext, useEffect, useReducer } from "react";
import { appLayoutReducer } from "./reducers";
const defaultState = {
    navigationIsCompact: false
};
export const AppLayoutContext = createContext(defaultState);
export const AppLayoutDispatchContext = createContext(() => null);
export const AppLayoutProvider = ({ children }) => {
    const STORAGE_KEY = 'appLayoutState';
    const savedState = () => {
        try {
            const saved = localStorage.getItem(STORAGE_KEY);
            if (saved == null)
                return defaultState;
            const initialValue = JSON.parse(saved);
            return initialValue || defaultState;
        }
        catch (e) {
            console.warn(e);
        }
        return defaultState;
    };
    const [state, dispatch] = useReducer(appLayoutReducer, savedState());
    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }, [state]);
    return (React.createElement(AppLayoutContext.Provider, { value: state },
        React.createElement(AppLayoutDispatchContext.Provider, { value: dispatch }, children)));
};
export function useAppLayout() {
    return useContext(AppLayoutContext);
}
export function useAppLayoutDispatch() {
    return useContext(AppLayoutDispatchContext);
}
