import { Form, Input } from 'antd';
import React from 'react';
var FormNames;
(function (FormNames) {
    FormNames["ID"] = "id";
    FormNames["TITLE"] = "title";
    FormNames["DESCRIPTION"] = "description";
    FormNames["ICON"] = "icon";
    FormNames["SEPARATOR"] = "separator";
})(FormNames || (FormNames = {}));
const WarehouseSectionTypeForm = (props) => {
    const { form, onSubmit, initialValues, } = props;
    return (React.createElement(Form, { form: form, onFinish: onSubmit, layout: "vertical", initialValues: initialValues },
        React.createElement(Form.Item, { name: FormNames.ID, hidden: true },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435", name: FormNames.TITLE },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { name: FormNames.DESCRIPTION, label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" },
            React.createElement(Input.TextArea, null)),
        React.createElement(Form.Item, { label: "\u0418\u043A\u043E\u043D\u043A\u0430", name: FormNames.ICON },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { label: "\u0420\u0430\u0437\u0434\u0435\u043B\u0438\u0442\u0435\u043B\u044C", name: FormNames.SEPARATOR },
            React.createElement(Input, null))));
};
export default WarehouseSectionTypeForm;
