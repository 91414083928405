var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Modal, Table, message } from "antd";
import React, { useMemo, useState } from "react";
import { createModalSkuColumns } from "./Columns";
import { useTaskInventotyApplyMovementMutation } from "../../../../../api/graphql/queries/task/taskInventory/TaskInventoryMutation";
import { MovementType } from "../../../../../api/graphql/common";
import { useParams } from "react-router-dom";
export const TableControls = ({ selectedItems, setSelectedItems, refetch }) => {
    const { taskId } = useParams();
    const [update] = useTaskInventotyApplyMovementMutation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalType, setModalType] = useState(null);
    const SKUColumn = useMemo(() => createModalSkuColumns(), []);
    const showModal = (type) => {
        setModalType(type);
        setIsModalVisible(true);
    };
    const handleOk = () => __awaiter(void 0, void 0, void 0, function* () {
        if (modalType && taskId) {
            try {
                yield update({
                    variables: {
                        taskId: Number(taskId),
                        inputApplyMovement: {
                            type: modalType,
                            taskInventorySkuIds: selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.map((item) => item.key),
                        },
                    },
                });
                try {
                    yield refetch();
                }
                catch (refetchError) {
                    message.error("Произошла ошибка при отправке данных.");
                }
            }
            catch (updateError) {
                message.error("Произошла ошибка при обновлении данных.");
            }
            finally {
                setIsModalVisible(false);
            }
        }
    });
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const renderModalContent = () => (React.createElement(Table, { size: "small", rowSelection: {
            type: "checkbox",
            selectedRowKeys: selectedItems.map((item) => item.key),
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedItems(selectedRows);
            },
        }, columns: SKUColumn, dataSource: selectedItems, pagination: false }));
    return (React.createElement(React.Fragment, null,
        React.createElement(Button.Group, { size: "small", style: { marginLeft: "auto" } },
            React.createElement(Button, { type: "primary", onClick: () => showModal(MovementType.CreateTask) }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0437\u0430\u0434\u0430\u0447\u0443 \u043D\u0430 \u043F\u0435\u0440\u0435\u043C\u0435\u0449\u0435\u043D\u0438\u0435"),
            React.createElement(Button, { type: "primary", onClick: () => showModal(MovementType.CreateTaskAndClose) }, "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C \u044F\u0447\u0435\u0439\u043A\u0443")),
        React.createElement(Modal, { title: "\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 SKU", open: isModalVisible, onOk: handleOk, onCancel: handleCancel }, renderModalContent())));
};
