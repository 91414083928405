import React from 'react';
import { Button, Col, Divider, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
const TaskItemFooter = (props) => {
    const { link, children, date, userName, } = props;
    const navigate = useNavigate();
    return (React.createElement(Row, { className: "align-items-center" },
        React.createElement(Col, { flex: "auto" },
            React.createElement("small", null, userName),
            React.createElement(Divider, { type: "vertical" }),
            React.createElement("small", null, date)),
        React.createElement(Col, { flex: "none" },
            React.createElement(Button.Group, null,
                children,
                React.createElement(Button, { onClick: () => navigate(link || '#'), size: 'small', type: 'primary', icon: React.createElement(ArrowRightOutlined, null) })))));
};
export default TaskItemFooter;
