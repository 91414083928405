import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ProductTypeListDocument = gql `
    query ProductTypeList($search: String, $page: Int!, $pageSize: Int!) {
  productTypeList(search: $search, page: $page, pageSize: $pageSize) {
    data {
      id
      title
      productCount
      productCategoryId
      updatedAt
      createdAt
      isExternal
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useProductTypeListQuery__
 *
 * To run a query within a React component, call `useProductTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypeListQuery({
 *   variables: {
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useProductTypeListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProductTypeListDocument, options);
}
export function useProductTypeListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProductTypeListDocument, options);
}
export function useProductTypeListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProductTypeListDocument, options);
}
export const ProductTypeDocument = gql `
    query ProductType($productTypeId: Long!) {
  productType(productTypeId: $productTypeId) {
    id
    title
    productCategoryId
    description
    sizeFormula
    titleMask
    isExternal
    productTypeCategories {
      id
      position
      title
      productTypeAttributes {
        id
        title
        isRequired
        isPersonal
        isReadOnly
        inFilter
        dataType
        canBeSummarized
        inPrint
        sortOrder
        formula
        position
        handbookId
        isMain
        dimension
        description
      }
    }
  }
}
    `;
/**
 * __useProductTypeQuery__
 *
 * To run a query within a React component, call `useProductTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypeQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useProductTypeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProductTypeDocument, options);
}
export function useProductTypeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProductTypeDocument, options);
}
export function useProductTypeSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ProductTypeDocument, options);
}
