var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from "react";
import { request } from "../../../api/rest/api";
import { message } from "antd";
export const useFileDownload = () => {
    const [isLoading, setIsLoading] = useState(false);
    const downloadFile = useCallback((fileId, fileName) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const response = yield request(undefined, window.env.FileHost)
            .then((instance) => {
            return instance.get(`/File/Download?key=${fileId}`, { responseType: "blob" });
        })
            .catch(() => {
            message.error("Ошибка получения информации по файлам");
        });
        if (response) {
            const blob = new Blob([response.data], { type: response.headers["content-type"] });
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute("download", fileName);
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        }
        setIsLoading(false);
    }), []);
    return { downloadFile, isLoading };
};
