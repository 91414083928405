import { Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
import Page from "../../../../../components/Page";
import PackingSetEditFormContainer from "../../components/PackingSetEditPage/PackingSetEditFormContainer";
import { TITLES } from "../../constants";
const { Title } = Typography;
const PackingSetEditPage = () => (React.createElement(Page, null,
    React.createElement(Row, null,
        React.createElement(Col, { span: 24 },
            React.createElement(Card, null,
                React.createElement(Title, { level: 3 }, TITLES.packing_set_edit),
                React.createElement(Divider, null),
                React.createElement(PackingSetEditFormContainer, null))))));
export default PackingSetEditPage;
