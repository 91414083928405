var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DeleteOutlined, MenuOutlined, MinusCircleOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row, Switch } from "antd";
import Card from "antd/es/card/Card";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { adaptProductTypeAttributeDtoToEntity } from "../../../../../../../api/graphql/adapters";
import ProductTypeAttrInput from "../../../../../../../components/ProductTypeAttrInput";
import { ProductAutomatizationContext } from "../../../ProductExpirationDate";
import { convertValueToString } from "../../../helpers/helpers";
import { hasValidCondition, getConditionAttributeId, getConditionByIndex, isValidCondition } from "../../../helpers/сonditionHeper";
import { useProductAutomatizationConditionPage } from "../../../hooks/api/useProductAutomatizationConditionPage";
import { ProductTypeAttributeSelect } from "../../ProductTypeAttributeSelect";
import { OperatorSelect } from "../../condition/OperatorSelect";
import { ProductTypeAttrContainer } from "../../condition/ProductTypeAttrContainer";
import { handleCommonError, handleSuccess } from "../../../../../../../helpers/function";
const GRID_COLUMN_SIZE = [1, 8, 15];
const DragHandle = SortableHandle(() => React.createElement(MenuOutlined, { style: { cursor: "grab", color: "#999" } }));
export const SortableItem = SortableElement((props) => {
    const { typeId } = useParams();
    const context = useContext(ProductAutomatizationContext);
    if (!context)
        throw new Error("useContext must be used within a Provider");
    const { handleDeleteExpression, handleChangeExpressionActivity, refetch, refetchLoading, sortLoading, productTypeAttribute, changeResultLoading, conditions, form } = context;
    const { editProductAutomatizationExpression, createProductAutomatizationExpression, deleteProductAutomatizationExpression } = useProductAutomatizationConditionPage();
    const { indexNumber, isActive, row, itemIndex } = props, rest = __rest(props, ["indexNumber", "isActive", "row", "itemIndex"]);
    const [isLoading, setIsLoading] = useState(false);
    const [where, setWhere] = useState([]);
    const [loadingIndex, setLoadingIndex] = useState(null);
    const onDelete = () => {
        handleDeleteExpression(indexNumber, setIsLoading);
    };
    const onActivityChange = (flag) => {
        handleChangeExpressionActivity(indexNumber, flag, setIsLoading);
    };
    const createCondition = (condition) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        setLoadingIndex(`${condition.expressionId}${condition.index}`);
        try {
            const result = yield createProductAutomatizationExpression.handleAction({
                operator: condition.operator,
                productTypeAttributeId: Number(condition.productTypeAttributeId),
                productTypeAutomationExpressionId: condition.expressionId,
                where: convertValueToString(condition.where),
            });
            const id = (_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.productTypeAutomationConditionCreate) === null || _b === void 0 ? void 0 : _b.productTypeAutomationConditionDto) === null || _c === void 0 ? void 0 : _c.id;
            if (id) {
                const newData = [...form.getFieldValue(`conditions-${indexNumber}`)].map((item, index) => {
                    if (index === condition.index) {
                        return Object.assign(Object.assign({}, item), { id: id });
                    }
                    return item;
                });
                form.setFieldsValue({ [`conditions-${indexNumber}`]: newData });
            }
            handleSuccess("Условие добавлено");
        }
        catch (error) {
            handleCommonError("Ошибка сохранения условия");
        }
        finally {
            setLoadingIndex(null);
        }
    });
    const editCondition = (condition) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingIndex(`${condition.expressionId}${condition.index}`);
        try {
            yield editProductAutomatizationExpression.handleAction({
                operator: condition.operator,
                productTypeAttributeId: Number(condition.productTypeAttributeId),
                where: convertValueToString(condition.where),
                id: condition.id,
            });
            yield refetch();
            handleSuccess("Условие обновлено");
        }
        catch (error) {
            handleCommonError("Ошибка сохранения условия");
        }
        setLoadingIndex(null);
    });
    const onSaveCondition = (condition) => {
        form.validateFields().then(() => __awaiter(void 0, void 0, void 0, function* () {
            if (isValidCondition(condition)) {
                if (!condition.id) {
                    try {
                        yield createCondition(condition);
                    }
                    catch (error) {
                        handleCommonError("Ошибка сохранения условия");
                    }
                }
                else {
                    try {
                        yield editCondition(condition);
                    }
                    catch (error) {
                        handleCommonError("Ошибка сохранения условия");
                    }
                }
            }
        }));
    };
    const onDeleteCondition = (condition) => __awaiter(void 0, void 0, void 0, function* () {
        if (condition === null || condition === void 0 ? void 0 : condition.id) {
            setLoadingIndex(`${condition.expressionId}${condition.index}`);
            try {
                yield deleteProductAutomatizationExpression.handleAction({
                    productTypeAutomationConditionId: condition === null || condition === void 0 ? void 0 : condition.id,
                });
                yield refetch();
                handleSuccess("Условие удалено");
            }
            catch (error) {
                handleCommonError("Ошибка удаления условия");
            }
            finally {
                setLoadingIndex(null);
            }
        }
    });
    useEffect(() => {
        const initialValues = {
            [`conditions-${indexNumber}`]: conditions === null || conditions === void 0 ? void 0 : conditions.filter((condition) => condition.expressionId === indexNumber).map((cond) => {
                setWhere((where) => [...where, cond.where]);
                return {
                    productTypeAttributeId: cond.productTypeAttributeId,
                    operator: cond.operator,
                    id: cond.id,
                };
            }),
        };
        form.setFieldsValue(initialValues);
    }, []);
    return (React.createElement("div", Object.assign({}, rest), sortLoading ? (React.createElement(Card, { loading: true })) : (React.createElement(React.Fragment, null,
        itemIndex > 0 && React.createElement(Divider, { plain: true }, "\u0415\u043B\u0438 \u043D\u0435\u0442 \u0442\u043E:"),
        React.createElement(Row, { gutter: [16, 16], style: { marginBottom: "10px" } },
            React.createElement(Col, { span: GRID_COLUMN_SIZE[0] },
                React.createElement(DragHandle, null)),
            React.createElement(Col, { span: GRID_COLUMN_SIZE[1] },
                React.createElement(Form.Item, { name: `result-${indexNumber}`, wrapperCol: { span: 24 } },
                    React.createElement(ProductTypeAttrInput, { withPreload: true, loading: changeResultLoading, attribute: adaptProductTypeAttributeDtoToEntity(productTypeAttribute) }))),
            React.createElement(Col, { span: GRID_COLUMN_SIZE[2], style: { display: "flex", justifyContent: "end", gap: "8px" } },
                React.createElement(Button, { loading: isLoading, onClick: onDelete, type: "primary", size: "small", icon: React.createElement(DeleteOutlined, null) }),
                React.createElement(Switch, { defaultChecked: isActive, onChange: onActivityChange, loading: isLoading }))),
        React.createElement(Form.List, { name: `conditions-${indexNumber}` }, (fields, { add, remove }) => (React.createElement(React.Fragment, null,
            fields.map(({ key, name }) => {
                const currentCondition = getConditionByIndex(conditions, indexNumber, name);
                const isConditionValid = hasValidCondition(conditions, indexNumber, name);
                const conditionAttributeId = getConditionAttributeId(conditions, indexNumber, name);
                const isLoading = `${currentCondition === null || currentCondition === void 0 ? void 0 : currentCondition.expressionId}${currentCondition === null || currentCondition === void 0 ? void 0 : currentCondition.index}` === loadingIndex && (refetchLoading || deleteProductAutomatizationExpression.isLoading || createProductAutomatizationExpression.isLoading || editProductAutomatizationExpression.isLoading);
                return (React.createElement(Row, { gutter: [16, 16], key: key + (conditions === null || conditions === void 0 ? void 0 : conditions.length), align: "bottom", style: { marginBottom: "10px" } },
                    React.createElement(Col, { span: GRID_COLUMN_SIZE[0] }),
                    React.createElement(Col, { span: GRID_COLUMN_SIZE[1] },
                        React.createElement(Row, { gutter: [16, 16] },
                            React.createElement(Form.Item, { name: [name, "id"], hidden: true },
                                React.createElement(Input, null)),
                            React.createElement(Col, { span: 12 },
                                React.createElement(Form.Item, { name: [name, "productTypeAttributeId"], style: { marginBottom: 0 }, rules: [{ required: true, message: "Выберите опцию" }] },
                                    React.createElement(ProductTypeAttributeSelect, { placeholder: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043E\u043F\u0446\u0438\u044E", typeId: Number(typeId) }))),
                            React.createElement(Col, { span: 12 },
                                React.createElement(Form.Item, { name: [name, "operator"], style: { marginBottom: 0 }, rules: [{ required: true, message: "Выберите опцию" }] },
                                    React.createElement(OperatorSelect, { placeholder: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043E\u043F\u0446\u0438\u044E" }))))),
                    React.createElement(Col, { span: GRID_COLUMN_SIZE[2] },
                        React.createElement(Row, { gutter: [16, 16], align: "middle" },
                            React.createElement(Col, { span: 22 }, isConditionValid && React.createElement(ProductTypeAttrContainer, { where: where[name], indexNumber: indexNumber, name: name, key: JSON.stringify(conditionAttributeId), id: currentCondition === null || currentCondition === void 0 ? void 0 : currentCondition.id, productTypeAttrId: conditionAttributeId })),
                            React.createElement(Col, { span: 1 },
                                React.createElement(Button.Group, null,
                                    React.createElement(Button, { loading: isLoading, disabled: !isConditionValid, onClick: () => onSaveCondition(currentCondition), type: "primary", size: "small", icon: React.createElement(SaveOutlined, null) }),
                                    React.createElement(Button, { loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            if (isConditionValid) {
                                                yield onDeleteCondition(currentCondition);
                                            }
                                            remove(name);
                                        }), size: "small", icon: React.createElement(MinusCircleOutlined, null) })))))));
            }),
            React.createElement(Row, null,
                React.createElement(Col, { span: GRID_COLUMN_SIZE[0] }),
                React.createElement(Col, { span: GRID_COLUMN_SIZE[1] + GRID_COLUMN_SIZE[2] },
                    React.createElement(Form.Item, null,
                        React.createElement(Button, { type: "dashed", style: { width: "100%" }, onClick: () => add(), icon: React.createElement(PlusOutlined, null) }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0443\u0441\u043B\u043E\u0432\u0438\u0435")))))))))));
});
