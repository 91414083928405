import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const TaskShippingListDocument = gql `
    query TaskShippingList($page: Int!, $pageSize: Int!, $filter: TaskFilterRequestInput!) {
  taskShippingList(page: $page, pageSize: $pageSize, filter: $filter) {
    data {
      id
      externalId
      createdAt
      updatedAt
      executedAt
      unloadingAddress
      clientFrom {
        id
        companyName
        companyInn
      }
      clientTo {
        id
        companyName
        companyInn
      }
      details {
        productId
        type
        title
        isRequirement
        requirementQuantity
        requirementIsRequired
        requirementId
        quantity
        dimension
        count
        requirementComment
      }
      warehouse {
        id
        storageTitle
      }
      user {
        userName
      }
      status {
        title
        style
        scope
      }
    }
    pagination {
      page
      itemsTotalCount
      itemsCurrentCount
      itemsOnPage
    }
  }
}
    `;
/**
 * __useTaskShippingListQuery__
 *
 * To run a query within a React component, call `useTaskShippingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskShippingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskShippingListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTaskShippingListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskShippingListDocument, options);
}
export function useTaskShippingListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskShippingListDocument, options);
}
export function useTaskShippingListSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskShippingListDocument, options);
}
export const TaskShippingDocument = gql `
    query TaskShipping($taskId: Long!) {
  taskShipping(taskId: $taskId) {
    id
    externalId
    createdAt
    updatedAt
    executedAt
    releaseAllowedName
    releaseAllowedPost
    releaseProducedName
    releaseProducedPost
    powerOfAttorneyDate
    powerOfAttorney
    payer {
      id
      companyName
      companyInn
    }
    clientFrom {
      id
      companyName
      companyInn
    }
    driverName
    vehicleNumber
    unloadingAddress
    requestNumber
    requestDate
    carrier {
      id
      companyName
      companyInn
    }
    clientTo {
      id
      companyName
      companyInn
    }
    warehouse {
      id
      storageTitle
    }
    user {
      userName
    }
    details {
      isRequirement
      productId
      type
      title
      requirementQuantity
      requirementIsRequired
      requirementId
      quantity
      dimension
      count
      requirementComment
      skus {
        detailsId
        id
        quantity
        skuAttributeValues {
          key
          value {
            value
            valueReadable
            id
          }
        }
        status {
          title
          style
        }
        storageCell
        storageCellId
      }
      attributes {
        canBeSummarized
        dataType
        dimension
        isRequired
        isMain
        title
        inPrint
        inFilter
        isPersonal
        canBeSummarized
        handbookId
        id
      }
    }
    status {
      title
      style
      scope
      relations {
        id
        title
        style
        needReport
      }
    }
  }
}
    `;
/**
 * __useTaskShippingQuery__
 *
 * To run a query within a React component, call `useTaskShippingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskShippingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskShippingQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskShippingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TaskShippingDocument, options);
}
export function useTaskShippingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TaskShippingDocument, options);
}
export function useTaskShippingSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(TaskShippingDocument, options);
}
export const GetTaskShippingHistoryDocument = gql `
    query getTaskShippingHistory($taskId: Long!) {
  taskShippingHistory(taskId: $taskId) {
    createdAt
    isEntryPoint
    needReport
    report
    statusFrom {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    statusTo {
      title
      isFinal
      scope
      style
      relations {
        id
        needReport
        style
        needReport
      }
    }
    user {
      uid
      email
      userName
      id
    }
  }
}
    `;
/**
 * __useGetTaskShippingHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskShippingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskShippingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskShippingHistoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskShippingHistoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTaskShippingHistoryDocument, options);
}
export function useGetTaskShippingHistoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTaskShippingHistoryDocument, options);
}
export function useGetTaskShippingHistorySuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetTaskShippingHistoryDocument, options);
}
