/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import LoadingState from '../../../constants/enum/LoadingState';
const initialState = {
    title: '',
    loadingState: LoadingState.Ready,
    isOpen: false,
    url: null,
};
const printDialogSlice = createSlice({
    name: 'printDialogSlice',
    initialState,
    reducers: {
        open(state, action) {
            state.isOpen = true;
            state.url = action.payload;
        },
        close(state) {
            state.isOpen = false;
            state.url = null;
            state.title = '';
        },
        changeLoadingState(state, action) {
            state.loadingState = action.payload;
        },
        setTitle(state, action) {
            state.title = action.payload;
        },
    },
});
export const selectPrintDialog = (state) => state.printDialog;
export const printDialogActions = printDialogSlice.actions;
export default printDialogSlice.reducer;
