import React from "react";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { useTaskMovement } from "../../../../hooks/api/taskMovementApi/useTaskMovement";
import MovementTaskListContainer from "../../../containers/task/movement/MovementTaskListContainer";
import MovementTaskListFilterContainer from "../../../containers/task/movement/MovementTaskListFilterContainer";
import MovementTaskListHeaderContainer from "../../../containers/task/movement/MovementTaskListHeaderContainer";
import MovementTaskPaginationContainer from "../../../containers/task/movement/MovementTaskPaginationContainer";
const MovementListPage = () => {
    const { taskList } = useTaskMovement();
    const props = {
        toolbarBottom: React.createElement(MovementTaskPaginationContainer, { pagination: taskList.pagination, isLoading: taskList.loading, data: taskList.data }),
        toolbarTop: React.createElement(MovementTaskListHeaderContainer, { data: taskList === null || taskList === void 0 ? void 0 : taskList.data, isLoading: taskList.loading, pagination: taskList === null || taskList === void 0 ? void 0 : taskList.pagination }),
        toolbarRight: React.createElement(MovementTaskListFilterContainer, { isLoading: taskList.loading, objectClass: ObjectClass.TaskMovement, fetchList: taskList.paginationFilteredGetTaskList }),
    };
    return (React.createElement(Page, Object.assign({}, props),
        React.createElement(MovementTaskListContainer, { pagination: taskList.pagination, taskList: taskList.data, isLoading: taskList.loading })));
};
export default MovementListPage;
