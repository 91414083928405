var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Col, Descriptions, Divider, Row } from 'antd';
import Title from "antd/lib/typography/Title";
import React from "react";
import Task from "../../../../components/task/Task";
const ChangeWarehouseTaskContainer = (props) => {
    var _a, _b, _c, _d, _e;
    const { id, isLoading, task } = props, tabs = __rest(props, ["id", "isLoading", "task"]);
    const infoWarehouseRequisitesSection = [
        { title: "Склад", value: task === null || task === void 0 ? void 0 : task.warehouse.storageTitle },
        { title: "Плательщик", value: (_a = task === null || task === void 0 ? void 0 : task.payer) === null || _a === void 0 ? void 0 : _a.companyName },
        { title: "Грузоотправитель", value: (_b = task === null || task === void 0 ? void 0 : task.shipper) === null || _b === void 0 ? void 0 : _b.companyName },
        { title: "Грузополучатель", value: (_c = task === null || task === void 0 ? void 0 : task.consignee) === null || _c === void 0 ? void 0 : _c.companyName },
        { title: "Перевозчик", value: (_d = task === null || task === void 0 ? void 0 : task.carrier) === null || _d === void 0 ? void 0 : _d.companyName },
    ];
    const infoWarehouseResponsibleToSection = [
        { title: "ФИО", value: task === null || task === void 0 ? void 0 : task.departureProducedName },
        { title: "Должность", value: task === null || task === void 0 ? void 0 : task.departureProducedPost },
    ];
    const infoWarehouseResponsibleFromSection = [
        { title: "ФИО", value: task === null || task === void 0 ? void 0 : task.departureAllowedName },
        { title: "Должность", value: task === null || task === void 0 ? void 0 : task.departureAllowedPost },
    ];
    const infoWarehouseDriverSection = [
        { title: "ФИО", value: task === null || task === void 0 ? void 0 : task.departureDriverName },
        { title: "Номер т/с", value: task === null || task === void 0 ? void 0 : task.departureVehicleNumber },
        { title: "Доверенность", value: task === null || task === void 0 ? void 0 : task.departurePowerOfAttorney },
        { title: "Дата доверенности", value: task === null || task === void 0 ? void 0 : task.departurePowerOfAttorneyDate },
    ];
    const destinationWarehouseRequisitesSection = [{ title: "Склад", value: (_e = task === null || task === void 0 ? void 0 : task.destinationWarehouse) === null || _e === void 0 ? void 0 : _e.storageTitle }];
    const destinationWarehouseResponsibleToSection = [
        { title: "ФИО", value: task === null || task === void 0 ? void 0 : task.destinationProducedName },
        { title: "Должность", value: task === null || task === void 0 ? void 0 : task.destinationProducedPost },
    ];
    const destinationWarehouseResponsibleFromSection = [
        { title: "ФИО", value: task === null || task === void 0 ? void 0 : task.destinationAllowedName },
        { title: "Должность", value: task === null || task === void 0 ? void 0 : task.destinationAllowedPost },
    ];
    const destinationWarehouseDriverSection = [
        { title: "ФИО", value: task === null || task === void 0 ? void 0 : task.destinationDriverName },
        { title: "Номер т/с", value: task === null || task === void 0 ? void 0 : task.destinationVehicleNumber },
        { title: "Доверенность", value: task === null || task === void 0 ? void 0 : task.destinationPowerOfAttorney },
        { title: "Дата доверенности", value: task === null || task === void 0 ? void 0 : task.destinationPowerOfAttorneyDate },
    ];
    const renderTaskInfo = (items, title) => {
        return React.createElement(Descriptions, { title: title, size: 'small', column: 1 }, items.map(item => {
            return React.createElement(Descriptions.Item, { label: item.title }, item.value);
        }));
    };
    return (React.createElement(Task, Object.assign({ isLoading: isLoading, requisitesTab: React.createElement(React.Fragment, null,
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                    React.createElement(Title, { level: 4 }, "\u0421\u043A\u043B\u0430\u0434 \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u0435\u043B\u044C"),
                    renderTaskInfo(infoWarehouseRequisitesSection, 'Реквизиты'),
                    React.createElement(Divider, null),
                    renderTaskInfo(infoWarehouseResponsibleToSection, 'Ответственные (отпуск произвел)'),
                    React.createElement(Divider, null),
                    renderTaskInfo(infoWarehouseResponsibleFromSection, 'Ответственные (отпуск разрешил)'),
                    React.createElement(Divider, null),
                    renderTaskInfo(infoWarehouseDriverSection, 'Водитель')),
                React.createElement(Col, { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                    React.createElement(Title, { level: 4 }, "\u0421\u043A\u043B\u0430\u0434 \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044C"),
                    renderTaskInfo(destinationWarehouseRequisitesSection, 'Реквизиты'),
                    React.createElement(Divider, null),
                    renderTaskInfo(destinationWarehouseResponsibleToSection, 'Ответственные (приём произвел)'),
                    React.createElement(Divider, null),
                    renderTaskInfo(destinationWarehouseResponsibleFromSection, 'Ответственные (приём разрешил)'),
                    React.createElement(Divider, null),
                    renderTaskInfo(destinationWarehouseDriverSection, 'Водитель')))) }, tabs)));
};
export default ChangeWarehouseTaskContainer;
