var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSkuHistoryLazyQuery, useSkuLazyQuery, useSkuListLazyQuery } from "../../../api/graphql/queries/sku/SkuQuery";
export const useSku = () => {
    var _a, _b;
    const [getById, { loading: getSkuByIdLoading, data: getSkuByIdData, refetch: refetchGetSkuById }] = useSkuLazyQuery();
    const [getSkuList, { loading: getSkuListLoading, data: getSkuListData, refetch: refetchSkuList }] = useSkuListLazyQuery();
    const [getHistory, { loading: getHistoryLoading, data: getHistoryData, refetch: refetchHistory }] = useSkuHistoryLazyQuery();
    const paginationFilteredGetTaskList = (filter, pageSize, page) => __awaiter(void 0, void 0, void 0, function* () {
        yield getSkuList({ variables: { page, pageSize, filter }, fetchPolicy: "cache-and-network" });
    });
    return {
        history: {
            getHistory,
            data: getHistoryData === null || getHistoryData === void 0 ? void 0 : getHistoryData.skuHistory,
            loading: getHistoryLoading,
            refetch: refetchHistory,
        },
        sku: {
            getById,
            data: getSkuByIdData === null || getSkuByIdData === void 0 ? void 0 : getSkuByIdData.sku,
            loading: getSkuByIdLoading,
            refetch: refetchGetSkuById,
        },
        skuList: {
            data: (_a = getSkuListData === null || getSkuListData === void 0 ? void 0 : getSkuListData.skuList) === null || _a === void 0 ? void 0 : _a.data,
            loading: getSkuListLoading,
            pagination: (_b = getSkuListData === null || getSkuListData === void 0 ? void 0 : getSkuListData.skuList) === null || _b === void 0 ? void 0 : _b.pagination,
            getSkuList,
            paginationFilteredGetTaskList,
            refetch: refetchSkuList,
        },
    };
};
