var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { InventoryByType } from "../../../../api/graphql/common";
import Scope from "../../../../constants/enum/Scope";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import InventoryTaskFormNomenclature from "../../../conponents/task/inventory/InventoryTaskFormNomenclature";
import { TabsContainer } from "../../../conponents/task/inventory/TabsContainer/TabsContainer";
import { useTaskInventoryDetails } from "../../../../hooks/api/taskInventoryApi/useTaskInventoryDetails";
const InventoryTaskFormNomenclatureContainer = (props) => {
    var _a;
    const { taskId, task, isLoading, refetch } = props;
    const { taskDetails } = useTaskInventoryDetails();
    const handlerAddOriginal = (values, type) => __awaiter(void 0, void 0, void 0, function* () {
        (taskDetails === null || taskDetails === void 0 ? void 0 : taskDetails.create) && (yield taskDetails.create({
            variables: {
                input: {
                    taskId,
                    request: {
                        ids: values,
                        inventoryByType: type,
                    },
                },
            },
        }));
        yield refetch();
    });
    return (React.createElement(InventoryTaskFormNomenclature, { editable: task ? taskHelpers.hasScope(task, Scope.DETAILS_EDIT) : false, isLoading: !task || isLoading || taskDetails.loading, onAddSku: (values) => {
            handlerAddOriginal(values, InventoryByType.Sku);
        }, onAddProduct: (values) => {
            handlerAddOriginal(values, InventoryByType.Product);
        }, onAddCell: (values) => {
            handlerAddOriginal(values, InventoryByType.StorageCell);
        }, warehouseId: (_a = task === null || task === void 0 ? void 0 : task.warehouse) === null || _a === void 0 ? void 0 : _a.id },
        React.createElement(TabsContainer, { task: task, refetch: refetch })));
};
export default InventoryTaskFormNomenclatureContainer;
