import { Input } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
export const HANDBOOK_SEARCH = "search";
export const HandbookSearch = ({}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState("");
    const debouncedSearch = useCallback(debounce((value) => setSearch(value), 300), []);
    const handleSearchChange = (e) => {
        debouncedSearch(e.target.value);
    };
    useEffect(() => {
        if (search) {
            searchParams.set(HANDBOOK_SEARCH, search);
            setSearchParams(searchParams);
        }
        else {
            searchParams.delete(HANDBOOK_SEARCH);
            setSearchParams(searchParams);
        }
    }, [search, searchParams, setSearchParams]);
    return React.createElement(Input, { placeholder: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u0434\u043B\u044F \u043F\u043E\u0438\u0441\u043A\u0430", onChange: handleSearchChange, style: { marginBottom: "16px", width: "100%" } });
};
