import React from 'react';
import { Button, Form, Input } from 'antd';
const HandbookValueForm = (props) => {
    const { onSubmit, isLoading } = props;
    const [form] = Form.useForm();
    const handlerSubmit = (values) => {
        onSubmit(values)
            .then(() => {
            form.resetFields();
        });
    };
    return (React.createElement(Form, { layout: "vertical", onFinish: handlerSubmit, form: form },
        React.createElement(Form.Item, { name: "title", label: "\u0417\u043D\u0430\u0447\u0435\u043D\u0438\u0435", rules: [{ required: true, message: 'Укажите значение' }] },
            React.createElement(Input, null)),
        React.createElement(Button, { type: "primary", block: true, htmlType: "submit", loading: isLoading }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C")));
};
export default HandbookValueForm;
