var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from "react";
import { debounce } from "lodash";
import { Select, Spin } from "antd";
import { getDefaultPaginationEntity } from "../../entities/PaginationEntity";
import LoadingState from "../../constants/enum/LoadingState";
import Centered from "../ui/Centered";
const { Option } = Select;
class SearchSelect extends Component {
    constructor(props) {
        super(props);
        this.handlerSearch = (query = "") => {
            this.setState({ search: query });
            this.handlesSearchRequest();
        };
        this.handlesSearchRequest = debounce(() => {
            this.setState((state) => ({
                pagination: Object.assign(Object.assign({}, state.pagination), { page: 1 }),
                data: [],
            }));
            this.handlerRequest().finally();
        }, 800);
        this.handlerChange = (newValue) => {
            const { onChange } = this.props;
            this.setState({
                value: newValue,
            });
            if (onChange)
                onChange(newValue);
        };
        this.setLoadingState = (newState) => {
            this.setState({ loadingState: newState });
        };
        this.handlerRequest = () => __awaiter(this, void 0, void 0, function* () {
            const { pagination, search } = this.state;
            const { request, paginated } = this.props;
            const { page, itemsOnPage } = pagination;
            if (paginated === false) {
                const req = request;
                const response = yield req(search, this.setLoadingState);
                this.setState((state) => ({
                    data: [...state.data, ...response],
                }));
            }
            else {
                const req = request;
                const response = yield req(page, itemsOnPage, search, this.setLoadingState);
                this.setState((state) => ({
                    data: [...state.data, ...response.list],
                    pagination: response.pagination,
                }));
            }
        });
        this.handlerPagination = (page, itemsOnPage) => __awaiter(this, void 0, void 0, function* () {
            yield this.setState((state) => ({
                pagination: Object.assign(Object.assign({}, state.pagination), { page, itemsOnPage: itemsOnPage !== null && itemsOnPage !== void 0 ? itemsOnPage : state.pagination.itemsOnPage }),
            }));
            this.handlerRequest().finally();
        });
        this.handlerFocus = () => __awaiter(this, void 0, void 0, function* () {
            const { data } = this.state;
            if (data.length === 0) {
                this.handlerRequest().finally();
            }
        });
        this.notFoundContent = () => {
            const { loadingState } = this.state;
            if (loadingState === LoadingState.Loading) {
                return React.createElement(Spin, { size: "small" });
            }
            return null;
        };
        this.dropdownRender = (menu) => {
            const { pagination, loadingState } = this.state;
            if (pagination.itemsCurrentCount >= pagination.itemsTotalCount) {
                return menu;
            }
            return (React.createElement("div", { style: { position: "relative" } },
                menu,
                loadingState === LoadingState.Loading ? (React.createElement(Centered, { style: {
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "white",
                        height: "auto",
                    } },
                    React.createElement(Spin, null))) : null));
        };
        this.handlerScroll = (event) => {
            const target = event.target;
            if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                const { pagination } = this.state;
                const { page, itemsOnPage, itemsCurrentCount, itemsTotalCount } = pagination;
                const loadedItems = (page - 1) * itemsOnPage + itemsCurrentCount;
                if (loadedItems < itemsTotalCount) {
                    this.handlerPagination(page + 1).finally();
                }
            }
        };
        this.state = {
            value: props.value,
            search: "",
            data: [],
            pagination: getDefaultPaginationEntity(30),
            loadingState: LoadingState.Ready,
        };
    }
    render() {
        const _a = this.props, { 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        placeholder, dataToOption, request, onChange, style, additionalOptions } = _a, otherProps = __rest(_a, ["placeholder", "dataToOption", "request", "onChange", "style", "additionalOptions"]);
        const { value, data, search } = this.state;
        return (React.createElement(Select, Object.assign({}, otherProps, { showSearch: true, searchValue: search, labelInValue: true, onPopupScroll: this.handlerScroll, value: value, placeholder: placeholder, notFoundContent: this.notFoundContent(), filterOption: false, onFocus: this.handlerFocus, onSearch: this.handlerSearch, onChange: this.handlerChange, dropdownRender: this.dropdownRender, style: Object.assign(Object.assign({}, style), { width: "100%", minWidth: "100px" }) }),
            additionalOptions, data === null || data === void 0 ? void 0 :
            data.map((item) => {
                const option = dataToOption(item);
                return (React.createElement(Option, { key: option.value, value: option.value }, option.label));
            })));
    }
}
export default SearchSelect;
