import React from 'react';
import classNames from 'classnames';
import { Avatar, Spin } from 'antd';
import { links } from '../../constants/links';
import Collapse from '../ui/Collapse';
import { useAuth } from 'react-oidc-context';
const UserPanel = (props) => {
    var _a, _b, _c, _d;
    const auth = useAuth();
    const { name, toggle, onHeaderClick, onLeftArrowClick, loading, } = props;
    const classList = classNames({
        'app-user': true,
        'show-user-menu': toggle,
    });
    const initials = (_d = (_c = (_b = (_a = name === null || name === void 0 ? void 0 : name.split(' ')) === null || _a === void 0 ? void 0 : _a.filter((item, index) => index < 2)) === null || _b === void 0 ? void 0 : _b.map((item) => item[0])) === null || _c === void 0 ? void 0 : _c.join('')) === null || _d === void 0 ? void 0 : _d.toUpperCase();
    return (React.createElement("div", { className: classList },
        React.createElement("div", { className: "user-nav" },
            React.createElement("div", { className: "toggle mdi mdi-arrow-left", onClick: onLeftArrowClick }),
            React.createElement("div", { className: "user-profile", onClick: onHeaderClick },
                React.createElement(Avatar, { style: { marginLeft: 10, background: '#1890ff' } }, initials),
                React.createElement("div", { className: "info" },
                    React.createElement(Spin, { spinning: loading }, name)),
                React.createElement("div", { className: "caret mdi mdi-chevron-down" }))),
        React.createElement(Collapse, { collapsed: !toggle, className: "user-menu" },
            React.createElement("div", { className: "user-menu-list" },
                React.createElement("a", { href: window.env.TemplateEditorHost, target: "_blank", className: "mdi mdi-file-document-edit-outline", rel: "noreferrer" }, "\u0420\u0435\u0434\u0430\u043A\u0442\u043E\u0440 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u043E\u0432"),
                React.createElement("a", { href: window.env.SchedulerHost, target: "_blank", className: "mdi mdi-clock-outline", rel: "noreferrer" }, "\u041F\u043B\u0430\u043D\u0438\u0440\u043E\u0432\u0449\u0438\u043A \u0437\u0430\u0434\u0430\u0447")),
            React.createElement("div", { className: "user-menu-list" },
                React.createElement("a", { href: links.oidc.profile.url(), target: "_blank", className: "mdi mdi-account", rel: "noreferrer" }, "\u041F\u0440\u043E\u0444\u0438\u043B\u044C"),
                React.createElement("span", { onClick: () => { var _a; auth.signoutRedirect({ id_token_hint: (_a = auth.user) === null || _a === void 0 ? void 0 : _a.id_token, post_logout_redirect_uri: window.env.FrontHost }); }, className: "mdi mdi-logout" }, "\u0412\u044B\u0439\u0442\u0438")))));
};
export default UserPanel;
