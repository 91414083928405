import { Empty } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import HandbookWarehouseTopologyHeader from '../../../../components/HandbookWarehouseTopologyHeader';
import Page from '../../../../components/Page';
import Centered from '../../../../components/ui/Centered';
import WarehouseCellModal from '../../../containers/WarehouseCellModal';
import WarehouseSectionModal from '../../../containers/WarehouseSectionModal';
import WarehouseTopologyContainer from '../../../containers/WarehouseTopologyContainer';
const HandbookWarehouseTopologyPage = () => {
    const { id } = useParams();
    return (React.createElement(Page, { toolbarLeft: React.createElement(WarehouseTopologyContainer, null), toolbarTop: React.createElement(HandbookWarehouseTopologyHeader, { warehouseId: id }) },
        React.createElement(Centered, null,
            React.createElement(Empty, { description: "\u0422\u043E\u043F\u043E\u043B\u043E\u0433\u0438\u044F \u0441\u043A\u043B\u0430\u0434\u0430" })),
        React.createElement(WarehouseSectionModal, null),
        React.createElement(WarehouseCellModal, null)));
};
export default HandbookWarehouseTopologyPage;
