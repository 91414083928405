import { Button, Form, Input, Modal, Select, Skeleton, Switch } from "antd";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { productTypeGraphqlApi } from "../../../../../../redux/api/productApi/productType";
const { useFetchProductTypeQuery } = productTypeGraphqlApi;
function filterProductTypeAttributes(categories) {
    return categories.flatMap((category) => category.productTypeAttributes || []);
}
const CreateProductAutomationModal = ({ visible, isLoading, onSubmit, onCancel }) => {
    const [form] = Form.useForm();
    const { typeId: typeParam } = useParams();
    const typeId = typeParam ? +typeParam : null;
    const { data, isLoading: typeIsLoading } = useFetchProductTypeQuery({ productTypeId: typeId }, { skip: !Number.isInteger(typeId) });
    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
            onSubmit(values);
        })
            .catch((info) => {
            console.error("Validate Failed:", info);
        })
            .finally(() => {
            form.resetFields();
        });
    };
    const attr = useMemo(() => filterProductTypeAttributes((data === null || data === void 0 ? void 0 : data.productTypeCategories) || []).map((i) => ({ label: i.title, value: i.id })), [JSON.stringify(data === null || data === void 0 ? void 0 : data.productTypeCategories)]);
    return (React.createElement(Modal, { title: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0437\u0430\u0446\u0438\u044E", open: visible, onOk: handleOk, onCancel: onCancel, footer: [
            React.createElement(Button, { key: "back", onClick: onCancel }, "\u041E\u0442\u043C\u0435\u043D\u0430"),
            React.createElement(Button, { disabled: typeIsLoading || isLoading, key: "submit", type: "primary", onClick: handleOk }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C"),
        ] }, typeIsLoading ? (React.createElement(Skeleton, null)) : (React.createElement(Form, { form: form, layout: "vertical" },
        React.createElement(Form.Item, { name: "enable", label: "\u0410\u043A\u0442\u0438\u0432\u043D\u0430", valuePropName: "checked" },
            React.createElement(Switch, { disabled: typeIsLoading || isLoading })),
        React.createElement(Form.Item, { name: "title", label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435", rules: [{ required: true, message: "Введите название" }] },
            React.createElement(Input, { disabled: typeIsLoading || isLoading })),
        React.createElement(Form.Item, { name: "productTypeAttributeId", label: "\u0410\u0442\u0440\u0438\u0431\u0443\u0442 \u043F\u0440\u043E\u0434\u0443\u043A\u0442\u0430", rules: [{ required: true, message: "Выберите атрибут продукта" }] },
            React.createElement(Select, { disabled: typeIsLoading || isLoading, options: attr })),
        React.createElement(Form.Item, { name: "schedule", label: "\u0420\u0430\u0441\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F" },
            React.createElement(Input, { disabled: typeIsLoading || isLoading }))))));
};
export default CreateProductAutomationModal;
