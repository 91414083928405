var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useWriteOffDetails } from "../../../../hooks/api/taskWriteOffApi/useWriteOffDetails";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import Scope from "../../../../constants/enum/Scope";
import WriteOffTaskFormNomenclature from "../../../conponents/task/writeOff/WriteOffTaskFormNomenclature";
import TableContainer from "../../../conponents/task/writeOff/Tables/EditTable/Table";
const WriteOffTaskFormNomenclatureContainer = (props) => {
    const { taskId, task, isLoading, refetch } = props;
    const { taskDetails } = useWriteOffDetails();
    const handlerAddSku = (skuIds) => __awaiter(void 0, void 0, void 0, function* () {
        const body = skuIds.map((skuId) => ({ skuId }));
        yield taskDetails.create({
            variables: {
                input: body,
                taskId,
            },
        });
        yield refetch();
    });
    return (React.createElement(WriteOffTaskFormNomenclature, { onAddSku: handlerAddSku, editable: task ? taskHelpers.hasScope(task, Scope.DETAILS_EDIT) : false, isLoading: !task || isLoading || taskDetails.loading, warehouseId: (task === null || task === void 0 ? void 0 : task.warehouse) ? [`${task === null || task === void 0 ? void 0 : task.warehouse.id}`] : [] },
        React.createElement(TableContainer, { taskId: taskId, refetch: refetch, taskWriteOffSkuDto: task === null || task === void 0 ? void 0 : task.taskWriteOffSkus })));
};
export default WriteOffTaskFormNomenclatureContainer;
