import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../../components/Page';
import HandbookWarehouseContainer from '../../containers/HandbookWarehouseContainer';
const HandbookWarehousesItemPage = () => {
    const { id } = useParams();
    return (React.createElement(Page, null,
        React.createElement(HandbookWarehouseContainer, { id: id })));
};
export default HandbookWarehousesItemPage;
