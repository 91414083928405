import { Col, Row, Skeleton, Space, Spin, Typography, } from 'antd';
import React from 'react';
const TaskListHeader = (props) => {
    const { isEmpty, currentCount, totalCount, isLoading, children, } = props;
    if (isEmpty) {
        return (React.createElement(Row, { align: "middle", gutter: [15, 0] },
            React.createElement(Col, { flex: "auto", key: 0 },
                React.createElement(Skeleton.Input, { active: true, style: { width: 200 }, size: "small" })),
            React.createElement(Col, { flex: "none", key: 1 },
                React.createElement(Space, null,
                    React.createElement(Skeleton.Button, { active: true, style: { width: 250 } })))));
    }
    return (React.createElement(Row, { align: "middle", gutter: [15, 0] },
        React.createElement(Col, { flex: "none", key: 0 },
            React.createElement(Typography.Title, { className: "m-0", level: 5 }, `Показано: ${currentCount} из ${totalCount}`)),
        React.createElement(Col, { flex: "auto", key: 1 },
            React.createElement(Spin, { spinning: isLoading })),
        React.createElement(Col, { flex: "none", key: 2 }, children)));
};
export default TaskListHeader;
