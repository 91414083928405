import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import MDIcon from "../../../../components/ui/MDIcon";
import { PartitionContext } from "../../../pages/task/partition/PartitionEditPage";
const { confirm } = Modal;
const PartitionDetailNomenclatureActions = (props) => {
    const { id, isOriginal, isLoading, isEditable, onAddCreated, onDeleteCreated, onEditCreated, onSaveCreated, onCancel } = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const context = useContext(PartitionContext);
    const toggleDropdown = () => setDropdownVisible((value) => !value);
    const closeDropdown = () => {
        if (dropdownVisible) {
            setDropdownVisible(false);
        }
    };
    useEffect(() => {
        const wrapper = document.querySelector(".app-content");
        if (dropdownVisible) {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.addEventListener("scroll", closeDropdown);
        }
        return () => {
            wrapper === null || wrapper === void 0 ? void 0 : wrapper.removeEventListener("scroll", closeDropdown);
        };
    }, [dropdownVisible]);
    const handlerEdit = () => {
        closeDropdown();
        onEditCreated(id);
    };
    const handlerDelete = () => {
        closeDropdown();
        confirm({
            title: "Удалить деление?",
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: "Вы уверены что хотите удалить деление?",
            okText: "Да",
            okType: "danger",
            cancelText: "Нет",
            onOk() {
                onDeleteCreated(id);
            },
        });
    };
    const skuDropdownMenu = {
        items: [
            {
                key: "sku_edit",
                icon: React.createElement(MDIcon, { icon: "pencil", className: "ant-dropdown-menu-item-icon" }),
                label: "Редактировать",
                onClick: () => handlerEdit()
            },
            {
                key: "sku_delete",
                danger: true,
                icon: React.createElement(MDIcon, { icon: "delete-forever", className: "ant-dropdown-menu-item-icon" }),
                label: "Удалить",
                onClick: () => handlerDelete()
            }
        ]
    };
    if (isOriginal) {
        return (React.createElement(Tooltip, { title: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0434\u0435\u043B\u0435\u043D\u0438\u0435", mouseEnterDelay: 1, placement: "left" },
            React.createElement(Button, { disabled: context === null || context === void 0 ? void 0 : context.isLocked, loading: isLoading, icon: React.createElement(MDIcon, { icon: "content-cut" }), onClick: () => onAddCreated(id), type: "primary", size: "small" })));
    }
    if (isEditable) {
        return (React.createElement(Button.Group, null,
            React.createElement(Tooltip, { title: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { loading: isLoading, type: "primary", icon: React.createElement(MDIcon, { icon: "content-save" }), size: "small", onClick: onSaveCreated })),
            React.createElement(Tooltip, { title: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C", mouseEnterDelay: 1 },
                React.createElement(Button, { loading: isLoading, type: "primary", danger: true, icon: React.createElement(MDIcon, { icon: "close-circle" }), size: "small", onClick: onCancel }))));
    }
    return (React.createElement(Dropdown, { disabled: context === null || context === void 0 ? void 0 : context.isLocked, menu: skuDropdownMenu, open: dropdownVisible, onOpenChange: toggleDropdown, placement: "topLeft", arrow: true, trigger: ["click"] },
        React.createElement(Button, { loading: isLoading, icon: React.createElement(MDIcon, { icon: "dots-vertical" }), type: "primary", size: "small" })));
};
export default PartitionDetailNomenclatureActions;
