var ColorPalette;
(function (ColorPalette) {
    ColorPalette["Primary"] = "primary";
    ColorPalette["Secondary"] = "secondary";
    ColorPalette["Success"] = "success";
    ColorPalette["Info"] = "info";
    ColorPalette["Warning"] = "warning";
    ColorPalette["Danger"] = "danger";
    ColorPalette["Default"] = "default";
    ColorPalette["Light"] = "light";
    ColorPalette["Dark"] = "dark";
    ColorPalette["Grey"] = "grey";
    ColorPalette["Blue"] = "blue";
    ColorPalette["Indigo"] = "indigo";
    ColorPalette["Violet"] = "violet";
    ColorPalette["Purple"] = "purple";
    ColorPalette["Pink"] = "pink";
    ColorPalette["Red"] = "red";
    ColorPalette["Orange"] = "orange";
    ColorPalette["Yellow"] = "yellow";
    ColorPalette["Olive"] = "olive";
    ColorPalette["Green"] = "green";
    ColorPalette["Teal"] = "teal";
    ColorPalette["Cyan"] = "cyan";
    ColorPalette["Brown"] = "brown";
})(ColorPalette || (ColorPalette = {}));
export default ColorPalette;
