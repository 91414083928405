var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import SkuTable from "../../../components/nomenclatureSearch/sku/SkuTable";
import { useSku } from "../../../hooks/api/sku/useSku";
import { useSkuAdapter } from "./hooks/useSkuAdapter";
import Centered from "../../../components/ui/Centered";
import { Empty, Spin } from "antd";
const FindTable = ({ tableRef, isLoading, attributes, itemList, tableScroll, setHeightOffset, setSkuDrawer }) => {
    const { sku } = useSku();
    const { adaptSku } = useSkuAdapter();
    const onInfoClick = (id) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const responce = yield sku.getById({ variables: { skuId: id } });
        if ((_b = (_a = responce === null || responce === void 0 ? void 0 : responce.data) === null || _a === void 0 ? void 0 : _a.sku) === null || _b === void 0 ? void 0 : _b.id) {
            setSkuDrawer((_) => {
                var _a;
                return ({
                    isOpen: true,
                    info: adaptSku((_a = responce === null || responce === void 0 ? void 0 : responce.data) === null || _a === void 0 ? void 0 : _a.sku),
                });
            });
        }
    });
    if (isLoading) {
        return (React.createElement(Centered, null,
            React.createElement(Spin, null)));
    }
    if (!(itemList === null || itemList === void 0 ? void 0 : itemList.length)) {
        return (React.createElement(Centered, null,
            React.createElement(Empty, null)));
    }
    return (React.createElement(SkuTable, { tableRef: tableRef, setHeightOffset: setHeightOffset, disableSelection: true, itemInfo: true, attributes: attributes, items: itemList, selectedKeys: [], onSelect: () => { }, onSkuInfoClick: (record, callback) => __awaiter(void 0, void 0, void 0, function* () {
            yield onInfoClick(+record.id);
            callback();
        }), tableProps: {
            scroll: {
                x: "auto",
                y: tableScroll,
            },
        } }));
};
export default FindTable;
