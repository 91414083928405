var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TreeSelect } from 'antd';
import React, { useState, } from 'react';
import LoadingState from '../../constants/enum/LoadingState';
const PAGE = 1;
const PAGE_SIZE = 100;
function AsyncTreeSelect(props) {
    var _a;
    const { onChange, request, dataToOption } = props, otherProps = __rest(props, ["onChange", "request", "dataToOption"]);
    const [value, setValue] = useState((_a = otherProps.value) !== null && _a !== void 0 ? _a : null);
    const [data, setData] = useState([]);
    const [, setLoadingState] = useState(LoadingState.Ready);
    const handlerChange = (newValue) => {
        setValue(newValue);
        if (onChange)
            onChange(newValue);
    };
    const handlerRequest = (parentId) => __awaiter(this, void 0, void 0, function* () {
        const response = yield request(PAGE, PAGE_SIZE, parentId, undefined, setLoadingState);
        setData([...data, ...response.list.map((item) => dataToOption(item, parentId))]);
    });
    const onLoadData = ({ id }) => new Promise((resolve) => {
        handlerRequest(id).finally(() => {
            resolve(undefined);
        });
    });
    const handlerFocus = () => __awaiter(this, void 0, void 0, function* () {
        if (data.length === 0) {
            handlerRequest().finally();
        }
    });
    return (React.createElement(TreeSelect, Object.assign({}, otherProps, { labelInValue: true, treeDataSimpleMode: true, style: { width: '100%' }, value: value !== null && value !== void 0 ? value : undefined, dropdownStyle: { maxHeight: 400, overflow: 'auto' }, onChange: handlerChange, loadData: onLoadData, onFocus: handlerFocus, treeData: data })));
}
export default AsyncTreeSelect;
