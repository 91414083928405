var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from "react";
import { request } from "../../../api/rest/api";
import { message } from "antd";
export const useGetFileInfo = () => {
    const [isLoading, setIsLoading] = useState(false);
    const getFileInfo = (fileId) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        return yield request(undefined, window.env.FileHost)
            .then((instance) => {
            return instance.get(`/File/FileInfo?key=${fileId}`).then((res) => {
                setIsLoading(false);
                return res;
            });
        })
            .catch(() => {
            message.error("Ошибка получения информации о файле");
        });
    });
    return { getFileInfo, isLoading };
};
