export const commentAdapter = (dto) => {
    const { id, createdAt, updateAt, user, message, } = dto;
    return {
        id,
        createdAt,
        updatedAt: updateAt,
        user,
        message,
    };
};
