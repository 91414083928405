class RectCreator {
    createNode(shape, graph) {
        return graph.addNode({
            shape: "rect",
            x: 0,
            y: 0,
            width: shape.width,
            height: shape.height,
            data: { draggable: false, id: "layout-0" },
            zIndex: -1,
            attrs: {
                body: {
                    pointerEvents: "none",
                    rx: shape.rx,
                    ry: shape.ry,
                    fill: "#b9bebd82",
                    stroke: "#CCC",
                    strokeWidth: 1,
                },
                label: { text: "", fill: "#000" },
            },
        });
    }
}
class CircleCreator {
    createNode(shape, graph) {
        return graph.addNode({
            shape: "circle",
            x: shape.cx - shape.r,
            y: shape.cy - shape.r,
            width: shape.r * 2,
            height: shape.r * 2,
            data: { draggable: false, id: "layout-0" },
            zIndex: -1,
            attrs: {
                body: {
                    pointerEvents: "none",
                    fill: "#EEE",
                    stroke: "#CCC",
                    strokeWidth: 1,
                },
                label: {
                    text: "Circle",
                    fill: "#333",
                },
            },
        });
    }
}
// Класс для создания узла эллипса
class EllipseCreator {
    createNode(shape, graph) {
        return graph.addNode({
            shape: "ellipse",
            x: shape.cx - shape.rx,
            y: shape.cy - shape.ry,
            width: shape.rx * 2,
            height: shape.ry * 2,
            data: { draggable: false, id: "layout-0" },
            zIndex: -1,
            attrs: {
                body: {
                    pointerEvents: "none",
                    fill: "#EEE",
                    stroke: "#CCC",
                    strokeWidth: 1,
                },
                label: {
                    text: "Ellipse",
                    fill: "#333",
                },
            },
        });
    }
}
// Класс для создания ребра линии
class LineCreator {
    createNode(shape, graph) {
        return graph.addEdge({
            shape: "edge",
            source: { x: shape.x1, y: shape.y1 },
            target: { x: shape.x2, y: shape.y2 },
            data: { draggable: false, id: "layout-0" },
            zIndex: -1,
            attrs: {
                line: {
                    pointerEvents: "none",
                    stroke: "#CCC",
                    strokeWidth: 1,
                },
            },
        });
    }
}
// Класс для создания узла пути
class PathCreator {
    createNode(shape, graph) {
        return graph.addNode({
            shape: "path",
            data: { draggable: false, id: "layout-0" },
            zIndex: -1,
            attrs: {
                body: {
                    pointerEvents: "none",
                    fill: "none",
                    stroke: "#CCC",
                    strokeWidth: 1,
                    d: shape.d,
                },
            },
        });
    }
}
// Класс для создания узла полигона
class PolygonCreator {
    createNode(shape, graph) {
        return graph.addNode({
            shape: "path",
            data: { draggable: false, id: "layout-0" },
            zIndex: -1,
            attrs: {
                body: {
                    pointerEvents: "none",
                    fill: "#EEE",
                    stroke: "#CCC",
                    strokeWidth: 1,
                    d: `M ${shape.points} Z`,
                },
            },
        });
    }
}
// Класс для создания узла полилинии
class PolylineCreator {
    createNode(shape, graph) {
        return graph.addNode({
            shape: "path",
            data: { draggable: false, id: "layout-0" },
            zIndex: -1,
            attrs: {
                body: {
                    pointerEvents: "none",
                    fill: "none",
                    stroke: "#CCC",
                    strokeWidth: 1,
                    d: `M ${shape.points}`,
                },
            },
        });
    }
}
function createShapeCreator(shape) {
    switch (shape.__typename) {
        case "Rect":
            return new RectCreator();
        case "Circle":
            return new CircleCreator();
        case "Ellipse":
            return new EllipseCreator();
        case "Line":
            return new LineCreator();
        case "Path":
            return new PathCreator();
        case "Polygon":
            return new PolygonCreator();
        case "Polyline":
            return new PolylineCreator();
        default:
            return null;
    }
}
export function createNodeFromShape(shape, graph) {
    const creator = createShapeCreator(shape);
    if (!creator) {
        console.warn("Unsupported shape type:", shape.__typename);
        return null;
    }
    const node = creator.createNode(shape, graph);
    if (node) {
        graph.zoom(1);
    }
    return node;
}
