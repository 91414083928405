var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button } from "antd";
import React, { useMemo } from "react";
import { getDocumentUrl } from "../../../../api/rest/requests/documents/documents";
import PrintActionsButton from "../../../../components/PrintActionsButton";
import TaskHeaderBase from "../../../../components/task/TaskHeader/TaskHeaderBase";
import TaskStatus from "../../../../components/task/TaskStatus";
import LoadingState from "../../../../constants/enum/LoadingState";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import Scope from "../../../../constants/enum/Scope";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useDocumentAvailable } from "../../../../hooks/useDocumentAvailable";
import { printDialogActions } from "../../../../redux/reducers/printDialogReducer";
import { useTaskInventory } from "../../../../hooks/api/taskInventoryApi/useTaskInventory";
import EditExecutedAtButton, { TaskTypes } from "../../../../components/Modals/EditExecutedAtButton";
const InventoryTaskHeaderContainer = (props) => {
    var _a;
    const { onChangeTask, task, isLoading, id, refetch } = props;
    const { task: taskAction } = useTaskInventory();
    const loadingState = isLoading || taskAction.loading;
    const { open: openPrintDialog } = printDialogActions;
    const dispatch = useAppDispatch();
    const documentsAvailable = useDocumentAvailable(ObjectClass.TaskInventory);
    const handlerChangeStatus = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield taskAction.changeStatus({ variables: { input: { taskId: id, input: values } } });
        yield refetch();
    });
    const handlerPrintAction = (documentId) => dispatch(openPrintDialog(getDocumentUrl(documentId, id.toString())));
    const editingAllowed = useMemo(() => (task ? taskHelpers.hasScope(task, Scope.EDIT) : false), [task]);
    const isLocked = useMemo(() => taskHelpers.hasExternalId(task), [task]);
    return (React.createElement(TaskHeaderBase, { externalId: task === null || task === void 0 ? void 0 : task.externalId, id: task === null || task === void 0 ? void 0 : task.id, userName: (_a = task === null || task === void 0 ? void 0 : task.user) === null || _a === void 0 ? void 0 : _a.userName, createdAt: task === null || task === void 0 ? void 0 : task.createdAt, executedAt: task === null || task === void 0 ? void 0 : task.executedAt, isLoading: loadingState, taskTypeTitle: "\u0418\u043D\u0432\u0435\u043D\u0442\u0430\u0440\u0438\u0437\u0430\u0446\u0438\u044F", actions: React.createElement(React.Fragment, null,
            React.createElement(PrintActionsButton, { printActions: documentsAvailable, onItemClick: handlerPrintAction }),
            !isLocked && (React.createElement(React.Fragment, null,
                editingAllowed ? (React.createElement(Button, { loading: loadingState, onClick: () => onChangeTask() }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C")) : null,
                React.createElement(EditExecutedAtButton, { refetch: refetch, taskType: TaskTypes.Inventory, taskId: task === null || task === void 0 ? void 0 : task.id }))),
            (task === null || task === void 0 ? void 0 : task.status) ? React.createElement(TaskStatus, { disabled: isLocked, loading: loadingState, status: adaptTaskInventoryDtoStatusToBaseTaskStatus(task.status, isLoading), onMenuItemClick: handlerChangeStatus }) : null) }));
};
export default InventoryTaskHeaderContainer;
const adaptTaskInventoryDtoStatusToBaseTaskStatus = (status, isLoading) => (Object.assign(Object.assign({}, status), { loadingState: isLoading ? LoadingState.Loading : LoadingState.Ready, style: status.style, relations: status.relations }));
