import { ColorPicker } from "antd";
import React from "react";
const ColorSelect = ({ value, loading, onChange }) => {
    const handleChange = (hex) => {
        if (onChange && hex) {
            onChange(hex);
        }
    };
    return (React.createElement(ColorPicker, { format: "hex", showText: true, value: value, disabled: loading, onChange: (_, hex) => {
            handleChange(hex);
        } }));
};
export default ColorSelect;
