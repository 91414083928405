import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ProductTypeAttributeDeleteDocument = gql `
    mutation ProductTypeAttributeDelete($input: ProductTypeAttributeDeleteInput!) {
  productTypeAttributeDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useProductTypeAttributeDeleteMutation__
 *
 * To run a mutation, you first call `useProductTypeAttributeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAttributeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAttributeDeleteMutation, { data, loading, error }] = useProductTypeAttributeDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAttributeDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAttributeDeleteDocument, options);
}
export const ProductTypeAttributeEditDocument = gql `
    mutation ProductTypeAttributeEdit($productTypeAttributeId: Long!, $input: ProductTypeAttributeEditInput!) {
  productTypeAttributeEdit(
    productTypeAttributeId: $productTypeAttributeId
    input: $input
  ) {
    productTypeAttributeDto {
      canBeSummarized
      createdAt
      dataType
      description
      externalId
      id
      handbookId
      formula
      dimension
      inFilter
      inPrint
      isExternal
      isMain
      isRequired
      isPersonal
      isReadOnly
      position
      sortOrder
      title
      updatedAt
    }
  }
}
    `;
/**
 * __useProductTypeAttributeEditMutation__
 *
 * To run a mutation, you first call `useProductTypeAttributeEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAttributeEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAttributeEditMutation, { data, loading, error }] = useProductTypeAttributeEditMutation({
 *   variables: {
 *      productTypeAttributeId: // value for 'productTypeAttributeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAttributeEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAttributeEditDocument, options);
}
export const ProductTypeAttributeCreateDocument = gql `
    mutation ProductTypeAttributeCreate($input: ProductTypeAttributeCreateInput!) {
  productTypeAttributeCreate(input: $input) {
    productTypeAttributeDto {
      canBeSummarized
      createdAt
      dataType
      description
      dimension
      externalId
      formula
      handbookId
      id
      inFilter
      inPrint
      isExternal
      isMain
      isPersonal
      isRequired
      isReadOnly
      position
      sortOrder
      title
      updatedAt
    }
  }
}
    `;
/**
 * __useProductTypeAttributeCreateMutation__
 *
 * To run a mutation, you first call `useProductTypeAttributeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAttributeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAttributeCreateMutation, { data, loading, error }] = useProductTypeAttributeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAttributeCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAttributeCreateDocument, options);
}
export const ProductTypeCategoryCreateDocument = gql `
    mutation ProductTypeCategoryCreate($input: ProductTypeCategoryCreateInput!) {
  productTypeCategoryCreate(input: $input) {
    productTypeCategoryDto {
      id
      createdAt
      externalId
      isExternal
      position
      productTypeAttributes {
        canBeSummarized
        createdAt
        dataType
        description
        dimension
        externalId
        formula
        handbookId
        id
        inFilter
        inPrint
        isExternal
        isMain
        isPersonal
        isRequired
        isReadOnly
        position
        sortOrder
        title
        updatedAt
      }
      title
      updatedAt
    }
  }
}
    `;
/**
 * __useProductTypeCategoryCreateMutation__
 *
 * To run a mutation, you first call `useProductTypeCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeCategoryCreateMutation, { data, loading, error }] = useProductTypeCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeCategoryCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeCategoryCreateDocument, options);
}
export const ProductTypeCategoryEditDocument = gql `
    mutation ProductTypeCategoryEdit($productTypeAttributeId: Long!, $input: ProductTypeCategoryEditInput!) {
  productTypeCategoryEdit(
    productTypeAttributeId: $productTypeAttributeId
    input: $input
  ) {
    productTypeCategoryDto {
      id
      externalId
      createdAt
      isExternal
      position
      productTypeAttributes {
        canBeSummarized
        createdAt
        dataType
        description
        dimension
        externalId
        formula
        handbookId
        id
        inFilter
        inPrint
        isExternal
        isMain
        isPersonal
        isRequired
        isReadOnly
        position
        sortOrder
        title
        updatedAt
      }
      title
      updatedAt
    }
  }
}
    `;
/**
 * __useProductTypeCategoryEditMutation__
 *
 * To run a mutation, you first call `useProductTypeCategoryEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeCategoryEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeCategoryEditMutation, { data, loading, error }] = useProductTypeCategoryEditMutation({
 *   variables: {
 *      productTypeAttributeId: // value for 'productTypeAttributeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeCategoryEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeCategoryEditDocument, options);
}
export const ProductTypeCategoryDeleteDocument = gql `
    mutation ProductTypeCategoryDelete($input: ProductTypeCategoryDeleteInput!) {
  productTypeCategoryDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useProductTypeCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useProductTypeCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeCategoryDeleteMutation, { data, loading, error }] = useProductTypeCategoryDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeCategoryDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeCategoryDeleteDocument, options);
}
export const ProductTypeAttributeSortCategoriesDocument = gql `
    mutation ProductTypeAttributeSortCategories($input: ProductTypeAttributeSortCategoriesInput!) {
  productTypeAttributeSortCategories(input: $input) {
    productTypeDto {
      createdAt
      description
      externalId
      id
      productCategoryId
      isExternal
      productTypeCategories {
        createdAt
        externalId
        id
        isExternal
        position
        title
        updatedAt
      }
      sizeFormula
      title
      titleMask
      updatedAt
    }
  }
}
    `;
/**
 * __useProductTypeAttributeSortCategoriesMutation__
 *
 * To run a mutation, you first call `useProductTypeAttributeSortCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAttributeSortCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAttributeSortCategoriesMutation, { data, loading, error }] = useProductTypeAttributeSortCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAttributeSortCategoriesMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAttributeSortCategoriesDocument, options);
}
export const ProductTypeAttributeSortOrderDocument = gql `
    mutation ProductTypeAttributeSortOrder($productTypeId: Long!, $input: ProductTypeAttributeSortOrderInput!) {
  productTypeAttributeSortOrder(input: $input, productTypeId: $productTypeId) {
    productTypeDto {
      createdAt
      description
      externalId
      id
      productCategoryId
      isExternal
      productTypeCategories {
        createdAt
        externalId
        id
        isExternal
        position
        title
        updatedAt
      }
      sizeFormula
      title
      titleMask
      updatedAt
    }
  }
}
    `;
/**
 * __useProductTypeAttributeSortOrderMutation__
 *
 * To run a mutation, you first call `useProductTypeAttributeSortOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAttributeSortOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAttributeSortOrderMutation, { data, loading, error }] = useProductTypeAttributeSortOrderMutation({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAttributeSortOrderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAttributeSortOrderDocument, options);
}
export const ProductTypeAttributeSortPositionDocument = gql `
    mutation ProductTypeAttributeSortPosition($productTypeId: Long!, $input: ProductTypeAttributeSortPositionInput!) {
  productTypeAttributeSortPosition(input: $input, productTypeId: $productTypeId) {
    productTypeDto {
      createdAt
      description
      externalId
      id
      productCategoryId
      isExternal
      productTypeCategories {
        createdAt
        externalId
        id
        isExternal
        position
        title
        updatedAt
      }
      sizeFormula
      title
      titleMask
      updatedAt
    }
  }
}
    `;
/**
 * __useProductTypeAttributeSortPositionMutation__
 *
 * To run a mutation, you first call `useProductTypeAttributeSortPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeAttributeSortPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeAttributeSortPositionMutation, { data, loading, error }] = useProductTypeAttributeSortPositionMutation({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeAttributeSortPositionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeAttributeSortPositionDocument, options);
}
export const ProductTypeCreateDocument = gql `
    mutation ProductTypeCreate($input: ProductTypeCreateInput!) {
  productTypeCreate(input: $input) {
    productTypeDto {
      id
      createdAt
      description
      externalId
      isExternal
      productCategoryId
      productTypeCategories {
        createdAt
        externalId
        id
        isExternal
        position
        title
        updatedAt
      }
      sizeFormula
      title
      titleMask
      updatedAt
    }
  }
}
    `;
/**
 * __useProductTypeCreateMutation__
 *
 * To run a mutation, you first call `useProductTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeCreateMutation, { data, loading, error }] = useProductTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeCreateDocument, options);
}
export const ProductTypeEditDocument = gql `
    mutation ProductTypeEdit($productTypeId: Long!, $input: ProductTypeEditInput!) {
  productTypeEdit(productTypeId: $productTypeId, input: $input) {
    productTypeDto {
      id
      createdAt
      description
      externalId
      isExternal
      productCategoryId
      productTypeCategories {
        createdAt
        externalId
        id
        isExternal
        position
        title
        updatedAt
        productTypeAttributes {
          canBeSummarized
          createdAt
          dataType
          description
          dimension
          externalId
          formula
          handbookId
          id
          inFilter
          inPrint
          isExternal
          isMain
          isPersonal
          isReadOnly
          isRequired
          position
          sortOrder
          title
          updatedAt
        }
      }
      sizeFormula
      title
      titleMask
      updatedAt
    }
  }
}
    `;
/**
 * __useProductTypeEditMutation__
 *
 * To run a mutation, you first call `useProductTypeEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeEditMutation, { data, loading, error }] = useProductTypeEditMutation({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeEditMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeEditDocument, options);
}
export const ProductTypeDeleteDocument = gql `
    mutation ProductTypeDelete($input: ProductTypeDeleteInput!) {
  productTypeDelete(input: $input) {
    boolean
  }
}
    `;
/**
 * __useProductTypeDeleteMutation__
 *
 * To run a mutation, you first call `useProductTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeDeleteMutation, { data, loading, error }] = useProductTypeDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductTypeDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProductTypeDeleteDocument, options);
}
