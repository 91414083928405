var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Card, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AppFragment from "../../components/Fragment";
import WarehouseSectionTypeForm from "../../components/WarehouseSectionTypeForm";
import { adaptStorageSectionTypeInput } from "../pages/handbookWarehouses/WarehouseSectionsTypePage/useWarehouseSectionType";
import { DataFetchErrorFallback } from "../../components/DataFetchErrorFallback/DataFetchErrorFallback";
const WarehouseSectionTypeFormContainer = (props) => {
    var _a, _b;
    const { warehouseId, getStorageSectionType, createStorageSectionType, editStorageSectionType } = props;
    const [type, setType] = useState(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const idParam = searchParams.get("current");
    const currentSectionTypeId = idParam ? +idParam : null;
    const [form] = Form.useForm();
    useEffect(() => {
        if (Number.isInteger(currentSectionTypeId))
            getStorageSectionType.handleAction({ variables: { warehouseId, storageSectionId: currentSectionTypeId } }).then((res) => {
                var _a;
                if ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.storageSectionType) {
                    setType(res.data.storageSectionType);
                }
            });
    }, [currentSectionTypeId, warehouseId]);
    const initialValues = type;
    const isLoading = createStorageSectionType.isLoading || editStorageSectionType.isLoading || ((_a = getStorageSectionType === null || getStorageSectionType === void 0 ? void 0 : getStorageSectionType.responce) === null || _a === void 0 ? void 0 : _a.loading);
    const handlerSubmit = (values) => {
        if (values.id) {
            const { id } = values, formValues = __rest(values, ["id"]);
            editStorageSectionType.handleAction(Object.assign(Object.assign({}, formValues), { warehouseId, storageSectionId: id }));
        }
        else {
            createStorageSectionType.handleAction(Object.assign({}, adaptStorageSectionTypeInput(values, warehouseId)));
            form.resetFields();
        }
    };
    useEffect(() => {
        form.resetFields();
    }, [initialValues]);
    const handlerBtnSubmitClick = () => {
        form.submit();
    };
    const handlerReset = () => {
        if (type) {
            searchParams.delete("current");
            setSearchParams(searchParams);
            setType(undefined);
        }
        else {
            form.resetFields();
        }
    };
    if ((_b = getStorageSectionType === null || getStorageSectionType === void 0 ? void 0 : getStorageSectionType.responce) === null || _b === void 0 ? void 0 : _b.error) {
        return React.createElement(DataFetchErrorFallback, null);
    }
    return (React.createElement(AppFragment, { title: `${type ? "Редактировать" : "Добавить"} тип секции`, buttons: React.createElement(Button.Group, { style: { width: "100%" } },
            React.createElement(Button, { block: true, loading: isLoading, onClick: handlerReset }, type ? "Отмена" : "Очистить"),
            React.createElement(Button, { block: true, type: "primary", onClick: handlerBtnSubmitClick, loading: isLoading }, type ? "Редактировать" : "Добавить")) },
        React.createElement(Card, { type: "inner", size: "small", loading: isLoading },
            React.createElement(WarehouseSectionTypeForm, { form: form, onSubmit: handlerSubmit, initialValues: initialValues }))));
};
export default WarehouseSectionTypeFormContainer;
