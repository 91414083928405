/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import LoadingState from "../../../constants/enum/LoadingState";
const initialState = {
    drawer: {
        open: false,
        info: null,
        id: null,
        history: null,
        loadingState: LoadingState.Ready,
        status: null
    },
};
const skuSlice = createSlice({
    name: "skuSlice",
    initialState,
    reducers: {
        open(state, action) {
            state.drawer.open = true;
            state.drawer.id = action.payload;
        },
        close(state) {
            state.drawer.id = null;
            state.drawer.open = false;
            state.drawer.info = null;
            state.drawer.history = null;
        },
        setHistory: (state, action) => {
            state.drawer.history = action.payload;
        },
        setInfo: (state, action) => {
            state.drawer.info = action.payload;
        },
    },
});
export const skuActions = skuSlice.actions;
export default skuSlice.reducer;
