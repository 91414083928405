import { Button, Dropdown } from 'antd';
import React from "react";
import TaskListItemWithSlot from "../../../../components/task/TaskList/TaskListItemWithSlot";
import TaskNomenclature from "../../../../components/task/TaskNomenclature";
import { links } from "../../../../constants/links";
import { getColumnType } from "../../../../helpers/function";
const columns = [getColumnType("id", "#", false, { width: "1%" }), getColumnType("type", "Тип", false, { width: "1%" }), getColumnType("title", "Номенклатура"), getColumnType("reason", "Причина", false, { width: "5%" })];
const CorrectionTaskListItem = (props) => {
    var _a;
    const { task, printActions, pagination, onPrintActionClick } = props;
    const info = [{ title: "Склад", value: task.warehouse.storageTitle }];
    const nomenclature = {
        columns,
        data: task.details.map((detail) => (Object.assign(Object.assign({ key: detail.id }, detail), { title: detail.productTitle, type: detail.productTypeTitle }))),
    };
    const handlerPrintAction = (id) => {
        if (onPrintActionClick)
            onPrintActionClick(id);
    };
    const documentsDropdownMenu = {
        items: printActions === null || printActions === void 0 ? void 0 : printActions.map(item => {
            return {
                key: item.id,
                label: item.title,
                onClick: () => handlerPrintAction(item.id)
            };
        })
    };
    const actions = (React.createElement(React.Fragment, null, (printActions === null || printActions === void 0 ? void 0 : printActions.length) ? (React.createElement(Dropdown, { menu: documentsDropdownMenu, trigger: ["click"] },
        React.createElement(Button, { size: "small", icon: React.createElement("span", { className: "mdi mdi-printer" }) }))) : null));
    return (React.createElement(TaskListItemWithSlot, { quantityTotal: (_a = nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.data) === null || _a === void 0 ? void 0 : _a.reduce((acc, item) => {
            const newCounter = acc + item.quantityBefore;
            return newCounter;
        }, 0), task: task, info: info, pagination: props.pagination, slot: (nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.data) ? React.createElement(TaskNomenclature, { columns: nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.columns, dataSource: nomenclature === null || nomenclature === void 0 ? void 0 : nomenclature.data }) : React.createElement(React.Fragment, null), link: links.taskCorrectionLinks.page.url(task.id), actions: actions }));
};
export default CorrectionTaskListItem;
