import React from "react";
import { AvailabilityOnDate } from "./AvailabilityOnDate";
import Page from '../../../components/Page';
import { AvailabilityOnDatePagination } from "./components/AvailabilityOnDatePagination";
import { AvailabilityOnDateFilter } from "./components/AvailabilityOnDateFilter";
import { AvailabilityOnDateTotal } from "./components/AvailabilityOnDateTotal";
export const AvailabilityOnDateContainer = () => {
    const pageProps = {
        toolbarRight: React.createElement(AvailabilityOnDateFilter, null),
        toolbarTop: React.createElement(AvailabilityOnDateTotal, null),
        toolbarBottom: React.createElement(AvailabilityOnDatePagination, null),
    };
    return (React.createElement(Page, Object.assign({}, pageProps),
        React.createElement(AvailabilityOnDate, null)));
};
