import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import ObjectClass from "../../../../constants/enum/ObjectClass";
import { links } from "../../../../constants/links";
import CommentsContainer from "../../../containers/CommentsContainer";
import FilesContainer from "../../../containers/FilesContainer";
import ProductInfoDrawerContainer from "../../../containers/ProductInfoDrawerContainer";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import WriteOffTaskHeaderContainer from "../../../containers/task/writeoff/WriteOffTaskHeaderContainer";
import WriteOffTaskNomenclatureContainer from "../../../containers/task/writeoff/WriteOffTaskNomenclatureContainer";
import WriteOffStatusHistoryContainer from "../../../containers/task/writeoff/WriteOffStatusHistoryContainer";
import WriteOffTaskContainer from "../../../containers/task/writeoff/WriteOffTaskContainer";
import { useTaskWriteOff } from "../../../../hooks/api/taskWriteOffApi/useTaskWriteOff";
const WriteOffTaskPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const objectInfo = {
        objectClass: ObjectClass.TaskWriteOff,
        objectId: taskId,
    };
    const { task } = useTaskWriteOff();
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    console.log("task.taskGetError", task.taskGetError);
    const handlerChangeTask = () => navigate(links.taskWriteOffLinks.edit.url(taskId));
    const pageProps = {
        toolbarTop: React.createElement(WriteOffTaskHeaderContainer, { taskGetError: !!task.taskGetError, refetch: task.refetch, id: taskId, task: task === null || task === void 0 ? void 0 : task.data, isLoading: task === null || task === void 0 ? void 0 : task.loading, onChangeTask: handlerChangeTask }),
        toolbarRight: React.createElement(CommentsContainer, Object.assign({}, objectInfo)),
    };
    const containerProps = {
        id: taskId,
        nomenclatureTab: React.createElement(WriteOffTaskNomenclatureContainer, { loading: (task === null || task === void 0 ? void 0 : task.loading) && !task.taskGetError, taskWriteOffDto: task === null || task === void 0 ? void 0 : task.data }),
        filesTab: React.createElement(FilesContainer, Object.assign({}, objectInfo)),
        historyTab: React.createElement(WriteOffStatusHistoryContainer, { taskId: taskId }),
    };
    return (React.createElement(Page, Object.assign({}, pageProps),
        !!task.taskGetError ? React.createElement(React.Fragment, null, "\u041E\u0448\u0438\u0431\u043A\u0430 \u0434\u043E\u0441\u0442\u0443\u043F\u0430") : React.createElement(WriteOffTaskContainer, Object.assign({ task: task.data, isLoading: task.loading }, containerProps)),
        React.createElement(ProductInfoDrawerContainer, null),
        React.createElement(SkuInfoDrawerContainer, null)));
};
export default WriteOffTaskPage;
