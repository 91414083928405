import ProductDataType from "../constants/enum/ProductDataType";
import TaskType from "../constants/enum/TaskType";
import ColorPalette from "../constants/enum/ColorPalette";
import moment from "moment";
import { message } from "antd";
import { DataType } from "../api/graphql/common";
export const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            // this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            buildFormData(formData, data[key], parentKey ? `${parentKey}` : key);
        });
    }
    else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
    }
};
export const toDate = (value) => {
    try {
        if (value instanceof Date) {
            return value;
        }
        return new Date(value);
    }
    catch (e) {
        throw new TypeError("[ GenericTaskModel ] Error while creating date object");
    }
};
export const dateToString = (date) => date.toLocaleString("default", {
    day: "numeric",
    month: "long",
    year: "numeric",
});
export const dateToTimeString = (date) => date.toLocaleString("default", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
});
export const getUrlWithParams = (pathname, params) => {
    const url = new URL(pathname, window.env.ApiHost);
    // eslint-disable-next-line no-restricted-syntax
    for (const key in params) {
        if ({}.hasOwnProperty.call(params, key)) {
            const value = params[key];
            if (value) {
                url.searchParams.append(key, value);
            }
        }
    }
    return url.pathname + decodeURI(url.search);
};
export function getColumnType(id, title, isEllipsis, otherProps) {
    const result = Object.assign(Object.assign({}, otherProps), { title: title !== null && title !== void 0 ? title : "", dataIndex: id, key: id });
    if (isEllipsis) {
        result.ellipsis = {
            showTitle: false,
        };
    }
    return result;
}
export const getColorName = (color) => {
    switch (color) {
        case ColorPalette.Blue:
            return "Синий";
        case ColorPalette.Brown:
            return "Коричневый";
        case ColorPalette.Cyan:
            return "Голубой";
        case ColorPalette.Green:
            return "Зеленый";
        case ColorPalette.Grey:
            return "Серый";
        case ColorPalette.Indigo:
            return "Индиго";
        case ColorPalette.Olive:
            return "Оливковый";
        case ColorPalette.Orange:
            return "Оранжевый";
        case ColorPalette.Pink:
            return "Розовый";
        case ColorPalette.Purple:
            return "Пурпурный";
        case ColorPalette.Red:
            return "Красный";
        case ColorPalette.Teal:
            return "Бирюзовый";
        case ColorPalette.Violet:
            return "Фиолетовый";
        case ColorPalette.Yellow:
            return "Желтый";
        default:
            return color;
    }
};
export const getProductDataTypeName = (productDataType) => {
    switch (productDataType) {
        case ProductDataType.HANDBOOK:
        case DataType.Handbook:
            return "Справочник";
        case ProductDataType.INTEGER:
        case DataType.Integer:
            return "Целое число";
        case ProductDataType.FLOAT:
        case DataType.Float:
            return "Число с плавающей точкой";
        case ProductDataType.COLOR:
        case DataType.Color:
            return "Цвет";
        case ProductDataType.VARCHAR:
        case DataType.Varchar:
            return "Текст (255 символов)";
        case ProductDataType.BOOLEAN:
        case DataType.Boolean:
            return "Логический (Да/Нет)";
        case ProductDataType.DATE:
        case DataType.Date:
            return "Дата";
        case ProductDataType.DATE_TIME:
        case DataType.DateTime:
            return "Дата и время";
        case ProductDataType.FORMULA:
        case DataType.Formula:
            return "Формула";
        default:
            return "";
    }
};
export const getTaskTypeName = (type) => {
    switch (type) {
        case TaskType.INVENTORY:
            return "Инветаризация";
        case TaskType.LOADING:
            return "Загрузка";
        case TaskType.MOVEMENT:
            return "Перемещение";
        case TaskType.PARTITION:
            return "Деление";
        case TaskType.PARTITION_ORIGINAL:
            return "Деление - в качестве оригинальной продукции";
        case TaskType.PARTITION_CREATED:
            return "Деление - получен в результате деления";
        case TaskType.SHIPPING:
            return "Отгрузка";
        case TaskType.CHANGE_WAREHOUSE:
            return "Перемещение между складами";
        case TaskType.CORRECTION:
            return "Корректировка";
        case TaskType.WRITE_OFF:
            return "Списание";
        default:
            return type;
    }
};
export const addressParse = (address) => {
    const country = address[0];
    let index;
    let region;
    let city;
    let street = "";
    let building = "";
    if (country === "Россия") {
        if (address[1] && parseInt(address[1], 10)) {
            index = address[1];
            region = address[3];
            city = address[5];
        }
        else {
            index = "";
            region = address[2];
            city = address[4];
        }
        address.forEach((item, ind) => {
            if (item.toLowerCase().includes("улица")) {
                street = item.replace("улица", "").trim();
                building = address[ind + 1];
            }
        });
        return {
            index,
            country,
            region,
            city,
            street,
            building,
        };
    }
    return {
        index: "",
        country: "",
        region: "",
        city: "",
        street: "",
        building: "",
    };
};
export const getProductTypeAttributesObject = (type) => {
    var _a;
    return (_a = type === null || type === void 0 ? void 0 : type.productTypeCategories.reduce((acc, cat) => {
        var _a;
        return (Object.assign(Object.assign({}, acc), (_a = cat.productTypeAttributes) === null || _a === void 0 ? void 0 : _a.filter((attr) => !attr.isPersonal).reduce((accAttr, attr) => (Object.assign(Object.assign({}, accAttr), { [attr.id]: attr })), {})));
    }, {})) !== null && _a !== void 0 ? _a : {};
};
export function selectAdapter(obj) {
    const result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === "object" && obj[key] !== null && "value" in obj[key]) {
                result[key] = obj[key].value;
            }
            else {
                result[key] = obj[key];
            }
        }
    }
    return result;
}
export function dtoStorageToSelectAdapter(obj) {
    const result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === "object" && obj[key] !== null && "id" in obj[key] && "storageTitle" in obj[key]) {
                result[`${key}Id`] = {
                    label: obj[key].storageTitle,
                    value: obj[key].id,
                };
            }
            else {
                result[key] = obj[key];
            }
        }
    }
    return result;
}
export function dtoContractorToSelectAdapter(obj) {
    const result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === "object" && obj[key] !== null && "id" in obj[key] && "companyName" in obj[key]) {
                result[`${key}Id`] = {
                    label: obj[key].companyName,
                    value: obj[key].id,
                };
            }
            else {
                result[key] = obj[key];
            }
        }
    }
    return result;
}
export function removeUndefinedNullKeys(obj) {
    const result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] !== undefined || obj[key] !== null) {
                result[key] = obj[key];
            }
        }
    }
    return result;
}
export function checkDataStringAndConvertToData(obj) {
    const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/;
    let newObj = Object.assign({}, obj);
    for (const key in newObj) {
        if (newObj.hasOwnProperty(key)) {
            const value = newObj[key];
            if (typeof value === "string" && regex.test(value)) {
                newObj[key] = moment(value);
            }
        }
    }
    return newObj;
}
export function formatDuration(duration) {
    const parts = [];
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    if (days > 0)
        parts.push(`${days} д.`);
    if (hours > 0 || days > 0)
        parts.push(`${hours}ч.`);
    if (minutes > 0 || hours > 0 || days > 0)
        parts.push(`${minutes}м.`);
    parts.push(`${seconds}сек.`);
    return parts.join(" ");
}
export const handleCommonError = (error) => message.error(error);
export const handleSuccess = (msg) => message.success(msg);
