import handbookApi, { HANDBOOK_VALUE_TAG } from '..';
import dtoToEntity from '../../../../api/rest/adapters/dtoToEntity';
import { Method } from '../../../../constants/enum/Method';
const URI = 'handbookValue';
const handbookValueApi = handbookApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchValueList: builder.query({
            query: (args) => ({
                method: Method.GET,
                url: `${URI}/list`,
                params: args,
            }),
            transformResponse: (response) => ({
                list: response.data.map(dtoToEntity.handbookValueAdapter),
                pagination: dtoToEntity.paginationAdapter(response.pagination),
            }),
            providesTags: (result) => {
                if (result) {
                    return [
                        ...result.list.map(({ id }) => ({ type: HANDBOOK_VALUE_TAG, id })),
                        HANDBOOK_VALUE_TAG,
                    ];
                }
                return [HANDBOOK_VALUE_TAG];
            },
        }),
        fetchValue: builder.query({
            query: (handbookValueId) => ({
                method: Method.GET,
                url: `${URI}/${handbookValueId}`,
            }),
            transformResponse: (response) => (dtoToEntity.handbookValueAdapter(response)),
            providesTags: (result, error, id) => [
                HANDBOOK_VALUE_TAG, { type: HANDBOOK_VALUE_TAG, id },
            ],
        }),
        addValue: builder.mutation({
            query: (values) => ({
                method: Method.POST,
                url: `${URI}/add`,
                body: values,
            }),
            transformResponse: (response) => (dtoToEntity.handbookValueAdapter(response)),
            invalidatesTags: [HANDBOOK_VALUE_TAG],
        }),
        editValue: builder.mutation({
            query: (args) => {
                const { handbookValueId, values } = args;
                return {
                    method: Method.PUT,
                    url: `${URI}/${handbookValueId}`,
                    body: values,
                };
            },
            transformResponse: (response) => (dtoToEntity.handbookValueAdapter(response)),
            invalidatesTags: (result, error, { handbookValueId }) => [
                HANDBOOK_VALUE_TAG, { type: HANDBOOK_VALUE_TAG, id: handbookValueId },
            ],
        }),
        deleteValue: builder.mutation({
            query: (handbookValueId) => ({
                method: Method.DELETE,
                url: `${URI}/${handbookValueId}`,
            }),
            transformResponse: (_, __, id) => id,
            invalidatesTags: (result, error, id) => [
                HANDBOOK_VALUE_TAG, { type: HANDBOOK_VALUE_TAG, id },
            ],
        }),
    }),
});
export default handbookValueApi;
