var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import InventoryEditTaskHeaderContainer from "../../../containers/task/inventory/InventoryEditTaskHeaderContainer";
import InventoryTaskFormNomenclatureContainer from "../../../containers/task/inventory/InventoryTaskFormNomenclatureContainer";
import SkuInfoDrawerContainer from "../../../containers/SkuInfoDrawerContainer";
import { useTaskInventory } from "../../../../hooks/api/taskInventoryApi/useTaskInventory";
import { taskHelpers } from "../../../../entities/tasks/BaseTaskType";
import { useTaskInventoryCRUDActions } from "../../../../hooks/api/taskInventoryApi/useTaskInventoryCRUDActions";
import InventoryTaskForm from "../../../conponents/task/inventory/InventoryTaskForm";
import { message } from "antd";
export const InventoryContext = React.createContext(null);
const InventoryProvider = InventoryContext.Provider;
const InventoryEditPage = () => {
    var _a, _b;
    const params = useParams();
    const taskId = params.taskId ? parseFloat(params.taskId) : 0;
    const { actions } = useTaskInventoryCRUDActions();
    const { edit, isLoading } = actions;
    const { task } = useTaskInventory();
    const isLocked = useMemo(() => taskHelpers.hasExternalId(task === null || task === void 0 ? void 0 : task.data), [task === null || task === void 0 ? void 0 : task.data]);
    const handlerSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e;
        if (values && typeof values === "object" && "taskId" in values && typeof values.taskId === "number" && "taskInventoryCommissions" in values && Array.isArray(values.taskInventoryCommissions)) {
            const response = yield edit(values.taskId, { taskInventoryCommissions: values.taskInventoryCommissions || [] });
            const id = (_e = (_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.taskInventoryEdit) === null || _d === void 0 ? void 0 : _d.taskInventoryDto) === null || _e === void 0 ? void 0 : _e.id;
            if (id) {
                message.success(`Задача ${id} успешно изменена`);
            }
        }
    });
    useEffect(() => {
        task.getById({ variables: { taskId } });
    }, []);
    const pageProps = {
        toolbarBottom: null,
        toolbarTop: React.createElement(InventoryEditTaskHeaderContainer, { id: taskId, task: task === null || task === void 0 ? void 0 : task.data, isLoading: task === null || task === void 0 ? void 0 : task.loading }),
        toolbarRight: null,
    };
    return (React.createElement(InventoryProvider, { value: { isLocked } },
        React.createElement(Page, Object.assign({}, pageProps),
            React.createElement(InventoryTaskForm, { initialData: { taskId: taskId, warehouse: (_a = task === null || task === void 0 ? void 0 : task.data) === null || _a === void 0 ? void 0 : _a.warehouse, tastaskInventoryCommissions: (_b = task === null || task === void 0 ? void 0 : task.data) === null || _b === void 0 ? void 0 : _b.taskInventoryCommissions }, isLoading: isLoading || (task === null || task === void 0 ? void 0 : task.loading), onSubmit: handlerSubmit }),
            React.createElement(InventoryTaskFormNomenclatureContainer, { refetch: task === null || task === void 0 ? void 0 : task.refetch, taskId: taskId, task: task === null || task === void 0 ? void 0 : task.data, isLoading: isLoading || (task === null || task === void 0 ? void 0 : task.loading) }),
            React.createElement(SkuInfoDrawerContainer, null))));
};
export default InventoryEditPage;
