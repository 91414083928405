var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { Button, DatePicker, Form, Spin, Tooltip, Typography, } from 'antd';
import { mapValues, pick, uniqueId } from 'lodash';
import Page from '../../../components/Page';
import ProductFilter from '../../../components/nomenclatureSearch/ProductFilter';
import LoadingState from '../../../constants/enum/LoadingState';
import { COMMON_ATTRIBUTES, PERSONAL_ATTRIBUTES, PRODUCT_ID, } from '../../../components/nomenclatureSearch/ProductFilter/ProductFilter';
import { getDefaultPaginationEntity } from '../../../entities/PaginationEntity';
import ReportTable from '../../../components/ReportTable';
import { getDefaultMovementTotalEntity } from '../../../entities/reports/MovementTotalEntity';
import { getReport, getReportExel } from '../../../api/rest/requests/reports/movement';
import { getColumnType } from '../../../helpers/function';
import { clientRender, dateRender, productRender, quantityRender, } from '../../../components/ReportTable/helpers';
import ReportTotal from '../../../components/ReportTotal';
import PaginationPanel from '../../../components/PaginationPanel';
import WarehouseSelect from '../../../components/WarehouseSelect';
import AppFragment from '../../../components/Fragment';
import { links } from '../../../constants/links';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import ClientSelect from '../../../components/ClientSelect';
const { Link } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const WAREHOUSE = 'warehouseId';
const DATE = 'date';
const DATE_FROM = 'periodFrom';
const DATE_TO = 'periodTo';
const CLIENTS = 'clients';
const defaultState = {
    attributes: [],
    items: {
        list: [],
        total: getDefaultMovementTotalEntity(),
        pagination: getDefaultPaginationEntity(DEFAULT_PAGE_SIZE),
    },
    loadingState: LoadingState.Ready,
    filterValues: {},
    reportLoadingInProcess: false,
};
const productFilterValuesToRequest = (values) => {
    var _a;
    const dateField = values[DATE];
    const result = {
        [PRODUCT_ID]: values[PRODUCT_ID],
        [CLIENTS]: values[CLIENTS] ? values[CLIENTS].map((item) => item.value) : [],
        [WAREHOUSE]: ((_a = values[WAREHOUSE]) === null || _a === void 0 ? void 0 : _a.value) || undefined,
    };
    if (dateField) {
        result[DATE_FROM] = dateField[0].format(dateFormat);
        result[DATE_TO] = dateField[1].format(dateFormat);
    }
    const convertAttributes = (attributes) => {
        if (attributes) {
            const filledAttributeKeys = Object.keys(attributes)
                .filter((key) => !!attributes[key]);
            if (filledAttributeKeys.length > 0) {
                return mapValues(pick(attributes, filledAttributeKeys), (item) => item);
            }
        }
        return undefined;
    };
    result.productAttributes = convertAttributes(values[COMMON_ATTRIBUTES]);
    result.skuAttributes = convertAttributes(values[PERSONAL_ATTRIBUTES]);
    return result;
};
const renderTaskTypeInfo = (task) => {
    switch (task.discriminator) {
        case 'TaskLoading':
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null, dateRender(task.executedAt)),
                React.createElement(Tooltip, { title: 'Задача на загрузку' },
                    React.createElement("span", { className: 'mdi mdi-download' })),
                React.createElement(Link, { href: links.taskLoadingLinks.page.url(task.id), target: "_blank" },
                    "#",
                    task.id)));
        case 'TaskShipping':
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null, dateRender(task.executedAt)),
                React.createElement(Tooltip, { title: 'Задача на отгрузку' },
                    React.createElement("span", { className: 'mdi mdi-upload' })),
                React.createElement(Link, { href: links.taskShippingLinks.page.url(task.id), target: "_blank" },
                    "#",
                    task.id)));
        case 'TaskChangeWarehouse':
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null, dateRender(task.executedAt)),
                React.createElement(Tooltip, { title: 'Задача на перемещение между складами' },
                    React.createElement("span", { className: 'mdi mdi-package-variant-closed' })),
                React.createElement(Link, { href: links.taskChangeWarehouseLinks.page.url(task.id), target: "_blank" },
                    "#",
                    task.id)));
        case 'TaskWriteOff':
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null, dateRender(task.executedAt)),
                React.createElement(Tooltip, { title: 'Задача на списание' },
                    React.createElement("span", { className: 'mdi mdi-minus-box' })),
                React.createElement(Link, { href: links.taskWriteOffLinks.page.url(task.id), target: "_blank" },
                    "#",
                    task.id)));
        default:
            return React.createElement(React.Fragment, null, "-");
    }
};
const columns = [
    getColumnType('skuId', '#', true, {
        align: 'right',
        fixed: 'left',
        width: 30,
    }),
    getColumnType('productTitle', 'Номенклатура', true, {
        align: 'left',
        render: (value, record) => productRender(record),
    }),
    getColumnType('clientTitle', 'Контрагент', true, {
        align: 'left',
        render: (value, record) => clientRender(record),
    }),
    {
        title: 'Приход',
        align: 'center',
        ellipsis: true,
        fixed: 'right',
        children: [
            getColumnType('taskLoadingDate', 'Дата', true, {
                align: 'right',
                fixed: 'right',
                width: 70,
                render: (value, record) => (record.arrivals.length > 0 ? (renderTaskTypeInfo(record.arrivals[0])) : React.createElement(React.Fragment, null, "-")),
            }),
            getColumnType('quantity', 'Кол-во', true, {
                align: 'right',
                fixed: 'right',
                width: 70,
                render: (value, record) => quantityRender(+value, record.arrivals.length > 0),
            }),
        ],
    },
    {
        title: 'Расход',
        align: 'center',
        ellipsis: true,
        fixed: 'right',
        children: [
            getColumnType('taskShippingDate', 'Дата', true, {
                align: 'right',
                fixed: 'right',
                width: 70,
                render: (value, record) => (record.disposals.length > 0 ? (renderTaskTypeInfo(record.disposals[0])) : React.createElement(React.Fragment, null, "-")),
            }),
            getColumnType('quantity', 'Кол-во', true, {
                align: 'right',
                fixed: 'right',
                width: 70,
                render: (value, record) => quantityRender(+value, record.disposals.length > 0),
            }),
        ],
    },
];
class MovementForPeriodPage extends Component {
    constructor(props) {
        super(props);
        this.filterForm = React.createRef();
        this.handlerSearch = () => __awaiter(this, void 0, void 0, function* () {
            const { items, filterValues } = this.state;
            const { pagination } = items;
            const paginatedItems = yield getReport(pagination.page.toString(), pagination.itemsOnPage.toString(), productFilterValuesToRequest(filterValues), (state) => this.setState({
                loadingState: state,
            }));
            this.setState({
                items: paginatedItems,
            });
        });
        this.handlerSetAttributes = (list) => {
            this.setState({
                attributes: list,
            });
        };
        this.handlerFilterChange = (values) => __awaiter(this, void 0, void 0, function* () {
            this.setState((state) => ({
                items: Object.assign(Object.assign({}, state.items), { pagination: defaultState.items.pagination }),
                filterValues: values,
            }));
            setTimeout(this.handlerSearch, 0);
        });
        this.handlerPaginationChange = (page, itemsOnPage) => __awaiter(this, void 0, void 0, function* () {
            this.setState((state) => ({
                items: Object.assign(Object.assign({}, state.items), { pagination: Object.assign(Object.assign({}, state.items.pagination), { page, itemsOnPage: itemsOnPage !== null && itemsOnPage !== void 0 ? itemsOnPage : state.items.pagination.itemsOnPage }) }),
            }));
            setTimeout(this.handlerSearch, 0);
        });
        this.handleDownload = () => __awaiter(this, void 0, void 0, function* () {
            const { filterValues } = this.state;
            const filterToRequest = productFilterValuesToRequest(filterValues);
            this.setState({
                reportLoadingInProcess: true,
                loadingState: 1,
            });
            yield getReportExel(filterToRequest, () => {
                this.setState({
                    reportLoadingInProcess: false,
                    loadingState: 0,
                });
            });
        });
        this.handlerClose = () => {
            var _a;
            (_a = this.filterForm.current) === null || _a === void 0 ? void 0 : _a.resetFields();
            this.setState(defaultState);
        };
        this.renderFooter = () => {
            const { items, loadingState } = this.state;
            const { pagination, list } = items;
            return (React.createElement(PaginationPanel, { pagination: pagination, disabled: loadingState === LoadingState.Loading || list.length === 0, onChange: this.handlerPaginationChange }));
        };
        this.state = defaultState;
    }
    renderFilter() {
        const { attributes, filterValues } = this.state;
        const canDownloadReport = !(!!(filterValues === null || filterValues === void 0 ? void 0 : filterValues.warehouseId) && !!(filterValues === null || filterValues === void 0 ? void 0 : filterValues.date));
        return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        React.createElement(AppFragment, { title: "\u0424\u0438\u043B\u044C\u0442\u0440", buttons: (React.createElement(Button.Group, { style: { width: "100%", gap: "5px" } },
                React.createElement(Button, { block: true, onClick: this.handlerClose }, "\u0421\u0431\u0440\u043E\u0441"),
                this.state.reportLoadingInProcess ? (React.createElement(Button, { disabled: true, size: "middle", icon: React.createElement(Spin, { size: "small", spinning: true }) })) : (React.createElement(Button, { onClick: this.handleDownload, disabled: canDownloadReport, size: "middle", icon: React.createElement("span", { className: "mdi mdi-download" }) })))) },
            React.createElement(ProductFilter, { productLabel: "\u0422\u0438\u043F \u043F\u0440\u043E\u0434\u0443\u043A\u0446\u0438\u0438", includePersonal: true, onSetAttributes: this.handlerSetAttributes, onChange: this.handlerFilterChange, attributes: attributes, formRef: this.filterForm, productIdIsRequired: false, style: { overflowX: 'hidden' }, additionalFields: (React.createElement(React.Fragment, null,
                    React.createElement(Form.Item, { name: CLIENTS, label: "\u041A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442", rules: [{ required: false }] },
                        React.createElement(ClientSelect, { mode: "multiple", placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442\u0430" })),
                    React.createElement(Form.Item, { name: WAREHOUSE, label: "\u0421\u043A\u043B\u0430\u0434", rules: [{ required: true }] },
                        React.createElement(WarehouseSelect, { placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043A\u043B\u0430\u0434" })),
                    React.createElement(Form.Item, { name: "date", label: "\u0414\u0430\u0442\u0430" },
                        React.createElement(RangePicker, null)))) })));
    }
    renderTotal() {
        const { loadingState, items } = this.state;
        return (React.createElement(ReportTotal, { isLoading: loadingState === LoadingState.Loading, sCount: items.total.totalCount, tlCount: items.total.taskLoadingCount, tsCount: items.total.taskShippingCount, tlQuantity: items.total.taskLoadingQuantity, tsQuantity: items.total.taskShippingQuantity }));
    }
    render() {
        const { loadingState, items } = this.state;
        const pageProps = {
            toolbarRight: this.renderFilter(),
            toolbarTop: this.renderTotal(),
            toolbarBottom: this.renderFooter(),
        };
        return (React.createElement(Page, Object.assign({}, pageProps),
            React.createElement(ReportTable, { isLoading: loadingState === LoadingState.Loading, data: items.list, rowKeyGenerator: () => Number(uniqueId()), columns: columns })));
    }
}
export default MovementForPeriodPage;
